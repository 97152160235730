import { queryAllAwardInJackpot, queryDailyPrizeInfo } from "@/api/dailyReward";
import { useEffect, useState } from "react";

const useLottery = ({ refreshTimes }) => {
  const [activityInfo, setActivityInfo] = useState<any>({});
  const queryActivityInfo = async () => {
    const info = await queryDailyPrizeInfo({});
    setActivityInfo(info);
  };
  useEffect(() => {
    console.log("刷新数据", refreshTimes);
    queryActivityInfo();
  }, [refreshTimes]);
  return {
    activityInfo,
  };
};

export default useLottery;
