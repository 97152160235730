import { useCommonContext } from "@/utils/context/common";
import { default as React, useLayoutEffect } from "react";
import KeepAlive, { useActivate, useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import Index from "./Market";

interface Props {
  pagePath?: any;
}

const MarketContainer: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const currentRole = search.get("currentRole");
  const { track } = useCommonContext();

  useLayoutEffect(() => {
    if (track.registerSuperProperty) {
      track.registerSuperProperty({
        merchant_shop_code: search.get("merchantShopCode"),
      });
    }
  }, []);

  useActivate(() => {
    console.log("market-----useActivate");
    document.title = currentRole === "2" ? "店铺" : "品牌";
  });

  useUnactivate(() => {
    console.log("market-----useUnactivate");
  });

  return (
    <KeepAlive cacheKey={`market-${shopId}`} saveScrollPosition={true}>
      <Index />
    </KeepAlive>
  );
};

export default MarketContainer;
