/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import microApp from "@micro-zoe/micro-app";
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import React, { FC, useEffect } from "react";
import KeepAlive, { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

interface Iprops {
  name: string;
  baseRouter?: string;
  baseUrl?: string;
  pagePath?: any;
  navigation?: any;
}
const MicroAppContainer: FC<Iprops> = ({ name, baseRouter, baseUrl }) => {
  let staticUrl = location.protocol + "//" + location.hostname + baseUrl;
  console.log("MicroAppContainer-----staticUrl", staticUrl);

  useEffect(() => {
    microApp.setData(name, {
      appId: "mx-shop-micro",
    });
  });
  useActivate(() => {
    console.log("MicroAppContainer-----useActivate");
  });
  useUnactivate(() => {
    console.log("MicroAppContainer-----useUnactivate");
  });

  return (
    <div className={styles.MicroContainer}>
      <KeepAlive cacheKey={`MicroAppContainer-${name}`} saveScrollPosition={true}>
        <micro-app
          name={name}
          url={staticUrl}
          baseroute={baseRouter}
          onCreated={() => console.log("micro-app元素被创建")}
          onBeforemount={() => console.log("即将被渲染")}
          onMounted={() => console.log("已经渲染完成")}
          onUnmount={() => console.log("已经卸载")}
          onError={() => console.log("渲染出错")}
        ></micro-app>
      </KeepAlive>
    </div>
  );
};
export default MicroAppContainer;
