import { getCurrentFormatTime, getCurrentformatTime } from "@/utils/date-format";
import { Popup } from "antd-mobile";
import React from "react";
import { YouXueSignUp } from "../../../model";
import styles from "./index.module.less";

interface PopupProps {
  signUpDetail?: YouXueSignUp;
  visible: boolean;
  showBottom?: boolean;
  setVisible: (boolean) => void;
  onSurePress?: (signUpDetail?: YouXueSignUp) => void;
}

export interface Info {
  // 标题
  title?: string;
  // 描述
  desc?: string;
}

const ActivityDetailComp: React.FC<PopupProps> = ({
  visible,
  setVisible,
  showBottom,
  onSurePress,
  signUpDetail,
}) => {
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Popup
      style={{ "--z-index": "199" }}
      maskStyle={{ zIndex: "100" }}
      visible={visible}
      onMaskClick={onClose}
    >
      <div className={styles.popup__box}>
        <div className={styles.box__header}>
          <div className={styles.header__title}>游学活动详情</div>
          <img
            className={styles.header__icon}
            onClick={() => onClose()}
            src="https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1710849026952_49.png"
          />
        </div>
        <div className={styles.box__time}>
          <div
            className={styles.time__title}
          >{`${getCurrentformatTime(parseInt(signUpDetail?.signUpEndTime || "0", 10))} 截止报名`}</div>
        </div>
        <div className={styles.list}>
          <div className={styles.page__body}>
            <>
              {signUpDetail ? (
                <>
                  <div className={styles.list__card}>
                    <img className={styles.card__image} src={signUpDetail?.studyTourLogo} />
                    <div className={styles.card__content}>
                      <div className={styles.content__title}>
                        <div className={styles.title__left}>{signUpDetail?.studyTourTitle}</div>
                        {signUpDetail?.travelAllowance && (
                          <div className={styles.title__right}>
                            <div className={styles.right__title}>
                              {signUpDetail?.travelAllowance}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={styles.content__desc}>
                        {`游学活动时间：${getCurrentFormatTime(parseInt(signUpDetail?.studyTourBeginTime || "0", 10))}-${getCurrentFormatTime(parseInt(signUpDetail?.studyTourEndTime || "0", 10))}`}
                      </div>
                    </div>
                  </div>
                  {signUpDetail?.studyTourBenefitPointResp?.map((item) => {
                    return (
                      <>
                        <div className={styles.list__header}>
                          <div className={styles.header__title}>{item?.benefitName}</div>
                          <div className={styles.header__desc}>{item?.benefitDesc}</div>
                        </div>
                      </>
                    );
                  })}
                  <div className={styles.footer} />
                  {showBottom && (
                    <div className={styles.bottom} onClick={() => onSurePress?.(signUpDetail)}>
                      <div className={styles.bottom__btn}>
                        <div className={styles.btn__text}>确认选择</div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.empty}>
                  <img
                    className={styles.emptyIcon}
                    src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                  />
                  <div>游学活动不存在～</div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ActivityDetailComp;
