import constate from "constate";
import { useEffect, useMemo, useRef, useState } from "react";

const useFilterOption = ({
  filterBoardVisible,
  filters,
  queryParams,
}: {
  filterBoardVisible: boolean;
  filters: any[];
  queryParams: any;
  scene: string;
  pageId: string;
  keyword?: string;
}) => {
  const [floatBoardData, setFloatBoardData] = useState<any>();
  const aggsType = useRef<"category" | "brand" | undefined>();

  useEffect(() => {
    if (!filterBoardVisible) {
      return;
    }
    // 第一次打开筛选面板时，需要先请求一次数据；第二次相同场景下打开筛选面板时，面板不刷新
    const brand = filters.filter((item) => item.title === "品牌")?.[0];
    const type = filters.filter((item) => item.title === "素材类别")?.[0];
    const category = filters.filter((item) => item.title === "素材类型")?.[0];
    const channel = filters.filter((item) => item.title === "素材渠道")?.[0];
    const serviceTag = filters.filter((item) => item.title === "素材来源")?.[0];
    const brandList = filters.filter((item) => item.title === "品牌")?.[0]?.itemList || [];
    const typeList = filters.filter((item) => item.title === "素材类别")?.[0]?.itemList || [];
    const categoryList = filters.filter((item) => item.title === "素材类型")?.[0]?.itemList || [];
    const channelList = filters.filter((item) => item.title === "素材渠道")?.[0]?.itemList || [];
    const serviceTagList = filters.filter((item) => item.title === "素材来源")?.[0]?.itemList || [];
    for (let item of queryParams.frontCodes) {
      for (let brandItem of brandList) {
        if (item.type === brand.type && item.code === brandItem.code) {
          brandItem.selected = true;
        }
      }
      for (let typeItem of typeList) {
        if (item.type === type.type && item.code === typeItem.code) {
          typeItem.selected = true;
        }
      }
      for (let categoryItem of categoryList) {
        if (item.type === category.type && item.code === categoryItem.code) {
          categoryItem.selected = true;
        }
      }
      for (let channelItem of channelList) {
        if (item.type === channel.type && item.code === channelItem.code) {
          channelItem.selected = true;
        }
      }
      for (let serviceTagItem of serviceTagList) {
        if (item.type === serviceTag.type && item.code === serviceTagItem.code) {
          serviceTagItem.selected = true;
        }
      }
    }
    setFloatBoardData({
      brandList,
      typeList,
      categoryList,
      channelList,
      serviceTagList,
    });
  }, [filterBoardVisible]);

  const shownParms = () => {
    return queryParams;
  };

  // 素材类别
  const shownTypeList = useMemo(() => {
    return floatBoardData?.typeList?.filter((item) => !item.hidden) ?? [];
  }, [floatBoardData]);

  const toggleType = (item: any) =>
    setFloatBoardData({
      ...floatBoardData,
      typeList: floatBoardData?.typeList?.map((tag) => ({
        ...tag,
        selected: tag.code === item.code ? (tag.selected ? false : true) : false,
      })),
    });

  // 素材渠道
  const shownChannelList = useMemo(() => {
    return floatBoardData?.channelList?.filter((item) => !item.hidden) ?? [];
  }, [floatBoardData]);

  const toggleChannel = (item: any) =>
    setFloatBoardData({
      ...floatBoardData,
      channelList: floatBoardData?.channelList?.map((tag) => ({
        ...tag,
        selected: tag.code === item.code ? (tag.selected ? false : true) : false,
      })),
    });

  // 素材类型
  const shownCategoryList = useMemo(() => {
    return floatBoardData?.categoryList?.filter((item) => !item.hidden) ?? [];
  }, [floatBoardData]);

  const toggleCategory = (item: any) =>
    setFloatBoardData({
      ...floatBoardData,
      categoryList: floatBoardData?.categoryList?.map((tag) => ({
        ...tag,
        selected: tag.code === item.code ? (tag.selected ? false : true) : false,
      })),
    });

  // 素材来源
  const shownServiceTagList = useMemo(() => {
    return floatBoardData?.serviceTagList?.filter((item) => !item.hidden) ?? [];
  }, [floatBoardData]);

  const toggleServiceTag = (item: any) =>
    setFloatBoardData({
      ...floatBoardData,
      serviceTagList: floatBoardData?.serviceTagList?.map((tag) => ({
        ...tag,
        selected: tag.code === item.code ? (tag.selected ? false : true) : false,
      })),
    });

  // 品牌
  const shownBrandList = useMemo(() => {
    return floatBoardData?.brandList?.filter((item) => !item.hidden) ?? [];
  }, [floatBoardData]);

  const selectedBrandList = useMemo(
    () => floatBoardData?.brandList?.filter((item) => item.selected) ?? [],
    [floatBoardData],
  );

  const resetBrand = () => {
    setFloatBoardData((oldData) => ({
      ...oldData,
      brandList: oldData?.brandList?.map((brand) => ({
        ...brand,
        selected: false,
      })),
    }));
  };

  const constructParams = () => {
    let frontCodes = queryParams.frontCodes || [];
    const brand = filters.filter((item) => item.title === "品牌")?.[0];
    const type = filters.filter((item) => item.title === "素材类别")?.[0];
    const category = filters.filter((item) => item.title === "素材类型")?.[0];
    const channel = filters.filter((item) => item.title === "素材渠道")?.[0];
    const serviceTag = filters.filter((item) => item.title === "素材来源")?.[0];
    frontCodes = frontCodes.filter(
      (item) =>
        item.type !== brand?.type &&
        item.type !== type?.type &&
        item.type !== category?.type &&
        item.type !== channel?.type &&
        item.type !== serviceTag?.type,
    );
    //品牌
    const brandList = floatBoardData?.brandList?.filter((i) => i.selected);
    for (let brandItem of brandList) {
      let flag = false;
      for (let item of frontCodes) {
        if (item.type === brand.type && item.code === brandItem.code) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        frontCodes.push({ type: brand.type, code: brandItem.code });
      }
    }
    //类别
    const typeList = floatBoardData?.typeList?.filter((i) => i.selected);
    for (let typeItem of typeList) {
      let flag = false;
      for (let item of frontCodes) {
        if (item.type === type.type && item.code === typeItem.code) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        frontCodes.push({ type: type.type, code: typeItem.code });
      }
    }
    //类型
    const categoryList = floatBoardData?.categoryList?.filter((i) => i.selected);
    for (let categoryItem of categoryList) {
      let flag = false;
      for (let item of frontCodes) {
        if (item.type === category.type && item.code === categoryItem.code) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        frontCodes.push({ type: category.type, code: categoryItem.code });
      }
    }
    //渠道
    const channelList = floatBoardData?.channelList?.filter((i) => i.selected);
    for (let channelItem of channelList) {
      let flag = false;
      for (let item of frontCodes) {
        if (item.type === channel.type && item.code === channelItem.code) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        frontCodes.push({ type: channel.type, code: channelItem.code });
      }
    }
    //来源
    const serviceTagList = floatBoardData?.serviceTagList?.filter((i) => i.selected);
    for (let serviceTagItem of serviceTagList) {
      let flag = false;
      for (let item of frontCodes) {
        if (item.type === serviceTag.type && item.code === serviceTagItem.code) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        frontCodes.push({ type: serviceTag.type, code: serviceTagItem.code });
      }
    }
    queryParams.frontCodes = frontCodes;
    return queryParams;
  };

  const toggleBrand = async (item: any) => {
    aggsType.current = "brand";

    const target = {
      ...floatBoardData,
      brandList: floatBoardData?.brandList?.map((brand) => ({
        ...brand,
        selected: brand.code === item.code ? !item.selected : brand.selected,
      })),
    };
    setFloatBoardData(target);
  };

  const resetFilter = () => {
    setFloatBoardData((oldData) => {
      return {
        ...oldData,
        brandList: oldData?.brandList?.map((brand) => ({
          ...brand,
          selected: false,
        })),
        typeList: oldData?.typeList?.map((brand) => ({
          ...brand,
          selected: false,
        })),
        categoryList: oldData?.categoryList?.map((brand) => ({
          ...brand,
          selected: false,
        })),
        channelList: oldData?.channelList?.map((brand) => ({
          ...brand,
          selected: false,
        })),
        serviceTagList: oldData?.serviceTagList?.map((brand) => ({
          ...brand,
          selected: false,
        })),
      };
    });
  };

  return {
    floatBoardData,
    shownServiceTagList,
    toggleServiceTag,
    shownTypeList,
    toggleType,
    shownCategoryList,
    toggleCategory,
    shownChannelList,
    toggleChannel,
    shownBrandList,
    toggleBrand,
    resetBrand,
    selectedBrandList,
    constructParams,
    resetFilter,
    shownParms,
  };
};

export const [
  FilterOptionProvider,
  useFloatBoardData,
  useShownServiceTagList,
  useToggleServiceTag,
  useShownTypeList,
  useToggleType,
  useShownCategoryList,
  useToggleCategory,
  useShownChannelList,
  useToggleChannel,
  useShownBrandList,
  useToggleBrand,
  useResetBrand,
  useSelectedBrandList,
  useConstructParams,
  useResetFilter,
  useShownParms,
] = constate(
  useFilterOption,
  (value) => value.floatBoardData,
  (value) => value.shownServiceTagList,
  (value) => value.toggleServiceTag,
  (value) => value.shownTypeList,
  (value) => value.toggleType,
  (value) => value.shownCategoryList,
  (value) => value.toggleCategory,
  (value) => value.shownChannelList,
  (value) => value.toggleChannel,
  (value) => value.shownBrandList,
  (value) => value.toggleBrand,
  (value) => value.resetBrand,
  (value) => value.selectedBrandList,
  (value) => value.constructParams,
  (value) => value.resetFilter,
  (value) => value.shownParms,
);
