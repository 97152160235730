import { Image } from "antd-mobile";
import React, { useState } from "react";
import RedpackTop from "./images/redpackTop.png";
import styles from "./index.module.less";
import RedPackRule from "./red-pack-rule";

interface IProps {
  type?: string;
  index?: number;
}

const RedPackTop: React.FC<IProps> = ({ type, index }) => {
  const [visible, setVisible] = useState(false);
  const handleShowRule = () => {
    setVisible(true);
  };
  return (
    <>
      <div className={styles.redpackTop}>
        <div className={styles.redpackTopRule} onClick={handleShowRule}>
          规则
        </div>
        <Image src={RedpackTop} fit="cover" />
      </div>
      <RedPackRule visible={visible} visibleChange={setVisible} />
    </>
  );
};
export default RedPackTop;
