import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

type Props = {
  height: number;
  dataList: Array<any>;
  childRender: React.FC<any>;
  stickyDuration?: number;
  rollingDuration?: number;
  onIndexChange?: (index: number, item: any) => void;
};

const VSwiper: React.FC<Props> = ({
  dataList,
  height,
  childRender,
  stickyDuration = 1000,
  rollingDuration = 1000,
  onIndexChange,
}) => {
  const containerHeight = `${height / 50}rem`;
  const [dataIndex, setDataIndex] = useState<number>(0);
  const domRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!dataList || dataList.length === 0) {
      return;
    }
    setDataIndex(0);

    setTimeout(() => {
      domRef.current?.style.setProperty("transition", `top ${rollingDuration / 1000}s ease-in-out`);
      domRef.current?.style.setProperty("top", "-100%");
    }, stickyDuration);

    const timer = setInterval(() => {
      setDataIndex((index) => (index + 1) % dataList.length);
      domRef.current?.style.setProperty("top", "0");
      domRef.current?.style.setProperty("transition", "");
      setTimeout(() => {
        domRef.current?.style.setProperty(
          "transition",
          `top ${rollingDuration / 1000}s ease-in-out`,
        );
        domRef.current?.style.setProperty("top", "-100%");
      }, stickyDuration);
    }, stickyDuration + rollingDuration);

    return () => {
      clearInterval(timer);
    };
  }, [dataList]);

  useEffect(() => {
    onIndexChange && onIndexChange(dataIndex, dataList[dataIndex]);
  }, [dataIndex]);

  return (
    <div className={styles.container} style={{ height: containerHeight }}>
      <div ref={domRef} className={styles.pairContainer}>
        {dataList[dataIndex] && childRender(dataList[dataIndex])}
        {dataList[dataIndex] && childRender(dataList[(dataIndex + 1) % dataList.length])}
      </div>
    </div>
  );
};

export default VSwiper;
