import { getRecommendProductList, ProductCard as ProductCartData, ProductParams } from "@/api/home";
import { ReactComponent as GuideTop } from "@/assets/toTop.svg";
import ProductWaterfall from "@/components/product-waterfall";
import { getCookie, platform } from "@/utils/platform";
import { ProductModel } from "@akc/biz-api";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./ProductList.module.less";

type Props = {
  pageId: string;
  activityInfo: any;
};

const ProductList: React.FC<Props> = ({ pageId, activityInfo }) => {
  const [showGuideTop, setShowGuideTop] = useState(false);
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [productParams, setProductParams] = useState<any>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const loadMorePromise = useRef<any>();
  const nextPage = useRef<number>(0);
  const idsClick = useRef<string[]>([]);
  const lastActivitySpuCode = useRef<string>("");
  const [search] = useSearchParams();

  const trackData = {
    domain: "激励中心",
    module: "日日奖推荐商品",
    page_code: activityInfo.promoActivityId,
    resource_name: "日日奖推荐商品",
    resource_content: "商品",
    resource_type: "日日奖推荐商品",
    recommend_requestid: "recommend_requestid",
    previous_page_name: search.get("previous_page_name") ?? "未知",
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }
    const searchRequestId =
      (getCookie("msUserCode") ?? search.get("userId") ?? "userId") + Date.now();
    setProductParams({
      pageId,
      pageIndex: 1,
      pageSize: 10,
      scene: "daily_prize",
      searchRequestId,
    });
  }, []);

  useEffect(() => {
    if (!productParams) {
      return;
    }
    getRecommendProductList(productParams)
      .then((data) => {
        if (!data.result) {
          return;
        }
        setProductList((productList) => {
          return [
            ...(productList || []),
            ...(data?.result || []).map((item, index) => ({
              ...item,
              rank: productList.length + index + 1,
              trackData: {
                ...trackData,
                recommend_requestid: productParams.searchRequestId,
                resource_id: item.activitySpuId,
                click_activity_id: item.activityId,
                click_activity_spu_code: item.activitySpuId,
                click_sku_id: item.selectSkuId,
              },
            })),
          ];
        });
        if (data?.result?.length) {
          nextPage.current = data.pageIndex + 1;
          lastActivitySpuCode.current = data.result[data.result.length - 1]?.activitySpuId || "";
          loadMorePromise.current?.resolve();
          setHasNext(data.hasNext);
        } else {
          setHasNext(false);
        }
      })
      .catch(console.error);
  }, [productParams]);

  const loadMore = async () => {
    if (!productParams) {
      return;
    }
    const searchRequestId =
      (getCookie("msUserCode") ?? search.get("userId") ?? "userId") + Date.now();
    setProductParams({
      idsClick: idsClick.current.join(),
      lastActivitySpuCode: lastActivitySpuCode.current,
      pageId,
      pageIndex: nextPage.current,
      pageSize: 10,
      scene: "daily_prize",
      searchRequestId,
    });
    await new Promise((resolve, reject) => {
      loadMorePromise.current = { resolve, reject };
    });
    loadMorePromise.current = null;
  };

  // 置顶
  const observerDomRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!observerDomRef) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio === 0) {
          setShowGuideTop(true);
        }
        if (entry.intersectionRatio > 0) {
          setShowGuideTop(false);
        }
      },
      { threshold: [0, 1] },
    );
    observerDomRef.current && observer.observe(observerDomRef.current);

    return () => {
      observerDomRef.current && observer.unobserve(observerDomRef.current);
      observer.disconnect();
    };
  }, []);

  return (
    <div className={styles.block}>
      <div className={styles.feedTitle}>
        <img
          className={styles.feedTitleImg}
          src="https://akim-oss.aikucun.com/1574bddb75c78a6fd2251d61e2993b5146201319_1723120801946_87.png"
        />
      </div>
      <div className={styles.waterfallConatainer}>
        <ProductWaterfall
          productList={productList}
          hasNext={hasNext}
          loadMore={loadMore}
          onClickProduct={(product) => idsClick.current.push(product?.activitySpuId || "")}
          provideBackground={false}
          topRightLocationCode="H5_Feed_ProductList_ProductPicRight"
          titleFrontLocationCode="H5_Feed_ProductList_TitleFront"
          titleBottomLocationCode="H5_SmartRecommend_ProductTitleDown"
          trackData={trackData}
          btnClickName="resource_click"
        />
      </div>
      <div className={styles["observer-dom"]} ref={observerDomRef}></div>
      {productList.length > 10 && showGuideTop && (
        <GuideTop
          className={styles["guide-top"]}
          onClick={() => {
            document.getElementById("dailyRewardTopTitle")?.scrollIntoView({ behavior: "smooth" });
          }}
        />
      )}
    </div>
  );
};

export default ProductList;
