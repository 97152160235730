import Cookies from "js-cookie";
import { urlQueryObject } from "./url-parse";

// 公共 sessionStorage 存储方法,自动追加 [店铺ID] [用户ID] 前缀识别;
export function setSessionStorage(key: string, value: string) {
  const openId = Cookies.get("msOpenId");
  const staticQuery = urlQueryObject();
  try {
    sessionStorage.setItem(`${staticQuery.shopId}:${openId}:${key}`, value);
  } catch (err) {
    // console.log(err);
  }
}

export const getSessionStorage = (key: string) => {
  const staticQuery = urlQueryObject();
  const openId = Cookies.get("msOpenId");
  if (staticQuery.shopId && openId) {
    try {
      const value = sessionStorage.getItem(`${staticQuery.shopId}:${openId}:${key}`);
      if (value) {
        return value;
      }
    } catch (err) {
      // console.log(err);
    }
  }
  return "";
};
