import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ListWrapper from "./components/list";
import Nav from "./components/nav";
import styles from "./index.module.less";

const UserTrack: React.FC = () => {
  const track = Track();
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);
  const [search] = useSearchParams();

  const { isH5, isWxH5 } = platform;

  useEffect(() => {
    document.title = "浏览足迹";
    setTrackData({
      page_name: document.title,
    });
    track.track("$pageview", {
      page_name: document.title,
      previous_page_name: search.get("page_name") || "",
    });
  }, []);
  return (
    <>
      <div className={styles.usetTrackWrapper}>
        {isH5 || isWxH5 ? <Nav /> : <></>}
        <ListWrapper />
      </div>
    </>
  );
};

export default UserTrack;
