import React from "react";
import styles from "./SignCard.module.less";

type CardInfo = {
  day: string;
  dayStatus: number;
  isCanGetSignInAward: boolean;
  isGetSignInAward: boolean;
  isSignIn: boolean;
  statusDesc: string;
  dayOfSignInScopeText: string;
};

const imgs = {
  forget:
    "https://akim-oss.aikucun.com/761f22b2c1593d0bb87e0b606f990ba4974706de_1723464046960_99.png",
  isSignIn:
    "https://akim-oss.aikucun.com/92cfceb39d57d914ed8b14d0e37643de0797ae56_1723464047315_68.png",
  isGetSignInAward:
    "https://akim-oss.aikucun.com/0286dd552c9bea9a69ecb3759e7b94777635514b_1723464047589_48.png",
  isCanGetSignInAward:
    "https://akim-oss.aikucun.com/98fbc42faedc02492397cb5962ea3a3ffc0a9243_1723464047998_7.png",
};

const waitImgs = [
  "https://akim-oss.aikucun.com/fb644351560d8296fe6da332236b1f8d61b2828a_1723464652429_18.png",
  "https://akim-oss.aikucun.com/fe2ef495a1152561572949784c16bf23abb28057_1723464652735_47.png",
  "https://akim-oss.aikucun.com/827bfc458708f0b442009c9c9836f7e4b65557fb_1723464652993_33.png",
  "https://akim-oss.aikucun.com/64e095fe763fc62418378753f9402623bea9e227_1723464653490_67.png",
];

const SignCard: React.FC<{ info: CardInfo }> = ({ info }) => {
  const {
    isCanGetSignInAward,
    isGetSignInAward,
    isSignIn,
    statusDesc,
    dayStatus,
    dayOfSignInScopeText,
  } = info;
  let imgUrl = "";
  let text = "";
  let textColor = "#222222";
  let text2Color = "#222222";

  if (isSignIn && !isGetSignInAward && isCanGetSignInAward) {
    imgUrl = imgs["isCanGetSignInAward"];
    text = "待抽奖";
  } else if (isGetSignInAward) {
    imgUrl = imgs["isGetSignInAward"];
    text = "已领奖";
    textColor = "#FF4747";
  } else if (isSignIn) {
    imgUrl = imgs["isSignIn"];
    text = "已签到";
    textColor = "#FF4747";
  } else if (isCanGetSignInAward) {
    imgUrl = imgs["isCanGetSignInAward"];
    text = "签到抽奖";
  } else if (!isSignIn && dayStatus === 1) {
    imgUrl = imgs["forget"];
    text = "漏签";
  } else {
    imgUrl = waitImgs[Math.floor(Math.random() * 4)];
    text = "待签到";
  }
  if (dayStatus === 2) {
    text = "今天";
    text2Color = "#FF4747";
  }
  return (
    <div className={styles.item + " " + (dayOfSignInScopeText === "今天" && "todaySignCard")}>
      <div className={styles.card} style={{ backgroundImage: `url(${imgUrl})`, color: textColor }}>
        {statusDesc}
      </div>
      <div style={{ color: text2Color }}>{dayOfSignInScopeText}</div>
    </div>
  );
};

export default SignCard;
