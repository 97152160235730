import goBack from "@/assets/go_back.png";
import SideBar from "@/components/sidebar";
import { useCommonContext } from "@/utils/context/common";
import { MICRO_APP_BASEURL, platform } from "@/utils/platform";
import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import { default as React, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./ShowImages.module.less";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const ShowImages: React.FC<Props> = () => {
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId"); // R店铺ID
  const imageUrlsStr = search.get("imageUrls"); // 图片地址列表
  const title = search.get("title"); // 标题
  const { track } = useCommonContext();
  const shopCode = search.get("shopCode");

  document.title = title ?? "证件信息";

  const goBackOrHome = () => {
    if (history.length > 1) {
      history.back();
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  useEffect(() => {
    //页面埋点
    /* eslint-disable */
    if (track.track) {
      track.track("$pageview", {
        page_name: "证件信息页",
        previous_page_name: search.get("previous_page_name"),
        desc: shopCode,
      });
    }
    /* eslint-enable */
  }, []);

  const imageUrls = imageUrlsStr && imageUrlsStr.length > 0 ? imageUrlsStr.split(",") : undefined;

  return (
    <div className={styles.show_images}>
      {platform.isH5 && (
        <Image className={styles.go_back} src={goBack} onClick={() => goBackOrHome()} />
      )}
      {platform.isH5 && <SideBar />}
      {imageUrls &&
        imageUrls.length > 0 &&
        imageUrls.map((img, index) => (
          <div key={index} className={styles.img_container}>
            <Image src={img} />
          </div>
        ))}
    </div>
  );
};

export default inject("appStore")(observer(ShowImages));
