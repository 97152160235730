import AddPurchasePanel from "@/components/add-purchase-panel";
import AddToCartModal from "@/components/add-to-cart-modal";
import PosterSwipe from "@/components/poster-swiper";
import { getIdentity } from "@/utils/identity";
import { CouponBoard, JBRedPacketComp, ProductsBoard } from "@akc/mx-taro-h5-components";
import { inject, observer } from "mobx-react";
import React from "react";
import Filter from "./components/filter/filter";
import Product from "./components/Product";
import { useControl } from "./hook";
import styles from "./index.module.less";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const JointBill: React.FC<Props> = () => {
  const control = useControl();

  return (
    <div className={styles.pageBox} id={"jointBillPage"}>
      <img
        onClick={(e) => control.goBack(e)}
        className={styles.backIcon}
        src={
          "https://akim-oss.aikucun.com/94d3ba75a08f7989339e366ab8d5ba96e504601c_1702891098175_58.png"
        }
      />
      <div className={styles.topCard}>
        {/*优惠券*/}
        {control.jointType === "coupon" ? (
          <CouponBoard couponInfo={control.couponInfo} />
        ) : (
          <JBRedPacketComp className={styles.redPacketCard} balance={control.balance} />
        )}
      </div>
      {control.jointType === "coupon" && (
        <div className={styles.together}>
          {/*省心凑*/}
          {/*// const role = getIdentity();*/}
          {/*// // C:true R:false*/}
          {control.couponInfo.useRange !== 0 && control.topproductList.length > 0 && (
            <ProductsBoard
              role={getIdentity() ? "c" : "b"}
              productsList={control.topproductList}
              onClickProduct={control.onClickProduct}
              onClickAddBtn={control.onClickAddBtn}
              onClickShareBtn={control.onClickShareBtn}
            />
          )}
        </div>
      )}
      {/*商品区域*/}
      <div className={styles.bottomBox} id="bottomBox">
        {/*筛选框*/}
        {control.couponInfo?.useRange !== 0 && (
          <div className={styles.filter}>
            <Filter
              filter={control.filter}
              priceRangeList={control.priceRangeList}
              brandList={control.brandList}
              handleFilter={control.handleFilter}
              searchParams={control.searchParams}
              setSearchParams={control.setSearchParams}
              handleSearch={control.handleSearch}
            />
          </div>
        )}
        {/*商卡区域*/}
        <div className={styles.cardContainer}>
          <Product
            handleSearch={control.handleSearch}
            prodArr={control.productList}
            hasNext={control.hasNext}
            secondResourceClick={control.secondResourceClick}
          />
        </div>
      </div>
      {/*加购弹框*/}
      {control.showNewCartPanel ? (
        <AddPurchasePanel
          visible={control.cartModalVisible}
          product={control.modalProduct}
          close={() => {
            control.setCartModalVisible(false);
          }}
          confirm={control.changeCartNum}
        />
      ) : (
        <AddToCartModal
          visible={control.cartModalVisible}
          product={control.modalProduct}
          close={() => {
            control.setCartModalVisible(false);
          }}
          confirm={control.changeCartNum}
        />
      )}
      {/* 转发海报 */}
      <PosterSwipe
        show={control.showPoster}
        posterData={control.sharePosterData}
        customEditData={control.customEditData}
        isOTC={control.isOTC}
        shareType="PRODUCT"
        btnConfig={["copy"]}
        close={control.onClosePoster}
      ></PosterSwipe>
    </div>
  );
};

export default inject("appStore")(observer(JointBill));
