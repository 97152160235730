import Track from "@/utils/track";
const track = Track();

export const resource_expose = (item: any, index: number) => {
  const trackParams = {
    page_name: "我的",
    domain: "我的",
    module: "专属推荐",
    resource_type: "专属推荐",
    resource_content: "商品",
    resource_name: "专属推荐",
    resource_id: item.activitySpuId,
    resource_rank: index + 1,
    recommend_requestid: item.searchRequestId,
    activity_id: item.activityId,
    click_activity_id: item.activityId,
    activity_spu_code: item.activitySpuId,
    sku_id: item.selectSkuId,
    product_model: item?.activityType === 4 ? "普通商品" : "档期商品",
  };
  track.track("resource_expose", trackParams);
};

export const resource_click = (item, type, index) => {
  const trackParams = {
    page_name: "我的",
    previous_page_name: "无",
    domain: "专属推荐",
    module: "专属推荐",
    btn_name: "商品点击",
    resource_type: "专属推荐",
    resource_content: "商品",
    resource_name: "专属推荐",
    resource_id: item.activitySpuId,
    resource_rank: index + 1,
    recommend_requestid: item.searchRequestId,
    activity_id: item.activityId,
    click_activity_id: item.activityId,
    click_activity_spu_code: item.activitySpuId,
    click_sku_id: item.selectSkuId,
    product_model: item?.activityType === 4 ? "普通商品" : "档期商品",
    price: item.skuCurrentPriceInfo.sowingPrice,
  };
  if (type === "detail") {
    delete trackParams.price;
  }

  track.track("resource_click", trackParams);
};

export const btn_click = (module: string, btn_name: string) => {
  const params = {
    page_name: "我的",
    previous_page_name: "无",
    domain: "我的",
    module,
    btn_name,
  };
  track.track("btn_click", params);
};

export const page_view = () => {
  const params = {
    page_name: "我的",
    previous_page_name: "无",
    domain: "我的",
  };
  track.track("$page_view", params);
};
