import { queryBrandList } from "@/api/brand";
import { ActivityTaskParams } from "@/api/rewardCenter";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import { debounce, isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchContent from "./search-content";
import SearchHeader from "./search-header";
import SearchSuggestKeys from "./search-suggest-keys";
import styles from "./search.module.less";
import { SearchProvider, useSearchContext } from "./useSearch";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const Search: React.FC<Props> = () => {
  const track = Track();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const [suggestList, setSuggestList] = useState<any[]>([]);
  const { list, loading, hasMore, loadMore } = useSearchContext();

  useEffect(() => {
    storeRem();
  }, []);

  const goHome = useCallback(() => {
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/index?shopId=${search.get("shopId")}&channel=h5Mall&distributorId=${search.get("distributorId")}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/#/index?shopId=${search.get("shopId")}&channel=h5Mall`);
    }
  }, [search]);

  const goBack = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          goHome();
        }
      }, 500);
    } else {
      goHome();
    }
  };

  const querySuggestList = debounce(async (text) => {
    if (isEmpty(text)) {
      return;
    }
    const data = await queryBrandList({
      searchParams: {
        isNeedSpuIds: false,
        keyword: text,
        pageNum: 1,
        pageSize: 10,
      },
    });
    const list: any = [];
    if (data?.result && data.result.length > 0) {
      for (const item of data.result) {
        const i = {
          node: (
            <>
              <div className={styles.brandItem}>
                <div className={styles.brandItemLogo}>
                  <img src={item.brandLogo} alt="" />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      item.brandName?.replace(
                        new RegExp(`(${text})`, "g"),
                        `<span style="color:#ff0000">${text}</span>`,
                      ) ?? "",
                  }}
                ></div>
              </div>
            </>
          ),
          data: item,
        };
        list.push(i);
      }
    }
    setSuggestList(list || []);
  }, 500);


  return (
    <SearchProvider>
      <div className={styles.search}>
        <SearchHeader
          onBack={goBack}
          placeholder="请输入品牌信息"
          querySuggestList={querySuggestList}
        ></SearchHeader>
        <SearchSuggestKeys suggestList={suggestList} />
        <SearchContent
          loading={loading}
          list={list}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </div>
    </SearchProvider>
  );
};

export default Search;
