import { newBornZoneResource } from "@/api/home";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./CouponBoardV1.module.less";

const CouponBoardV1 = () => {
  const [info, setInfo] = useState<any>({});
  const { navigate } = useNavigation();

  const queryInfo = async () => {
    let res = await newBornZoneResource();
    setInfo(res);
  };

  const goNewWrap = () => {
    // 去旧版新人专区落地页
    navigate({
      h5Options: {
        url: `/#/newPersonPage?configId=${info.configId}`,
      },
    });
  };

  useEffect(() => {
    queryInfo();
  }, []);

  if (!info?.productList?.length) {
    return <></>;
  }

  return (
    <div className={styles.newPersonCon}>
      <div style={{ backgroundColor: info.bgColor }}>
        <ul
          className={styles.newPersonWrap}
          style={{ backgroundImage: `url(${info.bgImg}` }}
          onClick={goNewWrap}
        >
          {info.productList.map((item) => {
            // 默认取第一张图片;
            const imgOriginalUrl = item.productPicUrl?.split(",")?.[0];
            const imgUrl = imgOriginalUrl
              ? `${imgOriginalUrl}?x-oss-process=image/resize,m_mfit,w_150`
              : "";

            return (
              <li key={item.imgUrl}>
                <img src={imgUrl} />
                {item.sellOut && <div className={styles.sellout}>售罄</div>}
                <p className={styles.newTag}>新人精选</p>
                <p className={styles.price}>
                  <span className={styles.unit}>¥</span>
                  <span>{item.price}</span>
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CouponBoardV1;
