import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { getPlatform } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import React, { useContext, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import CouponsBoard from "./components/CouponsBoard";
import ProductsBoard from "./components/ProductsBoard";
import ProductWaterfall from "./components/ProductWaterfall";
import useCheckBind from "./hooks/useCheckBind";
import useCouponList from "./hooks/useCouponList";
import styles from "./index.module.less";

const NewPerson: React.FC = () => {
  const { couponList, productList, atmosphereList, updateCouponList } = useCouponList();
  const { isAuthed, isBind, isVisitor, updateBindStatus } = useCheckBind();
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);
  const { navigate2H5 } = useNavigation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);
  const { isMini } = getPlatform();
  const goHome = () => {
    const options: IOptions = {
      path: "/mx-shop-micro/home",
      is_micro_app: true,
      is_replace: true,
    };
    navigate2H5(options);
  };
  useEffect(() => {
    document.title = "新人专享";
    const track = Track();
    const params = {
      page_name: "品牌券新人专区",
      domain: "新人专区",
      previous_page_name: "首页",
      tab_name: "全部",
      tab_rank: 0,
    };
    setTrackData(params);
    track.track("$pageview", params);
  }, []);

  useActivate(() => {
    if (isVisitor) {
      updateBindStatus();
    }
  });

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };

  const bindPhone = () => {
    if (isBind) {
      return;
    }
    if (isMini) {
      window.wx.miniProgram.navigateTo({
        url: "/pages/auth-page/auth-page",
      });
    } else {
      if (!isAuthed) {
        toAuth();
        return;
      }
      setBindPhonePopupVisible(true);
    }
  };

  return (
    <>
      <div className={styles.wrap} id="newPersionContainer">
        {!isMini && <div className={styles.fixedBackIcon} onClick={goHome}></div>}
        <div className={styles.floor}>
          <CouponsBoard
            list={couponList}
            updateCouponList={updateCouponList}
            isVisitor={isVisitor}
            toBind={bindPhone}
          />
        </div>
        <div className={styles.floor}>
          <ProductsBoard list={productList} atmosphereList={atmosphereList} />
        </div>
        <div className={styles.floor + " " + styles.full}>
          <ProductWaterfall isVisitor={isVisitor} toBind={bindPhone} />
        </div>
      </div>
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={updateBindStatus}
        extra={{ bindStatus: 3 }}
      />
    </>
  );
};

export default NewPerson;
