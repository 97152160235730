import { getBaseurl } from "@/utils/url-parse";
import { ProductModel } from "@akc/biz-api";
import { Request } from "../request";

export type ActivityParams = {
  pageId: string;
  searchParams: {
    pageNum: number;
    pageSize: number;
    activityStatus: number;
    activityTagNo: string;
    sortModel?: string;
    sortType?: number;
    isShare: boolean;
    seeHide?: boolean;
    redPackageType?: number;
  };
  storeIdsClick: string;
};

export type ActivityItem = {
  activityCardVOList?: Array<any>;
  uniqueId: string;
  activityId: string;
  activityType: number;
  activitySpuId: string;
  selectSkuId: string;
  sysTime: string;
  activityStatus: number;
  activitySimpleVO: {
    name: string;
    description: string;
    beginTimeStr: string;
    endTimeStr: string;
    merchantCode: string;
    merchantShopCode: string;
    status: number;
    presale: number;
    samePrice: number;
    isExpire: boolean;
    online: boolean;
  };
  spuId: string;
  title: string;
  firstPropertyValue: string;
  secondPropertyValue: string;
  merStyleNo: string;
  spuSellOut: boolean;
  spuOnline: boolean;
  spuIsExpired: boolean;
  sizeUrl?: string;
  salePropertyFirstList: Array<{
    name: string;
    value: string;
    picList: string[];
    sellOut: boolean;
  }>;
  salePropertySecondList: Array<{
    name: string;
    value: string;
    picList: string[];
    sellOut: boolean;
  }>;
  spuCategoryInfoVO: {
    categoryCode: string;
    categoryName: string;
    secondCategoryCode: string;
    secondCategoryName: string;
    firstCategoryCode: string;
    firstCategoryName: string;
  };
  merchantCode: string;
  videoInfo?: {
    videoUrl: string;
    coverUrl: string;
  };
  skuMainImageUrls: string[];
  skuExtendInfoVO: {
    buyDirectModel: boolean;
    buyShoppingCartModel: boolean;
    onlyDirectModel: boolean;
    isExchangeGoods: boolean;
    productNum: string;
    productType: string;
    isCrossBorder: boolean;
    isVirtual: boolean;
    supportInvoice: number;
    isRuralRevitalization: boolean;
    allowDeliver: boolean;
    onlyXDBuy: boolean;
    isFollow: boolean;
    showSkuEncryptionCode: boolean;
    minQuantity: number;
  };
  skuSamePriceFlag: boolean;
  identicalCommission: boolean;
  showColorPanel: boolean;
  showSkc: boolean;
  skuCurrentPriceInfo: {
    tagPrice: number;
    tagPriceDesc: string;
    sowingPrice: number;
    marketingPrice: number;
    discountAmount: number;
    selfPurchasePrice: number;
    selfPurchaseMarketingPrice: number;
    cheapAmount: number;
    usePromotion: boolean;
    promotionType: string;
    promotionTypeName: string;
    promotionId: string;
    promotionTimeStatus: number;
    promotionStartTime: string;
    promotionEndTime: string;
  };
  statisticsInfo: any;
  labelLocationVOList: Array<{
    locationCode: string;
    labelInfoVOList: Array<{
      labelUUID: string;
      labelCode: string;
      contentType: string;
      labelText?: string;
      labelIconUrl: string;
      labelIconWidth: number;
      labelIconHeight: number;
      groupKey: string;
      drillDown: boolean;
      promoActivityId?: string;
    }>;
  }>;
};

export type ActivityListData = {
  pageIndex: number;
  pageSize: number;
  currentPageSize: number;
  totalCount: number;
  pages: number;
  result?: ActivityItem[];
  extra: {
    searchRequestId: string;
  };
  hasNext: boolean;
};

export async function getActivityList(params: ActivityParams): Promise<ActivityListData> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/list`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export type RescoureData = {
  categoryBannerList: Array<{
    id: number;
    imgName: string;
    imgUrl: string;
    resourceType: number;
    resourceLocation: number;
    capsuleType: number;
    busiShowLocation: number;
    userType: number;
    categoryId: string;
    ringshopLinkUrl: string;
    smallRoutineLinkUrl: string;
    skipType: number;
    imgModuleId: number;
    sortValue: number;
    location: number;
    pluginCardType: number;
    pluginCardShowType: number;
    directShowType: number;
    publishType: number;
    appLinkUrl: string;
    newUserType: string;
  }>;
  diamondList: Array<{
    id: number;
    imgName: string;
    imgUrl: string;
    resourceType: number;
    resourceLocation: number;
    capsuleType: number;
    busiShowLocation: number;
    userType: number;
    categoryId: string;
    ringshopLinkUrl: string;
    smallRoutineLinkUrl: string;
    skipType: number;
    imgModuleId: number;
    sortValue: number;
    location: number;
    pluginCardType: number;
    pluginCardShowType: number;
    directShowType: number;
    publishType: number;
    appLinkUrl: string;
    newUserType: string;
    userLevels: string;
  }>;
  smallSuperBannerList: Array<{
    id: number;
    imgName: string;
    imgUrl: string;
    resourceType: number;
    resourceLocation: number;
    capsuleType: number;
    busiShowLocation: number;
    userType: number;
    categoryId: string;
    ringshopLinkUrl: string;
    smallRoutineLinkUrl: string;
    skipType: number;
    imgModuleId: number;
    sortValue: number;
    location: number;
    pluginCardType: number;
    pluginCardShowType: number;
    directShowType: number;
    publishType: number;
    appLinkUrl: string;
    newUserType: string;
  }>;
  bigSuperBannerList: any[];
  conjoinedSuperBannerList: any[];
  appIndexSuperBannerList: any[];
  imgComponentList: any[];
  resourceSortConfig: any;
  moduleId: number;
  moduleType: number;
};

export async function getCategoryResource(categoryId: string): Promise<RescoureData> {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/index/category/resource`,
    method: "get",
    params: {
      categoryId,
      channel: 2,
    },
  });
  return res?.data;
}

export type ProductParams = {
  idsClick: string;
  lastActivitySpuCode: string;
  pageId: string;
  pageIndex: number;
  pageSize: number;
  scene: string;
  searchRequestId: string | number;
  activitySpuIds?: string[];
  categoryCodeList?: string[];
};

export type ProductCard = {
  cardType: string;
  order: number;
  product: ProductModel;
};

export type ProductListData = {
  hasNext: boolean;
  pageIndex: number;
  pageSize: number;
  pages: number;
  result: ProductModel[];
};

export async function getRecommendProductList(params?: any): Promise<ProductListData> {
  let url = `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/recommend`;
  const { shopBizCode, distributorId } = params;
  if (shopBizCode) {
    url = `${url}?shopBizCode=${shopBizCode}`;
  }
  if (distributorId) {
    if (url.includes("?")) {
      url = `${url}&distributorId=${distributorId}`;
    } else {
      url = `${url}?distributorId=${distributorId}`;
    }
  }

  const res = await Request({
    url,
    method: "post",
    data: params,
    headers: {
      ["app-request-id"]: params.searchRequestId,
    },
  });
  return res?.data;
}

export async function getResource(params) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/resource/index`,
    method: "get",
    params,
  });
  // 6图片组件 7商品橱窗 8金刚位 9今日必卖 10品牌聚合 11新人券
  return res?.data;
}

export async function getHomeAtmosphere() {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/atmosphere/list?isBuyToday=true&type=1`,
    method: "get",
  });
  return res?.data;
}

export async function getDarkWords() {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
  });
  return res?.data;
}

/**
 * 用户关注品牌列表-5个
 */
export async function getFollowBrands() {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brandFocus/focusBrandSimple`,
    method: "get",
  });
  return res?.data;
}

export type ShopInfo = {
  shopLogoUrl: string;
  shopName: string;
};

export async function getShopInfo(
  shopId: string,
  distributorId: string | undefined | null,
): Promise<ShopInfo> {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xdwxh5/seller/info/${shopId}`,
    method: "get",
    params: {
      distributorId,
    },
  });
  return res?.data;
}

export async function apiGetWaitTotalPoints() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/points/waitTotalPoints`,
    method: "post",
  });
  return res?.data;
}

export async function findTaskList4HomePage() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/task/findTaskList4HomePage`,
    method: "post",
  });
  return res?.data;
}

export async function getIntegralMallUrl() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/integralMallUrl`,
    method: "get",
  });
  return res?.data;
}

export async function checkFollowWeChat(params) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/task/checkFollowWeChat`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function signJF(params) {
  const res = await Request({
    url: `${getBaseurl()}/api/spweb/integral/gold/task/signIn`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function newBornZoneResource() {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/index/newBornZone/resource`,
    method: "get",
    params: { channel: 2 },
  });
  return res?.data;
}

export async function queryCategoryTab4Index() {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/category/queryCategoryTab4Index`,
    method: "post",
  });
  return res?.data;
}
