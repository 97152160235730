import { MICRO_APP_BASEURL } from "@/utils/platform";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import { debounce, isEmpty } from "lodash";
import { inject, observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./search.module.less";

import { queryBrandList } from "@/api/brand";
import { ActivityItem } from "@/api/home";
import { ActivityTaskParams } from "@/api/rewardCenter";
import useRewardCenterData from "../../hooks/useRewardCenterData";
import SearchContent from "./search-content";
import SearchHeader from "./search-header";
import SearchHistoryKeys from "./search-history-keys";
import SearchSuggestKeys from "./search-suggest-keys";
import { SearchProvider } from "./useSearch";

interface Props {
  appStore?: any;
  [key: string]: any;
}

const Search: React.FC<Props> = () => {
  const track = Track();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const [suggestList, setSuggestList] = useState<any[]>([]);
  const [query, setQuery] = useState<ActivityTaskParams>({
    searchType: 1,
    brandId: undefined,
  });
  const { list, couponList, activityList, loading, hasMore, queryList, loadMore } = useRewardCenterData({ query, type: "Search" });

  //获取tab下的列表
  const queryTabList = async (params?: ActivityTaskParams) => {
    console.log("queryTabList", params);
    const data = {
      ...query,
      searchType: (params?.searchType || query.searchType) ?? 1,
      brandId: params?.brandId || query.brandId,
    };
    setQuery(data);
  };
  const handlTabChange = (value) => {
    queryTabList({
      pageIndex: 1,
      searchType: value,
      brandId: query.brandId,
    });
  };

  useEffect(() => {
    storeRem();
  }, []);

  const goHome = useCallback(() => {
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${search.get("shopId")}&channel=h5Mall&distributorId=${search.get("distributorId")}`,
      );
    } else {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${search.get("shopId")}&channel=h5Mall`,
      );
    }
  }, [search]);

  const goBack = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          goHome();
        }
      }, 500);
    } else {
      goHome();
    }
  };

  const querySuggestList = debounce(async (text) => {
    if (isEmpty(text)) {
      return;
    }
    const data = await queryBrandList({
      searchParams: {
        isNeedSpuIds: false,
        keyword: text,
        pageNum: 1,
        pageSize: 10,
      },
    });
    const list: any = [];
    if (data?.result && data.result.length > 0) {
      for (const item of data.result) {
        const i = {
          node: (
            <>
              <div className={styles.brandItem}>
                <div className={styles.brandItemLogo}>
                  <img src={item.brandLogo} alt="" />
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      item.brandName?.replace(
                        new RegExp(`(${text})`, "g"),
                        `<span style="color:#ff0000">${text}</span>`,
                      ) ?? "",
                  }}
                ></div>
              </div>
            </>
          ),
          data: item,
        };
        list.push(i);
      }
    }
    setSuggestList(list || []);
  }, 500);

  // 默认搜索（自动取关健词列表第一项进行搜索）
  const defaultSearch = (key?: string) => {
    // if (suggestList?.length) {
    //   setQuery({
    //     ...query,
    //     brandId: suggestList?.[0]?.data?.brandId,
    //   });
    // }
    setQuery({
      ...query,
      searchKeyword: key,
      brandId: ""
      // brandId: suggestList?.[0]?.data?.brandId,
    });
  };

  return (
    <SearchProvider queryContentList={queryTabList}>
      <div className={styles.search}>
        <SearchHeader
          onBack={goBack}
          placeholder="请输入活动名称或品牌名称"
          querySuggestList={querySuggestList}
          onSearch={defaultSearch}
        ></SearchHeader>
        <SearchSuggestKeys suggestList={suggestList} />
        {/* <SearchHistoryKeys /> */}
        <SearchContent
          loading={loading}
          activeTab={query.searchType}
          list={query.searchType === 3 ? couponList : query.searchType === 4 ? activityList : list}
          hasMore={hasMore}
          loadMore={loadMore}
          onChange={handlTabChange}
        />
      </div>
    </SearchProvider>
  );
};

export default Search;
// inject("appStore")(observer(Search));
