import { focusList, subscribe, unSubscribe } from "@/api/market";
import goBack from "@/assets/go_back.png";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { storeRem } from "@/utils/rem";
import Track from "@/utils/track";
import { FavoriteShopCardComp as StoreFocusNormal } from "@akc/mx-taro-h5-components";
import { Image, InfiniteScroll, Toast } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserRole } from "../utils/identity";
import styles from "./focus.module.less";
interface Props {
  appStore?: any;
  [key: string]: any;
}

const Home: React.FC<Props> = () => {
  const track = Track();
  const navigater = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const [list, setList] = useState<any>([]);
  const [pageNum, setPageNum] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <div className={styles.loadingMore}>
            <Image src={loadingPng} width={pxtorem(20)}></Image>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {list?.length > 0 && (
              <>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
              </>
            )}
          </div>
        )}
      </>
    );
  };

  //获取关注的店
  const queryFocus = async () => {
    if (queryLoading) {
      return;
    }
    setQueryLoading(true);
    const res: any = await focusList({
      pageId: "H5_Shop",
      searchParams: {
        pageNum: pageNum,
        pageSize: 10,
      },
    });
    let array = res?.data?.result || [];
    for (const item of array) {
      item.isFavorited = true;
    }
    if (list.length > 0) {
      array = list.concat(array);
    }
    setList(array);
    setPageNum(pageNum + 1);
    setHasMore(res?.data?.hasNext ?? false);
    setQueryLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    storeRem();
    // unmountApp("h5shop", {
    //   destroy: true,
    // });
    document.title = "关注好店";
  }, []);
  useActivate(() => {
    console.log("in useActivate");
    document.title = "关注好店";
  });

  useEffect(() => {
    queryFocus();
    track.track("$pageview", {
      page_name: "关注好店",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
  }, []);

  const subscribeItem = async (shopCode, isFavorited) => {
    const array = [...list];
    if (isFavorited) {
      const res = await unSubscribe({
        bizType: "MERCHANT_SHOP",
        bizNo: shopCode,
      });
      if (res) {
        Toast.show({
          content: "操作成功",
        });
        for (const item of array) {
          if (item.shopCode === shopCode) {
            item.isFavorited = !item.isFavorited;
          }
        }
      }
    } else {
      const res = await subscribe({
        bizType: "MERCHANT_SHOP",
        bizNo: shopCode,
      });
      if (res) {
        Toast.show({
          content: "操作成功",
        });
        for (const item of array) {
          if (item.shopCode === shopCode) {
            item.isFavorited = !item.isFavorited;
          }
        }
      }
    }
    setList(array);
  };

  const onAction = (e) => {
    console.log("点击店铺----", e);
    track.track("btn_click", {
      page_name: "关注好店",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "关注列表",
      btn_name: "店铺详情",
      merchant_shop_code: e.shopCode,
    });
    if (search.get("distributorId")) {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&distributorId=${search.get(
          "distributorId",
        )}&shopId=${search.get("shopId")}&page_name=${encodeURIComponent(
          "关注好店",
        )}&productType=1`,
      );
    } else {
      navigater(
        `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
          "shopId",
        )}&page_name=${encodeURIComponent("关注好店")}&productType=1`,
      );
    }
  };

  const onFavoriteAction = (item, isFavorited) => {
    console.log("关注----", item, isFavorited);
    subscribeItem(item.shopCode, isFavorited);
    track.track("btn_click", {
      page_name: "关注好店",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "关注列表",
      btn_name: isFavorited ? "取消关注" : "关注",
      merchant_shop_code: item.shopCode,
    });
  };

  const goWhere = () => {
    track.track("btn_click", {
      page_name: "关注好店",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "关注列表",
      btn_name: "去逛逛",
    });
    if (search.get("distributorId")) {
      navigater(
        `/mx-shop-micro/market?distributorId=${search.get("distributorId")}&shopId=${search.get(
          "shopId",
        )}&page_name=${encodeURIComponent("关注好店")}`,
      );
    } else {
      navigater(
        `/mx-shop-micro/market?shopId=${search.get("shopId")}&page_name=${encodeURIComponent(
          "关注好店",
        )}`,
      );
    }
  };

  const loadMore = async () => {
    if (hasMore) {
      queryFocus();
    }
  };

  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  return (
    <div className={styles.focus}>
      <div className={styles.back} id="backIcon">
        <Image className={styles.backIcon} src={goBack} onClick={() => goBackOrHome()}></Image>
      </div>
      {list.map((item, index) => {
        return (
          <div className={styles.focusItem} key={index}>
            <StoreFocusNormal
              shopInfo={item}
              isFavorited={item.isFavorited}
              tagLocationCode="H5_Shop_ShopNameDown"
              onAction={onAction}
              onFavoriteAction={onFavoriteAction}
              index={index}
              role={getUserRole()}
              terminal="h5"
            ></StoreFocusNormal>
            <div className={styles.line}></div>
          </div>
        );
      })}
      {list.length <= 0 && !loading && (
        <div className={styles.empty}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/ec63eb18e254f5a136c9e2406e2595e1d84aa8f6_1693451945207_20.png"
          ></img>
          <div className={styles.text}>未找到关注好店</div>
          <div className={styles.btn} onClick={() => goWhere()}>
            去逛逛
          </div>
        </div>
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
        <InfiniteScrollContent hasMore={hasMore} />
      </InfiniteScroll>
    </div>
  );
};

export default inject("appStore")(observer(Home));
