import { getUserRole } from "@/utils/identity";
import { platform } from "@/utils/platform";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { ShopCard } from "@akc/mx-taro-h5-components";
import { CenterPopup, Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./DrawResultPop.module.less";
import PrizeCard from "./PrizeCard";
import ProductReward from "./ProductReward";

const Products = ({ list }) => {
  const { navigate2Product } = useNavigation();
  const toProductDetail = (product, index) => {
    navigate2Product(
      {
        activitySpuId: product.activitySpuId,
        activityId: product.activityId,
        selectSkuId: product.selectSkuId,
      },
      {
        type: "日日奖积分商品",
        targetId: product.activitySpuId,
        resourceRank: index + 1,
      },
    );
  };
  return (
    <div className={styles.products}>
      {list &&
        list.map((product, index) => {
          const { skuMainImageUrls, skuCurrentPriceInfo, title, skuExtendInfoVO } = product;
          return (
            <div
              key={index}
              className={styles.productCard}
              onClick={() => toProductDetail(product, index)}
            >
              <div className={styles.productPicWrap}>
                <img className={styles.productPic} src={skuMainImageUrls?.[0]} />
                {skuCurrentPriceInfo?.commission && (
                  <div className={styles.commWrap}>
                    {platform.isApp ? "省赚" : platform.isMini ? "饷" : "赚"}¥
                    {skuCurrentPriceInfo.commission}
                    {skuCurrentPriceInfo.commissionAddition > 0 ? "+10" : ""}
                  </div>
                )}
              </div>
              <div className={styles.title}>{title}</div>
              {/* <div className={styles.priceWrap}>
            <span className={styles.price}>559</span>
            <span className={styles.tagPrice}>666</span>
          </div> */}
              <div className={styles.pointWrap}>
                <div className={styles.point}>{skuExtendInfoVO?.creditAmount}</div>
                <span>积分</span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Content = ({ awardInfo }) => {
  const { awardType, pointsBalance, merchantShopList } = awardInfo || {};
  const { navigate2Store, navigate2Product } = useNavigation();
  const onShopAction = (e) => {
    navigate2Store({
      merchantShopCode: e.shopCode,
      shopCode: e.shopCode,
      page_name: encodeURIComponent("日日奖"),
      productType: 1,
    });
  };
  const onProductAction = (product) => {
    navigate2Product(
      {
        activitySpuId: product.activitySpuId,
        activityId: product.activityId,
        selectSkuId: product.selectSkuId,
      },
      {
        type: "日日奖积分商品",
        targetId: product.activitySpuId,
      },
    );
  };
  if (awardType === "RED_PACKET") {
    return (
      merchantShopList?.[0] && (
        <ShopCard
          nameFrontTagLocationCode={"_Shop_ShopNameFront"}
          mainLocationCode={"_Shop_ShopNameDown"}
          shopInfo={merchantShopList[0]}
          index={0}
          productGrid={true}
          onShopAction={(e) => onShopAction(e)}
          onProductAction={(e) => onProductAction(e)}
          // onLiveAction={(e) => onLiveAction(e, index)}
          // labelLocationVOList={item?.labelLocationVOList}
          profitTag="赚"
          role={getUserRole()}
          // terminal="h5"
        />
      )
    );
  } else if (awardType === "POINTS") {
    return (
      <>
        <div className={styles.pointsContHeader}>为您推荐积分商品</div>
        <Products list={awardInfo.productList} />
      </>
    );
  } else if (awardType === "COUPON") {
    return merchantShopList?.[0] ? (
      <ShopCard
        nameFrontTagLocationCode={"_Shop_ShopNameFront"}
        mainLocationCode={"_Shop_ShopNameDown"}
        shopInfo={merchantShopList[0]}
        index={0}
        productGrid={true}
        onShopAction={(e) => onShopAction(e)}
        onProductAction={(e) => onProductAction(e)}
        profitTag="赚"
        role={getUserRole()}
      />
    ) : (
      <div className={styles.couponCont}>
        <img
          className={styles.pic}
          src="https://akim-oss.aikucun.com/7d7116e23efef7292cad5e6f033d9a962708228c_1723729119181_10.png"
        />
        <div>优惠券已到账，快去使用优惠券吧~</div>
      </div>
    );
  }
};

const NoPrizePop: React.FC<{
  visible: boolean;
  onClose: any;
  handleShare: any;
  setRefreshTimes: any;
}> = ({ visible, onClose, handleShare, setRefreshTimes }) => {
  return (
    <CenterPopup visible={visible} destroyOnClose maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}>
      <div className={styles.noPriczeWrap}>
        <div className={styles.title}>很遗憾，暂未中奖</div>
        <div className={styles.pic}></div>
        <div className={styles.content}>感谢您的参与，邀请好友一起试试吧~</div>
        <div className={styles.btn} onClick={handleShare}>
          邀请好友一起参与
        </div>
        <div
          className={styles.closeBtn}
          onClick={() => {
            onClose();
            setRefreshTimes((n) => n + 1);
          }}
        ></div>
      </div>
    </CenterPopup>
  );
};

const DrawResultPop: React.FC<{
  visible: boolean;
  onClose: any;
  result: any;
  handleShare: any;
  onOrderSuccess?: any;
  setRefreshTimes?: any;
}> = ({
  visible,
  onClose,
  result,
  handleShare,
  onOrderSuccess = () => true,
  setRefreshTimes = () => true,
}) => {
  if (!result) {
    return null;
  }
  if (
    result &&
    !document.getElementById("dailyRewardContainer") &&
    !document.getElementById("page") &&
    result?.awardType !== "PRODUCT" &&
    visible
  ) {
    onClose();
    setRefreshTimes((n) => n + 1);
  }
  if (result?.awardType === "NOTHING") {
    return (
      <NoPrizePop
        visible={visible}
        onClose={onClose}
        setRefreshTimes={setRefreshTimes}
        handleShare={() => {
          onClose();
          handleShare();
          setRefreshTimes((n) => n + 1);
        }}
      />
    );
  }
  return (
    <>
      <Popup
        visible={visible}
        getContainer={() =>
          document.getElementById("dailyRewardContainer") ||
          (document.getElementById("page") as HTMLElement)
        }
        bodyStyle={{
          backgroundColor: "transparent",
        }}
        destroyOnClose={true}
        maskStyle={{ background: "rgba(0, 0, 0, 0.8)" }}
      >
        <div className={styles.container}>
          <div className={styles.header + " " + styles[result?.awardType]}>
            {result?.awardType === "RED_PACKET" && (
              <div className={styles.title}>{result.redPacketAward?.hotBrandInfo?.brandName}</div>
            )}
          </div>
          <div
            className={styles.closeBtn}
            onClick={() => {
              onClose();
              setRefreshTimes((n) => n + 1);
            }}
          ></div>
          <div
            className={
              styles.wrap + " " + (result?.awardType === "PRODUCT" ? styles.wrapProduct : "")
            }
          >
            {result?.awardType === "PRODUCT" ? (
              <ProductReward
                activitySpuId={result?.activitySpuId}
                rewardActivityId={result?.lotteryActivityId}
                rewardRecordId={result?.lotteryAwardResultId}
                onSubmitCallback={() => {
                  onOrderSuccess();
                  onClose();
                }}
              />
            ) : (
              <>
                <PrizeCard info={result} />
                <div className={styles.main}>
                  <Content awardInfo={result} />
                </div>
                <div
                  className={styles.bottomBtn}
                  onClick={() => {
                    onClose();
                    handleShare("dailyRewardGift", result);
                    setRefreshTimes((n) => n + 1);
                  }}
                >
                  邀请好友参与
                </div>
              </>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default DrawResultPop;
