import AKJS from "@akc/akjs";
import { platform } from "./platform";

// 设置右上角按钮
export type ButtonOptions = {
  iconUrl?: string;
  title?: string; // iconUrl 和 title 二选一，iconUrl 优先
  titleColor?: string;
  titleSize?: number;
  onclick?: () => void;
  // callback: string; // 挂在window上的函数名，点击之后会通过window[callback]的方式触发回调
};

export const setTopRightButtons = (buttons: ButtonOptions[]) => {
  if (platform.isApp) {
    AKJS.ready().then(() => {
      AKJS.action
        .dispatch("event.navigationBar.config.rightBtns", {
          btns: buttons,
        })
        .catch((error) => {
          console.error("设置右上角分享按钮失败:", error);
        });
    });
  }
};
