import { ProductModel, ProductSKUModel } from "@/models/product";
import { Swiper } from "antd-mobile";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.less";

interface SizePopupProps {
  product: ProductModel;
  show: boolean;
  needBackTop?: boolean;
  lockScroll?: boolean;
  showPropertyValue?: boolean;
  change?: (value: boolean) => void;
  close?: () => void;
  toDetailByFirstProp?: (product: any, salePropertyFirstListPicItem: any) => void;
}

const SizePopup: React.FC<SizePopupProps> = ({
  product,
  show,
  // needBackTop = false,
  lockScroll = true,
  change,
  close,
  toDetailByFirstProp,
  showPropertyValue,
}) => {
  // 是否显示引导
  const hideGestureGuide = "hideGestureGuide";

  const showGestureGuide = localStorage.getItem(hideGestureGuide) !== "1";

  // 当前的轮播图片下标
  const [activeIndex, setActiveIndex] = useState(1);

  // 图片数据
  const [salePropertyFirstListPicList, setSalePropertyFirstListPicList] = useState<
    | {
        imgUrl: string;
        value: string;
        sellOut: boolean;
        name: string;
      }[]
    | null
  >(null);

  // 筛选图片数据
  const getProductImgs = (product: ProductModel) => {
    const salePropertyFirstList = product?.salePropertyFirstList?.map(
      (element: ProductSKUModel) => ({
        imgUrl: element.picList[0],
        value: element.value,
        sellOut: element.sellOut,
        name: element.name,
      }),
    );
    setSalePropertyFirstListPicList(salePropertyFirstList);
  };

  useEffect(() => {
    if (show) {
      if (lockScroll) {
        document.body.style.overflowY = "hidden";
      }
      getProductImgs(product);
    }
  }, [show]);

  const onClose = () => {
    if (lockScroll) {
      document.body.style.overflowY = "auto";
    }
    // 关闭手势引导
    localStorage.setItem(hideGestureGuide, "1");
    setActiveIndex(1);
    change && change(false);
    close && close();
  };

  const onGotoProductDetail = (salePropertyFirstListPicItem) => {
    toDetailByFirstProp && toDetailByFirstProp(product, salePropertyFirstListPicItem);
    onClose();
  };

  const onIndexChange = useCallback((index: number) => {
    setActiveIndex(index + 1);
  }, []);

  return (
    <>
      {show && (
        <div className={styles["poster_swipe_wrapper"]}>
          <div className={styles["poster__mask"]} onClick={onClose}></div>
          {salePropertyFirstListPicList && (
            <div className={styles["swipe-content"]}>
              <div className={styles["swiper-container"]}>
                {showPropertyValue && (
                  <div className={styles["first-property"]}>
                    {salePropertyFirstListPicList?.[activeIndex - 1]?.value}
                  </div>
                )}
                <Swiper
                  slideSize={84}
                  trackOffset={8}
                  loop={salePropertyFirstListPicList.length > 2}
                  stuckAtBoundary={false}
                  indicator={() => null}
                  onIndexChange={onIndexChange}
                >
                  {salePropertyFirstListPicList.map((item, idx) => (
                    <Swiper.Item key={idx}>
                      <div
                        className={clsx(styles["swiper-slide"], {
                          [styles["swiper-slide-active"]]: idx === activeIndex - 1,
                        })}
                      >
                        <img
                          className={styles["poster__img"]}
                          src={item.imgUrl}
                          onClick={() => onGotoProductDetail(item)}
                          onError={(e: any) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://akim-oss.aikucun.com/saas/f378f9726fc41f88df5af93052684fa9cb4ce10c_1639487955668_97.png";
                          }}
                        />
                        {product.spuInfoVO?.sellOut || item.sellOut ? (
                          <div className={styles["sell-out"]}>
                            <div className={styles["txt"]}>已售罄</div>
                          </div>
                        ) : null}
                      </div>
                    </Swiper.Item>
                  ))}
                </Swiper>
              </div>
              <div className={styles["prop-num"]}>
                {activeIndex}/{salePropertyFirstListPicList.length}
              </div>
              <div className={styles["check-detail"]} onClick={onClose}>
                <div
                  className={styles["check-detail-btn"]}
                  onClick={() => onGotoProductDetail(salePropertyFirstListPicList[activeIndex - 1])}
                >
                  查看详情
                </div>
                {showGestureGuide && (
                  <img
                    className={styles["gesture"]}
                    src="https://akim-oss.aikucun.com/8021fba8d977c0b1dac81d90d38bdb5abc196b25_1682070750546_8.png"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SizePopup;
