import { ISwiperDataItem } from "../components/custom-swiper/index";
// 轮播图获取第一个元素的高度
export const getFirstElementHeight = (
  item: ISwiperDataItem,
  callback: (height: number) => void,
) => {
  const swiperDom = document.getElementById("swiper");
  const screenWidth = swiperDom?.clientWidth || 375;
  try {
    let height = parseFloat(((item?.height / item?.width) * screenWidth).toFixed(2));
    if (height > 467) {
      height = 467;
    }
    if (height < 211) {
      height = 211;
    }
    callback(height);
  } catch (error) {
    console.error(error);
  }
};

export const formatTimeAgo = (createTimeStamp, systemTimeStamp) => {
  const diffMillis = Number(systemTimeStamp) - Number(createTimeStamp);

  // 小于1分钟
  if (diffMillis < 60 * 1000) {
    return "刚刚";
  }

  // 大于等于1分钟小于60分钟
  if (diffMillis < 60 * 60 * 1000) {
    const minutes = Math.floor(diffMillis / (60 * 1000));
    return `${minutes}分钟前`;
  }

  // 大于等于60分钟小于24小时
  if (diffMillis < 24 * 60 * 60 * 1000) {
    const hours = Math.floor(diffMillis / (60 * 60 * 1000));
    return `${hours}小时前`;
  }

  // 大于等于24小时小于等于72小时
  if (diffMillis < 72 * 60 * 60 * 1000) {
    const days = Math.floor(diffMillis / (24 * 60 * 60 * 1000));
    return `${days}天前`;
  }

  // 大于72小时
  const date = new Date(Number(createTimeStamp));
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const nowYear = new Date().getFullYear();
  if (year === nowYear) {
    return `${month}-${day}`;
  }
  return `${year}-${month}-${day}`;
};
