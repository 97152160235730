import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";
export function querySpuSalePropertyVO(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/querySpuSalePropertyVO`,
    method: "post",
    data: params,
  });
}
export function queryProductPrice(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/queryProductPrice`,
    method: "post",
    data: params,
  });
}

export function buyMore(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/buyMore`,
    method: "post",
    data: params,
  });
}

export function addToCartReq(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/shoppingCart/add-shoppingcart-count`,
    method: "post",
    headers: {
      ls: true,
    },
    data: params,
  });
}

export function batchReceiveCoupon(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/batchReceiveCoupon`,
    method: "post",
    data: params,
  });
}
