import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";
export function queryCategoryList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/category/list`,
    method: "get",
    params,
  });
}
export function queryPreferenceList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/category/preferenceList`,
    method: "post",
    data: params,
  });
}
export function preferenceSave(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/category/preferenceSave`,
    method: "post",
    data: params,
  });
}
export function queryDiscoverList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/discover/materialPageList`,
    method: "post",
    data: params,
  });
}
export function queryRecommendList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/recommend/materialPageList`,
    method: "post",
    data: params,
  });
}

export function setAwesome(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/material/interact/awesome`,
    method: "post",
    data: params,
  });
}

export function queryMaterialDetail(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/materialInfo`,
    method: "post",
    data: params,
  });
}

export function queryRecommendByMaterialNo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/recommendCommunity/v1/query/recommendByMaterialNo`,
    method: "post",
    data: params,
  });
}
//  热词
export function queryHotWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryHotWord`,
    method: "post",
    data: params,
  });
}

//  推荐词
export function querySuggestWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/querySuggest`,
    method: "get",
    params,
  });
}

//  暗词
export function queryDarkWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
    data: params,
  });
}

//搜索页暗词
export function getDarkWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
    data: params,
  });
}

//搜索页热词
export function getHotWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryHotWord`,
    method: "post",
    data: params,
  });
}

//搜索页联想词
export function getSuggestWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/querySuggest`,
    method: "get",
    params,
  });
}
