import { CascadePickerOption } from "antd-mobile";
import { useEffect, useState } from "react";
import { getAddressListInfo, getAddressVersion } from "@/api/youxue";

const TAG = "useAddress";

interface AddressItemInfo {
  name: string;
  childrens?: AddressItemInfo[];
}

interface AddressDataResult {
  intversion: number;
  childrens: AddressItemInfo[];
}

// 1、校验地址库版本 2、获取地址列表
const useAddress = () => {
  // 地址信息
  const [addressList, setAddressList] = useState<CascadePickerOption[]>();
  // 地址弹层
  const [addressSelectVisible, setAddressSelectVisible] = useState(false);

  const handleAddressListData = (addressResult: AddressDataResult) => {
    const addressObj =
      addressResult?.childrens?.map((res) => {
        const innerItem = res.childrens?.map((item) => {
          const childItem = {
            label: item.name,
            value: item.name,
          };
          return childItem;
        });
        const address = {
          label: res.name,
          value: res.name,
          children: innerItem,
        };
        return address;
      }) || [];
    setAddressList(addressObj);
  };

  const fetchAddressList = async () => {
    try {
      let addressListResult = await getAddressListInfo();
      console.log(TAG, "fetchAddressList result length:", addressListResult?.childrens?.length);
      if (addressListResult && addressListResult.childrens?.length > 0) {
        console.log(TAG, "设置地址缓存 intversion:", addressListResult?.intversion);
        localStorage.setItem("addressCache", JSON.stringify(addressListResult));
      }
      handleAddressListData(addressListResult);
    } catch (error) {
      console.log(TAG, "fetchAddressList error:", error);
    }
  };

  const checkAddressVersion = async (addressCache: AddressDataResult) => {
    console.log(TAG, "checkAddressVersion intversion:", addressCache?.intversion);
    let storageVersion = addressCache?.intversion;
    try {
      const addressVersionResult = await getAddressVersion();
      if (addressVersionResult && addressVersionResult.versionInteger > storageVersion) {
        console.log(TAG, "不走缓存,请求服务端获取最新地址");
        fetchAddressList();
      } else {
        console.log(TAG, "从缓存里中获取地址");
        handleAddressListData(addressCache);
      }
    } catch (error) {
      console.error(TAG, "checkAddressVersion error:", error);
    }
  };

  useEffect(() => {
    const initAddressConfig = async () => {
      const addressCacheStr = localStorage.getItem("addressCache");
      if (addressCacheStr) {
        console.log(TAG, "本地存在缓存");
        return checkAddressVersion(JSON.parse(addressCacheStr));
      } else {
        console.log(TAG, "本地不存在缓存");
        return fetchAddressList();
      }
    };
    initAddressConfig();
  }, []);

  // 城市选择
  const onSelectCity = () => {
    setAddressSelectVisible(true);
  };

  return { addressList, addressSelectVisible, setAddressSelectVisible, onSelectCity };
};

export default useAddress;
