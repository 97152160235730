import React, { useEffect, useState } from "react";
import styles from "./ADMF.module.less";

interface Props {
  info: any;
}

const ADMF: React.FC<Props> = ({ info }) => {
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "销售额", unit: "元" },
    SALE_QUANTITY: { desc: "销量", unit: "件" },
    COMMISSION: { desc: "基础省赚", unit: "元" },
    SHOP_NEW_USER_QUANTITY: { desc: "新C", unit: "人" },
  };
  // 奖励枚举
  const rewardTypeEnum = {
    1: { desc: "奖励", unit: "元" },
    2: { desc: "省赚比", unit: "%" },
    3: { desc: "销售额比", unit: "%" },
    4: { desc: "奖励", unit: "元" },
  };

  const [currentGmv, setCurrentGmv] = useState(0); // 当前累计
  const [rewardType, setRewardType] = useState(1); // 奖励类型
  const [ruleDetails, setRuleDetails] = useState<any>([]); // 进度数组
  const [isMultistage, setIsMultistage] = useState(false); // 进度数组是否超过3段(要展示...省略号)

  const gmvDom = () => {
    if (!info) {
      return;
    }
    if (info?.actSellBatePartakeInfo?.nextRewardAmount) {
      return (
        <p>
          当前累计
          {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
            "拓展"}
          {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
          {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
            "数量"}
          <span>
            {currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，
          <span>
            仅差{info?.actSellBatePartakeInfo?.nextRewardAmount}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          就可以获得
          <span>
            {info?.actSellBatePartakeInfo?.nextRuleRewardAmount}
            {rewardTypeEnum[rewardType].unit}
            {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].desc}
            奖励
          </span>
          ，继续努力呀~
        </p>
      );
    } else {
      return (
        <p>
          当前累计
          {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
            "拓展"}
          {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
          {info.actSellBatePartakeInfo.thresholdIndicatorType === "SHOP_NEW_USER_QUANTITY" &&
            "数量"}
          <span>
            {currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，预计可得
          <span>
            {ruleDetails[ruleDetails.length - 1].rewardAmount}
            {rewardTypeEnum[rewardType].unit}
            {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].desc}
            奖励
          </span>
          ，继续努力呀
        </p>
      );
    }
  };

  const matchTypeOfDataCaliberDom = () => {
    let dom = <></>;
    switch (info?.actSellBatePartakeInfo?.thresholdIndicatorType) {
      case "GMV": // 销售额
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>{info?.actSellBatePartakeInfo?.saleAmount}</span>
            <span className={styles.item__key}>累计销售额(元)</span>
          </li>
        );
        break;
      case "SALE_QUANTITY": // 销量
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalSaleQuantity}
            </span>
            <span className={styles.item__key}>累计销量(件)</span>
          </li>
        );
        break;
      case "COMMISSION": // 基础省赚
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalCommission}
            </span>
            <span className={styles.item__key}>累计基础省赚(元)</span>
          </li>
        );
        break;
      case "SHOP_NEW_USER_QUANTITY": // 新C
        dom = (
          <li className={styles.info__item}>
            <span className={styles.item__value}>
              {info?.actSellBatePartakeInfo?.totalShopNewUserQuantity}
            </span>
            <span className={styles.item__key}>累计店铺新C(人)</span>
          </li>
        );
        break;
    }
    return dom;
  };

  const getPercent = (item) => {
    if(item.isInfinite) { return 0; }
    if (currentGmv > item.strThreshold) {
      return currentGmv < item.endThreshold
        ? ((currentGmv - item.strThreshold) / (item.endThreshold - item.strThreshold)) * 100
        : 100;
    } else {
      return 0;
    }
  };

  const handleRuleDetails = () => {
    const _ruleDetails = info?.actSellRebateRuleInfos?.ruleDetails || [];
    const maxLength = 3;
    setIsMultistage(_ruleDetails.length > maxLength);
    if(_ruleDetails.length <= maxLength) {
      setRuleDetails(_ruleDetails);
    } else {
      /**
       * 1. 初始展示前两个阶梯+最后一个阶梯值;
       * 2. 当业绩超过两个后，销售业绩每增加一个阶梯，前面就少展示一个阶梯;
       * 3. 当业绩到最后3个阶梯间，固定展示最后3个阶梯;
       */
      let currentIndex = _ruleDetails.findIndex(item => {
        return item.endThreshold > currentGmv;
      });
      let newList:any[] = [];
      let isRewardSuperimpositionType = info?.actSellRebateRuleInfos?.rewardSuperimpositionType === "SUPERIMPOSITION"; // 是否可叠加
      let isExistCeilingTimes = info?.actSellBatePartakeInfo?.isExistCeilingTimes; // 是否存在封顶次数
      // let ceilingTimes = info?.actSellBatePartakeInfo?.ceilingTimes; // 次数
      let isInfinite =  isRewardSuperimpositionType && !isExistCeilingTimes; // 是否不封顶叠加
      if(currentIndex >= _ruleDetails.length - 3) {
        newList = isInfinite ? [..._ruleDetails.slice(-2), {
          isInfinite: true,
          endThreshold: "多卖多得",
          rewardAmount: "上不封顶"
        }] : _ruleDetails.slice(-3);
      } else if(currentIndex > 1) {
        newList = [_ruleDetails[currentIndex],_ruleDetails[currentIndex+1], _ruleDetails[_ruleDetails.length -1]];
      } else {
        newList = [_ruleDetails[0],_ruleDetails[1],_ruleDetails[_ruleDetails.length -1]];
      }
      if(isInfinite) {
        newList[newList.length - 1] = {
          isInfinite: true,
          endThreshold: "多卖多得",
          rewardAmount: "上不封顶"
        };
        setRuleDetails(newList);
      } else {
        setRuleDetails(newList);
      }
    }
  };

  useEffect(() => {
    handleRuleDetails();
  }, [currentGmv]);

  useEffect(() => {
    if (!info) {
      return;
    }
    setCurrentGmv(info?.actSellBatePartakeInfo?.currentGmv || 0);
    setRewardType(info?.actSellRebateRuleInfos?.ruleDetails?.[0]?.rewardType || 1);
  }, [info]);

  return (
    <div className={styles.achievement}>
      {info?.actSellBatePartakeInfo?.partakeStatus === 40 && (
        <img
          className={styles.achievement__received}
          src="https://akim-oss.aikucun.com/mshop/dc445ddc22b2697ae5fbd5168cd31abd8a7f0be3_1720255803315_87.png"
        />
      )}
      <h3 className={styles.achievement__title}>我的业绩</h3>
      <div className={styles.achievement__progress}>
        <div className={styles.progress__list}>
          <div className={`${styles.list__title}`}>
            <div
              className={`${styles.item__icon} ${currentGmv && styles["item__icon_active"]}`}
            ></div>
            <div className={styles.item__con}>
              <p className={styles.con__gmv}>
                {
                  thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                    .desc
                }
                (
                {
                  thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                    .unit
                }
                )
              </p>
              <p className={styles.con__award}>
                {rewardTypeEnum[rewardType].desc}
                {rewardType === 1 ? "(元)" : ""}
              </p>
            </div>
          </div>
          <ul className={styles.list__con}>
            {ruleDetails?.length &&
              ruleDetails.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${styles.list__item} ${
                      ruleDetails?.length === 1 &&
                      styles.list__lenght_1
                    } ${
                      ruleDetails?.length === 2 &&
                      styles.list__lenght_2
                    } ${
                      isMultistage && index === 2  && styles.list__multistage
                    }`}
                  >
                    <div className={styles.item__line}>
                      <div
                        className={styles.line__current}
                        style={{
                          width: `${getPercent(item)}%`,
                        }}
                      ></div>
                    </div>
                    <div
                      className={`${styles.item__icon} ${
                        currentGmv > item.endThreshold && styles["item__icon_active"]
                      }`}
                    ></div>
                    <div className={styles.item__con}>
                      <p className={styles.con__gmv}>{item.endThreshold}</p>
                      <p className={styles.con__award}>
                        {item.rewardAmount}
                        {[2, 3].includes(rewardType) && rewardTypeEnum[rewardType].unit}
                      </p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {currentGmv > 0 && <div className={styles.achievement__gmv}>{gmvDom()}</div>}
      {info?.actSellBatePartakeInfo?.saleAmount > 0 && (
        <>
          <ul className={styles.achievement__info}>
            {matchTypeOfDataCaliberDom()}
            <li className={styles.info__item}>
              <span className={styles.item__value}>
                {info?.actSellBatePartakeInfo?.estimatedRewards}
              </span>
              <span className={styles.item__key}>预估奖励(元)</span>
            </li>
            <li className={styles.info__item}>
              <span className={styles.item__value}>
                {info?.actSellBatePartakeInfo?.totalOrderNum}
              </span>
              <span className={styles.item__key}>订单总数</span>
            </li>
            <li className={styles.info__item}>
              <span className={styles.item__value}>
                {info?.actSellBatePartakeInfo?.afterSaleOrderNum}
              </span>
              <span className={styles.item__key}>售后订单</span>
            </li>
          </ul>
          <div className={styles.achievement__tips}>*销售数据每隔1小时刷新1次</div>
        </>
      )}
    </div>
  );
};

export default ADMF;
