import { queryDiscoverList, setAwesome } from "@/api/promote";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { getCookie } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { InfiniteScroll, PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import Macy from "macy";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import useShare from "../../share";
import { useTrack } from "../../track";
import Category, { ListEntity } from "../category";
import MaterialCard from "./components/material_card";
import styles from "./index.module.less";
interface DiscoveryProps {
  activeTab: string;
}
const imageInfoMap = new Map();
const Discovery: React.FC<DiscoveryProps> = React.memo((props: DiscoveryProps) => {
  const { activeTab } = props;
  const navigate = useNavigate();
  const [macyObject, setMacyObject] = useState<any>({});
  const [materialDatalist, setMaterialDatalist] = useState<any>([]);
  const [pageNo, setPageNo] = useState<number>(1);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isloading, setIsloading] = useState<boolean>(false);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [frontCodes, setFrontCodes] = useState<Array<ListEntity>>([]);
  const [search] = useSearchParams();
  const objectIds = search.get("objectIds");
  const baseProductIds = search.get("baseProductIds");
  const distributorId = search.get("distributorId");
  const fromPage = search.get("fromPage");
  const shopId = search.get("shopId");
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const pageSize = 20;
  const { trackDiscoveryCardClick } = useTrack();
  const useShareProps = useShare();
  let searchRequestId = "";
  const loadRef = useRef(false);
  useEffect(() => {
    searchRequestId = getCookie("mstoken") || "" + Date.now();
    console.log("searchRequestId", searchRequestId);
  }, []);
  const reset = () => {
    if (activeTab === "1") {
      setCanLoadMore(true);
      const shareType = fromPage === "productDetail" ? 4 : 2;
      const extraInfo =
        fromPage === "productDetail"
          ? {
              objectIds,
              queryType: search.get("queryType") ?? undefined,
              baseProductIds,
              fromPage,
            }
          : null;
      useShareProps.shareSetting(shareType, null, extraInfo);
      if (macyObject["waterfall"]) {
        macyObject["waterfall"].recalculate(true, true);
      }
    } else {
      setCanLoadMore(false);
    }
  };
  useActivate(() => {
    reset();
  });
  useEffect(() => {
    reset();
  }, [activeTab]);
  useLayoutEffect(() => {
    if (materialDatalist?.length > 0) {
      const interval = setInterval(() => {
        const dom = document.getElementById("waterfall");
        if (dom) {
          clearInterval(interval);
          const macyInstance = Macy({
            container: "#waterfall", // 图像列表容器
            trueOrder: false,
            waitForImages: true,
            useOwnImageLoader: false,
            debug: true,
            margin: { x: 0, y: 15 }, // 设计列与列的间距
            columns: 2, // 设置列数
          });
          macyObject["waterfall"] = macyInstance;
          setMacyObject(macyObject);
          // setTimeout(() => {
          macyObject["waterfall"].runOnImageLoad(function () {
            macyObject["waterfall"].recalculate(true, true);
            setFirstLoaded(true);
          });
          let sessionStorageValue: string = sessionStorage.getItem("discovery_card_12") as string;
          sessionStorageValue = JSON.parse(sessionStorageValue);
          const objName = "waterfall";
          if (sessionStorageValue) {
            sessionStorageValue[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("discovery_card_12", JSON.stringify(sessionStorageValue));
          } else {
            const obj: any = {};
            obj[objName] = document.getElementById(objName)?.style.height;
            sessionStorage.setItem("discovery_card_12", JSON.stringify(obj));
          }
          // }, 50);
        }
      }, 50);
    }
  }, [materialDatalist?.length]);

  const goDetail = (item, index) => {
    const indexAll = (pageNo - 1) * pageSize + index;
    trackDiscoveryCardClick({
      item,
      btn_name: "查看详情",
      index: indexAll,
      tab_name: frontCodes[0].name,
      fromPage,
    });
    if (item.containVideo && item.type === 1) {
      distributorId
        ? navigate(
            `/mx-shop-micro/recommendList?distributorId=${distributorId}&materialNo=${item.materialNo}`,
          )
        : navigate(`/mx-shop-micro/recommendList?materialNo=${item.materialNo}`);
    } else {
      distributorId
        ? navigate(
            `/mx-shop-micro/PromoteDetail?distributorId=${distributorId}&materialNo=${
              item.materialNo
            }&shopId=${shopId}&fromPage=${fromPage || ""}`,
          )
        : navigate(
            `/mx-shop-micro/PromoteDetail?materialNo=${item.materialNo}&shopId=${shopId}&fromPage=${
              fromPage || ""
            }`,
          );
    }
  };

  const setLike = async (item, index) => {
    const params = {
      scene: "G_P_C",
      materialNo: item.materialNo,
      interactScene: 1,
      interactFlag: item.awesome ? 1 : 0,
    };
    const res = (await setAwesome(params)) as any;
    if (res && res.success) {
      const newMaterialDatalist = materialDatalist.map((i) => {
        if (i.materialNo === item.materialNo) {
          return {
            ...i,
            awesomeNum: res.data,
            awesome: !item.awesome,
          };
        } else {
          return i;
        }
      });
      trackDiscoveryCardClick({
        item,
        btn_name: item.awesome ? "取消点赞" : "点赞",
        index: (pageNo - 1) * pageSize + index,
        tab_name: frontCodes[0].name,
        fromPage,
      });
      setMaterialDatalist(newMaterialDatalist);
    }
  };
  const queryImageInfo = async (list: Array<any>) => {
    const queryInfo = (url) => {
      return new Promise((resolve) => {
        if (imageInfoMap.get(url)) {
          console.log("imageInfoMap", imageInfoMap.size);
          resolve(imageInfoMap.get(url));
        } else if (url.indexOf("?") === -1) {
          fetch(url + "?x-image-process=image/info")
            .then((res) => {
              if (res.status === 200) {
                return res.json();
              }
            })
            .then((res) => {
              imageInfoMap.set(url, res);
              resolve(res);
            })
            .catch(() => {
              console.log("img error");
              resolve({ width: "", height: "" });
            });
        } else {
          resolve({ width: "", height: "" });
        }
      });
    };
    const promises = list.map((item) => queryInfo(item.coverVOList[0].coverUrl));
    const dimensionsList = await Promise.all(promises);
    return list.map((item, index) => {
      return {
        ...item,
        coverInfo: dimensionsList[index],
      };
    });
  };
  const queryMaterialList = async (item?: ListEntity, init?: boolean) => {
    if (loadRef.current) {
      return;
    }
    loadRef.current = true;
    setIsEmpty(false);
    if (init) {
      setIsloading(false);
      setMaterialDatalist([]);
      setFirstLoaded(false);
      const dom = document?.getElementById("discovery-wrapper");
      if (dom) {
        dom.scrollTop = 0;
      }
    }
    setIsloading(true);
    if (item) {
      setFrontCodes([{ ...item }]);
    }
    const params = {
      scene: "G_P_C",
      pageNo: init ? 1 : pageNo + 1,
      pageSize: init ? pageSize : 5,
      frontCodes: item ? [{ ...item }] : frontCodes,
      searchRequestId,
      objectIds: objectIds?.split(","),
      baseProductIds: baseProductIds?.split(","),
      queryType: search.get("queryType") ?? undefined,
    };
    objectIds?.length ? (params.scene = "") : void 0; // 实拍专区不需要该标识
    try {
      const res = await queryDiscoverList(params);
      if (res && res.data) {
        if (res.data.totalCount === 0) {
          setIsloading(false);
          setFirstLoaded(true);
          setTimeout(() => {
            setHasNext(false);
            setIsEmpty(true);
            loadRef.current = false;
          }, 300);
          return;
        }
        if (res.data.result && res.data.result.length > 0) {
          setIsEmpty(false);
          const handledData = await queryImageInfo(res.data.result);
          if (init) {
            setMaterialDatalist(handledData);
          } else {
            setMaterialDatalist([...materialDatalist, ...handledData]);
          }
          init ? setPageNo(4) : setPageNo(pageNo + 1);
          setIsloading(false);
          setTimeout(() => {
            setHasNext(res.data.hasNext);
            loadRef.current = false;
          }, 300);
        }
      }
      if (init) {
        setTimeout(() => {
          setFirstLoaded(true);
        }, 2000);
      }
    } catch (error) {
      if (init) {
        setIsloading(false);
        setIsEmpty(true);
        if (init) {
          setTimeout(() => {
            setFirstLoaded(true);
          }, 2000);
        }
      } else {
        setIsloading(false);
      }
    }
  };
  //发现页卡片曝光埋点
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < materialDatalist.length; i++) {
        const dom = document.getElementById(`discovery-${materialDatalist[i].materialNo}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [materialDatalist?.length]);

  const loadMore = async () => {
    if (canLoadMore && hasNext && !isloading) {
      await queryMaterialList();
    }
  };
  //加载更多
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <div className={styles.infinite}>
        {hasMore && isloading && materialDatalist.length > 0 ? (
          <div className={styles.loadingMore}>
            <img src={loadingPng} style={{ width: pxtorem(20), height: pxtorem(20) }} />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {materialDatalist?.length > 0 && !hasMore && (
              <div className={styles.toEnd}>
                <img style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></img>
                <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
                <img style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></img>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      className={styles["discovery-wrapper"]}
      style={{ paddingTop: fromPage === "productDetail" ? 0 : pxtorem(88) }}
      id="discovery-wrapper"
    >
      <Category handleChosen={queryMaterialList} />
      {isEmpty && !isloading && (
        <div className={styles["empty-wrapper"]}>
          <img
            className={styles["empty-img"]}
            src="https://akim-oss.aikucun.com/39b4ba7e3b243ff2a354ee1d644cbac0a0a2c1ed_1700467176746_20.png"
          />
          <div className={styles["empty-text"]}>暂无数据</div>
        </div>
      )}
      {!firstLoaded && (
        <img
          className={styles["skeleton-img"]}
          src="https://akim-oss.aikucun.com/65a2747ad0c9358eaf813808668d3a493be6db9f_1700560940576_26.png"
        />
      )}
      <PullToRefresh
        onRefresh={async () => {
          await sleep(1000);
          queryMaterialList(undefined, true);
        }}
      >
        <div className={styles["water-fall-box"]}>
          {materialDatalist && materialDatalist?.length > 0 && (
            <div id="waterfall" className={styles["waterfall"]}>
              {materialDatalist.map((item, index) => {
                return (
                  <div
                    className={styles["card-item"]}
                    key={index}
                    id={`discovery-${item.materialNo}`}
                    data-param={JSON.stringify({
                      page_name: fromPage === "productDetail" ? "实拍专区_列表" : "种草社区_发现",
                      previous_page_name: "",
                      domain: "种草",
                      tabName: frontCodes[0].name,
                      resourceRank: index,
                      type: "种草图文组件",
                      name: item?.showName,
                      materialId: item.materialNo,
                      content: "资源位跳转内容类型：素材",
                      searchRequestId: searchRequestId,
                    })}
                    data-eventid={`discovery-${item.materialNo}`}
                  >
                    <MaterialCard
                      key={index}
                      {...item}
                      setLike={(item) => {
                        setLike(item, index);
                      }}
                      goDetail={(item) => {
                        goDetail(item, index);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={250}>
            <InfiniteScrollContent hasMore={hasNext} />
          </InfiniteScroll>
        </div>
      </PullToRefresh>
    </div>
  );
});
export default Discovery;
