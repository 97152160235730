export function pxtorem(px: string | number | undefined) {
  px = px || 0;
  const res = (Number(px) / 50).toFixed(4) + "rem";
  return res;
}

export function rem2px(rem: string | number | undefined) {
  rem = rem || 0;
  const res = (Number(rem) * 50).toFixed(4) + "px";
  return res;
}

export function rem2pxNumber(rem: string | number | undefined) {
  rem = rem || 0;
  const res = Number(rem) * 50;
  return res;
}

export const getRatio = () => Number(document.documentElement.style.fontSize.split("px")[0]) / 50;
