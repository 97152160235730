import Arrow from "@/pages/JointBill/components/Arrow/Arrow";
import React, { useState } from "react";
import { FilterParams, Search } from "../../hook";
import styles from "./index.module.less";
import { disableScroll, enableScroll } from "./utils";

interface Props {
  appStore?: any;
  filter: FilterParams;
  priceRangeList: any[];
  brandList: any[];
  searchParams: Search;
  setSearchParams: (params: Search) => void;
  handleSearch: (params?: Search, init?: boolean) => void;
  [key: string]: any;
}

const Filter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState<string>("");
  const { filter, priceRangeList, brandList, searchParams } = props;
  const closeModal = () => {
    setShowModal("");
    enableScroll();
  };
  const handleSubmit = (type: "price" | "brand") => {
    let params: any = searchParams;
    if (type === "price" && !searchParams.price) {
      params = {
        sale: undefined,
        price: undefined,
        brand: undefined,
        all: undefined,
      };
      params.price = {
        name: "all",
        min: 0,
      };
    }
    if (type === "brand" && !searchParams.brand) {
      params = {
        sale: undefined,
        price: undefined,
        brand: undefined,
        all: undefined,
      };
      params.brand = [];
    }
    props.handleSearch(params, true);
    closeModal();
  };
  const resetFilter = (type: "price" | "brand") => {
    if (type === "price") {
      const params = {
        sale: undefined,
        price: undefined,
        brand: undefined,
        all: "down",
      };
      props.setSearchParams(params);
    }
    if (type === "brand") {
      const params = {
        sale: undefined,
        price: undefined,
        brand: undefined,
        all: "down",
      };
      props.setSearchParams(params);
    }
  };
  // 点击筛选框
  const clickFilter = (key: string) => {
    disableScroll();
    if (key === "all") {
      closeModal();
      const params = {
        sale: undefined,
        price: undefined,
        brand: undefined,
        all: "down",
      };
      props.setSearchParams(params);
      props.handleSearch(params, true);
    }
    if (key === "sale") {
      const { sale } = filter;
      const saleMap = {
        up: "down",
        down: "up",
        false: "down",
      };
      const value = saleMap[sale.toString()];
      closeModal();
      const params = {
        all: undefined,
        price: undefined,
        brand: undefined,
        sale: value,
      };
      props.setSearchParams(params);
      props.handleSearch(params, true);
    }
    if (key === "price") {
      if (showModal === "price") {
        closeModal();
        return;
      }
      setShowModal("price");
    }
    if (key === "brand") {
      if (showModal === "brand") {
        closeModal();
        return;
      }
      setShowModal("brand");
    }
  };
  // 选择品牌
  const handleBrand = (item: any) => {
    const brandId = item.brandId;
    let brand = searchParams.brand || [];
    if (brand.includes(brandId)) {
      brand = brand.filter((item) => item !== brandId);
    } else {
      brand.push(brandId);
    }
    const params = { ...searchParams };
    Object.keys(searchParams).forEach((key) => {
      if (key === "brand") {
        params[key] = brand;
      } else {
        params[key] = undefined;
      }
    });
    props.setSearchParams(params);
  };
  // 选择价格
  const handlePrice = (item: any) => {
    const params: any = {
      all: undefined,
      sale: undefined,
      price: undefined,
      brand: undefined,
    };
    params.price = item;
    // if(item.name!=="全部") {
    // 	params.price = item;
    // }else{
    // 	params.price={
    // 		name:"全部",
    // 	};
    // }
    props.setSearchParams(params);
  };
  // 输入价格
  const inputPrice = (type: "min" | "max", e: any) => {
    const price = { min: 0, max: 0 };
    const name = (searchParams as any).price?.name || "";
    if (type === "min") {
      price.min = e.target.value.replace(/[^\d{1,}]/g, "").slice(0, 6);
      price.max = name ? "" : (searchParams as any).price?.max;
    }
    if (type === "max") {
      price.min = name ? "" : (searchParams as any).price?.min;
      price.max = e.target.value.replace(/[^\d{1,}]/g, "").slice(0, 6);
    }
    const params = {
      all: undefined,
      sale: undefined,
      brand: undefined,
      price: price,
    };
    props.setSearchParams(params);
  };
  // 价格弹框
  const priceDom = () => {
    const price: any = searchParams.price || {};
    const { name = "", min = "", max = "" } = price;
    return (
      <div className={styles.priceBox}>
        <div className={styles.priceItemBox}>
          {priceRangeList.map((item, index) => {
            return (
              <div
                className={`${styles.priceItem} ${(price as any)?.name === item.name ? styles.price_active : ""}`}
                key={index}
                onClick={() => {
                  handlePrice(item);
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <div className={styles.price_range}>
          <input
            placeholder={"最低价"}
            className={styles.priceInput}
            value={name ? "" : min}
            onInput={(e) => {
              inputPrice("min", e);
            }}
          />
          <div className={styles.price_line}></div>
          <input
            placeholder={"最高价"}
            className={styles.priceInput}
            value={name ? "" : max}
            onInput={(e) => {
              inputPrice("max", e);
            }}
          />
        </div>
        <div className={styles.price_bottom}>
          <div
            className={styles.price_reset}
            onClick={() => {
              resetFilter("price");
            }}
          >
            重置
          </div>
          <div
            onClick={() => {
              handleSubmit("price");
            }}
            className={styles.price_confirm}
          >
            确定
          </div>
        </div>
      </div>
    );
  };
  // 品牌弹框
  const brand = () => {
    return (
      <div className={styles.brandContainer}>
        <div className={styles.brandBox}>
          {brandList.map((item) => {
            return (
              <div
                className={`${styles.brandItem} ${(searchParams.brand || []).includes(item.brandId) ? styles.item_active : ""}`}
                key={item.brandId}
                onClick={() => handleBrand(item)}
              >
                <img className={styles.brandIcon} src={item.brandLogo} alt={""} />
                <view className={styles.brandName}>{item.brandName}</view>
              </div>
            );
          })}
        </div>
        <div className={styles.brandBottom}>
          <div
            className={styles.brandReset}
            onClick={() => {
              resetFilter("brand");
            }}
          >
            重置
          </div>
          <div
            className={styles.brandConfirm}
            onClick={() => {
              handleSubmit("brand");
            }}
          >
            确定
          </div>
        </div>
      </div>
    );
  };
  const arrowStyle = (item: string) => {
    const { filter } = props;
    const typeMap = {
      sale: "double",
      price: "single",
      brand: "single",
    };
    const directionMap = {
      up: "up",
      down: "down",
      false: "default",
    };
    return {
      type: typeMap[item],
      direction: directionMap[filter[item].toString()],
    };
  };

  return (
    <div className={styles.filterContainer}>
      <a href={"#bottomBox"}>
        <div className={styles.filterBox}>
          <div
            onClick={() => {
              clickFilter("all");
            }}
            className={`${styles.filterItem} ${filter.all ? styles.item_active : ""}`}
          >
            综合
          </div>
          <div
            onClick={() => {
              clickFilter("sale");
            }}
            className={`${styles.filterItem} ${filter.sale ? styles.item_active : ""}`}
          >
            销量
            <Arrow type={arrowStyle("sale").type} direction={arrowStyle("sale").direction} />
          </div>
          <div
            onClick={() => {
              clickFilter("price");
            }}
            className={`${styles.filterItem} ${filter.price ? styles.item_active : ""}`}
          >
            价格
            <Arrow type={arrowStyle("price").type} direction={arrowStyle("price").direction} />
          </div>
          <div
            onClick={() => {
              clickFilter("brand");
            }}
            className={`${styles.filterItem} ${filter.brand ? styles.item_active : ""}`}
          >
            品牌
            <Arrow type={arrowStyle("brand").type} direction={arrowStyle("brand").direction} />
          </div>
        </div>
      </a>
      <div className={styles.contentBox}>
        {showModal === "price" && priceDom()}
        {showModal === "brand" && brand()}
      </div>
      {showModal && (
        <div
          className={styles.mask}
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        ></div>
      )}
    </div>
  );
};

export default Filter;
