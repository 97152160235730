import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import React from "react";
import styles from "./index.module.less";
type Props = {
  leftTitle: string;
  rightTitle?: string;
  hideMore?: boolean;
  onMoreClick?: () => void;
};

/**
 * 宝贝评价 & 营业执照 入口
 */
const LREntranceComp: React.FC<Props> = (prop) => {
  return (
    <div
      className={styles.pEvaluateC}
      onClick={() => {
        prop.onMoreClick?.();
      }}
    >
      {prop?.leftTitle && <div className={styles.left}>{prop.leftTitle}</div>}
      <div className={styles.right}>
        {prop.rightTitle && <div>{prop.rightTitle}</div>}
        {!prop.hideMore && <ArrowRightIcon className={styles.arrowRight} />}
      </div>
    </div>
  );
};

export default LREntranceComp;
