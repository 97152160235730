import React, { useContext, useEffect, useRef, useState } from "react";
import Search from "./components/search/search-page";
import styles from "./search.module.less";

const YearEndBounsSearch: React.FC = () => {

  return (
    <Search></Search>
  );
};

export default YearEndBounsSearch;