import { MaterialContentType, MaterialContextModel } from "@akc/biz-api";
import React, { useState } from "react";
import ItemImgComp from "./components/item-img";
import ItemTextComp from "./components/item-text";
import ItemVideoComp from "./components/item-video";
import styles from "./index.module.less";

type Props = {
  materialList?: MaterialContextModel[];
  onCopyTxt?: (txt?: string, index?: number) => void;
  onPreview?: (materialList?: MaterialContextModel[], index?: number) => void;
};

/**
 * 商品素材
 */
const MaterialResComp: React.FC<Props> = ({ materialList, onCopyTxt, onPreview }) => {
  const contentList = materialList?.filter(
    (e) =>
      e.contextType === MaterialContentType.text ||
      e.contextType === MaterialContentType.video ||
      e.contextType === MaterialContentType.image,
  );
  return (
    <div className={styles.materialResC}>
      {contentList?.map((item, index) => {
        switch (item.contextType) {
          case MaterialContentType.text:
            return (
              <ItemTextComp
                key={index}
                txt={item?.contextContent?.contextText?.content}
                onCopyTxt={(txt) => {
                  onCopyTxt?.(txt, index);
                }}
              />
            );
          case MaterialContentType.image:
            return (
              <ItemImgComp
                key={index}
                url={item?.contextContent?.contextImage?.imageUrl}
                onImgClick={() => {
                  const imgList = contentList?.filter(
                    (e) => e.contextType === MaterialContentType.image,
                  );
                  const imgIndex = imgList?.findIndex(
                    (e) =>
                      e.contextContent?.contextImage?.imageUrl ===
                      item?.contextContent?.contextImage?.imageUrl,
                  );
                  onPreview?.(imgList, imgIndex);
                }}
              />
            );
          case MaterialContentType.video:
            return <ItemVideoComp key={index} videoInfo={item?.contextContent?.contextVideo} />;
        }
      })}
    </div>
  );
};

export default MaterialResComp;
