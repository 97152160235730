import React from "react";
import styles from "./index.module.less";

interface Props {
  type?: "single" | "double";
  direction?: "up" | "down" | "default";
  [key: string]: any;
}

const Arrow: React.FC<Props> = (props) => {
  const { type = "double", direction = "defaule" } = props;
  return (
    <div className={styles.container}>
      {/*单箭头默认*/}
      {type === "single" && direction === "default" && (
        <div className={styles.single_default}></div>
      )}
      {/*单箭头降序*/}
      {type === "single" && direction === "down" && <div className={styles.single_down}></div>}
      {/*双箭头默认*/}
      {type === "double" && direction === "default" && (
        <div className={styles.double_default}></div>
      )}
      {/*双箭头降序*/}
      {type === "double" && direction === "down" && <div className={styles.double_down}></div>}
      {/*双箭头升序*/}
      {type === "double" && direction === "up" && <div className={styles.double_up}></div>}
    </div>
  );
};

export default Arrow;
