const DeviceFingerprintConfig = {
  stable: {
    apiServer: "http://10.223.123.219:7776",
    constIDServer: "http://10.223.123.219:7776",
    cdnServer: "http://10.223.123.219:7776",
    captchaJS: "http://10.223.123.219:7776",
    appId: "3eb2e07a81ab1bf31ae60c7a8fae3dd4",
  },
  release: {
    apiServer: "http://captcha-ui.akcrelease.com",
    constIDServer: "http://captcha-ui.akcrelease.com",
    cdnServer: "http://captcha-ui.akcrelease.com",
    captchaJS: "http://captcha-ui.akcrelease.com",
    appId: "3eb2e07a81ab1bf31ae60c7a8fae3dd4",
  },
  production: {
    apiServer: "https://cap.aikucun.com",
    constIDServer: "https://dfp.aikucun.com",
    cdnServer: "https://capjs-cdn.aikucun.com",
    captchaJS: "https://capjs-cdn.aikucun.com",
    appId: "88fa3769174743c041e0f38612ee1017",
  },
};
const appEnv = process.env.REACT_APP_ENV || "production";
const appId = DeviceFingerprintConfig[appEnv].appId;
const apiServer = DeviceFingerprintConfig[appEnv].apiServer;
const constIDServer = DeviceFingerprintConfig[appEnv].constIDServer;
const cdnServer = DeviceFingerprintConfig[appEnv].cdnServer;
const captchaJS = DeviceFingerprintConfig[appEnv].captchaJS;

const captchaConfig = {
  appId,
  apiServer,
  constIDServer: constIDServer + "/udid/c1",
  // eslint-disable-next-line camelcase
  constID_js: cdnServer + "/dx-captcha/libs/const-id.js",
  captchaJS: captchaJS + "/dx-captcha/index.js",
  // eslint-disable-next-line camelcase
  ua_js: cdnServer + "/dx-captcha/libs/greenseer.js",
  publicPath: cdnServer + "/dx-captcha/",
  style: "popup",
  isSaas: false,
};

export default captchaConfig;
