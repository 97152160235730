import { queryBrandList } from "@/api/newPerson";
import { useEffect, useState } from "react";

const makeAlphabet = (list) => {
  const data = {};
  list.forEach((el) => {
    const firstLetter = el.brandNamePinYin.slice(0, 1).toUpperCase();
    if (data[firstLetter]) {
      data[firstLetter].push(el);
    } else {
      data[firstLetter] = [el];
    }
  });
  const letters = Object.keys(data).sort();
  return letters.map((letter) => ({
    startsWith: letter,
    list: data[letter],
  }));
};

const useBrandList = () => {
  const [brandList, setBrandList] = useState<any[]>([]);
  const [alphabetDataList, setAlphabetDataList] = useState<any[]>([]);
  const updateBrandList = async () => {
    const res = await queryBrandList();
    if (res?.length) {
      setBrandList(res);
      setAlphabetDataList(makeAlphabet(res));
    }
  };
  useEffect(() => {
    updateBrandList();
  }, []);
  return {
    brandList,
    alphabetDataList,
  };
};

export default useBrandList;
