import { GZHInfo, getGZHInfo, getMiniSubscribe } from "@/api/wechat";
import { getPlatform } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const WechatAccount: React.FC = () => {
  const env = process.env.REACT_APP_ENV || "production";
  const { isH5, isMini, isSaas } = getPlatform();
  const [gzhInfo, setGzhInfo] = useState<GZHInfo>();
  const [visible, setVisible] = useState(false);

  const getWeChatAccountInfo = async () => {
    if (isH5) {
      const data = await getGZHInfo();
      setGzhInfo(data);
    }
    if (isMini && !isSaas) {
      const data = await getMiniSubscribe({ appKey: env === "production" ? "dpfwzs" : "dpzscsh" });
      setVisible(!data);
    }
  };

  const onFollow = () => {
    if (isH5) {
      window.location.href = "https://mp.weixin.qq.com/s/07rq1S5IbVgaPamNstYj7w";
    }
    if (isMini && !isSaas) {
      window.location.href =
        env === "production"
          ? "https://mp.weixin.qq.com/s/07rq1S5IbVgaPamNstYj7w"
          : "https://mp.weixin.qq.com/s/Hs612yfFA10qVUPK-h9eFg";
    }
  };

  useEffect(() => {
    getWeChatAccountInfo();
  }, []);

  useEffect(() => {
    if (isH5 && gzhInfo) {
      setVisible(!gzhInfo?.isFollow);
    }
  }, [gzhInfo]);

  return (
    <>
      {visible && (
        <div className={styles["wechat-account"]} onClick={onFollow}>
          <span>关注公众号，接收发货消息通知</span>
          <div className={styles["action"]}>
            去关注
            <img
              src="https://akim-oss.aikucun.com/mshop/5cd2c1e7a8442a4ce08e04e2c08f76d3f90e134e_1723098653743_36.png"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WechatAccount;
