import { useSearchParams } from "react-router-dom";
import { getUserRole } from "../identity";
import { platform } from "../platform";

const useQueryAllPlatRole = () => {
  const [search] = useSearchParams();
  const miniCurrrentRole = search.get("currrentRole");
  const getRole = () => {
    if (platform.isApp) {
      return "R";
    } else if (platform.isH5 || platform.isWxH5) {
      return getUserRole();
    } else {
      if (miniCurrrentRole) {
        return miniCurrrentRole === "1" ? "C" : miniCurrrentRole === "2" ? "R" : "E";
      }
    }
    return "R";
  };
  return {
    getRole,
  };
};
export default useQueryAllPlatRole;
