import useStickyStateObserver from "@/utils/hooks/useStickyStateObserver";
import { pxtorem } from "@/utils/pxtorem";
import { ActivityBrandModel } from "@akc/biz-api";
import { throttle } from "lodash";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import {
  useProductStatus,
  useSelectedActivity,
  useSetProductStatus,
  useShowProductStatus,
} from "../hooks/useActivityProduct";
import styles from "./ProductStatusTab.module.less";

interface IProps {
  brandData?: ActivityBrandModel;
  onChange?: () => void;
}

const ProductStatusTab = (props: IProps) => {
  const { brandData, onChange } = props;

  const showProductStatus = useShowProductStatus();
  const productStatus = useProductStatus();
  const setProductStatus = useSetProductStatus();
  const selectedActivity = useSelectedActivity();

  const [translateY, setTranslateY] = useState(0);
  const isProductStatusTabSticky = useRef(false);
  const productStatusTabRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);

  useStickyStateObserver(productStatusTabRef.current, (isSticky) => {
    isProductStatusTabSticky.current = isSticky;
  });

  const onStatusChange = (status: number) => {
    setProductStatus(status === productStatus ? null : status);
    onChange?.();
  };

  const handleScroll = throttle(
    () => {
      // window.requestAnimationFrame(() => {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop < 10) {
        setTranslateY(0);
        return;
      }
      if (!isProductStatusTabSticky.current) {
        setTranslateY(0);
        return;
      }
      if (Math.abs(lastScrollTop.current - scrollTop) < 50) {
        return;
      }
      if (scrollTop < lastScrollTop.current && translateY !== 0) {
        console.log("下滑-显示:scrollTop", scrollTop);
        setTranslateY(0);
      } else if (scrollTop > lastScrollTop.current && translateY === 0) {
        console.log("上滑-隐藏:scrollTop", scrollTop);
        setTranslateY(-40);
      }
      lastScrollTop.current = scrollTop;
      // });
    },
    500,
    { leading: true, trailing: false },
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isProductStatusTabSticky.current, translateY]);

  useActivate(() => {
    window.addEventListener("scroll", handleScroll);
  });

  useUnactivate(() => {
    window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    setTranslateY(0);
  }, [selectedActivity]);

  return (
    <>
      {showProductStatus && (
        <div
          ref={productStatusTabRef}
          style={{
            position: "sticky",
            zIndex: 2,
            top:
              brandData?.activityList && brandData.activityList.length > 1
                ? pxtorem(139)
                : pxtorem(84),
            transform: `translateY(${translateY}px)`,
            transition: "all ease 0.3s",
          }}
        >
          <div className={styles["container"]}>
            <span
              className={`${styles["tab-item"]} ${productStatus === 1 ? styles["active"] : ""}`}
              onClick={() => onStatusChange(1)}
            >
              热卖中
            </span>
            <span
              className={`${styles["tab-item"]} ${productStatus === 0 ? styles["active"] : ""}`}
              onClick={() => onStatusChange(0)}
            >
              预告
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductStatusTab;
