import { isSwitchBatch } from "@/api";
import { fetchAccountInfo } from "@/api/daily-reward";
import * as api from "@/api/joint-bill";
import { SharePosterParams } from "@/api/scode/poster";
import { CustomEditData } from "@/components/poster-swiper";
import { sharePosterParams4H5Product } from "@/utils/actions";
import { entryShop } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as track from "./track";

type JointType =
  | "coupon" // 优惠券凑单
  | "redPacket"; // 红包凑单

export interface FilterParams {
  all: "down" | false;
  sale: "up" | "down" | false;
  price: "down" | false;
  brand: "down" | false;
}
export interface Search {
  all: string | undefined;
  sale: string | undefined;
  price: object | undefined;
  brand: any[] | undefined;
}

export const useControl = () => {
  const [filter, setFilter] = useState<FilterParams>({
    all: "down",
    brand: false,
    price: false,
    sale: false,
  });
  const [searchParams, setSearchParams] = useState<Search>({
    all: "down",
    sale: undefined,
    price: undefined,
    brand: undefined,
  });
  const [isOTC, setIsOTC] = useState(false);
  const [priceRangeList, setPriceRangeList] = useState<any[]>([]);
  const [brandList, setBrandList] = useState<any[]>([]);
  const [pageNum, setPageNum] = useState<number>(1);
  const [pageSize] = useState<number>(6);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [productList, setProductList] = useState<any[]>([]);
  const [couponInfo, setCouponInfo] = useState<any>({});
  const [topproductList, setTopproductList] = useState<any[]>([]);
  const [cartModalVisible, setCartModalVisible] = useState<boolean>(false);
  const [modalProduct, setModalProduct] = useState<any>({});
  const { navigate2Product } = useNavigation();
  const [search] = useSearchParams();
  const promoActivityId = useRef<string>("");
  const awdId = useRef<string>("");
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  const awdInstanceId = useRef<string>("");
  const [sharePosterData, setSharePosterData] = useState<SharePosterParams>(
    {} as SharePosterParams,
  );
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const loading = useRef<boolean>(false);

  // 红包余额
  const [balance, setBalance] = useState<string>();

  const voucherNo = (search.get("voucherNo") || "").replace("undefined", "");
  const couponId = (search.get("couponId") || "").replace("undefined", "");
  const urlAwdId = (search.get("couponId") || "").replace("undefined", "");
  const searchAwdInstanceId =
    (search.get("awdInstanceId") || "").replace("undefined", "") || undefined;

  const jointType: JointType = (() =>
    voucherNo.length + couponId.length + urlAwdId.length + (searchAwdInstanceId?.length ?? 0) > 0
      ? "coupon"
      : "redPacket")();

  const scene = jointType === "coupon" ? "JOIN_ORDER_PAGE" : "JOIN_ORDER_PAGE_RED_PACKET";

  let baseAmount = "";

  //是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = useState<boolean>(false);

  //获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isSwitchBatch({ bwlCodes: ["B-H5_REACT_ADD_PANEL"] });
    const newCartPanel = !!res?.data?.[0]?.isHit;
    setShowNewCartPanel(newCartPanel);
  };

  const onClosePoster = () => {
    setShowPoster(false);
  };
  // 返回按钮
  const navigater = useNavigate();
  const goBack = (e: any) => {
    e.stopPropagation();
    navigater(-1);
  };
  //增加购物车数量
  const changeCartNum = () => {
    console.log("购物车数量");
  };
  // 省心凑去商详
  const onClickProduct = (item) => {
    track.topResourceClick(item);
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
    });
  };
  // 省心凑去加购
  const onClickAddBtn = (item) => {
    setCartModalVisible(true);
    setModalProduct(item);
  };
  // 省心凑去分享
  const onClickShareBtn = async (item) => {
    const pageCode = search.get("customPageCode") || "";
    setSharePosterData(
      await sharePosterParams4H5Product({
        pageCode: pageCode as string,
        activitySpuId: item.activitySpuId,
      }),
    );
    setCustomEditData({
      activitySpuId: item.activitySpuId,
      activityId: item.activityId,
      selectSkuId: item.selectSkuId,
    });
    setIsOTC(!!item?.skuExtendInfoVO?.isOTC);
    setShowPoster(true);
  };
  // 商卡列表点击埋点
  const secondResourceClick = (item: any) => {
    track.secondResourceClick({ ...item, searchParams: searchParams, brandList: brandList });
  };
  // 获取省心凑列表数据
  const getProductsData = () => {
    const param = {
      searchParams: {
        scene,
        awdInstanceId: awdInstanceId.current,
        pageNum: 1,
        pageSize: 3,
        priceRangeBasicVal: baseAmount,
        promoActivityIds: promoActivityId.current ? [promoActivityId.current] : [],
        awdId: awdId.current,
        soldOutStatus: false,
      },
    };
    api.queryProductList(param).then((res: any) => {
      if (res?.success && Array.isArray(res?.data?.result)) {
        setTopproductList(res.data.result);
      }
    });
  };
  // 查询优惠券数据
  const getCouponsData = async () => {
    if (jointType === "coupon") {
      const couponsParams = {
        ownerType: "CONSUMER",
        voucherNo: voucherNo || undefined,
        couponId: couponId || undefined,
      };
      const res = await api.queryJointBill(couponsParams);
      if ((res as any)?.success) {
        setCouponInfo(res?.data);
        awdInstanceId.current =
          res?.data?.awdInstanceId || searchAwdInstanceId || couponId || voucherNo;
        baseAmount = res?.data?.baseAmount;
        promoActivityId.current = res?.data?.promoActivityId;
        awdId.current = res?.data?.awdId || urlAwdId;
      }
    }
  };
  // 查询红包余额
  const queryRedPacketBalance = () => {
    fetchAccountInfo().then((res) => {
      setBalance(res?.freeBalance);
    });
  };
  // 根据搜索参数更改筛选栏状态
  const changeFilterStatus = (param: Search) => {
    const { all, sale, price, brand } = param;
    const filter: FilterParams = {
      all: false,
      brand: false,
      price: false,
      sale: false,
    };
    if (all === "down") {
      filter.all = "down";
    }
    if (sale) {
      filter.sale = sale === "up" ? "up" : "down";
    }
    if (price) {
      filter.price = price ? "down" : false;
    }
    if (brand) {
      filter.brand = brand ? "down" : false;
    }
    // 检测是否全部为false
    const res = Object.keys(filter)
      .map((item) => filter[item])
      .reduce((total, item) => item || total);
    if (!res) {
      filter.all = "down";
    }
    setFilter(filter);
  };
  // 参数转换
  const dataConversion = (params: Search) => {
    const { all, sale, price, brand } = params;
    const resParams: any = {
      pageNum: pageNum,
      pageSize: pageSize,
      scene,
      soldOutStatus: false,
    };
    if (awdInstanceId.current) {
      resParams.awdInstanceId = awdInstanceId.current;
    }
    if (promoActivityId.current) {
      resParams.promoActivityIds = [promoActivityId.current];
    }
    if (awdId.current) {
      resParams.awdId = awdId.current;
    }
    if (all) {
      resParams.sortModel = 1;
      resParams.sortType = 2;
    }
    if (sale) {
      resParams.sortModel = 3;
      resParams.sortType = sale === "up" ? 1 : 2;
    }
    if (brand) {
      resParams.brandIds = brand;
    }
    if (price) {
      resParams.priceLow = (price as any).min;
      resParams.priceHigh = (price as any).max;
    }
    return { searchParams: resParams };
  };
  // 查询商卡
  const handleSearch = async (params?: Search, init = false) => {
    if (loading.current) {
      return;
    }
    loading.current = true;
    const param = dataConversion(params || searchParams);
    changeFilterStatus(params || searchParams);
    if (init) {
      setPageNum(1);
      setProductList([]);
      setHasNext(true);
      param.searchParams.pageNum = 1;
    }
    const res: any = await api.queryProductList(param);

    if (res?.success && Array.isArray(res?.data?.result)) {
      const list = init ? res?.data?.result : productList.concat(res?.data?.result);
      setProductList(list);
      setHasNext(res?.data?.hasNext ?? false);
      setPageNum(res?.data?.pageIndex + 1);
    } else {
      setHasNext(false);
    }
    loading.current = false;
  };
  // 控制筛选栏展示
  const handleFilter = (key: string, value: string | boolean) => {
    const filter: FilterParams = {
      all: false,
      sale: false,
      price: false,
      brand: false,
    };
    filter[key] = value;
    setFilter(filter);
  };
  const getFilterParams = () => {
    const params: any = {
      scene,
      needBrandWhenOnlyOne: true,
    };
    if (awdInstanceId.current) {
      params.awdInstanceId = awdInstanceId.current;
    }
    if (promoActivityId.current) {
      params.promoActivityIds = [promoActivityId.current];
    }
    if (awdId.current) {
      params.awdId = awdId.current;
    }
    // 获取筛选列表数据
    api.filterData(params).then((res: any) => {
      if (res && res.success) {
        const { priceRangeList = [], brandList = [] } = res.data;
        setPriceRangeList(priceRangeList);
        setBrandList(brandList);
      }
    });
  };
  // 生命周期初始化
  useEffect(() => {
    //获取加购面板灰度
    getCartPanelGray();
    entryShop().then(() => {
      // 查询优惠券
      getCouponsData().then(() => {
        // 查询商卡
        handleSearch();
        if (jointType === "coupon") {
          // 查询省心凑
          getProductsData();
        } else {
          // 查询红包余额
          queryRedPacketBalance();
        }
        // 查询筛选栏数据
        getFilterParams();
      });
      track.pageView();
    });
  }, []);
  useLayoutEffect(() => {
    document.title = jointType === "coupon" ? "优惠凑单" : "红包凑单";
  }, []);
  useActivate(() => {
    document.title = jointType === "coupon" ? "优惠凑单" : "红包凑单";
  });

  return {
    goBack,
    jointType,
    couponInfo,
    balance,
    topproductList,
    onClickProduct,
    onClickAddBtn,
    onClickShareBtn,
    filter,
    priceRangeList,
    brandList,
    handleFilter,
    searchParams,
    setSearchParams,
    handleSearch,
    productList,
    hasNext,
    secondResourceClick,
    cartModalVisible,
    modalProduct,
    setCartModalVisible,
    changeCartNum,
    showPoster,
    sharePosterData,
    customEditData,
    isOTC,
    onClosePoster,
    showNewCartPanel,
  };
};
