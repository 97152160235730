import { getBaseurl } from "@/utils/url-parse";
import {
  API,
  BrandCardModel,
  BrandListRequestParams,
  BrandModel,
  PageableResponse,
} from "@akc/biz-api";
import { Request } from "./request";

// 关注品牌
export async function followBrand(brandId: string): Promise<void> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brandFocus/follow/userFollow`,
    method: "get",
    params: {
      brandId,
    },
  });
  return res?.data;
}

// 取消关注品牌
export async function unfollowBrand(brandId: string): Promise<void> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brandFocus/follow/userCancelFollow`,
    method: "get",
    params: {
      brandId,
    },
  });
  return res?.data;
}

// 关键词查询品牌列表
export const queryBrandList = async (params?: BrandListRequestParams) => {
  return API.post<PageableResponse<BrandCardModel>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/brand/list",
    body: params,
  });
};
/**
 * @description  查询必卖日历信息
 * @link https://yapi.akcops.com/project/1799/interface/api/400083
 * @author 白国庆/高扬
 */
export async function querySaleCalendarInfoServ(data = {}): Promise<void> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brand/querySaleCalendarInfo`,
    method: "post",
    data,
  });
  return res?.data;
}
