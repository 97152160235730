import { useCommonContext } from "@/utils/context/common";
import { pxtorem } from "@/utils/pxtorem";
import { inject, observer } from "mobx-react";
import { default as React, useEffect } from "react";

interface Props {
  appStore?: any;
  [key: string]: any;
}
const VideoSharerInstruction: React.FC<Props> = () => {
  const { track } = useCommonContext();

  useEffect(() => {
    if (track.track) {
      track.track("$pageview", {
        page_name: "视频号分享员",
        previous_page_name: "视频号分享员-直播预告",
      });
    }
  }, []);
  return (
    <div>
      <div
        style={{
          height: "100vh",
          overflow: "scroll",
          backgroundColor: "#e9e0f1",
        }}
      >
        <div>
          <img
            src="https://akim-oss.aikucun.com/9d43fecc42b50ec34129cff4365dcadfed307e30_1699327626657_98.png"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div
          style={{
            paddingLeft: pxtorem(16),
            paddingRight: pxtorem(16),
            paddingBottom: pxtorem(12),
          }}
        >
          <div style={{ paddingBottom: pxtorem(9) }}>
            <img
              src="https://akim-oss.aikucun.com/4008cfa6eedc41ba634995770c8a44dbab077b7e_1699327940289_98.png"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div style={{ paddingBottom: pxtorem(9) }}>
            <img
              src="https://akim-oss.aikucun.com/c1ee475a0be3148d06338b1e67db031a7a6fe5d9_1699328396836_74.png"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div style={{ paddingBottom: pxtorem(9) }}>
            <img
              src="https://akim-oss.aikucun.com/d711a99b72cc456639bcdcc609aed2b8e126129b_1699328434304_99.png"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div style={{ paddingBottom: pxtorem(9) }}>
            <img
              src="https://akim-oss.aikucun.com/25d08b0027db56c3a9c21ca5292b961fe5071096_1699328462809_8.png"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default inject("appStore")(observer(VideoSharerInstruction));
