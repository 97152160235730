import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import ProductList from "./components/productList";
import SortAndFilter from "./components/sortAndFilter";
import { SelectiveProductProvider } from "./hooks/useSelectiveProduct";
import styles from "./index.module.less";

const SelectiveForward: React.FC = () => {
  const [search] = useSearchParams();
  const queryData = {
    activityIdList: search.get("activityIdList")?.split(",") || undefined,
    brandIds: search.get("brandIds")?.split(",") || undefined,
    categoryIdList: search.get("categoryIdList")?.split(",") || undefined,
    shopCodes: search.get("shopCodes")?.split(",") || undefined,
  };

  const sortAndFilterDomRef = useRef<any>(null);

  useActivate(() => {
    document.title = "选择商品";
  });

  useEffect(() => {
    document.title = "选择商品";
  }, []);

  return (
    <SelectiveProductProvider
      pageId={"H5_TopProduct"}
      scene={"SELECTIVE_FORWARDING_PAGE"}
      options={queryData}
    >
      <div className={styles["container"]} id="selective-page">
        <Header />
        <SortAndFilter ref={sortAndFilterDomRef} />
        <ProductList />
        <Footer />
      </div>
    </SelectiveProductProvider>
  );
};

export default SelectiveForward;
