import AKJS from "@akc/akjs";
import { platform } from "./platform";

export const setTitle = (options: {
  appOptions?: {
    title: string;
    color?: string;
  };
  h5Options?: {
    title: string;
  };
}) => {
  const { isApp } = platform;
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.navigationBar.config.title", options.appOptions);
    });
  } else {
    document.title = options.h5Options?.title || "";
  }
};
