import {
  ActivityTaskItem,
  ActivityTaskParams,
  CouponItem,
  queryActCount,
  queryCouponPage,
  queryPage,
  queryRebateBanner,
  queryRedPacketList,
  queryRedPacketSummary,
} from "@/api/rewardCenter";
import { getUrlParam } from "@/utils/url-parse";
import constate from "constate";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  query: ActivityTaskParams;
  type?: "Search" | "Reward";
  setQuery?: (query: ActivityTaskParams) => void;
}

const useRewardCenterData = ({ query, type = "Reward", setQuery }: IProps) => {
  const [search] = useSearchParams();
  const [list, setList] = useState<ActivityTaskItem[]>([]);
  const [couponList, setCouponList] = useState<CouponItem[]>([]);
  const [activityList, setActivityList] = useState<ActivityTaskItem[]>([]);
  const [hasCoupon, sethasCoupon] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [banner, setBanner] = useState<any>();
  const [redPacketSummary, setRedPacketSummary] = useState<any>({});
  const [actStatus10, setActStatus10] = useState(0);
  const [actStatus20, setActStatus20] = useState(0);
  const [actStatus30, setActStatus30] = useState(0);
  const [actStatus40, setActStatus40] = useState(0);
  const [total, setTotal] = useState(0);
  // 满返/PK/满赠 任务是否存在数据 
  const [fullReturnRedPoint, setFullReturnRedPoint] = useState(false);
  const [rankRedPoint, setRandRedPoint] = useState(false);
  const [giftReturnRedPoint, setGiftReturnRedPoint] = useState(false);
  // 活动数量初始请求标示
  const [isInit, setIsInit] = useState(true);
  // 如果初始请求自动切换至进行中，则记录标示， 需重复请求列表接口，直到进行中子tab有数据为止
  // 0 无数据-无需自动切换 1-有数据-子tab数据需重新请求 2-有数据-子tab数据无需重新请求
  const [hasList, setHasList] = useState(0);

  const numInfo = useMemo(() => {
    return {
      actStatus10,
      actStatus20,
      actStatus30,
      actStatus40,
      total,
      fullReturnRedPoint,
      rankRedPoint,
      giftReturnRedPoint,
    };
  }, [actStatus10, actStatus20, actStatus30, actStatus40, total, fullReturnRedPoint, rankRedPoint, giftReturnRedPoint]);

  const queryBanner = async () => {
    try {
      const data = await queryRebateBanner();
      setBanner(data || null);
    } catch (error) {
      console.log(error);
    }
  };

  const queryCount = async (params?: ActivityTaskParams) => {
    const { pageIndex, ...queryParams } = params || {};
    let num = pageIndex || pageNum || 1;
    try {
      const data: any = await queryActCount({
        ...(queryParams || query || {}),
        pageIndex: num,
        pageSize: 10,
      });
      setActStatus10(data?.unparticipatedCount || 0);
      setActStatus20(data?.underWayCount || 0);
      setActStatus30(data?.waitRewardCount || 0);
      setActStatus40(data?.openedLotteryCount || 0);
      setFullReturnRedPoint(data?.fullReturnRedPoint || false);
      setRandRedPoint(data?.rankRedPoint || false);
      setGiftReturnRedPoint(data?.giftReturnRedPoint || false);
      return data?.underWayCount || 0;
    } catch (error) {
      console.log(error);
    }
    return false;
  };
  const getRedPacketSummary = async (params?: any) => {
    const { pageIndex, ...queryParams } = params || {};
    try {
      const data: any = await queryRedPacketSummary({ ...(queryParams || query || {}) });
      setRedPacketSummary(data);
    } catch (error) {
      console.log(error);
    }
  };

  const queryCouponList = async (params?: any, noLoading?: boolean) => {
    // 无需关心loading，强制请求
    if (!noLoading) {
      if (loading) {
        return;
      }
      setLoading(true);
    }
    const { pageIndex } = params || {};
    let num = pageIndex || pageNum || 1;
    let list: any = [];
    try {
      const data: any = await queryCouponPage({
        pageNo: num,
        pageSize: 10,
        status: 0,
        brandId: params?.brandId,
        shopCode: params?.shopCode,
      });
      list = data?.result || [];
      if (num > 1 && list.length > 0) {
        setCouponList(couponList.concat(list) || []);
      } else {
        setCouponList(list);
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
      sethasCoupon(data?.total > 0);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const queryActivityList = async (params?: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { pageIndex } = params || {};
    let num = pageIndex || pageNum || 1;
    let list: any = [];
    try {
      const data: any = await queryRedPacketList({
        pageNo: num,
        pageSize: 10,
        brandId: params.brandId,
        shopCode: params.shopCode,
      });
      list = data?.result || [];
      if (num > 1 && list.length > 0) {
        setActivityList(activityList.concat(list) || []);
      } else {
        setActivityList(list);
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  //获取tab下的列表
  const queryActList = async (params?: ActivityTaskParams) => {
    if (loading) {
      return;
    }
    console.log("queryList queryActList", params);
    setLoading(true);
    const { pageIndex, ...queryParams } = params || {};
    let num = pageIndex || pageNum || 1;
    let actList: any = [];
    try {
      if (num === 1) {
        setList([]);
      }
      const data: any = await queryPage({ ...(queryParams || {}), pageIndex: num, pageSize: 10 });
      // 结果对应的查询条件 与 当前页面的查询条件不匹配 则直接终止
      if (
        queryParams.searchType !== query.searchType ||
        queryParams.actSellRebateStatus !== query.actSellRebateStatus ||
        queryParams.promoActivityType !== query.promoActivityType
      ) {
        return;
      }
      actList = data?.result || [];
      // 状态为进行中 且 存在进行中有数据的标识（hasList=1）并且当前子tab下没有数据，则切换搜索条件重新触发搜索
      if (queryParams?.actSellRebateStatus === 20) {
        if (hasList === 1 && actList.length === 0) {
          setQuery?.({
            ...(queryParams || {}),
            pageIndex: num,
            pageSize: 10,
            taskWinningStatus: "NOT_WINNING_NO_GMV",
            refreshAll: false
          });
        } else if (hasList === 1) {
          // 子tab有数据 更新hasList为2
          setHasList(2);
        }
      } 
      if (num > 1 && actList.length > 0) {
        setList(list.concat(actList));
      } else {
        setList(actList);
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
      const total = Number(data?.total || 0);
      setTotal(total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  // 页面列表查询接口
  const queryList = async (params?: ActivityTaskParams) => {
    console.log("queryList type", type, "isInit", isInit, "params", params);
    if (params?.searchType === 5) {return;}
    if (type === "Reward" && params?.refreshAll && params?.pageIndex === 1) {
      if ([1, 2].includes(params?.searchType as number)) {
        if (isInit) {
          setIsInit(false);
          const status20 = await queryCount(params) || 0;
          console.log(status20);
          // 页面初始请求，如果进行中存在数据，且当前状态不是进行中，则切换到进行中状态
          if (status20 > 0 && params.actSellRebateStatus !== 20) {
            params.actSellRebateStatus = 20;
            params.taskWinningStatus = "HAS_GMV";
            params.refreshAll = false;
            // 设置进行中有数据标识
            setHasList(1);
            setQuery?.(params);
            // 条件变更，拦截后续请求重新请求，重新请求
            return;
          }
        } else {
          queryCount(params);
        }
      }
      if (params?.searchType === 4) {
        // 获取红包统计信息
        getRedPacketSummary();
      }
    }
    // 分享券查询
    if (params?.searchType === 3) {
      await queryCouponList(params);
    } else if (params?.searchType === 4) {
      // 红包查询
      await queryActivityList(params);
    } else if ([1, 2].includes(params?.searchType as number)) {
      // 活动查询
      await queryActList(params);
    }
  };
  // 加载更多
  const loadMore = async () => {
    if (hasMore) {
      await queryList({ ...query, refreshAll: false });
    }
  };

  // 查询条件变更，重新请求
  useEffect(() => {
    if (query.searchType !== undefined && query.searchType !== null) {
      setLoading(false);
      setHasMore(true);
      setPageNum(1);
      queryList({ ...query, shopCode: search.get("shopCode") || undefined, pageIndex: 1 });
    }
  }, [query, search.get("shopCode")]);

  useEffect(() => {
    if (type !== "Reward") {
      return;
    }
    queryBanner();
    queryCouponList(null, true);
  }, [search.get("shopCode")]);

  return {
    list,
    couponList,
    activityList,
    numInfo,
    banner,
    redPacketSummary,
    loading,
    hasMore,
    pageNum,
    queryList,
    loadMore,
    getRedPacketSummary,
    hasCoupon,
  };
};

export default useRewardCenterData;
