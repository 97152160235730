import { getBaseurl } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { Request } from "./request";

export type YeePayQueryOrderDetailsResponse = {
  orderNo: string;
  payAmount: string;
  subOrderNo: string;
  shopId: string;
  distributorId?: string;
  userId?: string;
  activitySpuIds?: string[];
  categoryCodeList: string[];
};
/**
 * 易宝支付查询
 *
 * https://yapi.akcops.com/project/867/interface/api/379869
 */
export const yeePayQueryOrderDetails = (
  params: {
    /** MD5校验码 */
    checkCode: string;
    /** 商户订单号 */
    outTradeNo: string;
    /** 特约商户号 */
    subMchId: string;
  },
  timeout = 10000,
) =>
  API.post<YeePayQueryOrderDetailsResponse>({
    hostID: "h5shop",
    path: "/api/payment/yee/payQuery",
    body: params,
    timeout,
  });

/**
 * 获取新分享券链接
 * https://yapi.akcops.com/project/993/interface/api/413034
 */
export const queryCouponShareFromPay = (params: {
  shopId: string;
  orderId?: string;
  couponId?: string;
}) =>
  API.get<{ link?: string; placardImgUrl?: string }>({
    path: `/api/share/unity/prod/mall/coupon/newShareCoupons/${params.shopId}`,
    body: params,
  });

export interface SubscribeOfficialResponse {
  isSubscribe?: boolean;
  /** 跳转链接 */
  showLink?: string;
}
/**
 * 查询关注公众号状态
 * https://yapi.akcops.com/project/2507/interface/api/413025
 */
export const querySubscribeOfficial = (userId: string) =>
  API.get<SubscribeOfficialResponse>({
    path: "/api/member/subscribeOfficialAccount",
    body: {
      userId,
    },
  });

export function selectCouponInfoPaySuccess(params) {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/after/share/coupon/iteration/selectCouponInfo`,
    method: "get",
    params,
  });
}

export function selectCouponInfo(params) {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/after/share/coupon/selectCouponInfo`,
    method: "get",
    params,
  });
}

export function showCardPopup(params) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/vip/card/popup`,
    method: "get",
    params,
  });
}
