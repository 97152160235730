import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import exposure from "@/utils/expose/exposure";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { Image, InfiniteScroll, SafeArea } from "antd-mobile";
import React, { useEffect, useState } from "react";
import RewardEmpty from "../Empty";
import RewardTabs from "../RewardTabs";
import styles from "./search.module.less";

import { ActivityTaskItem, ActivityTaskParams, CouponItem as CouponItemDs } from "@/api/rewardCenter";
import { shareActivity } from "@/utils/share/share";
import ActivityTask from "../activity-task/activity-task";
import CouponItem from "../coupon-item/coupon-item";
import RedPackItem from "../red-pack/redpacket-item";
import { useSearchContext } from "./useSearch";

const tabs: {
  name: React.ReactNode;
  value: number;
  key: string;
}[] = [
  {
    key: "limitedTimeList",
    name: "限时激励",
    value: 1,
  },
  {
    key: "benefitsList",
    name: "权益",
    value: 2,
  },
  {
    key: "redPackList",
    name: "转发",
    value: 4
  },
  {
    key: "couponAwdList",
    name: "分享券",
    value: 3,
  },
];
//加载更多
const InfiniteScrollContent = ({ hasMore, list }: { hasMore?: boolean; list: any[] }) => {
  return (
    <div className={styles.infinite}>
      {hasMore ? (
        <div className={styles.loadingMore}>
          <Image src={loadingPng} width={pxtorem(20)}></Image>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {list?.length > 0 && (
            <>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
              <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
            </>
          )}
        </div>
      )}
    </div>
  );
};
interface IProps {
  loading: boolean;
  activeTab: ActivityTaskParams["searchType"];
  list: any[];
  hasMore: boolean;
  loadMore: () => Promise<any>;
  onChange?: (value: ActivityTaskParams["searchType"]) => void;
}

const SearchContent: React.FC<IProps> = ({
  loading,
  activeTab,
  list,
  onChange,
  hasMore,
  loadMore,
}) => {
  const track = Track();
  const { showSearchTag, searchTagText } = useSearchContext();

  const handleTabChange = (value: ActivityTaskParams["searchType"]) => {
    onChange && onChange(value);
  };
  // 埋点曝光元素记录
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < list.length; i++) {
        let id = "cnt-";
        if (activeTab === 3) {
          const item = list[i] as CouponItemDs;
          id += item.couponId || item.voucherNo;
        } else {
          id += (list[i] as ActivityTaskItem).actId;
        }
        const dom = document.getElementById(id);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [activeTab, list]);

  return (
    <>
      {showSearchTag && (
        <>
          {searchTagText ? (
            <>
              <RewardTabs tabs={tabs} value={activeTab} onChange={handleTabChange} />
              <div className={styles.content}>
                {[1, 2].includes(activeTab as number) && (
                  <>
                    {list?.map((item) => <ActivityTask key={item.actCode} data={item} />)}
                    {(list?.length || 0) <= 0 && <RewardEmpty>抱歉，暂无活动</RewardEmpty>}
                  </>
                )}
                {activeTab === 3 && (
                  <>
                    {list?.map((item) => <CouponItem key={item.couponId} data={item} />)}
                    {(list?.length || 0) <= 0 && <RewardEmpty>抱歉，暂无分享券</RewardEmpty>}
                  </>
                )}
                {activeTab === 4 && (
                  <>
                    {list?.map((item, index) => (
                      <RedPackItem key={item.activityId} data={item} index={index} />
                    ))}
                    {(list?.length || 0) <= 0 && <RewardEmpty>抱歉，暂无分享券</RewardEmpty>}
                  </>
                )}
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
                  <InfiniteScrollContent list={list} hasMore={hasMore} />
                </InfiniteScroll>
                <SafeArea position="bottom" />
              </div>
            </>
          ) : (
            <RewardEmpty>{activeTab === 3 ? "暂无分享券" : "暂无活动"}</RewardEmpty>
          )}
        </>
      )}
    </>
  );
};

export default SearchContent;
