import { ProductDetailModel } from "@akc/biz-api";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  productDetail?: ProductDetailModel;
  //转发
  onShareClick?: () => void;
  //回到顶部
  onScrollTop?: () => void;
};

/**
 * 浮动菜单按钮
 */
const FloatMenuComp: React.FC<Props> = (prop) => {
  const product = prop.productDetail;
  return (
    <div className={styles.floatM}>
      {product?.pricePanelType !== 13 && product?.pricePanelType2 !== 7 && (
        <div>
          {!prop?.productDetail?.skuCurrentPriceInfo?.commission ? (
            <div onClick={() => prop.onShareClick?.()}>
              <Image
                className={`${styles["floatIcon"]} ${product?.skuExtendInfoVO?.isHideByManager ? styles["hideDisabled"] : styles[""]}`}
                src={
                  "https://akim-oss.aikucun.com/501c6e8493e0ee1a455837957d87147a386ef722_1677065941186_56.png"
                }
              />
            </div>
          ) : (
            <div onClick={() => prop.onShareClick?.()}>
              <Image
                className={`${styles["floatIcon"]} ${product?.skuExtendInfoVO?.isHideByManager ? styles["hideDisabled"] : styles[""]}`}
                src={
                  "https://akim-oss.aikucun.com/22c7c3ad23da312a93522991cd7a35d3b21537a9_1677065960317_20.png"
                }
              />
            </div>
          )}
        </div>
      )}
      <div
        onClick={() => {
          prop.onScrollTop?.();
        }}
      >
        <Image
          className={styles.floatIcon}
          src={
            "https://akim-oss.aikucun.com/4fe5417f0ef032aadefe6d23778c03a0cee1ed21_1677065917343_94.png"
          }
        />
      </div>
    </div>
  );
};

export default FloatMenuComp;
