import { preferenceSave, queryCategoryList, queryPreferenceList } from "@/api/promote";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTrack } from "../../track";
import styles from "./index.module.less";

export interface ListEntity {
  code: string;
  name: string;
  [key: string]: any;
}
interface CategoryProps {
  handleChosen: (item: ListEntity, isInit?: boolean) => void;
}
const Category: React.FC<CategoryProps> = (props: CategoryProps) => {
  const { handleChosen } = props;
  const [chosen, setChosen] = useState<Array<ListEntity>>([]);
  const [activeItem, setActiveItem] = useState<string>("");
  const [unchosen, setUnChosen] = useState<Array<ListEntity>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const shopId = search.get("shopId");
  const fromPage = search.get("fromPage");
  const { trackTabClick, trackTabExpose, trackCategoryClick } = useTrack();
  const BackPng =
    "https://akim-oss.aikucun.com/b07f427927175d0e745611f0d4342969f9f468f3_1701164604452_70.png";
  const HomePng =
    "https://akim-oss.aikucun.com/8cb8a10dbe75e006c04d3ff2036b4d61353908d6_1701164487068_67.png";
  const setInitList = async (needUpdate?: boolean) => {
    const [res1, res2] = await Promise.all([queryCategoryList(), queryPreferenceList()]);
    if (res1 && res1.data && res2 && res2.data) {
      // const chosenList = res2.data.length > 0 ? res2.data : res1.data;
      const chosenList = res1.data;
      const unChosenList = res1.data.filter((item: ListEntity) => {
        const index = chosenList.findIndex((i: ListEntity) => i.code === item.code);
        return index === -1;
      });
      setChosen(chosenList);
      setUnChosen(unChosenList);
      needUpdate && setActiveItem(chosenList[0].code);
      needUpdate && handleChosen(chosenList[0], true);
    }
  };

  useEffect(() => {
    setInitList(true);
  }, []);
  const choseItem = (item: ListEntity) => {
    const index = chosen.findIndex((i) => i.id === item.id);
    const categoryDom = document.getElementById(`category-item-${item.id}`) as HTMLElement;
    setActiveItem(item.code);
    handleChosen(item, true);
    categoryDom.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "nearest",
    });
    trackTabClick({ item, index, fromPage });
    trackTabExpose({ item, index, fromPage });
  };
  const toIndex = useCallback(async () => {
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  }, []);
  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigate(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigate(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };
  function Modal() {
    const closeModal = () => {
      setShowModal(false);
    };
    const addCategory = async (item: ListEntity) => {
      trackCategoryClick({ item, resource_type: 1, fromPage });
      const chosenItems = [...chosen];
      const unChosenItems = [...unchosen];
      const unChosen = unChosenItems.filter((i) => i.code !== item.code);
      chosenItems.push(item);
      const codes = chosenItems.map((i) => i.code);
      try {
        const res = (await preferenceSave({ codes })) as any;
        if (res && res.success === true) {
          setChosen(chosenItems);
          setUnChosen(unChosen);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const delCategory = async (item: ListEntity) => {
      if (item.code === "all" || item.code === activeItem) {
        return;
      }
      const chosenItems = [...chosen];
      const index = chosenItems.findIndex((i) => i.code === item.code);
      trackCategoryClick({ item, resource_type: 0, fromPage });
      chosenItems.splice(index, 1);
      const codes = chosenItems.map((i) => i.code);
      try {
        const res = (await preferenceSave({ codes })) as any;
        if (res && res.success === true) {
          setInitList(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    return (
      <div className={styles.modalWrapper}>
        <div onClick={closeModal} className={styles.mask}></div>
        <div className={styles.tabWrapper}>
          <div className={styles.title}>我的类目</div>
          <img
            onClick={closeModal}
            className={styles.pull}
            src="https://akim-oss.aikucun.com/5d5815647c76e193ee289b94a0c43bf26e8aa23e_1700031338311_35.png"
          />
          <div className={styles["tab-list"]}>
            {chosen &&
              chosen.map((item: ListEntity) => {
                return (
                  <div
                    onClick={() => delCategory(item)}
                    className={`${styles["tab-item"]} ${
                      activeItem === item.code ? styles["active"] : ""
                    }`}
                    key={item.code}
                  >
                    {item.name}
                    {item.code !== "all" && item.code !== activeItem && (
                      <img
                        className={styles["close-icon"]}
                        src="https://akim-oss.aikucun.com/feacb8e58e1e4974fd9a12dc2e5e730ffd3ec89f_1700030743451_56.png"
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <div className={styles.title}>全部类目</div>
          <div className={styles["tab-list"]}>
            {unchosen &&
              unchosen.map((item: ListEntity) => {
                return (
                  <div
                    onClick={() => addCategory(item)}
                    className={styles["tab-item"]}
                    key={item.code}
                  >
                    <img
                      className={styles["add-icon"]}
                      src="https://akim-oss.aikucun.com/9132e087f1bf1ec918d99ba611cc565ee51c54d8_1700031660068_58.png"
                    />
                    {item.name}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {fromPage === "productDetail" && (
        <div className={styles.NavContentBox}>
          <div className={styles.ImageBox} onClick={goBackOrHome}>
            <img src={BackPng} className={styles.NavImage} />
          </div>
          <div className={styles.ImageBox} onClick={toIndex}>
            <img src={HomePng} className={styles.NavImage} />
          </div>
        </div>
      )}
      {fromPage === "productDetail" ? (
        <div className={styles["hide-category"]}></div>
      ) : (
        <div className={styles["category-wrapper"]}>
          <div className={styles["category-list"]}>
            {chosen &&
              chosen.map((item: ListEntity) => {
                return (
                  <div
                    id={`category-item-${item.id}`}
                    onClick={() => {
                      choseItem(item);
                    }}
                    className={`${styles["category-item"]} ${
                      activeItem === item.code ? styles["active"] : ""
                    }`}
                    key={item.code}
                  >
                    {" "}
                    {item.name}
                  </div>
                );
              })}
          </div>
          {/* <div className={styles["add-area"]}> */}
          {/* <img onClick={() => setShowModal(true)} className={styles["add-icon"]} src="https://akim-oss.aikucun.com/945dc5cf126d93d64a5a989d56821c2f2388f698_1699950360827_2.png" /> */}
          {/* </div> */}
          {showModal && <Modal />}
        </div>
      )}
    </>
  );
};

export default Category;
