import { getUrlParam } from "@/utils/url-parse";
import constate from "constate";
import { useState } from "react";

const useDecorate = () => {
  const [selectedNav, setSelectedNav] = useState<any>(null);
  const [shareBtnText, setShareBtnText] = useState<string>("转发会场");
  const [brandId, setBrandId] = useState<any>(null);
  return {
    selectedNav,
    setSelectedNav,
    shareBtnText,
    setShareBtnText,
    brandId,
    setBrandId,
  };
};

const [UseDecorateProvider, UseDecorateContext] = constate(useDecorate);
export { UseDecorateContext, UseDecorateProvider };
