import { getCartTotal } from "@/api";
import { MICRO_APP_BASEURL, entryShop } from "@/utils/platform";
import React, { useCallback, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import Recommend from "../promote/components/recommend";
import styles from "./index.module.less";
// 页面接受参数
// tab: 1 | 2   1进入发现页，2进入推荐页
// materialNo: string  素材编号
// shopId: string  店铺id
// distributorId: string  分销员id
// objectIds string,string  用于根据商品查询的信息
// queryType 用于根据商品查询的信息
// baseProductIds string,string 用于根据商品查询的信息

function RecommendList() {
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const fromPage = search.get("fromPage");
  const [cartNum, setCartNum] = useState<number>(0);

  const navigate = useNavigate();
  const queryGetCartTotal = async () => {
    console.log(shopId, "shopId");
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartNum(cartSkuNum);
  };
  const setTitle = () => {
    document.title = fromPage === "productDetail" ? "实拍专区" : "逛一逛";
  };
  useEffect(() => {
    setTitle();
    entryShop();
    queryGetCartTotal();
  }, []);
  useActivate(() => {
    setTitle();
    queryGetCartTotal();
  });
  const gotoSearch = () => {
    localStorage.removeItem("search_tag");
    navigate(
      `/mx-shop-micro/enticeSearch?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
        "distributorId",
      )}`,
    );
  };
  const toIndex = useCallback(async () => {
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  }, []);

  const search2 =
    "https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1700044454438_19.png";
  const cart =
    "https://akim-oss.aikucun.com/371a5384044948994735af6426ee4634f70273de_1700102728165_73.png";
  const BackPng =
    "https://akim-oss.aikucun.com/b07f427927175d0e745611f0d4342969f9f468f3_1701164604452_70.png";
  const HomePng =
    "https://akim-oss.aikucun.com/8cb8a10dbe75e006c04d3ff2036b4d61353908d6_1701164487068_67.png";
  const toCart = useCallback(async () => {
    if (search.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2`);
    }
  }, []);
  return (
    <div className={styles["promote-container"]}>
      {
        <>
          <div className={`${styles["tab-list"]} ${styles["tab-list2"]}`}>
            <div className={styles.NavContentBox}>
              <div className={styles.ImageBox} onClick={() => navigate(-1)}>
                <img src={BackPng} className={styles.NavImage} />
              </div>
              <div className={styles.ImageBox} onClick={toIndex}>
                <img src={HomePng} className={styles.NavImage} />
              </div>
            </div>
            <div className={styles["icon-area"]}>
              <img onClick={gotoSearch} className={styles["search-icon"]} src={search2} />
              <div className={styles["cart-area"]} onClick={toCart}>
                {cartNum > 0 && <div className={styles["cart-num"]}>{cartNum}</div>}
                <img className={styles["cart-icon"]} src={cart} />
              </div>
            </div>
          </div>
          <div className={styles["ocupy-area"]}></div>
        </>
      }
      <Recommend activeTab="2" />
    </div>
  );
}

export default RecommendList;
