import { exchangeUserRole, UserInfo } from "@/api/user";
// import * as Sentry from "@sentry/react";
import { ErrorInfo } from "react-dom/client";

const enableSentry =
  process.env.REACT_APP_MODE === "production" || process.env.REACT_APP_MODE === "release";

/**
 * 初始化 Sentry
 */
export const initSentry = async () => {
  if (enableSentry) {
    const dsn = process.env.REACT_APP_GRAY
      ? "https://a6d573350bfe4cbc8e612a662c7cb406@sentrynew.aikucun.com/31"
      : "https://07fd415169c3420fb1935f3294fcfa45@sentrynew.aikucun.com/29";

    let version = "1.0.0";
    try {
      version = (await import("@/version")).default;
      console.log("读取到 version", version);
    } catch (error) {
      // 忽略错误
      console.log("读取 version 失败", error);
    }
    // Sentry.init({
    //   dsn,
    //   release: version,
    //   environment: process.env.REACT_APP_MODE,
    //   integrations: [new Sentry.BrowserTracing()],
    //   tracesSampleRate: 1.0,
    // });
  }
};

/**
 * 设置用户
 */
export const setUser = (user?: UserInfo & { [key: string]: any }) => {
  if (enableSentry) {
    // Sentry.setUser(
    //   user
    //     ? {
    //         ...user,
    //         id: user.openId, // user.id = openId
    //         username: user.shopId, // user.username = shopId
    //         email: user.phone, // user.email = phone
    //         distributorId: user.distributorId,
    //         role: exchangeUserRole(user.currentRole),
    //       }
    //     : null,
    // );
  }
};

/**
 * 捕获异常
 */
export const captureException = (error: Error | unknown, errorInfo?: ErrorInfo) => {
  if (enableSentry) {
    // Sentry.captureException(error, {
    //   extra: {
    //     errorInfo,
    //   },
    // });
  }
};

/**
 * 捕获异常
 */
export const captureMessage = (message: string, level: "info" | "error" = "info") => {
  if (enableSentry) {
    // Sentry.captureMessage(message, level);
  }
};
