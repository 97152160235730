import { MICRO_APP_BASEURL } from "@/utils/platform";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const distributorId = search.get("distributorId");
  const backIcon =
    "https://akim-oss.aikucun.com/mshop/b07f427927175d0e745611f0d4342969f9f468f3_1713854160494_35.png";
  const homeIcon =
    "https://akim-oss.aikucun.com/mshop/8cb8a10dbe75e006c04d3ff2036b4d61353908d6_1713854179343_45.png";

  const goHome = () => {
    if (distributorId) {
      navigate(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&distributorId=${distributorId}&channel=h5Mall`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };

  return (
    <>
      <div className={styles["header"]}>
        <div className={styles["header_left"]}>
          <div className={styles["back"]} onClick={() => navigate(-1)}>
            <img src={backIcon} />
          </div>
          <div className={styles["home"]} onClick={goHome}>
            <img src={homeIcon} />
            首页
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
