import { queryCustomerTrace } from "@/api/userTrace";
import { UserTrackResponseData } from "@/types";
import { platform } from "@/utils/platform";
import { Toast } from "antd-mobile";
import { useEffect, useRef, useState } from "react";

const useTrackList = () => {
  const pageId = useRef<string>();
  const locationCode = useRef<string>();
  const [listData, setListData] = useState([]);
  const [listLoading, setListLoading] = useState<boolean>(false);
  const initLocationCode = () => {
    if (platform.isH5 || platform.isWxH5) {
      locationCode.current = "H5_History_ProductTitleDown";
      pageId.current = "H5_History";
    } else if (platform.isMini) {
      locationCode.current = "Mini_Program_History_ProductTitleDown";
      pageId.current = "Mini_Program_History";
    } else if (platform.isApp) {
      locationCode.current = "App_History_ProductTitleDown";
      pageId.current = "App_History";
    }
  };

  useEffect(() => {
    initLocationCode();
    const updateListData = async () => {
      setListLoading(true);
      try {
        const res: UserTrackResponseData = await queryCustomerTrace({ pageId: pageId.current });
        if (res?.success) {
          if (res?.data?.length) {
            setListData(res?.data || []);
          }
        } else {
          Toast.show(res.message || "请求失败");
        }
        setListLoading(false);
      } catch (error) {
        setListLoading(false);
      }
    };
    updateListData();
  }, []);

  return {
    locationCode,
    listData,
    listLoading,
  };
};

export default useTrackList;
