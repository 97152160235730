import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import MaterialCard from "./MaterialCard";
import ProductCard from "./ProductCard";
import styles from "./ShoppingMessageCard.module.less";
import UserCard from "./UserCard";

interface shoppingMessageCardProps {
  item: any;
  key: number;
  index: number;
  onShared: (index) => void;
  onResourceClick: (index, obj) => void;
}

const ShoppingMessageCard: React.FC<shoppingMessageCardProps> = ({
  item,
  index,
  onShared,
  onResourceClick,
}) => {
  return (
    <div className={styles.shoppingMessageCard}>
      <UserCard userInfo={item} index={index} onResourceClick={onResourceClick} />
      {item.showType === 1 && (
        <ProductCard
          index={index}
          productInfo={item.productVO}
          shared={item.shared}
          onShared={onShared}
          onResourceClick={onResourceClick}
        />
      )}
      {item.showType === 2 && (
        <MaterialCard
          index={index}
          materialInfo={item.materialVO}
          shared={item.shared}
          onShared={onShared}
          onResourceClick={onResourceClick}
        />
      )}
    </div>
  );
};

export default ShoppingMessageCard;
