import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface IProps {
  coupon: any;
  type?: string;
}

const couponItemTwo: React.FC<IProps> = ({ coupon, type }) => {
  return (
    <div
      className={`${styles.couponItem} ${styles.two} ${
        type === "newcomer" ? styles.newcomer : styles.venue
      }`}
    >
      {type === "newcomer" &&
        coupon.buttonStatus === 1 &&
        coupon.currentNum &&
        coupon.currentNum < 100 && (
          <div className={styles.couponNum}>仅剩{coupon.currentNum}张</div>
        )}
      <div className={styles.main}>
        <div className={styles.name}>{coupon.brandName || coupon.couponName}</div>
        <div className={styles.flexJcc}>
          <span className={styles.amount}>{coupon.amount}</span>
          <span className={styles.rmb}>元</span>
        </div>
      </div>
      <div className={styles.time}>
        {coupon.diffDays > 0 && coupon.diffDays < 1 && (
          <>
            <span className={styles.theme}>{coupon.countdownDesc}</span>
            <span>后到期</span>
          </>
        )}
        {coupon.diffDays >= 1 && coupon.diffDays <= 3 && (
          <span>{coupon.diffDaysInteger}天后到期</span>
        )}
        {(coupon.diffDays > 3 || coupon.diffDays === 0) && <span>{coupon.endTimeDesc}到期</span>}
      </div>
    </div>
  );
};

export default couponItemTwo;
