import { getCookie } from "@/utils/platform";
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./stimulation-card.module.less";

type Props = {
  list?: any;
  onChangeTask: (task: any) => void;
};
const StimulationCard: React.FC<Props> = ({ list = {}, onChangeTask }) => {
  const [search] = useSearchParams();
  const brandActivityCode = search.get("brandActivityCode");
  const navigater = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);
  const [currentTask, setCurrentTask] = useState<any>({});

  const toRankPage = (currentTask) => {
    let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    navigater(
      `/mx-shop-micro/year-end-bonus/rank?brandActivityCode=${brandActivityCode}&ruleType=${currentTask.ruleType}&userType=${currentTask.userType}&token=${search.get("token")}&shopId=${search.get(
        "shopId",
      )}&env_identity=${envid || getCookie("X-Hades-Env-Identity")
      }`,
    );
  };

  const formatNumber = (num) => {
    return Math.min(num, 100);
  };

  const componentDom = () => {
    if (list?.length > 1) {
      return (
        // 多个激励
        <div className={styles.multi}>
          {/* tab */}
          <div className={styles.multi__tab}>
            {
              list.map((item, index) => {
                return <div className={`${styles.tab__item} ${index === tabIndex && styles.tab__active}`} key={index} onClick={() => setTabIndex(index)}>{item?.tabContent}</div>;
              })
            }
          </div>
          {/* 激励 */}
          <div className={styles.multi__achievement}>
            {/* 条件1: GMV业绩需达成 */}
            {
              currentTask?.gmvAim > 0 && (
                <div className={styles.achievement__task}>
                  <h4 className={styles.task__title}>{currentTask?.rankPrefixContent}1 : GMV业绩需达成</h4>
                  <div className={styles.task__progress}>
                    <div className={`${styles.progress__base} ${currentTask.gmvSuccessFlag && styles.progress__success}`}>
                      <div className={styles.current} style={{ width: `${formatNumber(currentTask?.userGmv / currentTask?.gmvAim * 100)}%` }}>
                        {
                          currentTask.userGmvStr && <div className={styles.current__gmv}>{currentTask.userGmvStr}</div>
                        }
                      </div>
                    </div>
                    <div className={styles.progress__node}>
                      <span className={styles.node__start}>0</span>
                      <span className={styles.node__end}>{currentTask.gmvAimStr}万</span>
                    </div>
                  </div>
                </div>
              )
            }
            {/* 条件2: 同比增长需达成 */}
            {
              currentTask?.increaseAim > 0 && (
                <div className={styles.achievement__task}>
                  <h4 className={styles.task__title}>{currentTask?.rankPrefixContent}{currentTask?.gmvAim > 0 ? 2 : 1}: 同比增长需达成</h4>
                  <div className={styles.task__progress}>
                    <div className={`${styles.progress__base} ${currentTask.increaseSuccessFlag && styles.progress__success}`}>
                      <div className={styles.current} style={{ width: `${formatNumber(currentTask?.userIncrease / currentTask?.increaseAim * 100)}% ` }}>
                        {
                          currentTask?.map?.userIncreaseStr && <div className={styles.current__gmv}>{currentTask.map.userIncreaseStr}</div>
                        }
                      </div>
                    </div>
                    <div className={styles.progress__node}>
                      <span className={styles.node__start}>0</span>
                      <span className={styles.node__end}>{currentTask.increaseAimStr}{currentTask.increaseAimType === 0 ? "%" : "万"}</span>
                    </div>
                  </div>
                </div>
              )
            }
            <div className={styles.achievement__rank}>
              <div className={styles.rank__left}>我的排名：<span>{currentTask?.rankNum || "-"}</span>{
                currentTask?.rankNum !== "-" && "名"
              }</div>
              <div className={styles.rank__right} onClick={() => toRankPage(currentTask)}>查看完整榜单</div>
            </div>
          </div>
          {/* 奖励规则 */}
          {
            currentTask?.ruleContentList?.length > 0 && (
              <div className={styles.multi__rule}>
                <h3 className={styles.rule__title}>奖励规则</h3>
                <div className={styles.rule__table}>
                  <div className={styles.table__header}>
                    <div className={styles.header__key}>业绩排名</div>
                    <div className={styles.header__value}>奖励内容</div>
                  </div>
                  <div className={styles.table__body}>
                    {
                      currentTask.ruleContentList.map((item, index) => {
                        return (
                          <div className={styles.body__item} key={index}>
                            <div className={styles.item__key} style={{ whiteSpace: "pre-wrap" }}>{item.key.replace(/\\n/g, <br />)}</div>
                            <div className={styles.item__value} style={{ whiteSpace: "pre-wrap" }}>{item.value.replace(/\\n/g, <br />)}</div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {
                  currentTask?.tierContent && <p className={styles.rule__tips}>{currentTask.tierContent}</p>
                }
              </div>
            )
          }
        </div>
      );
    } else {
      return (
        // 单个激励
        <div className={styles.single}>
          {/* 激励 */}
          <div className={styles.single__achievement}>
            {/* 标题 */}
            <div className={styles.achievement__title}>目标业绩达成</div>
            {/* 进度条 */}
            {/* gmv目标 */}
            {
              currentTask?.gmvAim > 0 && (
                <div className={styles.achievement__progress}>
                  <div className={`${styles.progress__base} ${currentTask.gmvSuccessFlag && styles.progress__success}`}>
                    <div className={styles.current} style={{ width: `${formatNumber(currentTask?.userGmv / currentTask?.gmvAim * 100)}%` }}>
                      {
                        currentTask?.userGmvStr && <div className={styles.current__gmv}>{currentTask.userGmvStr}</div>
                      }
                    </div>
                  </div>
                  <div className={styles.progress__node}>
                    <span className={styles.node__start}>0</span>
                    <span className={styles.node__end}>{currentTask.gmvAimStr}万</span>
                  </div>
                </div>
              )
            }
            {/* 增长目标 */}
            {
              currentTask?.increaseAim > 0 && (
                <div className={styles.achievement__progress}>
                  <div className={`${styles.progress__base} ${currentTask.increaseSuccessFlag && styles.progress__success}`}>
                    <div className={styles.current} style={{ width: `${formatNumber(currentTask?.userIncrease / currentTask?.increaseAim * 100)}%` }}>
                      {
                        currentTask?.map?.userIncreaseStr && <div className={styles.current__gmv}>{currentTask.map.userIncreaseStr}</div>
                      }
                    </div>
                  </div>
                  <div className={styles.progress__node}>
                    <span className={styles.node__start}>0</span>
                    <span className={styles.node__end}>{currentTask.increaseAimStr}{currentTask.increaseAimType === 0 ? "%" : "万"}</span>
                  </div>
                </div>
              )
            }
            {/* 排名 */}
            <div className={styles.achievement__rank}>
              <div className={styles.rank__left}>我的排名：<span>{currentTask?.rankNum || "-"}</span>{
                currentTask?.rankNum !== "-" && "名"
              }</div>
              <div className={styles.rank__right} onClick={() => toRankPage(currentTask)}>查看完整榜单</div>
            </div>
          </div>
          {/* 奖励规则 */}
          {
            currentTask?.ruleContentList?.length > 0 && (
              <div className={styles.single__rule}>
                <h3 className={styles.rule__title}>奖励规则</h3>
                <div className={styles.rule__table}>
                  <div className={styles.table__header}>
                    <div className={styles.header__key}>业绩排名</div>
                    <div className={styles.header__value}>奖励内容</div>
                  </div>
                  <div className={styles.table__body}>
                    {
                      currentTask.ruleContentList.map((item, index) => {
                        return (
                          <div className={styles.body__item} key={index}>
                            <div className={styles.item__key} style={{ whiteSpace: "pre-wrap" }}>{item.key.replace(/\\n/g, <br />)}</div>
                            <div className={styles.item__value} style={{ whiteSpace: "pre-wrap" }}>{item.value.replace(/\\n/g, <br />)}</div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
                {
                  currentTask?.tierContent && <p className={styles.rule__tips}>{currentTask.tierContent}</p>
                }
              </div>
            )
          }
        </div >
      );
    }
  };

  useEffect(() => {
    onChangeTask(currentTask);
  }, [currentTask]);

  useEffect(() => {
    setCurrentTask(list[tabIndex] || {});
  }, [tabIndex]);

  useEffect(() => {
    setTabIndex(0);
    setCurrentTask(list[0] || {});
  }, [list]);

  return (
    <>
      {componentDom()}
    </>
  );
};

export default StimulationCard;