import { queryBannerMsg } from "@/api/rewardCenter";
import { getCookie } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cls from "./index.module.less";

const ToExChangeTip: React.FC<{bizId?: string, callback?: () => void, onLoadComplete?: (visible: boolean) => void}> = ({bizId, callback, onLoadComplete}) => {
  const navigater = useNavigate();
  const [search] = useSearchParams();

  const [text, setText] = useState("");
  const [btnText, setBtnText] = useState("去兑奖");
  const [data, setData] = useState<any>();

  const queryBanner = async () => {
    const data = await queryBannerMsg({bizId: bizId as string});
    if (data?.bizId && data?.content) {
      onLoadComplete?.(true);
      setData(data);
      setText(data.content);
    } else {
      onLoadComplete?.(true);
      setData(null);
      setText("");
    }
  };
  const handleClick = () => {
    if (bizId) {
      callback?.();
    } else {
      let envid: any = window.location.href.match(/[?&]env_identity=([^?#&]+)/);
      envid = envid && envid[1];
      navigater(
        `/mx-shop-micro/reward-center/detail?token=${search.get("token")}&shopId=${search.get(
          "shopId",
        )}&channel=h5Mall&actId=${data?.bizId}&tabIndex=1&env_identity=${
          envid || getCookie("X-Hades-Env-Identity")
        }`,
      );
    }
  };

  useEffect(() => {
    queryBanner();
  }, []);
  // 查询关注公众号接口无异常，且为未关注状态 展示提示
  return (
    data && text && data.content && <div className={cls.exchangeBox}>
      <div className={cls.exchangeContent}>
        { text }
      </div>
      <div className={cls.exchangeBtn} onClick={handleClick}>
        { btnText }
      </div>
    </div>
  );
};
export default ToExChangeTip;
