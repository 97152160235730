import Track from "@/utils/track";

const track = Track();

const getTrackProductInfo = (product) => {
  if (!product) {
    return {};
  }
  const params = {
    activity_id: product?.activityId || "", // 活动ID
    activity_name: product?.activitySimpleVO?.name || "",
    activity_spu_code: product?.activitySpuId || "", // 活动商品SPU
    brand_id: product?.brandInfo.brandId || "",
    brand_name: product?.brandInfo?.brandName || "",
    merchant_shop_code: product?.activitySimpleVO?.merchant_shop_code || "", // 商家店铺编号
    product_model: product?.activityType ? `${product.activityType}` : "", // 商品模式
    product_name: product?.skuBaseInfoVO?.title || "",
    price: product?.skuCurrentPriceInfo?.marketingPrice || "",
    spu_id: product?.activitySpuId || "", // 基础商品SPU
    sku_id: product?.selectSkuId || "", // 商品SKU
  };
  return params;
};

const getTrackMaterialInfo = (material) => {
  if (!material) {
    return {};
  }
  const params = {
    material_id: material?.materialNo || "",
  };
  return params;
};

export const btn_click = (btn_name: string, shoppingMessageCardVO: any) => {
  const trackParams =
    shoppingMessageCardVO?.showType === 1
      ? getTrackProductInfo(shoppingMessageCardVO?.productVO)
      : getTrackMaterialInfo(shoppingMessageCardVO?.materialVO);
  const params = {
    page_name: "我的",
    resource_type: "派单任务",
    resource_content: shoppingMessageCardVO?.showType === 1 ? "商品" : "素材",
    resource_rank: 1,
    btn_name,
    ...trackParams,
  };
  track.track("btn_click", params);
};

export const resource_click = (btn_name: string, shoppingMessageCardVO: any) => {
  const trackParams =
    shoppingMessageCardVO?.showType === 1
      ? getTrackProductInfo(shoppingMessageCardVO?.productVO)
      : getTrackMaterialInfo(shoppingMessageCardVO?.materialVO);
  const params = {
    page_name: "我的",
    resource_type: "派单任务",
    resource_content: shoppingMessageCardVO?.showType === 1 ? "商品" : "素材",
    resource_rank: 1,
    btn_name,
    ...trackParams,
  };
  track.track("resource_click", params);
};

export const resource_expose = (shoppingMessageCardVO: any) => {
  const trackParams =
    shoppingMessageCardVO?.showType === 1
      ? getTrackProductInfo(shoppingMessageCardVO?.productVO)
      : getTrackMaterialInfo(shoppingMessageCardVO?.materialVO);
  const params = {
    page_name: "我的",
    resource_type: "派单任务",
    resource_content: shoppingMessageCardVO?.showType === 1 ? "商品" : "素材",
    resource_rank: 1,
    ...trackParams,
  };
  track.track("resource_expose", params);
};
