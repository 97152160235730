import React, { useState } from "react";
import styles from "./CategoryItem.module.less";

interface props {
  item: any;
  index: number;
  onConfirmAction: (item: any) => void;
}

const CategoryItem: React.FC<props> = ({ item, index, onConfirmAction }) => {
  return (
    <div className={styles.category}>
      <div className={styles.category__name}>{item.bizName}</div>
      <div
        className={`${styles.category__btn} ${item.isHide && styles.category__btn_cancel}`}
        onClick={() => onConfirmAction(item)}
      >
        {item.isHide ? "取消隐藏" : "隐藏分类"}
      </div>
    </div>
  );
};

export default CategoryItem;
