import { SimpleMaterial, queryMaterialSimple } from "@/api/material";
import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import Clickable from "@/components/clickable";
import { useCommonContext } from "@/utils/context/common";
import { default as useNavigation } from "@/utils/useNavigate";
import { Exposable } from "@akc/mx-taro-h5-components";
import { default as React, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useMaterialListData,
  useSelectedActivity,
  useSelectedActivityIdList,
} from "../hooks/useActivityProduct";
import styles from "./MaterialList.module.less";

const MaterialList: React.FC = () => {
  const materialListData = useMaterialListData();
  const { navigate2MaterialList, navigate2MaterialContent, navigate2CBrandStory } = useNavigation();

  const commonContext = useCommonContext();
  const params = useParams();
  const [simpleMaterialList, setSimpleMaterialList] = useState<SimpleMaterial[]>([]);

  useEffect(() => {
    if (!params.activityId || commonContext.currentRole !== "C") {
      return;
    }
    queryMaterialSimple(params.activityId).then((data) => setSimpleMaterialList(data));
  }, [commonContext.currentRole]);

  const listDom = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!listDom.current) {
      return;
    }
    listDom.current?.scrollTo(0, 0);
  }, [materialListData]);

  const selectedActivityIdList = useSelectedActivityIdList();
  const selectedActivity = useSelectedActivity();

  return (
    <>
      {commonContext.currentRole !== "C" &&
        materialListData?.result &&
        materialListData.result.length > 0 && (
          <>
            <div className={styles.materialList}>
              <div className={styles.header}>
                <div className={styles.title}>
                  <div>精选素材</div>
                  <div className={styles.count}>({materialListData.totalCount})</div>
                </div>
                <Clickable
                  className={styles.more}
                  onClick={() =>
                    navigate2MaterialList(undefined, undefined, selectedActivityIdList)
                  }
                  trackData={{ resource_type: "精选素材", btn_name: "更多素材" }}
                  trackContextKeys={[
                    "tab_name",
                    "tab_id",
                    "tab_rank",
                    "merchant_shop_code",
                    "activity_id",
                  ]}
                >
                  {materialListData.totalCount > 4 && (
                    <>
                      <div>更多素材</div>
                      <ArrowRightIcon className={styles.icon} />
                    </>
                  )}
                </Clickable>
              </div>
              <div ref={listDom} className={styles.list}>
                {materialListData.result.map((material, index) => (
                  <Exposable
                    key={index}
                    className={`${styles.item} ${
                      (materialListData.result?.length ?? 0) === 1 ? styles.onlyOne : ""
                    }`}
                    trackData={{
                      resource_type: "精选素材",
                      btn_name: "查看素材",
                      resource_rank: index,
                      material_id: material.materialNo,
                    }}
                    trackContextKeys={[
                      "tab_name",
                      "tab_id",
                      "tab_rank",
                      "merchant_shop_code",
                      "activity_id",
                    ]}
                    clickEventName="resource_click"
                    onClick={() => navigate2MaterialContent(material.type, material.materialNo)}
                  >
                    <div
                      className={styles.img}
                      style={{
                        background: `url(${material.coverVOList?.[0]?.coverUrl}) 0% 0% / cover no-repeat`,
                      }}
                    >
                      <div className={styles.count}>{material.imageCount}图</div>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.text}>
                        {material.labelLocationVOList
                          ?.filter((i) => i.locationCode === "H5_MaterialList_MaterialTitlefront")
                          .map((i, index) => (
                            <img
                              className={styles.lable}
                              key={index}
                              src={i.labelInfoVOList?.[0].labelIconUrl}
                            />
                          ))}
                        <span>{material.materialContent}</span>
                      </div>
                      {material.noteFlag === 1 && (
                        <div className={styles.tagContainer}>
                          <span className={styles.tag}>优质笔记</span>
                        </div>
                      )}
                    </div>
                  </Exposable>
                ))}
              </div>
            </div>
          </>
        )}
      {commonContext.currentRole === "C" && simpleMaterialList.length > 0 && (
        <Clickable
          trackData={{
            tab_id: selectedActivityIdList.join(","),
            activity_id: selectedActivityIdList.join(","),
            resource_type: "品牌故事",
            btn_name: "品牌故事",
            tab_name: selectedActivity?.activityAggrTitle,
            tab_rank: (selectedActivity?.rank ?? 0) + 1,
            merchant_shop_code: selectedActivity?.merchantShopCode,
          }}
          className={styles.brandStoreContainer}
          onClick={() => params.activityId && navigate2CBrandStory(params.activityId)}
        >
          <div className={styles.header}>
            {simpleMaterialList[0].outSideType === 3 ? "品牌故事" : "活动介绍"}
          </div>
          <div className={styles.content}>
            <div
              className={styles.img}
              style={{
                background: `url(${simpleMaterialList[0].materialPhoto?.[0].imageUrl})  0% 0% / cover no-repeat`,
              }}
            >
              <div className={styles.imgCount}>{simpleMaterialList[0].materialPhoto?.length}图</div>
            </div>
            <div className={styles.text}>{simpleMaterialList[0].materialContent}</div>
            <ArrowRightIcon className={styles.icon} />
          </div>
        </Clickable>
      )}
    </>
  );
};

export default MaterialList;
