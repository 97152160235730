import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";

export async function queryMaterialPageList(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/create/materialPageList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function queryMaterialTabs(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/querySelectedMaterialTab`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function queryMaterialFilter(params) {
  params.scene = "MORE_MATERIAL";
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v2/material/filter`,
    method: "post",
    data: params,
  });
  return res?.data;
}

import { MaterialModuleModel, PageableResponse } from "@akc/biz-api";

export type MaterialParam = {
  pageId: string;
  objectIds: string[];
  pageNo: number;
  pageSize: number;
  queryType: number;
  useType: number;
};

export async function queryMaterial(
  params: MaterialParam,
): Promise<PageableResponse<MaterialModuleModel>> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/materialUse/v1/queryTopMaterialPage`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export type SimpleMaterial = {
  materialContent?: string;
  materialPhoto?: Array<{
    imageUrl: string;
  }>;
  outSideType: number;
};

export async function queryMaterialSimple(activityId): Promise<SimpleMaterial[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/material/list/simple/${localStorage.getItem(
      "shopId",
    )}/${activityId}?relationActivityType=1`,
    method: "get",
  });
  return res.data.materialList.map((item) => ({ ...item, outSideType: res.data.outSideType }));
}
