import React from "react";

import TITLE_ICON from "@/assets/icon_line.png";
import ProductWaterfall from "@/components/product-waterfall";
import styles from "./index.module.less";

interface IProps {
  productList: any[];
  hasNext: boolean;
  onCartChange?: () => Promise<void>;
  idsClick: any;
  setIdsClick: any;
  loadMore: () => Promise<void>;
}
const Recommend: React.FC<IProps> = ({ productList, hasNext, loadMore, onCartChange }) => {
  return (
    <div className={styles["recommend"]}>
      {/* <div className={styles["recommend-header"]}>
        <img className={styles["recommend-header__icon"]} src={TITLE_ICON} alt="" />
        <div className={styles["recommend-header__title"]}>我的专属推荐</div>
        <img className={styles["recommend-header__icon"]} src={TITLE_ICON} alt="" />
      </div> */}

      <ProductWaterfall
        productList={productList}
        hasNext={hasNext}
        loadMore={loadMore}
        onCartChange={onCartChange}
        topRightLocationCode="H5_Search_ProductList_ProductPicRight"
        titleFrontLocationCode="H5_Search_ProductList_TitleFront"
        titleBottomLocationCode="H5_Search_ProductList_TitleDown"
        provideBackground={false}
        btnClickName="resource_click"
      />
    </div>
  );
};
export default Recommend;
