import { LiveData, getLiveData } from "@/api/live";
import liveGif from "@/assets/live.gif";
import { formatDate } from "@/utils/time";
import useNavigation from "@/utils/useNavigate";
import { FloatingBubble } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useSelectedActivity } from "../hooks/useActivityProduct";
import styles from "./LiveFloatingBubble.module.less";

const LiveFloatingBubble: React.FC = () => {
  const selectedActivity = useSelectedActivity();
  const [liveData, setLiveData] = useState<LiveData>();

  useEffect(() => {
    if (selectedActivity?.activityId) {
      getLiveData(selectedActivity.activityId).then((data) => setLiveData(data));
    }
  }, [selectedActivity]);

  const { navigate2Live } = useNavigation();

  return (
    liveData && (
      <FloatingBubble
        axis="xy"
        magnetic="x"
        style={{
          "--initial-position-bottom": "197px",
          "--initial-position-right": "12px",
          "--edge-distance": "12px",
          "--border-radius": "4px",
          "--size": "3rem 1.1rem",
          "--background": "transparent",
          "--z-index": "2",
        }}
        onClick={() => navigate2Live(liveData.liveNo, liveData.liveStatus)}
      >
        <div className={styles.container}>
          <div className={styles.bubble}></div>
          <img className={styles.heart} src={liveGif} />
          <div
            className={styles.contentWrapper}
            style={{
              background: `center / contain no-repeat url(${liveData?.coverUrl})`,
            }}
          >
            <div className={styles.content}>
              {liveData?.liveStatus === 10
                ? "直播预告"
                : liveData?.liveStatus === 10
                  ? "直播中"
                  : "直播回看"}
            </div>
          </div>
          <div className={`${styles.action} ${liveData?.liveStatus === 10 ? styles.preheat : ""}`}>
            {liveData?.liveStatus === 10 ? formatDate(liveData?.planBeginTime) : "去围观"}
          </div>
        </div>
      </FloatingBubble>
    )
  );
};

export default LiveFloatingBubble;
