import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "../tabs/tabs";
import styles from "./search.module.less";
import { useSearchContext } from "./useSearch";

interface IProps {
  placeholder: string;
  onBack: () => void;
  querySuggestList: (text: string) => void;
}

const SearchHeader: React.FC<IProps> = ({ placeholder, onBack, querySuggestList }) => {
  const track = Track();
  const [search] = useSearchParams();
  const keyword = search.get("key");

  const {
    inputText,
    setInputText,
    searchTagText,
    showSearchTag,
    handleSearch,
    setSearchTagText,
    setShowSearchTag,
    setShowSuggest,
    tabList,
    tabIndex,
    onSetTabIndex,
    onSearch
  } = useSearchContext();

  const handleSearchClick = () => {
    setShowSearchTag(false);
    setShowSuggest(true);
    console.log("handleSearchClick", showSearchTag);
    setTimeout(() => {
      const dom: any = document.getElementById("inputDom");
      dom?.focus();
    }, 0);
  };
  const handleTextChange = (e) => {
    const text = e?.target?.value;
    console.log("handleTextChange", text);
    setInputText(text);
    setSearchTagText(text);
  };

  const handleKeydown = (e: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
    if (showSearchTag) {
      return;
    }
    let text = e.target.value;
    if (isEmpty(text) && keyword) {
      text = keyword;
    }
    console.log("handleKeydown", e.key);
  };

  const handleTagClear = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setShowSuggest(true);
    setShowSearchTag(false);
    setSearchTagText("");
    setInputText("");
    setTimeout(() => {
      const dom: any = document.getElementById("inputDom");
      dom?.focus();
    }, 0);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowSearchTag(true);
      setShowSuggest(false);
      if (!inputText && !searchTagText) {
        onSearch();
      }
    }, 100)
  }

  useEffect(() => {
    querySuggestList(inputText);
    // 清除上一个 effect，确保每次只有一个防抖函数在运行
    // return () => {
    //   querySuggestList.cancel();
    // };
  }, [inputText]);
  useEffect(() => {
    if (searchTagText !== inputText) {
      setInputText(searchTagText);
    }
  }, [searchTagText]);

  return (
    <>
      <div className={styles.searchBox}>
        {/* <img
        onClick={onBack}
        className={styles.back}
        src="https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1694082344311_50.png"
      ></img> */}
        <div className={styles.inputBox} onClick={handleSearchClick}>
          <img
            className={styles.img}
            src="https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1694086326376_51.png"
          ></img>
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              className={
                styles.input + " " + (showSearchTag && searchTagText ? styles.hid : styles.visiable)
              }
              onKeyDown={handleKeydown}
              onChange={handleTextChange}
              id="inputDom"
              type="search"
              value={inputText}
              placeholder={keyword || placeholder}
              onFocus={() => {
                setShowSearchTag(false);
                setShowSuggest(true);
              }}
              onBlur={() => handleBlur()}
            ></input>
          </form>
          <div
            className={
              styles.tag + " " + (showSearchTag && searchTagText ? styles.visiable : styles.hid)
            }
          >
            <div
              style={{
                fontSize: pxtorem(14),
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: pxtorem(200),
              }}
            >
              {searchTagText}
            </div>
            <img
              onClick={handleTagClear}
              className={styles.close}
              src="https://akim-oss.aikucun.com/feacb8e58e1e4974fd9a12dc2e5e730ffd3ec89f_1694091300902_1.png"
            ></img>
          </div>
        </div>
        {/* <div className={styles.text} onClick={() => handleSearch(inputText || searchTagText || keyword)}>
        搜索{showSearchTag}
      </div> */}
      </div>
      {/* tabs */}
      {
        showSearchTag && inputText === "" && (
          <div className={styles.tab__wrap}>
            <Tabs list={tabList} activeIndex={tabIndex} onChange={onSetTabIndex} />
          </div>
        )
      }
    </>
  );
};
export default SearchHeader;
