import { getAppSystem } from "./app-system";
import { platform } from "./platform";

export const getNetworkType = () => {
  return new Promise((resolve) => {
    if (platform.isApp) {
      getAppSystem()
        .then((res) => {
          const { connectionType } = res || {};
          resolve(connectionType?.toLowerCase());
        })
        .catch(() => {
          resolve("other");
        });
    } else {
      const ua = navigator.userAgent;
      const networkStr = (ua.match(/NetType\/(\w+)/)?.[1] || "other").toLowerCase();
      resolve(networkStr);
    }
  });
};
