import { platform } from "@/utils/platform";
import { fetchCopyLink } from "../copy_link";
import { fetchCopyMessage } from "../copy_message";
import { generateSharePoster } from "../poster";
import {
  CarrierElementType,
  ContentType,
  NormalParamsType,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "../scode_params";
import { fetchShareCardInfo } from "../share_card_info";

type ParamsType = {
  activityId?: string;
  brandId?: string;
} & NormalParamsType;

const assemblyPrarms = (props: ParamsType) => ({
  bizInfo: bizInfoParams({
    ...props,
    contentType: props.activityId ? ContentType.activity : ContentType.activityAggr,
    contentId: props.activityId ?? props.brandId,
  }),
  envContext: envContextParams({
    sourceScene: props.sourceScene,
    sourceSceneId: props.sourceSceneId,
  }),
  carrierContext: carrierContextParams(
    props.carrierElements,
    props.posterCode,
    props.posterVersion,
  ),
});

/**
 * 生成档期海报
 */
export const generateActivityPoster = async (props: ParamsType) =>
  generateSharePoster(assemblyPrarms(props));

/**
 * 获取档期复制链接
 */
export const fetchActivityCopyLink = async (props: ParamsType) =>
  fetchCopyLink(assemblyPrarms(props));

/**
 * 获取档期复制文案
 */
export const fetchActivityCopyMessage = async (props: { activityId?: string; brandId?: string }) =>
  fetchCopyMessage({
    bizInfo: bizInfoParams({
      contentType: props.activityId ? ContentType.activity : ContentType.activityAggr,
      contentId: props.activityId ?? props.brandId,
    }),
  });

/**
 * 获取档期卡片信息
 */
export const fetchActivityCardInfo = async (
  props: Omit<ParamsType, "carrierElements"> & { carrierElements?: CarrierElementType[] },
) => {
  const ps = assemblyPrarms({
    ...props,
    carrierElements: props.carrierElements ?? [
      platform.isMini ? CarrierElementType.miniCard : CarrierElementType.h5Link,
    ],
  });
  return fetchShareCardInfo(ps);
};
