import { queryLogisticsList } from "@/api/order";
import { getPlatform } from "@/utils/platform";
import Track from "@/utils/track";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import Header from "./components/header";
import LogisticsCard from "./components/logistics-card";
import LogisticsNode from "./components/logistics-node";
import LogisticsPackage from "./components/logistics-package";
import RecommendProduct from "./components/recommend-product";
import WechatAccount from "./components/wechat-account";
import styles from "./index.module.less";

const Logistics: React.FC = () => {
  const track = Track();
  const { isH5 } = getPlatform();
  const [searchParams] = useSearchParams();
  const [isEmpty, setIsEmpty] = useState(false);
  const [logisticsList, setLogisticsList] = useState<any[]>([]);
  const [logisticsItem, setLogisticsItem] = useState(null);
  const [activitySpuIds, setActivitySpuIds] = useState<string[]>([]);
  const [categoryCodeList, setCategoryCodeList] = useState<string[]>([]);

  let orderNo = searchParams.get("orderNo") ?? "";
  let orderLineNo = searchParams.get("orderLineNo") ?? "";
  let logisticsNo = searchParams.get("logisticsNo") ?? "";

  const getLogisticsList = async () => {
    if ((!orderNo || orderNo === "undefined") && orderLineNo && orderLineNo !== "undefined") {
      // 把三级单转成二级单(兼容积分商品三级订单)
      orderNo = orderLineNo.slice(0, -3);
    }
    if (!orderNo || orderNo === "undefined") {
      setIsEmpty(true);
      return;
    }
    const res = await queryLogisticsList({
      orderNo,
      orderLineNo,
      type: searchParams.get("roleType") || searchParams.get("orderType") || "0",
      shopBizCode: searchParams.get("shopBizCode") || searchParams.get("shopId") || "",
    });
    if (!res?.data?.length) {
      setIsEmpty(true);
      return;
    }
    if (logisticsNo) {
      const packageIndex = res.data.findIndex((item: any) => item.logisticsNo === logisticsNo);
      if (packageIndex !== -1) {
        setLogisticsItem(res.data[packageIndex]);
      } else {
        setLogisticsItem(res.data[0]);
      }
    } else {
      setLogisticsItem(res.data[0]);
    }
    setLogisticsList(res.data);
    setActivitySpuIds(res.data[0].spuIds ?? []);
    setCategoryCodeList(res.data[0].threeCategoryCodes ?? []);
    setTimeout(() => {
      document.body.style.overflow = "unset";
    }, 500);
  };

  useActivate(() => {
    setTimeout(() => {
      document.body.style.overflow = "unset";
    }, 100);
  });

  useEffect(() => {
    document.title = "物流详情";
    getLogisticsList();
    track.track("$pageview", {
      page_name: "物流详情",
      previous_page_name: searchParams.get("previous_page_name") ?? "未知",
    });
  }, []);

  return (
    <div className={`${styles.container} ${isH5 ? styles.h5 : ""}`}>
      {isH5 && <Header />}
      {logisticsList.length > 0 && (
        <>
          <div className={styles.order}>
            <span>订单：{orderNo}</span>
            <span>{logisticsList.length}个包裹</span>
          </div>
          {logisticsList.length > 1 && (
            <LogisticsPackage
              logisticsList={logisticsList}
              changeLogisticsItem={(item) => setLogisticsItem(item)}
            />
          )}
          <WechatAccount />
        </>
      )}
      {logisticsItem && (
        <>
          <LogisticsCard logisticsItem={logisticsItem} />
          <LogisticsNode logisticsItem={logisticsItem} />
          {activitySpuIds.length > 0 && categoryCodeList.length > 0 && (
            <RecommendProduct activitySpuIds={activitySpuIds} categoryCodeList={categoryCodeList} />
          )}
        </>
      )}
      {logisticsList.length === 0 && isEmpty && (
        <div className={styles.empty}>
          <img
            src="http://akmer.aikucun.com/0df47db2e21ac3433855c79066ade6d73e6e2dc0_1577966465021_71.png"
            alt=""
          />
          <p>物流信息暂未更新，请稍后查看~</p>
        </div>
      )}
    </div>
  );
};

export default Logistics;
