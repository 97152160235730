import React, { useEffect, useRef, useState } from "react";
// import { Image } from "antd-mobile";

import styles from "./index.module.less";
// import LabelHeaderPng from "@/assets/promote_label_header.png";
import ArrowDown from "@/assets/arrow_down_black.png";
import ArrowUp from "@/assets/arrow_up_black.png";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";

interface IMaterialTextItem {
  title: string;
  subTitle: string;
  labelList: any[] | undefined;
  isYouxue: boolean;
  polyMaterialText: string;
}
interface IProps {
  materialText: IMaterialTextItem;
}
const InfoContent: React.FC<IProps> = ({ materialText }) => {
  const { isYouxue, polyMaterialText } = materialText;
  const [contentSwitch, setContentSwitch] = useState<boolean>(true);
  const [contentSwitchInit, setContentSwitchInit] = useState<boolean>(true);
  const subContentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const subContentHeight = subContentRef.current?.clientHeight;
    if (subContentHeight && subContentHeight > 70) {
      setContentSwitch(false);
    } else {
      setContentSwitchInit(false);
    }
  }, []);
  const handleCopy = (e) => {
    e.stopPropagation();
    copy(polyMaterialText);
    Toast.show({
      content: "已复制",
      maskClassName: styles["poster-unify-toast"],
    });
  };

  return (
    <>
      {/* <div className={styles.TotalInfoContent}>
        <span className={styles.ContentLabelText}>{title}</span>
      </div> */}
      <div className={styles.TotalInfoSubContent}>
        <div
          className={`${!contentSwitch ? styles.ContentArea : styles.ContentArea2}`}
          ref={subContentRef}
        >
          {!contentSwitch && contentSwitchInit && (
            <div className={styles.SwitchBtnOpen} onClick={() => setContentSwitch(true)}>
              <span className={styles.CopyMsg} onClick={handleCopy}>
                复制
              </span>
              <span>展开</span>
              <img src={ArrowDown} alt="" />
            </div>
          )}
          {isYouxue && <span className={styles.ContentLabel}>溯源</span>}
          {contentSwitch && !contentSwitchInit && (
            <div className={styles.SwitchBtnOpen}>
              <span className={styles.CopyMsg} onClick={handleCopy}>
                复制
              </span>
            </div>
          )}
          {polyMaterialText}
        </div>
        {contentSwitch && contentSwitchInit && (
          <div className={styles.SwitchBtnClose} onClick={() => setContentSwitch(false)}>
            <span className={styles.CopyMsg} onClick={handleCopy}>
              复制
            </span>
            <span>收起</span>
            <img src={ArrowUp} alt="" />
          </div>
        )}
      </div>
      {/* {labelList && labelList.length > 0 && <div className={styles.TotalInfoLabel}>
        {labelList && labelList.map((item: any, index: number) => (
          <div className={styles.LabelItem} key={index}>
            <Image src={LabelHeaderPng} width={14} height={14} />
            <span className={styles.LabelItemText}>{item.labelName}</span>
          </div>
        ))}
      </div>} */}
    </>
  );
};

export default InfoContent;
