import { getBaseurl } from "@/utils/url-parse";
import { API, ProductDetailAtmosphereModel } from "@akc/biz-api";
import { Request } from "./request";

// 新人专区页-用户优惠劵查询
const queryCouponList = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/couponZone/userCoupon/list`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 新人专区页-品牌查询
const queryBrandList4Coupon = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/couponZone/brand`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 新人专区页-推荐商品橱窗
const queryProductList = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/couponZone/productList`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 获取气氛数据
const queryAtmosphereData = async (params?: any) => {
  return API.post<ProductDetailAtmosphereModel>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/product/atmosphere/getAtmosphereData",
    body: params,
  });
};

export {
  queryAtmosphereData,
  queryBrandList4Coupon as queryBrandList,
  queryCouponList,
  queryProductList,
};
