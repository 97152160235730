import React, { useEffect, useState } from "react";
import styles from "./PK.module.less";

interface Props {
  info: any;
}

const PK: React.FC<Props> = ({ info }) => {
  // 目标枚举
  const thresholdIndicatorTypeEnum = {
    GMV: { desc: "销售额", unit: "元" },
    SALE_QUANTITY: { desc: "销量", unit: "件" },
    COMMISSION: { desc: "基础省赚", unit: "元" },
  };

  const [endThreshold, setEndThreshold] = useState<number>(0);

  const gmvDom = () => {
    if (!info) {
      return;
    }
    if (!info.isQualified) {
      // 没入围
      return (
        <p>
          当前累计
          {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
          <span>
            {info.actSellBatePartakeInfo.currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ， 仅差
          <span>
            {info?.actSellBatePartakeInfo?.unChosenAmount}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          入围 ，继续努力呀~
        </p>
      );
    } else if (info?.actSellBatePartakeInfo?.currentRank === 1) {
      return (
        <p>
          当前累计
          {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
          <span>
            {info.actSellBatePartakeInfo.currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，<span>排名{info.actSellBatePartakeInfo.currentRank}名</span>
          距下一名仅差
          <span>
            {info?.actSellBatePartakeInfo?.unChosenAmount}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，继续努力呀~
        </p>
      );
    } else {
      return (
        <p>
          当前累计
          {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}
          <span>
            {info.actSellBatePartakeInfo.currentGmv}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，<span>排名{info.actSellBatePartakeInfo.currentRank}名</span>
          距上一名仅差
          <span>
            {info?.actSellBatePartakeInfo?.unChosenAmount}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
          </span>
          ，继续努力呀~
        </p>
      );
    }
  };

  useEffect(() => {
    if (!info) {
      return;
    }
    const { ruleDetails } = info?.actSellRebateRuleInfos || {};
    if (ruleDetails?.length) {
      setEndThreshold(ruleDetails[ruleDetails.length - 1]["endThreshold"]);
    }
  }, [info]);

  return (
    <div className={styles.achievement}>
      {info?.actSellBatePartakeInfo?.partakeStatus === 40 && (
        <img
          className={styles.achievement__received}
          src="https://akim-oss.aikucun.com/mshop/dc445ddc22b2697ae5fbd5168cd31abd8a7f0be3_1720255803315_87.png"
        />
      )}
      {["BEGIN", "AWAITING_DRAW", "END"].includes(info.aggStatus) && (
        <>
          <h3 className={styles.achievement_title}>我的业绩</h3>
          {info?.actSellBatePartakeInfo?.currentGmv >= 0 && (
            <div className={styles.achievement_gmv}>{gmvDom()}</div>
          )}
          {info?.actSellBatePartakeInfo?.currentRankDetails?.length > 0 && (
            <ul className={styles.achievement__rank}>
              <li className={`${styles.rank__item} ${styles.rank__title}`}>
                <span className={styles.item__0}>排名</span>
                <span className={styles.item__1}>用户信息</span>
                <span className={styles.item__2}>
                  {
                    thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                      .desc
                  }
                  (
                  {
                    thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType]
                      .unit
                  }
                  )
                </span>
              </li>
              {info?.actSellBatePartakeInfo?.currentRankDetails[0].rank !== 1 && (
                <li className={`${styles.rank__item}`}>
                  <span className={styles.item__0}>...</span>
                  <span className={styles.item__1}>...</span>
                  <span className={styles.item__2}>...</span>
                </li>
              )}
              {info?.actSellBatePartakeInfo?.currentRankDetails.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${styles.rank__item} ${
                      item.isCurrentUser && styles.rank__item_active
                    } ${info.isQualified && item.rank === 1 && styles.rank__1} ${
                      info.isQualified && item.rank === 2 && styles.rank__2
                    } ${info.isQualified && item.rank === 3 && styles.rank__3}`}
                  >
                    <span className={styles.item__0}>
                      {info.isQualified ? item.rank : "未入围"}
                    </span>
                    <span className={`${styles.item__1} ${styles["text-ellipsis1"]}`}>
                      {item.isCurrentUser ? "我" : item.nickname || item.userCode}
                    </span>
                    <span className={styles.item__2}>{item.saleAmount || 0}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )}

      {info?.actSellRebateRuleInfos?.ruleDetails && (
        <div className={styles.rewardRules}>
          <h3 className={styles.rules_title}>奖励规则</h3>
          <div className={styles.rules_subtitle}>
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].desc}达
            {info?.actSellBatePartakeInfo?.minSalesVolume}
            {thresholdIndicatorTypeEnum[info.actSellBatePartakeInfo.thresholdIndicatorType].unit}
            ，前
            {endThreshold}名获得奖励
          </div>
          <ul className={styles.rules_rank}>
            <li className={`${styles.rank_item} ${styles.rank_title}`}>
              <span className={styles.item_scope}>销售排名</span>
              <span className={styles.item_reward}>奖励金额(元)</span>
            </li>
            {info?.actSellRebateRuleInfos?.ruleDetails.map((item, index) => {
              return (
                <li key={index} className={`${styles.rank_item}`}>
                  <span className={styles.item_scope}>
                    第{item.strThreshold}名-第{item.endThreshold}名
                  </span>
                  <span className={styles.item_reward}>{item.rewardAmount}元</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PK;
