import React from "react";
import styles from "./index.module.less";

interface Props {
  platform?: any;
  skuCurrentPriceInfo?: any;
}

const PriceCom: React.FC<Props> = (props: Props) => {
  const { platform, skuCurrentPriceInfo } = props;

  return (
    <div className={styles.save_money_content}>
      <div className={styles.save_money}>
        {platform.isApp
          ? "省赚"
          : platform.isTuan
            ? "推广费"
            : platform.isMini && !platform.isSaas
              ? "饷"
              : "赚"}
        ¥{skuCurrentPriceInfo?.commission}
      </div>
      {skuCurrentPriceInfo?.commissionAddition > 0 && (
        <div className={styles.add}>+{skuCurrentPriceInfo?.commissionAddition}</div>
      )}
    </div>
  );
};

export default PriceCom;
