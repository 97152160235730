import { getSchoolCourseList, getSchoolCourseSwitch } from "@/api/my/shop";
import Track from "@/utils/track";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Swiper } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface ISchoolCourse {
  categoryId?: number;
  id?: number;
  pictureUrl?: string;
  title?: string;
}

const ShopSchoolCard: React.FC = () => {
  const [schoolCourseSwitch, setSchoolCourseSwitch] = useState<boolean>(false);
  const [schoolCourseList, setSchoolCourseList] = useState<Array<ISchoolCourse>>([]);
  const { navigate2H5 } = useNavigation();
  const track = Track();

  const querySchoolCourseList = async () => {
    const data = (await getSchoolCourseList()) || [];
    setSchoolCourseList(data);
  };

  const goSchoolCourseAll = () => {
    const options: IOptions = {
      path: "/#/schoolIndex",
      is_micro_app: true,
    };
    navigate2H5(options);
    track.track("btn_click", {
      page_name: "我的",
      module: "学习天地",
      resource_type: "学习天地",
      btn_name: "查看全部",
    });
  };

  const goSchoolCourseDetail = (item: any) => {
    const options: IOptions = {
      path: "/#/schoolDetail",
      is_micro_app: true,
      query: `categoryId=${item.categoryId}&lessonId=${item.id}&courseName=${item.title}`,
    };
    navigate2H5(options);
  };

  const querySchoolCourseSwitch = async () => {
    const data = await getSchoolCourseSwitch();
    if (data) {
      setSchoolCourseSwitch(true);
      querySchoolCourseList();
    }
  };

  useEffect(() => {
    querySchoolCourseSwitch();
  }, []);

  return (
    schoolCourseSwitch &&
    schoolCourseList.length > 0 && (
      <div className={styles.shopSchoolCard}>
        <div className={styles.schoolTitle}>
          <div className={styles.titleLeft}>
            <span>学习天地</span>
          </div>
          <div className={styles.titleRight} onClick={goSchoolCourseAll}>
            <span>查看全部</span>
            <i className={styles.arrow} />
          </div>
        </div>
        <Swiper className={styles.swiperRoot} autoplay loop>
          {schoolCourseList.map((item, index) => (
            <Swiper.Item key={index} className={styles.swiperItem}>
              <img src={item.pictureUrl} onClick={() => goSchoolCourseDetail(item)} alt="" />
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    )
  );
};

export default ShopSchoolCard;
