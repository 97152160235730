import { ActivityTaskParams } from "@/api/rewardCenter";
import Track from "@/utils/track";
import constate from "constate";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";

interface ISearch {
  queryContentList: (params?: ActivityTaskParams) => Promise<any>;
}

const useSearch = ({ queryContentList }: ISearch) => {
  const track = Track();
  const [search] = useSearchParams();
  const keyword = search.get("key");
  const [searchTagText, setSearchTagText] = useState("");
  const [showSearchTag, setShowSearchTag] = useState(true);

  const [historyList, setHistoryList] = useState<any>([]);
  const [showSuggest, setShowSuggest] = useState(false);

  const handleSearch = (data) => {
    // text = text || searchTagText || keyword || "";
    // track.track("btn_click", {
    //   page_name: "店铺聚合",
    //   previous_page_name: search.get("page_name"),
    //   domain: "B2R旗舰店",
    //   btn_name: "搜索",
    //   btn_text: text,
    //   module: "搜索框",
    // });
    console.log("handleSearch", data);
    setShowSuggest(false);
    setShowSearchTag(true);
    queryContentList({ brandId: data.brandId, pageIndex: 1 });
  };

  const handleClickKeyTag = (data: any) => {
    console.log("handleClickKeyTag", data);
    track.track("resource_click", {
      page_name: "奖励中心搜索结果",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      resource_name: data.brandName,
      btn_name: "点击品牌",
    });
    setShowSuggest(false);
    setShowSearchTag(true);
    setSearchTagText(data.brandName);
    handleSearch(data);
  };

  const handleClearHistory = () => {
    localStorage.removeItem("keywords");
    setHistoryList([]);
  };

  useEffect(() => {
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
    track.track("$pageview", {
      page_name: "搜索结果页",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
    });
  }, []);

  useActivate(() => {
    console.log("in useActivate search");
    document.title = "\u200E";
    let list: any = localStorage.getItem("keywords");
    if (!isEmpty(list)) {
      list = JSON.parse(list);
      list = list.splice(0, 20);
      setHistoryList(list);
    } else {
      setHistoryList([]);
    }
  });

  return {
    searchTagText,
    showSearchTag,

    historyList,
    showSuggest,

    setShowSearchTag,
    setShowSuggest,
    setSearchTagText,

    handleSearch,

    handleClearHistory,
    handleClickKeyTag,
  };
};

export const [SearchProvider, useSearchContext] = constate(useSearch);
