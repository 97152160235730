import { queryByBrandActivityCode } from "@/api/yearEndBonus";
import loadingPng from "@/base64";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import TopNavigation from "@/components/top-navigation";
import PageEmptyStatus from "@/pages/rewardCenter/components/PageEmptyStatus";
import {
  shareAnnualBounsPosterAction,
  sharePosterParams4H5AnnualBouns
} from "@/utils/actions";
import { CommonContext } from "@/utils/context";
import { hostEnv, platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { Image } from "antd-mobile";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ProductList from "./components/landing-product-list/landing-product-list";
import { ActivityProductProvider } from "./hooks/useActivityProduct";
import styles from "./landing.module.less";

const Landing: React.FC = () => {
  const track = Track();
  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const { currentRole } = useContext(CommonContext);
  const [search] = useSearchParams();
  const brandActivityCode = search.get("brandActivityCode") || "";
  const [pageInfo, setPageInfo] = useState<any>({});
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [showEmptyStatus, setShowEmptyStatus] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [disableScroll, setDisableScroll] = useState(false);
  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  // 转发卡片（修改文案）需要的参数
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [sharePosterData, setSharePosterData] = useState<any>({});
  // ref
  const PageScrollRef = useRef<HTMLDivElement>(null);
  const ProductListRef = useRef<any>(null);

  const [pageTrackData, setPageTrackData] = useState<any>(null);

  const onScrollTop = () => {
    PageScrollRef?.current?.scrollTo(0, 0);
  };

  const onShare = async (e) => {
    e?.stopPropagation();

    if (platform.isH5) {
      const sharePosterData = await sharePosterParams4H5AnnualBouns({
        contentId: brandActivityCode,
        sourceScene: "AnnualBonusActivityLanding",
      });
      setCustomEditData({
        contentType: "ANNUAL_BONUS",
        contentId: brandActivityCode,
        sourceScene: "AnnualBonusActivityLanding",
      });
      setSharePosterData(sharePosterData);
      setShowPoster(true);
    } else {
      shareAnnualBounsPosterAction({
        contentId: brandActivityCode,
        contentType: "ANNUAL_BONUS",
        pageName: "品牌伯乐奖",
        src: "AnnualBonusActivityLanding",
      });
    }
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "auto";
  };

  const getByBrandActivityCode = () => {
    if (!brandActivityCode) { return; }
    setPageLoading(true);
    queryByBrandActivityCode({
      brandActivityCode
    })
      .then(res => {
        const { brandId, merchantCode } = res || {};
        setPageInfo({
          ...(res || {}),
          brandIds: brandId ? brandId.split(",") : [],
          merchantCodeList: merchantCode ? merchantCode.split(",") : []
        });
        setPageLoading(false);
        track.track("$pageview", {
          pageName: "伯乐奖落地页",
        });
        setTrackData({
          pageName: "伯乐奖落地页",
          previous_page_name: search.get("page_name") || "",
          resource_id: brandActivityCode,
        });
      });
  };

  useEffect(() => {
    getByBrandActivityCode();
  }, [brandActivityCode]);

  return (
    <div className={styles.page}>
      <div
        ref={PageScrollRef}
        className={`${styles.page__scroll} ${disableScroll && styles.page__scroll__disable}`}
      >
        <div
          className={styles.scroll__body}
          style={{
            backgroundImage: `url(${pageInfo?.sharePageInfoConfig?.pageBgImg ||
              "https://akim-oss.aikucun.com/mshop/fd7f76e1d0baf36c7582969a707b5ea210aab35a_1715072579626_64.png"
              })`,
          }}
        >
          <TopNavigation
            hideSearchIcon={true}
            hideCartIcon={true}
            hideFavoriteIcon={true}
            backgroundColor={isSticky ? "#fff" : "transparent"}
          />
          {!showEmptyStatus && (
            <ActivityProductProvider
              brandIds={pageInfo?.brandIds}
              merchantCodeList={pageInfo?.merchantCodeList}
            >
              <ProductList
                ref={ProductListRef}
                onScrollTop={onScrollTop}
                setDisableScroll={setDisableScroll}
                setShowEmptyStatus={setShowEmptyStatus}
                handleSetIsSticky={setIsSticky}
                trackData={pageTrackData}
              />
            </ActivityProductProvider>
          )}
          {showEmptyStatus && <PageEmptyStatus pageInfo={pageInfo} />}
        </div>
      </div>


      {!showEmptyStatus && (
        <div className={styles.page__footer}>{
          currentRole === "C" ? (
            <div
              className={`${styles.footer__btn} ${styles.footer__btn_c}`}
              onClick={(e) => onShare(e)}
            >
              分享
            </div>
          ) : (
            <div
              className={`${styles.footer__btn} ${styles.footer__btn_e}`}
              onClick={(e) => onShare(e)}
            >
              转发赚钱
            </div>
          )
        }</div>
      )}

      {pageLoading && (
        <div className={`${styles.pageLoading} ${styles.loadingFixed}`}>
          <Image src={loadingPng} width={pxtorem(30)}></Image>
        </div>
      )}

      {/* 海报*/}
      <PosterSwipe
        show={showPoster}
        posterData={sharePosterData}
        customEditData={customEditData}
        shareType="ANNUAL_BONUS"
        btnConfig={["copy", "edit"]}
        close={onClosePoster}
      />
    </div>
  );
};

export default Landing;