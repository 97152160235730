import { setUser } from "@/utils/sentry";
import { getBaseurl, urlQueryObject } from "@/utils/url-parse";
import { Request } from "./request";

export type UserInfo = {
  phone: string;
  nickname: string;
  headImgUrl: string;
  openId: string;
  administrator: boolean;
  roleSwitchAvailable: boolean;
  currentRole: number;
  nameAuth: boolean;
  nameAuthType: number;
  shopOwner?: boolean;
  shopDistributor?: boolean;
};

export const exchangeUserRole = (currentRole: number) => {
  if (currentRole === 4) {
    return "A";
  } else if (currentRole === 3) {
    return "E";
  } else if (currentRole === 2) {
    return "R";
  }
  return "C";
};

export async function queryUserInfo(): Promise<UserInfo> {
  const { distributorId, shopId = localStorage.getItem("shopId") } = urlQueryObject();
  const res = await Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${shopId}${
      distributorId ? `?distributorId=${distributorId}` : ""
    }`,
    method: "get",
  });
  setUser({ ...res?.data, shopId });
  return res?.data;
}
