import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "./request";

// 获取微信config
export const ApiGetWxConfig = (url: string): Promise<Response<any>> => {
  return Request({
    url: getBaseurl() + "/api/member/signature",
    method: "get",
    params: {
      url,
    },
  });
};
