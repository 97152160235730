import { API, B2rListRequestParams, PageableResponse, ShopCardModel } from "@akc/biz-api";

/**
 * 查询店铺卡片列表
 */
export const queryB2RShopList = async (params: B2rListRequestParams) => {
  return API.post<PageableResponse<ShopCardModel>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/merchant-shop/list",
  });
};
