import useNavigation, { IOptions } from "@/utils/useNavigate";
import React from "react";
import { FloatingBall } from "react-vant";

import ICON_CART from "@/assets/icon_navigation_cart_1.png";
import styles from "./index.module.less";

interface IProps {
  cartCount: number;
}
const FloatCart: React.FC<IProps> = ({ cartCount }) => {
  const { navigate2H5 } = useNavigation();

  const goCart = () => {
    const options: IOptions = {
      path: "/#/cart",
      is_micro_app: true,
    };
    navigate2H5(options);
  };

  return (
    <>
      <FloatingBall
        adsorb={{ distance: 10 }}
        offset={{ right: 10, bottom: "20vh" }}
        className={styles["loating-box"]}
      >
        <div className={styles["cart"]} onClick={goCart}>
          <img src={ICON_CART} className={styles["cart__image"]} alt="" />
          <div className={styles["cart__text"]}>购物车</div>
          {cartCount > 0 && <div className={styles["cart_badge"]}>{cartCount}</div>}
        </div>
      </FloatingBall>
    </>
  );
};
export default FloatCart;
