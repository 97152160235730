import moment from "moment";

const formatDate = (time?: string | number) => {
  if (!time) {
    return "";
  }
  moment.updateLocale("en", {
    calendar: {
      sameDay: "[今天]HH:mm",
      nextDay: "[明天]HH:mm",
      nextWeek: "M.D HH:mm",
      lastDay: "M.D HH:mm",
      lastWeek: "M.D HH:mm",
      sameElse() {
        return "M.D HH:mm";
      },
    },
  });
  return moment(Number(time)).calendar();
};

const isSameDay = (time1: number, time2: number) => {
  const date1 = new Date(time1);
  const date2 = new Date(time2);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export { formatDate, isSameDay };
