import { getCouponCount } from "@/api/coupon";
import useNavigate from "@/utils/useNavigate";
import React, { useEffect } from "react";
import styles from "./index.module.less";

type props = {
  afterShow?: (show: boolean) => void;
};
const CouponNotice: React.FC<props> = ({ afterShow }) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [count, setCount] = React.useState<number>(0);
  const navigate = useNavigate();
  useEffect(() => {
    getCouponCount().then((data = 0) => {
      setCount(data);
      setShow(data > 0);
    });
  }, []);
  useEffect(() => {
    afterShow?.(show);
  }, [show]);

  return (
    show && (
      <div
        className={styles.container}
        onClick={() => {
          navigate.navigate2CouponList();
        }}
      >
        <div>
          您有<span className={styles.count}>{count}</span>张优惠券未使用，下单立减，前去查看
        </div>
        <img className={styles.arrow} />
        <img
          onClick={(e) => {
            e.stopPropagation();
            setShow(false);
          }}
          className={styles.close}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcBAMAAACAI8KnAAAAJ1BMVEXMzMxHcEzNzc3MzMzOzs7Nzc3MzMzR0dHLy8v9/f3s7OzNzc3Y2NhhG+/rAAAACXRSTlP/AMXpPonYIVR4BnZcAAAAvElEQVQY022RPQ6CQBCFX0AtoCIhSkljT0FiK/ECxhPoEYwn4BhDRbtZC2qO4KmcnyySjS9hM19m8/bNgIJV3pr02EkF/qo7WLve8FBDlTwVL0bIW8ESizrG1w9PjPUPkwIVVuqx5/PjgfnNxQNnPt0w5W7gYoOrIPmZBLcQp4wGR6N4oZEAjrSJ1Bwz0mbQTOStWi5PernWJlt5tbKHxvCQxUCIEUJmFjIaIRowHj9azrI6tP8WG619/VO+zSgz3TUt9TUAAAAASUVORK5CYII="
        />
      </div>
    )
  );
};

export default CouponNotice;
