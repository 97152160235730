import { AccountInfo } from "@/models/reward";
import { platform } from "@/utils/platform";
import React from "react";
import styles from "./RedPacket.module.less";
import RotateComp from "./Rotate";

interface CardProps {
  redPacket?: AccountInfo;
  totalAmount: number;
  onUsePress?: (isUse?: boolean, info?: AccountInfo) => void;
  onDetailPress?: (info?: AccountInfo) => void;
  onRefreshPress?: () => void;
}

const RedPacketCardComp: React.FC<CardProps> = ({
  redPacket,
  totalAmount,
  onUsePress,
  onDetailPress,
  onRefreshPress,
}) => {
  const hasRedPacket = parseFloat(redPacket?.freeBalance ?? "0") > 0;

  const onUseAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onUsePress?.(hasRedPacket, redPacket);
  };

  const onDetailAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onDetailPress?.(redPacket);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.tag} />
          <div className={styles.card_left}>
            <div className={styles.left_content}>
              <div className={styles.content_title} onClick={onDetailAction}>
                <div className={styles.title_left}>红包余额</div>
                <div className={styles.title_right}>
                  <div className={styles.price_left}>¥</div>
                  <div className={styles.price_right}>{`${redPacket?.freeBalance ?? 0}`}</div>
                </div>
                <div className={styles.dec_title}>明细</div>
                <img
                  className={styles.dec_image}
                  src={
                    "https://akim-oss.aikucun.com/mshop/11fdcf7b0fdc6fde0a168ede848ffb6681c0bef8_1723546528825_85.png"
                  }
                />
              </div>
              {totalAmount > -1 ? (
                <div className={styles.desc_title}>{`累计获取红包共计¥${totalAmount}`}</div>
              ) : (
                <div className={styles.total_content}>
                  <div className={styles.c_title}>累计获取红包</div>
                  <RotateComp toggleRotationPress={onRefreshPress} />
                  <div className={styles.c_title}>点击刷新</div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.card_right} onClick={onUseAction}>
            <div className={styles.btn_title}>{hasRedPacket ? "去使用" : "去逛逛"}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RedPacketCardComp;
