import { SortType } from "@/api/activity";
import { ReactComponent as FilterIcon } from "@/assets/filter.svg";
import SortIcon from "@/components/sort-icon";
import { useCommonContext } from "@/utils/context/common";
import { Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./FilterBar.module.less";

type Props = {
  filterInfo: any;
  setFilterInfo: (info: any) => void;
};

interface PopProps extends Props {
  setIsShowPop: (info: any) => void;
  setFilterTabActive: (info: any) => void;
}

const enum SortModel {
  综合 = 1,
  上新占比 = 2,
  销量 = 3,
  转发量 = 4,
  红包余额 = 5,
  上新次数 = 6,
  销售价 = 7,
  省赚 = 8,
  折扣 = 9,
  开始时间 = 10,
  相关度 = 11,
  销售价沉底 = 14,
}

const tabsList = {
  common: [
    {
      name: "综合",
      sortModel: SortModel["综合"],
      hasSortIcon: false,
      sortType: SortType["Default"],
      active: true,
    },
    {
      name: "销量",
      sortModel: SortModel["销量"],
      hasSortIcon: true,
      sortType: SortType["Default"],
      active: false,
    },
    {
      name: "上新",
      sortModel: SortModel["上新占比"],
      hasSortIcon: true,
      sortType: SortType["Default"],
      active: false,
    },
  ],
  share: [
    {
      name: "转发",
      sortModel: SortModel["转发量"],
      hasSortIcon: true,
      sortType: SortType["Default"],
      active: false,
    },
  ],
  activity: [
    {
      name: "拆红包",
      sortModel: SortModel["红包余额"],
      hasSortIcon: false,
      sortType: SortType["Default"],
      active: false,
    },
  ],
  status: [
    {
      name: "已隐藏",
      key: "seeHide",
      val: true,
      active: false,
    },
    {
      name: "未隐藏",
      key: "seeHide",
      val: false,
      active: false,
    },
    {
      name: "已转发",
      key: "isShare",
      val: true,
      active: false,
    },
  ],
};

const PopContent: React.FC<PopProps> = ({
  filterInfo,
  setFilterInfo,
  setIsShowPop,
  setFilterTabActive,
}) => {
  const [activityList, setActivityList] = useState(tabsList.activity);
  const [statusList, setStatusList] = useState(tabsList.status);
  const selectActivity = (index: number) => {
    setActivityList(
      activityList.map((item, i) => {
        if (i === index) {
          item.active = !item.active;
        } else {
          item.active = false;
        }
        return item;
      }),
    );
  };
  const selectStatus = (index: number) => {
    setStatusList(
      statusList.map((item, i) => {
        if (i === index) {
          item.active = !item.active;
        } else {
          item.active = false;
        }
        return item;
      }),
    );
  };
  const reset = () => {
    setActivityList(
      activityList.map((item) => {
        item.active = false;
        return item;
      }),
    );
    setStatusList(
      statusList.map((item) => {
        item.active = false;
        return item;
      }),
    );
  };
  const submit = () => {
    const selectedActivity = activityList.find((item) => item.active);
    const selectedStatus = statusList.find((item) => item.active);
    const newFilterInfo = { ...filterInfo };
    if (selectedActivity) {
      const { sortModel } = selectedActivity;
      newFilterInfo.sortModel = sortModel;
    }
    statusList.map((item) => {
      const { key, val } = item;
      if (item.active) {
        newFilterInfo[key] = val;
      } else {
        const existItem = statusList.find((el) => el["key"] === key);
        if (!existItem?.active) {
          delete newFilterInfo[key];
        }
      }
    });
    selectedActivity || selectedStatus ? setFilterTabActive(true) : setFilterTabActive(false);
    setFilterInfo(newFilterInfo);
  };
  return (
    <div className={styles.popContent}>
      <div className={styles.header}>筛选</div>
      <dl className={styles.group}>
        <dt className={styles.title}>营销活动</dt>
        <dd className={styles.list}>
          {activityList.map((item, index) => (
            <div
              key={item.name}
              className={styles.item + " " + (item.active && styles.active)}
              onClick={() => {
                selectActivity(index);
              }}
            >
              {item.name}
            </div>
          ))}
        </dd>
      </dl>
      <dl className={styles.group}>
        <dt className={styles.title}>活动状态</dt>
        <dd className={styles.list}>
          {statusList.map((item, index) => (
            <div
              key={item.name}
              className={styles.item + " " + (item.active && styles.active)}
              onClick={() => {
                selectStatus(index);
              }}
            >
              {item.name}
            </div>
          ))}
        </dd>
      </dl>
      <div className={styles.footer}>
        <div className={styles.btns}>
          <div className={styles.btn} onClick={reset}>
            重置
          </div>
          <div
            className={styles.primaryBtn}
            onClick={() => {
              submit();
              setIsShowPop(false);
            }}
          >
            确定
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterBar: React.FC<Props> = ({ filterInfo, setFilterInfo }) => {
  const { currentRole } = useCommonContext();
  const [list, setList] = useState<any[]>([]);
  const [isShowPop, setIsShowPop] = useState(false);
  const [filterTabActive, setFilterTabActive] = useState(false);

  const changeTab = (index: number) => {
    const activeIndex = list.findIndex((item) => item.active);
    const Desc = SortType["Desc"];
    if (index === activeIndex) {
      const item = list[index];
      if (item.hasSortIcon) {
        item.sortType = item.sortType === Desc ? SortType["Asc"] : Desc;
        setList(list.map((item) => item));
        const { sortModel, sortType } = item;
        setFilterInfo({
          ...filterInfo,
          sortModel,
          sortType,
          forwarded: false,
          isShare: false,
        });
      }
    } else {
      setList(
        list.map((item, i) => {
          if (i === index) {
            item.active = true;
            if (item.hasSortIcon) {
              item.sortType = Desc;
            }
            const { sortModel, sortType } = item;
            setFilterInfo({
              ...filterInfo,
              sortModel,
              sortType,
              forwarded: false,
              isShare: false,
            });
          } else {
            item.active = false;
            if (item.hasSortIcon) {
              item.sortType = SortType["Default"];
            }
          }
          return item;
        }),
      );
    }
  };

  useEffect(() => {
    if (currentRole === "C") {
      setList(tabsList.common);
    } else {
      setList([...tabsList.common, ...tabsList.share]);
    }
  }, [currentRole]);

  return (
    <>
      <div className={styles.wrap}>
        {list.map((item, index) => (
          <div
            key={item.sortModel}
            className={styles.tab + " " + (item.active && styles.active)}
            onClick={() => {
              changeTab(index);
            }}
          >
            <span>{item.name}</span>
            {item.hasSortIcon && <SortIcon sortType={item.sortType} />}
          </div>
        ))}
        {currentRole !== "C" && (
          <div
            className={styles.filterTab + " " + (filterTabActive && styles.filterActive)}
            onClick={() => {
              setIsShowPop(true);
            }}
          >
            <span>筛选</span>
            <FilterIcon />
          </div>
        )}
      </div>
      <Popup
        visible={isShowPop}
        style={{ zIndex: 10000 }}
        onMaskClick={() => {
          setIsShowPop(false);
        }}
      >
        <PopContent
          filterInfo={filterInfo}
          setFilterInfo={setFilterInfo}
          setIsShowPop={setIsShowPop}
          setFilterTabActive={setFilterTabActive}
        />
      </Popup>
    </>
  );
};

export default FilterBar;
