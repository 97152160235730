import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import { pxtorem } from "@/utils/pxtorem";
import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface Props {
  loading: boolean; //是否加载中
  hasMore: any; //是否还有更多
  reachBottom: boolean; //是否结束
  [key: string]: any;
}

const BottomComp: React.FC<Props> = (props: Props) => {
  if (props.loading) {
    return;
  }
  return (
    <div className={styles.infinite}>
      {props.hasMore ? (
        <div>
          <img
            className={styles.loadingMore}
            src={loadingPng}
            style={{ width: pxtorem(20), height: pxtorem(20) }}
          />
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.reachBottom && (
            <>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
              <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BottomComp;
