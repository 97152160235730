import { platform } from "@/utils/platform";
import { getBaseurl, urlQueryObject } from "@/utils/url-parse";
import { isEmpty } from "lodash";

//获取用户身份
export const getIdentity = () => {
  // eslint-disable-next-line no-underscore-dangle
  const isMini = window.__wxjs_environment === "miniprogram";
  const isApp = navigator && navigator.userAgent.search("AKC") > -1;
  const isWxH5 = !isMini && navigator && !!navigator.userAgent.match(/MicroMessenger/i);
  const isH5 = isWxH5 || (!isMini && !isApp);

  //如果是app，那全是r
  if (isApp) {
    return false;
  }
  if (isWxH5 || isH5) {
    const userInfo =
      isEmpty(localStorage?.userinfo) || localStorage?.userinfo === "undefined"
        ? null
        : JSON.parse(localStorage?.userinfo);
    if (userInfo) {
      const role = userInfo?.actualRole || userInfo?.currentRole;
      return role === 1 ? true : false;
    } else {
      return false;
    }
  }
  if (isMini) {
    const urlQuery = urlQueryObject();
    if (urlQuery["isC"] && urlQuery["isC"] === "1") {
      return true;
    }
    return false;
  }
};

export const identity = getIdentity;

export const getUserRole = () => {
  const userInfo =
    isEmpty(localStorage?.userinfo) || localStorage?.userinfo === "undefined"
      ? null
      : JSON.parse(localStorage?.userinfo);
  // 如果是app，那全是r
  if (platform.isApp) {
    return "R";
  }
  if (userInfo?.currentRole === 2) {
    return "R";
  }
  if (userInfo?.currentRole === 1) {
    return "C";
  }
  // 管理员
  if (userInfo?.currentRole === 4) {
    return "A";
  }
  return "E";
};

/** 不需要鉴权pathname */
const unAuthPathname = ["/mx-shop-micro/after-pay"];
/**
 * 是否需要授权
 */
export const shouldAuth = () => {
  const pathname = window.location.pathname;
  if (
    !platform.isH5 ||
    !pathname.startsWith("/mx-shop-micro") ||
    unAuthPathname.includes(pathname)
  ) {
    return false;
  }
  return true;
};

export const checkIdentity = () => {
  if (!shouldAuth()) {
    return;
  }
  let current = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  const ua = window.navigator.userAgent.toLowerCase();
  const result = ua.match(/MicroMessenger/i);
  if (result) {
    window["willRedirect"] = true;
    if (!/\brdt=1/.test(current)) {
      current += (current.match(/.*\?/) ? "&" : "?") + "rdt=1";
    }
    window.location.href = `${getBaseurl()}/api/member/auth?backUrl=${window.encodeURIComponent(
      current,
    )}`;
  }
};
