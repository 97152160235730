import { getProtectAccount } from "@/api/my/shop";
import { Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface ITaskInfo {
  taskName?: string;
  content?: string;
  completed?: boolean;
  planValue?: string;
  targetValue?: string;
  alertContent?: string;
}

const ShopProtectAccount: React.FC = () => {
  const [taskInfo, setTaskInfo] = useState<ITaskInfo>({});
  const [visible, setVisible] = useState(false);

  const queryShopData = async () => {
    const data = await getProtectAccount();
    data && setTaskInfo(data);
  };

  useEffect(() => {
    queryShopData();
  }, []);

  return (
    <>
      {taskInfo && taskInfo?.taskName && (
        <div className={styles["protect-account"]}>
          <div className={styles["protect-account_title"]}>
            <div className={styles["title-l"]}>
              <i className={styles["title_icon1"]} />
              <div className={styles["title_main"]}>{taskInfo.taskName}</div>
            </div>
            <div className={styles["title-r"]} onClick={() => setVisible(true)}>
              <div className={styles["title_sub"]}>完成任务即可保留现有功能</div>
              <i className={styles["title_icon2"]} />
            </div>
          </div>
          <div className={styles["protect-account_content"]}>
            <div className={styles["content-l"]}>{taskInfo.content}</div>
            <div className={styles["content-r flex-center"]}>
              <span className={styles["theme"]}>
                {taskInfo.completed ? "已" : "未"}完成：{taskInfo.planValue}
              </span>
              <span>/{taskInfo.targetValue}</span>
            </div>
          </div>
        </div>
      )}
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          minHeight: "40vh",
        }}
      >
        <div className={styles["popup_header"]}>
          <span>保账号任务</span>
          <i className={styles["icon_close"]} onClick={() => setVisible(false)} />
        </div>
        <div className={styles["popup_content"]}>{taskInfo.alertContent}</div>
      </Popup>
    </>
  );
};

export default ShopProtectAccount;
