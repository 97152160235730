import { checkBind } from "@/api/my";
import { DotLoading } from "antd-mobile";
import React, { useEffect, useState } from "react";
import Apply from "./components/apply";
import Process from "./components/process";

const Logout: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [accountCancelling, setAccountCancelling] = useState(true);

  const init = async () => {
    const res: any = await checkBind();
    setAccountCancelling(res.code === 100008);
    setIsLoading(false);
  };

  const applySuccess = () => {
    console.log("申请成功");
    init();
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading) {
    return <DotLoading />;
  }
  if (accountCancelling) {
    return <Process />;
  } else {
    return (
      <Apply
        applySuccess={() => {
          applySuccess();
        }}
      />
    );
  }
};
export default Logout;
