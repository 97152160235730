import { fetchCustomerRewardList, Tab } from "@/api/daily-reward";
import {
  bizInfoParams,
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
  SourceScene,
} from "@/api/scode/scode_params";
import { CustomEditData } from "@/components/poster-swiper";
import { AwardItem, CouponAward } from "@/models/reward";
import { platform } from "@/utils/platform";
import { shareDailyReward } from "@/utils/share/share";
import { SearchBarRef } from "antd-mobile";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRangeModel } from "../components/date-select-dialog";
import usePageAction from "../utils/action";

let sharePosterData: any = null;
const useCustomerReward = () => {
  const tabList = [
    { name: "日日奖红包", type: "RED_PACKET" },
    { name: "实物奖", type: "PRODUCT" },
    { name: "优惠券", type: "COUPON" },
    { name: "积分", type: "POINTS" },
  ];
  const { openOrderDetail, openCustomerCneter, openShopDetail } = usePageAction();
  const [list, setList] = useState<AwardItem[]>([]);
  const pageIndex = useRef<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dateDialogVisible, setDateDialogVisible] = useState(false);
  const dateRange = useRef<DateRangeModel>();
  const searchText = useRef<string>();
  const [selectTab, setSelectTab] = useState<Tab>(tabList?.[0]);
  const [searchParams] = useSearchParams();
  const [showPoster, setShowPoster] = useState(false);
  const promoActivityId = searchParams.get("promoActivityId") ?? "";
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const searchBarRef = useRef<SearchBarRef>(null);
  const updateList = async (isSelect?: boolean) => {
    setLoading(true);
    if (selectTab?.type === undefined) {
      return;
    }
    const res = await fetchCustomerRewardList({
      awardType: selectTab?.type,
      pageIndex: pageIndex.current,
      startTime: dateRange?.current?.startDate,
      endTime: dateRange?.current?.endDate,
      userName: searchText.current,
      pageSize: 10,
    });
    setLoading(false);
    if (!res) {
      return;
    }
    pageIndex.current = (res?.pageIndex ?? 0) + 1;
    if (isSelect) {
      setList(res?.result || []);
    } else {
      setList([...list, ...(res?.result || [])]);
    }
    setHasMore(res?.hasNext ?? false);
  };

  const loadMore = async () => {
    if (hasMore && !loading) {
      updateList();
    }
  };

  useEffect(() => {
    document.title = "顾客奖励";
    updateList();
  }, []);

  useEffect(() => {
    pageIndex.current = 1;
    updateList(true);
  }, [selectTab]);

  (window as any).akc_onAppear = () => {
    console.log("页面出现");
  };

  const onChangeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setSelectTab(tabList?.[index]);
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  const onSearchChange = (text: string) => {
    searchText.current = text;
  };

  const onSearchAction = () => {
    pageIndex.current = 1;
    updateList(true);
    // 关闭软键盘
    if (searchBarRef.current) {
      searchBarRef.current.blur();
    }
  };

  const rewardName = (reward?: AwardItem) => {
    if (reward?.awardType === "RED_PACKET") {
      return reward?.redPackageAward?.amount;
    }
    if (reward?.awardType === "COUPON") {
      return reward?.couponAward?.couponName;
    }
    if (reward?.awardType === "POINTS") {
      return reward?.pointsAward?.amount;
    }
    if (reward?.awardType === "PRODUCT") {
      return reward?.productAward?.activitySpuId;
    }
  };

  // 转发
  const onForwardAction = (reward?: AwardItem) => {
    if (platform.isH5) {
      sharePosterData = {
        bizInfo: bizInfoParams({
          contentType: ContentType.dailyRewardGift,
          contentId: promoActivityId,
          contentDetailId: reward?.userId,
          extra: {
            awardName: rewardName(reward),
            awardType: reward?.awardType,
          },
        }),
        envContext: envContextParams({
          sourceScene: SourceScene.dailyRewardGift,
          sourceSceneId: ContentType.dailyRewardGift,
        }),
        contentContext: {
          awardType: reward?.awardType,
        },
        carrierContext: {
          ...carrierContextParams([CarrierElementType.h5Poster]),
          posterCode: ContentType.dailyRewardGift,
        },
      };
      setCustomEditData({
        promoActivityId: promoActivityId,
        awardName: reward && rewardName(reward),
        awardType: reward?.awardType,
      });
      setShowPoster(true);
    } else {
      const params = {
        contentId: promoActivityId,
        shareType: SourceScene.dailyRewardGift,
        contentDetailId: reward?.userId,
        awardName: rewardName(reward),
        awardType: reward?.awardType,
      };
      shareDailyReward(params);
    }
  };
  const onSelectTimeAction = () => {
    setDateDialogVisible(true);
  };

  const onCloseTimeAction = () => {
    setDateDialogVisible(false);
  };

  const getDateRange = (res?: DateRangeModel) => {
    dateRange.current = res;
    pageIndex.current = 1;
    updateList(true);
  };

  const getMonthAndDay = (dateString?: string) => {
    if (dateString === undefined) {
      return "";
    }
    const parts = dateString.split("-");
    return `${parts?.[1]}.${parts?.[2]}`;
  };

  const showSelectTime = () => {
    if (dateRange.current === undefined) {
      return "选择时间";
    }
    if (dateRange.current?.tabIndex === 1) {
      return `${getMonthAndDay(dateRange.current?.startDate)}-${getMonthAndDay(dateRange.current?.endDate)}`;
    }
    return dateRange.current?.fixName ?? "选择时间";
  };
  const onClosePosterAction = () => {
    setShowPoster(false);
  };

  const emptyTitleDes = (tab?: Tab) => {
    if (tab?.type === "PRODUCT") {
      return "暂无相关可用的实物奖励";
    }
    if (tab?.type === "RED_PACKET") {
      return "暂无相关可用的红包";
    }
    if (tab?.type === "COUPON") {
      return "暂无相关可用的优惠券";
    }
    if (tab?.type === "POINTS") {
      return "暂无相关可用的积分";
    }
  };

  // 顾客中心
  const onCustomerAction = (reward?: AwardItem) => openCustomerCneter(reward);

  // 订单详情
  const onOrderDetailAction = (reward?: AwardItem) => openOrderDetail(reward);

  // 实物奖去店铺
  const onShopDetailAction = (reward?: AwardItem) =>
    openShopDetail(reward?.productAward?.merchantShopCode);

  // 商家券跳店铺
  const onCouponToShopDetailAction = (coupon?: CouponAward) =>
    openShopDetail(coupon?.merchantShopCode);

  return {
    tabList,
    selectTab,
    list,
    loading,
    hasMore,
    dateDialogVisible,
    dateRange,
    showPoster,
    sharePosterData,
    customEditData,
    searchBarRef,
    loadMore,
    onSearchChange,
    emptyTitleDes,
    onSearchAction,
    onSelectTimeAction,
    onChangeTabIndex,
    onForwardAction,
    getDateRange,
    showSelectTime,
    onCloseTimeAction,
    onCustomerAction,
    onClosePosterAction,
    onOrderDetailAction,
    onShopDetailAction,
    onCouponToShopDetailAction,
  };
};

export default useCustomerReward;
