import { useCommonContext } from "@/utils/context/common";

export const useTrack = (pageName?: string) => {
  const { track } = useCommonContext();

  //PV
  const trackPageView = (params?: {
    previousPageName?: string | null;
    materialId?: string | null;
    activitySpuId?: string | null;
    activityId?: string | null;
  }) => {
    try {
      if (track.track) {
        track.track("$pageview", {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          material_id: params?.materialId,
          activity_spu_id: params?.activitySpuId,
          activity_id: params?.activityId,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //素材复制按钮
  const trakcMaterialCopy = (params?: {
    previousPageName?: string | null;
    materialId?: string | null;
    activitySpuId?: string | null;
  }) => {
    try {
      if (track.track) {
        const trackParams = {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          material_id: params?.materialId,
          activity_spu_id: params?.activitySpuId,
          resource_type: "素材内容",
          resource_name: "复制",
          btn_name: "复制",
        };
        track.track("resource_click", trackParams);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //营业执照
  const trackBusinessLicense = (params?: {
    previousPageName?: string | null;
    materialId?: string | null;
    activitySpuId?: string | null;
  }) => {
    try {
      if (track.track) {
        const trackParams = {
          page_name: pageName,
          previous_page_name: params?.previousPageName,
          material_id: params?.materialId,
          activity_spu_id: params?.activitySpuId,
          resource_type: "商品信息",
          resource_name: "查看营业执照",
          btn_name: "查看营业执照",
        };
        track.track("resource_click", trackParams);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    trackPageView,
    trakcMaterialCopy,
    trackBusinessLicense,
  };
};
