import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";
import { NormalScodeParams } from "./scode_params";

const staticUrl = getBaseurl();
export interface ShareCardResponse {
  urlShareTitle?: string;
  urlShareDesc?: string;
  urlShareImg?: string;
  urlShareUrl?: string;
  shareCopyWriting?: string;
  scode?: string;
}

/**
 * 请求生成H5/小程序卡片信息
 */
export const fetchShareCardInfo = (
  data: NormalScodeParams & {
    bizInfo: any;
  },
): Promise<Response<ShareCardResponse>> => {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/shareCardInfo`,
    method: "post",
    data: {
      ...data,
      contentContext: {
        ...data.contentContext,
        contentType: data.bizInfo.contentType,
        contentId: data.bizInfo.contentId,
      },
    },
  });
};
