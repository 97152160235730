import { CouponItem } from "@/api/rewardCenter";
import { SharePosterParams, generateSharePoster } from "@/api/scode/poster";
import {
  CarrierElementType,
  ContentType,
  SourceScene,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import { fetchForwardMode } from "@/api/share";
import { navigate } from "@/utils/navigate";
import { platform } from "@/utils/platform";
import { forwardPosterLink4App } from "@/utils/share/share";
import AKJS from "@akc/akjs";
import { Toast } from "antd-mobile";

/**
 * 优惠券转发
 * 目前仅支持小程序
 * @returns
 */
export const shareCoupon = async (data: CouponItem) => {
  const { isApp, isMini } = platform;
  console.log(platform);
  // App 逻辑分享海报逻辑
  if (isApp) {
    console.log("shareCoupon");
    Toast.show({
      icon: "loading",
      content: "海报加载中…",
      duration: 0,
    });
    // 1. 获取转发配置 & 获取视频号灰度状态
    const res = await fetchForwardMode();
    console.log("fetchForwardMode", res);
    const forwardMode = res;
    const posterCarrierElement =
      forwardMode === "h5" ? CarrierElementType.h5Poster : CarrierElementType.miniPoster;
    // 2. 根据转发配置生成海报 & 复制文案 & 复制链接
    const bizInfo = {
      contentId: data.voucherNo || data.couponId,
      contentType: "TENANT_FULL_REDUCTION_COUPON", // || ContentType.coupon || "FULL_REDUCTION_COUPON",
      contentExtra: {
        "tenant.coupon.dcTemplateVoucherName": data.dcTemplateVoucherName || "0.1元无门槛券",
        "tenant.coupon.quotaAmount": data.quotaAmount || 0.1,
        "tenant.coupon.timeDesc": data.timeDesc || "自领取之日起1天可用",
      },
      shortCodeExtend: {
        ownerType: "USE_CODE",
      },
    };
    const envContext = envContextParams({
      // sourceScene: SourceScene.conference3,
      // sourceSceneId: voucherNo,
    });
    const results = await generateSharePoster({
      bizInfo: bizInfo as SharePosterParams["bizInfo"],
      envContext: envContext,
      carrierContext: carrierContextParams([posterCarrierElement]),
    });
    Toast.clear();
    const posterInfo = results?.data?.posterInfos?.[0];
    console.log(posterInfo);
    AKJS.share.sharePoster({
      image: posterInfo?.imageUrl,
      onshare: (res) => {
        console.log("client share props", res);
        // this.trackEvent('share', { ...shareProps, ...(res && res.data) })();
      },
    });
    // forwardPosterLink4App({
    //   imageUrl: posterInfo?.imageUrl,
    //   onShare: res => {
    //     console.log("client share props", res);
    //     // this.trackEvent("share", { ...shareProps, ...(res && res.data) })();
    //   },
    // });
  } else if (isMini) {
    // 小程序分享海报逻辑
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          voucherNo: data.voucherNo || data.couponId,
          shareType: "TENANT_FULL_REDUCTION_COUPON",
          ownerType: data.ownerType,
          dcTemplateVoucherName: data.dcTemplateVoucherName,
          quotaAmount: data.quotaAmount,
          timeDesc: data.timeDesc,
        },
      },
    });
  }
};
