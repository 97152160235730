import { getBaseurl } from "@/utils/url-parse";
import { Response } from "@akc/biz-api";
import { Request } from "../request";
import { AppOrderDirectReqBody, AppOrderDirectResponse, CreateOrderReqBody } from "./model";

// App直购下单-post请求体
// https://yapi.akcops.com/project/1626/interface/api/266550
export const appOrderDirect = async (props: AppOrderDirectReqBody) => {
  const res = await Request<any, Response<AppOrderDirectResponse>>({
    url: `${process.env.REACT_APP_ZUUL_INFRA}/akucun-order-center/api/order/create/direct`,
    method: "post",
    data: props,
  });
  return res;
};

// 饷店创建订单-post请求体
// https://yapi.akcops.com/project/867/interface/api/201381
export const createOrder = async (props: CreateOrderReqBody) => {
  const res = await Request<any, Response<AppOrderDirectResponse>>({
    url: `${getBaseurl()}/api/order/create/v2`,
    method: "post",
    data: props,
  });
  return res;
};
