import { isSwitchBatch } from "@/api";
import { querySkin } from "@/api/decorate";
import cart from "@/assets/icon/tabbar_cart.png";
import cartactive from "@/assets/icon/tabbar_cart_active.png";
import dashboard from "@/assets/icon/tabbar_index.png";
import dashboardactive from "@/assets/icon/tabbar_index_active.png";
import my from "@/assets/icon/tabbar_my.png";
import myactive from "@/assets/icon/tabbar_my_active.png";
import order from "@/assets/icon/tabbar_order.png";
import orderactive from "@/assets/icon/tabbar_order_active.png";
import shop from "@/assets/icon/tabbar_shop.png";
import shopactive from "@/assets/icon/tabbar_shop_active.png";
import { useCommonContext } from "@/utils/context/common";
import { EventName, TabchangeEventData } from "@/utils/event/events";
import { getUserRole } from "@/utils/identity";
import { resetRem } from "@/utils/rem";
import { wxShare } from "@/utils/wx-share";
import microApp from "@micro-zoe/micro-app";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface TabBarItemProps {
  icon: string;
  icon2: string; // 当推荐tab下的图标
  activeIcon: string;
  type: string;
  name: string;
  path: string;
  skin: any;
  active: boolean;
}

let tapCount = 0;

const tabs = [
  {
    path: "/mx-shop-micro/home",
    name: "首页",
    icon: dashboard,
    activeIcon: dashboardactive,
    icon2:
      "https://akim-oss.aikucun.com/783c7a162fb80810f836ae4dca873e714b10aca4_1701693852128_34.png",
    type: "h5-shop",
  },
  {
    path: "/mx-shop-micro/promote",
    name: "逛一逛",
    icon: "https://akim-oss.aikucun.com/aff229c0c051ef4014952e6323d28533eb98702e_1701183067925_75.png",
    activeIcon:
      "https://akim-oss.aikucun.com/1eecff00be7b62fee3b4e6909312d09dcabf797b_1700113977446_17.png",
    icon2:
      "https://akim-oss.aikucun.com/aff229c0c051ef4014952e6323d28533eb98702e_1701183067925_75.png",
    type: "shop-micro",
  },
  {
    path: "/mx-shop-micro/market",
    name: "品牌馆",
    icon: shop,
    activeIcon: shopactive,
    icon2:
      "https://akim-oss.aikucun.com/9cc5f86504f287fdf3e948d186aa763644c6a917_1701693893951_22.png",
    type: "shop-micro",
  },
  {
    path: "/#/order/list",
    name: "订单",
    icon: order,
    activeIcon: orderactive,
    icon2:
      "https://akim-oss.aikucun.com/81b3798bebc63c6900afbcc6f76859c4fbb66f4b_1701693714255_13.png",
    type: "h5-shop",
  },
  {
    path: "/mx-shop-micro/my",
    name: "我的",
    icon: my,
    activeIcon: myactive,
    icon2:
      "https://akim-oss.aikucun.com/a82c993d7388d58af303d721d5030d9fa476273e_1701694161634_32.png",
    type: "shop-micro",
  },
];

interface TabBarProps {
  currentPath: string;
}

const TabBar: React.FC<TabBarProps> = React.memo(({ currentPath }) => {
  // console.log(currentPath);
  const [search] = useSearchParams();
  const { eventManager, cartNum, setCartNum } = useCommonContext();
  let shopId = search.get("shopId");
  let distributorId = search.get("distributorId");
  const fromPage = search.get("fromPage");
  const [skin, setSkin] = React.useState<any>({});
  const [handledTabs, setHandledTabs] = React.useState<any>([]);
  const [isShowNewHone, setIsShowNewHone] = React.useState<boolean>(false);
  const [hasShowedGuideTip, setHasShowedGuideTip] = React.useState<string>(
    localStorage.getItem("hasShowedGuideTip") || "",
  );
  const [activeTab, setActiveTab] = React.useState<string>("");
  const navigater = useNavigate();
  // 这些静态页不用显示底部导航栏
  const noBarList = ["/mx-shop-micro/video-share?readOnly=true", "/mx-shop-micro/shop/info"];
  const handleTabs = (data?: any) => {
    const handledTabs = tabs.map((item) => {
      if (item.name === "首页") {
        return {
          ...item,
          icon: data?.tabbarHomeNochooseImg || item.icon,
          activeIcon: data?.tabbarHomeChooseImg || item.activeIcon,
        };
      }
      if (item.name === "品牌馆") {
        return {
          ...item,
          icon: item.icon,
          activeIcon: item.activeIcon,
          name: getUserRole() === "R" ? "店铺" : "品牌馆",
        };
      }
      if (item.name === "订单") {
        return {
          ...item,
          icon: data?.tabbarOrderNochooseImg || item.icon,
          activeIcon: data?.tabbarOrderChooseImg || item.activeIcon,
        };
      }
      if (item.name === "我的") {
        return {
          ...item,
          name: "我的",
          icon: data?.tabbarPersonNochooseImg || my,
          activeIcon: data?.tabbarPersonChooseImg || myactive,
        };
      }
      return item;
    });
    setHandledTabs(handledTabs);
  };
  const isActiveTab = () => {
    let activeTab = "1";
    if (window.location.pathname.startsWith("/mx-shop-micro/promote")) {
      activeTab = search.get("tab") || "1";
    }
    setActiveTab(activeTab);
  };
  const setTabs = async () => {
    try {
      const [res, res2] = await Promise.all([
        querySkin(),
        isSwitchBatch({
          bwlCodes: ["B-BOTTOM-SHOP-TAB", "B-recommendCommunityTab", "B-HomePageRefatorH5"],
        }),
      ]);
      if (res2?.data && res2.data?.length) {
        const showBrand = !!res2.data[0]?.isHit;
        const showCommunity = !!res2.data[1]?.isHit;
        const showNewHome = !!res2.data[2]?.isHit;
        setIsShowNewHone(showNewHome);
        !showBrand
          ? tabs.splice(
              tabs.findIndex((item) => item.path.indexOf("market") >= 0),
              1,
            )
          : void 0;
        !showCommunity
          ? tabs.splice(
              tabs.findIndex((item) => item.path.indexOf("promote") >= 0),
              1,
            )
          : void 0;
        if (showNewHome) {
          const homeTabItem = tabs.find((item) => item.name === "首页");
          const orderTabItemIndex = tabs.findIndex((item) => item.name === "订单");
          if (homeTabItem) {
            homeTabItem.path = "/mx-shop-micro/home";
            homeTabItem.type = "shop-micro";
          }
          if (orderTabItemIndex > -1) {
            tabs[orderTabItemIndex] = {
              path: "/#/cart",
              name: "购物车",
              icon: cart,
              activeIcon: cartactive,
              icon2:
                "https://akim-oss.aikucun.com/7bac3583d5e09fb7514bd2e458d0131fdf2b9f4b_1718174520093_29.png",
              type: "h5-shop",
            };
          }
        }
      }
      if (res) {
        handleTabs(res);
        setSkin(res);
        isActiveTab();
        eventManager.subscribe<TabchangeEventData>(EventName.Tabchange, (data) => {
          console.log("setActiveTab", data.tabs);
          setActiveTab(data?.tabs);
        });
      }
    } catch (error) {
      handleTabs();
    }
  };
  const init = async () => {
    await setTabs();
  };
  // console.log("currentPath", currentPath);
  React.useEffect(() => {
    if (noBarList.findIndex((item) => window.location.href.indexOf(item) > -1) > -1) {
      console.log("noBarList", noBarList);
      return;
    }
    init();
    setCartNum();
  }, []);
  React.useEffect(() => {
    isActiveTab();
  }, [currentPath]);
  const getBaseNavi = () => {
    return navigater;
  };

  const getWechatShare = () => {
    return wxShare;
  };
  const TabBarItem: React.FC<TabBarItemProps> = ({
    icon,
    name,
    activeIcon,
    path,
    type,
    skin,
    active,
    icon2,
  }) => {
    const params = window.location.hash
      .split(/\?|&/)
      .filter((item) => item.indexOf("=") > 0)
      .reduce((a, c) => {
        const tmp = c.split("=");
        a[tmp[0]] = tmp[1];
        return a;
      }, {});
    const handler = (type, toPath) => {
      if (path !== "/mx-shop-micro/promote") {
        setActiveTab("1");
      }
      if (
        window.location.href.indexOf("/mx-shop-micro") > -1 &&
        window.location.href.indexOf("#") > -1
      ) {
        const currentUrl = window.location.href;
        const newUrl = currentUrl.toString().split("#")[0];
        history.replaceState(null, document.title, newUrl);
      }

      if (window.location.hash) {
        shopId = params["shopId"];
        distributorId = params["distributorId"];

        if (window.location.hash?.startsWith("mx-shop-micro/home?")) {
          sessionStorage.setItem(
            "indexPageScrollHeight",
            "" +
              (window.scrollY ||
                document.documentElement.scrollTop ||
                document.body.scrollTop ||
                0),
          );
        }
        // 当前在h5shop页面的情况下，在基座应用往h5shop的其它页面跳的时候，通过navigater跳转不生效，需要通知子应用做跳转
        if (type === "h5-shop" && microApp.getData("h5shop")) {
          microApp.setData("h5shop", {
            type: "switch-tab",
            target: toPath.split("#")[1],
            getBaseNavi,
            getWechatShare,
            resetRem,
          });
          return;
        }
      }

      if (toPath === "/mx-shop-micro/my") {
        tapCount = tapCount + 1;
        if (tapCount === 5) {
          // 1秒内连续点击5下,打开智能客服页面
          window.location.href = `/akc-cs/chat-list?locationType=6&webChannel=3&shopId=${shopId}${
            distributorId ? `&distributorId=${distributorId}` : ""
          }`;
          tapCount = 0;
        }
        setTimeout(() => {
          tapCount = 0;
        }, 1000);
      }

      if (toPath === "/mx-shop-micro/market") {
        tapCount = tapCount + 1;
        if (tapCount === 5) {
          const url = distributorId
            ? `/mx-shop-micro/daily-reward?shopId=${shopId}&distributorId=${distributorId}`
            : `/mx-shop-micro/daily-reward?shopId=${shopId}`;
          // 1秒内连续点击5下,打开日日奖
          window.location.href = url;
          tapCount = 0;
        }
        setTimeout(() => {
          tapCount = 0;
        }, 1000);
      }

      distributorId
        ? navigater(`${toPath}?shopId=${shopId}&distributorId=${distributorId}`, {
            replace: true,
          })
        : navigater(`${toPath}?shopId=${shopId}`, {
            replace: true,
          });
    };

    return (
      <div
        className={
          styles.tab_bar_item +
          " " +
          (name === "购物车" && !hasShowedGuideTip && isShowNewHone && styles.cart)
        }
        onClick={(e) => {
          handler(type, path);
        }}
      >
        <img
          className={styles.icon}
          src={active ? activeIcon : activeTab === "2" ? icon2 : icon}
          alt={name}
        />
        <div
          className={styles.text}
          style={{
            color: active
              ? skin?.tabbarSelectTextColor || "#e62525"
              : path.indexOf("promote") && activeTab === "2"
                ? "#FFFFFF"
                : skin?.tabbarNormalTextColor || "#7d7e80",
          }}
        >
          {name}
        </div>
        {name === "购物车" && !!cartNum && <div className={styles.numWrap}>{cartNum}</div>}
      </div>
    );
  };
  return (
    <>
      {fromPage !== "productDetail" &&
        tabs
          .filter((item) => item.path !== "/#/order/list")
          .some((tab) => currentPath.startsWith(tab.path)) && (
          <>
            <div
              className={styles.tabbar_content}
              style={{
                background: activeTab === "2" ? "rgba(0,0,0, 1)" : "",
                backgroundImage: activeTab !== "2" ? `url(${skin?.tabberBackgroup})` : "none",
              }}
              id="tab-bar-bottom"
            >
              {handledTabs.map((tab, index) => (
                <TabBarItem
                  active={currentPath.startsWith(tab.path)}
                  skin={skin}
                  key={index}
                  icon={tab.icon}
                  activeIcon={tab.activeIcon}
                  name={tab.name}
                  type={tab.type}
                  path={tab.path}
                  icon2={tab.icon2}
                />
              ))}
              {!hasShowedGuideTip && isShowNewHone && (
                <div
                  className={styles.cart_guide}
                  onClick={() => {
                    localStorage.setItem("hasShowedGuideTip", "true");
                    setHasShowedGuideTip("true");
                  }}
                >
                  <div className={styles.wrap}>
                    <div className={styles.guide_btn}>我知道了</div>
                    <div className={styles.guide_content}>
                      {/* { 购物车移到这里了，订单可在「我的-订单中心」查看哦} */}
                    </div>
                    <div className={styles.guide_line}></div>
                  </div>
                </div>
              )}
              <div id="tab-bar-mask" className={styles.tabBar_mask}></div>
            </div>
          </>
        )}
    </>
  );
});

export { TabBar };
