import { checkBind, checkUserBindPhone } from "@/api/my";
import { getPlatform } from "@/utils/platform";
import { useEffect, useState } from "react";

const useCheckBind = () => {
  const [isBind, setIsBind] = useState(true);
  const [isAuthed, setIsAuthed] = useState(true);

  const updateAuthStatus = async () => {
    const {
      data: { isAuthorized },
    } = await checkBind();
    setIsAuthed(isAuthorized);
  };
  const updateBindStatus = async () => {
    const {
      data: { isBindPhone },
    } = await checkUserBindPhone();
    setIsBind(isBindPhone);
    const { isMini } = getPlatform();
    if (!isBindPhone && !isMini) {
      updateAuthStatus();
    }
  };

  useEffect(() => {
    updateBindStatus();
  }, []);
  return {
    isAuthed,
    isBind,
    updateBindStatus,
  };
};

export default useCheckBind;
