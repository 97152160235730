import { msgReachSubscribe } from "@/api/rewardCenter";
import { getPlatform } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import cls from "./index.module.less";
import { navigateTo } from "./toFollow";

type Props = {
  pageName?: string;
  onLoadComplete?: (visible: boolean) => void;
};
const Follow: React.FC<Props> = ({ pageName, onLoadComplete }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState("中奖提醒不延迟，活动进度即刻知！");
  const [btnText, setBtnText] = useState("立即开启");
  const [data, setData] = useState<any>();

  const querySubscribeInfo = async () => {
    const list = await msgReachSubscribe();
    if (list?.length) {
      const res = list.find((item) => item.appKey === "app_promoter");
      if (res) {
        onLoadComplete?.(true);
        setData(res);
        setText(res.showTitle);
        setBtnText("立即开启");
        setShow(true);
      }
    } else {
      onLoadComplete?.(false);
    }
  };
  const toFollow = () => {
    navigateTo(data);
  };
  const close = () => {
    setShow(false);
  };

  useEffect(() => {
    const platform = getPlatform();
    if (platform.isH5) {
      return;
    }
    querySubscribeInfo();
  }, []);
  // 查询关注公众号接口无异常，且为未关注状态 展示提示
  return (
    show && (
      <div className={pageName === "rewardDetail" ? cls.wrap2 : cls.wrap}>
        <div className={cls.followBox}>
          <div className={cls.followContent}>{text}</div>
          <div className={cls.btnWrap}>
            <div className={cls.followBtn} onClick={toFollow}>
              {btnText}
            </div>
            <div className={cls.closeBtn} onClick={close}></div>
          </div>
        </div>
      </div>
    )
  );
};
export default Follow;
