import Clickable from "@/components/clickable";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const CardItem = (props) => {
  const { item, userInfo } = props;
  const { navigate2MyItem } = useNavigation();

  const onItemClick = () => {
    if (item.path) {
      navigate2MyItem(item.path);
      return;
    }
  };

  return (
    <Clickable
      className={styles.cardItem}
      onClick={onItemClick}
      trackData={{
        page_name: "我的",
        btn_name: item.title,
      }}
    >
      <div className={styles.flexBetweenCenter}>
        <div className={styles.itemLeft}>
          {item.icon && <img src={item.icon} alt="" />}
          {item.title}
        </div>
        <div className={styles.itemRight}>
          {item.desc && <span>{item.desc}</span>}
          {item.key === "walletItem" && userInfo?.amount && (
            <span className={styles.amount}>{userInfo.amount}</span>
          )}
          <i className={styles.arrow} />
        </div>
      </div>
    </Clickable>
  );
};

export default CardItem;
