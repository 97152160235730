import ArrowRight from "@/assets/arrow_right.png";
import { ReactComponent as BoxSelect } from "@/assets/box_select.svg";
import { ReactComponent as BoxUnselect } from "@/assets/box_unselect.svg";
import { MemberSignUpInfo } from "@akc/biz-api";
import { CascadePicker, Toast } from "antd-mobile";
import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import YxFormItemComp from "../form-item";
import useBasicInfo from "./hooks/useBasicInfo";
import styles from "./index.module.less";

interface Props {
  showCheckStatus: boolean; // 是否显示提示信息
  memberInfo?: MemberSignUpInfo; // 会员信息
  ref?: ForwardedRef<any>;
}

// 游学报名 基础信息组件
const YxSignUpBasicInfoComp: React.FC<Props> = forwardRef(
  ({ memberInfo, showCheckStatus }, ref) => {
    console.log("render YxSignUpBasicInfoComp:", memberInfo);
    const {
      inputNameRef,
      inputIdolNumRef,
      inputPhoneRef,
      inputCetificateRef,
      sexValue,
      setSexValue,
      addressInfo,
      setAddressInfo,
      personalDesc,
      statementContent,
      setStatementContent,
      onSelectCity,
      onInputPersonalDesc,
      onOnekeyCopy,
      curSignupInfo,
      addressSelectVisible,
      setAddressSelectVisible,
      addressList,
      checkContentIsValid,
      showDisplayAddress,
    } = useBasicInfo(memberInfo);

    const getSignUpContent = () => {
      return curSignupInfo.current;
    };

    useImperativeHandle(ref, () => ({
      getSignUpContent,
      checkContentIsValid,
    }));

    return (
      <>
        <div className={styles["containerC"]}>
          <div className={styles["basicInfo"]}>
            {/* 姓名 */}
            <YxFormItemComp
              ref={inputNameRef}
              formItem={{
                id: "inputName",
                title: "姓名",
                content: memberInfo?.name,
                hintContent: "请填写真实姓名",
                showCheckStatus: showCheckStatus,
                maxLength: 10,
                className: styles["firstColumn"],
              }}
            />
            {/* 爱豆编号 */}
            <YxFormItemComp
              ref={inputIdolNumRef}
              formItem={{
                id: "inputAidouNum",
                title: "爱豆编号",
                content: memberInfo?.memberCode,
                hintContent: "请输入爱豆编号",
                showCheckStatus: showCheckStatus,
                maxLength: 20,
              }}
            />
            {/* 联系方式 */}
            <YxFormItemComp
              ref={inputPhoneRef}
              formItem={{
                id: "inputPhone",
                title: "联系方式",
                content: memberInfo?.mobileNumber,
                hintContent: "请输入联系方式",
                showCheckStatus: showCheckStatus,
              }}
            />
            {/* 性别 */}
            <div className={styles["itemV"]}>
              <div className={styles["itemV1"]}>
                <div className={styles["left"]}>
                  <div className={styles["flag"]}>*</div>
                  <span className={styles["title"]}>性别</span>
                </div>
                <div className={styles["right"]}>
                  <div className={styles["cbx"]} onClick={() => setSexValue(1)}>
                    {sexValue === 1 ? (
                      <BoxSelect className={styles["cbIv"]} />
                    ) : (
                      <BoxUnselect className={styles["cbIv"]} />
                    )}
                    <span className={styles["des"]}>男</span>
                  </div>
                  <div className={styles["cbx1"]} onClick={() => setSexValue(2)}>
                    {sexValue === 2 ? (
                      <BoxSelect className={styles["cbIv"]} />
                    ) : (
                      <BoxUnselect className={styles["cbIv"]} />
                    )}
                    <span className={styles["des"]}>女</span>
                  </div>
                </div>
              </div>
              <div className={styles["divider"]} />
              {sexValue !== 1 && sexValue !== 2 && showCheckStatus && (
                <span className={styles["inputTips"]}>此项为必填项</span>
              )}
            </div>
            {/* 证书名称 */}
            <YxFormItemComp
              ref={inputCetificateRef}
              formItem={{
                id: "inputCertificate",
                title: "品牌合作伙伴证书名称",
                content: memberInfo?.certificate,
                hintContent: "请输入证书名称",
                showCheckStatus: showCheckStatus,
                maxLength: 50,
              }}
            />
            {/* 出发城市 */}
            <div className={styles["itemV"]} onClick={onSelectCity}>
              <div className={styles["itemV1"]}>
                <div className={styles["left"]}>
                  <div className={styles["flag"]}>*</div>
                  <span className={styles["title"]}>出发城市</span>
                </div>
                <div className={styles["right"]}>
                  {addressInfo &&
                  (addressInfo.province?.length ?? 0) > 0 &&
                  (addressInfo.city?.length ?? 0) > 0 ? (
                    <span className={styles["cityTv"]}>{showDisplayAddress(addressInfo)}</span>
                  ) : (
                    <span className={styles["chooseTv"]}>请选择</span>
                  )}
                  <img className={styles["arrowIv"]} src={ArrowRight} />
                </div>
              </div>
              <div className={styles["divider"]} />
              {(addressInfo?.province?.length ?? 0) === 0 &&
                (addressInfo?.city?.length ?? 0) === 0 &&
                showCheckStatus && <span className={styles["inputTips"]}>此项为必填项</span>}
            </div>
            {/* 个人意愿 */}
            <div className={styles["item2"]}>
              <div className={styles["top"]}>
                <div className={styles["flag"]}>*</div>
                <span className={styles["title"]}>参加游学个人意愿描述</span>
              </div>
              <textarea
                id="description"
                placeholder="请详细描述个人能力"
                className={styles["input"]}
                value={personalDesc}
                maxLength={3000}
                onChange={(res) => {
                  onInputPersonalDesc(res.target.value);
                }}
              />
              {(personalDesc === undefined || personalDesc?.length === 0) && showCheckStatus && (
                <span className={styles["inputTips1"]}>此项为必填项</span>
              )}
            </div>
          </div>
          {/* 注意事项 */}
          <div className={styles["attention"]}>
            <div className={styles["tv1"]}>注意事项确认</div>
            <div className={styles["tv2"]}>
              1.请确认所报名游学入选后可参与，如无故不参与将取消后续游学资格！
            </div>
            <div className={styles["tv3"]}>
              2.所有游学均不允许携带小孩及家属参加，如若需要携带小孩请勿报名参加。
            </div>
            <div className={styles["dv1"]}>
              <div className={styles["flag"]}>*</div>
              <span className={styles["title"]}>为保障游学能够顺利进行，请输入以下声明：</span>
            </div>
            <div className={styles["tv4"]}>本人确认入选后即可参加游学，且确认不携带小孩参加</div>
            <div className={styles["dv2"]}>
              <textarea
                id="idStatement"
                placeholder="请输入抄写内容"
                className={styles["copyInput"]}
                value={statementContent}
                onChange={(res) => {
                  setStatementContent(res.target.value);
                }}
              />
              {statementContent?.length === 0 && (
                <div className={styles["v2Tv1"]} onClick={onOnekeyCopy}>
                  一键输入
                </div>
              )}
            </div>
          </div>

          {addressSelectVisible && (
            <CascadePicker
              options={addressList ?? []}
              cancelText={
                <div style={{ color: "#A5A5A5", fontSize: 16, marginLeft: 10 }}>取消</div>
              }
              confirmText={
                <div
                  style={{ color: "#FF4747", fontSize: 16, fontWeight: "bold", marginRight: 10 }}
                >
                  确定
                </div>
              }
              visible={addressSelectVisible}
              defaultValue={[addressInfo?.province ?? "", addressInfo?.city ?? ""]}
              onClose={() => {
                setAddressSelectVisible(false);
              }}
              onConfirm={(val, extend) => {
                console.log("地址选择确认:", val);
                if (val && val.length > 0) {
                  setAddressInfo({
                    province: val[0]?.toString(),
                    city: val[1]?.toString(),
                  });
                }
              }}
              onSelect={(val) => {
                console.log("onSelect", val);
              }}
            />
          )}
        </div>
      </>
    );
  },
);

export default YxSignUpBasicInfoComp;
