import { urlQueryObject } from "./url-parse";
/* eslint-disable camelcase */
export const resetRem = () => {
  console.log("重新设置值------", sessionStorage.getItem("conference3_product_12"));

  // 重设1-2size
  const conference3Product = sessionStorage.getItem("conference3_product_12");
  if (conference3Product) {
    const conference3ProductObj = JSON.parse(conference3Product);
    for (const key in conference3ProductObj) {
      if (Object.prototype.hasOwnProperty.call(conference3ProductObj, key)) {
        try {
          setTimeout(() => {
            const keyId = document.getElementById(key) as HTMLElement;
            if (!keyId) {
              return;
            }
            keyId.style.height = conference3ProductObj[key] || "1000px";
          }, 500);
        } catch (error) {
          // console.log("4444", error);
        }
      }
    }
  }

  if (
    location.pathname.includes("/mx-shop-micro/activity/") ||
    location.pathname === "/mx-shop-micro/decorate"
  ) {
    document.body.style.overflow = "unset";
  } else {
    document.body.style.overflow = "hidden";
  }

  //重新设置h5微信分享信息
  const defaultData = {
    desc: "",
    link: `${window.location.href.split("#")[0]}mx-shop-micro/home?shopId=${urlQueryObject().shopId}`,
    imgUrl: "https://cdn.xiangdian.com/cube/p633-1646035630195.png",
  };
  const share_storage = sessionStorage.getItem("conference3_share_info") as string;

  if (!share_storage) {
    return;
  }

  const { shareData, repostParams, isHideAll } = JSON.parse(share_storage) as any;
  console.log("分享flag----", sessionStorage.getItem("conference3_share_flag"));
  if (
    !sessionStorage.getItem("conference3_share_flag") &&
    (sessionStorage.getItem("conference3_share_flag") === "" ||
      sessionStorage.getItem("conference3_share_flag") === null)
  ) {
    console.log("回到原页面，初始化分享信息");
    window.wx.ready(() => {
      // 判断当前客户端版本是否支持指定JS接口
      window.wx.checkJsApi({
        jsApiList: [
          "onMenuShareAppMessage",
          "onMenuShareTimeline",
          "showMenuItems",
          "chooseWXPay",
          "previewImage",
          "chooseImage",
          "getNetworkType",
          "hideAllNonBaseMenuItem",
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success() {
          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"
          // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
          console.log("设置成功分享信息");
          window.wx.onMenuShareAppMessage({
            title: shareData.title || document.title, // 分享标题
            desc: shareData.desc || shareData.shopDesc || shareData.title || document.title, // 分享描述
            link: shareData.link || defaultData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shareData.imgUrl || shareData.shopLogoUrl || defaultData.imgUrl, // 分享图标
            success() {
              console.log("发送给朋友-------", shareData);
            },
            fail() {
              console.log("发送给朋友-fail");
            },
            complete() {
              console.log("发送给朋友-complete");
            },
            cancel() {
              console.log("发送给朋友-cancel");
            },
            trigger() {
              console.log("发送给朋友-trigger");
            },
          });
          // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
          window.wx.onMenuShareTimeline({
            title: shareData.title || document.title, // 分享标题
            link: shareData.link || defaultData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: shareData.imgUrl || shareData.shopLogoUrl || defaultData.imgUrl, // 分享图标
            success() {
              // 设置成功
              console.log("发送给朋友-success");
              console.log("发送给朋友-repostParams", repostParams);
            },
            cancel() {
              // 用户取消分享后执行的回调函数
            },
          });
          console.log("isHideAll", isHideAll);
          if (isHideAll === true) {
            window.wx.hideAllNonBaseMenuItem();
          } else {
            // 要显示的菜单项，所有menu项见附录3
            window.wx.showMenuItems({
              menuList: [
                // 要显示的菜单项
                "menuItem:share:appMessage", // 发送给朋友
                "menuItem:share:timeline", // 分享到朋友圈
                "menuItem:favorite", // 收藏
                "menuItem:share:qq", // 分享到QQ
                "menuItem:share:QZone", //  分享到QQ空间
                "menuItem:openWithQQBrowser", // 分在QQ浏览器中打开
                "menuItem:openWithSafari", // 在Safari中打开
                "menuItem:share:email", // 邮件
                "menuItem:copyUrl", // 复制链接
              ],
            });
          }
        },
      });
    });
    sessionStorage.setItem("conference3_share_flag", "");
  }
};
export const storeRem = () => {
  const fontSize = document?.querySelectorAll("html")?.[0]?.style?.fontSize;
  sessionStorage.setItem("rootfontSize", fontSize);
};
