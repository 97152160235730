import { queryBindCheck } from "@/api/member";
import { getPlatform } from "@/utils/platform";
import { useEffect, useState } from "react";

const useCheckBind = () => {
  const [isBind, setIsBind] = useState(true);
  const [isAuthed, setIsAuthed] = useState(true);
  const [isVisitor, setIsVisitor] = useState(false);
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = useState(false);

  const { isMini } = getPlatform();

  const updateBindStatus = async () => {
    const { isAuthorized, isBindPhone, isVisitor } = await queryBindCheck();
    setIsAuthed(isAuthorized);
    setIsBind(isBindPhone);
    setIsVisitor(isVisitor);
  };

  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };

  const bindPhone = () => {
    if (isBind) {
      return;
    }
    if (isMini) {
      window.wx.miniProgram.navigateTo({
        url: "/pages/auth-page/auth-page",
      });
    } else {
      if (!isAuthed) {
        toAuth();
        return;
      }
      setBindPhonePopupVisible(true);
    }
  };

  useEffect(() => {
    updateBindStatus();
  }, []);
  return {
    isAuthed,
    isBind,
    isVisitor,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    bindPhone,
    updateBindStatus,
  };
};

export default useCheckBind;
