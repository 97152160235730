import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";
import { NormalScodeParams, PosterBizInfo } from "./scode_params";

const staticUrl = getBaseurl();

export type LinkMessageInfo = {
  copyMessage: string;
  scode: string;
};

// 获取复制链接
export const fetchCopyLink = (
  data: NormalScodeParams & {
    bizInfo: PosterBizInfo;
  },
): Promise<Response<LinkMessageInfo>> => {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/copyMessage`,
    method: "post",
    data: {
      ...data,
      contentContext: {
        ...data.contentContext,
        contentType: data.bizInfo.contentType,
        contentId: data.bizInfo.contentId,
      },
    },
  });
};
