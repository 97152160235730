import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./search.module.less";
import { useSearchContext } from "./useSearch";

interface IProps {
  placeholder: string;
  onBack: () => void;
  querySuggestList: (text: string) => void;
  onSearch?: (key?: string) => void;
}

const SearchHeader: React.FC<IProps> = ({ placeholder, onBack, querySuggestList, onSearch }) => {
  const track = Track();
  const [search] = useSearchParams();
  const keyword = search.get("key");
  const [inputText, setInputText] = useState("");

  const {
    searchTagText,
    showSearchTag,
    handleSearch,
    setSearchTagText,
    setShowSearchTag,
    setShowSuggest,
  } = useSearchContext();

  const handleSearchFocus = () => {
    setShowSearchTag(false);
    setShowSuggest(true);
    console.log("handleSearchFocus", showSearchTag);
    setTimeout(() => {
      const dom: any = document.getElementById("inputDom");
      dom?.focus();
    }, 0);
  };
  const handleSearchClick = () => {
    if (inputText || searchTagText || keyword) {
      onSearch?.(inputText || searchTagText || keyword as string);
    }
  };
  const handleTextChange = (e) => {
    const text = e?.target?.value;
    console.log("handleTextChange", text);
    setInputText(text);
    setSearchTagText(text);
  };
  const handleKeydown = (e: any) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
    if (showSearchTag) {
      return;
    }
    let text = e.target.value;
    if (isEmpty(text) && keyword) {
      text = keyword;
    }
    console.log("handleKeydown", e.key);
    onSearch?.(inputText);
  };
  const handleTagClear = (e?: any) => {
    if (e) {
      e.stopPropagation();
    }
    setShowSuggest(true);
    setShowSearchTag(false);
    setSearchTagText("");
    setInputText("");
    setTimeout(() => {
      const dom: any = document.getElementById("inputDom");
      dom?.focus();
    }, 0);
  };

  useEffect(() => {
    querySuggestList(inputText);
    // 清除上一个 effect，确保每次只有一个防抖函数在运行
    // return () => {
    //   querySuggestList.cancel();
    // };
  }, [inputText]);
  useEffect(() => {
    if (searchTagText !== inputText) {
      setInputText(searchTagText);
    }
  }, [searchTagText]);

  return (
    <div className={styles.searchBox}>
      {/* <img
        onClick={onBack}
        className={styles.back}
        src="https://akim-oss.aikucun.com/11d024154013c0ca47dfaeca33ecdeb6a5b9fcb6_1694082344311_50.png"
      ></img> */}
      <div className={styles.inputBox} onClick={handleSearchFocus}>
        <img
          className={styles.img}
          src="https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1694086326376_51.png"
        ></img>
        <form action="#" onSubmit={(e) => e.preventDefault()}>
          <input
            className={
              styles.input + " " + (showSearchTag && searchTagText ? styles.hid : styles.visiable)
            }
            onKeyDown={handleKeydown}
            onChange={handleTextChange}
            id="inputDom"
            type="search"
            value={inputText}
            placeholder={keyword || placeholder}
            autoFocus
            onFocus={() => {
              setShowSearchTag(false);
              setShowSuggest(true);
            }}
            onBlur={() =>
              setTimeout(() => {
                setShowSearchTag(true);
                setShowSuggest(false);
              }, 100)
            }
          ></input>
        </form>
        <div
          className={
            styles.tag + " " + (showSearchTag && searchTagText ? styles.visiable : styles.hid)
          }
        >
          <div
            style={{
              fontSize: pxtorem(14),
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: pxtorem(200),
            }}
          >
            {searchTagText}
          </div>
          <img
            onClick={handleTagClear}
            className={styles.close}
            src="https://akim-oss.aikucun.com/feacb8e58e1e4974fd9a12dc2e5e730ffd3ec89f_1694091300902_1.png"
          ></img>
        </div>
      </div>
      <div className={styles.text} onClick={handleSearchClick}>
        搜索{showSearchTag}
      </div>
    </div>
  );
};
export default SearchHeader;
