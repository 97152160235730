import { isSwitchBatch } from "@/api";
import * as animationData from "@/assets/lottie/like.json";
import AddPurchasePanel from "@/components/add-purchase-panel";
import AddToCartModal from "@/components/add-to-cart-modal";
import { getCurPageClientWidth } from "@/utils/getStyle";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import {
  RecommendSmallActivity,
  RecommendSmallConference,
  RecommendSmallProduct,
  RecommendSmallSchedule,
} from "@akc/mx-taro-h5-components";
import { Mask, Popup, Slider } from "antd-mobile";
import { throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import { createPortal } from "react-dom";
import Lottie from "react-lottie";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "../../track";
import PocketCom from "../recommend/pocket";
import styles from "./index.module.less";
interface VideoEntity {
  url: string; //
  cover: string;
  creatorAvatar: string;
  name: string; // 标题
  productListVOList: Array<any>;
  activityListVOList: Array<any>;
  conferenceVOList: Array<any>;
  scheduleListVOList: Array<any>;
  allList: Array<any>;
  materialNo: string;
  awesomeNum: string;
  awesome: boolean;
}
interface VideoPlayerProps {
  activeTab: string;
  videoItem: VideoEntity;
  queryGetCartTotal: () => void;
  currentVideoIndex: number;
  muted: boolean;
  triggerShare: () => void;
  playPreviousVideo: (e: any) => void;
  playNextVideo: (e: any) => void;
  playAnimation: () => void;
  showAnimation: boolean;
  cartTotal: number;
  index: number;
  setSwiper: (enable: boolean) => void;
  canAuto: boolean;
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const {
    canAuto,
    activeTab,
    videoItem,
    queryGetCartTotal,
    currentVideoIndex,
    muted,
    cartTotal,
    showAnimation,
    triggerShare,
    playAnimation,
    index,
    setSwiper,
  } = props;
  const rootRef = useRef<HTMLVideoElement>(null);
  const pListRef = useRef<HTMLDivElement>(null);
  const portalRoot = document.getElementById("videoContainer") as HTMLElement;
  const [cartModalVisible, setCartModalVisible] = useState(false);
  const [popVisible, setPopVisible] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [search] = useSearchParams();
  const fromPage = search.get("fromPage");
  const [isSliderMoving, setIsSliderMoving] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentPlayId, setCurrentPlayId] = useState<string>("");
  const [formattedDuration, setFormattedDuration] = useState("00:00");
  const [formattedCurrentTime, setFormattedCurrentTime] = useState("00:00");
  const [ecommerceVisiable, setEcommerceVisiable] = useState<boolean>(false);
  const {
    navigate2Product,
    navigate2Activity,
    navigate2LiveRoom,
    navigate2SuperVenue,
    navigate2Venue,
    navigate,
    navigate2Cart,
  } = useNavigation();
  const [modalProduct, setModalProduct] = useState({});
  const {
    trackPlay,
    trackVideoExpose,
    trackVideoClick,
    trackPocketClick,
    trackProductCardExpose,
    trackProductCardClick,
  } = useTrack();
  const [showShareMask, setShowShareMask] = useState(false);
  const [loading, setLoading] = useState(false);

  //是否显示新版加购面板
  const [showNewCartPanel, setShowNewCartPanel] = useState<boolean>(false);
  //获取加购面板灰度
  const getCartPanelGray = async () => {
    const res = await isSwitchBatch({ bwlCodes: ["B-H5_REACT_ADD_PANEL"] });
    const newCartPanel = !!res?.data?.[0]?.isHit;
    setShowNewCartPanel(newCartPanel);
  };

  //增加购物车数量
  const changeCartNum = () => {
    queryGetCartTotal();
  };
  const changeVideoTime = throttle((value) => {
    setIsSliderMoving(true);
    // console.log("change", value, currentTime, duration);
    // eslint-disable-next-line
    const videoRef = rootRef.current;
    // setTimeout(() => {
    if (videoRef) {
      videoRef.currentTime = (value / 100) * videoRef.duration;
      setCurrentTime(videoRef.currentTime);
    }
    // }, 50);
  });

  // 生成随机字符串
  function generateRandomString(length) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  }
  // 视频控制bar
  const onTogglePlay = (e?: any | null, control?: "play" | "pause") => {
    // e?.stopPropagation();
    const videoRef = rootRef.current;
    const item = videoItem;
    const params = {
      item,
      videoRef: {
        duration: rootRef?.current?.duration,
        currentTime: rootRef?.current?.currentTime,
        ended: rootRef?.current?.ended,
        videoIndex: currentVideoIndex,
      },
      btn_name: rootRef.current?.paused ? "播放" : "暂停",
      currentPlayId,
      fromPage,
    };
    if (videoRef) {
      if (control === "play") {
        const p = videoRef.play();
        if (p !== undefined) {
          p.then(() => {
            setIsPlaying(true);
            console.log("auto");
          }).catch(() => {
            console.log("not auto");
            setIsPlaying(false);
          });
        }
        return;
      }
      if (control === "pause") {
        videoRef.pause();
        setIsPlaying(false);
        return;
      }
      setIsPlaying(videoRef.paused);
      videoRef.paused ? videoRef.play() : videoRef.pause();
      trackPlay(params);
    }
  };

  function formatVideoTime(seconds) {
    const formatSeconds = Math.round(seconds);
    const minutes = Math.floor(formatSeconds / 60);
    const remainingSeconds = formatSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const videoHandler = () => {
    const videoRef = rootRef.current;
    if (videoRef) {
      const aspectRatio = videoRef.videoWidth / videoRef.videoHeight;
      const width = getCurPageClientWidth();
      const height = width / aspectRatio;
      videoRef.style.height = `${height}px`;
      const t1 = formatVideoTime(videoRef?.duration);
      // onTogglePlay(null, "pause");
      // setTimeout(() => {
      // }, 0);
      setDuration(videoRef?.duration);
      setFormattedDuration(t1);
    }
  };
  const progressBarHandler = () => {
    const videoRef = rootRef.current;
    console.log("progressBarHandler", videoRef);
    videoRef?.addEventListener("loadeddata", videoHandler);
    // videoRef?.load();
    videoRef?.addEventListener("timeupdate", function () {
      const t2 = formatVideoTime(videoRef?.currentTime);
      setCurrentTime(videoRef?.currentTime);
      setFormattedCurrentTime(t2);
    });
  };
  useEffect(() => {
    if (!canAuto) {
      onTogglePlay(null, "pause");
    }
  }, [canAuto]);
  useEffect(() => {
    //获取加购面板灰度
    getCartPanelGray();
    const handleVisibilityChange = () => {
      if (document.hidden) {
        onTogglePlay(null, "pause");
        // 页面切换到后台
        console.log("页面切换到后台");
        // 在这里可以执行相关的操作，例如暂停视频播放、停止音乐等
      } else {
        // 页面切换回前台
        console.log("页面切换回前台");
        // 在这里可以执行相关的操作，例如恢复视频播放、继续音乐等
      }
    };
    progressBarHandler();
    document.addEventListener("visibilitychange", handleVisibilityChange);
  }, []);
  useEffect(() => {
    if (activeTab === "1") {
      onTogglePlay(null, "pause");
    }
    // if (activeTab === "2") {
    //   if(currentVideoIndex === index)
    //   onTogglePlay(null, "play");
    // }
  }, [activeTab]);
  useEffect(() => {
    if (index === currentVideoIndex) {
      onTogglePlay(null, "play");
      setTimeout(() => {
        setEcommerceVisiable(true);
      }, 200);
      // progressBarHandler();
      const pList = pListRef.current;
      if (pList) {
        pList.scrollLeft = 0;
      }
      // videoRef?.load();
      // 第一次以及 //切换视频
      // setTimeout(() => {
      const params = {
        item: videoItem,
        videoRef: {
          duration: rootRef?.current?.duration,
          currentTime: rootRef?.current?.currentTime,
          ended: rootRef?.current?.ended,
          videoIndex: currentVideoIndex,
        },
        btn_name: rootRef.current?.paused ? "暂停" : "播放",
        currentPlayId,
        fromPage,
      };
      setCurrentPlayId(generateRandomString(10));
      setIsExpand(false);
      triggerShare();
      if (activeTab === "2") {
        trackPlay(params);
        trackVideoExpose({ item: videoItem, currentVideoIndex, fromPage });
      }

      //底部商品口袋曝光埋点
      const arr = videoItem.allList.slice(0, 5);
      if (arr.length > 0) {
        arr.map((item, index) => {
          trackProductCardExpose({
            item,
            index,
            type: item.lType, //商品档期会场
            materialNo: videoItem.materialNo,
            currentVideoIndex,
            fromPage,
          });
        });
      }
    } else {
      const videoRef = rootRef.current;
      setEcommerceVisiable(false);
      if (videoRef) {
        videoRef.pause();
      }
      setShowShareMask(false);
    }
  }, [currentVideoIndex]);
  //商详
  const goDetail = (item: any, index: number, curTab: number, pocketCard: number) => {
    console.log("商详", item, index, curTab);
    setPopVisible(false);
    navigate2Product({
      activityId: item.activityId,
      activitySpuId: item.activitySpuId,
      selectSkuId: item.selectSkuId,
      promotePageType: fromPage === "productDetail" ? 4 : 1,
    });
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "商品点击",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "商品点击",
        fromPage,
      });
    }
  };
  //还想买
  const stillBuy = (item: any, index: number, curTab: number, pocketCard: number) => {
    setPopVisible(false);
    goDetail(item, index, curTab, pocketCard);
    // buyMore({
    //   activityId: item.activityId,
    //   activitySpuId: item.activitySpuId,
    // });
    // Toast.show({
    //   content: "已通知商家您的意愿～",
    //   maskClassName: "mask_class",
    //   duration: 2000
    // });
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "还想买",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "还想买",
        fromPage,
      });
    }
  };
  //跳直播间
  const goLive = (item: any, index: number, curTab: number, pocketCard: number) => {
    console.log("跳直播间", item);
    setPopVisible(false);
    navigate2LiveRoom({
      liveId: item.liveVO.liveNo,
      liveStatus: item.liveVO.liveStatus,
    });
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "去直播间",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "去直播间",
        fromPage,
      });
    }
  };
  //跳档期
  const goActivity = (item: any, index: number, curTab: number, pocketCard: number) => {
    console.log("跳档期", item);
    setPopVisible(false);
    navigate2Activity({
      activityNo: item.activityId,
      promotePageType: fromPage === "productDetail" ? 4 : 1,
    });
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "活动点击",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "活动点击",
        fromPage,
      });
    }
  };
  //跳会场
  const goConference = (item: any, index: number, curTab: number, pocketCard: number) => {
    //curTab 区分商品档期会场  pocketCard 01RecommendSmall  or  PocketCom
    setPopVisible(false);
    if (item.type === 1) {
      navigate2SuperVenue({
        h5Query: {
          hotSaleId: item.conferenceId,
        },
      });
    }
    if (item.type === 2) {
      navigate2Venue({
        h5Query: {
          conferenceId: item.conferenceId,
        },
      });
    }
    if (item.type === 3) {
      navigate({
        h5Options: {
          url: `/mx-shop-micro?customPageCode=${item.conferenceId}`,
        },
      });
    }
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "会场点击",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "会场点击",
        fromPage,
      });
    }
  };
  useActivate(() => {
    onTogglePlay(null, "pause");
    setPopVisible(false);
    setCartModalVisible(false);
    triggerShare();
  });
  useUnactivate(() => {
    setPopVisible(false);
    setCartModalVisible(false);
  });
  const openPop = () => {
    console.log("打开商品口袋");
    if (videoItem.allList.length === 0) {
      return;
    }
    if (!popVisible) {
      trackVideoClick({ btn_name: "商品口袋", item: videoItem, currentVideoIndex, fromPage });
    }
    const videoRef = rootRef.current;
    if (videoRef) {
      !popVisible ? onTogglePlay(null, "pause") : onTogglePlay(null, "play");
    }
    setPopVisible(!popVisible);
  };
  useEffect(() => {
    console.log("popVisible", popVisible, showShareMask);
    setSwiper(!(popVisible || showShareMask || cartModalVisible));
  }, [popVisible, showShareMask, cartModalVisible]);
  //去购物车
  const goCart = () => {
    console.log("去购物车");
    navigate2Cart();
    openPop();
  };
  //加购
  const addCart = (item: any, index: number, curTab: number, pocketCard: number) => {
    console.log("加购", item, index, curTab);
    setPopVisible(false);
    setCartModalVisible(true);
    setModalProduct(item);
    if (pocketCard === 0) {
      trackProductCardClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "购买",
        currentVideoIndex: currentVideoIndex,
        index,
        fromPage,
      });
    } else if (pocketCard === 1) {
      trackPocketClick({
        item,
        curTab,
        materialNo: videoItem.materialNo,
        btn_name: "购买",
        fromPage,
      });
    }
  };
  const renderVideoControls = () => {
    return (
      <>
        {!isPlaying && (
          <img
            className={styles.playBtn}
            onClick={onTogglePlay}
            src={
              "https://akim-oss.aikucun.com/95ed8103334574f04313dc90dde7b8d742706191_1700037506305_16.png"
            }
          ></img>
        )}
      </>
    );
  };
  const Ellipsis = ({ content }) => {
    const expand = () => {
      console.log("isExpand", isExpand);
      setIsExpand(!isExpand);
    };
    return (
      <div className={styles.ellipsisWrapper}>
        <div className={`${isExpand ? styles["normal"] : styles["ellipsis"]}`}>{content}</div>
        <div className={styles.btn} onClick={expand}>
          {isExpand ? "收起" : "展开"}
        </div>
      </div>
    );
  };
  const SliderIcon = () => {
    return <div className={styles["slider-icon"]} />;
  };
  return (
    <>
      <div className={styles["video-player-container"]}>
        <div className={styles["mask1"]} onClick={onTogglePlay}></div>
        <div className={styles["mask2"]} onClick={onTogglePlay}></div>
        {loading && (
          <div className={styles.videoLoading}>
            <img src="https://akim-oss.aikucun.com/14b85fa8e87f846f757eaccda09761641b397f01_1702533385957_40.png" />
          </div>
        )}
        <video
          onWaiting={() => setLoading(true)}
          onPlaying={() => setLoading(false)}
          onPause={() => setLoading(false)}
          id={`video-play-${index}`}
          object-fit="cover"
          onClick={onTogglePlay}
          ref={rootRef}
          className={styles.video}
          loop={true}
          controlsList={"nofullscreen"}
          src={videoItem.url}
          autoPlay={currentVideoIndex === index}
          muted={muted}
          poster={videoItem.cover}
          disablePictureInPicture
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          playsInline={true}
          x5-playsinline="true"
        >
          <source src={videoItem.url} />
          <track kind="captions" />
        </video>
        {renderVideoControls()}
        {showAnimation && (
          <div className={styles["animation-wrapper"]}>
            <Lottie
              options={defaultOptions}
              height={121}
              width={200}
              isStopped={!showAnimation}
              isPaused={false}
            />
          </div>
        )}
        {/* {
              currentVideoIndex !== 0 && isPlaying && <div className={styles["prev-video"]} onClick={playPreviousVideo}>
                上个视频
                <img className={styles["icon"]} src="https://akim-oss.aikucun.com/5d5815647c76e193ee289b94a0c43bf26e8aa23e_1700045513374_97.png" />
              </div>} */}
        {/* 电商元素 */}
        {portalRoot &&
          ecommerceVisiable &&
          createPortal(
            <div
              className={`${
                fromPage === "productDetail" ? styles["fixed-elements2"] : styles["fixed-elements"]
              } ${ecommerceVisiable ? styles["fade-in"] : styles[""]}`}
            >
              {!isSliderMoving && (
                <div className={styles["ecommerce-elements"]}>
                  {/* <div className={styles["next-wrapper"]} >
                    <div className={styles["next-video"]} onClick={playNextVideo}>
                      下个视频
                      <img className={styles["icon"]} src="https://akim-oss.aikucun.com/b0e24833f752e8500906c80edc9b995077774209_1700045624589_79.png" />
                    </div>
                  </div> */}
                  {/* <div className={styles["header-wrapper"]}>
                <img src={videoItem.creatorAvatar} className={styles["header-element"]} />
              </div> */}
                  <div
                    className={styles["like-elements"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      playAnimation();
                    }}
                  >
                    <img
                      className={styles["like-icon"]}
                      src={
                        videoItem.awesome
                          ? "https://akim-oss.aikucun.com/f15f0ed4187e8026f1eae90ab4d23310898c6bb6_1700470465297_59.png"
                          : "https://akim-oss.aikucun.com/f15f0ed4187e8026f1eae90ab4d23310898c6bb6_1700043430085_48.png"
                      }
                    />
                    <div className={styles["like-num"]}>{videoItem.awesomeNum}</div>
                  </div>
                  {/* 分享 */}
                  <div
                    className={styles["share-element"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowShareMask(true);
                    }}
                  >
                    <img
                      className={styles["share-icon"]}
                      src="https://akim-oss.aikucun.com/7a9243411482929fb7db87247c7a92a16741ce4f_1700044053808_40.png"
                    />
                    <div className={styles["share-num"]}>分享</div>
                  </div>

                  {/* 商品卡片 */}
                  {
                    <div
                      className={`${styles["product-card-element"]} ${
                        videoItem.allList.length > 0 ? "" : styles["hidden"]
                      }`}
                      onClick={openPop}
                    >
                      <img
                        className={styles["product-icon"]}
                        src="https://akim-oss.aikucun.com/c96bf7c023f1c98b3f3012003500dcf81725dc3f_1700044082614_11.png"
                      />
                      <div className={styles["product-num"]}>
                        {videoItem.productListVOList?.length +
                          videoItem["scheduleListVOList"]?.length +
                          videoItem.activityListVOList?.length +
                          videoItem.conferenceVOList?.length}
                      </div>
                    </div>
                  }
                </div>
              )}
              {!isSliderMoving && (
                <div className={styles["float-elements"]}>
                  {videoItem.name.length > 40 ? (
                    <Ellipsis content={videoItem.name} />
                  ) : (
                    <div className={styles["desc-elements"]}>{videoItem.name}</div>
                  )}
                  {
                    <div
                      ref={pListRef}
                      id="product-list"
                      className={`${styles["product-list"]} ${
                        videoItem.allList?.length > 0 ? styles["product-shadow"] : ""
                      }`}
                    >
                      {videoItem.allList.slice(0, 5).map((item, index) => {
                        return (
                          <div className={styles["product-item"]} key={index}>
                            {item.lType === "product" && (
                              <RecommendSmallProduct
                                product={item}
                                text="赚"
                                titleDown="H5_MaterialList_ProductNameDown"
                                goLive={(item) => {
                                  goLive(item, index, 0, 0);
                                }}
                                addCart={(item) => {
                                  addCart(item, index, 0, 0);
                                }}
                                goProduct={(item) => {
                                  goDetail(item, index, 0, 0);
                                }}
                                stillBuy={(item) => {
                                  stillBuy(item, index, 0, 0);
                                }}
                              />
                            )}
                            {item.lType === "activity" && (
                              <RecommendSmallActivity
                                brandDown="H5_MaterialList_ScheduleNameDown"
                                activity={item}
                                text="赚"
                                goActivityDetail={(item) => {
                                  goActivity(item, index, 1, 0);
                                }}
                              />
                            )}
                            {item.lType === "schedule" && (
                              <RecommendSmallSchedule activity={item} text="赚" />
                            )}
                            {item.lType === "conference" && (
                              <RecommendSmallConference
                                conference={item}
                                text="赚"
                                goConferenceDetail={(item) => {
                                  goConference(item, index, 1, 0);
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                      {videoItem.allList.length > 5 && (
                        <img
                          className={styles["more-item"]}
                          onClick={openPop}
                          src="https://akim-oss.aikucun.com/307ee94f8b87f15f04c46985cd5280b5c4d634be_1701249038175_22.png"
                        />
                      )}
                    </div>
                  }
                </div>
              )}
              {/* 自定义播放进度条 */}
              {!isPlaying && (
                <>
                  {isSliderMoving && (
                    <div className={styles.timeWrapper}>
                      <div className={styles["time1"]}>{formattedCurrentTime}</div>
                      <div className={styles["time1"]}>/</div>
                      <div className={styles["time2"]}>{formattedDuration}</div>
                    </div>
                  )}
                  <Slider
                    className={styles.mySlider}
                    min={0}
                    value={Math.round((currentTime / duration) * 100)}
                    max={100}
                    icon={<SliderIcon />}
                    onChange={changeVideoTime}
                    onAfterChange={() => {
                      setIsSliderMoving(false);
                    }}
                  />
                </>
              )}
            </div>,
            portalRoot,
          )}
        {/* 分享 */}
        <Mask
          visible={showShareMask}
          getContainer={document.getElementById("root")}
          className={styles["share-mask-container"]}
          onMaskClick={() => setShowShareMask(false)}
        >
          <img
            className={styles["share-guide"]}
            src="https://akim-oss.aikucun.com/342fd5035fac892693a1b04f6a73c3a957a0617d_1700105346385_60.png"
          />
        </Mask>
        {/* 商品口袋 */}
        {popVisible && (
          <Popup
            visible={popVisible}
            onMaskClick={() => {
              setPopVisible(false);
              onTogglePlay(null, "play");
            }}
            bodyStyle={{
              borderTopLeftRadius: pxtorem(12),
              borderTopRightRadius: pxtorem(12),
              height: pxtorem(467),
            }}
            style={{
              "--z-index": "1100",
            }}
            maskStyle={{
              zIndex: "1100",
            }}
          >
            <PocketCom
              cartTotal={cartTotal}
              close={openPop}
              goConference={(item, index, curTab) => {
                goConference(item, index, curTab, 1);
              }}
              goActivity={(item, index, curTab) => {
                goActivity(item, index, curTab, 1);
              }}
              goLive={(item, index, curTab) => {
                goLive(item, index, curTab, 1);
              }}
              stillBuy={(item, index, curTab) => {
                stillBuy(item, index, curTab, 1);
              }}
              goDetail={(item, index, curTab) => {
                goDetail(item, index, curTab, 1);
              }}
              goCart={goCart}
              addCart={(item, index, curTab) => {
                addCart(item, index, curTab, 1);
              }}
              activityList={videoItem["activityListVOList"]}
              scheduleList={videoItem["scheduleListVOList"]}
              productList={videoItem["productListVOList"]}
              conferenceList={videoItem["conferenceVOList"]}
              materialNo={videoItem.materialNo}
              currentVideoIndex={currentVideoIndex}
            />
          </Popup>
        )}
      </div>
      {showNewCartPanel ? (
        <AddPurchasePanel
          visible={cartModalVisible}
          product={modalProduct}
          close={() => {
            setCartModalVisible(false);
          }}
          confirm={changeCartNum}
          trackData={{
            pageName: fromPage === "productDetail" ? "实拍专区-视频" : "种草社区_推荐",
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
          }}
        />
      ) : (
        <AddToCartModal
          visible={cartModalVisible}
          product={modalProduct}
          close={() => {
            setCartModalVisible(false);
          }}
          confirm={changeCartNum}
          pageType={fromPage === "productDetail" ? 4 : 1}
        />
      )}
    </>
  );
};
export default VideoPlayer;
