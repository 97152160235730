import { formatDate, formatDateYYYYMM } from "@/utils/date-format";
import { DatePickerView, Popup, Toast } from "antd-mobile";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./index.module.less";

interface Props {
  popVisible: boolean;
  close: () => void;
  getTimeRange: (startDate?: string, endDate?: string) => void;
}
const DatePickerV2: React.FC<Props> = (props: Props) => {
  const { popVisible, close, getTimeRange } = props;
  const tabList = [
    { name: "按月份", value: 1 },
    { name: "自定义", value: 2 },
  ];
  const [curTabIndex, setCurTabIndex] = useState<number>(0);
  const [currentTimeIndex, setCurrentTimeIndex] = useState<number>(0);
  const [currentMonth, setCurrentMonth] = useState<string>(formatDateYYYYMM(new Date()));
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [minDate, setMinDate] = useState(new Date(2017, 8, 1));
  const [maxDate, setMaxDate] = useState(new Date());
  const labelRenderer = useCallback((type: string, data) => {
    switch (type) {
      case "year":
        return data + "年";
      case "month":
        return data + "月";
      case "day":
        return data + "日";
      case "hour":
        return data + "时";
      case "minute":
        return data + "分";
      case "second":
        return data + "秒";
      default:
        return data;
    }
  }, []);
  const onClose = () => {
    close && close();
  };
  const timeChange = (date) => {
    console.log("timeChange", formatDate(date));
    if (curTabIndex === 0) {
      setCurrentMonth(formatDateYYYYMM(date));
    } else {
      if (currentTimeIndex === 0) {
        setStartDate(formatDate(date));
        setMinDate(date);
      } else if (currentTimeIndex === 1) {
        setEndDate(formatDate(date));
        setMaxDate(date);
      }
    }
  };
  const comfirm = () => {
    if (curTabIndex === 1 && (!startDate || !endDate)) {
      Toast.show({ content: "请选择开始时间或结束时间", maskStyle: { zIndex: 100001 } });
      return;
    }

    if (curTabIndex === 0) {
      console.log("comfirm", "startDate=", currentMonth);
      getTimeRange(currentMonth);
    } else {
      console.log("comfirm", "startDate=", startDate, "endDate=", endDate);
      getTimeRange(startDate, endDate);
    }
    onClose();
  };
  const getDefaultValue = () => {
    if (curTabIndex === 0) {
      return currentMonth && currentMonth.length > 0 ? new Date(currentMonth) : new Date();
    }
    if (currentTimeIndex === 0) {
      return startDate && startDate?.length > 0 ? new Date(startDate) : new Date();
    }
    return endDate && endDate?.length > 0 ? new Date(endDate) : new Date();
  };
  return (
    <Popup
      visible={popVisible}
      onMaskClick={onClose}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 10000,
      }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.cancel} onClick={onClose}>
            取消
          </div>
          <div className={styles.comfirm} onClick={comfirm}>
            确认
          </div>
        </div>

        <div className={styles.tab}>
          <div className={styles.mytabs}>
            {tabList.map((item, index) => {
              return (
                <div
                  className={` ${styles["tab-item"]} 
                  ${curTabIndex === index ? styles["active"] : styles[""]}
              `}
                  key={index}
                  onClick={() => {
                    setCurTabIndex(index);
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>

        {curTabIndex === 1 && (
          <div className={styles.time_title}>
            <div
              className={` ${styles["filter_time"]} 
            ${currentTimeIndex === 0 ? styles["active"] : styles[""]}
            ${startDate ? styles[""] : styles["empty"]}
              `}
              onClick={() => {
                setCurrentTimeIndex(0);
              }}
            >
              {startDate || "开始时间"}
            </div>
            <div>至</div>
            <div
              className={` ${styles["filter_time"]} 
            ${currentTimeIndex === 1 ? styles["active"] : styles[""]}
            ${endDate ? styles[""] : styles["empty"]}
              `}
              onClick={() => {
                setCurrentTimeIndex(1);
              }}
            >
              {endDate || "结束时间"}
            </div>
          </div>
        )}
        <div className={styles.date_picker}>
          <DatePickerView
            defaultValue={getDefaultValue()}
            renderLabel={labelRenderer}
            precision={curTabIndex === 0 ? "month" : "day"}
            max={currentTimeIndex === 1 || curTabIndex === 0 ? new Date() : maxDate}
            min={currentTimeIndex === 0 || curTabIndex === 0 ? new Date(2017, 8, 1) : minDate}
            onChange={(val) => {
              timeChange(val);
            }}
          />
        </div>
      </div>
    </Popup>
  );
};

export default DatePickerV2;
