import { SkinInfo } from "@/api/decorate";
import { GZHInfo } from "@/api/wechat";
import { createContext } from "react-activation";
import EventManager from "./event/eventManager";
import { getUserRole } from "./identity";

interface TrackProps {
  init?(config: any, superProperties: any): void;
  registerSuperProperty?(superProperties: any): void;
  alias?(user: any): void;
  pageName?(): string | undefined;
  track?(eventName: any, props?: any): void;
  pageView?(pageName: string, props?: any): void;
}

export type Role = "R" | "E" | "C" | "A";
export interface ContextProps {
  track: TrackProps;
  eventManager: EventManager;
  skin: SkinInfo;
  currentRole: Role;
  gzhInfo?: GZHInfo;
  cartNum?: number;
  setCartNum: (num?: number) => Promise<number>;
  setGzhInfo: (info: GZHInfo) => void;
  setSkin: (info: any) => void;
}
export const CommonContext = createContext<ContextProps>({
  track: {},
  eventManager: new EventManager(),
  skin: {},
  currentRole: getUserRole(),
  gzhInfo: {},
  cartNum: 0,
  setCartNum: () => Promise.resolve(0),
  setGzhInfo: () => undefined,
  setSkin: () => undefined,
});
