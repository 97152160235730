import { EventName } from "./events";

type EventCallback<T> = (data: T) => void;

interface EventManagerMap {
  [eventName: string]: Array<EventCallback<any>>;
}

class EventManager {
  private events: EventManagerMap = {};

  public subscribe<T>(eventName: EventName, callback: EventCallback<T>): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    if (this.events[eventName].includes(callback)) {
      return;
    }
    this.events[eventName].push(callback);
  }

  public publish<T>(eventName: EventName, data: T): void {
    const eventCallbacks = this.events[eventName];
    if (eventCallbacks) {
      eventCallbacks.forEach((callback) => {
        callback(data);
      });
    }
  }

  public unsubscribe<T>(eventName: EventName, callback: EventCallback<T>): void {
    const eventCallbacks = this.events[eventName];
    if (eventCallbacks) {
      this.events[eventName] = eventCallbacks.filter((cb) => cb !== callback);
    }
  }
}
export default EventManager;
