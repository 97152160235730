import AKJS from "@akc/akjs";
import { platform } from "./platform";

export const onClosePage = (options?: {
  appOptions?: {
    markId: string;
  };
}) => {
  const { isApp } = platform;
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.page.close", options?.appOptions);
    });
  } else {
    history.back();
  }
};

export const setMarkPageId = (options?: {
  appOptions?: {
    markId: string;
  };
}) => {
  const { isApp } = platform;
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.page.setMarkId", options?.appOptions);
    });
  }
};
