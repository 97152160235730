import { useCommonContext } from "@/utils/context/common";
import { default as React } from "react";
import styles from "./index.module.less";

type Props = {
  txt?: string;
  onCopyTxt?: (txt?: string) => void;
};
const ItemTextComp: React.FC<Props> = ({ txt, onCopyTxt }) => {
  const commonContext = useCommonContext();
  const currentRoleIsC = commonContext.currentRole === "C";
  return (
    <div className={styles.resItemTxt}>
      <span>{txt}</span>
      {!currentRoleIsC && (
        <div
          className={styles.copy}
          onClick={(e) => {
            e.stopPropagation();
            onCopyTxt?.(txt);
          }}
        >
          复制
        </div>
      )}
    </div>
  );
};

export default ItemTextComp;
