import { queryJumpTypeServ } from "@/api/activity";
import Exposable from "@/components/exposable";
import { fetchCounpon } from "@/utils/actions";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./CouponCard.module.less";

type Props = {
  data: any;
  isVisitor?: boolean;
  styleType?: string;
  setIsShowCouponPop?: React.Dispatch<React.SetStateAction<boolean>>;
  updateCouponList: () => void;
};

const CouponBtn = ({ data, collectCoupon, setIsShowCouponPop }) => {
  const [localData, setLocalData] = useState(data);
  const {
    navigate2Product,
    navigate2Activity,
    navigate2OfferOrderList,
    navigate2GoodsOfNpieceXfold,
  } = useNavigation();

  let btnCfg = {
    name: "立即抢",
    className: styles.btn + " " + styles.primary,
  };
  if ([4, 5].includes(localData.buttonStatus)) {
    btnCfg.name = "去使用";
    btnCfg.className = styles.btn;
  }

  const couponJump = async (voucherNo) => {
    // 去使用
    const { success, data } = await queryJumpTypeServ({ voucherNo });
    if (success && data) {
      const {
        jumpType,
        couponId: _couponId,
        voucherNo: _voucherNo,
        activityId,
        awdInstanceId,
      } = data;
      switch (jumpType) {
        case "productDetail":
          navigate2Product({
            activitySpuId: data.activitySpuId,
            activityId: data.activityId,
            selectSkuId: data.selectSkuId,
          });
          break;
        case "productList":
          navigate2GoodsOfNpieceXfold({
            miniQuery: {
              couponId: _couponId,
              voucherNo: _voucherNo,
              awdInstanceId,
            },
            h5Query: {
              couponId: _couponId,
              voucherNo: _voucherNo,
              awdInstanceId,
            },
          });
          break;
        case "activityDetail":
          navigate2Activity({
            activityNo: activityId,
          });
          break;
        case "activityList":
          navigate2OfferOrderList({
            miniQuery: { voucherNo },
            h5Query: { voucherNo },
          });
          break;
        default:
          break;
      }
    }
  };

  const clickCoupon = async () => {
    if (localData.buttonStatus === 1) {
      // 领券
      await collectCoupon(localData);
      data.buttonStatus = 4;
      setLocalData({ ...data });
    }
    // else if ([4, 5].includes(localData.buttonStatus)) {
    //   couponJump(localData.voucherNo);
    // }
    couponJump(localData.voucherNo);
    if (setIsShowCouponPop) {
      setIsShowCouponPop(false);
    }
  };

  return (
    <Exposable
      className={btnCfg.className}
      onClick={clickCoupon}
      clickEventName="resource_click"
      trackData={{
        resource_type: "品牌新客券",
        resource_content: "优惠券",
        resource_rank: 0,
        relative_rank: data.relativeRank,
        brand_id: data.brandId,
        activity_id: data.relatedProduct?.activityId,
        activity_spu_code: data.relatedProduct?.activitySpuId,
        coupons_id: data.awdId,
        btn_name: btnCfg.name,
      }}
      trackContextKeys={["page_name", "previous_page_name", "domain"]}
    >
      {btnCfg.name}
    </Exposable>
  );
};

const ExpireTime = ({ data, styleType = "small" }) => {
  const { endTime } = data;
  const time = (+endTime - Date.now()) / 1000;
  let day = parseInt(String(time / (60 * 60 * 24)), 10);
  let hou: number | string = parseInt(String((time % (60 * 60 * 24)) / 3600), 10);
  let min: number | string = parseInt(String(((time % (60 * 60 * 24)) % 3600) / 60), 10);

  day < 0 && (day = 0);
  hou < 0 && (hou = 0);
  min < 0 && (min = 0);

  hou = hou < 10 ? "0" + hou : hou;
  min = min < 10 ? "0" + min : min;

  const endDate = new Date(+endTime);
  const year = endDate.getFullYear();
  const month = endDate.getMonth() + 1;
  const date = endDate.getDate();
  if (styleType === "long") {
    if (day) {
      return <div className={styles.extra}>{data.timeDesc}</div>;
    } else {
      return (
        <div className={styles.extra}>
          <span className={styles.hl}>
            {hou}:{min}
          </span>
          后到期
        </div>
      );
    }
  } else {
    if (day > 3) {
      return (
        <div className={styles.desc}>
          {month}月{date}日到期
        </div>
      );
    } else if (day) {
      return <div className={styles.desc}>{day}天后到期</div>;
    } else {
      return (
        <div className={styles.desc}>
          <span className={styles.count}>
            {hou}:{min}
          </span>
          后到期
        </div>
      );
    }
  }
};

const SmllCard = ({ hasBtn = true, data, children }) => {
  return (
    <div
      className={
        styles.wrap + " " + (false && styles.disable) + " " + (!hasBtn && styles.smallCard)
      }
    >
      {data.currentNum < 100 && <div className={styles.cornerLabel}>仅剩{data.currentNum}张</div>}
      <div className={styles.title}>{data.brandName}</div>
      <div className={styles.cont}>
        <span className={styles.price}>{data.quotaAmount || data.amount}</span>元
      </div>
      {children}
      <ExpireTime data={data} />
    </div>
  );
};

const LongCard = ({ data, children }) => {
  return (
    <div className={styles.LongWrap + " " + (false && styles.disable)}>
      {data.currentNum < 100 && (
        <div className={styles.cornerLabel + " " + styles.translateY0}>仅剩{data.currentNum}张</div>
      )}
      <div className={styles.main}>
        <dl className={styles.longLeft}>
          <dt className={styles.price}>{data.quotaAmount || data.amount}</dt>
          <dd>满{data.baseAmount}元可使用</dd>
        </dl>
        <div className={styles.longRight}>
          <dl>
            <dt className={styles.title}>{data.brandName}</dt>
            <dd className={styles.desc}>{data.couponLimitDescList.join()}</dd>
          </dl>
          <ExpireTime data={data} styleType="long" />
        </div>
      </div>
      {children}
    </div>
  );
};

const CouponCard: React.FC<Props> = ({
  data,
  isVisitor = false,
  styleType = "small",
  setIsShowCouponPop,
  updateCouponList,
}) => {
  const collectCoupon = async (coupon) => {
    await fetchCounpon(coupon);
    updateCouponList();
  };
  return (
    <>
      {styleType === "small" ? (
        <SmllCard hasBtn={!isVisitor} data={data}>
          {!isVisitor && (
            <CouponBtn
              data={data}
              collectCoupon={collectCoupon}
              setIsShowCouponPop={setIsShowCouponPop}
            />
          )}
        </SmllCard>
      ) : (
        <LongCard data={data}>
          {!isVisitor && (
            <CouponBtn
              data={data}
              collectCoupon={collectCoupon}
              setIsShowCouponPop={setIsShowCouponPop}
            />
          )}
        </LongCard>
      )}
    </>
  );
};

export default CouponCard;
