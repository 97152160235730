import React from "react";
import styles from "./index.module.less";
import { StudyTourSignUpDayListInfo, YouXueSignUp } from "../../../model";
import { Image } from "antd-mobile";
import { pxtorem } from "@/utils/pxtorem";
import loadingPng from "@/base64";
import StudyActivityCardComp from "../card";
import useStudyTourList from "../../hook/useStudyTourList";
import ActivityDetailComp from "../../dialog/detail";
interface Props {
  signUpDetail?: YouXueSignUp;
  title?: string;
  tabKey?: string;
  onStudyTourListAction?: (
    tabKey?: string,
    list?: StudyTourSignUpDayListInfo[],
    brandLogos?: string[],
    total?: number,
  ) => void;
  onSelectPress?: (signUpDetail?: YouXueSignUp) => void;
}

const StudyTourListComp: React.FC<Props> = ({ title, tabKey, onStudyTourListAction }) => {
  const {
    list,
    loading,
    detailVisible,
    selectStudyTourDetail,
    unSelect,
    onActivityDetailAction,
    onSelectActivityAction,
    onDetailDialogVisible,
    onSelectSureAction,
  } = useStudyTourList({
    title: title,
    tabKey: tabKey,
    onStudyTourListAction: onStudyTourListAction,
  });

  return (
    <div className={styles.list}>
      <div className={styles.page__body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              {list.map((item) => {
                return (
                  <>
                    <div className={styles.list__header}>
                      <img
                        className={styles.header__image}
                        src="https://akim-oss.aikucun.com/mshop/1077b85f577ee7c1dd642e90ed6ed7550c54602c_1716973029635_87.png"
                      />
                      <div className={styles.header__title}>{item?.day}</div>
                    </div>
                    {item?.studyTourSignUpList?.map((t, index) => {
                      return (
                        <>
                          <StudyActivityCardComp
                            signUpDetail={t}
                            key={index}
                            supportMulSelect={true}
                            supportDelete={false}
                            showCover={unSelect(t)}
                            onDetailPress={onActivityDetailAction}
                            onSelectPress={onSelectActivityAction}
                          />
                        </>
                      );
                    })}
                  </>
                );
              })}
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.emptyIcon}
                  src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                />
                <div>暂无相关任务</div>
              </div>
            )
          )}
        </>
        {!loading && (list?.length || 0) > 0 && (
          <div className={styles.footer}>
            <div className={styles.footer__text}>🎁更多活动即将来袭，敬请保持关注~</div>
          </div>
        )}
        {detailVisible && (
          <ActivityDetailComp
            signUpDetail={selectStudyTourDetail.current}
            visible={detailVisible}
            showBottom={true}
            setVisible={onDetailDialogVisible}
            onSurePress={onSelectSureAction}
          />
        )}
      </div>
    </div>
  );
};

export default StudyTourListComp;
