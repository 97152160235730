import { queryRecommendList } from "@/api/product";
import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import { useEffect, useRef, useState } from "react";

const useRecommendProductList = ({
  product,
  scene,
}: {
  product?: ProductDetailModel;
  scene?: string;
}) => {
  // 当前页（必须在1~500之间！）
  const pageNum = useRef<number>(1);
  // 是否有下一页
  const [hasNext, setHasNext] = useState(false);
  // 商品列表
  const [recommendProductList, setRecommendProductList] = useState<ProductModel[]>([]);

  const fetchProductList = async () => {
    if (!product) {
      return;
    }
    const res = await queryRecommendList({
      pageId: "H5_Search",
      activitySpuIds: [product.activitySpuId ?? ""],
      pageIndex: pageNum.current,
      pageSize: 10,
      scene: scene,
      categoryCode: product.spuCategoryInfoVO?.categoryCode,
      voConfig: {
        showType: 2,
      },
    });
    const result = res?.result ?? [];
    if (pageNum.current === 1) {
      setRecommendProductList(result ?? []);
    } else {
      setRecommendProductList((list) => [
        ...list,
        ...result.map((item) => ({
          ...item,
        })),
      ]);
    }

    setHasNext(res?.hasNext ?? false);
    pageNum.current = (res?.pageIndex ?? 1) + 1;
  };

  // 加载更多
  const onLoadNextPage = async () => {
    await fetchProductList();
  };

  useEffect(() => {
    if (!product) {
      return;
    }
    setRecommendProductList([]);
    pageNum.current = 1;
    fetchProductList();
  }, [product]);

  return {
    hasNext,
    recommendProductList,
    onLoadNextPage,
  };
};

export default useRecommendProductList;
