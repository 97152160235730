import Track from "@/utils/track";
import React, { useEffect, useRef, useState } from "react";
import { useSetBrandIds, useSetFilterInfo } from "../hooks/useActivityProduct";
import styles from "./BrandTabs.module.less";

interface Props {
  list: Array<any>;
  activeTabIndex: number;
  isSticky: boolean;
  brandData: any;
  onChangeActiveTabIndex: (index) => void;
  onChangeShowPanel: (bool) => void;
}

const BrandTabs: React.FC<Props> = ({
  list,
  activeTabIndex,
  isSticky,
  brandData,
  onChangeActiveTabIndex,
  onChangeShowPanel,
}) => {
  const track = Track();

  const setBrandIds = useSetBrandIds();
  const setFilterInfo = useSetFilterInfo();

  const [show, setShow] = useState(false);

  const [categoriesModalTranslateY, setCategoriesModalTranslateY] = useState(0);
  const categoriesModalHeight = useRef<any>(null);
  const [selectedCardWidth, setSelectedCardWidth] = useState("");
  const [selectedCateMargin, setSelectedCateMargin] = useState("0.32rem");

  const showTabs = () => {
    if (show) {
      setCategoriesModalTranslateY(-categoriesModalHeight.current);
      setCategoriesModalTranslateY(0);
      setShow(false);
    } else {
      setShow(true);
      setCategoriesModalTranslateY(0);
    }
  };

  const changeActiveIndex = (index, isInit = false) => {
    //   track.track("tab_click", {
    //     page_name: "店铺聚合",
    //     previous_page_name: search.get("page_name"),
    //     domain: "B2R旗舰店",
    //     resource_content: "B2R旗舰店",
    //     module: "关注好店",
    //     tab_name: categories[index]?.brandName,
    //   });
    if (!isInit && index === activeTabIndex) {
      return;
    }
    onChangeActiveTabIndex(index);
    setFilterInfo(undefined);
    // setBrandIds(index === 0 ? [] : [list[index].brandId]);

    // setCardList([]);
    // setPageNum(1);
    // setHasMore(false);
    // queryTabList(1, index, []);
    if (show) {
      showTabs();
    }

    const categoryDom = document.getElementById(`tab-${index}`) as HTMLElement;
    setTimeout(() => {
      categoryDom.scrollIntoView({
        behavior: "smooth",
        inline: "center",
        block: "nearest",
      });
    }, 100);
    setSelectedCardWidth(`${categoryDom.offsetWidth * 0.8}px`);
    setSelectedCateMargin(`${categoryDom.offsetLeft + categoryDom.offsetWidth * 0.1}px`);
  };

  useEffect(() => {
    onChangeShowPanel(show);
  }, [show]);

  useEffect(() => {
    setBrandIds(activeTabIndex === 0 ? [] : [list[activeTabIndex].brandId]);
  }, [brandData]);

  useEffect(() => {
    setTimeout(() => {
      const tmp = document.getElementById("categories-in-modal")?.clientHeight || 0;
      categoriesModalHeight.current = tmp;
      changeActiveIndex(activeTabIndex, true);
    }, 0);
  }, []);

  return (
    <div className={`${styles.tabs} ${isSticky && styles.tabs__sticky}`}>
      <div id="tadDiv" className={styles.tadDiv}>
        <div className={styles.tabLine}>
          <div className={styles.tabTile}>
            {list.map((item, index) => {
              return (
                <div
                  className={`${styles.tab} ${index === activeTabIndex ? styles.curTab : ""}`}
                  style={
                    {
                      // width: `${item.brandName?.length * 0.32}rem`,
                    }
                  }
                  id={`tab-${index}`}
                  key={index}
                  onClick={() => changeActiveIndex(index)}
                >
                  <div className={styles.text}>{item.brandName}</div>
                </div>
              );
            })}
          </div>

          <div
            className={styles.selectedLineContainer}
            style={{
              width: selectedCardWidth,
              marginLeft: selectedCateMargin,
            }}
          >
            <div className={styles.selectedLine} />
          </div>
        </div>
      </div>

      {show ? (
        <img
          onClick={showTabs}
          className={styles.btn}
          id="modal_content"
          src={
            isSticky
              ? "https://akim-oss.aikucun.com/mshop/b45075628be69b91784fcc046bdb9b7b812f6c3a_1716814589795_43.png"
              : "https://akim-oss.aikucun.com/mshop/11ea7e7e974c2d2301454ff97888403eb82453cb_1716800519368_76.png"
          }
        />
      ) : (
        <img
          onClick={showTabs}
          className={styles.btn}
          id="modal_content"
          src={
            isSticky
              ? "https://akim-oss.aikucun.com/mshop/991d1392e4b142deb6f19d3439fdde971325a94d_1716814617185_40.png"
              : "https://akim-oss.aikucun.com/mshop/991d1392e4b142deb6f19d3439fdde971325a94d_1715306110853_54.png"
          }
        />
      )}

      {list.length > 0 && (
        <div className={styles.tabModal} style={{ visibility: `${show ? "visible" : "hidden"}` }}>
          <div
            className={styles.content}
            id="categories-in-modal"
            style={{
              transform: `translateY(${categoriesModalTranslateY}px)`,
              transition: "transform 0.3s",
            }}
          >
            {list.map((item, index) => {
              return (
                <div
                  key={index}
                  id="modal_content"
                  className={
                    styles.category +
                    " " +
                    (index === activeTabIndex ? styles.category_selected : "")
                  }
                  onClick={() => changeActiveIndex(index)}
                >
                  <div id="modal_content" className={styles.text}>
                    {item.brandName}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandTabs;
