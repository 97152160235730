import { fetchCurrentSingInfo, getYouxueSignUpDetail, youxueSignUp } from "@/api/youxue";
import { setTitle } from "@/utils/set-title";
import { MemberSignUpInfo, YouXueSignUpDetail } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const TAG = "useSignup";

export type SignUpSuccessCallback = () => void;

const useSignUp = (successCallback?: SignUpSuccessCallback) => {
  const [searchParams] = useSearchParams();
  const youxueIds = searchParams.get("youxueIds");
  const activityImgs = searchParams.get("activityImages");
  console.log(TAG, "router---", searchParams.toString());
  const basicInfoRef = useRef<any>(null);
  const [showCheckStatus, setShowCheckStatus] = useState(false);
  const [activityImages, setActivityImages] = useState<string[] | undefined>([]);
  const [signUpInfo, setSignUpInfo] = useState<MemberSignUpInfo>();

  // 获取签到信息记录
  const fetchDetailInfo = async () => {
    try {
      const res = await fetchCurrentSingInfo();
      if (res) {
        setSignUpInfo(res);
      }
    } catch (error) {
      console.error("fetchDetailInfo catch error:", error);
    }
  };

  useEffect(() => {
    setTitle({ appOptions: { title: "游学报名表" }, h5Options: { title: "游学报名表" } });
    const imgList = activityImgs?.split(",");
    setActivityImages(imgList);
    fetchDetailInfo();
  }, []);

  // 游学报名
  const requestYouxueSignUp = useCallback(async () => {
    const studyTourIds = youxueIds?.split(",");
    console.log(TAG, "requestYouxueSignUp studyTourIds:", studyTourIds);
    if (!studyTourIds || studyTourIds?.length === 0) {
      Toast.show("请求异常，请退出重试~");
      console.log(TAG, "业务异常 studyTourIds 值有问题");
      return;
    }
    try {
      const res = await youxueSignUp({
        ...basicInfoRef.current?.getSignUpContent(),
        studyTourIds: studyTourIds,
        signUpType: 1,
      });
      if (res && res.signUpSuccess) {
        successCallback?.();
      } else {
        Toast.show({
          content: res?.signUpFailReason || "请求失败",
          maskClickable: false,
          position: "center",
          maskStyle: { zIndex: 9999 },
        });
      }
    } catch (error) {
      console.log(TAG, "youxueSignUp error...", error);
    }
  }, []);

  // 点击提交
  const onSubmitSignUpInfo = () => {
    setShowCheckStatus(true);
    const result = basicInfoRef.current.checkContentIsValid();
    if (!result) {
      Toast.show("您有部分信息尚未补充完整，请继续填写~");
    } else {
      console.log(TAG, "onSubmitSignUpInfo 内容校验通过");
      requestYouxueSignUp();
    }
  };

  return { activityImages, basicInfoRef, showCheckStatus, onSubmitSignUpInfo, signUpInfo };
};

export default useSignUp;
