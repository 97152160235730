import { getBaseurl } from "@/utils/url-parse";
import {
  ActivityListReqestParams,
  ActivityModel,
  API,
  PageableResponse,
  ResourceBannerModel,
  ResourceJumpType,
} from "@akc/biz-api";
import { Request, Response } from "./request";
import { SearchParam } from "./type";

// 前台类目
export type PrefixCategory = {
  code?: string;
  name?: string;
  thirdCategory?: Array<string>;
};

// 尺码信息
export type SizeInfo = {
  sizeCode?: string;
  sizeName?: string;
  productNum?: number;
};

export type CategoryAndSizeDataType = {
  prefixCategoryList?: Array<PrefixCategory>;
  sizeAndProductNumList?: Array<SizeInfo>;
};

export async function getCategoryAndSize(
  activityIdList: string[],
  prefixFirstCode?: string,
): Promise<CategoryAndSizeDataType> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/size/list`,
    method: "post",
    data: {
      activityIdList,
      prefixFirstCode: prefixFirstCode !== "ALL" ? prefixFirstCode : undefined,
    },
  });
  return res?.data;
}

export enum SortModel {
  General = 1, // 综合
  Sales = 3, // 销量
  Share = 4, // 转发
  New = 6, // 上新
  Price = 7, // 价格
  Profit = 8, // 赚
  Discount = 9, // 折扣
}

export enum SortType {
  Default = 0, // 默认
  Asc = 1, // 升序
  Desc = 2, // 降序
}

export type ServiceTag = {
  tagCode?: string;
  tagName?: string;
  order?: number;
  selected?: boolean;
  hidden?: boolean;
};

export type FilterPrefixCategory = {
  categoryId?: string;
  categoryName?: string;
  level?: number;
  selected?: boolean;
  hidden?: boolean;
  childrenList?: Array<FilterPrefixCategory>;
};

export type SalePropertieTag = {
  tagCode?: string;
  tagName?: string;
  order?: number;
  selected?: boolean;
  hidden?: boolean;
};

export type SaleProperty = {
  groupName?: string;
  groupCode?: string;
  tags?: Array<SalePropertieTag>;
};

export type Brand = {
  brandId?: string;
  brandLogo?: string;
  brandName?: string;
  brandNamePinYin?: string;
  selected?: boolean;
  hidden?: boolean;
};

export type PromotionType = {
  promotionType?: string;
  promotionTypeName?: string;
  type?: string;
  selected?: boolean;
  hidden?: boolean;
};

export type ActivityStatus = {
  itemCode?: string;
  itemName?: string;
  selected?: boolean;
  hidden?: boolean;
};

export type FilterBoardType = {
  prefixCategoryList?: Array<FilterPrefixCategory>;
  prefixCategoryList4BoardIndex?: Array<FilterPrefixCategory>;
  serviceTagList?: Array<ServiceTag>;
  activityStatusList?: Array<ActivityStatus>;
  saleProperties?: Array<SaleProperty>;
  guideProperties?: Array<{
    groupName?: string;
    groupCode?: string;
    tags?: Array<{
      tagCode?: string;
      tagName?: string;
      order?: number;
    }>;
  }>;
  brandList?: Array<Brand>;
  promotionTypeList?: Array<PromotionType>;
};

export async function queryFilterBoardData(params: SearchParam): Promise<FilterBoardType> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/queryFilterBoardData`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function queryRecommendActivityList(
  pageId: string,
  pageIndex: number,
  pageSize: number,
  scene: string,
): Promise<ActivityModel[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/recommend`,
    method: "post",
    data: {
      pageId,
      pageIndex,
      pageSize,
      scene,
    },
  });
  return res?.data?.result;
}

export async function queryActivityCouponList(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/queryList`,
    method: "post",
    data: params,
  });
  return res?.data;
}

export async function batchReceiveCouponServ(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/batchReceiveCoupon`,
    method: "post",
    data: params,
  });
}

export async function queryJumpTypeServ(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/queryJumpType`,
    method: "post",
    data: params,
  });
}

export async function queryBrandHideInfo(brandId?: string): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/member/black/list/new/getHideInfo`,
    method: "post",
    params: { brandId },
  });
}

export async function setBrandHide(brandId?: string) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/black/list/new/hide`,
    method: "post",
    params: { brandId },
  });
  return res?.data;
}

export async function cancleBrandHide(brandId?: string) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/black/list/new/undoHide`,
    method: "post",
    params: { brandId },
  });
  return res?.data;
}

export async function queryActivityBanner(): Promise<ResourceBannerModel[]> {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/subscribe/v2/queryActivityBannerV2/${localStorage.getItem(
      "shopId",
    )}?location=ACTIVITY_DETAIL&newFlag=1${
      localStorage.getItem("distributorId")
        ? "&distributorId=" + localStorage.getItem("distributorId")
        : ""
    }&distributorId=WCIfvTDgxMK`,
    method: "post",
  });
  // TODO 找高扬确认数据结构
  const data = res?.data;
  return data?.map((item) => {
    const hrefType = item.hrefType;
    let jumpType: ResourceJumpType | undefined;
    switch (hrefType) {
      case 0: // URL
      case 1: // 图片
      case 10: // 热卖榜单
      case 11: // 转发领红包
      case 13: // 店铺详情
        jumpType = "URL";
        break;
      case 2: // 活动
        jumpType = "ACTIVITY";
        break;
      case 5: // 直播
        jumpType = "LIVE";
        break;
      case 6: // 商详
        jumpType = "PRODUCT";
        break;
      case 8: // 普通会场
        jumpType = "VENUE";
        break;
      case 9: // 超品会场
        jumpType = "HOT_SALE";
        break;
      case 14: // 商家店铺
        jumpType = "CUSTOM_PAGE_MERCHANT_SHOP";
        break;
      default:
        break;
    }
    return {
      resourceId: item.id,
      imgUrl: item.imgUrl,
      jumpTarget: item.hrefUrl,
      jumpType,
    };
  });
}

export type MultiActivityBrandPageInfo = {
  brandId: string;
  brandLogoUrl: string;
  activityCount: number;
  activityList: any[];
  totalOnlineProductCount: number;
};

export async function queryMultiActivityBrandPageInfoServ(
  params?: any,
): Promise<MultiActivityBrandPageInfo> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/multiActivityBrandPageInfo`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 * 查询活动列表
 */
export const queryActivityList = async (params: ActivityListReqestParams) => {
  return API.post<PageableResponse<ActivityModel>>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/activity/list",
    body: params,
  });
};
