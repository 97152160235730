import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useRef, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Autoplay, EffectCoverflow } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./CarouselSwiper.module.less";

const CarouselSwiper: React.FC<{ list: any[]; promoActivityId?: string; taskId?: string }> = ({
  list,
  promoActivityId,
  taskId,
}) => {
  // const awardTypeEnum = {
  //   COUPON: "优惠券",
  //   POINTS: "积分",
  //   RED_PACKET: "红包",
  //   PRODUCT: "商品",
  // };
  const track = Track();
  const swiperRef = useRef<any>(null);
  const [isShowSwiper, setIsShowSwiper] = useState(false);

  const { preasableAction, navigate2Product, navigate2Activity, navigate } = useNavigation();

  const handleJumpPage = (obj, index) => {
    const { jumpType, jumpTarget } = obj;
    if (!(jumpType && jumpTarget)) {
      return;
    }
    track.track("resource_click", {
      page_name: "日日奖",
      resource_type: "轮播图",
      resource_rank: index,
      page_code: promoActivityId || "",
      task_id: taskId || "",
      resource_url: jumpType === "LINK" ? jumpTarget : "", // 商家发起的活动取对应的店铺S码；平台发起的不展示
      merchant_shop_code: jumpType === "SHOP" ? jumpTarget : "", // 商家发起的活动取对应的店铺S码；平台发起的不展示
      market_id: jumpType === "CONFERENCE_3" ? jumpTarget : "", // 跳转的会场id
      activity_id: jumpType === "ACTIVITY" ? jumpTarget : "", // 跳转的档期活动id
      activity_spu_code: jumpType === "PRODUCT" ? jumpTarget : "", // 跳转的spu商品id
      btn_name: "轮播图点击",
    });
    switch (jumpType) {
      case "LINK": // 落地页
      case "SHOP": // 商家店铺(S码)
        preasableAction({
          targetType: jumpType,
          targetId: jumpTarget,
        });
        break;
      case "CONFERENCE_3": // 会场3.0
        navigate({
          h5Options: {
            url: `/mx-shop-micro?customPageCode=${jumpTarget}`,
          },
          appOptions: {
            url: `${origin}/mx-shop-micro?customPageCode=${jumpTarget}`,
          },
          miniOptions: {
            url: `${origin}/mx-shop-micro`,
            query: {
              customPageCode: jumpTarget,
            },
          },
        });
        break;
      case "PRODUCT": // 商品详情
        navigate2Product({
          activitySpuId: jumpTarget,
        });
        break;
      case "ACTIVITY": // 活动详情
        navigate2Activity({
          activityNo: jumpTarget,
        });
        break;
    }
  };

  useEffect(() => {
    console.log("useEffect——", swiperRef?.current?.swiper);
    setIsShowSwiper(true);
  }, [list]);
  useActivate(() => {
    console.log("useActivate——", swiperRef?.current?.swiper);
    // swiperRef?.current?.swiper?.enable();
    // swiperRef?.current?.swiper?.slideNext();
    setIsShowSwiper(true);
  });
  useUnactivate(() => {
    console.log("useUnactivate——", swiperRef?.current?.swiper);
    // swiperRef?.current?.swiper?.disable();
    setIsShowSwiper(false);
  });

  return (
    <div className={styles.carousel_swiper_wrap}>
      {/* <div className={styles.entry}>
        <span className={styles.entry__text}>全部奖品</span>
        <img
          className={styles.entry__img}
          src="https://akim-oss.aikucun.com/mshop/94f07a44d35b51c913e72cadcc3236b721f22e24_1724636713471_35.png"
        />
      </div> */}
      {list.length > 0 && isShowSwiper && (
        /**
         * https://swiperjs.com/swiper-api#modules
         *
         * effect: slide的切换效果(coverflow: 3d流)
         * slidesPreview: auto 设置同时显示的幻灯片数量
         * loopAdditionalSlides(number): loop模式下会在slides前后复制若干个slide,前后复制的个数不会大于原总个数。
         * coverflowEffect
         *    rotate(number): slide做3d旋转时Y轴的旋转角度
         *    stretch(number): 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
         *    depth(number): slide的位置深度。值越大z轴距离越远，看起来越小。
         *    modifier(number): depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。
         *    slideShadows(boolean): 是否开启slide阴影
         * autoplay 设置为true启动自动切换
         *    delay(number)：自动切换的时间间隔，单位ms
         *    disableOnInteraction(boolean): 用户操作swiper之后，是否禁止autoplay
         * modules 在项目中引入swiper 时，需要用到的 Swiper 模块。
         *    EffectCoverflow
         *    Autoplay
         * loop(boolean): 是否开启循环模式
         */
        <Swiper
          ref={swiperRef}
          initialSlide={1}
          effect={"coverflow"}
          slidesPerView={"auto"}
          loopAdditionalSlides={2}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 205,
            modifier: 2,
            slideShadows: true,
          }}
          autoplay={{ delay: 1800, disableOnInteraction: false }}
          modules={[EffectCoverflow, Autoplay]}
          loop={true}
          className={styles.carousel_swiper}
        >
          {(list.length <= 7 ? list.concat(list) : list).map((item, index) => (
            <SwiperSlide
              className={styles.swiper_slide}
              key={index}
              onClick={() => handleJumpPage(item, index)}
            >
              <img className={styles.slide_item} src={item.awardPic} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default CarouselSwiper;
