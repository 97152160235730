import {
  apiGetWaitTotalPoints,
  checkFollowWeChat,
  findTaskList4HomePage,
  getIntegralMallUrl,
  signJF,
} from "@/api/home";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Swiper, Toast } from "antd-mobile";
import React, { FC, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import styles from "./IntegralCard.module.less";

const IntegralCard: FC = () => {
  const [freeAmount, setFreeAmount] = useState<string>("0");
  const [waitTotalAmount, setWaitTotalAmount] = useState<number>(0);
  const [memberTaskVOList, setMemberTaskVOList] = useState<any[]>([]);
  const [logList, setLogList] = useState<any[]>([]);
  const [mallUrl, setMallUrl] = useState("");
  const { navigate2H5, navigateByH5Link } = useNavigation();

  const buttonTextMap = {
    C_JF_SIGN_UP: ["去签到", "已签到"],
    C_JF_FOLLOW_W: ["去关注", "已关注"],
    C_JF_SHARE: ["去完成", "已完成"],
    C_JF_PAY: ["去完成", "已完成"],
    C_JF_INVITE_PAY: ["去完成", "已完成"],
  };

  const queryJFShopUrl = async () => {
    const res = await getIntegralMallUrl();
    setMallUrl(res.mallH5Url);
  };

  const getWaitTotalPoints = async () => {
    const data = await apiGetWaitTotalPoints();
    setWaitTotalAmount(data?.waitTotalAmount || 0);
  };

  const query = async () => {
    const res = await findTaskList4HomePage();
    const { freeAmount, memberTaskVOList, pointsBulletScreenVO, pointsGiftVO } = res;
    // pointsBulletScreenVO.bulletScreens = [
    //   {
    //     headPicture:
    //       "https://thirdwx.qlogo.cn/mmopen/vi_32/KjXNw3FNfsKBVhM6yf21LceoYdTD2pLU5sibIPpnuAg8pShaoKZYexMLPQqcJ6Zichdd6YWwsFxaOrqGzfo7NPug/132",
    //     nickName: "luweibin",
    //     info: "已成功兑换惺惺相惜",
    //   },
    //   {
    //     headPicture:
    //       "https://thirdwx.qlogo.cn/mmopen/vi_32/KjXNw3FNfsKBVhM6yf21LceoYdTD2pLU5sibIPpnuAg8pShaoKZYexMLPQqcJ6Zichdd6YWwsFxaOrqGzfo7NPug/132",
    //     nickName: "2luweibin",
    //     info: "2已成功兑换惺惺相惜",
    //   },
    // ];
    setLogList(pointsBulletScreenVO?.bulletScreens || []);

    if (pointsGiftVO && pointsGiftVO.topGifts && pointsGiftVO.topGifts.length) {
      const obj = pointsGiftVO.topGifts[0];
      memberTaskVOList.push({
        iconUrl: obj.pictureUrl,
        taskName: obj.productTitle,
        taskRemark: obj.productDesc,
        buttonText: "去兑换",
        type: "exchange",
        taskStatus: 0,
      });
      queryJFShopUrl();
    }
    setFreeAmount(freeAmount);
    setMemberTaskVOList(memberTaskVOList);
  };

  const toFollowWechat = async (task) => {
    const res = await checkFollowWeChat({ actId: task.actId });
    if (res.isFollow) {
      Toast.show(`关注成功,${task.awdAmount}积分已发放至你的积分账户`);
    } else {
      window.location.href = "https://mp.weixin.qq.com/s/07rq1S5IbVgaPamNstYj7w";
    }
  };

  const toSign = async (task) => {
    // 签到任务，签到得积分
    const params = { actId: task.actId, awardId: task.awardId };
    await signJF(params);
    Toast.show(`签到成功，获得${task.awdGrowth}成长值及${task.awdAmount}积分~`);
    query();
  };

  const handleClick = (task) => {
    const { type, taskStatus, templateCode } = task;
    if (type === "exchange") {
      navigateByH5Link(mallUrl);
    } else if (taskStatus === 0) {
      switch (templateCode) {
        case "C_JF_FOLLOW_W":
          // 关注微信得积分
          toFollowWechat(task);
          break;
        case "C_JF_SIGN_UP":
          // 签到任务，签到得积分
          toSign(task);
          break;
        case "C_JF_SHARE":
          navigate2H5({
            path: "/#/top-list?from=cjf",
          });
          break;
        case "C_JF_PAY":
          navigate2H5({
            path: "/#/cart",
          });
          break;
        case "C_JF_INVITE_PAY":
          navigate2H5({
            path: "/#/top-list?from=cjf",
          });
          break;
        default:
          navigate2H5({
            path: "/#/top-list?from=cjf",
          });
      }
    }
  };

  useEffect(() => {
    query();
    getWaitTotalPoints();
  }, []);

  useActivate(() => {
    getWaitTotalPoints();
  });

  if (!memberTaskVOList?.length) {
    return <></>;
  }

  const ReceivePoint = () => {
    return (
      <div className={styles.receivePoint}>
        <img
          src="https://akim-oss.aikucun.com/mshop/3478fe69c7dcebc14f62b322a44059dedf000466_1679731292625_52.png"
          alt=""
        />
        <span>你有{waitTotalAmount}积分待领取</span>
      </div>
    );
  };

  const LogList: FC<{ list: any }> = ({ list }) => {
    return (
      <div className={styles.logWrap}>
        <Swiper
          direction="vertical"
          loop={true}
          autoplay={true}
          indicator={(total, current) => <></>}
          style={{ "--height": "22px" }}
        >
          {list.map((item) => (
            <Swiper.Item key={item.nickName}>
              <div className={styles.item}>
                <div className={styles.inner}>
                  <img className={styles.headImg} src={item.headPicture} alt="" />
                  <div className={styles.nickNameWrap}>
                    <span className={styles.nickname}>{item.nickName} </span>
                    {item.info}
                  </div>
                </div>
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    );
  };

  return (
    <div className={styles.integralCardWrap}>
      <div className={styles.integralCard}>
        <div className={styles.header}>
          <div className={styles.left}>
            当前可用:&nbsp;<span>{freeAmount}</span>
            <i className={styles.iconCoin}></i>
          </div>
          <div className={styles.right}>
            {waitTotalAmount > 0 ? <ReceivePoint /> : <LogList list={logList} />}
          </div>
        </div>
        <div className={styles.swipeWrap}>
          <Swiper autoplay={true} loop={true} indicator={(total, current) => <></>}>
            {memberTaskVOList.map((item: any) => {
              return (
                <Swiper.Item key={item.actId}>
                  <div className={styles.content}>
                    <div className={styles.left}>
                      <div
                        className={styles.productImg}
                        style={{ backgroundImage: `url(${item.iconUrl})` }}
                      >
                        {item?.type === "exchange" && (
                          <span className={styles.iconExchange}>可兑</span>
                        )}
                      </div>
                      <div className={styles.center}>
                        <div className={styles.top}>{item.taskName}</div>
                        <div className={styles.bottom}>{item.taskRemark}</div>
                      </div>
                    </div>
                    <div className={styles.right} onClick={() => handleClick(item)}>
                      {item.buttonText || buttonTextMap[item.templateCode]?.[item.taskStatus]}
                    </div>
                  </div>
                </Swiper.Item>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default IntegralCard;
