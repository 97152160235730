import { searchByImage } from "@/api/product";
import TopBar from "@/components/top-bar";
import { navigateBack } from "@/utils/navigate";
import { getCookie, getPlatform } from "@/utils/platform";
import Track from "@/utils/track";
import { urlQueryObject } from "@/utils/url-parse";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getSafeAreaInsetBottom, trackParams } from "./common";
import MyCropper from "./components/Cropper";
import ImageSelect from "./components/ImageSelect";
import SearchContainer from "./components/SearchContainer/index";
import "./index.less";
import styles from "./index.module.less";
import { NaturalBoxType, QueryParams } from "./type";

let requestCount = false; // 是否初始请求(变量名没取错，本身想记录请求次数的)
const cropperMaxHeight = window.innerHeight - 255;
const platform = getPlatform();

const getRequestId = () => {
  const { userId = "userId" } = urlQueryObject();

  const id = (getCookie("msUserCode") ?? userId) + Date.now();
  trackParams.search_request_id = id;
  return id;
};

getRequestId();

const ImageSearch: React.FC = () => {
  const track = Track();
  const [searchParams] = useSearchParams();
  const [picturePath, setPicturePath] = useState(searchParams.get("picturePath") as string);

  const [dataSource, setDataSource] = useState([]);
  const [cropData, setCropData] = useState<NaturalBoxType[]>([]);
  const currentKeyRef = useRef("");
  const [currentKey, setCurrentKey] = useState("");
  const [pageState, setPageState] = useState({
    pageNum: 1,
    pageSize: 10,
    hasNext: true,
  });
  const [isLoading, setLoading] = useState(false);
  const [reflash, setReflash] = useState(0);
  const [cropHeight, setCropHeight] = useState(cropperMaxHeight);

  const currentRect = useRef("");
  const getQueryParams = (
    isInit: boolean,
    serachPositon?: undefined | NaturalBoxType,
  ): QueryParams => {
    const params: QueryParams = {
      enableDetect: isInit, // 是否开启多主体识别
      imgUrl: picturePath as string, // 图片url
      pageId: "H5_Search", // 页面ID（标签需要）
      pageNum: 1,
      pageSize: 10,
      voConfig: { showType: 2 },
      searchRequestId: trackParams.search_request_id,
    };

    if (!isInit) {
      params.currentRect = currentRect.current ? JSON.parse(currentRect.current) : null;
    }

    return params;
  };
  const trackEventForResult = (nums: number) => {
    // 商品搜索请求完成时触发，包括以下场景：点击搜索按钮或搜索词，出现搜索结果
    const params = {
      ...trackParams,
      nums,
    };

    track.track("view_search_result", params);
  };

  useEffect(() => {
    setCropHeight(cropperMaxHeight - getSafeAreaInsetBottom());

    const params = getQueryParams(true);
    setLoading(true);
    searchByImage(params)
      .then((searchByImageRes: any) => {
        const currentRect = searchByImageRes?.extra?.currentRect;
        const allRect = searchByImageRes?.extra?.allRect || [];
        const imgUrl = searchByImageRes?.extra?.imgUrl;
        const result = searchByImageRes?.result || [];
        trackEventForResult(result.length);
        setCropData(allRect);
        imgUrl && setPicturePath(imgUrl);
        setDataSource(result);
        setCurrentKey(JSON.stringify(currentRect));
        currentKeyRef.current = JSON.stringify(currentRect);
        currentRect.current = JSON.stringify(currentRect);
        // 分页参数
        setPageState((pre) => {
          return {
            ...pre,
            pageNum: searchByImageRes.pageIndex,
            hasNext: searchByImageRes?.hasNext,
          };
        });
      })
      .finally(() => {
        requestCount = true;
        setLoading(false);
      });
  }, []);

  const [positon, setPositon] = useState<NaturalBoxType>();

  useEffect(() => {
    // 隔离images的副作用
    currentKeyRef.current = currentKey;
    currentRect.current = currentKey;
  }, [currentKey]);

  const debounceRequest = debounce(() => {
    setLoading(true);
    searchByImage(getQueryParams(false))
      .then((searchByImageRes: any) => {
        const result = searchByImageRes?.result || [];
        setDataSource(result);
        trackEventForResult(result.length);
        setPageState((pre) => {
          return {
            ...pre,
            pageNum: 1,
            hasNext: searchByImageRes?.hasNext,
          };
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const loadMore = async () => {
    const params = getQueryParams(false);
    params.pageNum = pageState.pageNum + 1;
    const searchByImageRes = (await searchByImage(params)) as any;
    setDataSource((pre) => [...pre, ...searchByImageRes.result] as any);
    setPageState((pre) => {
      return {
        ...pre,
        pageNum: searchByImageRes.pageIndex,
        hasNext: searchByImageRes?.hasNext,
      };
    });
  };

  const onCropBoxChange = (_: any, naturaData: NaturalBoxType) => {
    setPositon(naturaData);
    currentRect.current = JSON.stringify(naturaData);
  };
  const onCropend = useCallback((_: any, naturaData: NaturalBoxType) => {
    debounceRequest();
  }, []);

  const appendImageCropParams = (url: string, p: NaturalBoxType) =>
    url + `&x-oss-process=image/crop,x_${~~p.x},y_${~~p.y},w_${~~p.width},h_${~~p.height}`;

  const onCurrentChange = useCallback((item: { key: string }, index) => {
    setCurrentKey(item.key);
    currentRect.current = item.key;
    debounceRequest();
  }, []);

  const images = useMemo(
    () =>
      cropData.map((item) => {
        const itemKey = JSON.stringify(item);
        const naturalKey = JSON.stringify(positon);
        if (currentKeyRef.current === itemKey && !!positon) {
          return {
            key: naturalKey,
            src: appendImageCropParams(picturePath, positon),
          };
        } else {
          return {
            key: itemKey,
            src: appendImageCropParams(picturePath, item),
          };
        }
      }),
    [positon, cropData, currentKeyRef.current],
  );

  const myCropperBox = useMemo(
    () => cropData.find((item) => JSON.stringify(item) === currentKey) as NaturalBoxType,
    [currentKey, cropData],
  );

  const handleClose = () => {
    navigateBack();
  };
  const onCartChange = () => {
    setReflash(new Date().getTime());
  };

  return (
    <div className={`${styles["image-search"]} search-coustom`}>
      {!platform.isMini && (
        <div className={`${styles["fiexd-top"]} fiexd-top`}>
          <TopBar isShowSearch={false} reflash={reflash} />
        </div>
      )}
      <MyCropper
        imageSrc={picturePath}
        position={myCropperBox}
        onCropBoxChange={onCropBoxChange}
        onCropend={onCropend}
        maxHeight={cropHeight}
        cropData={cropData}
      />
      <SearchContainer
        dataSource={dataSource}
        pageState={pageState}
        isLoading={isLoading}
        loadMore={loadMore}
        trackData={trackParams}
        renderHeader={() => <ImageSelect images={images} onChange={onCurrentChange} />}
        onCartChange={onCartChange}
        closeFn={handleClose}
        requestCount={requestCount}
        images={images}
      />
    </div>
  );
};

export default ImageSearch;
