import { getGZHInfo } from "@/api/wechat";
import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import { useCommonContext } from "@/utils/context/common";
import { Modal, Popup } from "antd-mobile";
import { default as React } from "react";
import styles from "./FollowWx.module.less";

const FollowWX: React.FC = () => {
  const context = useCommonContext();
  const [showPopup, setShowPopup] = React.useState(false);

  const onPopupClose = () => {
    setShowPopup(false);
    getGZHInfo().then((res) => {
      context.setGzhInfo(res);
      if (!res?.isFollow) {
        Modal.show({
          showCloseButton: true,
          closeOnMaskClick: true,
          content: (
            <div className={styles.modal}>
              <div className={styles.header}>您未关注公众号</div>
              <div className={styles.content}>将会错过品牌上新和福利折扣通知</div>
              <div
                className={styles.button}
                onClick={() => {
                  Modal.clear();
                  setShowPopup(true);
                }}
              >
                立即关注
              </div>
            </div>
          ),
        });
      }
    });
  };

  return (
    <>
      {!context.gzhInfo?.isFollow && (
        <div className={styles.container} onClick={() => setShowPopup(true)}>
          <img
            className={styles.followWxImg}
            src="https://akim-oss.aikucun.com/4d134bc072212ace2df385dae143139da74ec0ef_1698894995425_49.png"
          />
          <span>关注公众号，接收品牌上新和福利折扣通知</span>
          <span className={styles.followAction}>去看看</span>
          <ArrowRightIcon className={styles.followActionImg} />
        </div>
      )}
      <Popup visible={showPopup} showCloseButton onMaskClick={onPopupClose} onClose={onPopupClose}>
        <div className={styles.popup}>
          <div className={styles.header}>关注公众号领积分</div>
          <div className={styles.imgWrapper}>
            {context.gzhInfo?.weChatInfoVO?.qrCodeUrl && (
              <img className={styles.bg} src={context.gzhInfo?.weChatInfoVO?.qrCodeUrl} />
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default FollowWX;
