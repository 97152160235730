import { shareActivity } from "@/utils/share/share";
import { ActivityCardNormalV2Comp } from "@akc/mx-taro-h5-components";
import React from "react";

interface IProps {
  data: any;
  index?: number;
}

const RedPackItem: React.FC<IProps> = ({ data, index }) => {
  const handleAction = (activity) => {
    console.log(activity);
    // 记录 分享档期活动ID，以触发领红包逻辑，app端 使用分享海报页面自带的领红包逻辑
    if (activity?.redPacket) {
      localStorage.setItem(
        "rewardCenter:shareActivity",
        activity?.activityId || activity?.activityNo || true,
      );
    }
    shareActivity({
      appOptions: {
        activityNo: activity?.activityId || activity?.activityNo,
        // redPacket 为 true， 代表可以在分享海报页面触发领红包逻辑
        redPacket: !!activity?.redPacket,
      },
      miniOptions: {
        activityNo: activity?.activityId || activity?.activityNo,
      },
    });
  };
  return (
    <>
      <ActivityCardNormalV2Comp
        model={data}
        key={index}
        entryBtnName="转发领红包"
        entryBtnAction={handleAction}
        mainLocationCode="App_ForwardRedPacket_Schedule_BrandDown"
        productTLLocationCode="App_ForwardRedPacket_Schedule_TopProductPicLeft"
        productTRLocationCode="App_ForwardRedPacket_Schedule_TopProductPicRight"
      />
    </>
  );
};
export default RedPackItem;
