import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";
import { NormalBizInfo } from "./scode_params";

const staticUrl = getBaseurl();

// 获取复制文案
export const fetchCopyMessage = (data: { bizInfo: NormalBizInfo }): Promise<Response<string[]>> => {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/copyWriting`,
    method: "post",
    data,
  });
};
