import { SortType } from "@/api/activity";
import { ReactComponent as AscIcon } from "@/assets/sort_asc.svg";
import { ReactComponent as DescIcon } from "@/assets/sort_desc.svg";
import React from "react";
import styles from "./index.module.less";

type Props = {
  sortType?: SortType;
};

const SortIcon: React.FC<Props> = ({ sortType }) => {
  return (
    <div className={styles.container}>
      <AscIcon className={sortType === SortType.Asc ? styles.active : ""} />
      <DescIcon className={sortType === SortType.Desc ? styles.active : ""} />
    </div>
  );
};

export default SortIcon;
