import { Dialog } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface AlertProps {
  title?: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

const alert = (props: AlertProps) => {
  Dialog.confirm({
    title: props.title ? <span className={styles.alertTitle}>{props.title}</span> : undefined,
    style: { zIndex: 100000 },
    content: props.content ? (
      <span className={styles.alertContent}>{props.content}</span>
    ) : undefined,
    confirmText: props.confirmText ? (
      <span className={styles.alertConfirmText}>{props.confirmText}</span>
    ) : undefined,
    onConfirm: props.onConfirm,
    cancelText: props.cancelText ? (
      <span className={styles.alertCancelText}>{props.cancelText}</span>
    ) : undefined,
    onCancel: props.onCancel,
  });
};

export const Alert = {
  alert,
};
