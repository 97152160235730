import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "./request";

export function queryLogisticsList(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/order/v2/logistics/query`,
    method: "get",
    params,
  });
}
