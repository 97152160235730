/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import { resetRem } from "@/utils/rem";
import { wxShare } from "@/utils/wx-share";
import microApp from "@micro-zoe/micro-app";
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MicroAppDetail.module.less";

interface Props {
  pagePath?: any;
  navigation?: any;
}

const MicroApp: React.FC<Props> = () => {
  const navigater = useNavigate();
  const staticUrl = location.protocol + "//" + location.hostname + "/h5shop";

  const getBaseNavi = () => {
    return navigater;
  };

  const getWechatShare = () => {
    return wxShare;
  };

  useEffect(() => {
    const updateBindingInfoFlag = sessionStorage.getItem("updateBindingInfoFlag");
    const getCaptchaContainer = document.getElementById("captchaContainer");
    // 在绑定完手机号之后，如果第一次进子应用，需要给一个标识
    microApp.setData("h5shop", {
      getBaseNavi,
      getWechatShare,
      resetRem,
      updateBinding: updateBindingInfoFlag,
      getCaptchaContainer,
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden auto";
    return () => {
      resetRem();
    };
  }, []);

  return (
    <div className={styles.app_container}>
      {/* <KeepAlive cacheKey="MICRO_APP" saveScrollPosition={true}> */}
      <micro-app name="h5shop" url={staticUrl} baseroute="/" keep-alive></micro-app>
      {/* </KeepAlive> */}
    </div>
  );
};

export default MicroApp;
