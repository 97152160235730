import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";
import { NormalScodeParams, PosterBizInfo } from "./scode_params";

const staticUrl = getBaseurl();

export type PosterInfo = {
  id: string | number;
  imageUrl: string;
  posterType: string;
  templateNo: string;
  customize: boolean;
  scode: string;
  style: { x: number; y: number; width: number; height: number };
};

export type SharePosterParams = NormalScodeParams & {
  bizInfo: PosterBizInfo;
};

// 分享海报
export const generateSharePoster = (
  data: SharePosterParams,
): Promise<
  Response<{
    posterInfos: PosterInfo[];
  }>
> => {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/generatePoster`,
    method: "post",
    data: {
      ...data,
      contentContext: {
        ...data.contentContext,
        contentType: data.bizInfo.contentType,
        contentId: data.bizInfo.contentId,
      },
    },
  });
};

// 批量生产海报-选择性转发
export function batchGeneratePoster(params?: any) {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/batchGeneratePoster`,
    method: "post",
    data: params,
  });
}

// 转发保存-选择性转发
export function forwardRecordSave(params?: any) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/forwardRecord/save/v3.0`,
    method: "post",
    data: params,
  });
}
