import { DatePickerView, Popup } from "antd-mobile";
import React from "react";
import { useDateDialog } from "./hook/useDateDialog";
import styles from "./index.module.less";

export interface DateRangeModel {
  //开始时间
  startDate?: string;
  //结束时间
  endDate?: string;
  //tab选中下标
  tabIndex?: number;
  //固定日期选中下标
  fixIndex?: number;
  //固定日期选中名称
  fixName?: string;
}

export interface DateSelectDialogProps {
  popVisible: boolean;
  defaultRange?: DateRangeModel;
  close: () => void;
  getDateRange: (res?: DateRangeModel) => void;
}

const DateSelectDialog: React.FC<DateSelectDialogProps> = (props: DateSelectDialogProps) => {
  const { ...control } = useDateDialog(props);

  return (
    <Popup
      visible={props.popVisible}
      onMaskClick={control.onClose}
      bodyStyle={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        zIndex: 1000,
      }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.cancel} onClick={control.onClose}>
            取消
          </div>
          <div className={styles.tab}>
            <div className={styles.mytabs}>
              {control.tabList.map((item, index) => {
                return (
                  <div
                    className={` ${styles["tab-item"]} 
                  ${control.curTabIndex === index ? styles["active"] : styles[""]}
              `}
                    key={index}
                    onClick={() => {
                      control.setCurTabIndex(index);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.comfirm} onClick={control.comfirm}>
            确认
          </div>
        </div>

        <div className={styles.date_container}>
          {control.curTabIndex === 0 && (
            <div className={styles.fix_dates}>
              {control.dateFixList.map((item, index) => {
                return (
                  <div
                    className={` ${styles["fix-item"]} 
                  ${control.curFixIndex === index ? styles["active"] : styles[""]}
              `}
                    key={index}
                    onClick={() => {
                      control.setCurFixIndex(index !== control.curFixIndex ? index : -1);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          )}
          {control.curTabIndex === 1 && (
            <div className={styles.time_title}>
              <div
                className={` ${styles["filter_time"]} 
            ${control.currentTimeIndex === 0 ? styles["active"] : styles[""]}
            ${control.startDate ? styles[""] : styles["empty"]}
              `}
                onClick={() => {
                  control.setCurrentTimeIndex(0);
                }}
              >
                {control.startDate || "开始时间"}
              </div>
              <div className={styles.filter_center_t}>至</div>
              <div
                className={` ${styles["filter_time"]} 
            ${control.currentTimeIndex === 1 ? styles["active"] : styles[""]}
            ${control.endDate ? styles[""] : styles["empty"]}
              `}
                onClick={() => {
                  control.setCurrentTimeIndex(1);
                }}
              >
                {control.endDate || "结束时间"}
              </div>
            </div>
          )}
          {control.curTabIndex === 1 && (
            <div className={styles.date_picker}>
              <DatePickerView
                value={control.getDatePickerDefaultValue()}
                renderLabel={control.labelRenderer}
                precision={"day"}
                max={control.currentTimeIndex === 1 ? new Date() : control.maxDate}
                min={control.currentTimeIndex === 0 ? new Date(2017, 8, 1) : control.minDate}
                onChange={(val) => {
                  control.timeChange(val);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};

export default DateSelectDialog;
