import { SharePosterParams } from "@/api/scode/poster";
import outSPng from "@/assets/coupon_all_s.png";
import backSPng from "@/assets/coupon_back_s.png";
import shareSPng from "@/assets/coupon_share_btn_s.png";
import fetchedIconSPng from "@/assets/fetched_icon_s.png";
import getSPng from "@/assets/get_s.png";
import noStartSPng from "@/assets/noStart_s.png";
import useSPng from "@/assets/use_s.png";
import { sharePosterParams4H5Coupon } from "@/utils/actions";
import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import exposure from "../../utils/expose/exposure";
import { pxtorem } from "../../utils/pxtorem";
import PosterSwipe from "../poster-swiper";
import styles from "./index.module.less";
// import couBack from "@/assets/coupon_back.png";
import useCouponAction, { CouponDisplayStatus, displayStatus4Coupon } from "@/utils/action/coupon";
import Coupon11Comp from "./11";

interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  trackInfo?: any;
  [key: string]: any;
}

const CouponCom: React.FC<Props> = (props: Props) => {
  const dataInfo = props.propData;

  const [coupons, setCoupons] = useState<any[]>(props.propData.dataInfo);

  useEffect(() => {
    setCoupons(props.propData.dataInfo);
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < props?.propData?.dataInfo?.length; i++) {
        const dom = document.getElementById(
          `${props.propData.componentCode}-${props.propData.dataInfo[i].awdId}`,
        );
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [JSON.stringify(props.propData)]);

  // 转发海报相关
  const [showPoster, setShowPoster] = useState(false);
  const sharePosterData = useRef<SharePosterParams>();
  const onClosePoster = () => {
    sharePosterData.current = undefined;
    setShowPoster(false);
  };

  // 已领取的优惠券记录
  const [fetchedCouponIds, setFetchedCouponIds] = useState<string[]>([]);

  const { couponBtnAction } = useCouponAction();

  // 点击按钮的响应
  const btnAction = (coupon, index?: any) => {
    couponBtnAction({
      pageData: props.pageData,
      coupon,
      layoutType: dataInfo.layoutType,
      share4H5Action: async () => {
        sharePosterData.current = sharePosterParams4H5Coupon({
          pageCode: props.pageData.pageCode,
          voucherNo: coupon.voucherNo,
        });
        setShowPoster(true);
      },
      fetchSuccessAction: () => {
        const key = `${coupon.awdId}_${coupon.promoActivityId}`;
        setFetchedCouponIds([...fetchedCouponIds, key]);
      },
      resourceRank: props.resourceRank,
      relativeRank: index,
      trackInfo: props.trackInfo,
    });
  };

  const share4H5Action = async (coupon) => {
    sharePosterData.current = sharePosterParams4H5Coupon({
      pageCode: props.pageData.pageCode,
      voucherNo: coupon.voucherNo,
    });
    setShowPoster(true);
  };
  const fetchSuccessAction = (coupon) => {
    const key = `${coupon.awdId}_${coupon.promoActivityId}`;
    setFetchedCouponIds([...fetchedCouponIds, key]);
  };

  /**
   * 优惠券卡片-一行一
   */
  const couponBigCard = (item, index) => {
    return (
      <Coupon11Comp
        coupon={item}
        index={index}
        propData={props.propData}
        pageData={props.pageData}
        resourceRank={props.resourceRank}
        share4H5Action={() => share4H5Action(item)}
        fetchSuccessAction={() => fetchSuccessAction(item)}
        trackInfo={props.trackInfo}
      />
    );
  };

  /**
   * 优惠券卡片-一/二行多等
   */
  const couponSmallCard = (item, index) => {
    const status = item.displayStatus ?? displayStatus4Coupon(item);
    if (status === CouponDisplayStatus.unknown) {
      return undefined;
    }
    let btnSrc: string;
    let tagSrc: string | undefined;
    let btnStyle: any | undefined;
    let tagClassName: string | undefined;
    switch (status) {
      case CouponDisplayStatus.fetchNow:
        btnSrc = getSPng;
        break;
      case CouponDisplayStatus.exhaust:
        btnSrc = getSPng;
        btnStyle = {
          width: pxtorem(78),
          paddingTop: pxtorem(2),
          opacity: 0.3,
        };
        tagSrc = outSPng;
        tagClassName = styles.outS;
        break;
      case CouponDisplayStatus.use:
        btnSrc = useSPng;
        break;
      case CouponDisplayStatus.fetched:
        btnSrc = useSPng;
        btnStyle = {
          opacity: 0.3,
        };
        tagSrc = fetchedIconSPng;
        tagClassName = styles.outS;
        break;
      case CouponDisplayStatus.forward:
        btnSrc = shareSPng;
        break;
      default:
        btnSrc = noStartSPng;
        break;
    }

    return (
      <div
        key={"cou" + index}
        className={styles.item}
        style={{
          background: `url(${backSPng}) no-repeat`,
          backgroundSize: "100% 100%",
          marginLeft:
            index === 0 && dataInfo.layoutType === "10"
              ? dataInfo.hasBackgroundImg === 0
                ? pxtorem(12)
                : pxtorem(6)
              : dataInfo.hasBackgroundImg === 0
                ? pxtorem(3)
                : pxtorem(6),
          marginRight:
            index === coupons?.length - 1
              ? dataInfo.hasBackgroundImg === 0
                ? pxtorem(12)
                : pxtorem(6)
              : pxtorem(0),
          marginTop:
            dataInfo.hasBackgroundImg === 1 &&
            dataInfo.layoutType === "20" &&
            dataInfo.imgStyle === "top"
              ? 0
              : pxtorem(6),
          marginBottom:
            dataInfo.hasBackgroundImg === 0 && dataInfo.layoutType === "20"
              ? pxtorem(0)
              : pxtorem(6),
        }}
        id={`${props.propData.componentCode}-${item.awdId}`}
        data-param={JSON.stringify({
          type: "优惠券",
          content: "优惠券",
          name: item.dcTemplateVoucherName,
          id: item.awdId,
          picture: dataInfo.hasBackgroundImg === 1 ? true : false,
          hasSpecially: false,
          coupons_Id: item.awdId,
          resourceRank: props.resourceRank,
          relativeRank: index,
          ...props.trackInfo,
        })}
        data-eventid={props.propData.componentCode + "-" + item.awdId}
        onClick={() => btnAction(item, index)}
      >
        <div className={styles.price}>
          <div className={styles.symbol}>¥</div>
          <div className={styles.bigText}>{item.quotaAmount}</div>
        </div>
        <div className={styles.gold}>满{item.baseAmount}可用</div>
        <div className={styles.btn}>
          <Image src={btnSrc} className={styles.nowS} style={btnStyle}></Image>
        </div>
        {tagSrc && (
          <div className={styles.topIcon}>
            <Image src={tagSrc} className={tagClassName}></Image>
          </div>
        )}
      </div>
    );
  };

  /**
   * 优惠券卡片-一行多只有2个
   */
  const couponSpecialTwoCard = (item, index) => {
    const status = item.displayStatus ?? displayStatus4Coupon(item);
    if (status === CouponDisplayStatus.unknown) {
      return undefined;
    }
    let btnSrc: string;
    let tagSrc: string | undefined;
    let btnStyle: any | undefined;
    let tagClassName: string | undefined;
    switch (status) {
      case CouponDisplayStatus.fetchNow:
        btnSrc = getSPng;
        break;
      case CouponDisplayStatus.exhaust:
        btnSrc = getSPng;
        btnStyle = {
          width: pxtorem(82),
          paddingTop: pxtorem(2),
          opacity: 0.3,
        };
        tagSrc = outSPng;
        tagClassName = styles.outS;
        break;
      case CouponDisplayStatus.use:
        btnSrc = useSPng;
        break;
      case CouponDisplayStatus.fetched:
        btnSrc = useSPng;
        btnStyle = {
          opacity: 0.3,
        };
        tagSrc = fetchedIconSPng;
        tagClassName = styles.outS;
        break;
      case CouponDisplayStatus.forward:
        btnSrc = shareSPng;
        break;
      default:
        btnSrc = noStartSPng;
        break;
    }
    return (
      <div
        key={"cou" + index}
        className={styles.item}
        style={{
          background: `url(${backSPng}) no-repeat`,
          backgroundSize: "100% 100%",
          marginLeft: index === 0 ? pxtorem(12) : pxtorem(9),
          marginRight: 0,
          marginTop:
            dataInfo.hasBackgroundImg === 1 &&
            dataInfo.layoutType === "20" &&
            dataInfo.imgStyle === "top"
              ? 0
              : pxtorem(6),
          marginBottom:
            dataInfo.hasBackgroundImg === 0 && dataInfo.layoutType === "20"
              ? pxtorem(0)
              : pxtorem(6),
          width: pxtorem(171),
        }}
        id={`${props.propData.componentCode}-${item.awdId}`}
        data-param={JSON.stringify({
          type: "优惠券",
          content: "优惠券",
          name: item.dcTemplateVoucherName,
          id: item.awdId,
          picture: dataInfo.hasBackgroundImg === 1 ? true : false,
          hasSpecially: false,
          coupons_Id: item.awdId,
          resourceRank: props.resourceRank,
          relativeRank: index,
          ...props.trackInfo,
        })}
        data-eventid={props.propData.componentCode + "-" + item.awdId}
        onClick={() => btnAction(item, index)}
      >
        <div className={styles.price}>
          <div className={styles.symbol}>¥</div>
          <div className={styles.bigText}>{item.quotaAmount}</div>
        </div>
        <div className={styles.gold} style={{ width: "100%" }}>
          满{item.baseAmount}可用
        </div>
        <div className={styles.btn}>
          <Image src={btnSrc} className={styles.nowS} style={btnStyle}></Image>
        </div>
        {tagSrc && (
          <div className={styles.topIcon}>
            <Image src={tagSrc} className={tagClassName}></Image>
          </div>
        )}
      </div>
    );
  };

  const couponSpecialOneCard = (item, index) => {
    return (
      <Coupon11Comp
        coupon={item}
        index={index}
        propData={props.propData}
        pageData={props.pageData}
        resourceRank={props.resourceRank}
        share4H5Action={() => share4H5Action(item)}
        fetchSuccessAction={() => fetchSuccessAction(item)}
      />
    );
  };

  if ((coupons?.length ?? 0) === 0) {
    return <></>;
  }

  return (
    <>
      <div
        className={styles.coupon}
        style={{
          borderTopLeftRadius: pxtorem(props.propData?.borderTopLeftRadius),
          borderTopRightRadius: pxtorem(props.propData?.borderTopRightRadius),
          borderBottomLeftRadius: pxtorem(props.propData?.borderBottomLeftRadius),
          borderBottomRightRadius: pxtorem(props.propData?.borderBottomRightRadius),
          margin: dataInfo.hasBackgroundImg === 1 ? pxtorem(12) : 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(dataInfo?.hasBackgroundImg === 0 ||
          dataInfo?.hasBackgroundImg === null ||
          dataInfo?.hasBackgroundImg === undefined) && (
          <>
            {dataInfo?.layoutType === "11" && (
              <div className={styles.one1}>
                {coupons?.map((item, index) => {
                  return <div key={index}>{couponBigCard(item, index)}</div>;
                })}
              </div>
            )}
            {dataInfo?.layoutType === "10" && (
              <div className={styles.oneMore} style={{ boxSizing: "border-box" }}>
                <div
                  className={styles.content}
                  style={{
                    justifyContent: coupons?.length < 2 ? "center" : "unset",
                  }}
                >
                  {coupons?.length > 2
                    ? coupons?.map(couponSmallCard)
                    : coupons?.length === 2
                      ? coupons?.map(couponSpecialTwoCard)
                      : coupons?.map(couponSpecialOneCard)}
                </div>
              </div>
            )}
            {dataInfo?.layoutType === "20" && (
              <div className={styles.oneTwoMore}>
                <div
                  className={styles.content}
                  style={{ alignContent: "baseline", height: pxtorem(208) }}
                >
                  {coupons?.map(couponSmallCard)}
                </div>
              </div>
            )}
          </>
        )}
        {dataInfo?.hasBackgroundImg === 1 && dataInfo?.imgStyle === "top" && (
          <div
            className={dataInfo.layoutType === "10" ? styles.oneMore : styles.oneTwoMore}
            style={{ backgroundColor: dataInfo.backgroundColor }}
          >
            <Image className={styles.topImg} src={dataInfo.backgroundImgUrl} fit="cover"></Image>
            <div
              className={styles.content}
              style={{
                alignContent: "baseline",
                paddingTop: dataInfo.layoutType === "10" ? pxtorem(0) : pxtorem(6),
              }}
            >
              {coupons?.map(couponSmallCard)}
            </div>
          </div>
        )}
        {dataInfo?.hasBackgroundImg === 1 && dataInfo?.imgStyle === "left" && (
          <div
            className={
              props.propData.layoutType === "10" ? styles.oneLeftMore : styles.oneTwoLeftMore
            }
            style={{ backgroundColor: dataInfo.backgroundColor }}
          >
            <Image className={styles.leftImg} src={dataInfo.backgroundImgUrl} fit="cover"></Image>
            <div className={styles.content}>{coupons?.map(couponSmallCard)}</div>
          </div>
        )}
      </div>
      {/* 海报 */}
      {sharePosterData.current && (
        <PosterSwipe
          show={showPoster}
          posterData={sharePosterData.current}
          shareType="COUPON"
          btnConfig={["copy", "edit"]}
          close={onClosePoster}
        />
      )}
    </>
  );
};

export default inject("appStore")(observer(CouponCom));
