import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import Empty from "../images/empty.png";

interface IProps {
  children?: React.ReactNode;
}

const RewardEmpty: React.FC<IProps> = ({ children }) => {
  const emptyCss: React.CSSProperties = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: pxtorem(60),
  };
  const emptyImg: React.CSSProperties = {
    width: pxtorem(140),
    height: pxtorem(140),
  };
  const emptyText: React.CSSProperties = {
    fontFamily: "苹方-简",
    fontSize: pxtorem(14),
    lineHeight: pxtorem(20),
    color: "#666666",
  };

  return (
    <div style={emptyCss}>
      <img style={emptyImg} src={Empty} alt="" />
      <span style={emptyText}>{children || "抱歉，暂无活动"}</span>
    </div>
  );
};
export default RewardEmpty;
