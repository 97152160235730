import { MICRO_APP_BASEURL } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProductList } from "../hooks/useActivityProduct";
import styles from "./PageEmptyStatus.module.less";

interface Props {
  pageInfo: any;
}

const PageEmptyStatus: React.FC<Props> = ({ pageInfo }) => {
  const navigater = useNavigate();
  const { navigate2Home } = useNavigation();

  const [search] = useSearchParams();
  const shopId = search.get("shopId");

  const productList = useProductList();

  const goHome = (e) => {
    e.stopPropagation();
    navigate2Home();
  };

  return (
    <div className={styles.page__empty__status}>
      <img
        className={styles.empty__img}
        src="https://akim-oss.aikucun.com/mshop/5679fa0533683de3ae789448b07766da168a56d4_1716538434469_98.png"
      />
      <p className={styles.empty__tips}>没有找到相关信息，看看场上其他好物吧～</p>
      <div className={styles.empty__btn} onClick={(e) => goHome(e)}>
        查看更多
      </div>
    </div>
  );
};

export default PageEmptyStatus;
