import useCheckBind from "@/pages/new-person/hooks/useCheckBind";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Popup } from "antd-mobile";
import React, { useContext, useEffect, useState } from "react";
import useZoneCoupon from "../../hooks/useZoneCoupon";
import CouponCard from "./CouponCard";
import styles from "./CouponsBoardV2.module.less";

const CouponsBoard: React.FC = () => {
  const [isShowPop, setIsShowPop] = useState(false);
  const { navigate2H5 } = useNavigation();
  const {
    isVisitor,
    bindPhone,
    bindPhonePopupVisible,
    setBindPhonePopupVisible,
    updateBindStatus,
  } = useCheckBind();
  const { thirdCustomerFlag, thirdCustomerCoupons, updateCouponData } = useZoneCoupon();

  const toNewPersonPage = () => {
    navigate2H5({
      path: "/mx-shop-micro/new-person",
    });
  };
  if (thirdCustomerFlag === 0 || !thirdCustomerCoupons.length) {
    return <></>;
  }

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.aside} onClick={toNewPersonPage}>
            更多福利
          </div>
        </div>
        <div className={styles.list} onClick={bindPhone}>
          {thirdCustomerCoupons.map((item, index) => {
            item.relativeRank = index;
            return (
              <CouponCard
                key={item.awdId}
                data={item}
                isVisitor={isVisitor}
                updateCouponList={updateCouponData}
              />
            );
          })}
        </div>
        {isVisitor && (
          <div className={styles.loginBtn} onClick={bindPhone}>
            立即登录
          </div>
        )}
        <div
          className={styles.footer}
          onClick={() => {
            setIsShowPop(true);
          }}
        >
          <div className={styles.item}>100%正品</div>
          <div className={styles.item}>天天特卖</div>
          <div className={styles.item}>退换无忧</div>
          <div className={styles.itemArrow}></div>
        </div>
      </div>
      <Popup
        visible={isShowPop}
        onMaskClick={() => {
          setIsShowPop(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          minHeight: "40vh",
          paddingBottom: "40px",
          zIndex: 10000,
        }}
      >
        <div className={styles.popHeader}>
          平台保障
          <div
            className={styles.closeBtn}
            onClick={() => {
              setIsShowPop(false);
            }}
          ></div>
        </div>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>品牌特卖</dt>
          <dd className={styles.popItemCont}>
            与各大品牌方合作，深度挖掘好质量、好价格、好款式的品牌好货
          </dd>
        </dl>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>正品保证</dt>
          <dd className={styles.popItemCont}>所有货源均来自官方渠道，保证100%正品</dd>
        </dl>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>售后无忧</dt>
          <dd className={styles.popItemCont}>
            提供7天无理由退换货、免费上门取件和自动补运费差价等服务，全程保障优质购物体验
          </dd>
        </dl>
      </Popup>
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={updateBindStatus}
        extra={{ bindStatus: 3 }}
      />
    </>
  );
};

export default CouponsBoard;
