import React, { useRef } from "react";

interface IProps {
  children: any;
  hasMore: boolean; //是否加载更多
  scrollBottom: () => void; //滚动到底的事件处理
  domId?: string; //滚动所在dom的id
  styles?: any; //样式
  className?: string; //类名
}
const TouchComponent: React.FC<IProps> = (props) => {
  const { styles, className, hasMore, scrollBottom, domId, children } = props;
  const startRef = useRef<any>(0);

  const touchStart = (event: any) => {
    // 处理触摸事件
    const height = (
      domId ? document.getElementById(domId)?.clientHeight : document.documentElement?.clientHeight
    ) as number;
    const scrollHeight = (
      domId ? document.getElementById(domId)?.scrollHeight : document.documentElement?.scrollHeight
    ) as number;
    const scrollTop = (
      domId ? document.getElementById(domId)?.scrollTop : document.documentElement?.scrollTop
    ) as number;
    if (!hasMore && scrollHeight - scrollTop - height < 3) {
      startRef.current = event.touches[0].clientY;
    } else {
      startRef.current = 0;
    }
  };

  const touchEnd = (event: any) => {
    // 处理触摸事件
    if (event.changedTouches[0].clientY > startRef.current || startRef.current === 0) {
      return;
    }
    setTimeout(() => {
      scrollBottom();
    }, 500);
  };

  return (
    <div
      id="touchPage"
      className={className ? className : ""}
      style={styles}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
    >
      {children}
    </div>
  );
};
export default TouchComponent;
