import { signIn } from "@/api/dailyReward";
import {
  CarrierElementType,
  ContentType,
  SourceScene,
  bizInfoParams,
  carrierContextParams,
  envContextParams,
} from "@/api/scode/scode_params";
import PosterSwipe, { CustomEditData } from "@/components/poster-swiper";
import BindPhonePopup from "@/pages/personal/components/bind-phone-popup";
import { shareCard } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import { shareDailyReward } from "@/utils/share/share";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { debounce } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import useCheckBind from "../new-person/hooks/useCheckBind";
import CarouselSwiper from "./components/CarouselSwiper";
import Lottery from "./components/Lottery";
import PopForDraw from "./components/PopForDraw";
import PopForSigned, { SignedInfo } from "./components/PopForSigned";
import ProductList from "./components/ProductList";
import RegularPopup from "./components/RegularPopup";
import SignList from "./components/SignList";
import TopBackSticky from "./components/TopBackSticky";
import useLottery from "./hook/useLottery";
import styles from "./index.module.less";

let sharePosterData: any = null;
let hasSetTrackData = false;

let pageId = "H5_SmartRecommend";
if (platform.isMini) {
  pageId = "Mini_Program_SmartRecommend";
} else if (platform.isApp) {
  pageId = "App_SmartRecommend";
}

const Index: React.FC = () => {
  const { track } = useCommonContext();
  const [search] = useSearchParams();
  const [refreshTimes, setRefreshTimes] = useState(0);
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const [signedInfo, setSignedInfo] = useState<SignedInfo>({} as SignedInfo);
  const [curShareType, setCurShareType] = useState("DAILY_AWARD");
  const [showPoster, setShowPoster] = useState(false);
  const [showRegularPop, setShowRegularPop] = useState(false);
  const [popForSignedVisible, setPopForSignedVisible] = useState(false);
  const [popForDrawVisible, setPopForDrawVisible] = useState(false);
  const { activityInfo } = useLottery({ refreshTimes });
  const {
    isVisitor,
    bindPhonePopupVisible,
    bindPhone,
    updateBindStatus,
    setBindPhonePopupVisible,
  } = useCheckBind();

  const handleSignIn = debounce(async () => {
    const { btnStatus } = activityInfo.lotteryInfo;
    // 新用户先授权和绑定手机号
    if (isVisitor) {
      bindPhone();
      return;
    }
    if (btnStatus === 2) {
      const res = await signIn({
        taskId: activityInfo.signInTaskInfo?.taskId,
        promoActivityId: activityInfo.promoActivityId,
      });
      if (res?.isGetSignInAward) {
        setPopForDrawVisible(true);
      }
      if (!res?.isGetSignInAward && res?.recommendLotteryAward) {
        setSignedInfo(res);
        setPopForSignedVisible(true);
      }
      setRefreshTimes((n) => n + 1);
    }
    if (track?.track) {
      track?.track("resource_click", {
        page_name: "日日奖",
        previous_page_name: search.get("previous_page_name"),
        btn_name: "签到",
        page_code: activityInfo.promoActivityId,
        task_id: activityInfo.signInTaskInfo?.taskId,
      });
    }
  }, 1000);
  const handleShare = (type = "dailyReward", reward?: any) => {
    const rewardName = () => {
      if (reward?.awardType === "RED_PACKET") {
        return reward?.redPacketAward?.amount;
      }
      if (reward?.awardType === "COUPON") {
        const { baseAmount, quotaAmount } = reward?.couponAwd as any;
        return `满${baseAmount}减${quotaAmount}`;
      }
      if (reward?.awardType === "POINTS") {
        return reward?.pointsAmount;
      }
    };
    if (platform.isH5) {
      sharePosterData = {
        bizInfo: bizInfoParams({
          contentType: ContentType[type],
          contentId: activityInfo.promoActivityId,
          extra: {
            awardName: reward && rewardName(),
            awardType: reward?.awardType,
          },
        }),
        envContext: envContextParams({
          sourceScene: SourceScene[type],
          sourceSceneId: ContentType[type],
        }),
        carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
      };
      setCustomEditData({
        promoActivityId: activityInfo.promoActivityId,
        awardName: reward && rewardName(),
        awardType: reward?.awardType,
      });
      setCurShareType(ContentType[type]);
      setShowPoster(true);
    } else {
      const params = {
        contentId: activityInfo.promoActivityId,
        shareType: ContentType[type],
        awardName: reward && rewardName(),
        awardType: reward?.awardType,
      };
      shareDailyReward(params);
    }
  };

  const onClosePoster = () => {
    setShowPoster(false);
    document.body.style.overflow = "unset";
  };

  // 埋点上下文数据设置
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  useActivate(() => {
    document.title = "日日奖";
  });

  useEffect(() => {
    document.title = "日日奖";
  }, []);

  useEffect(() => {
    if (!activityInfo) {
      return;
    }
    if (!hasSetTrackData && activityInfo.promoActivityId) {
      hasSetTrackData = true;
      setTrackData({
        page_name: "日日奖",
      });
      if (track?.track) {
        track?.track("$pageview", {
          page_name: "日日奖",
          previous_page_name: search.get("previous_page_name"),
          page_code: activityInfo.promoActivityId,
        });
      }
    }
    if (activityInfo.promoActivityId) {
      shareCard({ contentType: "dailyReward", contentId: activityInfo.promoActivityId });
    }
  }, [activityInfo]);

  if (!activityInfo) {
    return (
      <div className={styles.noActivity}>
        <img
          src="https://akim-oss.aikucun.com/b37f6ddcefad7e8657837d3177f9ef2462f98acf_1724119290589_81.png"
          className={styles.noActivityPic}
        />
        暂无可参加的活动，敬请期待~
      </div>
    );
  }
  return (
    <div
      className={styles.wrap + " " + (platform.isH5 ? styles.isInH5 : "")}
      id="dailyRewardContainer"
      style={{
        backgroundImage: `url(${activityInfo?.dailyTheme?.topBgImgUrl || "https://akim-oss.aikucun.com/mshop/77de68daecd823babbb58edb1c8e14d7106e83bb_1728900334396_5.png"})`,
      }}
    >
      <div className={styles.topTitle} id="dailyRewardTopTitle">
        活动时间：{moment(parseInt(activityInfo?.startTime)).format("M.D")}~
        {moment(parseInt(activityInfo?.endTime)).format("M.D")}
      </div>
      <TopBackSticky />
      <div className={styles.topFixedLabel} onClick={() => setShowRegularPop(true)}>
        活动规则
      </div>
      <div className={styles.productSwiperWrap}>
        <CarouselSwiper
          list={activityInfo.lotteryInfo?.topAwardList || []}
          promoActivityId={activityInfo?.promoActivityId || ""}
          taskId={activityInfo?.signInTaskInfo?.taskId || ""}
        />
      </div>
      <Lottery
        activityInfo={activityInfo}
        handleShare={handleShare}
        setRefreshTimes={setRefreshTimes}
        pageId={pageId}
      />
      <div className={styles.signListWrap}>
        <SignList activityInfo={activityInfo} handleSignIn={handleSignIn} />
      </div>
      {/* <div className={styles.block}>
        <RewardList list={activityInfo.lotteryInfo?.topAwardList || []} />
      </div> */}
      <ProductList pageId={pageId} activityInfo={activityInfo} />
      <div className={styles.footer} onClick={() => handleShare()}>
        <div className={styles.longBtn}>邀请好友一起抽奖</div>
      </div>
      {sharePosterData && (
        <PosterSwipe
          show={showPoster}
          customEditData={customEditData}
          posterData={sharePosterData}
          shareType={curShareType}
          btnConfig={["copy", "edit"]}
          close={onClosePoster}
        />
      )}

      <PopForSigned
        visible={popForSignedVisible}
        info={signedInfo}
        handleConfirm={() => {
          setPopForSignedVisible(false);
        }}
      />
      <PopForDraw visible={popForDrawVisible} setVisible={setPopForDrawVisible} />
      <RegularPopup
        visible={showRegularPop}
        setVisible={setShowRegularPop}
        activityRuleDesc={activityInfo.activityRuleDesc}
      />
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={updateBindStatus}
        extra={{ bindStatus: 3 }}
      />
    </div>
  );
};

export default Index;
