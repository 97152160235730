import React, { useEffect, useState } from "react";
import icon from "../../assets/icon.svg";
import styles from "./index.module.less";

interface ImageItemProps {
  imgSrc: string;
  isSelected: boolean;
  onSelect: (e: any) => void;
}

const ImageItem: React.FC<ImageItemProps> = ({ imgSrc, isSelected, onSelect }) => {
  return (
    <div
      className={`${styles["image-item"]} ${isSelected ? styles["selected"] : ""}`}
      onClick={onSelect}
    >
      <div className={styles["image-wrapper"]}>
        <img className={styles.item} src={imgSrc} alt="Item" />
        {!isSelected && <img className={styles.icon} src={icon} alt="Icon" />}
      </div>
    </div>
  );
};

interface ImageSelectProps {
  images: { key: string; src: string }[];
  onChange: (item: any, index) => void;
}

const ImageSelect: React.FC<ImageSelectProps> = ({ images, onChange }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleSelect = (index: number, e: any) => {
    e.stopPropagation();
    if (index !== selectedIndex) {
      setSelectedIndex(index);
      onChange(images[index], index);
    }
  };

  return (
    <div className={styles["image-item-box"]}>
      {images.map(({ key, src }, index) => (
        <ImageItem
          key={index}
          imgSrc={src}
          isSelected={selectedIndex === index}
          onSelect={(e) => handleSelect(index, e)}
        />
      ))}
    </div>
  );
};

export default React.memo(ImageSelect);
