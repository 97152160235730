import { useEffect, useRef, useState } from "react";

import {
  MonthTab,
  StudyTourConfigInfo,
  StudyTourSelectInfo,
  StudyTourSignUpDayListInfo,
  YouXueSignUp,
} from "../../model";
import { navigate } from "@/utils/navigate";
import { setTitle } from "@/utils/set-title";
import { Toast } from "antd-mobile";
import { fetchConfigInfo } from "@/api/youxue";
import { setMarkPageId } from "@/utils/page_utils";

/// 最大选择数量
export const MAX_SELECT_COUNT = 3;
const useStudyTour = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectTab, setSelectTab] = useState<MonthTab>();
  const tabList = useRef<MonthTab[]>();
  const [instructVisible, setInstructVisible] = useState<boolean>(false);
  const [registrationVisible, setRegistrationVisible] = useState<boolean>(false);
  const selectStudyTourDetail = useRef<YouXueSignUp>();
  const selectListMap = useRef<StudyTourSelectInfo[]>([]);
  const [checkCount, setCheckCount] = useState<number>(0);
  const [activityImages, setActivityImages] = useState<string[]>([]);
  const selectStudyIds = useRef<string[]>([]);
  const [configInfo, setConfigInfo] = useState<StudyTourConfigInfo>({});
  const [totalCount, setTotalCount] = useState<number>(0);

  /// 请求Tab
  const queryPageTab = async () => {
    let data = await fetchConfigInfo();
    setConfigInfo(data);
    tabList.current = data?.monthTabs;
    if ((tabList.current?.length || 0) > 2) {
      setSelectTab(tabList.current?.[1]);
      tabList.current?.forEach((item) => {
        selectListMap.current?.push({
          tab: `${item?.month}`,
        });
      });
    }
  };

  const updateCheckedCount = () => {
    let number = 0;
    selectListMap.current?.map((item) => {
      if (item?.tab === selectTab?.month) {
        item.list?.forEach((i) => {
          i.studyTourSignUpList?.forEach((i) => {
            if (i?.isSelect) {
              number += 1;
            }
          });
        });
      }
    });
    setCheckCount(number);
  };

  const updateSelectStudyIds = () => {
    const studyIds: string[] = [];
    selectListMap.current?.map((item) => {
      if (item.tab === selectTab?.month) {
        item?.list?.forEach((i) => {
          const selectedIds =
            i?.studyTourSignUpList?.filter((t) => t.isSelect === true)?.map((s) => s.studyTourId) ??
            [];
          studyIds.push(...selectedIds.map(String));
        });
        selectStudyIds.current = studyIds;
      }
    });
  };

  useEffect(() => {
    if (!selectTab) {
      return;
    }
    selectListMap.current?.map((item) => {
      if (item?.tab === selectTab?.month) {
        setActivityImages(item.brandLogos || []);
        setTotalCount(item?.total || 0);
      }
    });
    updateSelectStudyIds();
    updateCheckedCount();
  }, [selectTab]);

  const onChangeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setSelectTab(tabList.current?.[index]);
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  useEffect(() => {
    setTitle({ appOptions: { title: "游学报名" }, h5Options: { title: "游学报名" } });
    queryPageTab();
    setMarkPageId({ appOptions: { markId: "youxue_calendar" } });
  }, []);

  const onSelectedDialogVisible = (show?: boolean) => setVisible(show ?? false);

  const onInstructAction = (show?: boolean) => setInstructVisible(show ?? false);

  const onRegistrationAction = (show?: boolean, isSure?: boolean) => {
    if (selectStudyIds.current.length <= 0) {
      Toast.show("请选择游学活动～");
      return;
    }

    setRegistrationVisible(show ?? false);

    const query = {
      youxueIds: selectStudyIds.current,
      activityImages: activityImages,
    };

    if (isSure) {
      navigate({
        h5Options: {
          url: "/mx-shop-micro/yx/signup",
          query: query,
        },
        appOptions: {
          url: `${origin}/mx-shop-micro/yx/signup?youxueIds=${selectStudyIds.current}&activityImages=${activityImages}`,
        },
        miniOptions: {
          url: `${origin}/mx-shop-micro/yx/signup`,
          query: query,
        },
      });
    }
  };

  const onStudyTourListAction = (
    tabKey?: string,
    list?: StudyTourSignUpDayListInfo[],
    brandLogos?: string[],
    total?: number,
  ) => {
    if (!list) {
      return;
    }
    const index = selectListMap.current?.findIndex((item) => item.tab === tabKey) || 0;
    if (index > -1) {
      const sinUp = selectListMap.current[index];
      if (sinUp) {
        sinUp.list = list;
        sinUp.brandLogos = brandLogos;
        sinUp.total = total;
      }
    }
    updateCheckedCount();
    selectListMap.current?.map((item) => {
      if (item?.tab === selectTab?.month) {
        setActivityImages(item.brandLogos || []);
        setTotalCount(item.total || 0);
      }
    });
    updateSelectStudyIds();
  };

  const onDeleteAction = () => {
    updateSelectStudyIds();
    updateCheckedCount();
  };

  return {
    selectTab,
    visible,
    activityImages,
    tabList,
    instructVisible,
    registrationVisible,
    selectStudyTourDetail,
    selectListMap,
    checkCount,
    configInfo,
    totalCount,
    onChangeTabIndex,
    onSelectedDialogVisible,
    onInstructAction,
    onDeleteAction,
    onRegistrationAction,
    onStudyTourListAction,
  };
};

export default useStudyTour;
