import { getCookie } from "@/utils/platform";
import { getBaseurl, urlQueryObject } from "@/utils/url-parse";
import { API } from "@akc/biz-api";
import { Request } from "./request";

const staticUrl = getBaseurl();

//获取页面信息
export function searchPageInfo(params) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/info`,
    method: "post",
    data: params,
  });
}
//获取弹幕列表
export function searchBarrageList(params?: any) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/atmosphere/list`,
    method: "get",
    params: params,
  });
}

// 获取公益弹窗证书图片链接
export const requestConferencePopupImage = (pageCode: string) =>
  API.post<{ posterImgUrl?: string }>({
    hostID: "h5shop",
    path: "/api/mshop/mshop-aggr-prod/outer/common/findPopupImage",
    body: {
      code: pageCode,
      type: "CUSTOM_PAGE_30",
    },
  }).then((res) => res?.posterImgUrl);

//获取档期信息（基础组件）
export function searchActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/activity`,
    method: "post",
    data: params,
  });
}
//获取档期信息（导航组件）
export function searchNavActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/activity`,
    method: "post",
    data: params,
  });
}
//获取秒杀信息
export function searchSecKill(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/seckill`,
    method: "post",
    data: params,
  });
}
//获取秒杀tab下是否有数据
export function searchSecKillTab(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/seckillAllTab`,
    method: "post",
    data: params,
  });
}
//获取优惠券信息
export function searchCoupon(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/coupon`,
    method: "post",
    data: params,
  });
}
//获取积分优惠券信息
export function searchPointCoupon(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/points/coupon`,
    method: "post",
    data: params,
  });
}
//获取商品信息
export function searchProduct(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/product`,
    method: "post",
    data: params,
  });
}
//获取导航档期信息
export function searchNavigationActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/activity`,
    method: "post",
    data: params,
  });
}
//获取导航商品信息
export function searchNavigationProduct(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/product`,
    method: "post",
    data: params,
  });
}
//预览
export function preview(params?: any) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/custompage/preview`,
    method: "post",
    data: params,
  });
}
export function getCartTotal(params?: any) {
  return Request({
    url: `${staticUrl}/api/shoppingCart/queryBuyerCartSkuTotal`,
    method: "get",
    headers: {
      ls: true,
    },
    params: params,
  });
}

export function member(params?: any) {
  const url = params.distributorId
    ? `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${params.shopId}?distributorId=${
        params.distributorId
      }`
    : `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${params.shopId}`;
  return Request({
    url,
    method: "get",
    headers: {
      "app-login-channel": "xdWxH5",
      "auth-token": getCookie("mstoken"),
      ls: true,
    },
    params: params,
  });
}
export function isSwitchBatch(params?: any) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/common/bwl/isHitByBatch`,
    method: "post",
    data: params,
  });
}

export function queryFrontSettingsServ(params?: any) {
  return Request({
    url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/common/queryFrontSetting`,
    method: "get",
    headers: {
      ls: true,
    },
    params: params,
  });
}
