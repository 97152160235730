import loadingPng from "@/base64";
import AddPurchasePanel from "@/components/add-purchase-panel";
import AddToCartModal from "@/components/add-to-cart-modal";
import PosterSwipe from "@/components/poster-swiper";
import SizePopup from "@/components/size-popup";
import TopNavigation from "@/components/top-navigation";
import VSwiper from "@/components/v-swiper";
import VerticalScrollview from "@/components/vertical-scrollview";
import { navigate } from "@/utils/navigate";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import useNavigation from "@/utils/useNavigate";
import {
  DecorateMaterialCompV2,
  MedicationGuidanceComp,
  ProductCard11Comp,
  ProductDetailAttrComp,
  ProductDetailBottomBarComp,
  ProductDetailPromotionComp,
  ProductDetailShootingNValuationComp,
  ProductDetailSoldRecordComp,
  ProductInfoCardComp,
  ScheduleStatus5PDComp,
} from "@akc/mx-taro-h5-components";
import { Image, ImageViewer, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import React from "react";
import FloatMenuComp from "../components/float-menu";
import LREntranceComp from "../components/lr-entrance";
import MaterialResComp from "../components/material-res";
import MedicateGuidanceDialog from "../components/medicate-guidance-dialog";
import PriceExplainComp from "../components/price-explain";
import ProductList from "../components/product-list";
import PromotionBottomDialog, { MarketingServicesType } from "../components/promotion-dialog";
import TopTabsComp from "../components/top-tabs";
import GuideDialog from "./components/guide-dialog";
import { useProductDetailMaterial } from "./hook/useProductDetailMaterial";
import styles from "./index.module.less";

const ProductDetailMaterial: React.FC = () => {
  const { ...control } = useProductDetailMaterial();
  const { navigate2Material, navigate2Home, navigate2BrandDetail } = useNavigation();

  return (
    <VerticalScrollview
      id="page"
      className={styles.container}
      onMoveUpAtBottom={() => {
        console.log("move up~~~~");
      }}
    >
      <div id="anchor-1" ref={control.headerDomRef} />
      {/* 导航 */}
      <TopNavigation
        hideHomeText={true}
        hideSearchIcon={true}
        hideCartIcon={true}
        hideFavoriteIcon={false}
        isWhiteTheme={!control.showTopTabs}
        center={
          control.showTopTabs ? (
            <TopTabsComp activeKey={control.activeKey} tabItems={control.tabItems} />
          ) : (
            <Image
              className={styles.topStable}
              src="https://akim-oss.aikucun.com/8e2e5c6656109e73430813f34b8b01d794db022f_1724899992042_44.png"
            />
          )
        }
      />

      {/* 头部背景图 */}
      {control.productDetailModel && (
        <Image
          className={styles.topBg}
          src="https://akim-oss.aikucun.com/1c9fe87ca2cc83b2a8155d689f969149b6129fe4_1726212464623_53.png"
        />
      )}

      {control.productDetailModel && (
        <div className={styles.body}>
          <div className={styles.topC}>
            {/* 氛围轮播 */}
            <div className={styles.swipC}>
              {(control.atmosphereModel?.atmosphereList?.length ?? 0) > 0 && (
                <div className={styles.swip}>
                  <VSwiper
                    dataList={control.atmosphereModel?.atmosphereList ?? []}
                    height={40}
                    stickyDuration={2000}
                    rollingDuration={500}
                    childRender={(item) => (
                      <div className={styles.swipItem}>
                        <Image className={styles.swipItemIcon} src={item.userAvatar} />
                        <div className={styles.swipItemName}>
                          {item.userName}
                          {item.orderTime}
                          {item.dataTypeString}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
            </div>

            {/* 倒计时 */}
            {control.currentPriceInfo && (
              <div className={styles.countDown}>
                <ScheduleStatus5PDComp
                  startTime={control.currentPriceInfo?.promotionStartTime}
                  endTime={control.currentPriceInfo?.promotionEndTime}
                  textColor="#fff"
                  textFontSize={12}
                  numBackgroundColor="#FFFFFF4d"
                  numBgRadius={4}
                  numColor="#fff"
                  getServerTime={() => new Date()}
                />
              </div>
            )}
          </div>
          {/* 商品信息卡片 */}
          <ProductInfoCardComp
            className={`${(control.atmosphereModel?.atmosphereList?.length ?? 0) > 0 ? styles["part"] : styles["productCardInfo"]}`}
            product={control.productDetailModel}
            titleBottomLocationCode="H5_ProductDetail_Material_ProductTitleDown"
            onCopyTitleAction={(message) => {
              if (message) {
                copy(message);
                Toast.show("复制成功");
              }
            }}
            onClick={() => {
              if (control.productDomRef.current) {
                const rect = control.productDomRef.current.getBoundingClientRect();
                const offset = rect.top + window.pageYOffset - 44;
                setTimeout(() => {
                  window?.scrollTo({
                    top: offset,
                    behavior: "smooth",
                  });
                });
              }
            }}
            onForwardAction={(productDetail) => {
              control.onForwardAction();
            }}
          />
          <div className={styles.resAndAttr}>
            {/* 商品素材 */}
            {(control.productDetail?.materialContextVOS?.length ?? 0) > 0 && (
              <MaterialResComp
                materialList={control.productDetail?.materialContextVOS}
                onCopyTxt={(txt, _index) => {
                  if (txt) {
                    copy(txt);
                    Toast.show("复制成功");
                    control.onMaterialCopyClick();
                  }
                }}
                onPreview={(materialList, index) => {
                  const images = materialList
                    ?.filter((e) => e !== undefined)
                    ?.map((e) => e.contextContent?.contextImage?.imageUrl) as string[];
                  control.setShowPicPreview(true);
                  control.setPreviewPicList(images ?? []);
                  control.setPreviewIndex(index ?? 0);
                  control.previewRef?.current?.swipeTo(index ?? 0);
                }}
              />
            )}

            {/* 商品尺码表/尺码表 */}
            {((control.productDetailModel?.productSizeTableSimpleInfo?.attrValueRes?.length ?? 0) ||
              (control.productDetailModel?.spuInfoVO?.sizeUrl?.length ?? 0) > 0) && (
              <div id="anchor-2">
                <Image
                  className={styles.fenge}
                  src="https://akim-oss.aikucun.com/02985127ed6bdc43c10deb048418678a2289e3a7_1726134288140_95.png"
                />

                <ProductDetailAttrComp
                  className={styles.productAttr}
                  product={control.productDetail?.productDetailVO}
                  onAllAttrClick={() => {
                    control.goToProductFitSize();
                  }}
                />
              </div>
            )}
          </div>
          <div id="anchor-3">
            {/* 精选素材 */}
            {!control.currentRoleIsC && control.topMaterialResponse && (
              <DecorateMaterialCompV2
                className={styles.part}
                models={control.topMaterialResponse?.result}
                count={control.topMaterialResponse?.totalCount ?? 0}
                title="精选素材"
                titleFontSize={14}
                titleFrontLocationCode="H5_MaterialList_MaterialTitlefront"
                moreLimitNumber={4}
                onMoreAction={() => {
                  const originParams = location.href.split("?")[1];
                  const shopPath = `/mx-shop-micro/material?activitySpuId=${control.productDetailModel?.activitySpuId}&baseProductId=${control.productDetailModel?.spuInfoVO?.spuId}&useType=1&queryType=14${originParams}`;
                  navigate({
                    h5Options: {
                      url: shopPath,
                    },
                  });
                }}
                onMaterialAction={(material) => {
                  navigate2Material(
                    material.type,
                    material.materialNo,
                    control.productDetailModel?.activitySpuId,
                  );
                }}
              />
            )}
            {/* 实拍专区 */}
            {control.currentRoleIsC &&
              (control.materialListModel?.materialPictureVOList?.length ?? 0) > 0 && (
                <ProductDetailShootingNValuationComp
                  className={styles.part}
                  materials={control.materialListModel?.materialPictureVOList}
                  materialTotalCount={control.materialListModel?.total}
                  showEvaluation={false}
                  onMoreMaterialAction={() => {
                    const originParams = location.href.split("?")[1];
                    const path = `/mx-shop-micro/promote?objectIds=${control.productDetailModel?.activitySpuId}&baseProductIds=${control.productDetailModel?.spuInfoVO?.spuId}&tab=1&fromPage=productDetail&queryType=14&${originParams}`;
                    navigate({
                      h5Options: {
                        url: path,
                      },
                    });
                  }}
                  onMaterialAction={(item) => {
                    const originParams = location.href.split("?")[1];
                    const path = `/mx-shop-micro/${item.containVideo && item.type === 1 ? "recommendList" : "PromoteDetail"}?fromPage=productDetail&materialNo=${item.materialNo}&${originParams}`;
                    navigate({
                      h5Options: {
                        url: path,
                      },
                    });
                  }}
                />
              )}
          </div>
          <div id="anchor-4" ref={control.productDomRef} className={styles.productCard}>
            {/* 商品卡片1行1 */}
            {control.productModel && (
              <ProductCard11Comp
                className={styles.pCard}
                product={control.productModel}
                priceInfo={
                  control.productDetailModel?.skuAdvancePriceInfo ??
                  control.productDetailModel?.skuCurrentPriceInfo
                }
                titleBottomLocationCode="H5_ProductDetail_Material_ProductTitleDown"
                isShowAddCart={control.productModel?.skuExtendInfoVO?.buyShoppingCartModel}
                onSkusClick={(product) => {
                  // sku切换
                  control.showSkuPreview(product);
                }}
                onBuyClick={(product) => {
                  // 购买
                  control.onBuyAction();
                }}
                onForwardClick={(product) => {
                  // 转发
                  control.forwardProductCard11(product);
                }}
                onAddCartClick={(product) => {
                  // 加购
                  control.onAddCartAction();
                }}
              />
            )}
            {/* OTC用药指导 */}
            <MedicationGuidanceComp
              className={styles.medicationGuidanceComp}
              product={control.productDetail?.productDetailVO}
              onMedicationGuidanceAction={() => {
                control.setShowMedicateDialog(true);
              }}
            />
          </div>
          {/* 营销信息区域<复杂标签> */}
          <ProductDetailPromotionComp
            className={styles.part}
            product={control.productDetail?.productDetailVO}
            incentiveLocationCode="H5_ProductDetail_Marketing_Motivate"
            promoteSalesLocationCode="H5_ProductDetail_Marketing_PromoteSales"
            serviceLocationCode="H5_ProductDetail_Marketing_Service"
            onAddressAction={() => {
              control.showPromotionDialog(MarketingServicesType.Address);
            }}
            onDeliveryAction={() => {
              control.showPromotionDialog(MarketingServicesType.delivery);
            }}
            onIncentiveAction={() => {
              control.showPromotionDialog(MarketingServicesType.Motivate);
            }}
            onServiceAction={() => {
              control.showPromotionDialog(MarketingServicesType.Service);
            }}
            onPromoteSalesAction={() => {
              const nxCodes = [
                "PROMO_NX_YUAN",
                "PROMO_NX_ZHE",
                "PROMO_YI_KOU_JIA",
                "PROMO_NX_YUAN_YIKOUJIA",
                "PROMO_NX_ZHE_YIKOUJIA",
              ];
              let labelList = control.productDetailModel?.labelLocationVOList?.map((item) => {
                if (
                  item.locationCode &&
                  "H5_ProductDetail_Marketing_PromoteSales".includes(item.locationCode)
                ) {
                  return item;
                }
              });
              labelList = labelList?.filter((item) => item) ?? [];
              let promotionTtags = labelList[0]?.labelInfoVOList ?? [];
              const promoteSalesNx = promotionTtags.filter(
                (i) => i.labelCode && nxCodes.includes(i.labelCode),
              );
              if (promoteSalesNx?.length) {
                const refIds = promoteSalesNx.map((i) => i.refId);
                const promoActivityId = Array.from(new Set(refIds));
                navigate({
                  h5Options: {
                    url: `${MICRO_APP_BASEURL}/#/goodsOfNpieceXfold?marketingId=${promoActivityId.join(",")}`,
                  },
                });
              } else {
                control.showPromotionDialog(MarketingServicesType.Promote);
              }
            }}
            onNewcomerCouponAction={() => {
              const { receiveStatus, buttonStatus } =
                control.productDetailModel?.skuExtendInfoVO?.newcomerCouponVO ?? {};
              if (buttonStatus === 2) {
                // buttonStatus按钮状态(1：领券 2：条件不符 3：暂未开始 4：已领取 5.去使用 6.已抢光 7.去凑单）
                return;
              }
              if (receiveStatus === 0) {
                control.showPromotionDialog(MarketingServicesType.Discount_NewComer);
              } else if (receiveStatus === 1) {
                if (buttonStatus === 7) {
                  control.onUseCoupon();
                } else if (buttonStatus === 5) {
                  // 去使用，等同于立即购买
                  control.onBuyAction();
                }
              }
            }}
            onInformationAction={() => {
              control.showPromotionDialog(MarketingServicesType.Product_Paramters);
            }}
          />
          {/* 宝贝评价 */}
          <LREntranceComp
            leftTitle="宝贝评价"
            rightTitle="查看全部"
            onMoreClick={() => {
              navigate({
                h5Options: {
                  url: `${MICRO_APP_BASEURL}/#/commentList?productId=${control.productDetailModel?.skuBaseInfoVO?.merStyleNo}`,
                },
              });
            }}
          />
          {/* 最近购买 */}
          <ProductDetailSoldRecordComp
            className={styles.part}
            product={control.productDetail?.productDetailVO}
            recentBuyList={control.atmosphereModel?.recentBuyList}
            saleCountStr={control.atmosphereModel?.saleCountStr}
          />
          {/* 营业执照 */}
          {control.productDetailModel &&
            (
              control.productDetailModel?.skuAdvancePriceInfo ??
              control.productDetailModel?.skuCurrentPriceInfo
            )?.activitySpuType !== 1 && (
              <LREntranceComp
                leftTitle="营业执照"
                rightTitle="查看"
                onMoreClick={() => {
                  control.onBusinessLicenseClick();
                }}
              />
            )}
          {/* 价格解释 */}
          <PriceExplainComp
            onItemClick={(imageUrls, index) => {
              control.setShowPicPreview(true);
              control.setPreviewPicList(imageUrls ?? []);
              control.setPreviewIndex(index ?? 0);
              control.previewRef?.current?.swipeTo(index ?? 0);
            }}
          />
          {/* loading */}
          {control.loading && (
            <div className={`${styles.loading} ${styles.loadingFixed}`}>
              <Image src={loadingPng} width={pxtorem(30)}></Image>
            </div>
          )}
        </div>
      )}
      {/* 推荐商品列表 */}
      {control.productDetailModel && control.recommendProductList?.length > 0 && (
        <ProductList
          productList={control.recommendProductList}
          loadMore={control.onLoadNextPage}
          hasNext={control.hasNext}
        />
      )}
      {/* 底部功能按钮区域 */}
      {control.productDetailModel && (
        <div className={styles.bottomBtns}>
          {(control.productDetailModel?.skuCurrentPriceInfo?.promoCouponInfo?.discountList
            ?.length ?? 0) > 0 && (
            <div className={styles.couponTips}>
              <div className={styles.couponDesc}>当前商品可使用</div>
              <div className={styles.couponsContents}>
                {control.productDetailModel?.skuCurrentPriceInfo?.promoCouponInfo?.discountList?.map(
                  (item, index) => {
                    return (
                      <span key={index}>
                        {item.marketingTypeDesc}{" "}
                        {index <
                        (control.productDetailModel?.skuCurrentPriceInfo?.promoCouponInfo
                          ?.discountList?.length ?? 0) -
                          1
                          ? "、"
                          : ""}
                      </span>
                    );
                  },
                )}
              </div>
              <div className={styles.couponDesc}>优惠</div>
            </div>
          )}
          <ProductDetailBottomBarComp
            product={control.productDetailModel}
            cartCount={control.cartTotal}
            isReminded={false}
            onB2RShopAction={() => {
              //点击「店铺」
              control.onB2RShopAction();
            }}
            onActivityAction={() => {
              //点击「首页」
              if (control.productDetailModel?.brandInfo?.brandId) {
                navigate2BrandDetail(control.productDetailModel?.brandInfo?.brandId);
              }
            }}
            onHomeAction={() => {
              //点击「首页」
              navigate2Home();
            }}
            onServiceAction={() => {
              //点击「客服」
              control.onServiceAction();
            }}
            onCartAction={() => {
              //点击「购物车」
              control.goCart();
            }}
            onForwardAction={() => {
              //点击「转发赚钱」
              control.onForwardAction();
            }}
            onBuyAction={() => {
              //点击「立即购买」
              control.onBuyAction();
            }}
            onAddCartAction={() => {
              //点击「加入购物车」
              control.onAddCartAction();
            }}
            onWant2BuyAction={() => {
              // 点击「还想买」
              control.stillBuy();
            }}
          />
        </div>
      )}
      {/* 悬浮菜单按钮 */}
      {control.showFloatMenu && control.productDetailModel && (
        <FloatMenuComp
          productDetail={control.productDetailModel}
          onScrollTop={() => {
            control.headerDomRef.current?.scrollIntoView({ behavior: "smooth" });
          }}
          onShareClick={() => {
            if (control.productDetailModel?.skuExtendInfoVO?.isHideByManager) {
              const useInfo = JSON.parse(localStorage.getItem("userinfo") ?? "") ?? {};
              Toast.show(
                useInfo.shopOwner
                  ? "该品牌已被你隐藏，若要转发前往“我的-隐藏设置”取消隐藏"
                  : "该品牌暂时无法销售",
              );
              return;
            }
            control.onForwardAction();
          }}
        />
      )}
      {/* 营销标签底部弹窗 */}
      <PromotionBottomDialog
        dialogVisible={control.promotionDialogVisible}
        onClose={(type) => {
          control.diamissPromotionDialog();
          if (type === MarketingServicesType.Discount_NewComer) {
            control.initPageRequest();
          }
        }}
        type={control.promotionDialogType}
        product={control.productDetail?.productDetailVO}
        addressId={control.selectAddressId}
        addressList={control.addressList}
        couponList={control.couponList}
        onAddressItemClick={control.updateSelectAddress}
        onPromoteRightLook={(label) => {
          control.diamissPromotionDialog();
          control.goPromoActivity?.(label);
        }}
        onReceive={(coupon) => {
          //领券
          control.receiveCoupon(coupon);
        }}
        onUse={(coupon) => {
          control.diamissPromotionDialog();
          // 去使用，等同于立即购买
          control.onBuyAction();
        }}
        onTargetAmount={(coupon) => {
          control.diamissPromotionDialog();
          control.onUseCoupon(coupon);
        }}
      />
      {/* OTC用药指导弹窗 */}
      <MedicateGuidanceDialog
        dialogVisible={control.showMedicateDialog}
        medicationGuidanceList={control.productDetailModel?.skuBaseInfoVO?.medicationGuidanceList}
        onClose={() => {
          control.setShowMedicateDialog(false);
        }}
      />
      {/* 商品sku预览弹窗 */}
      {control.sizePopupProduct && (
        <SizePopup
          product={control.sizePopupProduct}
          show={control.showSizePopup}
          close={() => control.setShowSizePopup(false)}
          showPropertyValue
          toDetailByFirstProp={(product, salePropertyFirstListPicItem) => {
            control.initPageRequest({
              firstSalePropertyValue: salePropertyFirstListPicItem?.value,
              selectSkuId: "",
            });
            control.setFirstSalePropertyValue(salePropertyFirstListPicItem?.value);
          }}
        />
      )}
      {/* 转发弹窗 */}
      {control.sharePosterData && (
        <PosterSwipe
          show={control.showPoster}
          posterData={control.sharePosterData}
          customEditData={control.customEditData}
          isOTC={control.isOTC}
          shareType="PRODUCT"
          btnConfig={["copy", "edit"]}
          close={() => {
            control.setShowPoster(false);
            document.body.style.overflow = "auto";
          }}
        />
      )}
      {/* 加购弹窗 */}
      {control.productDetailModel && control.cartPannelButtonType && control.showNewCartPanel ? (
        <AddPurchasePanel
          visible={control.cartModalVisible}
          product={control.modalProduct}
          close={(e) => {
            if (e?.skuId !== control.selectSkuId) {
              control.initPageRequest({
                firstSalePropertyValue: "",
                selectSkuId: e?.skuId,
              });
            }
            control.setCartModalVisible(false);
          }}
          confirm={control.changeCartNum}
          trackData={{ pageName: "素材商品详情页" }}
          buttonType={control.cartPannelButtonType}
          buttonName="确定"
        />
      ) : (
        <AddToCartModal
          visible={control.cartModalVisible}
          product={control.modalProduct}
          buttonStyle={control.cartPannelButtonType}
          buttonText="确定"
          pageType={5}
          close={(e) => {
            if (e?.skuId !== control.selectSkuId) {
              control.initPageRequest({
                firstSalePropertyValue: "",
                selectSkuId: e?.skuId,
              });
            }
            control.setCartModalVisible(false);
          }}
          confirm={control.changeCartNum}
        />
      )}
      {/* 图片预览 */}
      <ImageViewer.Multi
        ref={control.previewRef}
        getContainer={document.body}
        images={control.previewPicList}
        visible={control.showPicPreview}
        defaultIndex={control.previewIndex}
        onClose={() => {
          control.setShowPicPreview(false);
        }}
      />
      {/* 引导弹窗 */}
      <GuideDialog
        visible={control.showGuideDialog}
        onClose={() => {
          control.saveGuideState();
          control.setShowGuideDialog(false);
        }}
        onGoSetting={() => {
          control.setShowGuideDialog(false);
          control.saveGuideState();
          setTimeout(() => {
            navigate({
              h5Options: {
                url: "/mx-shop-micro/share-setting",
              },
            });
          }, 200);
        }}
      />
    </VerticalScrollview>
  );
};

export default ProductDetailMaterial;
