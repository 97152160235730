import { queryCouponWindow } from "@/api/coupon";
import { useEffect, useState } from "react";

const useCouponWindow = ({ pageCode = "home_page" } = {}) => {
  const [couponPopType, setCouponPopType] = useState<
    "新版新人券弹框" | "类目天降券" | "旧版新人券弹框" | "大促样式" | "常规样式" | ""
  >("");
  const [couponInfo, setCouponInfo] = useState<any>(null);
  const query = async () => {
    const params = {
      shopBizCode: localStorage.getItem("shopId"),
      pageCode: "home_page",
    };
    const res = await queryCouponWindow(params);
    // res.data = newPersonCouponDataV1;
    if (res?.data) {
      const { thirdCustomerFlag, thirdCustomerCoupons, popType, showEffect, visitor, scene } =
        res.data;
      setCouponInfo(res.data);
      if (thirdCustomerFlag && thirdCustomerCoupons.length) {
        setCouponPopType("新版新人券弹框");
      } else if (popType === 0) {
        if (showEffect === 1) {
          if (visitor && scene === 1) {
            setCouponPopType("旧版新人券弹框");
          } else {
            // setCouponPopType("大促样式");
          }
        } else if (showEffect === 0) {
          setCouponPopType("常规样式");
        }
      } else if (popType === 1 && pageCode === "home_page") {
        setCouponPopType("类目天降券");
      }
    }
  };

  useEffect(() => {
    query();
  }, []);

  return {
    couponPopType,
    couponInfo,
  };
};

export default useCouponWindow;
