import React, { FC } from "react";
import MicroAppContainer from "../index";

const aftersaleApp: FC = () => {
  return (
    <MicroAppContainer
      name="aftersale"
      baseRouter="/mx-shop-micro/aftersale"
      baseUrl="/aftersale"
    />
  );
};
export default aftersaleApp;
