//获取用户身份
export const getProjectEnum = () => {
  if (location.href.indexOf("/decorate") >= 0) {
    return 2; // 店铺装修
  }
  if (location.href.indexOf("/market") >= 0) {
    return 3; // 店铺装修
  }
  return 1; //会场3.0
};

export const project = getProjectEnum;
