import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";
//当前登录用户分享员状态
export function queryChannelSharerStatus(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/sharer/queryChannelSharerStatus`,
    method: "get",
    params: params,
  });
}

//分享员二维码
export function createChannelSharerPoster(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/sharer/createChannelSharerPoster`,
    method: "post",
    data: params,
  });
}

//已接受邀请
export function acceptInvite(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/sharer/acceptInvite`,
    method: "post",
    data: params,
  });
}

//当前分享员绑定的视频号信息
export function queryChannelAnchorInfo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/sharer/queryChannelAnchorInfo`,
    method: "get",
    params: params,
  });
}

//视频号业绩首页看板
export function queryAchievementData(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/order/shipinhao/achievement/total`,
    method: "post",
    data: params,
  });
}

//视频号业绩首页看板
export function queryAchievementDataList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/order/shipinhao/liveRoom/achievement/total`,
    method: "post",
    data: params,
  });
}

//二维码＋背景图 合成海报
export function generateSimpleImage(params?: any) {
  return Request({
    url: `${process.env.REACT_APP_ZUUL_INFRA}/base-poster-generater/base-poster-generater/poster/generateSimpleImage`,
    method: "post",
    data: params,
  });
}
