import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";

export type LiveData = {
  couponType: number;
  coverUrl: string;
  liveNo: string;
  liveStatus: number;
  liveTitle: string;
  planBeginTime: string;
};

export async function getLiveData(activityId: string): Promise<LiveData> {
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/live/getLiveData/${localStorage.getItem(
      "shopId",
    )}/${activityId}`,
    method: "post",
  });
  return res?.data;
}
