import { MemberSignUpInfo } from "@akc/biz-api";
import React from "react";
import styles from "./index.module.less";

export interface ProgInfo {
  status?: 1 | 2 | 3 | 4; // 1完成 2等待进行 3失败 4进行中
  title?: string;
  time?: string;
  key?: "signUp" | "audit" | "confirm" | "signDown";
}

interface Props {
  progList: ProgInfo[];
  memberSignUpInfo?: MemberSignUpInfo;
}

// 审核状态
const AuditStatusComp: React.FC<Props> = (props) => {
  const idx = props.progList.findIndex((o) => o.status !== 1); // 找不到可以认为游学结束
  // 状态图标
  const findLogo = (info: ProgInfo) => {
    if (info.status === 1) {
      return "https://xiangdian-oss.aikucun.com/app_akc_ios/8b58dff54ecfe1ff72a1a3dc01e0b29b79746b11_1717669777058_81.png";
    }
    if (info.status === 2) {
      return "https://xiangdian-oss.aikucun.com/app_akc_ios/0e45e482fb226cbc133a0e8653efd37f7476df8e_1717669837590_28.png";
    }
    if (info.status === 3) {
      return "https://xiangdian-oss.aikucun.com/app_akc_ios/5d75fce8efbabcabab8c50ed4bc4ff2e28064d12_1717670033289_90.png";
    }
    if (info.status === 4) {
      return "https://xiangdian-oss.aikucun.com/app_akc_ios/59f16d83c6f34e817c6a0ee59ca26c8c4caeca28_1717670101883_19.png";
    }
  };
  return (
    <div className={styles.comp}>
      <div className={styles.prog_container}>
        {props.progList.map((item, index) => {
          const lcn =
            index === 0
              ? `${styles.prog_left_first} ${styles.prog_transparent}`
              : index === props.progList.length - 1
                ? index > idx && idx !== -1
                  ? `${styles.prog_left_last} ${styles.prog_nor}`
                  : `${styles.prog_left_last} ${styles.prog_high}`
                : index > idx && idx !== -1
                  ? `${styles.prog_left} ${styles.prog_nor}`
                  : `${styles.prog_left} ${styles.prog_high}`;

          const rcn =
            index === props.progList.length - 1
              ? `${styles.prog_right_last} ${styles.prog_transparent}`
              : index >= idx && idx !== -1
                ? `${styles.prog_right} ${styles.prog_nor}`
                : `${styles.prog_right} ${styles.prog_high}`;

          return (
            <div key={index} className={styles.prog_item}>
              <div className={styles.prog_prog}>
                <div className={lcn}></div>
                <div
                  className={index === 0 ? `${styles.prog_right_first} ${styles.prog_high}` : rcn}
                ></div>
              </div>
              <img
                className={
                  index === 0
                    ? styles.prog_status_first
                    : index === props.progList.length - 1
                      ? styles.prog_status_last
                      : styles.prog_status
                }
                src={findLogo(item)}
              />
              <div className={styles.prog_title}>{item.title || ""}</div>
              <div className={styles.prog_time}>{item.time || ""}</div>
            </div>
          );
        })}
      </div>
      {/* 拒绝理由*/}
      {props.progList[idx]?.key === "audit" &&
        props.progList[idx]?.status === 3 &&
        (props.memberSignUpInfo?.refuseReason?.length || 0) > 0 && (
          <>
            <div className={styles.arrow}>
              {props.progList.map((item, index) => {
                return index === idx ? (
                  <div className={styles.item}>
                    <img
                      className={styles.arrow_item}
                      src="https://xiangdian-oss.aikucun.com/app_akc_ios/e1fa4795d11da15cdbb80a572ebc830ec77a8ba8_1717743565767_58.png"
                    />
                  </div>
                ) : (
                  <div className={styles.item}></div>
                );
              })}
            </div>
            <div className={styles.refuse}>
              <div className={styles.refuse_title}>{props.memberSignUpInfo?.refuseReason}</div>
            </div>
          </>
        )}
      <div className={styles.line}></div>
    </div>
  );
};

export default AuditStatusComp;
