import { queryAtmosphereData, queryCouponList } from "@/api/newPerson";
import { ProductDetailAtmosphereItem } from "@akc/biz-api";
import { useEffect, useState } from "react";

const useCouponList = () => {
  const [couponList, setCouponList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [atmosphereList, setAtmosphereList] = useState<ProductDetailAtmosphereItem[]>([]);

  const updateAtmosphereList = async (activitySpuId) => {
    const res = await queryAtmosphereData({ activitySpuId });
    setAtmosphereList(res?.atmosphereList || []);
  };

  const updateCouponList = async () => {
    const params = {
      pageId: "newBornZone",
      queryProduct: true,
    };
    const res = await queryCouponList(params);
    if (res?.length) {
      setCouponList(
        res.map((item: any) => {
          item.couponNewBorn.relatedProduct = item.productInfo;
          return item.couponNewBorn;
        }),
      );
      const proList = res.map((item: any) => item.productInfo);
      setProductList(proList);
      updateAtmosphereList(proList[0]?.activitySpuId);
    }
  };

  useEffect(() => {
    updateCouponList();
  }, []);
  return {
    couponList,
    productList,
    atmosphereList,
    updateCouponList,
  };
};

export default useCouponList;
