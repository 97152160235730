import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";

// 【4管理员/3店长/1顾客】角色切换
export function switchRole(switchRole: 4 | 3 | 1) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/administrator/switch/role`,
    method: "get",
    params: { switchRole },
  });
}

export function checkLegalContract() {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/legal/contract/check`,
    method: "get",
  });
}

export function signLegalContract() {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/legal/contract/sign`,
    method: "post",
  });
}

// 【管理员/店长】店铺基本信息
export async function getShopBaseDetail() {
  const res = await Request({
    url: `${getBaseurl()}/api/shop/base/detail`,
    method: "get",
  });
  return res?.data;
}

// 【管理员】隐藏品牌数量
export async function getHiddenBrandCount(): Promise<number | string | undefined> {
  const res = await Request({
    url: `${getBaseurl()}/api/member/black/list/new/pageFrom`,
    method: "post",
    data: { hideStatus: 0, pageIndex: 1, pageSize: 10 },
  });
  return res?.data?.total;
}

// 【店长】店铺数据中心
export async function getStatisticsInfo(params: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/shop/member/statistics/shop/info`,
    method: "get",
    params,
  });
  return res?.data;
}

// 【店长】店铺参谋
export async function getCustomerConfig() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xapp/customer/shopDataConfig`,
    method: "post",
  });
  return res?.data;
}

// 【店长】店铺参谋
export async function getCustomerStatistics() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xapp/customer/customerTransferStatistics`,
    method: "post",
    data: { searchDay: 1 },
  });
  return res?.data;
}

// 【店长】店铺参谋
export async function getFrontCustomerStatistics(data?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xapp/customer/frontCustomerStatistics`,
    method: "post",
    data,
  });
  return res?.data;
}

// 【店长】保账号任务
export async function getProtectAccount() {
  const res = await Request({
    url: `${getBaseurl()}/api/spweb/task/protect/account`,
    method: "get",
  });
  return res?.data;
}

// 【店长】派单任务
export async function getPushOrderRemind(data?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/pushOrder/v1/query/remind`,
    method: "post",
    data,
  });
  return res?.data;
}

// 【店长】推广订单统计
export async function getOrderStatusCount() {
  const res = await Request({
    url: `${getBaseurl()}/api/order/v2/state/count`,
    method: "get",
  });
  return res?.data;
}

// 【店长】顾客浏览统计
export async function getCustomerForward() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xapp/customer/customerForward`,
    method: "post",
    data: {},
  });
  return res?.data;
}

// 【店长】第三方应用市场的权限
export function getAppMarketPermission() {
  return Request({
    url: `${getBaseurl()}/api/open/osm/outer/xh5/service/check-user-perm`,
    method: "post",
  });
}

// 【店长】积分中心-白名单
export async function getPointCenterItemSwitch() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/member/integralSwitch`,
    method: "get",
  });
  return res?.data;
}

// 【店长】阅己图书-开关
export async function getDreamBookSwitch() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/dream-book/isAvailable`,
    method: "get",
  });
  return res?.data;
}

// 【店长】分享员-显示
export async function getShowChannelSharer() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/sharer/showChannelSharer`,
    method: "get",
  });
  return res?.data;
}

// 【店长】学习天地-开关
export async function getSchoolCourseSwitch() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/tag/allowBusinessSchool/status`,
    method: "get",
  });
  return res?.data;
}

// 【店长】学习天地
export async function getSchoolCourseList() {
  const res = await Request({
    url: `${getBaseurl()}/api/school/course/topList`,
    method: "get",
    params: { campType: 2 },
  });
  return res?.data ?? [];
}

// 【店长】联系店主
export async function getConsultShopOwner() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/consultShopOwner`,
    method: "post",
  });
  return res?.data;
}

// 【店长-设置】店铺认证状态查询
export async function getShopAuthStatus() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/audit/enterprise/queryMemberHighestAuthStatus`,
    method: "get",
  });
  return res?.data;
}

// 【店长-设置】实名认证状态查询
export async function getNameAuthStatus() {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/verified/query`,
    method: "get",
  });
  return res?.data;
}

// 【管理员/店长】获取H5店铺海报主图
export async function getShareShop(data: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/v2/share/shop`,
    method: "post",
    data,
  });
  return res?.data;
}

/**
 * @description 查询用户隐藏信息(品牌 | 店铺 | 类目)
 * @link https://yapi.akcops.com/project/2507/interface/api/394332
 * @author 星辰(周禹安)
 */
export async function queryHideInfoServ(data: any): Promise<Response<any>> {
  // const res = await Request({
  //   url: `${getBaseurl()}/api/member/multi/member/queryHideInfo`,
  //   method: "post",
  //   data,
  // });
  // return res?.data;
  return Request({
    url: `${getBaseurl()}/api/member/multi/member/queryHideInfo`,
    method: "post",
    data,
  });
}

/**
 * @description 隐藏设置(品牌 | 店铺 | 类目)
 * @link https://yapi.akcops.com/project/2507/interface/api/394341
 * @author 星辰(周禹安)
 */
export async function setHideInfoServ(data: any): Promise<Response<any>> {
  // const res = await Request({
  //   url: `${getBaseurl()}/api/member/multi/member/setHideInfo`,
  //   method: "post",
  //   data,
  // });
  // return res?.data;
  return Request({
    url: `${getBaseurl()}/api/member/multi/member/setHideInfo`,
    method: "post",
    data,
  });
}
