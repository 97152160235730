import { queryJumpTypeServ } from "@/api/activity";
import { CouponItem as CouponItemDs } from "@/api/rewardCenter";
import { NavigatorOptions, navigate } from "@/utils/navigate";
import { platform } from "@/utils/platform";
import Track from "@/utils/track";
import { Button, Toast } from "antd-mobile";
import { RightOutline } from "antd-mobile-icons";
import React from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";
import { shareCoupon } from "./share";

interface IProps {
  type?: string;
  index?: number;
  data?: CouponItemDs;
}

const CouponItem: React.FC<IProps> = ({ type, index, data }) => {
  const track = Track();
  const [search] = useSearchParams();

  const handleToDetail = async () => {
    if (!platform.isApp) {
      return;
    }

    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
    });
    const params: any = {
      sceneId: "COUPON_MANAGER_PAGE",
      ownerType: "USE_CODE",
    };
    if (data?.isOldCoupon) {
      params.couponId = data?.couponId;
    } else {
      params.voucherNo = data?.voucherNo;
    }
    const { success, data: result, message } = await queryJumpTypeServ(params);
    Toast.clear();
    if (success) {
      const { jumpType, isOldCoupon, couponId, voucherNo, activityId, activitySpuId } =
        result || {};
      let options: NavigatorOptions = {
        url: "",
      };
      switch (jumpType) {
        case "productDetail":
          options = {
            url: "akapp://aikucun.com/productDetail",
            query: {
              activityId,
              productId: activitySpuId,
            },
          };
          break;
        case "activityDetail":
          options = {
            url: "akapp://aikucun.com/activity",
            query: {
              id: activityId,
            },
          };
          break;
        case "productList":
        case "activityList":
          options = {
            url: "akapp://aikucun.com/shareableCouponDetail",
            query: {
              jumpType: jumpType,
              couponId,
              voucherNo,
              isOldCoupon,
            },
          };
          break;
      }
      if (options.url) {
        console.log("handleToDetail", options);
        navigate({
          appOptions: options,
        });
      }
    } else {
      Toast.show({
        content: message || "服务异常，请稍后重试~",
      });
    }
  };
  const handleShare = () => {
    track.track("resource_click", {
      page_name: window.location.pathname.includes("reward-search")
        ? "奖励中心/奖励中心搜索结果"
        : "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      tab_name: "分享券",
      resource_type: "分享券",
      resource_name: data?.dcTemplateVoucherName,
      resource_content: "分享券",
      resource_rank: index,
      resource_id: data?.couponId || data?.voucherNo,
      merchant_shop_code: data?.ownerType !== "PLATFORM" ? data?.ownerId : "",
      btn_name: "去分享",
    });
    shareCoupon(data as CouponItemDs);
  };
  return (
    <>
      <div
        className={styles.CouponItemGroup + " " + (type ? styles.CouponItemEquity : "")}
        id={`cnt-${data?.couponId || data?.voucherNo}`}
        data-param={JSON.stringify({
          page_name: window.location.pathname.includes("reward-search")
            ? "奖励中心/奖励中心搜索结果"
            : "奖励中心",
          previous_page_name: search.get("page_name"),
          domain: "激励活动",
          tab_name: "分享券",
          resource_type: "分享券",
          resource_name: data?.dcTemplateVoucherName,
          resource_content: "分享券",
          resource_rank: index,
          resource_id: data?.couponId || data?.voucherNo,
          merchant_shop_code: data?.ownerType !== "PLATFORM" ? data?.ownerId : "",
        })}
        data-eventid={`cnt-${data?.couponId || data?.voucherNo}`}
      >
        <div className={styles.CouponItemBox}>
          <div className={styles.CouponItemContent}>
            <div style={{ display: "flex" }}>
              <div className={styles.CouponItemLeft}>
                <div className={styles.CouponItemAmount}>
                  <span className={styles.CouponItemUnit}>¥</span>
                  {data?.quotaAmount}
                </div>
                <div>
                  剩余 <span className={styles.CouponItemCount}>{data?.currentNum}</span> 张
                </div>
              </div>
              <div className={styles.CouponItemRight}>
                <div className={styles.CouponItemInfo}>
                  <div>
                    <div className={styles.CouponItemTitle}>{data?.dcTemplateVoucherName}</div>
                    <div>{data?.timeDesc}</div>
                  </div>
                  <Button size="mini" color="primary" onClick={handleShare}>
                    去分享
                  </Button>
                </div>
                <div className={styles.CouponItemDate}>{data?.shareTimeDesc}</div>
              </div>
            </div>
            <div className={styles.CouponItemFooter} onClick={handleToDetail}>
              <div>{data?.dcTemplateVoucherInstructions}</div>
              {platform.isApp && (
                <div>
                  去查看
                  <RightOutline />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CouponItem;
