import {
  batchReceiveCouponServ,
  cancleBrandHide,
  queryActivityCouponList,
  queryJumpTypeServ,
  setBrandHide,
} from "@/api/activity";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import {
  activityAPI,
  ActivityBrandModel,
  ActivityModel,
  CouponModel,
  LabelInfoModel,
  LabelPageId,
} from "@akc/biz-api";
import {
  ActivityCountDownTimerComp,
  ActivityMarketingServicesComp,
  ActivityMarketingServicesMoreComp,
} from "@akc/mx-taro-h5-components";
import { Dialog, Popup, Toast } from "antd-mobile";
import React from "react";
import { useActivate, useUnactivate } from "react-activation";
import { useSelectedActivity } from "../hooks/useActivityProduct";
import styles from "./ActivityDesc.module.less";

enum MarketingServicesType {
  Motivate, // 激励
  Discount, // 优惠
  Promote_NX, // 活动-N件X元
  Promote_SecondKill, // 活动-秒杀
  Promote, // 活动-其他
  Service, // 服务
}
interface ActivityMarketingServicesMoreInfo {
  type: MarketingServicesType;
  title: string;
  labelInfos?: LabelInfoModel[];
  message?: string;
}

interface IProps {
  brandId?: string;
  brandData?: ActivityBrandModel;
  updateActivity: boolean;
}

const ActivityDesc = (props: IProps) => {
  const { brandData } = props;
  const {
    navigate2Product,
    navigate2OfferOrderList,
    navigate2GoodsOfNpieceXfold,
    navigate2NxProductList,
    navigate2BusinessLicense,
  } = useNavigation();
  const selectedActivity = useSelectedActivity();
  const track = Track();
  const [activity, setActivity] = React.useState<ActivityModel>({});
  const [couponList, setCouponList] = React.useState<Array<CouponModel>>([]);
  const [popupInfo, setPopupInfo] = React.useState<ActivityMarketingServicesMoreInfo>();
  const [popupTitle, setPopupTitle] = React.useState<string>();
  const [showHideBrand, setShowHideBrand] = React.useState<boolean>(false);
  const [hideBrandStatus, setHideBrandStatus] = React.useState<boolean>(false);

  const isLoading = React.useRef(false);

  const getActivityData = () => {
    selectedActivity?.activityId &&
      activityAPI
        .queryActivityData({
          activityId: selectedActivity.activityId,
          pageId: LabelPageId.h5ActivityDetail,
        })
        .then((data) => {
          setActivity(data ?? {});
        });
  };

  const getCouponList = () => {
    selectedActivity?.activityId &&
      queryActivityCouponList({
        sourceType: "ACTIVITY",
        activityId: selectedActivity.activityId,
      }).then((data) => {
        setCouponList(data);
      });
  };

  const getPromoActivityIds = (data: ActivityModel) => {
    const { labelLocationVOList = [] } = data;
    const promoteSalesItem = labelLocationVOList.filter(
      (i) => i.locationCode === "H5_ScheduleDetail_Marketing_PromoteSales",
    )[0];
    if (promoteSalesItem) {
      const nx_codes = [
        "PROMO_NX_YUAN",
        "PROMO_NX_ZHE",
        "PROMO_YI_KOU_JIA",
        "PROMO_NX_YUAN_YIKOUJIA",
        "PROMO_NX_ZHE_YIKOUJIA",
      ];
      const promo_NX_List =
        (promoteSalesItem.labelInfoVOList &&
          promoteSalesItem.labelInfoVOList.filter((item: any) =>
            nx_codes.includes(item.labelCode),
          )) ||
        [];
      return promo_NX_List.map((item: any) => item.refId).join(",");
    } else {
      return "";
    }
  };

  useActivate(() => {
    setPopupTitle("");
  });

  useUnactivate(() => {
    setPopupTitle("");
  });

  React.useEffect(() => {
    if (selectedActivity?.activityId) {
      getActivityData();
      getCouponList();
    } else {
      setActivity({});
    }
    console.log("selectedActivity...", selectedActivity);
  }, [selectedActivity]);

  React.useEffect(() => {
    if (props.updateActivity) {
      getActivityData();
      getCouponList();
    }
  }, [props.updateActivity]);

  const onPopupTrack = (name: string) => {
    track.track("resource_click", {
      page_name: "品牌活动",
      tab_name: selectedActivity?.activityAggrTitle,
      tab_id: selectedActivity?.activityId,
      tab_rank: (selectedActivity?.rank ?? 0) + 2,
      merchant_shop_code: selectedActivity?.merchantShopCode,
      activity_id: selectedActivity?.activityId,
      resource_type: "档期信息",
      btn_name: name,
    });
  };

  const onPopup = (info: ActivityMarketingServicesMoreInfo) => {
    onPopupTrack(info.title);
    if (info.type === MarketingServicesType.Promote_NX) {
      getPromoActivityIds(activity) && navigate2NxProductList(getPromoActivityIds(activity));
      return;
    }
    setPopupInfo(info);
    setPopupTitle(info.title);
  };

  const onNewcomerTrack = (coupon: CouponModel) => {
    track.track("resource_click", {
      page_name: "品牌活动",
      tab_name: selectedActivity?.activityAggrTitle,
      tab_id: selectedActivity?.activityId,
      tab_rank: (selectedActivity?.rank ?? 0) + 2,
      merchant_shop_code: selectedActivity?.merchantShopCode,
      activity_id: selectedActivity?.activityId,
      resource_type: "档期信息",
      coupons_id: coupon.awdId,
      btn_name: "新客券",
    });
  };

  const onReceiveCouponNewcomer = (coupon: CouponModel) => {
    if (coupon.receiveStatus === 0) {
      const info = {
        title: "优惠",
        type: 1,
      };
      setPopupInfo(info);
      setPopupTitle(info.title);
    }
    onNewcomerTrack(coupon);
  };

  const onUseCouponNewcomer = (coupon: CouponModel) => {
    if ([4, 5, 7].includes(Number(coupon.buttonStatus))) {
      const info = {
        title: "优惠",
        type: 1,
      };
      setPopupInfo(info);
      setPopupTitle(info.title);
    }
    onNewcomerTrack(coupon);
  };

  const onReceiveCoupon = (coupon: CouponModel) => {
    if (isLoading.current) {
      return;
    }
    isLoading.current = true;
    const { awdId, promoActivityId } = coupon;
    batchReceiveCouponServ({
      couponByIdReqList: [{ awdId, promoActivityId }],
      rcsTraceId: "",
      captchaToken: "",
      pageIndex: window.location.href,
      protocolVersion: "",
    })
      .then((res) => {
        const { code, success, data, message } = res || {};
        if (code === "00000" && success && data) {
          Toast.show("优惠券领取成功");
          getActivityData();
          getCouponList();
        } else {
          Toast.show(message || "优惠券领取成功");
        }
      })
      .finally(() => {
        isLoading.current = false;
      });
    track.track("pick_up_coupon", {
      page_name: "品牌活动",
      tab_name: selectedActivity?.activityAggrTitle,
      tab_id: selectedActivity?.activityId,
      tab_rank: (selectedActivity?.rank ?? 0) + 2,
      merchant_shop_code: selectedActivity?.merchantShopCode,
      activity_id: selectedActivity?.activityId,
      resource_type: "档期信息",
      coupons_id: coupon.awdId,
      is_manual: "否",
      coupon_name: coupon?.dcTemplateVoucherName,
      coupon_amount: coupon?.quotaAmount,
      btn_name: "领券",
    });
  };

  const onUseCoupon = (coupon: CouponModel) => {
    console.log("去使用！", coupon.awdId);
    const { isOldCoupon, couponId, voucherNo } = coupon;
    const params = isOldCoupon ? { couponId } : { voucherNo };
    queryJumpTypeServ(params).then((res) => {
      const { success, data, message } = res || {};
      if (success) {
        const {
          jumpType,
          couponId: _couponId,
          voucherNo: _voucherNo,
          activityId,
          awdInstanceId,
        } = data || {};
        setPopupTitle("");
        switch (jumpType) {
          case "productDetail":
            navigate2Product({
              activitySpuId: data.activitySpuId,
              activityId: data.activityId,
              selectSkuId: data.selectSkuId,
            });
            break;
          case "productList":
            navigate2GoodsOfNpieceXfold({
              h5Query: {
                couponId: _couponId,
                voucherNo: _voucherNo,
                awdInstanceId,
              },
            });
            break;
          case "activityDetail":
            setPopupTitle("");
            break;
          case "activityList":
            navigate2OfferOrderList({
              h5Query: isOldCoupon ? { couponId } : { voucherNo },
            });
            break;
          default:
            break;
        }
      } else {
        Toast.show(message || "服务异常，请稍后重试！");
      }
    });
    track.track("resource_click", {
      page_name: "品牌活动",
      tab_name: selectedActivity?.activityAggrTitle,
      tab_id: selectedActivity?.activityId,
      tab_rank: (selectedActivity?.rank ?? 0) + 2,
      merchant_shop_code: selectedActivity?.merchantShopCode,
      activity_id: selectedActivity?.activityId,
      resource_type: "档期信息",
      coupons_id: coupon.awdId,
      btn_name: "去使用",
    });
  };

  const onTargetAmount = (coupon: CouponModel) => {
    console.log("去凑单", coupon.awdId);
    onUseCoupon(coupon);
  };

  const onHideBrand = (hide: boolean) => {
    // 取消隐藏
    if (hide === false) {
      cancleBrandHide(activity.brandId).then((data) => {
        Toast.show("活动已在店铺中显示，用户进店可以看到此品牌活动");
        data && setHideBrandStatus(hide);
      });
    }
    // 隐藏品牌
    if (hide === true) {
      Dialog.confirm({
        title: "隐藏品牌",
        content: "隐藏后，用户进店将看不到此品牌活动，确定吗？",
        className: "dialog_hide",
        onConfirm: () => {
          setBrandHide(activity.brandId).then((data) => {
            Toast.show("活动已隐藏,用户进店将看不到此活动");
            data && setHideBrandStatus(hide);
          });
        },
      });
    }
  };

  const onLicense = () => {
    activity?.activityId && navigate2BusinessLicense(activity.activityId);
  };

  const onCouponList = () => {
    return couponList;
  };

  const getServerTime = (): number => {
    return Date.now();
  };

  return (
    <>
      {activity?.activityId && (
        <div className={styles.activityDescWrap}>
          <div className={styles.activityDescCont}>
            <ActivityMarketingServicesComp
              motivateLocationCode="H5_ScheduleDetail_Marketing_PromoteSales_Motivate"
              discountLocationCode="H5_ScheduleDetail_Marketing_Discount"
              promoteLocationCode="H5_ScheduleDetail_Marketing_PromoteSales"
              serviceLocationCode="H5_ScheduleDetail_Marketing_Service"
              nxCodes={[
                "PROMO_NX_YUAN",
                "PROMO_NX_ZHE",
                "PROMO_YI_KOU_JIA",
                "PROMO_NX_YUAN_YIKOUJIA",
                "PROMO_NX_ZHE_YIKOUJIA",
              ]}
              model={activity}
              showHideBrand={showHideBrand}
              hideBrandStatus={hideBrandStatus}
              onMore={onPopup}
              onHideBrand={onHideBrand}
              onLicense={onLicense}
              onRequestCoupon={onCouponList}
              coupon={activity?.newcomerCouponVO}
              onReceive={onReceiveCouponNewcomer}
              onUse={onUseCouponNewcomer}
              onTargetAmount={onTargetAmount}
              getServerTime={getServerTime}
            />
            <Popup
              visible={!!popupTitle}
              onMaskClick={() => {
                setPopupTitle("");
              }}
              bodyStyle={{
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                minHeight: "40vh",
                zIndex: 10000,
              }}
              maskStyle={{
                background: "rgba(0, 0, 0, 0.7)",
              }}
            >
              {popupInfo && (
                <div
                  className={`${styles.popupContainer} ${popupTitle === "服务" ? styles["servicePopup"] : ""}`}
                >
                  <div className={styles["popupHeader"]}>
                    {popupTitle}
                    <i className={styles["popupClose"]} onClick={() => setPopupTitle("")}></i>
                  </div>
                  <div className={styles["popupContent"]}>
                    <ActivityMarketingServicesMoreComp
                      type={popupInfo.type}
                      message={popupInfo.message}
                      coupons={
                        popupInfo.type === MarketingServicesType.Discount ? couponList : undefined
                      }
                      labelInfos={popupInfo.labelInfos}
                      onReceive={onReceiveCoupon}
                      onUse={onUseCoupon}
                      onTargetAmount={onTargetAmount}
                      style={{ paddingTop: "0" }}
                    />
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </div>
      )}
      {!activity?.activityId &&
        brandData &&
        (brandData.consistentBeginTimeStr || brandData.consistentEndTimeStr) && (
          <ActivityCountDownTimerComp
            startTime={brandData.consistentBeginTimeStr}
            endTime={brandData.consistentEndTimeStr}
            getServerTime={getServerTime}
          />
        )}
    </>
  );
};

export default ActivityDesc;
