import { queryAchievementData } from "@/api/videoShare";
import DatePicker from "@/components/data-picker";
import TopNavigation from "@/components/top-navigation";
import {
  formatDate,
  getCurrentDateTime,
  getZeroDateFromToday,
  todayTimeRange,
} from "@/utils/date-format";
import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { Popup } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AchievementWrap from "../components/achievement";
import styles from "./index.module.less";

interface IProps {
  appStore?: any;
  [key: string]: any;
}
type achievementDataParams = {
  type: 0 | 1 | 2;
  orderCount: string;
  sale: { decimal: string; integer: string };
  commission: { decimal: string; integer: string };
  newBuyerCnt: string;
  oldBuyerCnt: string;
  avgPrice: { decimal: string; integer: string };
};
const dateTypes = [
  { text: "今日", type: "1" },
  { text: "昨日", type: "2" },
  { text: "近7日", type: "3" },
  { text: "近30日", type: "4" },
  { text: "自定义", type: "5", needIcon: true },
];

const explainContent = [
  { text: "视频号分享员渠道总订单数，不含退货、退款、缺货订单", name: "订单数" },
  { text: "视频号分享员渠道总销售额，不含退货、退款、缺货订单", name: "销售额" },
  { text: "视频号分享员的收入，不含退货、退款、缺货订单", name: "预估收入" },
  { text: "仅视频号分享员渠道的下单新客", name: "下单新客" },
  { text: "仅视频号分享员渠道的下单老客", name: "下单老客" },
  {
    text: "销售额/顾客数，视频号分享员渠道的顾客总数不含退货、退款、缺货订单",
    name: "客单价",
  },
];
const PerformanceBoard: React.FC<IProps> = ({ appStore }) => {
  const [search] = useSearchParams();
  const [chosenTimeTab, setChosenTimeTab] = useState(0);
  const [chosenDate, setChosenDate] = useState(getCurrentDateTime().split(" ")[0]);
  const [explainPopVisible, setExplainPopVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [achievementDataList, setAchievementDataList] = useState<achievementDataParams[]>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState("");
  const [distributorCnt, setDistributorCnt] = useState(0);

  const queryAchievement = async (beginTime, endTime) => {
    try {
      const res = await queryAchievementData({ beginTime, endTime });
      setLastUpdateTime(res?.data?.lastUpdateTime);
      setDistributorCnt(Number(res?.data?.distributorCnt));
      setAchievementDataList(res?.data?.list || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimeRange = (type: string) => {
    // 今天
    if (type === "1") {
      return todayTimeRange();
    }
    // 昨天
    if (type === "2") {
      return getZeroDateFromToday(1);
    }
    // 近7日
    if (type === "3") {
      return getZeroDateFromToday(7);
    }
    // 近30日
    if (type === "4") {
      return getZeroDateFromToday(30);
    }
  };
  const getTimeFromDatePicker = (startDate: string, endDate: string) => {
    setChosenTimeTab(4);
    setChosenDate(`${startDate} 至 ${endDate}`);
    queryAchievement(`${startDate + " 00:00:00"}`, `${endDate + " 23:59:59"}`);
    return;
  };
  const getformatDate = (type: string) => {
    if (type === "1") {
      return formatDate(new Date());
    }
    if (type === "2") {
      const now = new Date();
      const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      return formatDate(yesterday);
    }
    if (type === "3") {
      const now = new Date(); // 获取当前时间
      const lastWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6);
      const endString = formatDate(now);
      const startString = formatDate(lastWeek);
      return `${startString} 至 ${endString}`;
    }
    if (type === "4") {
      const now = new Date(); // 获取当前时间
      const lastMonth = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29); // 创建新的Date对象，避免改变now的值
      const endString = formatDate(now);
      const startString = formatDate(lastMonth);
      return `${startString} 至 ${endString}`;
    }
    return formatDate(new Date());
  };
  const changeDateType = (type: string) => {
    if (type !== "5") {
      setChosenTimeTab(Number(type) - 1);
      const dateFormatted = getformatDate(type);
      const timeRange = getTimeRange(type);
      if (timeRange) {
        setChosenDate(dateFormatted);
        queryAchievement(timeRange[0], timeRange[1]);
      }
    } else {
      setDatePickerVisible(true);
    }
  };
  const initData = async () => {
    if (platform.isApp) {
      const res: any = await AKJS.info.account();
      appStore.token = res.data.token;
      appStore.channel = "akcApp";
    } else if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
    }
    changeDateType("1");
  };
  useEffect(() => {
    initData();
    document.title = "业绩看板";
  }, []);
  return (
    <>
      {(platform.isH5 || platform.isWxH5) && (
        <div>
          <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
        </div>
      )}
      <div className={styles["container"]}>
        <AchievementWrap
          achievementDataList={achievementDataList}
          distributorCnt={distributorCnt}
          lastUpdateTime={lastUpdateTime}
        >
          <div className={styles.title}>
            业绩看板
            <div
              className={styles.explain}
              onClick={() => {
                setExplainPopVisible(true);
              }}
            >
              名词解释
              <img src="https://akim-oss.aikucun.com/05e544ccdbe4b51518566553673bfd82ba3bd985_1706757674856_72.png" />
            </div>
          </div>
          <div className={styles.select_data}>
            {dateTypes.map((item, index) => {
              return (
                <div
                  className={` ${styles["normal"]} 
                ${chosenTimeTab === index ? styles["active"] : null}
                ${item.needIcon ? styles["needIcon"] : null}
                `}
                  key={index}
                  onClick={() => {
                    changeDateType(item.type);
                  }}
                >
                  {item.text}
                  {item.needIcon && (
                    <img
                      src={
                        chosenTimeTab === 4
                          ? "https://akim-oss.aikucun.com/54da1a71994e6a977b84415aeb201d86e36f46e5_1707049613947_43.png"
                          : "https://akim-oss.aikucun.com/54da1a71994e6a977b84415aeb201d86e36f46e5_1706704566998_31.png"
                      }
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.select_tip} style={{ marginLeft: chosenTimeTab === 4 ? 65 : 0 }}>
            统计时间：{chosenDate}
          </div>
        </AchievementWrap>

        <DatePicker
          popVisible={datePickerVisible}
          close={() => {
            setDatePickerVisible(false);
          }}
          getTimeRange={getTimeFromDatePicker}
        />
      </div>
      {/* 名词解释popup */}
      <Popup
        visible={explainPopVisible}
        onMaskClick={() => {
          setExplainPopVisible(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          zIndex: 10000,
        }}
      >
        <div className={styles.explainPopup}>
          <div className={styles.title}>
            名词解释
            <img
              onClick={() => {
                setExplainPopVisible(false);
              }}
              src="https://akim-oss.aikucun.com/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1706759551207_66.png"
            />
          </div>
          {explainContent.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.text}>{item.text}</div>
              </div>
            );
          })}
          <div className={styles.bottom_tip}>
            <span>* </span>
            数据自2023年11月21日起统计，数据因顾客取消订单、售后退款而实时扣减，故不同时间段内，数据存在波动。
          </div>
        </div>
      </Popup>
    </>
  );
};
export default inject("appStore")(observer(PerformanceBoard));
