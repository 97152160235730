import {
  ActivityTaskItem,
  ActivityTaskParams,
  CouponItem as CouponItemDs,
  receiveRedPacket,
} from "@/api/rewardCenter";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import DailyReward from "@/pages/daily-reward";
import exposure from "@/utils/expose/exposure";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import Track from "@/utils/track";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import { Image, InfiniteScroll, SafeArea } from "antd-mobile";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ActivityTask from "./components/activity-task/activity-task";
import RewardBanner from "./components/banner";
import CouponItem from "./components/coupon-item/coupon-item";
import Empty from "./components/Empty";
import RewardFilters from "./components/filters";
import Follow from "./components/Follow/Follow";
import PriorityDisplay from "./components/PriorityDisplay";
import RedPack from "./components/red-pack/red-pack";
import RedpacketModal from "./components/red-pack/redpacket";
import RewardTabs from "./components/reward-tabs/index";
import RewardSearchHeader from "./components/search-header";
import ToExChangeTip from "./components/ToExchangeTip/ToExchangeTip";
import useRewardCenterData from "./hooks/useRewardCenterData";
import styles from "./index.module.less";

const tabs = ({
  hasCoupon,
}): {
  name: React.ReactNode;
  value: number;
  key: string;
}[] => [
  {
    key: "limitedTimeList",
    name: "限时激励",
    value: 1,
  },
  {
    key: "dailyReward",
    name: "日日奖",
    value: 5,
  },
  {
    key: "benefitsList",
    name: (
      <>
        <img
          style={{ width: pxtorem(32) }}
          src="https://akim-oss.aikucun.com/f970c786f5bee690e81c3e66db31e101175fded7_1713764369498_21.png"
        />
      </>
    ),
    value: 2,
  },
  {
    key: "redPackList",
    name: "转发",
    value: 4,
  },
  ...(hasCoupon
    ? [
        {
          key: "couponAwdList",
          name: "分享券",
          value: 3,
        },
      ]
    : []),
];

const subTabs = [
  {
    key: "WINNING",
    name: "已中奖",
    value: "WINNING",
  },
  {
    key: "NOT_WINNING_HAS_GMV",
    name: "未中奖",
    value: "NOT_WINNING_HAS_GMV",
  },
  {
    key: "NOT_WINNING_NO_GMV",
    name: "无业绩",
    value: "NOT_WINNING_NO_GMV",
  }
];
const subTabs2 = [
  {
    key: "HAS_GMV",
    name: "有业绩",
    value: "HAS_GMV",
  },
  {
    key: "NOT_WINNING_NO_GMV",
    name: "无业绩",
    value: "NOT_WINNING_NO_GMV",
  }
];

const typeNameMap = {
  "FULL_RETURN": "满返",
  "GIFT_RETURN": "满赠",
  "RANK": "PK",
};
const status20TipsMap = (key, num, type) => ({
  "HAS_GMV": <>共 <span className={styles.tipsNum}>{num}</span> 场产生业绩的{typeNameMap[type] || ""}活动，销售数据每隔一小时刷新一次</>,
  "NOT_WINNING_NO_GMV": <>共 <span className={styles.tipsNum}>{num}</span> 场未销售的{typeNameMap[type] || ""}活动，销售数据每隔一小时刷新一次</>
})[key];
const status40TipsMap = (key, num, type) => ({
  "WINNING": <>共 <span className={styles.tipsNum}>{num}</span> 场中奖的{typeNameMap[type] || ""}活动</>,
  "NOT_WINNING_HAS_GMV": <>共 <span className={styles.tipsNum}>{num}</span> 场未中奖的{typeNameMap[type] || ""}活动</>,
  "NOT_WINNING_NO_GMV": <>共 <span className={styles.tipsNum}>{num}</span> 场无销售业绩的{typeNameMap[type] || ""}活动</>
})[key];
const tipsMap = (key, num, type, key2) => ({
  "10": <>共 <span className={styles.tipsNum}>{num}</span> 场预热中的{typeNameMap[type] || ""}活动，活动开始后无需报名直接售卖即可参加</>,
  "20": status20TipsMap(key2, num, type),
  "30": <>共 <span className={styles.tipsNum}>{num}</span> 场处于售后期中的{typeNameMap[type] || ""}活动，销售数据每隔一小时刷新一次；若您的顾客产生取消、退货等售后订单可能影响业绩哦</>,
  "40": status40TipsMap(key2, num, type),
})[key];

//加载更多
const InfiniteScrollContent = ({ hasMore, list }: { hasMore?: boolean; list: any[] }) => {
  return (
    <div className={styles.infinite}>
      {hasMore ? (
        <div className={styles.loadingMore}>
          <Image src={loadingPng} width={pxtorem(20)}></Image>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          {list?.length > 0 && (
            <>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadLeft}></Image>
              <div style={{ color: "rgba(204, 204, 204, 1)" }}>已经到底了</div>
              <Image style={{ width: pxtorem(26), height: pxtorem(2) }} src={loadRight}></Image>
            </>
          )}
        </div>
      )}
    </div>
  );
};

interface Params extends ActivityTaskParams {
  refreshAll?: boolean;
}

const RewardCenter: React.FC = () => {
  document.title = "激励中心";
  const track = Track();
  const [search] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [redpacketInfo, setRedpacketInfo] = useState<any>({});

  const [query, setQuery] = useState<Params>({
    shopCode: (search.get("shopCode")
      ? search.get("shopCode")
      : "") as ActivityTaskParams["shopCode"],
    searchType: (["1", "2", "3", "4", "5"].includes(search.get("searchType") as string)
      ? Number(search.get("searchType"))
      : 1) as ActivityTaskParams["searchType"],
    promoActivityType: (["FULL_RETURN", "RANK", "GIFT_RETURN"].includes(
      search.get("promoActivityType") as string,
    )
      ? search.get("promoActivityType")
      : "FULL_RETURN") as ActivityTaskParams["promoActivityType"],
    actSellRebateStatus: 10,
    taskWinningStatus: undefined,
    isDraw: undefined,
    shopType: 1,
    date: 0,
    refreshAll: true,
  });
  const {
    list,
    couponList,
    activityList,
    numInfo,
    banner,
    redPacketSummary,
    loading,
    hasCoupon,
    hasMore,
    loadMore,
    getRedPacketSummary,
  } = useRewardCenterData({
    query,
    setQuery,
  });

  const handleSetQuery = (value: any, key?: keyof ActivityTaskParams) => {
    let data = query;
    if (key) {
      data = { ...data, [key]: value };
    } else {
      data = { ...data, ...value };
    }
    setQuery(data);
    console.log("query change:", data);
    return data;
  };

  const handleTabChange = (value: any) => {
    setOpen(false);
    if (value === 1 || value === 2) {
      handleSetQuery({
        searchType: value,
        promoActivityType: query.promoActivityType || "FULL_RETURN",
        actSellRebateStatus: 10,
        isDraw: undefined,
        shopType: 1,
        beginDate: undefined,
        date: 0,
        endDate: undefined,
        taskWinningStatus: undefined,
        refreshAll: true,
      });
    } else {
      handleSetQuery({
        searchType: value,
        promoActivityType: undefined,
        refreshAll: true,
      });
    }
  };
  const handleSubTabChange = (value: any) => {
    handleSetQuery({
      taskWinningStatus: value,
      refreshAll: false,
    });
  };
  const handleFilterChange = (value: ActivityTaskParams, refreshAll?: boolean) => {
    handleSetQuery({
      ...value,
      refreshAll: !!refreshAll,
    });
  };
  const handleCloseFilter = () => {
    if (open) {
      setOpen(false);
    }
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  useEffect(() => {
    setTrackData({
      page_name: "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
    });
    track.track("$pageview", {
      page_name: "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
    });
  }, []);
  // 埋点曝光元素记录
  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      const resList = query.searchType === 3 ? couponList : list;
      for (let i = 0; i < resList.length; i++) {
        let id = "cnt-";
        if (query.searchType === 3) {
          const item = resList[i] as CouponItemDs;
          id += item.couponId || item.voucherNo;
        } else {
          id += (resList[i] as ActivityTaskItem).actId;
        }
        const dom = document.getElementById(id);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [query.searchType, list, couponList]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === "visible";
      let haveShareActivityId = localStorage.getItem("rewardCenter:shareActivity");
      if (isVisible) {
        console.log("页面重新显示");
      }
      if (isVisible && haveShareActivityId) {
        console.log("页面重新显示，有分享活动行为，开始执行领红包逻辑");
        localStorage.removeItem("rewardCenter:shareActivity");
        if (platform.isApp) {
          console.log("页面重新显示，有分享活动行为，app端跳过领红包逻辑");
          getRedPacketSummary();
          return;
        }
        receiveRedPacket({ activityId: haveShareActivityId }).then((data) => {
          console.log("领取红包结果", data);
          if (data?.redPacketAmount) {
            setRedpacketInfo(data);
            setVisible(true);
          } else {
            setRedpacketInfo({});
          }
          getRedPacketSummary();
        });
      }
    };
    window.akc_onAppear = handleVisibilityChange;
    document.addEventListener("visibilitychange", handleVisibilityChange);
    // 页面初始化移除分享标示
    localStorage.removeItem("rewardCenter:shareActivity");
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const Content = () => {
    return (
      <div className={styles.content}>
        {/* 红包 */}
        {query.searchType === 4 && (
          <RedPack summary={redPacketSummary} activityList={activityList} />
        )}
        {/* 分享券 */}
        {query.searchType === 3 &&
          couponList.map((item) => <CouponItem key={item.couponId} data={item} />)}
        {/* 限时激励/权益 */}
        {[1, 2].includes(query.searchType as number) &&
          list.map((item, index) => (
            <ActivityTask
              key={item.actId}
              index={index}
              equity={query.searchType === 2}
              data={item}
            />
          ))}

        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0}>
          <InfiniteScrollContent
            list={
              query.searchType === 3 ? couponList : query.searchType === 4 ? activityList : list
            }
            hasMore={hasMore}
          />
        </InfiniteScroll>
        {!loading &&
        (([1, 2].includes(query.searchType as number) && !list.length) ||
          (query.searchType === 3 && !couponList.length)) ? (
          <Empty>
            {query.searchType === 3
              ? "抱歉，暂无分享券"
              : query.searchType === 4
                ? "抱歉，暂无红包"
                : "抱歉，暂无活动"}
          </Empty>
        ) : (
          ""
        )}
        <SafeArea position="bottom" />
      </div>
    );
  };

  return (
    <div className={styles.container + " " + (open ? styles.mask : "")} onClick={handleCloseFilter}>
      {/* 优先级组件, 按顺序加载组件，优先展示第一个符合条件的组件 */}
      <ToExChangeTip />
      {!search.get("shopCode") && query.searchType !== 5 ? <RewardSearchHeader /> : ""}
      <RewardTabs tabs={tabs({ hasCoupon })} value={query.searchType} onChange={handleTabChange} />
      {[1, 2].includes(query.searchType as number) && (
        <>
          <RewardBanner banner={banner} />
          <div className={styles.followWrap}>
            <Follow />
          </div>
          <RewardFilters
            value={query}
            numInfo={numInfo}
            open={open}
            toggleFilter={setOpen}
            onFilter={handleFilterChange}
          />
        </>
      )}
      {[1, 2].includes(query.searchType as number) && (<>
        <div className={styles.contentTips}>*{tipsMap(query.actSellRebateStatus, numInfo.total || 0, query.promoActivityType, query.taskWinningStatus)}</div>
        {[20, 40].includes(query.actSellRebateStatus as number) ? <RewardTabs style={{marginTop: pxtorem(8)}} tabs={query.actSellRebateStatus === 40 ? subTabs : subTabs2} type="mini" value={query.taskWinningStatus} onChange={handleSubTabChange} /> : ""}
      </>)}
      {query.searchType === 5 ? <DailyReward /> : <Content />}
      <RedpacketModal visible={visible} visibleChange={setVisible} data={redpacketInfo} />
    </div>
  );
};
export default RewardCenter;
