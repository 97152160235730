import { pxtorem } from "@/utils/pxtorem";
import "cropperjs/dist/cropper.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import { BoxType, NaturalBoxType, RenderBoxType } from "../../type";
import "./cropper.less";

interface CropperProps {
  imageSrc: string;
  position: NaturalBoxType;
  maxHeight?: number;
  cropData: Array<any>;
  onCropBoxChange?: (data: any, NaturaData: any) => void;
  handleCropBoxChange?: (data: any, NaturaData: any) => void;
  onCropend?: (data: any, NaturaData: any) => void;
}

// 渲染尺寸转图片真实尺寸
const render2naturalSize = (_position: BoxType, _scale: number) => {
  return Object.fromEntries(
    Object.entries(_position).map(([key, value]) => [key, value * _scale || 1]),
  );
};

// 图片真实尺寸转渲染尺寸
const natural2renderSize = (_position: BoxType, _scale: number) => {
  return Object.fromEntries(
    Object.entries(_position).map(([key, value]) => [key, value / _scale || 1]),
  );
};

const defaultRenderBox = { left: 0, top: 0, width: 0, height: 0 };
const defaultNaturalBox = { x: 0, y: 0, width: 0, height: 0 };

export const MyCropper: React.FC<CropperProps> = ({
  imageSrc,
  position = defaultNaturalBox,
  maxHeight = 550,
  cropData = [],
  onCropBoxChange,
  handleCropBoxChange,
  onCropend,
}) => {
  const [image] = useState(imageSrc);
  const imgScale = useRef(1);
  const [renderScale, setRenderScale] = useState(1);
  const cropperRef = useRef<ReactCropperElement>();
  const outerWrapRef = useRef<HTMLDivElement | null>(null);
  const positionRef = useRef<NaturalBoxType>();
  // 父级容器的ref
  const startYRef = useRef(0); // 用于记录起始Y轴位置
  const isOnTouch = useRef(false);

  // 获取原始图片尺寸 计算与渲染尺寸的比例
  useLayoutEffect(() => {
    const image = new Image();
    image.onload = (e) => {
      const img = e.target as HTMLImageElement;
      // 设置缩放比例
      imgScale.current = img.naturalWidth / window.innerWidth;
      setRenderScale(imgScale.current);
    };
    image.src = imageSrc;
  }, []);

  // 获取裁剪框位置数据 { left, top, width, height }
  const getCropBoxData = (): RenderBoxType => {
    return (cropperRef.current?.cropper.getCropBoxData?.() as RenderBoxType) || defaultRenderBox;
  };

  // 设置裁剪框位置数据
  const setCropBoxData = (_position: RenderBoxType) => {
    cropperRef.current?.cropper.setCropBoxData?.(_position);
  };

  const prePosition = useRef({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const isBoxMoveRef = useRef(false);
  const isBoxMove = (p) => JSON.stringify(prePosition.current) !== JSON.stringify(p);

  const onReady = () => {
    const { x, y, width, height } = positionRef.current as NaturalBoxType;
    // 计算与渲染尺寸的比例
    imgScale.current =
      ((cropperRef.current?.cropper?.getImageData() as any) || {}).naturalWidth / window.innerWidth;
    setRenderScale(imgScale.current);
    setCropBoxData(
      natural2renderSize(
        { left: x, top: y, width, height },
        imgScale.current,
      ) as unknown as RenderBoxType,
    );
  };

  useEffect(() => {
    const { x, y, width, height } = position;
    // 存储最新的 position
    positionRef.current = position;

    setCropBoxData(
      natural2renderSize(
        { left: x, top: y, width, height },
        renderScale,
      ) as unknown as RenderBoxType,
    );
  }, [position, renderScale]);

  // 判断手势或鼠标点击点是否在裁剪框外
  const isOutsideCropBox = (clientX: number, clientY: number, margin = 20) => {
    const cropBoxData = getCropBoxData();
    if (!cropBoxData) {
      return false;
    }
    const { left, top, width, height } = cropBoxData;

    // 增加冗余范围
    return (
      clientX < left - margin || // 左边界
      clientX > left + width + margin || // 右边界
      clientY < top - margin || // 上边界
      clientY > top + height + margin // 下边界
    );
  };

  // 手指或鼠标开始触摸
  const handleTouchStart = (e) => {
    const { clientY } = e.touches?.[0] || {}; // 获取触摸起始位置的Y轴
    startYRef.current = clientY;
    isOnTouch.current = true;
  };

  const handleMouseDown = (e) => {
    const { clientY } = e.touches?.[0] || {}; // 获取触摸起始位置的Y轴
    startYRef.current = clientY;
  };

  // 控制滚动
  const scrollContainer = (currentY) => {
    const container = outerWrapRef.current; // 父级容器
    const distanceY = startYRef.current - currentY; // 计算移动的距离

    // 更新父级容器的滚动条位置，实现滚动
    if (container) {
      container.scrollTop += distanceY;
    }

    // 更新起始Y轴位置
    startYRef.current = currentY;
  };

  // 手指或鼠标移动
  const handleTouchMove = (e) => {
    const { clientX = 0, clientY = 0 } = e.touches?.[0] || {}; // 获取移动过程中的Y轴位置

    // 计算触摸点相对于 cropper-box 的位置, 滚动条位置
    const scrollTop = outerWrapRef.current?.scrollTop;
    const touchY = clientY + scrollTop; // 相对Y位置

    if (isOutsideCropBox(clientX, touchY)) {
      scrollContainer(clientY);
    }

    const { left, top, width, height } = getCropBoxData();

    isBoxMoveRef.current = isBoxMove({ left, top, width, height });
    // 存储位置
    prePosition.current = { left, top, width, height };
  };

  const onCrop = (v) => {
    const { left, top, width, height } = getCropBoxData();

    onCropBoxChange?.(
      { x: left, y: top, width, height },
      render2naturalSize({ x: left, y: top, width, height }, renderScale),
    );

    if (isOnTouch.current) {
      handleCropBoxChange?.(
        { x: left, y: top, width, height },
        render2naturalSize({ x: left, y: top, width, height }, renderScale),
      );
    }
  };

  const onCropperCropend = () => {
    const { left, top, width, height } = getCropBoxData();
    if (isBoxMoveRef.current) {
      onCropend?.(
        { x: left, y: top, width, height },
        render2naturalSize({ x: left, y: top, width, height }, renderScale),
      );
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    const containerHeight = (outerWrapRef.current as any).clientHeight; // 容器高度
    const contentHeight = (cropperRef.current as any).scrollHeight; // 内部内容的高度

    if (contentHeight > containerHeight) {
      setIsOverflowing(true); // 如果内部高度大于容器高度，设置溢出状态为 true
    } else {
      setIsOverflowing(false);
    }
  });

  return (
    <div
      style={
        isOverflowing ? {} : { display: "flex", alignItems: "center", height: `${maxHeight}px` }
      }
    >
      <div
        className="cropper-wrap"
        style={{ maxHeight: `${maxHeight}px`, width: "100%", overflowY: "auto" }} // 限制高度，并允许滚动
        onTouchStart={handleTouchStart}
        onMouseDown={handleMouseDown}
        onTouchEnd={() => {
          isOnTouch.current = false;
          const { left, top, width, height } = getCropBoxData();

          isBoxMoveRef.current = isBoxMove({ left, top, width, height });
          // 存储位置
          prePosition.current = { left, top, width, height };
        }}
        ref={outerWrapRef} // 父级容器的引用
        onTouchMove={handleTouchMove} // 监听触摸移动
        onMouseMove={handleTouchMove} // 监听鼠标移动
      >
        <Cropper
          key={renderScale}
          className="cropper-box"
          ref={cropperRef as any}
          style={{ height: "100%", width: "100vw" }}
          zoomable={false}
          scalable={false}
          center={false}
          src={image}
          viewMode={2}
          dragMode={"none"} // 动态设置 dragMode
          minCropBoxHeight={25}
          minCropBoxWidth={25}
          background={false}
          responsive={true}
          checkOrientation={false}
          guides={false}
          crop={onCrop}
          cropend={onCropperCropend}
          ready={onReady}
        />
        <div className="image-container" style={{ display: cropData.length ? "none" : "block" }}>
          <img src={imageSrc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(MyCropper);
