import AKJS from "@akc/akjs";
import { platform } from "../platform";

const send = (props: { name: string; param?: any }) => {
  if (!platform.isApp) {
    return;
  }
  AKJS.action.dispatch("natEventBusFire", {
    eventName: props.name,
    param: props.param,
  });
};

export const event = {
  send,
  // 监听事件暂未实现
};
