import AKJS from "@akc/akjs";

export enum ShareScene {
  //微信好友/群
  wxSession = 0,
  //微信朋友圈
  wxTimeline = 1,
  //微信收藏
  wxFavorite = 2,
  //系统分享
  system = 3,
}

export interface ShareLinkParams {
  title?: string;
  description?: string;
  logoUrl?: string;
  webPageUrl?: string;
  scene?: ShareScene;
}

// 分享链接
export const showShareUrlDialog = (shareLinkParams?: ShareLinkParams) => {
  if (!shareLinkParams || !shareLinkParams.title) {
    console.log("分享标题为空");
    return;
  }
  AKJS.action.dispatch("natRouterPresentFloating", {
    target: 2,
    pageName: "forwardFloatingDialog",
    params: {
      src: "CARD",
      bizInfo: JSON.stringify({
        shareCardInfo: {
          urlShareTitle: shareLinkParams?.title,
          urlShareDesc: shareLinkParams?.description,
          urlShareUrl: shareLinkParams?.webPageUrl,
          urlShareImg: shareLinkParams?.logoUrl,
        },
      }),
    },
  });
};
