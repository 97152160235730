import { useCommonContext } from "@/utils/context/common";
import { Grid, Toast } from "antd-mobile";
import { default as React, useState } from "react";
import SharerProductCard from "../productCard";
import ShareCodeModal from "../shareCodeModal";
import styles from "./index.module.less";

const FORECAST_TITLE_IMG =
  "https://akim-oss.aikucun.com/4fa08afaa95443f120bac2897c147ffe706a8719_1709100368479_65.png";
const AVATAR_URL =
  "https://akim-oss.aikucun.com/25b92ed62e9f188307a0284671df91e9678571cc_1702637476577_65.png";

interface IProps {
  item: any;
  index: number;
  name: string;
}
const LiveContent: React.FC<IProps> = ({ item, index, name }) => {
  const [shareCodeModalVisible, setShareCodeModalVisible] = useState<boolean>(false);
  const containerStyleMap = ["container__living", "container__forecast"];
  const { track } = useCommonContext();
  const handleClickShareCode = () => {
    if (item.qrCodeUrl) {
      setShareCodeModalVisible(true);
      //分享码弹窗曝光
      if (track.track) {
        track.track("resource_expose", {
          resource_type: "分享码弹框",
          resource_name: "分享码弹框",
          module: "分享码弹框",
        });
      }
    } else {
      Toast.show("无分享码");
    }
    //查看分享码点击
    if (track.track) {
      track.track("resource_click", {
        page_name: "分享员详情页",
        resource_type: "视频号直播预览",
        resource_name: name,
        resource_rank: index + 1,
        module: "绑定视频号列表",
        btn_name: "查看分享码",
      });
    }
  };
  const LivingHeader = () => {
    return (
      <div className={styles["living-header"]}>
        <div className={styles["living-header__title"]}>
          <div className={styles["living__icon"]}>
            <div className={styles["item__list"]}>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className={styles["icon__item"]}></div>
              ))}
            </div>
          </div>
          <div className={styles["title__text"]}>直播中</div>
        </div>
        <div className={styles["living-header__code"]} onClick={handleClickShareCode}>
          查看直播码
        </div>
      </div>
    );
  };
  const ForeCastHeader = () => {
    return (
      <div className={styles["forecast-header"]}>
        <img src={FORECAST_TITLE_IMG} className={styles["title"]} />
        <div className={styles["time"]}>开播时间 {item.startTime}</div>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles["container"]} ${styles[containerStyleMap[item.type - 1]]}`}>
        {item.type === 1 && <LivingHeader />}
        {item.type === 2 && <ForeCastHeader />}
        <div className={styles["live-desc__content"]}>
          <div className={styles["content__logo"]}>
            <img
              src={item?.activityListResp?.brandLogoUrl || AVATAR_URL}
              alt="活动主logo"
              className={`${styles["logo__img"]} ${
                item.type === 1 ? styles["living__bd"] : styles["forecast__bd"]
              }`}
            />
          </div>
          {item.desc}
        </div>
        <div className={styles["live-product"]}>
          <Grid columns={3} gap={8}>
            {item?.activityListResp?.channelSharerProductListVOS?.map((product, index) => {
              return (
                <Grid.Item key={index}>
                  <SharerProductCard {...product} />
                </Grid.Item>
              );
            })}
          </Grid>
        </div>
      </div>
      {shareCodeModalVisible && (
        <ShareCodeModal
          liveQrCode={item.qrCodeUrl}
          setShareCodeModalVisible={setShareCodeModalVisible}
        />
      )}
    </>
  );
};
export default LiveContent;
