import { getCouponCount } from "@/api/coupon/index";
import {
  getConsultShopOwner,
  getWeChatAccountInfoUrlByUserId,
  launchConsultation,
} from "@/api/my/index";
import { getShopBaseDetail } from "@/api/my/shop";
import Clickable from "@/components/clickable";
import Beianhao from "@/pages/my/components/common-beian";
import Logout from "@/pages/my/components/common-logout";
import FollowWeChat from "@/pages/personal/components/follow-wechat";
import OrderCenter from "@/pages/personal/components/order-center";
import { getCSBase } from "@/utils/service";
import useNavigation from "@/utils/useNavigate";
import { Mask } from "antd-mobile";
import Cookie from "js-cookie";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { UseUserInfoContext } from "../../hooks/useUserInfo";
import AdminHeader from "../admin-header";
import styles from "./index.module.less";

type ItemModel = {
  title: string;
  key: string;
  path?: string;
  icon?: string;
};

const Admin: React.FC = () => {
  const { userInfo } = UseUserInfoContext();
  const [searchParams] = useSearchParams();
  const { navigate2MyItem } = useNavigation();

  const shopId = searchParams.get("shopId") ?? "";
  const distributorId = searchParams.get("distributorId") ?? "";
  const [shopInfo, setShopInfo] = useState<any>({});
  const [shopDesc, setShopDesc] = useState<string>("");
  const [couponCount, setCouponCount] = useState<number>(0);
  const [isFollow, setIsFollow] = useState<boolean>(true);
  const [showBeianhao, setShowBeianhao] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);

  const group1: Array<ItemModel> = [
    {
      title: "收货地址",
      key: "addressItem",
      path: "/#/addressList",
    },
  ];

  const group2: Array<ItemModel> = [
    {
      title: "转发设置",
      key: "transferSettingItem",
      path: "/mx-shop-micro/share-setting",
    },
    {
      title: "店铺装扮",
      key: "decorationItem",
      path: "/#/decoration",
    },
    {
      title: "一键转链",
      key: "transferLinkItem",
      path: "/#/tools/transfer-link",
    },
  ];

  const group3: Array<ItemModel> = [
    {
      title: "隐藏设置",
      key: "hideBrandItem",
      path: "/mx-shop-micro/hide-setting",
    },
    {
      key: "couponItem",
      title: "我的优惠券",
      path: "/#/coupon",
    },
    {
      key: "browseHistoryItem",
      title: "浏览足迹",
      path: "/mx-shop-micro/track",
    },
    {
      title: "品牌关注",
      key: "brandFollowItem",
      path: "/#/my-focus",
    },
  ];

  const group4: Array<ItemModel> = [
    {
      title: "联系客服",
      key: "serviceItem",
    },
    {
      title: "关于我们",
      key: "legalProtocolItem",
      path: "/#/legal-protocol",
    },
    {
      title: "资质信息",
      key: "qualificationItem",
    },
  ];

  const jumpToCS = async (queryObj) => {
    const webChannel = 3;
    const query = {
      ...queryObj,
      webChannel,
      shopId,
      distributorId,
    };
    const queryStr = Object.keys(query).reduce((res, i) => `${res}${i}=${query[i]}&`, "?");
    const auditChannel = 2;
    let sourceQueryStr = `shopId=${shopId}&source=3&auditChannel=${auditChannel}&distributorId=${distributorId}`;
    const msUserCode = Cookie.get("msUserCode");
    if (msUserCode) {
      sourceQueryStr += `&userId=${msUserCode}`;
    }
    const base = await getCSBase({
      webChannel,
      locationType: queryObj.locationType,
      merchantCode: queryObj.merchantCode,
    });
    window.location.href = `${base}${queryStr}sourceOrigin=${window.encodeURIComponent(
      window.location.origin,
    )}&sourceQueryStr=${window.encodeURIComponent(sourceQueryStr)}`;
  };

  const onServiceToCS = async () => {
    const query = { locationType: 6 };
    const queryObj: any = { locationType: 6 };
    const res: any = await launchConsultation(query);
    if (res && res.success && res.data) {
      const { merchantCode } = res.data;
      if (merchantCode) {
        queryObj.merchantCode = merchantCode;
      }
      jumpToCS(queryObj);
    }
  };

  const onItemClick = (item) => {
    if (item.path) {
      navigate2MyItem(item.path);
      return;
    }
    if (item.key === "serviceItem") {
      onServiceToCS();
      return;
    }
    if (item.key === "qualificationItem") {
      setShowBeianhao(!showBeianhao);
      setTimeout(() => {
        const footerDom = document.getElementById("footer");
        footerDom?.scrollIntoView({ behavior: "smooth" });
      }, 100);
      return;
    }
  };

  const queryConsultShopOwner = async () => {
    const res = await getConsultShopOwner();
    if (res?.data?.shopDesc) {
      setShopDesc(res.data.shopDesc);
    }
  };

  const onCloseGuide = () => {
    setAddressVisible(false);
    const dom = document.getElementById("tab-bar-mask");
    const dom2 = document.getElementById("tab-bar-bottom");
    if (dom && dom2) {
      dom.style.width = "0";
      dom.style.height = "0";
      dom2.style.borderTop = "1px solid rgba(34, 34, 34, 0.08);";
    }
  };

  useEffect(() => {
    queryConsultShopOwner();
    getCouponCount().then((count) => setCouponCount(Number(count)));
    getWeChatAccountInfoUrlByUserId().then((res) => setIsFollow(!!res?.data?.isFollow));
    getShopBaseDetail().then((data) => setShopInfo(data));
  }, []);

  useActivate(() => {
    getCouponCount().then((count) => setCouponCount(Number(count)));
    getWeChatAccountInfoUrlByUserId().then((res) => setIsFollow(!!res?.data?.isFollow));
  });

  const GroupItem = ({ item }) => (
    <>
      <Clickable
        className={styles.item}
        onClick={() => onItemClick(item)}
        trackData={{
          page_name: "我的",
          btn_name: item.title,
        }}
      >
        <div className={styles.itemLeft}>{item.title}</div>
        <div className={styles.itemRight}>
          {item.key === "couponItem" && (
            <span className={styles.itemRightCount}>{couponCount || 0}张</span>
          )}
          {item.key !== "qualificationItem" && <i className={styles.iconRight} />}
          {item.key === "qualificationItem" && (
            <i className={`${styles.iconRight} ${showBeianhao ? styles.rotateUp : ""}`} />
          )}
        </div>
      </Clickable>
      {/* 资质信息 */}
      {item.key === "qualificationItem" && showBeianhao && <Beianhao />}
    </>
  );

  return (
    <div className={styles.adminContainer}>
      <AdminHeader shopInfo={shopInfo} shopDesc={shopDesc} userInfo={userInfo} />
      {!isFollow && (
        <div className={styles.followWeChat}>
          <FollowWeChat />
        </div>
      )}
      <OrderCenter onAddressGuide={() => setAddressVisible(true)} />
      <div style={{ position: "relative", zIndex: addressVisible ? 11000 : 10 }}>
        <div className={styles.group}>
          {group1.map((item, index) => (
            <GroupItem item={item} key={index} />
          ))}
        </div>
        {addressVisible && (
          <>
            <div className={styles["address-item-guide__line"]}></div>
            <div className={styles["address-item-guide__txt"]}>收货地址移到这里了哦～</div>
            <div className={styles["address-item-guide__button"]} onClick={onCloseGuide}>
              我知道了
            </div>
          </>
        )}
      </div>
      <Mask
        visible={addressVisible}
        disableBodyScroll={true}
        opacity={0.7}
        style={{ "--z-index": "10100" }}
        onMaskClick={onCloseGuide}
      />
      <div className={styles.group}>
        {group2.map((item, index) => (
          <GroupItem item={item} key={index} />
        ))}
      </div>
      <div className={styles.group}>
        {group3.map((item, index) => (
          <GroupItem item={item} key={index} />
        ))}
      </div>
      <div className={styles.group}>
        {group4.map((item, index) => (
          <GroupItem item={item} key={index} />
        ))}
      </div>
      <Logout />
      <div className={styles.footer} id="footer">
        <a href="https://beian.miit.gov.cn/">沪ICP备18031936号</a>
      </div>
    </div>
  );
};

export default Admin;
