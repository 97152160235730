import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import { Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { getUrlParam } from "../../../../utils/url-parse";
import {
  useConstructParams,
  useResetFilter,
  useSelectedBrandList,
  useShownBrandList,
  useShownCategoryList,
  useShownChannelList,
  useShownParms,
  useShownServiceTagList,
  useShownTypeList,
  useToggleBrand,
  useToggleCategory,
  useToggleChannel,
  useToggleServiceTag,
  useToggleType,
} from "../../hooks/useFilterOptions";
import AllBrand from "./AllBrand";
import styles from "./FilterBoard.module.less";

type Props = {
  onFilterChange?: (any) => void;
};

const FilterBoard: React.FC<Props> = ({ onFilterChange }) => {
  const shownServiceTagList = useShownServiceTagList();
  const toggleServiceTag = useToggleServiceTag();
  const shownTypeList = useShownTypeList();
  const toggleType = useToggleType();
  const showCategoryList = useShownCategoryList();
  const toggleCategory = useToggleCategory();
  const showChannelList = useShownChannelList();
  const toggleChannel = useToggleChannel();
  const shownBrandList = useShownBrandList();
  const toggleBrand = useToggleBrand();
  const selectedBrandList = useSelectedBrandList();
  const resetFilter = useResetFilter();
  const shownParms = useShownParms();
  const [isProduct, setIsProduct] = useState(false);
  const [isShop, setIsShop] = useState(false);
  const [isActivity, setIsActivity] = useState(false);

  const [allBrandBoardVisible, setAllBrandBoardVisible] = React.useState(false);
  const [allBrandBoardShown, setAllBrandBoardShown] = React.useState(false);

  const constructParams = useConstructParams();

  const reset = () => {
    resetFilter();
  };

  useEffect(() => {
    const {
      activitySpuId = "",
      baseProductId = "",
      queryType = "",
      activityNo,
      merchantShopCode,
      resourceCode,
    } = getUrlParam();
    if (activitySpuId && queryType) {
      setIsProduct(true);
    } else if (merchantShopCode && resourceCode) {
      setIsShop(true);
    } else {
      setIsActivity(true);
    }
  });

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>全部筛选</div>
        <div className={styles.body}>
          {shownBrandList.length > 0 && isShop && (
            <>
              <div className={styles.subtitle}>
                <span>品牌</span>
                <span
                  onClick={() => setAllBrandBoardVisible(true)}
                  className={`${styles.seeAll} ${
                    selectedBrandList.length > 0 ? styles.active : ""
                  }`}
                >
                  {shownBrandList?.length > 6 && (
                    <span>
                      {selectedBrandList
                        .map((i) => i.name)
                        .slice(0, 3)
                        .join("、")}
                    </span>
                  )}
                  {selectedBrandList.length > 3 && <span>...</span>}
                  {selectedBrandList.length === 0 && shownBrandList?.length > 6 && (
                    <span>查看全部</span>
                  )}
                  {shownBrandList?.length > 6 && (
                    <ArrowRightIcon className={styles.arrowRightIcon} />
                  )}
                </span>
              </div>
              <div className={styles.items}>
                {shownBrandList.slice(0, 9).map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${styles.brandItem} ${
                      item.selected ? styles.active : ""
                    }`}
                    onClick={() => {
                      toggleBrand(item);
                    }}
                  >
                    <span
                      className={styles.brandLogoWrapper}
                      style={{
                        backgroundImage: `url(${item.icon})`,
                      }}
                    ></span>
                    <span className={styles.text}>{item.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}
          {shownTypeList?.length > 0 && (
            <>
              <div className={styles.subtitle}>素材类别</div>
              <div className={styles.items}>
                {shownTypeList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${item.selected ? styles.active : ""}`}
                    onClick={() => toggleType(item)}
                  >
                    <span className={styles.text}>{item.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}
          {showCategoryList?.length > 0 && !isProduct && (
            <>
              <div className={styles.subtitle}>素材类型</div>
              <div className={styles.items}>
                {showCategoryList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${item.selected ? styles.active : ""}`}
                    onClick={() => toggleCategory(item)}
                  >
                    <span className={styles.text}>{item.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}
          {showChannelList?.length > 0 && (
            <>
              <div className={styles.subtitle}>素材渠道</div>
              <div className={styles.items}>
                {showChannelList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${item.selected ? styles.active : ""}`}
                    onClick={() => toggleChannel(item)}
                  >
                    <span className={styles.text}>{item.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}
          {shownServiceTagList?.length > 0 && (
            <>
              <div className={styles.subtitle}>素材来源</div>
              <div className={styles.items}>
                {shownServiceTagList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${item.selected ? styles.active : ""}`}
                    onClick={() => toggleServiceTag(item)}
                  >
                    <span className={styles.text}>{item.name}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.reset} onClick={reset}>
            重置
          </div>
          <div
            className={styles.confirm}
            onClick={() => onFilterChange && onFilterChange(constructParams())}
          >
            确定
          </div>
        </div>
      </div>
      <Popup
        visible={allBrandBoardVisible}
        showCloseButton
        onMaskClick={() => setAllBrandBoardVisible(false)}
        onClose={() => setAllBrandBoardVisible(false)}
        afterShow={() => setAllBrandBoardShown(true)}
        bodyStyle={{ height: "90vh" }}
        className={styles.allBrandBoard}
      >
        {allBrandBoardShown && <AllBrand onClose={() => setAllBrandBoardVisible(false)} />}
      </Popup>
    </>
  );
};

export default FilterBoard;
