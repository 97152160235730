import { queryProductList } from "@/api/newPerson";
import { useEffect, useRef, useState } from "react";

const useProductList = () => {
  const [productList, setProductList] = useState<any[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const currentPageNum = useRef<number>(1);
  const getProductList = async () => {
    const params = {
      pageId: "H5_Search",
      pageIndex: currentPageNum.current,
      brandIds: selectedBrand ? [selectedBrand.brandId] : [],
    };
    try {
      const res = await queryProductList(params);
      const curList = currentPageNum.current === 1 ? res.result : [...productList, ...res.result];
      setProductList(curList);
      setHasNext(res.hasNext);
    } catch (err) {
      console.log(8989, err);
    }
  };
  const loadMore = () => {
    currentPageNum.current += 1;
    return getProductList();
  };
  const reLoad = () => {
    currentPageNum.current = 1;
    setHasNext(true);
    setProductList([]);
    getProductList();
  };
  useEffect(() => {
    reLoad();
    if (selectedBrand) {
      const { brandId } = selectedBrand;
      document
        .getElementById(`tab_${brandId}`)
        ?.scrollIntoView({ block: "nearest", inline: "center" });
    }
  }, [selectedBrand]);
  return {
    productList,
    hasNext,
    loadMore,
    selectedBrand,
    setSelectedBrand,
  };
};

export default useProductList;
