import { queryAchievementDataList } from "@/api/videoShare";
import TopNavigation from "@/components/top-navigation";
import useQueryAllPlatRole from "@/utils/hooks/useQueryAllPlatRole";
import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Achievement from "../components/achievement";
import styles from "./index.module.less";

const IDENDTITY_IMG_R =
  "https://akim-oss.aikucun.com/2bc37e161764a79dcb4520acf241cfcb666c3ef3_1709185176329_71.png";
const IDENDTITY_IMG_E =
  "https://akim-oss.aikucun.com/cd16053df2c0b275effc112c3ab961416e96a027_1709185277729_25.png";
const TIME_PREFIX_IMG =
  "https://akim-oss.aikucun.com/6749aef724784a5bd528da8f7afc6cc501b61ce1_1709186982777_95.png";
const AVATAR_URL =
  "https://akim-oss.aikucun.com/25b92ed62e9f188307a0284671df91e9678571cc_1702637476577_65.png";

interface IAnchorInfo {
  headImgUrl: string;
  nickName: string;
}
interface IProps {
  appStore?: any;
  [key: string]: any;
}

const HistoryData: React.FC<IProps> = ({ appStore }) => {
  const navigater = useNavigate();
  const { getRole } = useQueryAllPlatRole();
  const role = getRole();
  const [search] = useSearchParams();
  const goIntroduction = () => {
    navigater("/mx-shop-micro/video-sharer-instruction");
  };
  const [liveList, setLiveList] = useState<any[]>([]);
  const [distributorCnt, setDistributorCnt] = useState<number>(0);
  const [anchorInfo, setAnchorInfo] = useState<IAnchorInfo | null>(null);
  const reqAchievementDataList = async () => {
    const anchorId = search.get("anchorId");
    const res = (await queryAchievementDataList({
      accountId: anchorId,
      type: "HISTORY",
    })) as any;
    setLiveList(res?.data?.liveList || []);
    setDistributorCnt(Number(res?.data?.distributorCnt) || 0);
  };
  const initData = async () => {
    if (platform.isApp) {
      const res: any = await AKJS.info.account();
      appStore.token = res.data.token;
      appStore.channel = "akcApp";
    } else if (platform.isMini) {
      appStore.token = null;
      appStore.channel = "xdApplets";
      if (search.get("plt") === "saas") {
        appStore.channel = "SaaSApplets";
      }
      appStore.token = search.get("token");
    }
    reqAchievementDataList();
  };
  useEffect(() => {
    initData();
    setAnchorInfo(JSON.parse(localStorage.getItem("anchorInfo") || "{}"));
    document.title = "历史直播数据";
  }, []);
  return (
    <div style={{ overflow: "scroll", height: "100vh" }}>
      {(platform.isH5 || platform.isWxH5) && (
        <div>
          <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
        </div>
      )}
      <div className={styles["container"]}>
        <div className={styles["header"]}>
          <img
            src={anchorInfo?.headImgUrl || AVATAR_URL}
            alt="头像"
            className={styles["header__avatar"]}
          />
          <div className={styles["header__info"]}>
            <div className={styles["info__name"]}>{anchorInfo?.nickName}</div>
            <img
              onClick={goIntroduction}
              className={styles["info__identity"]}
              src={role === "R" ? IDENDTITY_IMG_R : role === "E" ? IDENDTITY_IMG_E : ""}
            />
          </div>
        </div>
        <div className={styles["data-list"]}>
          {liveList.map((item, index) => {
            return (
              <div className={styles["list__item"]} key={index}>
                <div className={styles["item__title"]}>
                  <img className={styles["title__prefix"]} src={TIME_PREFIX_IMG} alt="prefix" />
                  {item.liveRoomTime}
                </div>
                <Achievement
                  achievementDataList={item.list}
                  distributorCnt={distributorCnt}
                  lastUpdateTime={item.lastUpdateTime}
                >
                  <div className={styles["item__content"]}>{item.liveRoomName}</div>
                </Achievement>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default inject("appStore")(observer(HistoryData));
