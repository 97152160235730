import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import loadingPng from "@/base64";
import React from "react";
import styles from "./index.module.less";

type Props = {
  hasMore: boolean;
};

const InfiniteScrollContent: React.FC<Props> = ({ hasMore }: { hasMore?: boolean }) => {
  return (
    <>
      {hasMore ? (
        <div className={styles.loadingMore}>
          <img src={loadingPng} />
        </div>
      ) : (
        <div className={styles.loadingEnd}>
          <>
            <img src={loadLeft} />
            <div className={styles.end_word}>已经到底了</div>
            <img src={loadRight} />
          </>
        </div>
      )}
    </>
  );
};

export default InfiniteScrollContent;
