import { Swiper } from "antd-mobile";
import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import styles from "./ProductsBoard.module.less";

type Props = {
  list: any[];
  atmosphereList: any[];
};

const ProductsBoard: React.FC<Props> = ({ list = [], atmosphereList = [] }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <div className={styles.title}>
          <img
            src="https://akim-oss.aikucun.com/972a67c48192728a34979d9a35164c1295401b71_1706339126449_17.png"
            className={styles.titleImg}
          />
          ({list.length})
        </div>
        <div className={styles.aside}>
          <Swiper
            indicator={() => null}
            autoplay={true}
            loop={true}
            direction="vertical"
            style={{ "--height": "24px" }}
          >
            {atmosphereList.map((item: any) => (
              <Swiper.Item key={item.userName}>
                <div className={styles.swiperWrap}>
                  <img src={item.userAvatar} className={styles.avatar} />
                  {item.userName} {item.orderTime}已下单
                </div>
              </Swiper.Item>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles.list}>
        {list.map((item, index) => {
          item.relativeRank = index;
          return <ProductCard key={item.activitySpuId} product={item} />;
        })}
      </div>
    </div>
  );
};

export default ProductsBoard;
