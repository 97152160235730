import iconPause from "@/assets/pause.png";
import iconPlaying from "@/assets/paying.png";
import iconVolumeClose from "@/assets/volume-close.png";
import iconVolumeOpen from "@/assets/volume-open.png";
import { useCommonContext } from "@/utils/context/common";
import { getNetworkType } from "@/utils/network-type";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { inject, observer } from "mobx-react";
import { CSSProperties, default as React, useEffect, useRef, useState } from "react";
import exposure from "../../utils/expose/exposure";
import styles from "./index.module.less";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  resourceRank?: any;
  trackInfo?: any;
  [key: string]: any;
}

const VideoCom: React.FC<Props> = ({ propData, pageData, resourceRank, trackInfo }) => {
  const rootRef = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const [autoPlay, setAutoplay] = useState(false);
  const { track } = useCommonContext();

  const init = () => {
    if (rootRef.current) {
      const videoRef = rootRef.current;
      getNetworkType()
        .then((res) => {
          // wifi环境下自动播放，自动播放需要静音
          if (res === "wifi" && platform.isApp) {
            setTimeout(() => {
              setPlaying(true);
              setAutoplay(true);
              setMuted(true);
              videoRef.play();
            }, 200);
          }
        })
        .catch();
      videoRef.addEventListener("ended", () => {
        videoRef.currentTime = 0;
        setPlaying(false);
      });
    }
  };

  useEffect(() => {
    init();
    const interval = setInterval(() => {
      let flag = false;
      const dom = document.getElementById(`${propData.componentCode}`);
      if (dom) {
        exposure.add({
          el: dom,
        });
      } else {
        flag = true;
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  // 视频控制bar
  const onTogglePlay = (e) => {
    e.stopPropagation();
    const videoRef = rootRef.current;
    if (videoRef) {
      setPlaying(videoRef.paused);
      videoRef.paused ? videoRef.play() : videoRef.pause();
    }
    //操作埋点
    /* eslint-disable */
    if (track.track) {
      track.track("play", {
        page_name: document.title,
        btn_name: "播放",
        video_time: videoRef?.duration,
        play_time: videoRef?.currentTime,
        market_id: pageData.pageCode,
        market_name: document.title,
        ...trackInfo,
      });
    }
    /* eslint-enable */
  };

  const onToggleMute = (e) => {
    e.stopPropagation();
    const videoRef = rootRef.current;
    if (videoRef) {
      setMuted(!videoRef.muted);
      videoRef.muted = !videoRef.muted;
    }
    //操作埋点
    /* eslint-disable */
    if (track.track) {
      track.track("play", {
        page_name: document.title,
        btn_name: "禁音",
        video_time: videoRef?.duration,
        play_time: videoRef?.currentTime,
        market_id: pageData.pageCode,
        market_name: document.title,
        ...trackInfo,
      });
    }
    /* eslint-enable */
  };

  const renderVideoControls = () => {
    return (
      <div className={styles.videoBar}>
        <div className={styles.left}>
          <img
            className={styles.playBtn}
            src={playing ? iconPlaying : iconPause}
            onClick={onTogglePlay}
          ></img>
        </div>
        <div className={styles.right}>
          <img
            className={styles.volumeBtn}
            onClick={onToggleMute}
            src={muted ? iconVolumeClose : iconVolumeOpen}
          ></img>
        </div>
      </div>
    );
  };

  const setVideoSize = () => {
    const { paddingLeft, paddingRight, videoConfigDetails } = propData;
    const containerW = 375 - paddingLeft - paddingRight;
    const videoW = videoConfigDetails[0].width;
    const videoH = videoConfigDetails[0].height;
    const styles: CSSProperties = {
      width: pxtorem(containerW),
      height: pxtorem(videoH * (containerW / videoW)),
    };
    return styles;
  };

  // 视频控件
  const renderVideo = () => {
    return (
      <video
        ref={rootRef}
        className={styles.video}
        style={setVideoSize()}
        src={propData.videoConfigDetails[0].url}
        controls={false}
        autoPlay={autoPlay}
        muted={muted}
        poster={propData.videoConfigDetails[0].coverImg}
        disablePictureInPicture
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsInline={true}
        x5-playsinline="true"
      >
        <source src={propData.videoConfigDetails[0].url} />
        <track kind="captions" />
      </video>
    );
  };

  const containerStyle: CSSProperties = {
    margin: `${pxtorem(propData.paddingTop)} ${pxtorem(propData.paddingRight)} ${pxtorem(
      propData.paddingBottom,
    )} ${pxtorem(propData.paddingLeft)}`,
  };
  return (
    <div
      className={styles.videoContainer}
      style={containerStyle}
      id={`${propData.componentCode}`}
      data-param={JSON.stringify({
        type: "视频",
        content: "视频",
        picture: true,
        hasSpecially: false,
        resourceRank: resourceRank,
        relativeRank: 0,
        ...trackInfo,
      })}
      data-eventid={propData.componentCode}
      onClick={onTogglePlay}
    >
      {renderVideo()}
      {renderVideoControls()}
    </div>
  );
};

export default inject("appStore")(observer(VideoCom));
