import { countDown } from "@/utils/date-format";
import { platform } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

interface MxPriceProps {
  product: any;
  promoCouponInfo: any;
  hasNx: boolean;
}

const FloatTip = ({ info }) => {
  const [isShow, setIsShow] = useState(true);
  const { promotionStartTime } = info || {};
  const hasStart = new Date().getTime() - promotionStartTime > 0;
  let remainTime = { day: 0, hou: 0, min: 0 };
  if (!hasStart) {
    remainTime = countDown(promotionStartTime);
  }
  useEffect(() => {
    setIsShow(true);
  }, [info]);
  return (
    isShow && (
      <div className={styles.countdownReminderTips}>
        <div className={styles.tipsTit}>
          <img
            className={styles.icon}
            src="https://akim-oss.aikucun.com/mshop/6ee18960a73fbbbc77816993e1c54ae58c5fc6a0_1708685834483_9.png"
          />
          <div>稍作等待价格更优惠哦~~</div>
        </div>
        <div className={styles.tipsCon}>
          <div className={styles.conCountDown}>
            距开始<div className={styles.num}>{remainTime.day}</div>天
            <div className={styles.num}>{remainTime.hou}</div>时
          </div>
          <div
            className={styles.conBtn}
            onClick={() => {
              setIsShow(false);
            }}
          >
            我知道了
          </div>
        </div>
      </div>
    )
  );
};

const MxPrice: React.FC<MxPriceProps> = ({ product, promoCouponInfo, hasNx }) => {
  const [showTmpPrice, setShowTmpPrice] = useState(false);
  const [info, setInfo] = useState({ ...product, ...(product?.skuAdvancePriceInfo || {}) });
  const commissionAdditionPrefix = platform.isApp
    ? "省赚"
    : platform.isTuan
      ? "推广费"
      : platform.isMini && !platform.isSaas
        ? "饷"
        : "赚";
  useEffect(() => {
    setShowTmpPrice(
      product.skuAdvancePriceInfo
        ? true
        : product.promotionTimeStatus === 2 &&
            ["N_PIECES_X_CNY", "N_PIECES_X_DISCOUNT"].includes(product.promotionType),
    );
    setInfo({ ...product, ...(product?.skuAdvancePriceInfo || {}) });
  }, [product]);

  const PriceDetail = ({ info }) => {
    if (info.promotionType === "N_PIECES_X_CNY") {
      return (
        <div className={styles.price}>
          <span className={styles.promotionName}>任选{info.promoExtendInfo.nxCnt}件</span>
          <span className={styles.wllPrice}>{info.promoExtendInfo.nxVal}</span>
        </div>
      );
    } else if (info.promotionType === "N_PIECES_X_DISCOUNT") {
      return (
        <div className={styles.price}>
          <span className={styles.promotionName}>{info.pricePrefixDesc}</span>
          <span className={styles.wllPrice}>{info.promoExtendInfo.nxVal}</span>
          <span>/件</span>
        </div>
      );
    } else {
      return (
        <div className={styles.price}>
          {info.promotionTypeName && info.promotionType !== "HIGH_COMMISSION" && (
            <span className={styles.promotionName}>{info.promotionTypeName}</span>
          )}
          <span className={styles.wllPrice}>{info.marketingPrice}</span>
        </div>
      );
    }
  };

  return (
    <div className={styles.wrap}>
      {showTmpPrice && (
        <div className={styles.row}>
          <div className={styles.curPrice + " " + styles.col}>
            {product.skuAdvancePriceInfo ? "当前购买价" : "单件价"}
            <span className={styles.bold}>¥{product.marketingPrice}</span>
          </div>

          {product.commission && (
            <div className={styles.col}>
              {commissionAdditionPrefix}·<span className={styles.bold}>¥{product.commission}</span>
              {product.commissionAddition && (
                <span className={styles.bold}> + ¥{product.commissionAddition}</span>
              )}
            </div>
          )}
          {promoCouponInfo.skuTotalPostPrice > 0 && (
            <div className={styles.redLable + " " + styles.bold}>
              {hasNx
                ? `到手¥${promoCouponInfo.skuPostPrice}/件`
                : `券后¥${promoCouponInfo.skuTotalPostPrice}`}
            </div>
          )}
        </div>
      )}

      <div
        className={
          styles.row +
          " " +
          styles.willPriceWrap +
          " " +
          (info?.promotionTimeStatus === 1 && styles.greenColor)
        }
      >
        {info?.promotionTimeStatus === 1 && <div className={styles.preLabel}>预告</div>}
        <PriceDetail info={info || {}} />
        {info?.tagPrice &&
          info?.promotionType !== "N_PIECES_X_CNY" &&
          info?.promotionType !== "N_PIECES_X_DISCOUNT" && (
            <div className={styles.tagPrice}>¥{info.tagPrice}</div>
          )}
      </div>
      {product.skuAdvancePriceInfo && info?.promotionTimeStatus === 1 && <FloatTip info={info} />}
    </div>
  );
};

export default MxPrice;
