import {
  ActivityStatus,
  Brand,
  FilterBoardType,
  FilterPrefixCategory,
  PromotionType,
  SalePropertieTag,
  SaleProperty,
  ServiceTag,
  queryFilterBoardData,
} from "@/api/activity";
import { queryProductList } from "@/api/product";
import { SearchParam } from "@/api/type";
import constate from "constate";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useBrandIds, useMerchantCodeList } from "../hooks/useActivityProduct";

export type FilterOption<T> = {
  name: string;
  key: string;
  linkedObj: T;
  active?: boolean;
  children?: Array<FilterOption<T>>;
  parent?: FilterOption<T>;
};

export type Options = {
  newIncludeSaleout?: boolean;
  newServiceList?: Array<FilterOption<ServiceTag>>;
  newPropertyList?: Array<FilterOption<SaleProperty | SalePropertieTag>>;
  newPromotionList?: Array<FilterOption<PromotionType>>;
  newActivityStatusList?: Array<FilterOption<ActivityStatus>>;
  newCategoryList?: Array<FilterOption<FilterPrefixCategory>>;
  newBrandList?: Array<FilterOption<Brand>>;
  newMinPrice?: number;
  newMaxPrice?: number;
};

const useFilterOption = ({
  filterBoardVisible,
  activityIdList,
  scene,
  pageId,
  keyword,
}: {
  filterBoardVisible: boolean;
  activityIdList: Array<string>;
  scene: string;
  pageId: string;
  keyword?: string;
}) => {
  const [search] = useSearchParams();

  const brandIds = useBrandIds();
  const merchantCodeList = useMerchantCodeList();

  const [productCount, setProductCount] = useState<string>();
  const [isCouting, setIsCouting] = useState<boolean>(false);
  const [includeSaleout, setIncludeSaleout] = useState<boolean>(true);
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const aggsType = useRef<"category" | "brand" | undefined>();

  const [serviceList, setServiceList] = useState<Array<FilterOption<ServiceTag>>>([]);
  const [categoryList, setCategoryList] = useState<Array<FilterOption<FilterPrefixCategory>>>([]);
  const [brandList, setBrandList] = useState<Array<FilterOption<Brand>>>([]);
  const [propertyList, setPropertyList] = useState<
    Array<FilterOption<SaleProperty | SalePropertieTag>>
  >([]);
  const [promotionList, setPromotionList] = useState<Array<FilterOption<PromotionType>>>([]);
  const [activityStatusList, setActivityStatusList] = useState<Array<FilterOption<ActivityStatus>>>(
    [],
  );

  const prefixCategoryList4BoardIndex = useRef<FilterPrefixCategory[]>();

  const boardCategoryList = useMemo(() => {
    return (
      prefixCategoryList4BoardIndex.current?.map(
        (i) =>
          categoryList
            .flatMap((j) => [j].concat(j.children ?? []))
            .find((k) => k.key === i.categoryId) as FilterOption<FilterPrefixCategory>,
      ) ?? []
    );
  }, [categoryList]);
  const selectedChildCategoryList = useMemo(
    () => categoryList.flatMap((i) => i.children).filter((k) => k?.active),
    [categoryList],
  );
  const selectedBrandList = useMemo(
    () => brandList?.filter((item) => item.active) ?? [],
    [brandList],
  );

  const options = {
    serviceList,
    categoryList,
    boardCategoryList,
    selectedChildCategoryList,
    brandList,
    propertyList,
    promotionList,
    activityStatusList,
    selectedBrandList,
  };

  const constructParams = (params: Options): SearchParam => {
    return {
      scene,
      keyword,
      activityIdList: activityIdList,
      activityTypeGroup: 1,
      aggsType: aggsType.current,
      soldOutStatus: (
        params.newIncludeSaleout !== undefined ? params.newIncludeSaleout : includeSaleout
      )
        ? undefined
        : false,
      categoryIdList: (params.newCategoryList ?? categoryList)
        .flatMap((i) => {
          // 只有全部子类目都选中时，才会将父类目也选中
          let children = i.children ?? [];
          return children.some((j) => !j.active) ? children : children.concat(i);
        })
        .filter((j) => j.active)
        .map((k) => k.key),
      saleProperties: (params.newPropertyList ?? propertyList)
        .flatMap((i) => i.children ?? [])
        .filter((j) => j.active)
        .map((k) => k.key),
      // brandIds: (params.newBrandList ?? brandList).filter((i) => i.active).map((j) => j.key),
      brandIds,
      merchantCodeList,
      serviceTag: (params.newServiceList ?? serviceList).filter((i) => i.active).map((j) => j.key),
      promotionTypes: (params.newPromotionList ?? promotionList)
        .filter((i) => i.active)
        .map((j) => j.key),
      activityStatus: (params.newActivityStatusList ?? activityStatusList)
        .filter((i) => i.active)
        .map((j) => j.key),
      priceLow:
        params.newMinPrice !== undefined
          ? params.newMinPrice === -1
            ? undefined
            : params.newMinPrice
          : minPrice,
      priceHigh:
        params.newMaxPrice !== undefined
          ? params.newMaxPrice === -1
            ? undefined
            : params.newMaxPrice
          : maxPrice,
    };
  };

  const updateProductCount = async (params: Options) => {
    if (!filterBoardVisible) {
      return;
    }

    setIsCouting(true);
    const data = await queryProductList(pageId, constructParams(params), {
      onlyCountTotal: true,
    });
    setProductCount(data?.total);
    setIsCouting(false);
  };

  // 更新“服务”列表数据，refresh为true时不会保留原来的选中态
  const updateServiceList = (data: FilterBoardType, refresh = false) => {
    setServiceList((old) => {
      const selectedList = refresh ? [] : old.filter((i) => i.active).map((j) => j.key);
      return (
        data.serviceTagList?.map((i) => ({
          name: i.tagName as string,
          key: i.tagCode as string,
          linkedObj: i,
          active: selectedList.includes(i.tagCode as string),
        })) ?? []
      );
    });
  };

  const updateCategoryList = (data: FilterBoardType, refresh = false) => {
    setCategoryList((old) => {
      const selectedList = refresh
        ? []
        : old
            .flatMap((i) => [i].concat(i.children ?? []))
            .filter((j) => j.active)
            .map((k) => k.key);
      return (
        data.prefixCategoryList?.map((i) => {
          let newItem = {
            name: i.categoryName ?? "",
            key: i.categoryId ?? "",
            linkedObj: i,
            active: selectedList.includes(i.categoryId as string),
          };
          newItem["children"] = i.childrenList?.map((j) => ({
            name: j.categoryName ?? "",
            key: j.categoryId ?? "",
            linkedObj: j,
            parent: newItem,
            active: selectedList.includes(i.categoryId as string),
          }));
          return newItem;
        }) ?? []
      );
    });
  };

  const updateBrandList = (data: FilterBoardType, refresh = false) => {
    setBrandList((old) => {
      const selectedList = refresh ? [] : old.filter((i) => i.active).map((j) => j.key);
      return (
        data.brandList?.map((i) => ({
          name: i.brandName ?? "",
          key: i.brandId ?? "",
          linkedObj: i,
          active: selectedList.includes(i.brandId as string),
        })) ?? []
      );
    });
  };

  const updatePropertyList = (data: FilterBoardType, refresh = false) => {
    setPropertyList((old) => {
      const selectedList = refresh
        ? []
        : old
            .flatMap((i) => i.children ?? [])
            .filter((j) => j?.active)
            .map((k) => k?.key);
      return (
        data.saleProperties?.map((i) => ({
          name: i.groupName as string,
          key: i.groupCode as string,
          linkedObj: i,
          children: i.tags?.map((j) => ({
            name: j.tagName as string,
            key: j.tagCode as string,
            linkedObj: j,
            active: selectedList.includes(j.tagCode as string),
          })),
        })) ?? []
      );
    });
  };

  const updatePromotionList = (data: FilterBoardType, refresh = false) => {
    setPromotionList((old) => {
      const selectedList = refresh ? [] : old.filter((i) => i.active).map((j) => j.key);
      return (
        data.promotionTypeList?.map((i) => ({
          name: i.promotionTypeName as string,
          key: i.promotionType as string,
          linkedObj: i,
          active: selectedList.includes(i.promotionType as string),
        })) ?? []
      );
    });
  };

  const updateActivityStatusList = (data: FilterBoardType, refresh = false) => {
    setActivityStatusList((old) => {
      const selectedList = refresh ? [] : old.filter((i) => i.active).map((j) => j.key);
      return (
        data.activityStatusList?.map((i) => ({
          name: i.itemName as string,
          key: i.itemCode as string,
          linkedObj: i,
          active: selectedList.includes(i.itemCode as string),
        })) ?? []
      );
    });
  };

  const updateBoardDataWithInitialParams = async () => {
    const data = await queryFilterBoardData({
      activityIdList,
      scene,
      activityTypeGroup: 1,
      keyword,
      serviceTag: [],
      brandIds,
      merchantCodeList,
    });
    prefixCategoryList4BoardIndex.current = data.prefixCategoryList4BoardIndex;
    updateServiceList(data, true);
    updateCategoryList(data, true);
    updateBrandList(data, true);
    updatePropertyList(data, true);
    updatePromotionList(data, true);
    updateActivityStatusList(data, true);
  };

  useEffect(() => {
    setProductCount(undefined); // 参数变化后，重置productCount
    aggsType.current = undefined;
  }, [pageId, scene, keyword, activityIdList]);

  useEffect(() => {
    if (!filterBoardVisible || productCount) {
      return;
    }
    updateBoardDataWithInitialParams();
    updateProductCount({});
  }, [filterBoardVisible]);

  const checkHasSelection = (params: Options) => {
    return (
      !(params.newIncludeSaleout !== undefined ? params.newIncludeSaleout : includeSaleout) ||
      (params.newServiceList ?? serviceList).some((i) => i.active) ||
      (params.newPropertyList ?? propertyList)
        .flatMap((i) => i.children ?? [])
        .some((i) => i.active) ||
      (params.newPromotionList ?? promotionList).some((i) => i.active) ||
      (params.newActivityStatusList ?? activityStatusList).some((i) => i.active) ||
      (params.newCategoryList ?? categoryList)
        .flatMap((i) => (i.children ?? []).concat(i))
        .some((i) => i.active) ||
      (params.newBrandList ?? brandList).some((i) => i.active)
    );
  };

  const toggleSaleout = () => {
    const newIncludeSaleout = !includeSaleout;
    setIncludeSaleout(newIncludeSaleout);
    if (!checkHasSelection({ newIncludeSaleout })) {
      updateBoardDataWithInitialParams();
    }
    updateProductCount({ newIncludeSaleout });
  };

  // 分类和品牌的选取与取消的时候都会请求面板数据，其他选项选择的时候不会请求面板数据，取消选择的时候仅当所有选项都取消选中时会请求面板数据
  const toggleService = (service: FilterOption<ServiceTag>) => {
    const newServiceList = serviceList.map((i) => ({
      ...i,
      active: i.key === service.key ? !i.active : i.active,
    }));
    setServiceList(newServiceList);
    if (!checkHasSelection({ newServiceList })) {
      updateBoardDataWithInitialParams();
    }
    updateProductCount({ newServiceList });
  };

  const toggleCategory = async (category: FilterOption<FilterPrefixCategory>) => {
    aggsType.current = "category";
    const level = category.linkedObj.level;
    let newCategoryList = categoryList.map((i) => ({
      ...i,
      active: i.key === category.key ? !i.active : i.active,
      children: i.children?.map((j) => ({
        ...j,
        active:
          i.linkedObj.level === level
            ? // 选择项为父元素：父类目选中，所有子类目自动选中；父类目取消选中，所有子类目自动取消选中
              i.key === category.key
              ? !i.active
              : j.active
            : // 选择项为子元素
              j.key === category.key
              ? !j.active
              : j.active,
      })),
    }));
    if (category.parent) {
      newCategoryList = newCategoryList.map((i) => ({
        ...i,
        active: i.children?.some((j) => j.active),
      }));
    }
    setCategoryList(newCategoryList);
    updateProductCount({ newCategoryList });
    const data = await queryFilterBoardData(constructParams({ newCategoryList }));
    updateServiceList(data);
    updateBrandList(data);
    updatePropertyList(data);
    updatePromotionList(data);
    updateActivityStatusList(data);
  };

  const toggleBrand = async (brand: FilterOption<Brand>) => {
    aggsType.current = "brand";
    const newBrandList = brandList.map((i) => ({
      ...i,
      active: i.key === brand.key ? !i.active : i.active,
    }));
    setBrandList(newBrandList);
    updateProductCount({ newBrandList });
    const data = await queryFilterBoardData(constructParams({ newBrandList }));
    prefixCategoryList4BoardIndex.current = data.prefixCategoryList4BoardIndex;
    updateServiceList(data);
    updateCategoryList(data);
    updatePropertyList(data);
    updatePromotionList(data);
    updateActivityStatusList(data);
  };

  const toggleProperty = (property: FilterOption<SalePropertieTag | SaleProperty>) => {
    // 销售属性只能选择二级
    const newPropertyList = propertyList.map((i) => ({
      ...i,
      children: i.children?.map((j) => ({
        ...j,
        active: j.key === property.key ? !j.active : j.active,
      })),
    }));
    console.log("newPropertyList", newPropertyList);
    setPropertyList(newPropertyList);
    if (!checkHasSelection({ newPropertyList })) {
      updateBoardDataWithInitialParams();
    }
    updateProductCount({ newPropertyList });
  };

  const togglePromotion = (promotion: FilterOption<PromotionType>) => {
    const newPromotionList = promotionList.map((i) => ({
      ...i,
      active: i.key === promotion.key ? !i.active : i.active,
    }));
    setPromotionList(newPromotionList);
    if (!checkHasSelection({ newPromotionList })) {
      updateBoardDataWithInitialParams();
    }
    updateProductCount({ newPromotionList });
  };

  const toggleActivity = (activityStatus: FilterOption<ActivityStatus>) => {
    const newActivityStatusList = activityStatusList.map((i) => ({
      ...i,
      active: i.key === activityStatus.key ? !i.active : i.active,
    }));
    setActivityStatusList(newActivityStatusList);
    if (!checkHasSelection({ newActivityStatusList })) {
      updateBoardDataWithInitialParams();
    }
    updateProductCount({ newActivityStatusList });
  };

  const setPrice = (min?: number, max?: number) => {
    const newMinPrice = min;
    setMinPrice(newMinPrice);
    const newMaxPrice = max;
    setMaxPrice(newMaxPrice);
    // 如果没有设置，设置为-1，从而与undefined区分开
    updateProductCount({ newMinPrice: newMinPrice ?? -1, newMaxPrice: newMaxPrice ?? -1 });
  };

  const toggles = {
    toggleSaleout,
    toggleService,
    toggleCategory,
    toggleBrand,
    toggleProperty,
    togglePromotion,
    toggleActivity,
    setPrice,
    constructParams,
  };

  const resetCategory = () => {
    setCategoryList((old) =>
      old.map((i) => ({
        ...i,
        active: false,
        children: i.children?.map((j) => ({ ...j, active: false })),
      })),
    );
  };

  const resetBrand = () => {
    setBrandList((old) => old.map((i) => ({ ...i, active: false })));
  };

  const resetFilter = () => {
    setProductCount(undefined);
    setIncludeSaleout(true);
    setServiceList((old) => old.map((i) => ({ ...i, active: false })));
    resetCategory();
    resetBrand();
    setPropertyList((old) =>
      old.map((i) => ({ ...i, children: i.children?.map((j) => ({ ...j, active: false })) })),
    );
    setPromotionList((old) => old.map((i) => ({ ...i, active: false })));
    setActivityStatusList((old) => old.map((i) => ({ ...i, active: false })));
    setMinPrice(undefined);
    setMaxPrice(undefined);
    updateBoardDataWithInitialParams();
    updateProductCount({
      newIncludeSaleout: true,
      newServiceList: [],
      newCategoryList: [],
      newBrandList: [],
      newPropertyList: [],
      newPromotionList: [],
      newActivityStatusList: [],
      newMaxPrice: -1,
      newMinPrice: -1,
    });
  };

  const resets = { resetCategory, resetBrand, resetFilter };

  return {
    minPrice,
    maxPrice,
    productCount,
    isCouting,
    includeSaleout,
    options,
    toggles,
    resets,
  };
};

export const [
  FilterOptionProvider,
  useMinPrice,
  useMaxPrice,
  useProductCount,
  useIsCounting,
  useIncludeSaleout,
  useOptions,
  useToggles,
  useResets,
] = constate(
  useFilterOption,
  (value) => value.minPrice,
  (value) => value.maxPrice,
  (value) => value.productCount,
  (value) => value.isCouting,
  (value) => value.includeSaleout,
  (value) => value.options,
  (value) => value.toggles,
  (value) => value.resets,
);
