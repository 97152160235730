import { adminLogout } from "@/api/member";
import { getPlatform } from "@/utils/platform";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Dialog } from "antd-mobile";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const Logout = () => {
  const { isWxH5 } = getPlatform();
  const { navigate2H5 } = useNavigation();

  const onLogout = () => {
    Dialog.confirm({
      title: "退出登录",
      content: "确认退出当前账号吗?",
      bodyClassName: "dialog_logout",
      onConfirm: async () => {
        await adminLogout();
        const options: IOptions = {
          path: "/mx-shop-micro/home",
          is_micro_app: true,
          is_replace: true,
        };
        navigate2H5(options);
      },
    });
  };

  return (
    <>
      {!isWxH5 && (
        <div className={styles.logout} onClick={onLogout}>
          退出登录
        </div>
      )}
    </>
  );
};

export default Logout;
