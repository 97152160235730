import { queryProductList } from "@/api/product";
import { hostEnv } from "@/utils/platform";
import { ProductModel } from "@akc/biz-api";
import constate from "constate";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useProduct = () => {
  const [search] = useSearchParams();
  const actId = search.get("actId") || "";

  const [productList, setProductList] = useState<Array<ProductModel>>([]);
  const currentPageNum = useRef<number>(1);
  const currentRequestId = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const isLoading = useRef(false);

  const updateProductList = async (requestId: number) => {
    // if (!actId) {
    //   return;
    // }
    isLoading.current = true;
    const res = await queryProductList(`${hostEnv}_Shop`, {
      scene: "SALES_REBATE_PAGE",
      pageNum: currentPageNum.current,
      pageSize: 10,
      promoActivityIds: [actId || ""],
    });
    if (requestId !== currentRequestId.current) {
      return;
    }
    setHasNext(res.hasNext);
    setProductList((productList) => {
      return [
        ...productList,
        ...(res.result?.map((item, index) => ({
          ...item,
          rank: productList.length + index,
        })) ?? []),
      ];
    });
    currentPageNum.current = res.pageIndex + 1;
    isLoading.current = false;
  };

  const loadMore = async () => {
    // console.error("loadMore——");
    if (typeof currentPageNum.current === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await updateProductList(currentRequestId.current);
  };

  useEffect(() => {
    loadMore();
  }, []);

  return {
    productList,
    hasNext,
    loadMore,
  };
};

export const [ProductProvider, useHasNext, useLoadMore, useProductList] = constate(
  useProduct,
  (value) => value.hasNext,
  (value) => value.loadMore,
  (value) => value.productList,
);
