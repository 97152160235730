import { PrefixCategory, SizeInfo, SortModel, SortType, getCategoryAndSize } from "@/api/activity";
import { queryProductList } from "@/api/product";
import { hostEnv } from "@/utils/platform";
// import { fetchActivityCardInfo } from "@/api/scode/biz/activity";
import { SearchParam } from "@/api/type";
import { useCommonContext } from "@/utils/context/common";
import { shareCardByH5 } from "@/utils/share/share";
import { ActivityModel, ProductModel } from "@akc/biz-api";
import { TrackDataContext } from "@akc/mx-taro-h5-components";
import constate from "constate";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";

const useActivityProduct = ({
  initialSelectedActivityId,
  activityList,
}: {
  initialSelectedActivityId?: string;
  activityList?: Array<ActivityModel>;
}) => {
  const [selectedActivity, setSelectedActivity] = useState<ActivityModel | undefined>();
  const [search] = useSearchParams();

  useEffect(() => {
    setSelectedActivity(
      activityList?.find((activity) => activity.activityId === initialSelectedActivityId),
    );
  }, [initialSelectedActivityId, activityList]);

  const [brandIds, setBrandIds] = useState<string[]>([]);
  const [prefixCategoryList, setPrefixCategoryList] = useState<Array<PrefixCategory>>();
  const [selectedPrefixCategory, setSelectedPrefixCategory] = useState<PrefixCategory>();
  const [sizeInfoList, setSizeInfoList] = useState<Array<SizeInfo>>();
  const [selectedSizeInfo, setSelectedSizeInfo] = useState<SizeInfo>();
  const [isEmptyStatus, setIsEmptyStatus] = useState<boolean>(false);

  const selectedActivityIdList = useMemo(() => {
    if (!activityList || !selectedActivity) {
      return [];
    }
    return selectedActivity?.activityId
      ? [selectedActivity.activityId ?? ""]
      : activityList.map((i) => i.activityId ?? "").filter((i) => i !== "") || [];
  }, [activityList, selectedActivity]);

  const skipGetCategoryAndSize = useRef(false);
  useEffect(() => {
    async function updateCategoryAndSize() {
      if (selectedActivityIdList.length === 0) {
        // setPrefixCategoryList([]);
        // setSelectedPrefixCategory({});
        // setSizeInfoList([]);
        return;
      }
      // 重置选中类目的时候，如果skip掉，会重复调用getCategoryAndSize两次，所以在这里设置一下跳过
      skipGetCategoryAndSize.current = true;
      const res = await getCategoryAndSize(selectedActivityIdList);
      setPrefixCategoryList(res.prefixCategoryList);
      setSelectedPrefixCategory(res.prefixCategoryList?.[0] ?? {});
      setSizeInfoList(res.sizeAndProductNumList ?? []);
      //为了在切换后里面讲skip改成false，防止导致切换tab不查询getCategoryAndSize接口
      setTimeout(() => {
        skipGetCategoryAndSize.current = false;
      }, 0);
    }
    updateCategoryAndSize();
  }, [selectedActivityIdList]);

  const shareCardByH5Data = useRef<any>();
  const updateShareCardByH5Data = useCallback(() => {
    if (!shareCardByH5Data.current) {
      return;
    }
    const { urlShareTitle, urlShareDesc, urlShareImg, urlShareUrl, scode } =
      shareCardByH5Data.current;
    // 三个点分享
    shareCardByH5(
      {
        title: urlShareTitle,
        desc: urlShareDesc,
        imgUrl: urlShareImg,
        link: urlShareUrl,
        scode,
      },
      false,
    );
  }, []);

  // useEffect(() => {
  //   if (selectedActivityIdList.length === 0) {
  //     return;
  //   }
  //   const brandId = selectedActivity?.brandId;
  //   const firstActivityId = selectedActivityIdList?.[0];
  //   fetchActivityCardInfo({
  //     activityId: selectedActivity?.activityId,
  //     brandId,
  //     extra: {
  //       brandId,
  //       queryType: search.get("queryType") ?? undefined,
  //       activityId: firstActivityId,
  //       sharePageId: LabelPageId.h5ActivityDetail,
  //     },
  //   }).then((data) => {
  //     shareCardByH5Data.current = data.data;
  //     updateShareCardByH5Data();
  //   });
  // }, [selectedActivityIdList]);

  useActivate(() => {
    // 页面回到前台时，更新微信右上角三个点分享
    updateShareCardByH5Data();
  });

  const { currentRole } = useCommonContext();
  type SortInfo = {
    name: string;
    sortModel: SortModel;
    sortTypes: SortType[];
  };
  const [selectedSortInfo, setSelectedSortInfo] = useState<SortInfo>();
  const [selectedSortType, setSelectedSortType] = useState<SortType>(SortType.Desc);

  const sortInfoList = useMemo<Array<SortInfo>>(() => {
    return [
      currentRole === "C"
        ? {
            name: "综合",
            sortModel: SortModel.General,
            sortTypes: [SortType.Desc],
          }
        : undefined,
      {
        name: "销量",
        sortModel: SortModel.Sales,
        sortTypes: [SortType.Desc],
      },
      {
        name: "价格",
        sortModel: SortModel.Price,
        sortTypes: [SortType.Asc, SortType.Desc],
      },
      {
        name: "上新",
        sortModel: SortModel.New,
        sortTypes: [SortType.Desc, SortType.Asc],
      },
    ].filter((i) => typeof i !== "undefined") as Array<SortInfo>;
  }, [currentRole]);

  const sortPopupOptions = useMemo(
    () =>
      currentRole === "C"
        ? []
        : [
            {
              name: "综合",
              desc: "综合排序",
              sortModel: SortModel.General,
              sortTypes: [SortType.Desc],
            },
            {
              name: "赚",
              desc: "赚从高到低",
              sortModel: SortModel.Profit,
              sortTypes: [SortType.Desc],
            },
            {
              name: "转发",
              desc: "转发从高到低",
              sortModel: SortModel.Share,
              sortTypes: [SortType.Desc],
            },
            {
              name: "折扣",
              desc: "折扣从高到低",
              sortModel: SortModel.Discount,
              sortTypes: [SortType.Asc],
            },
          ],
    [currentRole, selectedSortInfo],
  );

  const shownFirstSortOption = useMemo(() => {
    if (currentRole === "C") {
      return undefined;
    } else {
      const selected = sortPopupOptions.find((i) => i.sortModel === selectedSortInfo?.sortModel);
      if (selected) {
        return { ...selected, active: true };
      } else {
        return { ...sortPopupOptions[0], active: false };
      }
    }
  }, [currentRole, sortPopupOptions, selectedSortInfo]);

  useEffect(() => {
    // TODO
    async function updateSizeInfo() {
      // if (selectedActivityIdList.length === 0) {
      //   return;
      // }
      if (selectedPrefixCategory) {
        if (!skipGetCategoryAndSize.current) {
          const res = await getCategoryAndSize(selectedActivityIdList, selectedPrefixCategory.code);
          setSizeInfoList(res.sizeAndProductNumList);
        }
      } else {
        setSizeInfoList([]);
      }
      setSelectedSizeInfo(undefined);

      setSelectedSortInfo(
        ((shownFirstSortOption ? sortPopupOptions[0] : shownFirstSortOption) as SortInfo) ??
          sortInfoList[0],
      );
      setSelectedSortType(SortType.Desc);
    }
    updateSizeInfo();
  }, [selectedPrefixCategory]);

  const currentPageNum = useRef<number>();
  const currentRequestId = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const [productList, setProductList] = useState<Array<ProductModel>>([]);
  const [filterInfo, setFilterInfo] = useState<Partial<SearchParam>>();
  const isLoading = useRef(false);

  const updateProductList = async (requestId: number) => {
    if (!selectedSortInfo?.sortModel) {
      return;
    }
    isLoading.current = true;
    const res = await queryProductList(`${hostEnv}_Shop`, {
      scene: "SALES_REBATE_PAGE",
      activityTypeGroup: 1,
      pageSize: 10,
      pageNum: currentPageNum.current,
      activityIdList: brandIds?.length > 0 ? selectedActivityIdList : [],
      // thirdCategoryIdList: selectedPrefixCategory?.thirdCategory,
      sizes: selectedSizeInfo?.sizeCode ? [selectedSizeInfo?.sizeCode] : undefined,
      sortModel: selectedSortInfo?.sortModel,
      sortType: selectedSortType,
      soldOutStatus: filterInfo?.soldOutStatus,
      serviceTag: filterInfo?.serviceTag,
      categoryIdList: filterInfo?.categoryIdList,
      brandIds,
      promotionTypes: filterInfo?.promotionTypes,
      guideProperties: filterInfo?.guideProperties,
      saleProperties: filterInfo?.saleProperties,
      priceHigh: filterInfo?.priceHigh,
      priceLow: filterInfo?.priceLow,
      activityStatus: filterInfo?.activityStatus,
      promoActivityIds: [search.get("actId") ?? ""],
    });
    if (requestId !== currentRequestId.current) {
      return;
    }
    setHasNext(res.hasNext);
    setProductList((productList) => {
      return [
        ...productList,
        ...(res.result?.map((item, index) => ({
          ...item,
          rank: productList.length + index,
        })) ?? []),
      ];
    });
    setIsEmptyStatus(currentPageNum.current === 1 && res.result?.length === 0);
    currentPageNum.current = res.pageIndex + 1;
    isLoading.current = false;
  };

  const hasFilterOption = useMemo(() => {
    return (
      typeof filterInfo?.soldOutStatus !== "undefined" ||
      (filterInfo?.serviceTag ?? []).length > 0 ||
      (filterInfo?.categoryIdList ?? []).length > 0 ||
      (filterInfo?.brandIds ?? []).length > 0 ||
      (filterInfo?.promotionTypes ?? []).length > 0 ||
      (filterInfo?.guideProperties ?? []).length > 0 ||
      (filterInfo?.saleProperties ?? []).length > 0 ||
      (filterInfo?.activityStatus ?? []).length > 0 ||
      filterInfo?.priceHigh ||
      filterInfo?.priceLow
    );
  }, [filterInfo]);

  const updateFilterInfo = (params: Partial<SearchParam>) => {
    setFilterInfo(params);
    setSelectedSizeInfo(undefined);
    setSelectedPrefixCategory(prefixCategoryList?.[0]);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setTrackData } = useContext(TrackDataContext);

  const resetProductList = () => {
    setProductList([]);
    setHasNext(true);
    currentPageNum.current = 1;
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    updateProductList(currentRequestId.current);

    setTrackData((data) => ({
      ...data,
      activity_id: selectedActivityIdList.join(","),
      tab_id: selectedActivityIdList.join(","),
      tab_rank: selectedActivity?.activityId ? (selectedActivity.rank ?? 0) + 1 : 0,
      tab_name: selectedActivity?.activityAggrTitle,
      rank_name: selectedSortInfo?.sortModel.toString(),
      rank_type: selectedSortType?.toString(),
    }));
  };

  useEffect(() => {
    resetProductList();
  }, [selectedSortInfo, selectedSortType, brandIds]);

  useEffect(() => {
    setHasNext(true);
  }, [brandIds]);

  useEffect(() => {
    if (filterInfo !== undefined) {
      resetProductList();
    }
  }, [filterInfo]);

  const loadMore = async () => {
    if (typeof currentPageNum.current === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await updateProductList(currentRequestId.current);
  };

  return {
    selectedActivity,
    setSelectedActivity,
    prefixCategoryList,
    selectedPrefixCategory,
    setSelectedPrefixCategory,
    sizeInfoList,
    selectedSizeInfo,
    setSelectedSizeInfo,
    sortInfoList,
    selectedSortInfo,
    setSelectedSortInfo,
    selectedSortType,
    setSelectedSortType,
    updateFilterInfo,
    hasFilterOption,
    productList,
    hasNext,
    loadMore,
    selectedActivityIdList,
    setFilterInfo,
    sortPopupOptions,
    shownFirstSortOption,
    setBrandIds,
    brandIds,
    isEmptyStatus,
  };
};

export const [
  ActivityProductProvider,
  useSelectedActivity,
  useSetSelectedActivity,
  usePrefixCategoryList,
  useSelectedPrefixCategory,
  useSetSelectedPrefixCategory,
  useSizeInfoList,
  useSelectedSizeInfo,
  useSetSelectedSizeInfo,
  useSortInfoList,
  useSelectedSortInfo,
  useSetSelectedSortInfo,
  useSelectedSortType,
  useSetSelectedSortType,
  useUpdateFilterInfo,
  useHasFilterOption,
  useProductList,
  useHasNext,
  useLoadMore,
  useSelectedActivityIdList,
  useSetFilterInfo,
  useSortPopupOptions,
  useShownFirstSortOption,
  useSetBrandIds,
  useBrandIds,
  useIsEmptyStatus,
] = constate(
  useActivityProduct,
  (value) => value.selectedActivity,
  (value) => value.setSelectedActivity,
  (value) => value.prefixCategoryList,
  (value) => value.selectedPrefixCategory,
  (value) => value.setSelectedPrefixCategory,
  (value) => value.sizeInfoList,
  (value) => value.selectedSizeInfo,
  (value) => value.setSelectedSizeInfo,
  (value) => value.sortInfoList,
  (value) => value.selectedSortInfo,
  (value) => value.setSelectedSortInfo,
  (value) => value.selectedSortType,
  (value) => value.setSelectedSortType,
  (value) => value.updateFilterInfo,
  (value) => value.hasFilterOption,
  (value) => value.productList,
  (value) => value.hasNext,
  (value) => value.loadMore,
  (value) => value.selectedActivityIdList,
  (value) => value.setFilterInfo,
  (value) => value.sortPopupOptions,
  (value) => value.shownFirstSortOption,
  (value) => value.setBrandIds,
  (value) => value.brandIds,
  (value) => value.isEmptyStatus,
);
