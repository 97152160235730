import useNavigation from "@/utils/useNavigate";

const useSkip = () => {
  const { navigate2Home, navigate2H5 } = useNavigation();

  const goHome = () => {
    navigate2Home();
  };
  const goBack = () => {
    if (window.history.length === 1) {
      goHome();
    } else {
      navigate2H5(-1);
    }
  };

  return {
    goHome,
    goBack,
  };
};

export default useSkip;
