import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";

// 关注店铺
export function focusList(params) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/merchant-shop/subscript/list`,
    method: "post",
    data: params,
  });
}

// 首页店铺tab
export function getTabInfo(params) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/merchant-shop/tab/info`,
    method: "post",
    data: params,
  });
}

// 首页店铺tab下列表
export function getTabList(params) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/merchant-shop/tab/list`,
    method: "post",
    data: params,
  });
}

// 分类
export function getCategoryList(params) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/merchant-shop/category/info`,
    method: "get",
    params: params,
  });
}

// 关注店铺
export function subscribe(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/subscription/subscribe`,
    method: "post",
    data: params,
  });
}

// 取消关注店铺
export function unSubscribe(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/subscription/unsubscribe`,
    method: "post",
    data: params,
  });
}

//搜索页暗词
export function getDarkWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryDarkWord`,
    method: "post",
    data: params,
  });
}

//搜索页热词
export function getHotWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/queryHotWord`,
    method: "post",
    data: params,
  });
}

//搜索页联想词
export function getSuggestWord(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/discover/querySuggest`,
    method: "get",
    params,
  });
}

// 营销弹框
export function queryCardPop(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/vip/card/popup`,
    method: "get",
    params,
  });
}
