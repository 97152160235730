import { getCartTotal, isSwitchBatch } from "@/api";
import { useCommonContext } from "@/utils/context/common";
import { EventName, TabchangeEventData } from "@/utils/event/events";
import { MICRO_APP_BASEURL, entryShop } from "@/utils/platform";
import { default as React } from "react";
import { useActivate } from "react-activation";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Discovery from "./components/discovery";
import Recommend from "./components/recommend";
import styles from "./index.module.less";
import { useTrack } from "./track";
// 页面接受参数
// tab: 1 | 2   1进入发现页，2进入推荐页
// materialNo: string  素材编号
// shopId: string  店铺id
// distributorId: string  分销员id
// objectIds string,string  用于根据商品查询的信息
// queryType 用于根据商品查询的信息
// baseProductIds string,string 用于根据商品查询的信息
// fromPage: string  来自实拍专区
function Promote() {
  const myTrack = useTrack();
  const location = useLocation();
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const fromPage = search.get("fromPage");
  const tab = search.get("tab");
  const [tabs] = React.useState([
    { id: "1", name: "发现" },
    { id: "2", name: "推荐" },
  ]);
  const [active, setActive] = React.useState<string>(tab || "1");
  const [cartNum, setCartNum] = React.useState<number>(0);
  const [showDiscovery, setShowDiscovery] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { eventManager } = useCommonContext();
  const queryGetCartTotal = async () => {
    console.log(shopId, "shopId");
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartNum(cartSkuNum);
  };
  const discoverShow = async () => {
    const res = await isSwitchBatch({ bwlCodes: ["B-ZCDiscoveryPageEntry"] });
    const isHit = !!res?.data[0]?.isHit;
    setShowDiscovery(isHit);
    // setShowDiscovery(true);
  };
  const setTitle = () => {
    document.title = fromPage === "productDetail" ? "实拍专区" : "逛一逛";
  };
  const init = async () => {
    setTitle();
    await entryShop();
    queryGetCartTotal();
    discoverShow();
  };
  const pageviewTrack = () => {
    if (tab === "2") {
      myTrack.trackPageView({ tab: 2, fromPage: fromPage });
    }
    if (tab === "1") {
      myTrack.trackPageView({ tab: 1, fromPage: fromPage });
    }
  };
  React.useEffect(() => {
    init();
  }, []);
  useActivate(() => {
    console.log("navigatesearch", search, tab);
    setTitle();
    queryGetCartTotal();
    const promoteTab = sessionStorage.getItem("promoteTab");
    eventManager.publish<TabchangeEventData>(EventName.Tabchange, { tabs: promoteTab || "2" });
    // 从其他页面跳转过来
    pageviewTrack();
  });
  React.useEffect(() => {
    // 第一次 & 切换tab
    pageviewTrack();
    if (tab !== active) {
      setActive(tab || "1");
    }
  }, [location.search]);
  const getCart = (e) => {
    // @param { cartNum: Number }
    setCartNum(e.cartNum);
  };
  const gotoSearch = () => {
    const page_name = active === "1" ? "种草社区_发现" : "种草社区_推荐";
    localStorage.removeItem("search_tag");
    console.log(
      `/mx-shop-micro/enticeSearch?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
        "distributorId",
      )}&page_name=${page_name}`,
    );
    if (search.get("distributorId")) {
      navigate(
        `/mx-shop-micro/enticeSearch?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}&page_name=${page_name}`,
      );
    } else {
      navigate(
        `/mx-shop-micro/enticeSearch?shopId=${shopId}&channel=h5Mall&page_name=${page_name}`,
      );
    }
  };
  const changeTabs = (id: string) => {
    sessionStorage.setItem("promoteTab", id);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const searchParams = url.searchParams;
    console.log("searchParams", searchParams);
    searchParams.set("tab", id);
    if (id === "1") {
      searchParams.delete("materialNo");
      myTrack.trackPageView({ tab: 1, fromPage: fromPage });
    }
    if (id === "2") {
      const videoPlayer = document.getElementById("video-player") as any;
      if (videoPlayer) {
        videoPlayer.play();
      }
      myTrack.trackPageView({ tab: 2, fromPage: fromPage });
    }
    if (id !== active) {
      eventManager.publish<TabchangeEventData>(EventName.Tabchange, { tabs: id });
    }
    const newUrl = url.toString();
    history.replaceState(null, document.title, newUrl);
    setActive(id);
  };
  const search1 =
    "https://akim-oss.aikucun.com/371a5384044948994735af6426ee4634f70273de_1700035063982_58.png";
  const search2 =
    "https://akim-oss.aikucun.com/f04090805c6eebdfc7f9cb69b81f3e44c3a3636a_1700044454438_19.png";
  const cart1 =
    "https://akim-oss.aikucun.com/371a5384044948994735af6426ee4634f70273de_1700102728165_73.png";
  const cart2 =
    "https://akim-oss.aikucun.com/371a5384044948994735af6426ee4634f70273de_1701853962351_7.png";
  const toCart = React.useCallback(async () => {
    if (search.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2`);
    }
  }, []);
  return (
    <div className={styles["promote-container"]}>
      {fromPage !== "productDetail" && (
        <>
          <div className={`${styles["tab-list"]} ${active === "2" ? styles["tab-list2"] : ""}`}>
            <div className={styles["icon-area"]}>
              <img
                onClick={gotoSearch}
                className={styles["search-icon"]}
                src={active === "1" ? search1 : search2}
              />
              <div className={styles["cart-area"]} onClick={toCart}>
                {cartNum > 0 && <div className={styles["cart-num"]}>{cartNum}</div>}
                <img className={styles["cart-icon"]} src={active === "1" ? cart2 : cart1} />
              </div>
            </div>
            {showDiscovery &&
              tabs.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`${styles["item"]} ${active === item.id ? styles["active"] : ""}`}
                    onClick={() => {
                      changeTabs(item.id);
                    }}
                  >
                    {item.name}
                  </div>
                );
              })}
          </div>
          {/* <div className={styles["ocupy-area"]}></div> */}
        </>
      )}
      <div style={{ display: active === "1" ? "block" : "none" }}>
        <Discovery activeTab={active} />
      </div>
      <div style={{ display: active === "2" ? "block" : "none" }}>
        <Recommend activeTab={active} getCart={getCart} />
      </div>
    </div>
  );
}

export default Promote;
