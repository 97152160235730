import useNavigation, { IOptions } from "@/utils/useNavigate";
import React from "react";

import styles from "./index.module.less";

import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";

const Navigation: React.FC = () => {
  const { navigate2H5 } = useNavigation();

  const goHome = () => {
    const options: IOptions = {
      path: "/mx-shop-micro/home",
      is_micro_app: true,
    };
    navigate2H5(options);
  };
  const goBack = () => {
    if (window.history.length === 1) {
      goHome();
    } else {
      navigate2H5(-1);
    }
  };

  return (
    <div className={styles["nav"]}>
      <div className={styles["nav-box"]}>
        <div className={styles["nav-box__image-box"]} onClick={goBack}>
          <img src={BackPng} className={styles["image-box__image"]} />
        </div>
        <div className={styles["nav-box__image-box"]} onClick={goHome}>
          <img src={HomePng} className={styles["image-box__image"]} />
        </div>
      </div>
    </div>
  );
};
export default Navigation;
