import { getTabList } from "@/api/market";
import exposure from "@/utils/expose/exposure";
import { getUserRole } from "@/utils/identity";
import { hostEnv } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { ShopCardV2 } from "@akc/mx-taro-h5-components";
import { InfiniteScroll } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./ShopList.module.less";

interface Props {
  setCardList?: (any) => void;
  setShowEmptyStatus?: (bool) => void;
  trackData?: any;
}

const ShopList: React.FC<Props> = ({ setCardList, setShowEmptyStatus, trackData }) => {
  const track = Track();
  const { navigate2Product, navigate2LiveRoom, navigate2Store } = useNavigation();

  const [search] = useSearchParams();
  const actId = search.get("actId") || "";

  const [shopList, setShopList] = useState<any[]>([]);
  const pageNum = useRef<number>(1);
  const currentRequestId = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const isLoading = useRef(false);

  const onShopAction = (e, index?: any) => {
    console.log("点击店铺回调", e);
    if (index >= 0) {
      track.track("resource_click", {
        page_name: "店铺聚合",
        previous_page_name: search.get("page_name"),
        domain: "B2R旗舰店",
        module: "店铺列表",
        resource_content: e.shopName,
        resource_rank: index,
        merchant_shop_code: e.shopCode,
      });
    } else {
      track.track("resource_click", {
        page_name: "店铺聚合",
        previous_page_name: search.get("page_name"),
        domain: "B2R旗舰店",
        resource_content: "B2R旗舰店",
        module: "关注好店",
        merchant_shop_code: e.shopCode,
        btn_name: e.shopName,
      });
    }
    navigate2Store({
      merchantShopCode: e.shopCode,
      shopCode: e.shopCode,
      page_name: encodeURIComponent("店铺聚合"),
      productType: 1,
    });
    // if (search.get("distributorId")) {
    //   navigater(
    //     `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&distributorId=${search.get(
    //       "distributorId",
    //     )}&shopId=${search.get("shopId")}&page_name=${encodeURIComponent(
    //       "店铺聚合",
    //     )}&productType=1`,
    //   );
    // } else {
    //   navigater(
    //     `../mx-shop-micro/decorate?merchantShopCode=${e.shopCode}&shopId=${search.get(
    //       "shopId",
    //     )}&page_name=${encodeURIComponent("店铺聚合")}&productType=1`,
    //   );
    // }
  };
  const onProductAction = (e, index) => {
    console.log("点击商品回调", e);
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      activity_spu_code: e.activitySpuId,
      activity_id: e.activityId,
      sku_id: e.selectSkuId,
      btn_name: "商品点击",
      resource_rank: index,
      merchant_shop_code: e.shopCode,
    });
    navigate2Product({
      activityId: e.activityId,
      activitySpuId: e.activitySpuId,
      selectSkuId: e.selectSkuId,
    });
  };
  const onStudyTourMaterialAction = (e) => {
    console.log("点击游学素材图片/视频回调", e);
  };
  const onLiveAction = (e, index) => {
    console.log("点击直播回调", e);
    track.track("resource_click", {
      page_name: "店铺聚合",
      previous_page_name: search.get("page_name"),
      domain: "B2R旗舰店",
      module: "店铺列表",
      btn_name: "直播点击",
      live_no: e.liveNo,
      resource_rank: index,
      merchant_shop_code: e.shopCode,
    });
    navigate2LiveRoom({
      liveId: e.liveNo,
      liveStatus: e.liveStatus,
    });
  };

  const updateShopList = async (requestId: number) => {
    if (!actId) {
      return;
    }
    isLoading.current = true;
    const res = await getTabList({
      pageId: `${hostEnv}_Shop`,
      searchParams: {
        pageNum: pageNum.current,
        pageSize: 10,
        promoActivityIds: [actId],
        searchRequestId: requestId,
        scene: "SALES_REBATE_PAGE",
        isNeedSpuIds: true,
      },
      voConfig: {
        withProduct: true,
      },
    });
    if (requestId !== currentRequestId.current) {
      return;
    }
    setHasNext(res?.data?.hasNext ?? false);
    let list = res?.data?.result || [];
    if (pageNum.current > 1 && shopList.length > 0) {
      list = shopList.concat(list);
    }
    setShopList(list);
    setCardList && setCardList(list);
    setShowEmptyStatus && setShowEmptyStatus(pageNum.current === 1 && list.length === 0);
    isLoading.current = false;
    pageNum.current = (res?.data?.pageIndex ?? 0) + 1;
  };

  const loadMore = async () => {
    if (typeof pageNum === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await updateShopList(currentRequestId.current);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < shopList.length; i++) {
        const dom = document.getElementById(`market-${shopList[i]["shopCode"]}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
        if (!flag) {
          clearInterval(interval);
        }
      }
    }, 1000);
  }, [shopList?.length]);

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className={styles["shop-list-wrap"]}>
      {shopList?.length > 0 && (
        <div className={styles.cards}>
          {shopList.map((item: any, index) => {
            return (
              <div
                id={`market-${item.shopCode}`}
                data-param={JSON.stringify({
                  ...trackData,
                  module: "店铺列表",
                  resource_rank: index,
                  merchant_shop_code: item?.shopCode,
                  resource_content: item?.shopName,
                })}
                data-eventid={`market-${item?.shopCode}`}
                key={index}
                className={styles.card_item}
              >
                <ShopCardV2
                  nameFrontTagLocationCode={`${hostEnv}_Shop_ShopNameFront`}
                  mainLocationCode={`${hostEnv}_Shop_ShopNameDown`}
                  shopInfo={item}
                  index={index}
                  sign="market"
                  onShopAction={(e) => onShopAction(e, index)}
                  onProductAction={(e) => onProductAction(e, index)}
                  onStudyTourMaterialAction={onStudyTourMaterialAction}
                  onLiveAction={(e) => onLiveAction(e, index)}
                  labelLocationVOList={item?.labelLocationVOList}
                  profitTag="赚"
                  role={getUserRole()}
                  terminal="h5"
                />
              </div>
            );
          })}
        </div>
      )}
      <InfiniteScroll loadMore={loadMore} hasMore={hasNext} />
    </div>
  );
};

export default ShopList;
