import Track from "@/utils/track";
import AKJS from "@akc/akjs";
import axios from "axios";
import { Provider } from "mobx-react";
import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import stores from "./store";
import captchaConfig from "./utils/captcha/config";
import { CommonContextProvider, useCommonContext } from "./utils/context/common";
import { platform } from "./utils/platform";
import { getBaseurl, getUrlParam } from "./utils/url-parse";

console.log("项目 名称：mx-shop-micro");

sessionStorage.setItem("wxShareUrl", window.location.href.split("#")[0]);

declare global {
  interface Window {
    exposeObserver: IntersectionObserver;
    collectEvent: any;
    _dx: any;
  }
}

const App = () => {
  const timeoutIds = React.useRef<Map<number | string, any>>(new Map());
  const [loading, setLoading] = React.useState(true);

  const { track } = useCommonContext();

  const getCookie = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  };

  // 设置全局曝光监听器
  React.useEffect(() => {
    const track = Track();
    window.exposeObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const trackId = entry.target.getAttribute("data-track-id");
          if (!trackId) {
            return;
          }
          if (entry.intersectionRatio >= 0.5) {
            const timeoutId = setTimeout(() => {
              timeoutIds.current.delete(trackId);
              track.track(
                "resource_expose",
                JSON.parse(entry.target.getAttribute("data-track-data") ?? ""),
              );
            }, 500);
            timeoutIds.current.set(trackId, timeoutId);
          } else {
            clearTimeout(timeoutIds.current.get(trackId));
            timeoutIds.current.delete(trackId);
          }
        });
      },
      { threshold: 0.5 },
    );
  }, []);

  //初始化信息
  const InitInfo = async () => {
    if (platform.isApp) {
      const res: any = await AKJS.ready().then(() => {
        return AKJS.info.account();
      });
      stores.appStore.token = res.data.token;
      stores.appStore.channel = "akcApp" as any;
    } else if (platform.isMini) {
      stores.appStore.token = null;
      stores.appStore.channel = "xdApplets" as any;
      if (getUrlParam()?.["plt"] === "saas") {
        stores.appStore.channel = "SaaSApplets" as any;
      }
      if (getUrlParam()?.["plt"] === "atuan") {
        stores.appStore.channel = "tuanApplets" as any;
      }
      stores.appStore.token = getUrlParam()?.["token"] as any;
    } else {
      if (
        getUrlParam()?.["shopId"] &&
        getUrlParam()?.["shopId"] !== "undefined" &&
        getUrlParam()?.["shopId"] !== "null"
      ) {
        await axios({
          method: "get",
          url: getUrlParam()?.["distributorId"]
            ? `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${getUrlParam()?.["shopId"]
            }?distributorId=${getUrlParam()?.["distributorId"]}`
            : `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${getUrlParam()?.["shopId"]}`,
          headers: {
            "app-login-channel": "xdWxH5",
            "auth-token": getCookie("mstoken") || process.env.REACT_APP_MSTOKEN,
            mstoken: getCookie("mstoken") || process.env.REACT_APP_MSTOKEN,
            "AKC-H5": "H5",
          },
          params: {},
        }).then((res) => {
          localStorage.setItem("userinfo", JSON.stringify(res?.data?.data));
          const registerSuperPropertyParams = {
            currentRole: res?.data?.data?.currentRole,
          };
          // saas 小程序增加租户id上报
          const tenantId = getUrlParam()?.["tenantId"];
          if (tenantId) {
            registerSuperPropertyParams["tenant_id"] = tenantId;
          }
          track.registerSuperProperty?.(registerSuperPropertyParams);
        });
      }
    }
    setLoading(false);
  };

  // 获取设备指纹
  const setConstID = () => {
    const options = {
      appId: captchaConfig.appId, // 唯一标识，必填
      server: captchaConfig.constIDServer, // ConstId 服务接口，可选
    };

    // eslint-disable-next-line no-underscore-dangle
    window._dx.ConstID(options, function (err, token) {
      if (err) {
        console.log("323232error: " + err);
        return;
      }
      localStorage.setItem("constID", token);
    });
  };

  React.useLayoutEffect(() => {
    InitInfo();
    setConstID();
  }, []);

  return (
    <CommonContextProvider>
      <Provider {...stores}>{!loading && <RouterProvider router={router} />}</Provider>
    </CommonContextProvider>
  );
};

export default App;
