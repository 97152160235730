import { YouXueSignUpDetail } from "@akc/biz-api";
import moment from "moment";
import { useEffect, useState } from "react";

import { ProgInfo } from "../components/audit-status";

export const useAuditStatus = (detail?: YouXueSignUpDetail) => {
  // 进度列表
  const [progList, setProgList] = useState<ProgInfo[]>([]);

  // 显示取消报名按钮
  const [cancelEnable, setCancelEnable] = useState(false);

  // 显示去确认按钮
  const [confirmEnable, setConfirmEnable] = useState(false);

  useEffect(() => {
    if (!detail) {
      return;
    }
    const progs: ProgInfo[] = [];
    // 处理提交报名步骤逻辑
    const signUpTime = Number(detail?.memberSignUpInfo?.signUpTime ?? 0);
    progs.push({
      title: "提交报名表",
      time: signUpTime > 0 ? moment(signUpTime).format("MM-DD") : undefined,
      status: 1,
      key: "signUp",
    });
    // 处理审核步骤逻辑
    const auditStatus = detail?.memberSignUpInfo?.auditStatus;
    const auditTime = Number(detail?.memberSignUpInfo?.auditTime ?? 0);
    const auditTimeFormat = auditTime > 0 ? moment(auditTime).format("MM-DD") : undefined;

    // 审核通过
    if (auditStatus === 1) {
      progs.push({
        title: "审核通过",
        time: auditTimeFormat,
        status: 1,
        key: "audit",
      });
    } else if (auditStatus === 2) {
      // 审核拒绝
      progs.push({
        title: "审核未通过",
        time: auditTimeFormat,
        status: 3,
        key: "audit",
      });
    } else {
      // 待审核
      progs.push({
        title: "审核中",
        time: auditTimeFormat,
        status: 4,
        key: "audit",
      });
    }

    // 处理行程确认步骤逻辑
    const confirmStatus = detail?.memberSignUpInfo?.confirmStatus;
    const confirmTime = Number(detail?.memberSignUpInfo?.confirmTime ?? 0);
    const confirmTimeFormat = confirmTime > 0 ? moment(confirmTime).format("MM-DD") : undefined;
    if (confirmStatus === 1) {
      // 行程确认
      progs.push({
        title: "行程确认",
        time: confirmTimeFormat,
        status: 1,
        key: "confirm",
      });
    } else if (confirmStatus === 2) {
      // 确认不参加
      progs.push({
        title: "确认不参加",
        time: confirmTimeFormat,
        status: 3,
        key: "confirm",
      });
    } else if (confirmStatus === 3) {
      // 超时未确认
      progs.push({
        title: "超时未确认",
        time: confirmTimeFormat,
        status: 3,
        key: "confirm",
      });
    } else {
      // 行程未确认
      progs.push({
        title: "行程待确认",
        time: confirmTimeFormat,
        status: auditStatus === 1 ? 4 : 2,
        key: "confirm",
      });
    }

    // 处理游学完成步骤逻辑
    const signFlag = detail?.memberSignUpInfo?.signFlag;
    const signTime = Number(detail?.memberSignUpInfo?.signTime ?? 0);
    const signTimeFormat = signTime > 0 ? moment(signTime).format("MM-DD") : undefined;
    // 游学完成
    if (signFlag === 1) {
      progs.push({
        title: "游学结束",
        time: signTimeFormat,
        status: 1,
        key: "signDown",
      });
    } else {
      // 游学中
      progs.push({
        title: "游学中",
        time: signTimeFormat,
        status: confirmStatus === 1 ? 4 : 2,
        key: "signDown",
      });
    }

    // 查找当前进度和按钮
    const step = progs.find((o) => o.status !== 1);

    // 刷新
    setCancelEnable(step?.key === "audit" && step.status === 4);
    setConfirmEnable(step?.key === "confirm" && step.status === 4);
    setProgList(progs);
  }, [detail]);

  return {
    progList,
    cancelEnable,
    confirmEnable,
  };
};
