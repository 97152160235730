import { queryAddressList } from "@/api/product";
import { AddressListModel } from "@/pages/product-detail/model/AddressListModel";
import Cookie from "js-cookie";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

/**
 * 获取地址列表
 */
export const useAddressList = () => {
  const [search] = useSearchParams();
  const [addressList, setAddressList] = useState<AddressListModel[]>();
  //当前地址id
  const [selectAddressId, setSelectAddressId] = useState<string>();
  const [isLoadAddress, setIsLoadAddress] = useState<boolean>(false);
  const setAddressId = (addressId) => {
    const openId = Cookie.get("msOpenId");
    localStorage.setItem("addressId", addressId);
    localStorage.setItem(`${search.get["shopId"]}:${openId}:addressId`, addressId);
  };

  const getAddressId = () => {
    const openId = Cookie.get("msOpenId");
    return (
      localStorage.getItem(`${search.get["shopId"]}:${openId}:addressId`) ||
      localStorage.getItem("addressId")
    );
  };

  const fetchAddressList = async () => {
    try {
      const res = await queryAddressList();
      setIsLoadAddress(true);
      setAddressList(res);
      if (res?.length ?? 0 > 0) {
        const saveAddressId = getAddressId();
        const defaultAddress = res?.filter((it) => it.isDefault === 1);
        if (saveAddressId && saveAddressId !== "undefined") {
          const selected = res?.filter((it) => it.id === saveAddressId);
          if (selected?.length) {
            //之前存储的地址
            setSelectAddressId(selected?.[0]?.id ?? "");
          }
        } else if (defaultAddress?.length) {
          // 设置默认地址
          setSelectAddressId(defaultAddress?.[0]?.id ?? "");
        } else {
          //地址列表的第一个
          setSelectAddressId(res?.[0]?.id ?? "");
        }
      } else {
        setSelectAddressId("");
      }
    } catch (error) {
      setIsLoadAddress(true);
      setAddressList([]);
      setSelectAddressId("");
    }
  };

  //更新选中的地址
  const updateSelectAddress = (addressId?: string, index?: number) => {
    setAddressId(addressId);
    setSelectAddressId(addressId);
  };

  return { addressList, selectAddressId, updateSelectAddress, fetchAddressList, isLoadAddress };
};
