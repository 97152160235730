import useNavigation from "@/utils/useNavigate";
import React, { useEffect, useRef } from "react";
import { useActivate, useUnactivate } from "react-activation";
import Swiper from "react-id-swiper";
import "./B2RShopList.less";
import styles from "./B2RShopList.module.less";

type Props = {
  dataList: Array<any>;
};

const params = {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 30,
  speed: 3500,
  loop: true,
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
  },
};

const B2RShopList: React.FC<Props> = ({ dataList }) => {
  const swiperRef = useRef(null);

  useActivate(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      setTimeout(() => {
        const swiperInstance = swiperRef.current;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        swiperInstance?.swiper?.autoplay.start();
      }, 1000);
    }
  });
  useUnactivate(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      swiperInstance?.swiper?.autoplay.pause();
    }
  });

  const { navigate } = useNavigation();

  const toShopList = () => {
    navigate({
      h5Options: {
        url: "/mx-shop-micro/shopList",
      },
    });
  };

  const toShopDetail = (merchantShopCode) => {
    navigate({
      h5Options: {
        url: `/mx-shop-micro/decorate?merchantShopCode=${merchantShopCode}`,
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.aside} onClick={toShopList}>
          查看全部
        </div>
      </div>
      <div className="b2r-shop-list">
        <Swiper {...params} ref={swiperRef}>
          {dataList.map((item) => (
            <div
              key={item.shopCode}
              className={styles.itemWrap}
              onClick={() => toShopDetail(item.shopCode)}
            >
              <div className={styles.item}>
                <img src={item.shopLogo} className={styles.img} />
                <div className={styles.title}>{item.shopName}</div>
                <div className={styles.desc}>{item.shopDesc}</div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default B2RShopList;
