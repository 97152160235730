import { getYouxueSignUpDetail, youxueSignUp, youxueSignUpConfirm } from "@/api/youxue";
import { navigate } from "@/utils/navigate";
import { onClosePage } from "@/utils/page_utils";
import { platform } from "@/utils/platform";
import { setTitle } from "@/utils/set-title";
import { showShareUrlDialog } from "@/utils/share/app_utils";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { YouXueSignUpDetail } from "@akc/biz-api";
import { Toast } from "antd-mobile";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpSuccessCallback } from "../../sign-up/hooks/useSignUp";
const TAG = "useInvite";

// 常量定义
const ERROR_CODE_YX4001 = "YX4001";
const STUDY_TOUR_ID = "studyTourId";
const DELAYED_NAVIGATION_DELAY = 1500;

// 游学邀约
const useInvite = (
  sureJoinCallback?: SignUpSuccessCallback,
  signUpCallback?: SignUpSuccessCallback,
) => {
  const { navigate2Home } = useNavigation();
  const [searchParams] = useSearchParams();
  const studyTourId = searchParams.get(STUDY_TOUR_ID) ?? "";
  const basicInfoRef = useRef<any>(null);
  const [showCheckStatus, setShowCheckStatus] = useState(false);
  const [signUpDetail, setSignUpDetail] = useState<YouXueSignUpDetail>();
  const [errorPage, setErrorPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate2HomeDelayed = (delay = DELAYED_NAVIGATION_DELAY) => {
    setTimeout(() => {
      navigate2Home();
    }, delay);
  };

  // 业务异常处理
  const handleBizError = (res: any) => {
    console.log(TAG, "handleBizError,code:", res?.code);
    Toast.show({ content: res?.message || "服务异常，请稍后重试！", duration: 300 });
    navigate2HomeDelayed();
  };

  // 通用异常处理
  const handleError = (desc: string) => {
    console.log(TAG, "handleError desc:", desc);
    setErrorPage(true);
  };

  const fetchDetailInfo = async () => {
    console.log(TAG, "fetchDetailInfo studyTourId :", studyTourId);
    if (!studyTourId || studyTourId.length === 0) {
      setErrorPage(true);
      return;
    }
    setIsLoading(true);
    try {
      const res = await getYouxueSignUpDetail({
        studyTourId: studyTourId,
        scene: "INVITE",
      });

      if (res?.code === ERROR_CODE_YX4001) {
        handleBizError(res);
        return;
      }
      if (res && res.data) {
        setErrorPage(false);
        setSignUpDetail(res.data);
      } else {
        handleError("res or data is empty");
      }
    } catch (error) {
      console.error("catch error info:", error);
      handleError("catch error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setTitle({ appOptions: { title: "游学邀约函" }, h5Options: { title: "游学邀约函" } });
    fetchDetailInfo();
  }, []);

  useEffect(() => {
    const { isApp } = platform;
    if (isApp && signUpDetail) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.navigationBar.config.rightBtns", {
          btns: [
            {
              iconUrl:
                "https://xiangdian-oss.aikucun.com/app_akc_ios/4e0c103e51627588bc2007a84fb5773b8972461f_1684812474126_96.png",
              onclick: () => {
                let forwardUrl = `${origin}/mx-shop-micro/yx/invite?studyTourId=${studyTourId}`;
                showShareUrlDialog({
                  title: signUpDetail.title,
                  description: signUpDetail.subTitle,
                  logoUrl: signUpDetail.forwardImage,
                  webPageUrl: forwardUrl,
                });
              },
            },
          ],
        });
        return;
      });
    }
  }, [signUpDetail]);

  // 邀约确认提交
  const requestInvitationSignup = useCallback(async () => {
    const studyTourIds = studyTourId?.split(",").map((item) => {
      return item;
    });
    const res = await youxueSignUp({
      ...basicInfoRef.current?.getSignUpContent(),
      studyTourIds: studyTourIds,
      signUpType: 2,
    });
    if (res && res.signUpSuccess) {
      signUpCallback?.();
    } else {
      Toast.show({
        content: res?.signUpFailReason || "请求失败",
        maskClickable: false,
        position: "center",
        maskStyle: { zIndex: 9999 },
      });
    }
  }, []);

  // 暂不参加
  const notJoin = async () => {
    console.log(TAG, "notJoin", signUpDetail?.signUpType);
    if (signUpDetail?.signUpType === 2) {
      // 定向邀约
      youxueSignUpConfirm({
        confirmStatus: 2,
        studyTourId,
      })
        .then(() => {
          onClosePage();
        })
        .catch(console.error);
    } else {
      onClosePage();
    }
  };

  // 确认参加
  const comfirmJoin = async () => {
    console.log(TAG, "comfirmJoin", signUpDetail?.signUpType);
    if (signUpDetail?.signUpType === 0) {
      // 非定向
      setShowCheckStatus(true);
      const result = basicInfoRef.current.checkContentIsValid();
      if (!result) {
        Toast.show("您有部分信息尚未补充完整，请继续填写~");
      } else {
        console.log(TAG, "comfirmJoin 内容合法执行请求");
        requestInvitationSignup();
      }
    } else if (signUpDetail?.signUpType === 2) {
      // 定向:确认
      const result = await youxueSignUpConfirm({
        studyTourId: studyTourId,
        confirmStatus: 1,
      });
      if (!result) {
        Toast.show(result?.message || "请求异常");
      } else {
        sureJoinCallback?.();
      }
    }
  };

  // 去看看
  const goSee = () => {
    console.log(TAG, "goSee");
    navigate({
      h5Options: {
        url: "/mx-shop-micro/yx/calendar",
      },
      appOptions: {
        url: `${origin}/mx-shop-micro/yx/calendar`,
      },
      miniOptions: {
        url: `${origin}/mx-shop-micro/yx/calendar`,
      },
    });
  };

  return {
    basicInfoRef,
    showCheckStatus,
    setShowCheckStatus,
    comfirmJoin,
    signUpDetail,
    goSee,
    notJoin,
    errorPage,
    isLoading,
  };
};

export default useInvite;
