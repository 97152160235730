import { getRecommendProductList, ProductCard as ProductCartData, ProductParams } from "@/api/home";
import TITLE_ICON from "@/assets/icon_line.png";
import ProductWaterfall from "@/components/product-waterfall";
import { getCookie, platform } from "@/utils/platform";
import { ProductModel } from "@akc/biz-api";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

type Props = {
  activitySpuIds: string[];
  categoryCodeList: string[];
};

const RecommendProduct: React.FC<Props> = ({ activitySpuIds, categoryCodeList }) => {
  const pageId = platform.isMini ? "Mini_Program_ProductList" : "H5_ProductList";
  const titleDownLocationCode = platform.isMini
    ? "Mini_Program_ProductList_TitleDown"
    : "H5_ProductList_TitleDown";
  const titleFrontLocationCode = platform.isMini
    ? "Mini_Program_ProductList_TitleFront"
    : "H5_ProductList_TitleFront";
  const pictureRightLocationCode = platform.isMini
    ? "Mini_Program_ProductList_ProductPicRight"
    : "H5_ProductList_ProductPicRight";

  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [productParams, setProductParams] = useState<ProductParams>();
  const [hasNext, setHasNext] = useState<boolean>(true);
  const loadMorePromise = useRef<any>();
  const nextPage = useRef<number>(0);
  const idsClick = useRef<string[]>([]);
  const lastActivitySpuCode = useRef<string>("");
  const [search] = useSearchParams();
  const trackData = {
    page_name: "物流详情",
    domain: "精选推荐物流详情",
    module: "精选推荐物流详情",
    resource_type: "精选推荐物流详情",
    resource_name: "精选推荐物流详情",
    resource_content: "商品",
    recommend_requestid: "recommend_requestid",
    previous_page_name: search.get("previous_page_name") ?? "未知",
  };

  useEffect(() => {
    const searchRequestId =
      (getCookie("msUserCode") ?? search.get("userId") ?? "userId") + Date.now();
    setProductParams({
      idsClick: idsClick.current.join(),
      lastActivitySpuCode: lastActivitySpuCode.current,
      pageId,
      pageIndex: 1,
      pageSize: 10,
      scene: "logisticsDetail",
      searchRequestId,
      activitySpuIds,
      categoryCodeList,
    });
  }, []);

  useEffect(() => {
    if (!productParams) {
      return;
    }
    getRecommendProductList(productParams)
      .then((data) => {
        setProductList((productList) => {
          return [
            ...(productList || []),
            ...(data?.result || []).map((item, index) => ({
              ...item,
              rank: productList.length + index + 1,
              trackData: {
                ...trackData,
                recommend_requestid: productParams.searchRequestId,
                resource_id: item.activitySpuId,
                click_activity_id: item.activityId,
                click_activity_spu_code: item.activitySpuId,
                click_sku_id: item.selectSkuId,
              },
            })),
          ];
        });
        if (data?.result?.length) {
          nextPage.current = data.pageIndex + 1;
          lastActivitySpuCode.current = data.result[data.result.length - 1]?.activitySpuId || "";
          loadMorePromise.current?.resolve();
          setHasNext(data.hasNext);
        } else {
          setHasNext(false);
        }
      })
      .catch(console.error);
  }, [productParams]);

  const loadMore = async () => {
    if (!productParams) {
      return;
    }
    const searchRequestId = (getCookie("msUserCode") ?? "userId") + Date.now();
    setProductParams({
      idsClick: idsClick.current.join(),
      lastActivitySpuCode: lastActivitySpuCode.current,
      pageId,
      pageIndex: nextPage.current,
      pageSize: 10,
      scene: "logisticsDetail",
      searchRequestId,
      activitySpuIds,
      categoryCodeList,
    });
    await new Promise((resolve, reject) => {
      loadMorePromise.current = { resolve, reject };
    });
    loadMorePromise.current = null;
  };

  return (
    <>
      {productList.length > 0 && (
        <div className={styles["recommend"]}>
          <div className={styles["recommend-header"]}>
            <img className={styles["recommend-header__icon"]} src={TITLE_ICON} alt="" />
            <div className={styles["recommend-header__title"]}>精选推荐</div>
            <img className={styles["recommend-header__icon"]} src={TITLE_ICON} alt="" />
          </div>
          <ProductWaterfall
            productList={productList}
            hasNext={hasNext}
            loadMore={loadMore}
            topRightLocationCode={pictureRightLocationCode}
            titleFrontLocationCode={titleFrontLocationCode}
            titleBottomLocationCode={titleDownLocationCode}
            provideBackground={false}
            trackData={trackData}
            btnClickName="resource_click"
          />
        </div>
      )}
    </>
  );
};

export default RecommendProduct;
