import { getBaseurl, urlQueryObject } from "@/utils/url-parse";
import { Request } from "./request";
//获取优惠券信息
export function shopPlusActivityList(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  // url: `http://127.0.0.1:4523/m1/2845608-0-default/api/mshop/mshop-aggr-prod/outer/activity/list`,
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/activity/list`,
    method: "post",
    data: params,
  });
}

//获取优惠券信息
export function getProfitadditional(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  // url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,
  // url: `http://127.0.0.1:4523/m1/2845608-0-default/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,

  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,

    method: "post",
    data: params,
  });
}

//获取高拥加码更多列表
export function getHighCommission(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  // url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,
  return Request({
    // url: `http://127.0.0.1:4523/m1/2845608-0-default/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission/v2`,
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission/v2`,

    method: "post",
    data: params,
  });
}

//获取更多商品
export function getProductList(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }

  // url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,
  return Request({
    // url: `http://127.0.0.1:4523/m1/2845608-0-default/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission/v2`,
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/list`,

    method: "post",
    data: params,
  });
}

//获取高拥加码更多列表
export function getShopNaviList(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }

  // url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission`,
  return Request({
    // url: `http://127.0.0.1:4523/m1/2845608-0-default/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/highcommission/v2`,
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/common/queryShopOrBrandNavigationList`,

    method: "post",
    data: params,
  });
}

/**
 * 用户角色 currentRole
 */
export enum currentRole {
  // 顾客
  customer = "1",
  // 店主
  storeOwner = "2",
  // 店长
  storeManager = "3",
  // 管理员
  admin = "4",
}
