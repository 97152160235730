import { PaginationResponseData, TopListResponseData } from "@/types";
import { getBaseurl, urlQueryObject } from "@/utils/url-parse";
import { Request, getQueryParams } from "./request";

//获取店铺信息
export function searchShopInfo(params, shopId, distributorId) {
  let url = `${getBaseurl()}/api/member/xdwxh5/member/infoV2/${shopId}`;
  if (distributorId) {
    url += `?distributorId=${distributorId}`;
  }
  return Request({
    url,
    method: "get",
    params,
  });
}

//获取页面信息
export function searchPageInfo(params) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/info`,
    method: "post",
    data: params,
  });
}
//获取弹幕列表
export function searchBarrageList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/atmosphere/list`,
    method: "get",
    params: params,
  });
}

//获取档期信息（基础组件）
export function searchActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/activity`,
    method: "post",
    data: params,
  });
}
//获取档期信息（导航组件）
export function searchNavActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/activity`,
    method: "post",
    data: params,
  });
}
//获取秒杀信息
export function searchSecKill(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/seckill`,
    method: "post",
    data: params,
  });
}
//获取优惠券信息
export function searchCoupon(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/coupon`,
    method: "post",
    data: params,
  });
}
//获取素材信息
export function searchMaterial(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/material`,
    method: "post",
    data: params,
  });
}
//获取商品信息
export function searchProduct(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/product`,
    method: "post",
    data: params,
  });
}
//获取导航档期信息
export function searchNavigationActivity(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/activity`,
    method: "post",
    data: params,
  });
}
//获取导航商品信息
export function searchNavigationProduct(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/navigation/product`,
    method: "post",
    data: params,
  });
}
//获取商家店铺
export function getShop(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/merchant-shop/info`,
    method: "post",
    data: params,
  });
}
//获取商家店铺的搭建页面
export function getShopPage(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
    params.customPageVersion = urlQuery["version"];
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/searchPageCodeByShopCode`,
    method: "get",
    params: params,
  });
}

//获取榜单信息
export function searchTopList(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/topList`,
    method: "post",
    data: params,
  });
}
//获取一级类目更多
export function searchCategoryMore(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brandWall/categoryOne/detailByPage`,
    method: "post",
    data: params,
  });
}
//获取一级类目详情
export function searchCategoryDetail(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/brandWall/mer/categoryOne/detail`,
    method: "post",
    data: params,
  });
}

// 获取帮单二级页
export function searchTopListV2(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request<PaginationResponseData<TopListResponseData>>({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/topList/v2`,
    method: "post",
    data: params,
  });
}

// 获取秒杀二级页
export function searchSecListV2(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request<PaginationResponseData<TopListResponseData>>({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/seckill/v2`,
    method: "post",
    data: params,
  });
}

// 获取优惠券二级页
export function searchCouponV2(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request<PaginationResponseData<TopListResponseData>>({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/activity/coupon/v2`,
    method: "post",
    data: params,
  });
}

// 关注店铺
export function subscribe(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/subscription/subscribe`,
    method: "post",
    data: params,
  });
}

// 取消关注店铺
export function unSubscribe(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/subscription/unsubscribe`,
    method: "post",
    data: params,
  });
}

export async function querySkin(): Promise<any> {
  const url = `${getBaseurl()}/api/shop/decoration/querySkinInfo`;
  const res = await Request({
    url,
    method: "get",
  });
  return res?.data;
}

//获取小卡素材
export function searchMaterialV2(params?: any) {
  const urlQuery = urlQueryObject();
  if (urlQuery["preview"] || urlQuery["preview"] === "true") {
    params.preview = true;
  }
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/custompage/component/common/queryMerShopMaterial`,
    method: "post",
    data: params,
  });
}

export type SkinInfo = {
  appbar?: any;
  tabber?: any;
  home?: {
    searchBtnBgColor: string;
    searchBorderColor: string;
    searchBtnTextColor: string;
    categoryTextColor: string;
    categorySelectedTextColor: string;
    categoryBg: string;
    categoryArrowColor: string;
    categoryArrowBg: string;
    categoryArrowBgImg: string;
    categorySelectedTextLineType: string;
    categorySelectedTextLineColor: string;
    categorySelectedTextLineColorDirect: string;
    categorySelectedTextLineColor1: string;
    categorySelectedTextLineColor2: string;
    diamondBg: string;
    diamondBgColor: string;
    diamondTextColor: string;
    diamondProgressColor: string;
    diamondProgressBgColor: string;
    supperBannerBg: string;
    supperBannerBgColor: string;
    appIndexSupperBannerBg: string;
    appIndexSupperBannerBgColor: string;
    appIndexSupperBannerBgLeft: string;
    appIndexSupperBannerBgRight: string;
    nagivationTextColor: string;
    nagivationIconColor: string;
    nagivationIconImg: string;
    nagivationBtnBgColor: string;
    licenseComplianceIcon: string;
    searchBgColor: string;
    searchIconColor: string;
    searchHintColor: string;
    appSearchCameraIconColor: string;
    tipPointColor: string;
    bottomTipColor: string;
    personPriceDescColor: string;
    personStatusColor: string;
    personStatusBgColor: string;
    homeMessageIconColor: string;
    homeMessageBgColor: string;
    homeMessageTextColor: string;
    personMessageIconColor: string;
    personMessageBgColor: string;
    personMessageTextColor: string;
    updateTime: string;
    status: number;
    updater: string;
    resourceThemeBg: string;
    resourceThemeBgColor: string;
    resourceHotSaleBg: string;
    resourceHotSaleBgColor: string;
    resourceBannerBg: string;
    resourceBannerBgColor: string;
    imageBgColor: string;
    productWindowBgColor: string;
    taskPanelBgColor: string;
    homeFeedPanelBgColor: string;
    todaySaleBg: string;
    todaySaleBgColor: string;
    brandsTogetherBgImg: string;
    brandsTogetherBgColor: string;
  };
};

export async function queryHomeSkin(): Promise<SkinInfo | undefined> {
  const res = await Request({
    url: `${getBaseurl()}/api/cms/assets/selectAppSkinInfoVersion?channel=H5MALL`,
    method: "post",
  });
  return res?.data;
}

export type TagData = {
  activityTagNo: string;
  activityTagName: string;
  type?: number;
  href?: string;
  subscriptType?: string;
  subscriptImg?: string;
  activityTagNameColor?: string;
  activityTagImg?: string;
  activityTagImgDropDown?: string;
  promotionLevel?: string;
  programIcon?: string;
  activityTagShowType?: number;
};

export async function getTabs(): Promise<TagData[]> {
  const shopId = getQueryParams(location.href)["shopId"];
  const res = await Request({
    url: `${getBaseurl()}/api/commodity/subscribe/v2/queryActivityTag/${shopId}`,
    method: "post",
  });
  return res?.data;
}

// const queryActivityGroup = obj => http.post(`/api/commodity/subscribe/v2/queryActivityTag/${obj.shopBizCode}`, null, {
//   URLSchema: '/api/commodity/subscribe/v2/queryActivityTag/:shopBizCode'
// });
