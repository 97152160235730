import useCouponAction, { CouponDisplayStatus, displayStatus4Coupon } from "@/utils/action/coupon";
import { Divider, Image } from "antd-mobile";
import React from "react";
// import { pxtorem } from "../../../utils/pxtorem";
import couBack from "@/assets/coupon_back.png";
import backOutPng from "@/assets/coupon_back_out.png";
import sharePng from "@/assets/coupon_share_btn.png";
import fetchedIconPng from "@/assets/fetched_icon.png";
import getPng from "@/assets/get_now.png";
import noStartPng from "@/assets/no_start.png";
import qiangPng from "@/assets/qiang.png";
import usePng from "@/assets/use.png";
import styles from "./index.module.less";

interface Props {
  coupon: any;
  pageData?: any;
  trackInfo?: any;
  share4H5Action: () => void;
  fetchSuccessAction: () => void;
}

const Coupon11Comp: React.FC<Props> = (props) => {
  const { couponBtnAction } = useCouponAction();
  // 点击按钮的响应
  const btnAction = async (coupon) => {
    couponBtnAction({
      pageData: props.pageData,
      coupon,
      layoutType: "11",
      share4H5Action: props.share4H5Action,
      fetchSuccessAction: props.fetchSuccessAction,
      trackInfo: props.trackInfo,
    });
  };

  const status = props.coupon.displayStatus ?? displayStatus4Coupon(props.coupon);
  if (status === CouponDisplayStatus.unknown) {
    return undefined;
  }
  if (status === CouponDisplayStatus.exhaust) {
    return (
      <div
        className={styles.coupon11_itemOut}
        style={{
          background: `url(${backOutPng}) no-repeat`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className={styles.price}>
          <div className={styles.symbol}>¥</div>
          <div className={styles.bigText}>{props.coupon?.quotaAmount}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.text}>
            满{props.coupon.baseAmount}减{props.coupon?.quotaAmount}
          </div>
          <Divider className={styles.divider} style={{ borderColor: "#eeeeee" }}></Divider>
          <div className={styles.date}>{props.coupon.timeDesc}</div>
          <div className={styles.limit}>{props.coupon.couponLimitDescList?.join("")}</div>
        </div>
        <div className={styles.btn}>
          <Image src={qiangPng} className={styles.out}></Image>
        </div>
      </div>
    );
  }
  let src: string;
  let className: string = styles.now;
  switch (status) {
    case CouponDisplayStatus.fetchNow:
      src = getPng;
      break;
    case CouponDisplayStatus.use:
      src = usePng;
      break;
    case CouponDisplayStatus.fetched:
      src = fetchedIconPng;
      className = styles.out;
      break;
    case CouponDisplayStatus.forward:
      src = sharePng;
      break;
    default:
      src = noStartPng;
      break;
  }
  return (
    <div
      className={styles.coupon11_item}
      style={{
        background: `url(${couBack}) no-repeat`,
        backgroundSize: "100% 100%",
      }}
      onClick={() => btnAction(props.coupon)}
    >
      <div className={styles.price}>
        <div className={styles.symbol}>¥</div>
        <div className={styles.bigText}>{props.coupon?.quotaAmount}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.text}>
          满{props.coupon.baseAmount}减{props.coupon?.quotaAmount}
        </div>
        <Divider className={styles.divider}></Divider>
        <div className={styles.date}>{props.coupon.timeDesc}</div>
        <div className={styles.limit}>{props.coupon.couponLimitDescList?.join("")}</div>
      </div>
      <div className={styles.btn}>
        <Image src={src} className={className}></Image>
      </div>
    </div>
  );
};

export default Coupon11Comp;
