import iconPause from "@/assets/pause.png";
import iconPlaying from "@/assets/paying.png";
import { pxtorem } from "@/utils/pxtorem";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
interface Props {
  videoUrl: string;
  thumbnail?: string;
  height: number;
  width: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
}

const VideoPlayComp: React.FC<Props> = (props) => {
  const paddingLeft = props?.paddingLeft ?? 0;
  const paddingRight = props?.paddingRight ?? 0;
  const rootRef = useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const [autoPlay, setAutoplay] = useState(false);

  const init = () => {
    if (rootRef.current) {
      const videoRef = rootRef.current;
      videoRef.addEventListener("ended", () => {
        videoRef.currentTime = 0;
        setPlaying(false);
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  // 视频控制bar
  const onTogglePlay = (e) => {
    e.stopPropagation();
    const videoRef = rootRef.current;
    if (videoRef) {
      setPlaying(videoRef.paused);
      videoRef.paused ? videoRef.play() : videoRef.pause();
    }
  };

  const setVideoSize = () => {
    const containerW = 375 - (paddingLeft ?? 0) - (paddingRight ?? 0);
    const videoW = props.width;
    const videoH = props.height;
    const styles: CSSProperties = {
      width: pxtorem(containerW),
      height: pxtorem(videoH * (containerW / videoW)),
    };
    return styles;
  };

  // 视频控件
  const renderVideo = () => {
    return (
      <video
        ref={rootRef}
        className={styles.video}
        style={setVideoSize()}
        src={props.videoUrl}
        controls={true}
        autoPlay={autoPlay}
        muted={muted}
        poster={props.thumbnail}
        disablePictureInPicture
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        webkit-playsinline="true"
        x-webkit-airplay="true"
        playsInline={true}
        x5-playsinline="true"
      >
        <source src={props.videoUrl} />
      </video>
    );
  };

  return <div className={styles.videoPlayC}>{renderVideo()}</div>;
};

export default VideoPlayComp;
