import { useEffect, useRef, useState } from "react";
import useActivityList from "./useActivityList";
import { StudyTourSignUpDayListInfo, YouXueSignUp } from "../../model";
import { Toast } from "antd-mobile";

export interface Tab {
  // 标识
  key?: string;
  // 标题
  title?: string;
}

const useStudyListTour = (props?: {
  title?: string;
  tabKey?: string;
  onStudyTourListAction?: (
    tabKey?: string,
    list?: StudyTourSignUpDayListInfo[],
    brandLogos?: string[],
    total?: number,
  ) => void;
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [detailVisible, setDetailVisible] = useState<boolean>(false);
  const selectStudyTourDetail = useRef<YouXueSignUp>();

  const { list, setList, brandLogos, total, loading } = useActivityList({
    title: props?.title,
  });

  useEffect(() => {
    if (!props?.onStudyTourListAction) {
      return;
    }
    props?.onStudyTourListAction(props?.tabKey, list, brandLogos.current, total.current);
  }, [list]);

  const checkCount = () => {
    let number = 0;
    list?.map((item) => {
      item.studyTourSignUpList?.forEach((i) => {
        if (i?.isSelect) {
          number += 1;
        }
      });
    });
    return number;
  };

  const unSelect = (signUpDetail?: YouXueSignUp) =>
    !signUpDetail?.signUpFlag || signUpDetail?.memberSignUpFlag;

  const unSelectWithStudyActivityEnd = (signUpDetail?: YouXueSignUp) => !signUpDetail?.signUpFlag;

  const onSelectStudyTourList = (signUpDetail?: YouXueSignUp, select?: boolean) => {
    if (!signUpDetail) {
      return;
    }
    list?.map((t) => {
      // 选中的是已选的 取消勾选
      t?.studyTourSignUpList?.forEach((item) => {
        if (item?.studyTourId === signUpDetail?.studyTourId) {
          if (unSelect(item)) {
            if (unSelectWithStudyActivityEnd(item)) {
              Toast.show(item?.signUpBanReason || "该场游学报名已经裁止，请再看看其他的活动吧");
            }
            return;
          }
          if (!item.isSelect && total.current <= checkCount()) {
            Toast.show("已超出可选数量，请清理后再选择");
            return;
          }
          if (select) {
            item.isSelect = true;
            return;
          }
          item.isSelect = !item?.isSelect || false;
        }
      });
      setList([...list]);
    });
  };

  const onSelectSureAction = (signUpDetail?: YouXueSignUp) => {
    setDetailVisible(false);
    onSelectStudyTourList(signUpDetail, true);
  };
  const onSelectActivityAction = (signUpDetail?: YouXueSignUp) => {
    onSelectStudyTourList(signUpDetail, false);
  };

  const onActivityDetailAction = (signUpDetail?: YouXueSignUp) => {
    if (unSelect(signUpDetail)) {
      return;
    }
    selectStudyTourDetail.current = signUpDetail;
    setDetailVisible(true);
  };

  const onSelectedDialogVisible = (show?: boolean) => setVisible(show ?? false);

  const onDetailDialogVisible = (show?: boolean) => setDetailVisible(show ?? false);

  return {
    list,
    visible,
    loading,
    detailVisible,
    selectStudyTourDetail,
    unSelect,
    onSelectedDialogVisible,
    onDetailDialogVisible,
    onActivityDetailAction,
    onSelectSureAction,
    onSelectActivityAction,
  };
};

export default useStudyListTour;
