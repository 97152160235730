import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";

// 获取基本信息
export function getUserInfo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/info/${params.shopId}`,
    method: "get",
    params,
  });
}

// 获取积分相关信息
export function getPoint(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/carouselProducts`,
    method: "get",
    params,
  });
}

// 获取好货推荐
export function getRecommendProductList(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/product/recommend`,
    method: "post",
    data: params,
    headers: {
      ["app-request-id"]: params.searchRequestId,
    },
  });
}

// 获取店铺转发信息
export function getConsultShopOwner(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/consultShopOwner`,
    method: "post",
    data: params,
  });
}

// 获取是否是纯C
export function getIsOnlyC(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/member/isOnlyC`,
    method: "get",
    data: params,
  });
}

// 获取是否实名认证
export function getAuthInfo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/verified/query`,
    method: "get",
    data: params,
  });
}

// 获取是否关注公众号
export function getWeChatAccountInfoUrlByUserId(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xapp/wechatAccount/weChat/getWeChatAccountInfoUrlByUserId`,
    method: "post",
    data: params,
  });
}

// 获取用户是否绑定手机号
export function checkUserBindPhone(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/bindPhone/checkUserBindPhone`,
    method: "get",
    data: params,
  });
}

// 判断是否绑定
export function checkBind(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/visitorInfo`,
    method: "get",
    data: params,
    headers: {
      "app-login-channel": "xdWxH5",
    },
  });
}

export function launchConsultation(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/launchConsultation`,
    method: "post",
    data: params,
  });
}
export function sendSms(params?: any, rcsTraceId?: string) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/distributor/sendSms`,
    method: "post",
    data: params,
    headers: {
      rcsTraceId,
    },
  });
}

export function bindUserPhone(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xdwxh5/member/bindPhone/bindUserPhone`,
    method: "post",
    data: params,
  });
}

// 获取短信验证码 { phone, captchaToken }
export function getSmsCode(params?: any, rcsTraceId?: string) {
  return Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/bindPhoneNumSms`,
    method: "post",
    data: params,
    headers: {
      rcsTraceId,
    },
  });
}

// 绑定手机号 { phone, captchaToken, smsCode }
export function bindPhoneNum(params?: any, rcsTraceId?: string) {
  return Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/bindPhoneNum`,
    method: "post",
    data: params,
    headers: {
      rcsTraceId,
    },
  });
}

export function bindPhoneNumConfirm(params?: any, rcsTraceId?: string) {
  return Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/bindPhoneNumConfirm`,
    method: "post",
    data: params,
    headers: {
      rcsTraceId,
    },
  });
}

export function bindPhoneNumCancel(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/bindPhoneNumCancel`,
    method: "post",
    data: params,
  });
}

// 是否显示注销入口
export function queryShowCancellation(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/cancelAccount/showCancellation`,
    method: "get",
    data: params,
  });
}

// 注销-提交注销申请
export function applyCancelAccount(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/cancelAccount/apply`,
    method: "post",
    data: params,
  });
}

// 注销进度查询
export function queryCancelAccountInfo(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/cancelAccount/info`,
    method: "get",
    data: params,
  });
}

// 取消账号注销申请
export function withdrawCancelAccount(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/cancelAccount/withdraw`,
    method: "get",
    data: params,
  });
}

/**
 *
 * @description 登录用户编号信息（多端）
 * @author 乐天
 * @link https://yapi.akcops.com/project/2507/interface/api/364686
 */
export function currentUserInfoServ(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/multi/member/currentUserInfo`,
    method: "get",
    data: params,
  });
}
export function memberInfoServ(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/mshop-member-aggregation/outer/c/center/info`,
    method: "get",
    data: params,
  });
}
/**
 *
 * @description 分页查询推单消息列表(饷店H5+饷店小程序+APP内嵌H5)
 * @author 勾涛
 * @link https://yapi.akcops.com/project/2595/interface/api/365136
 */
export async function queryShoppingMessageListServ(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/pushOrder/v1/query/page/shoppingMessage`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 *
 * @description 派单提醒已分享(饷店H5+饷店小程序+APP内嵌H5)
 * @author 勾涛
 * @link https://yapi.akcops.com/project/2595/interface/api/365829
 */
export async function pushShoppingMessageShareServ(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/pushOrder/v1/share`,
    method: "post",
    data: params,
  });
  return res?.data;
}

/**
 *
 * @description 查询个人主页推单提醒消息所属时间枚举(饷店H5+饷店小程序+APP-内嵌H5)
 * @author 勾涛
 * @link https://yapi.akcops.com/project/1799/interface/api/391893
 */
export async function queryPageTabServ(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/pushOrder/v1/query/page/tab`,
    method: "get",
    data: params,
  });
  return res?.data;
}

// 运费无忧标签
export async function getLabelViewServ(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/getLabelView`,
    method: "post",
    data: params,
  });
  return res.data;
}
