import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";

// 查询用户授权绑定状态
const queryBindCheck = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/auth/common/bindCheck`,
    method: "get",
    data: params,
  });
  return res?.data;
};

/**
 * @link https://yapi.akcops.com/project/669/interface/api/141930
 * @description 获取当前登录用户的visitorStatus
 * @author 郎齐鹏
 */
const queryBindCheckServ = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/auth/xdwxh5/visitorInfo`,
    method: "get",
    data: params,
  });
  return res?.data;
};

// 管理员-登陆退出
const adminLogout = function () {
  return Request({
    url: `${getBaseurl()}/api/member/logout`,
    method: "post",
  });
};

// 隐藏入口控制
// https://yapi.akcops.com/project/2507/interface/api/394359
const queryHideInfo = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/hideEntry`,
    method: "post",
    data: params,
  });
  return res?.data;
};

// 设置隐藏
// https://yapi.akcops.com/project/2507/interface/api/394341
const setHideInfo = async (params?: any) => {
  const res = await Request({
    url: `${getBaseurl()}/api/member/multi/member/setHideInfo`,
    method: "post",
    data: params,
  });
  return res?.data;
};

export { adminLogout, queryBindCheck, queryHideInfo, setHideInfo };
