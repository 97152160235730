import { getAuthInfo, queryShowCancellation } from "@/api/my";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";

import styles from "./index.module.less";

import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";

const menuList = [
  {
    type: "authname",
    icon: "https://akim-oss.aikucun.com/283771fcfda0de87f43b26eef5c952963c8d3cfc_1667291867450_53.png",
    text: "实名认证",
    path: "/#/authname",
  },
  // {
  //   type: "changePhone",
  //   icon: "https://akim-oss.aikucun.com/ae0a7afecee1e83d27e322e287cb9bf351ea0018_1702376167560_85.png",
  //   text: "更换手机",
  //   path: "/mePkg/pages/change-phone/change-phone"
  // },
  {
    type: "about",
    icon: "https://akim-oss.aikucun.com/744dfb961aa3b328fd1c80718a2afbc5c4e52018_1702006231429_32.png",
    text: "关于我们",
    path: "/#/legal-protocol",
  },
];
const PersonalSetting: React.FC = () => {
  // const navigate = useNavigate();
  const { navigate2H5 } = useNavigation();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isShowCancellation, setShowCancellation] = useState<boolean>(false);
  const goHome = () => {
    const options: IOptions = {
      path: "/mx-shop-micro/home",
      is_micro_app: true,
    };
    navigate2H5(options);
  };
  const goBack = () => {
    if (window.history.length === 1) {
      goHome();
    } else {
      navigate2H5(-1);
    }
  };
  const goPage = (path: string, type: string) => {
    const options: IOptions = {
      path: path,
      is_micro_app: true,
    };
    if (type === "authname") {
      options.query = isVerified ? "isAuth=true" : "isAuth=false";
    }
    if (type === "changePhone") {
      return;
    }
    navigate2H5(options);
  };
  const reqAuthInfo = async () => {
    const { data } = await getAuthInfo();
    console.log(data);
    setIsVerified(data.isVerified);
  };
  const getShowCancellation = async () => {
    const { data } = await queryShowCancellation();
    console.log(data);
    setShowCancellation(data);
  };
  useEffect(() => {
    document.title = "设置";
    reqAuthInfo();
    getShowCancellation();
  }, []);
  const gotoBackUpPage = () => {
    location.href = "https://beian.miit.gov.cn/";
  };
  return (
    <div className={styles["container"]}>
      <div className={styles["nav"]}>
        <div className={styles["nav-box"]}>
          <div className={styles["nav-box__image-box"]} onClick={goBack}>
            <img src={BackPng} className={styles["image-box__image"]} />
          </div>
          <div className={styles["nav-box__image-box"]} onClick={goHome}>
            <img src={HomePng} className={styles["image-box__image"]} />
          </div>
        </div>
      </div>
      <div className={styles["setting"]}>
        {menuList.map((item, index) => (
          <div
            className={styles["setting-item"]}
            key={index}
            onClick={() => goPage(item.path, item.type)}
          >
            <div className={styles["setting-item__text"]}>{item.text}</div>
            <div className={styles["setting-item__arrow-right"]}>
              {item.type === "authname" && isVerified && (
                <div className={styles["nameauth"]}>已认证</div>
              )}
              <img
                className={styles["arrow-right__icon"]}
                src="https://akim-oss.aikucun.com/12c6fc06c99a462375eeb3f43dfd832b08ca9e17_1705998711713_51.png"
              ></img>
            </div>
          </div>
        ))}
        {isShowCancellation && (
          <div
            className={styles["logout-btn"]}
            onClick={() => {
              navigate2H5({
                path: "/mx-shop-micro/logout",
              });
            }}
          >
            注销账号
          </div>
        )}
        {/* <div
          className={styles["backup"]}
          onClick={() => {
            gotoBackUpPage();
          }}
        >
          沪ICP备 18031936号
        </div> */}
      </div>
    </div>
  );
};
export default PersonalSetting;
