import ProductWaterfall from "@/components/product-waterfall";
import { ProductModel } from "@akc/biz-api";
import React from "react";
import styles from "./index.module.less";
type Props = {
  productList?: ProductModel[];
  loadMore: () => Promise<void>;
  hasNext: boolean;
};
const ProductList: React.FC<Props> = (props) => {
  return (
    <div className={styles.goodsRecommond}>
      <div className={styles.goodsRecommondTitle}>
        <img
          className={styles.flag}
          src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
        />
        <span className={styles.text}>好货推荐</span>
        <img
          className={styles.flag}
          src="https://akim-oss.aikucun.com/saas/518edcdf460cd0094a1e816cbc116011c403c706_1654505998379_9.png"
        />
      </div>
      <ProductWaterfall
        productList={props.productList ?? []}
        hasNext={props.hasNext}
        loadMore={props.loadMore}
        topRightLocationCode="H5_ScheduleList_TopProductPicRight"
        titleBottomLocationCode="H5_Search_ProductList_TitleDown"
        titleFrontLocationCode="H5_Search_ProductList_TitleFront"
        provideBackground={false}
      />
    </div>
  );
};

export default ProductList;
