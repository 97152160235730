import React from "react";
import styles from "./index.module.less";

// 游学注意事项
const NoteComp: React.FC = () => (
  <div className={styles.tips}>
    <div className={styles.tips_container}>
      <div className={styles.tips_title}>注意事项确认</div>
      <div className={styles.tips_desc}>
        {
          "1.请确认所报名游学入选后可参与，如无故不参与将取消后续游学资格！\n2.所有游学均不允许携带小孩及家属参加，如若需要携带小孩请勿报名参加。"
        }
      </div>
    </div>
  </div>
);

export default NoteComp;
