export const trackParams = {
  page_name: "搜索",
  previous_page_name: "以图搜图",
  domain: "搜索",
  search_type: "以图搜图",
  search_request_id: "",
  resource_type: "商品列表",
  resource_content: "商品",
  page_desc: "以图搜图",
  tab_name: "以图搜图",
  module: "商品小卡",
  page_version: "新",
};

export const getSafeAreaInsetBottom = () => {
  const div = document.createElement("div");
  div.style.position = "absolute";
  div.style.top = "0";
  div.style.left = "0";
  div.style.width = "0";
  div.style.height = "0";
  div.style.visibility = "hidden";
  // 设置和获取 safe-area-inset-bottom 的值
  div.style.cssText = "padding-bottom: env(safe-area-inset-bottom);";
  document.body.appendChild(div);
  // 获取计算后的样式
  const computedStyle = window.getComputedStyle(div);
  const insetBottom = parseFloat(computedStyle.paddingBottom);
  // 移除测试元素
  document.body.removeChild(div);
  return insetBottom || 0; // 如果获取不到则返回 0
};

export default {
  trackParams,
  getSafeAreaInsetBottom,
};
