import { pxtorem } from "@/utils/pxtorem";
import React from "react";

interface IProps {
  children?: React.ReactNode;
  text?: string;
}

const RewardEmpty: React.FC<IProps> = ({ children, text }) => {
  const emptyCss: React.CSSProperties = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: pxtorem(60)
  };
  const emptyImg: React.CSSProperties = {
    width: pxtorem(140),
    height: pxtorem(140)
  };
  const emptyText: React.CSSProperties = {
    fontFamily: "苹方-简",
    fontSize: pxtorem(14),
    lineHeight: pxtorem(20),
    color: "#666666",
  };

  return (
    <div style={emptyCss}>
      <img style={emptyImg} src="https://akim-oss.aikucun.com/mshop/5679fa0533683de3ae789448b07766da168a56d4_1731326753583_93.png" alt="" />
      <span style={emptyText}>{children || text || "抱歉，暂无活动"}</span>
    </div>
  );
};
export default RewardEmpty;
