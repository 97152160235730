import { getCartTotal } from "@/api";
import cartIcon from "@/assets/icon_navigation_cart_1.png";
import IndexIcon from "@/assets/icon_navigation_index_1.png";
import TopIcon from "@/assets/icon_navigation_top_1.png";
import { getCurPageClientHeight, getCurPageClientWidth } from "@/utils/getStyle";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import Track from "@/utils/track";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";
// import microApp from "@micro-zoe/micro-app";
// import { wxShare } from "@/utils/wx-share";
const Navigation: React.FC = () => {
  const [showTopBox, setShowTopBox] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const navigater = useNavigate();

  const track = Track();
  const scrollHandle = () => {
    const scrollTop = document.getElementById("page")?.scrollTop || 0;
    if (scrollTop > getCurPageClientHeight() * 2) {
      setShowTopBox(true);
    } else {
      setShowTopBox(false);
    }
    // this.maxBottomHandle();
  };
  const fetchCartTotal = async () => {
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartTotal(cartSkuNum);
  };
  useEffect(() => {
    const throttleEvent = throttle(scrollHandle, 500);
    window.addEventListener("scroll", throttleEvent, true);
    fetchCartTotal();
    return () => {
      window.removeEventListener("scroll", throttleEvent, true);
    };
  }, []);

  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const [left, setLeft] = useState(15);
  const [bottom, setBottom] = useState(120);

  const handleTouchStart = (e) => {
    const { clientX, clientY } = e.touches[0];
    startX.current = clientX;
    startY.current = clientY;
    isDragging.current = true;
  };

  const handleTouchMove = (e) => {
    // 如果没有处于拖拽状态，则直接返回
    if (!isDragging.current) {
      return;
    }

    const { clientX, clientY } = e.touches[0];
    const deltaX = clientX - startX.current;
    const deltaY = startY.current - clientY;

    setLeft((prevLeft) => Math.min(getCurPageClientWidth() - 60, Math.max(15, prevLeft + deltaX)));
    setBottom((prevTop) =>
      Math.min(getCurPageClientHeight() - 110, Math.max(55, prevTop + deltaY)),
    );

    startX.current = clientX;
    startY.current = clientY;
  };
  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  const getNum = useMemo(() => {
    if (cartTotal > 99) {
      return "99+";
    }
    return cartTotal;
  }, [cartTotal]);

  const isBr50 = useMemo(() => {
    if (cartTotal < 10) {
      return true;
    }
    return false;
  }, [cartTotal]);
  const toTop = useCallback(() => {
    document.getElementById("page")?.scrollTo(0, 0);
    setShowTopBox(false);
  }, []);
  // const getBaseNavi = () => {
  //   return navigater;
  // };

  // const getWechatShare = () => {
  //   return wxShare;
  // };
  const toCart = useCallback(async () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "购物车",
        btn_name: "购物车",
        btn_text: "购物车",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2`);
    }
  }, []);
  const toIndex = useCallback(async () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "首页",
        btn_name: "首页",
        btn_text: "首页",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  }, []);
  return (
    <div
      className={styles.navigationWrap}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      style={{ left, bottom }}
    >
      {!!showTopBox && (
        <div className={styles.topBox + " " + styles.box} onClick={toTop}>
          <img src={TopIcon} />
          <span>回顶部</span>
        </div>
      )}
      <div className={styles.homeBox + " " + styles.box} onClick={toIndex}>
        <img src={IndexIcon} />
        <span>首页</span>
      </div>
      <div className={styles.cartBox + " " + styles.box} onClick={toCart}>
        <div className={styles.relative}>
          <img src={cartIcon} />
          {!!cartTotal && (
            <span className={isBr50 ? styles.tag + " " + styles.br50 : styles.tag}>{getNum}</span>
          )}
        </div>
        <span>购物车</span>
      </div>
    </div>
  );
};
export default Navigation;
