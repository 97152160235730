import { getCartTotal } from "@/api";
import { ReactComponent as CartIcon } from "@/assets/cart.svg";
import { ReactComponent as FavoriteIcon } from "@/assets/favorite.svg";
import { ReactComponent as FavoriteWhiteIcon } from "@/assets/favorite_white.svg";
import { ReactComponent as SearchIcon } from "@/assets/search.svg";
import { useCommonContext } from "@/utils/context/common";
import { EventName } from "@/utils/event/events";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import useNavigation, { SearchPageParams } from "@/utils/useNavigate";
import { Mask } from "antd-mobile";
import eruda from "eruda";
import React, { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface IProps {
  zIndex?: number; // zIndex
  center?: React.ReactNode; // 中间的内容
  backgroundColor?: string; // 背景色
  badgeBackgroundColor?: string; // 购物车数量背景色
  badgeFontColor?: string; // 购物车数量字体颜色
  showSearchIcon?: boolean; // 是否显示搜索图标
  showCartIcon?: boolean; // 是否显示购物车图标
  showFavoriteIcon?: boolean; // 是否显示收藏图标
  searchPageParams?: SearchPageParams; // 搜索参数
  hideHomeText?: boolean; // 是否隐藏首页文字
  hideSearchIcon?: boolean; // 是否显示搜索图标
  hideCartIcon?: boolean; // 是否显示购物车图标
  hideFavoriteIcon?: boolean; // 是否显示收藏图标
  showShareIcon?: boolean; // 是否显示分享图标
  onClickShare?: () => void;
  showRightTitle?: string; // 是否显示右侧标题
  rightClick?: () => void; // 右边点击回掉
  isWhiteTheme?: boolean; //是否白色主题
}

/**
 * 顶部导航栏
 * @param props
 * 更新购物车方法
 * import Navigation from "@/components/top-navigation/Navigation";
 * import {CommonContext} from "@/utils/context";
 * import {EventName} from "@/utils/event/events";
 *
 * const { eventManager } = useCommonContext();
 * eventManager.publish(EventName.updateNavigationCart,"");
 */
const NavigationBar: React.FC<IProps> = (props) => {
  const { zIndex = 999, center, backgroundColor, badgeBackgroundColor, badgeFontColor } = props;
  const [cartTotal, setCartTotal] = useState(0);
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const shopId = search.get("shopId");
  const { eventManager, cartNum = 0, setCartNum, currentRole } = useCommonContext();
  const { navigate2SearchPage, navigate2Home } = useNavigation();

  // 前往购物车
  const goCart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall`);
    }
  };
  // 返回首页
  const goHome = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e?.stopPropagation();
    navigate2Home();
  };
  // 返回上一页
  const goBack = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };

  // 查询购物车数量
  const queryGetCartTotal = async () => {
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartTotal(cartSkuNum);
  };

  const setupLongPress = (selector, callback) => {
    let longPressTimer: any = null;
    const element: HTMLElement = document.getElementById(selector) as HTMLElement;
    if (element) {
      element.addEventListener("touchstart", function (e) {
        longPressTimer = setTimeout(function () {
          callback();
        }, 3000);
      });

      element.addEventListener("touchend", function (e) {
        clearTimeout(longPressTimer);
      });
    }
  };

  useEffect(() => {
    setCartNum();
    eventManager.subscribe(EventName.updateNavigationCart, queryGetCartTotal);
    setupLongPress("navigation-home", () => {
      eruda.init();
    });
    return () => {
      eventManager.unsubscribe(EventName.updateNavigationCart, queryGetCartTotal);
    };
  }, []);
  useActivate(() => {
    setCartNum();
  });

  const containerDom = useRef<HTMLDivElement>(null);
  const placeHolderDom = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!placeHolderDom.current || !containerDom.current) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        containerDom.current?.style.setProperty(
          "background-color",
          `rgba(255,255,255,${1 - entries[entries.length - 1].intersectionRatio})`,
        );
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] },
    );
    observer.observe(placeHolderDom.current);

    return () => {
      observer?.disconnect();
    };
  }, [placeHolderDom.current, containerDom.current]);

  const [showFavoriteGuide, setShowFavoriteGuide] = useState(false);

  const onClickShareIcon = () => {
    if (props.onClickShare) {
      props.onClickShare();
    }
  };

  return (
    <>
      <div
        ref={containerDom}
        className={styles.container}
        style={{ zIndex: zIndex || 999, backgroundColor: backgroundColor || "rgba(255,255,255,0)" }}
      >
        <div className={`${props.isWhiteTheme ? styles["leftBox-white"] : styles["leftBox"]}`}>
          <img
            onClick={(e) => goBack(e)}
            className={styles.backIcon}
            src={
              props?.isWhiteTheme
                ? "https://akim-oss.aikucun.com/b1d5781111d84f7b3fe45a0852e59758cd7a87e5_1699586824224_62.png"
                : "https://akim-oss.aikucun.com/b07f427927175d0e745611f0d4342969f9f468f3_1703228563129_65.png"
            }
          />
          <div onClick={(e) => goHome(e)} className={styles.homeBox} id="navigation-home">
            <img
              className={styles.homeIcon}
              src={
                props?.isWhiteTheme
                  ? "https://akim-oss.aikucun.com/17ba0791499db908433b80f37c5fbc89b870084b_1699586824394_34.png"
                  : "https://akim-oss.aikucun.com/8cb8a10dbe75e006c04d3ff2036b4d61353908d6_1703228562634_67.png"
              }
            />
            {!(props.hideHomeText ?? false) && <span className={styles.homeText}>首页</span>}
          </div>
        </div>
        <div className={styles.midBox}>{center}</div>
        <div className={styles.rightBox}>
          {props.hideSearchIcon ? (
            <></>
          ) : (
            <div
              className={styles.rightIconWrapper}
              onClick={() => navigate2SearchPage(props.searchPageParams)}
            >
              <SearchIcon className={styles.rightIcon} />
            </div>
          )}
          {props.hideCartIcon ? (
            <></>
          ) : (
            <div className={styles.rightIconWrapper} onClick={(evt) => goCart(evt)}>
              <CartIcon className={styles.rightIcon} />
              {cartNum > 0 && (
                <div
                  className={styles.iconNum}
                  style={{ background: badgeBackgroundColor, color: badgeFontColor }}
                >
                  {cartNum}
                </div>
              )}
            </div>
          )}
          {props.hideFavoriteIcon ? (
            <></>
          ) : (
            <div
              className={`${props.isWhiteTheme ? styles["rightIconWrapper-white"] : styles["rightIconWrapper"]}`}
              onClick={() => setShowFavoriteGuide(true)}
            >
              {props.isWhiteTheme ? (
                <FavoriteWhiteIcon className={styles.favoriteWhite} />
              ) : (
                <FavoriteIcon className={styles.rightIcon} />
              )}
            </div>
          )}
          {props.showShareIcon ? (
            <div className={styles.rightIconWrapper} onClick={() => onClickShareIcon()}>
              <img
                className={styles.shareIcon}
                src={
                  ["A", "C"].includes(currentRole)
                    ? "https://akim-oss.aikucun.com/mshop/301668a14e3ce9ab72c4c7fa97d7d0bca3b0a77c_1723031060569_8.png"
                    : "https://akim-oss.aikucun.com/mshop/eeaaa563822b7c0c875f3c49e65c48e966db9716_1723717603263_33.png"
                }
              />
            </div>
          ) : (
            <></>
          )}
          {props?.showRightTitle && (
            <div className={styles.rightTitle} onClick={props?.rightClick}>
              {props?.showRightTitle}
            </div>
          )}
        </div>
      </div>
      <div ref={placeHolderDom} className={styles.placeholder} />

      <Mask visible={showFavoriteGuide} onMaskClick={() => setShowFavoriteGuide(false)}>
        <div className={styles.favoriteGuide}>
          <img
            src="https://akim-oss.aikucun.com/0ade7c2cf97f75d009975f4d720d1fa6c19f4897_1699537745382_34.png"
            className={styles.image1}
          />
          <img
            src="https://akim-oss.aikucun.com/77de68daecd823babbb58edb1c8e14d7106e83bb_1699427519013_56.png"
            className={styles.image2}
          />
          <img
            src="https://cdn.xiangdian.com/gray-h5shop/img/collect-guide.9f503840.gif"
            className={styles.image3}
          />

          <div className={styles.footer}>
            <div onClick={() => setShowFavoriteGuide(false)} className={styles.button}>
              我知道了
            </div>
          </div>
        </div>
      </Mask>
    </>
  );
};

export default NavigationBar;
