import {
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
} from "@/api/scode/scode_params";
import {
  conference3CarrierContext,
  conference3ContentContext,
  conference3EnvContext,
} from "@/utils/actions";
import { LabelPageId } from "@akc/biz-api";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const useSelectivePosters = () => {
  const [search] = useSearchParams();
  const queryData = {
    activityIdList: search.get("activityIdList")?.split(",") || undefined,
    shopCodes: search.get("shopCodes")?.split(",") || undefined,
    categoryIdList: search.get("categoryIdList")?.split(",") || undefined,
    brandIds: search.get("brandIds")?.split(",") || undefined,
  };

  const [resourceType, setResourceType] = useState("");
  const [shareType, setShareType] = useState("");
  const [sharePosterData, setSharePosterData] = useState<any>({});

  const init = async () => {
    //  档期页海报（单档期+多档期）
    if (queryData.activityIdList?.length) {
      setResourceType("档期");
      setShareType(ContentType.activity);
      const brandId = search.get("brandId") || undefined;
      const queryType = search.get("queryType") || undefined;
      setSharePosterData({
        brandId: queryData.activityIdList.length > 1 ? brandId : undefined,
        activityId: queryData.activityIdList.length > 1 ? undefined : queryData.activityIdList[0],
        contentType: queryData.activityIdList.length > 1 ? ContentType.activityAggr : undefined,
        carrierElements: [CarrierElementType.h5Poster],
        extra: {
          brandId,
          queryType,
          activityId: queryData.activityIdList[0],
          sharePageId: LabelPageId.h5ActivityDetail,
        },
      });
      return;
    }

    // 店铺页海报
    if (queryData.shopCodes?.length) {
      setResourceType("商家店铺");
      setShareType(ContentType.conference3);
      const merchantShopCode = search.get("shopCodes");
      const bizInfo = search.get("bizInfo") || "";
      setSharePosterData({
        bizInfo: JSON.parse(bizInfo),
        envContext: await conference3EnvContext(merchantShopCode),
        contentContext: conference3ContentContext(merchantShopCode),
        carrierContext: conference3CarrierContext([CarrierElementType.h5Poster]),
      });
      return;
    }

    // 类目页海报
    if (queryData.categoryIdList?.length && search.get("threeClassId")) {
      setResourceType("类目");
      setShareType(ContentType.cartegory);
      const threeClassId = search.get("threeClassId");
      const productTypeList = search.get("productTypeList");
      setSharePosterData({
        bizInfo: {
          contentType: ContentType.cartegory,
          contentId: threeClassId,
          contentTypeConfig: "1",
          contentExtra: {},
          shortCodeExtend:
            productTypeList && productTypeList !== "undefined" ? { productTypeList } : {},
        },
        envContext: envContextParams({}),
        contentContext: {
          contentType: ContentType.cartegory,
          contentId: threeClassId,
          contentExtra: {},
        },
        carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
      });
      return;
    }

    // 品牌页海报
    if (queryData.brandIds?.length) {
      setResourceType("品牌");
      setShareType(ContentType.shareBrand);
      const brandId = search.get("brandIds");
      const productTypeList = search.get("productTypeList");
      setSharePosterData({
        bizInfo: {
          contentType: ContentType.shareBrand,
          contentId: brandId,
          contentTypeConfig: "1",
          contentExtra: {},
          shortCodeExtend:
            productTypeList && productTypeList !== "undefined" ? { productTypeList } : {},
        },
        envContext: envContextParams({}),
        contentContext: {
          contentType: ContentType.shareBrand,
          contentId: brandId,
          contentExtra: {},
        },
        carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
      });
      return;
    }
  };

  useEffect(() => {
    init();
  }, []);

  return { resourceType, shareType, sharePosterData };
};

export default useSelectivePosters;
