import { Mask } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";

interface PopupProps {
  visible: boolean;
  title?: string;
  content?: string;
  btnName?: string;
  setVisible: (isVisible?: boolean, isSure?: boolean) => void;
}

const InstructAlert: React.FC<PopupProps> = ({ visible, title, content, btnName, setVisible }) => {
  const onClose = (isVisible?: boolean, isSure?: boolean) => {
    setVisible(isVisible, isSure);
  };

  return (
    <Mask visible={visible}>
      <div className={styles.confirmDialog}>
        <div className={styles.body__title}>
          <div className={styles.title__content}>{title}</div>
          <img
            className={styles.image__content}
            onClick={() => onClose(false, false)}
            src="https://akim-oss.aikucun.com/mshop/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1710849026952_49.png"
          />
        </div>

        <div className={styles.dialog__body}>
          <div className={styles.body__desc}>{content}</div>
        </div>
        <div className={styles.body__btn}>
          <div className={styles.btn__item} onClick={() => setVisible(false, true)}>
            <div className={styles.btn__text}>{btnName}</div>
          </div>
        </div>
        {/* <div className={styles.btn__line} /> */}
      </div>
    </Mask>
  );
};

export default InstructAlert;
