import { platform } from "@/utils/platform";
import { Toast } from "antd-mobile";
import React, { useRef } from "react";
import { generateSimpleImage } from "../../../../api/videoShare";
import styles from "./index.module.less";

const TITLE_IMG =
  "https://akim-oss.aikucun.com/8d81dd595748de1e63ac40cc6253e5260d017057_1709190388159_1.png";

import { UseVideoShareContext } from "../../hooks/useVideoShare";
interface IProps {
  liveQrCode: string;
  setShareCodeModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShareCodeModal: React.FC<IProps> = ({ liveQrCode, setShareCodeModalVisible }) => {
  const { handleDownload, anchorName } = UseVideoShareContext();

  const imageRef = useRef<HTMLImageElement>(null);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };
  const handelProcessImg = async () => {
    try {
      const params = {
        templateNo: "CX20240305001",
        data: {
          qrcodebase64: liveQrCode,
          cardTitle: anchorName,
        },
      };
      const res: any = await generateSimpleImage(params);
      if (res?.success) {
        handleDownload(res?.data);
      } else {
        Toast.show(res?.message || "请求异常");
      }
    } catch {
      console.log("请求异常");
    }
  };

  return (
    <>
      <div className={styles.modalWrapper} onClick={() => setShareCodeModalVisible(false)}>
        <div className={styles.popup} onClick={(e) => handleModalClick(e)}>
          <div className={styles.popupContent}>
            <img
              onClick={() => setShareCodeModalVisible(false)}
              className={styles.modalCloseIcon}
              src="https://akim-oss.aikucun.com/6c14bd7f6f9e54e46024d35dfe2c4548c9a3821d_1702288423030_51.png"
            ></img>
            <div className={styles.popTitle}>
              <img className={styles.popTitleTop} src={TITLE_IMG}></img>
            </div>
            <div className={styles.popCard}>
              <div className={styles.popCardTitle}>{anchorName}</div>
              <img
                ref={imageRef}
                show-menu-by-longpress="true"
                lazy-load="false"
                crossOrigin="anonymous"
                src={liveQrCode}
                className={styles["popCardImg"]}
              ></img>
            </div>
          </div>
          <div className={styles["popupTips"]}>注意：该二维码不要直接发给你的客户</div>
          {platform.isApp && (
            <div
              className={styles.popupButton}
              style={{
                justifyContent: "center",
              }}
            >
              <div className={styles.btn} onClick={handelProcessImg}>
                保存海报
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ShareCodeModal;
