import { CascadePickerOption } from "antd-mobile";

/// 月份
const getMouth = (): CascadePickerOption[] => {
  const mouths: CascadePickerOption[] = [];
  for (let i = 1; i < 13; i++) {
    const menth = i;
    mouths.push({ label: `${menth}月`, value: `${menth}月` });
  }
  return mouths;
};

/// 最近十年年份总和 + 全部
export const optionsPicker = (): CascadePickerOption[] => {
  const currentYear = new Date().getFullYear();
  const years: CascadePickerOption[] = [];
  years.push({ label: "全部", value: "全部", children: [] });
  for (let i = 0; i < 10; i++) {
    const year = currentYear - i;
    years.push({ label: `${year}年`, value: `${year}年`, children: getMouth() });
  }

  return years;
};
