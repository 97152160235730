import {
  fetchAccountInfo,
  fetchMyRewardList,
  fetchRedPacketTotalInfo,
  fetchTabList,
  Tab,
} from "@/api/daily-reward";
import {
  bizInfoParams,
  carrierContextParams,
  CarrierElementType,
  ContentType,
  envContextParams,
  SourceScene,
} from "@/api/scode/scode_params";
import { CustomEditData } from "@/components/poster-swiper";
import {
  AccountInfo,
  AwardItem,
  AwardProductItme,
  CouponAward,
  RedPackageAward,
} from "@/models/reward";
import { useCommonContext } from "@/utils/context/common";
import { platform } from "@/utils/platform";
import { shareDailyReward } from "@/utils/share/share";
import AKJS from "@akc/akjs";
import { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import { couponTrack, pageView, pointTrack, redPacketTrack } from "../track/reward";
import usePageAction from "../utils/action";
import useCouponAction from "../utils/coupon";

let sharePosterData: any = null;
const useMyReward = () => {
  const commonContext = useCommonContext();
  const {
    openOrderDetail,
    openCustomerReward,
    openRedDetail,
    openRedPacketUsePage,
    openPointCenter,
    openShopDetail,
  } = usePageAction();
  const [list, setList] = useState<AwardItem[]>([]);
  const pageIndex = useRef<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [redPacket, setRedPacket] = useState<AccountInfo>();
  const [tabList, setTabList] = useState<Tab[]>();
  const currentRoleIsC = commonContext.currentRole === "C";
  const currentRoleIsAdmin = commonContext.currentRole === "A";
  const [selectTab, setSelectTab] = useState<Tab>();
  const [searchParams] = useSearchParams();
  const [showPoster, setShowPoster] = useState(false);
  const promoActivityId = searchParams.get("promoActivityId") ?? "";
  const [customEditData, setCustomEditData] = useState<CustomEditData>({} as CustomEditData);
  const { couponDetailAction } = useCouponAction();
  const drawResultInfo = useRef<AwardProductItme>();
  const [drawResultVisible, setDrawResultVisible] = useState(false);
  const [curShareType, setCurShareType] = useState(ContentType.dailyReward);
  const [totalAmount, setTotalAmount] = useState<number>(-1);
  const rightTitle = "顾客奖励";

  const updateList = async (isSelect?: boolean) => {
    setLoading(true);
    if (selectTab?.type === undefined) {
      return;
    }
    const res = await fetchMyRewardList({
      awardType: selectTab?.type,
      pageIndex: pageIndex.current,
      pageSize: 10,
    });
    setLoading(false);
    if (!res) {
      return;
    }
    pageIndex.current = (res?.pageIndex ?? 0) + 1;
    if (isSelect) {
      setList(res?.result || []);
    } else {
      setList([...list, ...(res?.result || [])]);
    }
    setHasMore(res?.hasNext ?? false);
  };

  const updateRedPacketAmount = async () => {
    fetchAccountInfo().then((res) => {
      if (res?.freeBalance !== undefined) {
        setRedPacket(res);
      }
    });
  };

  const updateTotalAmount = async () => {
    fetchRedPacketTotalInfo().then((res) => {
      if (res?.totalInAmount !== undefined) {
        if (res?.totalInAmount) {
          setTotalAmount(res?.totalInAmount);
        }
      }
    });
  };

  const loadMore = async () => {
    if (hasMore && !loading) {
      updateList();
    }
  };

  const setTopRightTitle = () => {
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.navigationBar.config.rightBtns", {
          btns: [
            {
              title: rightTitle,
              titleColor: "#666666",
              titleSize: 14,
              onclick: () => {
                openCustomerReward(promoActivityId);
              },
            },
          ],
        });
        return;
      });
    }
  };

  const updateTabList = () => {
    fetchTabList().then((res) => {
      if (res !== undefined) {
        setTabList(res);
        if (res?.[0] !== undefined) {
          setSelectTab(res?.[0]);
        }
      }
    });
  };

  useEffect(() => {
    document.title = "我的奖励";
    updateTabList();
    setTopRightTitle();
    updateRedPacketAmount();
    updateTotalAmount();
    pageView(promoActivityId);
  }, []);

  useEffect(() => {
    pageIndex.current = 1;
    updateList(true);
  }, [selectTab]);

  useActivate(() => {
    updateRedPacketAmount();
    document.title = "我的奖励";
  });

  (window as any).akc_onAppear = () => {
    updateRedPacketAmount();
  };

  const scrollToTop = () => {
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  const onChangeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setSelectTab(tabList?.[index]);
    scrollToTop();
  };

  // 店铺
  const shopDetail = (redPacket?: RedPackageAward) => openShopDetail(redPacket?.merchantShopCode);

  // 实物奖去店铺
  const onShopDetailAction = (reward?: AwardItem) =>
    openShopDetail(reward?.productAward?.merchantShopCode);

  // 商家券跳店铺
  const onCouponToShopDetailAction = (coupon?: CouponAward) =>
    openShopDetail(coupon?.merchantShopCode);

  // 优惠券凑单页
  const shareableCouponDetail = async (couponAward?: CouponAward) => {
    couponDetailAction(couponAward);
  };

  // 积分中心
  const pointCenter = () => openPointCenter();

  const onUseAction = (reward?: AwardItem, index?: number) => {
    // 去店铺
    if (reward?.awardType === "RED_PACKET") {
      shopDetail(reward?.redPackageAward);
      redPacketTrack(false, index);
      return;
    }

    // 优惠去凑单页
    if (reward?.awardType === "COUPON") {
      shareableCouponDetail(reward?.couponAward);
      couponTrack(reward?.couponAward?.couponTemplateId, index);
      return;
    }

    // 去C的会员中心页面
    if (reward?.awardType === "POINTS") {
      pointCenter();
      pointTrack(index);
      return;
    }
  };

  // 顾客奖励
  const onRightAction = () => {
    openCustomerReward(promoActivityId);
  };

  // 转发
  const onForwardAction = () => {
    if (platform.isH5) {
      sharePosterData = {
        bizInfo: bizInfoParams({
          contentType: ContentType.dailyReward,
          contentId: promoActivityId,
        }),
        envContext: envContextParams({
          sourceScene: SourceScene.dailyReward,
          sourceSceneId: ContentType.dailyReward,
        }),
        carrierContext: carrierContextParams([CarrierElementType.h5Poster]),
      };
      setCustomEditData({
        promoActivityId: promoActivityId,
      });
      setCurShareType(ContentType.dailyReward);
      setShowPoster(true);
    } else {
      const params = {
        contentId: promoActivityId ?? "",
        shareType: SourceScene.dailyReward,
      };
      shareDailyReward(params);
    }
  };

  // 红包详情
  const onRedDetailAction = () => openRedDetail();

  // 红包凑单
  const onRedPacketUseAction = (isUse?: boolean) => {
    openRedPacketUsePage(isUse);
    redPacketTrack(true, 0);
  };

  // 关闭转发
  const onClosePosterAction = () => {
    setShowPoster(false);
  };

  // 去领取
  const onClaimRewardAction = (reward?: AwardItem) => {
    drawResultInfo.current = {
      ...reward,
      activitySpuId: reward?.productAward?.activitySpuId,
      lotteryActivityId: reward?.promoTaskId,
      lotteryAwardResultId: reward?.recordId,
    };
    setDrawResultVisible(true);
  };

  // 订单详情
  const onOrderDetailAction = (reward?: AwardItem) => {
    openOrderDetail(reward);
  };

  // 关闭领奖pop
  const onCloseDrowResultPopAction = () => setDrawResultVisible(false);

  // 实物奖列表刷新
  const onProductRefreshAction = () => {
    if (selectTab?.type === "PRODUCT") {
      pageIndex.current = 1;
      scrollToTop();
      updateList(true);
    }
  };

  // 刷新红包总额
  const onRefreshTotalAmountAction = () => updateTotalAmount();

  const rewardName = (reward?: AwardItem) => {
    if (reward?.awardType === "RED_PACKET") {
      return reward?.redPackageAward?.amount;
    }
    if (reward?.awardType === "COUPON") {
      return reward?.couponAward?.couponName;
    }
    if (reward?.awardType === "POINTS") {
      return reward?.pointsAward?.amount;
    }
    if (reward?.awardType === "PRODUCT") {
      return reward?.productAward?.activitySpuId;
    }
  };

  // 日日奖清单转发
  const onRewardForwardAction = (reward?: AwardItem) => {
    if (platform.isH5) {
      sharePosterData = {
        bizInfo: bizInfoParams({
          contentType: ContentType.dailyRewardGift,
          contentId: promoActivityId,
          contentDetailId: reward?.userId,
          extra: {
            awardName: rewardName(reward),
            awardType: reward?.awardType,
          },
        }),
        envContext: envContextParams({
          sourceScene: SourceScene.dailyRewardGift,
          sourceSceneId: ContentType.dailyRewardGift,
        }),
        contentContext: {
          awardType: reward?.awardType,
        },
        carrierContext: {
          ...carrierContextParams([CarrierElementType.h5Poster]),
          posterCode: ContentType.dailyRewardGift,
        },
      };
      setCustomEditData({
        promoActivityId: promoActivityId,
        awardName: reward && rewardName(reward),
        awardType: reward?.awardType,
      });
      setCurShareType(ContentType.dailyRewardGift);
      setShowPoster(true);
    } else {
      const params = {
        contentId: promoActivityId,
        shareType: SourceScene.dailyRewardGift,
        contentDetailId: reward?.userId,
        awardName: rewardName(reward),
        awardType: reward?.awardType,
      };
      shareDailyReward(params);
    }
  };

  const emptyTitleDes = (tab?: Tab) => {
    if (tab?.type === "PRODUCT") {
      return "暂无相关可用的实物奖励";
    }
    if (tab?.type === "RED_PACKET") {
      return "暂无相关可用的红包";
    }
    if (tab?.type === "COUPON") {
      return "暂无相关可用的优惠券";
    }

    if (tab?.type === "POINTS") {
      return "暂无相关可用的积分";
    }
  };

  return {
    tabList,
    selectTab,
    rightTitle,
    list,
    redPacket,
    loading,
    hasMore,
    currentRoleIsC,
    currentRoleIsAdmin,
    showPoster,
    sharePosterData,
    customEditData,
    drawResultVisible,
    drawResultInfo,
    curShareType,
    totalAmount,
    onCloseDrowResultPopAction,
    onProductRefreshAction,
    loadMore,
    onChangeTabIndex,
    emptyTitleDes,
    onForwardAction,
    onRightAction,
    onRedDetailAction,
    onUseAction,
    onClosePosterAction,
    onRedPacketUseAction,
    onClaimRewardAction,
    onOrderDetailAction,
    onShopDetailAction,
    onCouponToShopDetailAction,
    onRewardForwardAction,
    onRefreshTotalAmountAction,
  };
};

export default useMyReward;
