import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import { getUrlParam } from "../../../../utils/url-parse";
import styles from "./index.module.less";

interface Props {
  data: any;
  tabIndex: number;
  changeTab: (index) => void;
}

const Tabs: React.FC<Props> = (props: Props) => {
  const { activityNo } = getUrlParam();

  const changeActive = (index) => {
    props.changeTab(index);
  };

  return (
    <div
      className={styles.tabs}
      style={{
        position: "relative",
        top: "unset",
        zIndex: "unset",
      }}
      id="material-tabs"
    >
      {props.data.map((item, index) => {
        return (
          <div className={styles.tab} onClick={() => changeActive(index)} key={index}>
            <div className={styles.item + " " + (props.tabIndex === index ? styles.active : "")}>
              {item.tabDesc}
            </div>
            <div className={styles.nums}>
              {item.materialCount > 99 ? "99+" : item.materialCount}
            </div>
            {props.tabIndex === index && (
              <div className={styles.line}>
                <div className={styles.item}></div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
