import { batchReceiveCoupon, clearWindowNum } from "@/api/coupon";
import useCheckBind from "@/pages/new-person/hooks/useCheckBind";
import useNavigation from "@/utils/useNavigate";
import { Mask, Toast } from "antd-mobile";
import React, { FC, useState } from "react";
import styles from "./NewPersonCouponV2.module.less";

type Props = {
  couponInfo: any;
};

const ExpireTime = ({ data }) => {
  const { endTime } = data;
  const time = (+endTime - Date.now()) / 1000;
  let day = parseInt(String(time / (60 * 60 * 24)), 10);
  let hou: number | string = parseInt(String((time % (60 * 60 * 24)) / 3600), 10);
  let min: number | string = parseInt(String(((time % (60 * 60 * 24)) % 3600) / 60), 10);

  day < 0 && (day = 0);
  hou < 0 && (hou = 0);
  min < 0 && (min = 0);

  hou = hou < 10 ? "0" + hou : hou;
  min = min < 10 ? "0" + min : min;

  const endDate = new Date(+endTime);
  const year = endDate.getFullYear();
  const month = endDate.getMonth() + 1;
  const date = endDate.getDate();
  if (day > 3) {
    return (
      <div className={styles.desc}>
        {month}月{date}日到期
      </div>
    );
  } else if (day) {
    return <div className={styles.desc}>{day}天后到期</div>;
  } else {
    return (
      <div className={styles.desc}>
        <span className={styles.red}>
          {hou}:{min}
        </span>
        后到期
      </div>
    );
  }
};

const LongCard: FC<any> = ({ coupon }) => {
  return (
    <div className={styles.longCard}>
      <div className={styles.left}>
        <span className={styles.price}>{coupon.amount}</span>
        <span>元</span>
      </div>
      <div className={styles.right}>
        <div className={styles.title}>{coupon.brandName}</div>
        <ExpireTime data={coupon} />
      </div>
      {coupon.buttonStatus === 1 && coupon.currentNum && coupon.currentNum < 100 && (
        <div className={styles.cornerLabel}>仅剩{coupon.currentNum}张</div>
      )}
    </div>
  );
};

const NormalCardList: FC<{ list: any }> = ({ list }) => {
  return (
    <div className={styles.list + " " + (list.length > 2 && styles.longList)}>
      {list.map((item) => {
        return (
          <div key={item.ticketId} className={styles.normalCard}>
            <div className={styles.title}>{item.brandName}</div>
            <div className={styles.cont}>
              <span className={styles.price}>{item.amount}</span>元
            </div>
            <ExpireTime data={item} />
            {item.buttonStatus === 1 && item.currentNum && item.currentNum < 100 && (
              <div className={styles.cornerLabel}>仅剩{item.currentNum}张</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const NewPersonCoupon: FC<Props> = ({ couponInfo }) => {
  const [visible, setVisible] = useState(!!couponInfo.thirdCustomerCoupons?.length);
  const { isVisitor, bindPhone } = useCheckBind();
  const { navigate } = useNavigation();

  const receiveCoupons = async () => {
    const couponByIdReqList = couponInfo.thirdCustomerCoupons.map(
      ({ ticketId, promoActivityId }) => ({
        awdId: ticketId,
        promoActivityId,
      }),
    );
    const { code, success, data, message } = await batchReceiveCoupon({
      couponByIdReqList,
      rcsTraceId: "",
      captchaToken: "",
    });
    if (code === "00000" && success && data) {
      Toast.show({
        content: "优惠券领取成功",
        maskStyle: { zIndex: 1003 },
      });
      clearWindowNum();
      setVisible(false);
      // 跳转到新人专区落地页
      navigate({
        h5Options: {
          url: "/mx-shop-micro/new-person",
        },
      });
      return;
    }
    Toast.show({
      content: message || "优惠券被领光啦",
      maskStyle: { zIndex: 1003 },
    });
  };
  const submit = () => {
    if (isVisitor) {
      bindPhone();
    } else {
      receiveCoupons();
    }
  };
  return (
    <Mask visible={visible} style={{ zIndex: 1002 }} onMaskClick={() => setVisible(false)}>
      <div className={styles.overlayContent}>
        <div className={styles.main}>
          {couponInfo.thirdCustomerCoupons.length === 1 && (
            <LongCard coupon={couponInfo.thirdCustomerCoupons[0]} />
          )}
          {couponInfo.thirdCustomerCoupons.length > 1 && (
            <NormalCardList list={couponInfo.thirdCustomerCoupons} />
          )}
        </div>
        <div className={styles.longBtn} onClick={submit}>
          {isVisitor ? "立即登录" : "领券去使用"}
        </div>
      </div>
    </Mask>
  );
};

export default NewPersonCoupon;
