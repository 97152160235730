/**
 * 获取当前页面视口高度（兼容性写法）
 */
export function getCurPageClientHeight() {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export function getCurPageClientWidth() {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
