import ARROW from "@/assets/icon_arrow_down.png";
import CLOSE from "@/assets/icon_close_gray.png";
import { Popup, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useActivate, useUnactivate } from "react-activation";
import styles from "./index.module.less";

type Props = {
  logisticsItem: any;
};
const LogisticsNode: React.FC<Props> = ({ logisticsItem }) => {
  const [isReceived, setIsReceived] = useState<boolean>(false);
  const [deliveryNodes, setDeliveryNodes] = useState<any[]>([]);
  const [isCompleteNodes, setIsCompleteNodes] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const onCopy = (str: string) => {
    setVisible(false);
    copy(str);
    Toast.show("复制成功");
  };
  const telTransform = (content: string) => {
    if (content) {
      const regExp =
        /(((0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?)|(((\(\d{3}\))|(\d{3}-))?(1[3456789]\d{9}))|((400)-(\d{3})-(\d{4})(.)(\d{1,4}))|((400)-(\d{3})-(\d{4})))/g;
      const telNode =
        "<a href='tel:$1' style=\"display:inline;color: #1858FF;text-decoration:underline\">$1</a>";
      content = content.replace(regExp, telNode);
    }
    return content;
  };

  const handleDeliveryNodes = () => {
    if (logisticsItem?.deliveryNodes?.length) {
      const status = [""];
      const deliveryNodes = logisticsItem.deliveryNodes.map((node: any, index: number) => {
        if (node.traceStatus && !status.includes(node.traceStatus)) {
          node.showTraceStatus = true;
          status.push(node.traceStatus);
        } else {
          node.showTraceStatus = false;
        }
        if (index === 0) {
          setIsReceived(node.traceStatus === "已签收");
        }
        return {
          ...node,
          content: telTransform(node.content),
        };
      });
      setDeliveryNodes(deliveryNodes);
    }
  };

  useActivate(() => {
    setVisible(false);
  });

  useUnactivate(() => {
    setVisible(false);
  });

  useEffect(() => {
    handleDeliveryNodes();
  }, [logisticsItem]);

  return (
    <>
      <div className={styles["logistics-node"]}>
        {logisticsItem.logisticsNotice && (
          <div className={styles["logistics-notice"]}>
            如需通知顾客已到货，您可以
            <span className={styles["notice"]} onClick={() => setVisible(true)}>
              复制物流通知
            </span>
          </div>
        )}
        <div className={styles["logistics-node__content"]}>
          {/* 收货信息 */}
          <div className={`${styles["node"]} ${isReceived ? styles["node-received"] : ""}`}>
            <div className={styles["node-left"]}></div>
            <div className={styles["node-right"]}>
              <div className={styles["address-point"]}>收</div>
              <div className={styles["node-right__title"]}>收货信息</div>
              <div className={styles["node-right__content"]}>{logisticsItem.address}</div>
            </div>
          </div>
          {/* 物流节点 */}
          {deliveryNodes.length > 0 &&
            deliveryNodes.map((node, index) => (
              <div
                className={`
                  ${styles["node"]}
                  ${node.traceStatusCode === 4 ? styles["node-received"] : ""}
                  ${node.abnormalType && index === 0 ? styles["node-abnormal"] : ""}`}
                style={{ display: isCompleteNodes || index === 0 ? "flex" : "none" }}
                key={index}
              >
                <div className={styles["node-left"]}>
                  {node.time && (
                    <div className={styles["node-left__time"]}>
                      <span>{moment(node.time).format("MM-DD")}</span>
                      <span>{moment(node.time).format("HH:mm")}</span>
                    </div>
                  )}
                </div>
                <div className={styles["node-right"]}>
                  {node.showTraceStatus ? (
                    <div className={styles["light-point"]}></div>
                  ) : (
                    <div className={styles["dark-point"]}></div>
                  )}

                  {node.showTraceStatus && (
                    <div className={styles["node-right__title"]}>{node.traceStatus}</div>
                  )}

                  {node.content && (
                    <div
                      className={styles["node-right__content"]}
                      dangerouslySetInnerHTML={{ __html: node.content }}
                    ></div>
                  )}

                  {node.traceStatusCode === 4 && (
                    <>
                      <div className={styles["received-point"]}></div>
                      <div className={styles["node-right__remind"]}>
                        温馨提示：小店不会以快递丢失/商品质量等为由，向您索取银行卡信息或手机验证码。请您时刻保持警惕，如遇到问题，请您先与小店核实。
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
        {deliveryNodes.length > 1 && (
          <div className={styles["logistics-node__footer"]}>
            <div
              className={styles["node-complete"]}
              onClick={() => setIsCompleteNodes(!isCompleteNodes)}
            >
              {isCompleteNodes ? "收起完整物流进度" : "查看完整物流进度"}
              <img
                src={ARROW}
                className={`${isCompleteNodes ? styles["up"] : styles["down"]}`}
                alt=""
              />
            </div>
          </div>
        )}
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => setVisible(false)}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <div className={styles["notice-popup"]}>
          <div className={styles["notice-popup__header"]}>
            <div className={styles["title"]}>物流通知</div>
            <img className={styles["close"]} src={CLOSE} onClick={() => setVisible(false)} />
          </div>
          <div className={styles["notice-popup__content"]}>{logisticsItem.logisticsNotice}</div>
          <div
            className={styles["notice-popup__footer"]}
            onClick={() => onCopy(logisticsItem.logisticsNotice)}
          >
            复制文案
          </div>
        </div>
      </Popup>
    </>
  );
};

export default LogisticsNode;
