import { fetchShareCardInfo } from "@/api/scode/share_card_info";
import { shareCardByH5 } from "@/utils/share/share";

// 种草模块分享

const useShare = () => {
  /**
   * http://yapi.akcops.com/project/1911/interface/api/169116
   *
   * @param {(1 | 2 | 3 | 4 | 5 | 6)} type 1: 逛一逛-推荐  2: 逛一逛-发现列表 3: 逛一逛-发现详情 4:实拍专区-列表 5: 实拍专区-详情-图文 6: 实拍专区-详情-视频
   * @param {(string | null)} [materialNo]
   */
  const shareSetting = async (
    type: 1 | 2 | 3 | 4 | 5 | 6,
    materialNo?: string | null,
    extraInfo?: any,
  ) => {
    const location = {
      1: "RECOMMENDED",
      2: "DISCOVERY_LIST",
      3: "DISCOVERY_DETAILS",
      4: "LIVE_SHOT_LIST",
      5: "LIVE_SHOT_DETAILS",
      6: "LIVE_SHOT_DETAILS",
    };
    const locationType = {
      5: "IMG_TXT",
      6: "VIDEO",
    };
    const sourceScene = {
      1: "种草视频",
      2: "种草发现列表",
      3: "种草图文",
      4: "种草发现列表",
      5: "种草图文",
      6: "种草视频",
    };
    const p = {
      bizInfo: {
        contentId: materialNo,
        contentType: "GRASS_GROWING",
        contentExtra: {
          share_grass_growing_location: location[type],
          share_grass_growing_location_type: locationType[type],
        },
        shortCodeExtend: extraInfo || {},
      },
      carrierContext: { carrierElements: ["h5LINK"] },
      contentContext: {
        contentExtra: { page_name: "种草" },
        contentId: materialNo || "",
        contentType: sourceScene[type],
      },
      envContext: {
        sourceScene: sourceScene[type],
        bizCategory: "XD",
        bizSubCategory: "xdWxH5",
        terminalType: "h5",
      },
    };
    const res = await fetchShareCardInfo(p as any);
    if (res && res.data) {
      shareCardByH5(
        {
          title: res.data.urlShareTitle,
          desc: res.data.urlShareDesc,
          link: res.data.urlShareUrl,
          imgUrl: res.data.urlShareImg,
          scode: res.data.scode,
        },
        false,
        // res.data.urlShareUrl
        undefined,
      );
    }
  };

  return {
    shareSetting,
  };
};

export default useShare;
