import { getCurrentFormatTime, getCurrentformatTime } from "@/utils/date-format";
import { pxtorem } from "@/utils/pxtorem";
import React from "react";
import { YouXueSignUp } from "../../../model";
import styles from "./index.module.less";

interface CardProps {
  signUpDetail?: YouXueSignUp;
  supportMulSelect?: boolean; // 支持多选
  supportDelete?: boolean; // 支持删除
  showCover?: boolean; // 不可选
  onDetailPress?: (signUpDetail?: YouXueSignUp) => void;
  onSelectPress?: (signUpDetail?: YouXueSignUp) => void;
  onDeletePress?: (signUpDetail?: YouXueSignUp) => void;
}

const StudyActivityCardComp: React.FC<CardProps> = ({
  signUpDetail,
  supportMulSelect,
  supportDelete,
  showCover,
  onSelectPress,
  onDetailPress,
  onDeletePress,
}) => {
  const benefitNames = signUpDetail?.studyTourBenefitPointResp
    ?.map((benefit) => benefit.benefitName)
    .join("·");

  const selectImageSrc = () => {
    if (!signUpDetail?.signUpFlag || false) {
      return "https://akim-oss.aikucun.com/mshop/356a192b7913b04c54574d18c28d46e6395428ab_1718165911548_20.png";
    }

    if (signUpDetail?.memberSignUpFlag || false) {
      return "https://akim-oss.aikucun.com/mshop/bac50f0575688981b15853698f91f5bfd730cddb_1716972865804_46.png";
    }

    return signUpDetail?.isSelect || false
      ? "https://akim-oss.aikucun.com/mshop/bac50f0575688981b15853698f91f5bfd730cddb_1716972865804_46.png"
      : "https://akim-oss.aikucun.com/mshop/ab97305ff047ff9fbec5fc5c6c28b0b3f1cb86b3_1716972821728_27.png";
  };

  const onDetailAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onDetailPress?.(signUpDetail);
  };
  return (
    <>
      <div
        className={showCover ? `${styles.card} ${styles.card__transparent}` : styles.card}
        onClick={() => onSelectPress?.(signUpDetail)}
      >
        {supportMulSelect && (
          <div className={styles.card__left}>
            <img className={styles.left__image} src={selectImageSrc()} />
          </div>
        )}
        <div className={styles.card__right}>
          <div className={styles.right__top}>
            <img className={styles.top__image} src={signUpDetail?.studyTourLogo} />
            <div
              className={styles.top__content}
              style={{ marginRight: supportDelete ? pxtorem(20) : 0 }}
            >
              <div className={styles.content__title}>
                <div className={styles.title__left}>{signUpDetail?.studyTourTitle}</div>
                {signUpDetail?.travelAllowance && (
                  <div className={styles.title__right}>
                    <div className={styles.right__title}>{signUpDetail?.travelAllowance}</div>
                  </div>
                )}
              </div>
              <div
                className={styles.content__desc}
              >{`游学活动时间：${getCurrentFormatTime(parseInt(signUpDetail?.studyTourBeginTime || "0", 10))}-${getCurrentFormatTime(parseInt(signUpDetail?.studyTourEndTime || "0", 10))}`}</div>
            </div>
          </div>
          <div className={styles.right__bottom} onClick={onDetailAction}>
            <div className={styles.bottom__title}>{benefitNames}</div>
            <img
              className={styles.bottom__image}
              src="https://akim-oss.aikucun.com/mshop/dc5999c9efd5dc8255f036a748507c62d9f45d21_1716972920290_34.png"
            />
          </div>
          {supportDelete && (
            <div onClick={() => onDeletePress?.(signUpDetail)}>
              <img
                className={styles.right__delete}
                src={
                  "https://akim-oss.aikucun.com/mshop/eec3ad2e003b47ca2dd387bc88c570ac39ada344_1717420794394_34.png"
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StudyActivityCardComp;
