import { useCommonContext } from "@/utils/context/common";
import { pxtorem } from "@/utils/pxtorem";
import { getUrlParam } from "@/utils/url-parse";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import { default as React, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface Props {
  data: any;
  openMore: () => void;
  params: any;
}

const Card: React.FC<Props> = (props: Props) => {
  const [maxHeight, setMaxHeight] = useState<any>(pxtorem(30));
  const { track } = useCommonContext();
  const [searchParams] = useSearchParams();
  const { activitySpuId = "", merchantShopCode, activityNo } = getUrlParam();

  useEffect(() => {
    //初始化
    const height = document.getElementById("text")?.offsetHeight as number;
    const rem: any = document.documentElement.style.fontSize.split("px")[0];
    if (height / rem > 0.6) {
      setMaxHeight(pxtorem(30));
    } else {
      setMaxHeight("unset");
    }
  }, [props.data?.brandDesc]);

  const copyDesc = () => {
    copy(props.data?.brandDesc);
    Toast.show({
      content: "内容已复制",
      position: "center",
      maskClassName: styles["poster-unify-toast"],
    });
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name:
          props.params?.queryType === 14
            ? "商品素材列表"
            : props.params?.queryType === 17
              ? "店铺素材列表"
              : "档期素材列表",
        previous_page_name: searchParams.get("previous_page_name"),
        btn_name: "复制",
        domain: "",
        merchant_shop_code: merchantShopCode,
        activity_id: activityNo,
        activity_spu_code: activitySpuId,
      });
    }
  };

  const open = () => {
    setMaxHeight("unset");
    props.openMore();
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name:
          props.params?.queryType === 14
            ? "商品素材列表"
            : props.params?.queryType === 17
              ? "店铺素材列表"
              : "档期素材列表",
        previous_page_name: searchParams.get("previous_page_name"),
        btn_name: "展开",
        domain: "",
        merchant_shop_code: merchantShopCode,
        activity_id: activityNo,
        activity_spu_code: activitySpuId,
      });
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <img className={styles.logo} src={props.data?.brandLogo} />
        <div className={styles.info}>
          <div className={styles.name}>{props.data?.brandName}</div>
          <div className={styles.nums}>共{props.data?.materialCount}个素材</div>
        </div>
      </div>
      {props.data?.brandDesc && (
        <div className={styles.desc} style={{ maxHeight: maxHeight }}>
          <div id="text">{props.data?.brandDesc}</div>
          <div className={styles.btns}>
            {maxHeight !== "unset" && (
              <>
                <div onClick={() => copyDesc()}>复制</div>
                <div style={{ marginLeft: pxtorem(8) }} onClick={() => open()}>
                  展开
                </div>
              </>
            )}
          </div>
          {maxHeight === "unset" && (
            <div
              style={{ textAlign: "end", marginRight: pxtorem(8), position: "relative" }}
              onClick={() => copyDesc()}
            >
              复制
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
