import { pxtorem } from "@/utils/pxtorem";
import { NoticeBar } from "antd-mobile";
import React from "react";
import styles from "../index.module.less";

const RewardBanner: React.FC<{banner: string}> = ({banner}) => {
  if (!banner) {return "";}
  return (
    <div className={styles.banner}>
      <div
        style={{
          backgroundImage: `url(${banner || "https://akim-oss.aikucun.com/eeb720ec6f81085954371b1233237ae89c5c0460_1713754988667_95.png"})`,
        }}
      >
        {/* <NoticeBar
          content={"您参加的“全民带货领现金 最高奖1000万”活动省赚加码啦～"}
          color="alert"
          style={{
            "--background-color": "#00000080",
            border: "none",
            "--height": pxtorem(25),
            "--text-color": "#FFFFFF",
            "--font-size": pxtorem(11),
          }}
        /> */}
      </div>
    </div>
  );
};
export default RewardBanner;
