import React from "react";

import KeepAlive from "react-activation";
import { useSearchParams } from "react-router-dom";
import Index from "./index";

interface Props {
  pagePath?: any;
}

const PromoteContainer: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const materialNo = search.get("materialNo") || "0";
  const objectIds = search.get("objectIds") || "0";
  const cacheKey = `zhongcaoshouye-${materialNo}-${objectIds}`;
  return (
    <KeepAlive cacheKey={cacheKey} saveScrollPosition={true}>
      <Index />
    </KeepAlive>
  );
};

export default PromoteContainer;
