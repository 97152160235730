import TopNavigation from "@/components/top-navigation";
import VerticalScrollview from "@/components/vertical-scrollview";
import {
  ProductDetailAIMaterialComp,
  ProductDetailBottomBarComp,
  ProductDetailPriceComp,
  ProductDetailPromotionComp,
  ProductDetailRelatedComp,
  ProductDetailShootingNValuationComp,
  ProductDetailSoldRecordComp,
  ProductDetailTopComp,
  ProductDetailVideoNImageComp,
  ProductSKUPannelComp,
} from "@akc/mx-taro-h5-components";
import { Image } from "antd-mobile";
import React from "react";
import { useProductDetail } from "./hooks";
import styles from "./index.module.less";

const ProductDetail: React.FC = () => {
  const control = useProductDetail();
  React.useEffect(() => {
    console.log("商品详情");
  }, []);

  return (
    <VerticalScrollview
      className={styles.container}
      onMoveUpAtBottom={() => {
        console.log("move up~~~~");
      }}
    >
      {/* 导航 */}
      <div className={styles.navBar}>
        <TopNavigation
          hideHomeText={true}
          hideSearchIcon={true}
          hideCartIcon={true}
          hideFavoriteIcon={false}
          center={
            <Image
              className={styles.topStable}
              src="https://akim-oss.aikucun.com/8e2e5c6656109e73430813f34b8b01d794db022f_1724899992042_44.png"
            />
          }
        />
      </div>
      <div className={styles.body}>
        {/* 顶部区域 */}
        <ProductDetailTopComp product={control.productDetail} />

        <div className={styles.content}>
          {/* 价格区域 */}
          <ProductDetailPriceComp product={control.productDetail} />

          {/* 促销信息 */}
          <ProductDetailPromotionComp product={control.productDetail} />

          {/* 规格选择 */}
          <ProductSKUPannelComp product={control.productDetail} packUpSellOut={false} />

          {/* 档期/店铺卡片 */}

          {/* banner */}

          {/* AI 素材 */}
          <ProductDetailAIMaterialComp materials={undefined} />

          {/* 精选素材 */}

          {/* 商品素材 */}
          <ProductDetailVideoNImageComp product={control.productDetail} />
          <ProductDetailShootingNValuationComp product={control.productDetail} />

          {/* 最近购买 */}
          <ProductDetailSoldRecordComp product={control.productDetail} />

          {/* 连带推荐 */}
          <ProductDetailRelatedComp />

          {/* 尺码表 */}

          {/* 详情介绍 */}
        </div>
      </div>

      {/* 底部功能按钮区域 */}
      <ProductDetailBottomBarComp
        className={styles.bottomBtns}
        product={control.productDetail}
        cartCount={3}
        isReminded={false}
        onActivityAction={() => {
          //点击「会场」
        }}
        onServiceAction={() => {
          //点击「客服」
        }}
        onCartAction={() => {
          //点击「购物车」
        }}
        onRemindAction={() => {
          //点击「提醒我」
        }}
        onForwardAction={() => {
          //点击「转发赚钱」
        }}
        onBuyAction={() => {
          //点击「立即购买」
        }}
        onAddCartAction={() => {
          //点击「加入购物车」
        }}
        onWant2BuyAction={() => {
          // 点击「还想买」
        }}
        onExchangeAction={() => {
          //点击「立即兑换」
        }}
      />
    </VerticalScrollview>
  );
};

export default ProductDetail;
