import React, { useMemo } from "react";
import styles from "./AllBrand.module.less";
import { IndexBar, List } from "antd-mobile";
import { useOptions, useToggles } from "../hooks/useFilterOption";

type Props = {
  onClose: () => void;
};

const AllBrand: React.FC<Props> = ({ onClose }) => {
  const { toggleBrand } = useToggles();
  const { brandList } = useOptions();
  // const resetBrand = useResetBrand();

  const charCodeOfA = "A".charCodeAt(0);
  const groups = useMemo(() => {
    return (
      Array(26)
        .fill("")
        .map((_, i) => ({
          title: String.fromCharCode(charCodeOfA + i),
          items:
            brandList.filter(
              (item) =>
                item.linkedObj.brandNamePinYin
                  ?.toUpperCase()
                  .startsWith(String.fromCharCode(charCodeOfA + i)),
            ) ?? [],
        }))
        .filter((group) => group.items.length > 0) ?? []
    );
  }, [brandList]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>全部品牌</div>

      <div className={styles.body}>
        <IndexBar>
          {groups.map((group) => {
            const { title, items } = group;
            return (
              <IndexBar.Panel index={title} title={title} key={title}>
                <List>
                  {items.map((item, index) => (
                    <List.Item key={index}>
                      <div className={styles.brandItem} onClick={() => toggleBrand(item)}>
                        <div
                          className={`${styles.logo} ${item.active ? styles.active : ""}`}
                          style={{
                            backgroundImage: `url(${item.linkedObj.brandLogo})`,
                          }}
                        ></div>

                        <div className={`${styles.brandName} ${item.active ? styles.active : ""}`}>
                          {item.name}
                        </div>
                      </div>
                    </List.Item>
                  ))}
                </List>
              </IndexBar.Panel>
            );
          })}
        </IndexBar>
      </div>

      <div className={styles.footer}>
        <div
          className={styles.reset}
          onClick={() => {
            console.log("");
            // resetBrand();
          }}
        >
          重置
        </div>
        <div className={styles.confirm} onClick={() => onClose()}>
          确定
        </div>
      </div>
    </div>
  );
};

export default AllBrand;
