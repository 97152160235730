import { checkIsNeedPopUp } from "@/api/rewardCenter";
import { getPlatform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { Dialog } from "antd-mobile";
import React from "react";

type Info = {
  appKey?: string;
  showTitle?: string;
  showLink?: string;
  isSubscribe?: boolean;
};
const navigateTo = (info: Info) => {
  const platform = getPlatform();
  if (platform.isApp) {
    AKJS.action.dispatch("event.page.open", {
      url: "/wechatMiniProgram",
      param: {
        path: info.showLink || "/mePkg/pages/promoter-guide/promoter-guide",
        // data.showLink ||
        // (data.appKey === "app_promoter"
        //   ? "mePkg/pages/promoter-guide/promoter-guide"
        //   : "pages/web-view/web-view?url=https%3A%2F%2Fmp.weixin.qq.com%2Fs%3F__biz%3DMzUxMDY1MDc4Mg%3D%3D%26mid%3D2247502553%26idx%3D2%26sn%3D065ceac815e0f6af3042ef6a46b91073%26chksm%3Df97d3a28ce0ab33e2c6eb409d3b9b2020eb82c46eeeddb702af7bee5d93fbfe11b01394ac3ff%23rd"),
      },
    });
  } else {
    window.wx.miniProgram.navigateTo({
      url: info.showLink || "/mePkg/pages/promoter-guide/promoter-guide",
    });
  }
};

const showPromoterDialog = async ({ actId }) => {
  const platform = getPlatform();
  const params = {
    promoActivityId: actId,
    channel: platform.isSaas ? 15 : 4,
  };
  const isShow = await checkIsNeedPopUp(params);
  if (!isShow) {
    return;
  }
  //推广员弹框
  const modal = Dialog.show({
    title: "别再错过任何奖励！",
    content: "中奖消息秒速送达，再也不用担心忘记兑奖！机会不等人，赶紧开启通知吧！",
    closeOnMaskClick: true,
    style: { "--adm-color-text": "#666666" } as React.CSSProperties,
    bodyStyle: {
      textAlign: "center",
    },
    actions: [
      {
        key: "confirm",
        text: "立即开启",
        bold: true,
        style: { color: "#FF4747" },
        onClick: () => {
          modal.close();
          navigateTo({ appKey: "app_promoter" });
        },
      },
    ],
  });
};

export { navigateTo, showPromoterDialog };
