/**
 * 倒计时公用方法抽取
 *
 */
// 向下取整
export function ChangeNumFloor(num) {
  return Math.floor(num);
}
// 后端获取字段转为天：小时：分钟：秒
export function initTime({ type = "ms", startTime = 0, serverTime = 0 } = {}) {
  const now = type === "s" ? Number(serverTime) * 1000 : serverTime; // 1641564283   后端传过来的字段是s这里做了一下兼容如果是ms也支持
  const getStartTime = String(startTime).replace(/-/g, "/"); // 预设开课时间  后端字段接口里面取字段时间格式 "2022-01-14 09:00:00" 转换成2022/01/14 09:00:00 否则手机时间无效时间
  const endDate = new Date(Number(getStartTime));
  const end = endDate.getTime();
  const leftTime = end - now;
  const day = ChangeNumFloor(leftTime / 1000 / 60 / 60 / 24);
  const hour = ChangeNumFloor((leftTime / 1000 / 60 / 60) % 24);
  const minute = ChangeNumFloor((leftTime / 1000 / 60) % 60);
  const seconds = ChangeNumFloor((leftTime / 1000) % 60);
  return {
    day,
    hour,
    minute,
    seconds,
    leftTime,
  };
}
// 天：小时：分钟：秒 单个数字需要补0
export function CheckTime(i) {
  return i ? i.toString().padStart(2, "0") : "0";
}
