import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import Logout from "@/pages/my/components/common-logout";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SettingItem from "./components/setting-item";
import styles from "./index.module.less";

const mySetting: React.FC = () => {
  const [search] = useSearchParams();
  const shopId = search.get("shopId");
  const navigate = useNavigate();

  const itemGroup1 = [
    {
      title: "店铺咨询二维码设置",
      key: "imSettingItem",
      path: "/#/im-setting",
    },
    {
      title: "联系店主",
      key: "consultShopOwnerItem",
      last: true,
    },
  ];

  const itemGroup2 = [
    {
      title: "联系客服",
      key: "serviceItem",
      last: true,
    },
  ];

  const itemGroup3 = [
    {
      title: "店铺设置",
      key: "storeSettingItem",
      path: "/#/store-setting",
    },
    {
      title: "转发设置",
      key: "transferSettingItem",
      path: "/mx-shop-micro/share-setting",
    },
    {
      title: "店铺认证",
      key: "shopCertificationItem",
      path: "/#/shop/certification",
    },
    {
      title: "收货地址",
      key: "addressItem",
      path: "/#/addressList",
    },
    {
      title: "实名认证",
      key: "authNameItem",
      path: "/#/authname",
      last: true,
    },
  ];

  const itemGroup4 = [
    {
      title: "商品/活动海报昵称",
      key: "posterNickname",
      last: true,
    },
  ];

  const itemGroup5 = [
    {
      title: "关于我们",
      key: "legalProtocolItem",
      path: "/#/legal-protocol",
    },
    {
      title: "资质信息",
      key: "qualificationItem",
      last: true,
    },
  ];

  const goHome = () => {
    if (search.get("distributorId")) {
      navigate(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigate(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  };

  useEffect(() => {
    document.title = "设置";
  }, []);

  return (
    <div className={styles.settingPage}>
      <div className={styles["nav"]}>
        <div className={styles["nav-box"]}>
          <div className={styles["nav-box__image-box"]} onClick={() => navigate(-1)}>
            <img src={BackPng} className={styles["image-box__image"]} />
          </div>
          <div className={styles["nav-box__image-box"]} onClick={goHome}>
            <img src={HomePng} className={styles["image-box__image"]} />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.group}>
          {itemGroup1.map((item) => (
            <SettingItem key={item.key} item={item} />
          ))}
        </div>
        <div className={styles.group}>
          {itemGroup2.map((item) => (
            <SettingItem key={item.key} item={item} />
          ))}
        </div>
        <div className={styles.group}>
          {itemGroup3.map((item) => (
            <SettingItem key={item.key} item={item} />
          ))}
        </div>
        <div className={styles.group}>
          {itemGroup4.map((item) => (
            <SettingItem key={item.key} item={item} />
          ))}
        </div>
        <div className={styles.group}>
          {itemGroup5.map((item) => (
            <SettingItem key={item.key} item={item} />
          ))}
        </div>
        <Logout />
      </div>
      <div className={styles.footer} id="footer">
        <a href="https://beian.miit.gov.cn/">沪ICP备18031936号</a>
      </div>
    </div>
  );
};

export default mySetting;
