import { useEffect, useRef, useState } from "react";
interface CountdownProps {
  timeStart?: number | Date;
  timeEnd?: number | Date;
  remainder?: number;
  duration?: number;
  unit?: "Y" | "M" | "D" | "H" | "m";
  complete?: () => void;
}

const computedCountdownTime = (time: number, unit?: CountdownProps["unit"]) => {
  const uintY = 1000 * 60 * 60 * 24 * 365;
  const uintM = 1000 * 60 * 60 * 24 * 30;
  const uintD = 1000 * 60 * 60 * 24;
  const uintH = 1000 * 60 * 60;
  let years = 0;
  let months = 0;
  let days = 0;
  let hours = 0;
  let minutes = 0;
  if (unit === "Y") {
    years = Math.floor(time / (1000 * 60 * 60 * 24 * 365));
    months = Math.floor((time % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
    days = Math.floor((time % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
    hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  }
  if (unit === "M") {
    months = Math.floor(time / (1000 * 60 * 60 * 24 * 30));
    days = Math.floor((time % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
    hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  }
  if (unit === "D") {
    days = Math.floor(time / (1000 * 60 * 60 * 24));
    hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  }
  if (unit === "H") {
    hours = Math.floor(time / (1000 * 60 * 60));
    minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  }
  if (unit === "m") {
    minutes = Math.floor(time / (1000 * 60));
  }
  const seconds = Math.floor((time % (1000 * 60)) / 1000);
  const milliseconds = Math.floor((time % (1000 * 60 * 10)) / 100);
  const microseconds = Math.floor((time % (1000 * 60 * 100)) / 10);
  return { years, months, days, hours, minutes, seconds, milliseconds, microseconds };
};

function useCountdown({
  timeStart,
  timeEnd,
  remainder,
  duration = 1000,
  unit = "D",
  complete,
}: CountdownProps) {
  const timer = useRef<any>();
  const [dateTime, setDateTime] = useState(0);
  const [countdown, setCountdown] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    microseconds: 0,
  });
  useEffect(() => {
    console.log("remainder", remainder);
    let time = dateTime;
    if (remainder && remainder >= 0) {
      time = remainder;
    } else if (timeStart && timeEnd) {
      time = new Date(timeStart).valueOf() - new Date(timeEnd).valueOf();
    } else {
      time = 0;
    }
    clearInterval(timer.current);
    setCountdown(computedCountdownTime(time, unit));
    setDateTime(time);
  }, [timeStart, timeEnd, remainder]);

  useEffect(() => {
    console.log("dateTime", dateTime);
    let time = dateTime;
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      time = time - duration;
      if (time < 0) {
        complete?.();
        clearInterval(timer.current);
        setCountdown({
          years: 0,
          months: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
          microseconds: 0,
        });
      } else {
        setCountdown(computedCountdownTime(time, unit));
      }
    }, duration);
    return () => clearInterval(timer.current);
  }, [dateTime]);

  return countdown;
}

export { computedCountdownTime, useCountdown };
