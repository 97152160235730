import { pxtorem } from "@/utils/pxtorem";
import {
  ConferenceActivity,
  RecommendActivity,
  RecommendProduct,
  RecommendSchedule,
} from "@akc/mx-taro-h5-components";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTrack } from "../../track";
import styles from "./pocket.module.less";

interface Props {
  close?: () => void;
  goCart?: () => void;
  addCart?: (item: any, index: number, curTab: number) => void;
  goDetail?: (item: any, index: number, curTab: number) => void;
  stillBuy?: (item: any, index: number, curTab: number) => void;
  goLive?: (item: any, index: number, curTab: number) => void;
  goActivity?: (item: any, index: number, curTab: number) => void;
  goConference?: (item: any, index: number, curTab: number) => void;
  productList?: any;
  scheduleList?: any;
  activityList?: any;
  conferenceList?: any;
  cartTotal?: any;
  materialNo?: string;
  currentVideoIndex?: number;
  data?: any;
}

const PocketCom: React.FC<Props> = (props: Props) => {
  const [curTab, setCurTab] = useState(
    props?.productList?.length > 0
      ? 0
      : props?.activityList?.length > 0 ||
          props?.scheduleList?.length > 0 ||
          props?.conferenceList?.length > 0
        ? 1
        : 0,
  );
  const [search] = useSearchParams();
  const hasTabs =
    props?.productList?.length > 0 &&
    (props?.activityList?.length > 0 ||
      props?.scheduleList?.length > 0 ||
      props?.conferenceList?.length > 0);
  const { trackPocketExpose } = useTrack();
  const fromPage = search.get("fromPage");

  //商品口袋列表曝光埋点
  useEffect(() => {
    if (curTab === 0) {
      props?.productList?.map((item, index) => {
        trackPocketExpose({
          item,
          index,
          resource_content: "商品",
          materialNo: props?.materialNo || "",
          fromPage,
        });
      });
    } else if (curTab === 1) {
      props?.activityList?.map((item, index) => {
        trackPocketExpose({
          item,
          index,
          resource_content: "档期",
          materialNo: props?.materialNo || "",
          fromPage,
        });
      });

      props?.conferenceList?.map((item, index) => {
        trackPocketExpose({
          item,
          index: index + props?.activityList.length,
          resource_content: "会场",
          materialNo: props?.materialNo || "",
          fromPage,
        });
      });
    }
  }, [curTab]);

  const changeTab = (index) => {
    if (index !== curTab) {
      const dom: any = document.getElementById("cur_tabs");
      if (dom) {
        dom.scrollTop = 0;
      }
    }
    setCurTab(index);
  };

  return (
    <>
      <div className={styles.header} style={{ paddingTop: hasTabs ? 0 : pxtorem(16) }}>
        {hasTabs ? (
          <div className={styles.tabs}>
            <div
              onClick={() => changeTab(0)}
              className={styles.tab + " " + (curTab === 0 ? styles.active : "")}
            >
              商品
            </div>
            <div
              onClick={() => changeTab(1)}
              className={styles.tab + " " + (curTab === 1 ? styles.active : "")}
            >
              会场
            </div>
            <div
              className={styles.line}
              style={{ transform: `translateX(${curTab === 0 ? pxtorem(112) : pxtorem(239)})` }}
            ></div>
          </div>
        ) : (
          <div className={styles.text}>{props?.productList?.length > 0 ? "商品" : "会场"}</div>
        )}
        <img
          onClick={props?.goCart}
          className={styles.cart}
          src="https://akim-oss.aikucun.com/edc5b5bdb5b890bc864465f536d25d13928df87c_1702467126126_64.png"
        />
        {props?.cartTotal > 0 && <div className={styles.cartNum}>{props?.cartTotal}</div>}
      </div>
      <div
        id="cur_tabs"
        className={
          styles.content + " " + (hasTabs ? styles.content_height : styles.content_all_height)
        }
      >
        {curTab === 0 &&
          props?.productList?.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: pxtorem(8) }}>
                <RecommendProduct
                  product={item}
                  text="赚"
                  titleDown="H5_MaterialList_ProductNameDown"
                  goLive={(item) => {
                    props?.goLive && props?.goLive(item, index, 0);
                  }}
                  addCart={(item) => {
                    props?.addCart && props?.addCart(item, index, 0);
                  }}
                  goProduct={(item) => {
                    props?.goDetail && props?.goDetail(item, index, 0);
                  }}
                  stillBuy={(item) => {
                    props?.stillBuy && props?.stillBuy(item, index, 0);
                  }}
                />
              </div>
            );
          })}
        {curTab === 1 &&
          props?.activityList?.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: pxtorem(8) }}>
                <RecommendActivity
                  key={index}
                  text="赚"
                  activity={item}
                  brandDown="H5_MaterialList_ScheduleNameDown"
                  openLive={(item) => {
                    props?.goLive && props?.goLive(item, index, 1);
                  }}
                  goProductDetail={(item) => {
                    props?.goDetail && props?.goDetail(item, index, 1);
                  }}
                  goActivityDetail={(item) => {
                    props?.goActivity && props?.goActivity(item, index, 1);
                  }}
                />
              </div>
            );
          })}
        {curTab === 1 &&
          props?.scheduleList?.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: pxtorem(8) }}>
                <RecommendSchedule
                  key={index}
                  text="赚"
                  activity={item}
                  brandDown="H5_MaterialList_ScheduleNameDown"
                />
              </div>
            );
          })}
        {curTab === 1 &&
          props?.conferenceList?.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: pxtorem(8) }}>
                <ConferenceActivity
                  key={index}
                  conference={item}
                  text="赚"
                  brandDown="H5_MaterialList_ScheduleNameDown"
                  goConferenceDetail={(item) => {
                    props?.goConference && props?.goConference(item, index, 2);
                  }}
                />
              </div>
            );
          })}
        <div className={styles.bottom}>已经到底啦～</div>
      </div>
    </>
  );
};

export default PocketCom;
