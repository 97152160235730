import ArrowRight from "@/assets/arrow-right-black.png";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import React from "react";
import styles from "./index.module.less";
interface IProps {
  userinfo: any;
}
const MyBalance: React.FC<IProps> = ({ userinfo }) => {
  const { navigate2H5 } = useNavigation();
  const goWallet = () => {
    const options: IOptions = {
      path: "/#/wallet",
      is_micro_app: true,
    };
    navigate2H5(options);
  };
  return (
    <div className={styles["container"]} onClick={goWallet}>
      <div className={styles["title"]}>我的余额(元)</div>
      <div className={styles["number"]}>
        <span className={styles["number__text"]}>{userinfo.amount}</span>
        <img className={styles["number__arrow-icon"]} src={ArrowRight} alt="" />
      </div>
    </div>
  );
};
export default MyBalance;
