import loadingPng from "@/base64";
import PriceComp from "@/components/basics/price";
import DatePickerV2 from "@/components/data-picker2";
import { pxtorem } from "@/utils/pxtorem";
import { Image, InfiniteScroll } from "antd-mobile";
import React from "react";
import RedPacketDetailCardComp from "./components/RedPacketDetailCard";
import useRedPacketDetail from "./hook/useRedPacketDetail";
import styles from "./redPacketDetail.module.less";
/**
 * 红包明细列表
 */
const RedPacketDetail: React.FC = () => {
  const {
    list,
    hasMore,
    loading,
    loadMore,
    onDateClick,
    datePickerVisible,
    setDatePickerVisible,
    getTimeFromDatePicker,
    selectDateStr,
    redPacket,
  } = useRedPacketDetail();

  return (
    <div id="page" className={styles.container}>
      <div className={styles.head}>
        <div className={styles.totalBalance}>
          <div className={styles.balanceN}>总余额(元)</div>
          <PriceComp
            className={styles.balancePrice}
            price={redPacket?.freeBalance ? Number(redPacket?.freeBalance) : 0}
            intClassName={styles.balanceT}
            decimalsClassName={styles.balanceTD}
          />
        </div>

        <div className={styles.dateFilterC}>
          <div className={styles.dateFilter}>
            <div className={styles.titleL}>账户明细</div>
            <div
              className={styles.dateC}
              onClick={() => {
                onDateClick();
              }}
            >
              <div className={styles.dateR}>{selectDateStr}</div>
              <img
                className={styles.downIcon}
                src={
                  "https://akim-oss.aikucun.com/a36c52b42e462e85ae33b80b75b7f62323b15953_1724658405838_4.png"
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              {list.map((item, index) => (
                <RedPacketDetailCardComp
                  key={index}
                  detail={item}
                  showLine={index !== list.length - 1}
                />
              ))}
              <div className={styles.footer}>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={100} />
              </div>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.empty_icon}
                  src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                />
                <div>暂无相关明细</div>
              </div>
            )
          )}
        </>
      </div>
      <DatePickerV2
        popVisible={datePickerVisible}
        close={() => {
          setDatePickerVisible(false);
        }}
        getTimeRange={getTimeFromDatePicker}
      />
    </div>
  );
};

export default RedPacketDetail;
