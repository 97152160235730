import React from "react";
import KeepAlive, { useActivate, useUnactivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import Index from "./Search";

interface Props {
  pagePath?: any;
}

const SearchContainer: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const merchantShopCode = search.get("merchantShopCode");

  useActivate(() => {
    console.log("search444------");
  });

  useUnactivate(() => {
    console.log("search444------");
  });

  return (
    <KeepAlive cacheKey={`search-${merchantShopCode}`} saveScrollPosition={true}>
      <Index />
    </KeepAlive>
  );
};

export default SearchContainer;
