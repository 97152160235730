import { Popup } from "antd-mobile";
import React, { useEffect, useState } from "react";
import CouponCard from "./CouponCard";
import styles from "./CouponsBoard.module.less";

type Props = {
  list: any[];
  isVisitor: boolean;
  updateCouponList: () => void;
  toBind: () => void;
};

const CouponsBoard: React.FC<Props> = ({ list, updateCouponList, isVisitor, toBind }) => {
  const [isShowPop, setIsShowPop] = useState(false);
  const [isShowCouponPop, setIsShowCouponPop] = useState(false);

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>({list.length})</div>
          <div className={styles.aside} onClick={() => setIsShowCouponPop(true)}>
            更多福利
          </div>
        </div>
        <div className={styles.list} onClick={toBind}>
          {list.map((item, index) => {
            item.relativeRank = index;
            return (
              <CouponCard
                key={item.awdId}
                data={item}
                isVisitor={isVisitor}
                updateCouponList={updateCouponList}
              />
            );
          })}
        </div>
        {isVisitor && (
          <div className={styles.loginBtn} onClick={toBind}>
            立即登录
          </div>
        )}
        <div
          className={styles.footer}
          onClick={() => {
            setIsShowPop(true);
          }}
        >
          <div className={styles.item}>100%正品</div>
          <div className={styles.item}>天天特卖</div>
          <div className={styles.item}>退换无忧</div>
          <div className={styles.itemArrow}></div>
        </div>
      </div>
      <Popup
        visible={isShowCouponPop}
        onMaskClick={() => {
          setIsShowCouponPop(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          zIndex: 10000,
        }}
      >
        <div className={styles.couponPopWrap}>
          <div className={styles.popHeader}>
            新人专享券 ({list.length})
            <div
              className={styles.closeBtn}
              onClick={() => {
                setIsShowCouponPop(false);
              }}
            ></div>
          </div>
          <div className={styles.longCouponList}>
            {list.map((item, index) => {
              item.relativeRank = index;
              return (
                <CouponCard
                  key={item.awdId}
                  data={item}
                  isVisitor={isVisitor}
                  styleType="long"
                  updateCouponList={updateCouponList}
                  setIsShowCouponPop={setIsShowCouponPop}
                />
              );
            })}
          </div>
        </div>
      </Popup>
      <Popup
        visible={isShowPop}
        onMaskClick={() => {
          setIsShowPop(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          minHeight: "40vh",
          paddingBottom: "40px",
          zIndex: 10000,
        }}
      >
        <div className={styles.popHeader}>
          平台保障
          <div
            className={styles.closeBtn}
            onClick={() => {
              setIsShowPop(false);
            }}
          ></div>
        </div>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>品牌特卖</dt>
          <dd className={styles.popItemCont}>
            与各大品牌方合作，深度挖掘好质量、好价格、好款式的品牌好货
          </dd>
        </dl>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>正品保证</dt>
          <dd className={styles.popItemCont}>所有货源均来自官方渠道，保证100%正品</dd>
        </dl>
        <dl className={styles.popItem}>
          <dt className={styles.popItemTitle}>售后无忧</dt>
          <dd className={styles.popItemCont}>
            提供7天无理由退换货、免费上门取件和自动补运费差价等服务，全程保障优质购物体验
          </dd>
        </dl>
      </Popup>
    </>
  );
};

export default CouponsBoard;
