import moment from "moment";

// 日期格式化函数
export const formatDate = (date) => {
  // 数字格式化函数，补齐前导零
  function formatNumber(val) {
    return val < 10 ? "0" + val : val;
  }
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份（注意要加1，因为月份从0开始）
  const day = date.getDate(); // 获取日

  return `${year}-${formatNumber(month)}-${formatNumber(day)}`;
  // 使用字符串模板语法将年月日组合成字符串，并按照指定格式输出
};

export const todayTimeRange = () => {
  // 获取当前日期时间
  const currentDate = new Date();

  // 将当前日期时间格式化为 yyyy-mm-dd hh:mm:ss 的形式
  const format = (num) => (num < 10 ? `0${num}` : num);
  const currentDateTimeStr = `${currentDate.getFullYear()}-${format(
    currentDate.getMonth() + 1,
  )}-${format(currentDate.getDate())} ${format(currentDate.getHours())}:${format(
    currentDate.getMinutes(),
  )}:${format(currentDate.getSeconds())}`;

  // 输出当天日期从 0 点到 24 点的时间区间，即 [yyyy-mm-dd 00:00:00, yyyy-mm-dd 23:59:59]
  const currentDateString = currentDateTimeStr.slice(0, 10);
  const startDateTimeStr = `${currentDateString} 00:00:00`;
  const endDateTimeStr = `${currentDateString} 23:59:59`;

  const todayTimeRange = [`${startDateTimeStr}`, `${endDateTimeStr}`];
  return todayTimeRange;
};

//获取整点时刻，如：近七天
export const getZeroDateFromToday = (n) => {
  const today = new Date();
  if (n === 1) {
    const nDaysAgo = new Date(today.getTime() - n * 24 * 60 * 60 * 1000);
    const formatNumber = (n) => (n < 10 ? "0" + n : n);
    const nDaysAgoStr =
      nDaysAgo.getFullYear() +
      "-" +
      formatNumber(nDaysAgo.getMonth() + 1) +
      "-" +
      formatNumber(nDaysAgo.getDate()) +
      " " +
      "00" +
      ":" +
      "00" +
      ":" +
      "00";
    const todayStr =
      today.getFullYear() +
      "-" +
      formatNumber(nDaysAgo.getMonth() + 1) +
      "-" +
      formatNumber(nDaysAgo.getDate()) +
      " " +
      "23" +
      ":" +
      "59" +
      ":" +
      "59";
    return [nDaysAgoStr, todayStr];
  } else {
    const nDaysAgo = new Date(today.getTime() - (n - 1) * 24 * 60 * 60 * 1000);

    // 手动格式化日期字符串
    const formatNumber = (n) => (n < 10 ? "0" + n : n);
    const nDaysAgoStr =
      nDaysAgo.getFullYear() +
      "-" +
      formatNumber(nDaysAgo.getMonth() + 1) +
      "-" +
      formatNumber(nDaysAgo.getDate()) +
      " " +
      "00" +
      ":" +
      "00" +
      ":" +
      "00";
    const todayStr =
      today.getFullYear() +
      "-" +
      formatNumber(today.getMonth() + 1) +
      "-" +
      formatNumber(today.getDate()) +
      " " +
      "23" +
      ":" +
      "59" +
      ":" +
      "59";
    return [nDaysAgoStr, todayStr];
  }
};

// 获取当天日期，格式 yyyy-mm-dd hh:mm:ss
export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();
  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hour
    .toString()
    .padStart(2, "0")}:${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
};

export const countDown = (endTimeList, { needDay = true } = {}, newTime = new Date().getTime()) => {
  const timeDown = (endTime) => {
    let obj: any;
    // 如果活动未结束，对时间进行处理
    if (endTime - newTime > 0) {
      const time = (endTime - newTime) / 1000;
      // 获取天、时、分、秒
      const day = parseInt("" + time / (60 * 60 * 24), 10);
      let hou: number;
      if (needDay) {
        hou = parseInt("" + (time % (60 * 60 * 24)) / 3600, 10);
      } else {
        hou = parseInt("" + time / 3600, 10);
      }
      const min = parseInt("" + ((time % (60 * 60 * 24)) % 3600) / 60, 10);
      const sec = parseInt("" + (((time % (60 * 60 * 24)) % 3600) % 60), 10);
      const ms = parseInt("" + (((endTime - newTime) / 100) % 10), 10);

      let type = 0;
      if (day > 0) {
        type = 1;
      }
      obj = {
        day: day < 10 ? `${day}` : day,
        hou: hou < 10 ? `0${hou}` : hou,
        min: min < 10 ? `0${min}` : min,
        sec: sec < 10 ? `0${sec}` : sec,
        ms,
        isOver: false,
        type,
      };
    } else {
      // 活动已结束，全部设置为'00'
      obj = {
        day: "00",
        hou: "00",
        min: "00",
        sec: "00",
        ms: "0",
        isOver: true,
      };
    }
    return obj;
  };
  if (endTimeList && !Array.isArray(endTimeList)) {
    if (typeof endTimeList === "string" && endTimeList.indexOf("-") > 0) {
      // 字符串日期
      endTimeList = new Date(endTimeList.replace(/-/g, "/")).getTime();
    }
    return timeDown(endTimeList);
  }
  const countDownArr: any[] = [];
  endTimeList &&
    endTimeList.forEach((o) => {
      const obj = timeDown(o);
      countDownArr.push(obj);
    });
  return countDownArr;
};

// 昨天
export const yesterdayYYMMDD = (date) => {
  const currentday = date;
  currentday.setDate(currentday.getDate() - 1);
  return moment(currentday).format("yyyy-MM-DD");
};

// 近7天
export const sevenDayBeforeYYMMDD = (date) => {
  const currentday = date;
  currentday.setDate(currentday.getDate() - 6);
  return moment(currentday).format("yyyy-MM-DD");
};

//近30天
export const thirtyDayBeforeYYMMDD = (date) => {
  const currentday = date;
  currentday.setDate(currentday.getDate() - 29);
  return moment(currentday).format("yyyy-MM-DD");
};

// 获取当天日期，格式 yyyy-mm-dd hh:mm:ss
export const getCurrentformatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hour
    .toString()
    .padStart(2, "0")}:${minute.toString().padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
};

// 获取当天日期，格式 yyyy.mm.dd
export const getCurrentFormatTime = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}.${month.toString().padStart(2, "0")}.${day.toString().padStart(2, "0")}`;
};

// 日期格式化函数
export const formatDateYYYYMM = (date) => {
  // 数字格式化函数，补齐前导零
  function formatNumber(val) {
    return val < 10 ? "0" + val : val;
  }
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份（注意要加1，因为月份从0开始）

  return `${year}-${formatNumber(month)}`;
  // 使用字符串模板语法将年月日组合成字符串，并按照指定格式输出
};

export const formatDateYYYYMM2 = (date) => {
  // 数字格式化函数，补齐前导零
  function formatNumber(val) {
    return val < 10 ? "0" + val : val;
  }
  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份（注意要加1，因为月份从0开始）

  return `${year}年${formatNumber(month)}月`;
  // 使用字符串模板语法将年月日组合成字符串，并按照指定格式输出
};
