import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./rank-item.module.less";


type Props = {
  item: any;
};
const RankItem: React.FC<Props> = ({ item = {} }) => {

  const rankDom = () => {
    switch (item.rank) {
      case -1:
        return <div>-</div>;
        break;
      case 1:
        return <img className={styles.rank__icon} src="https://akim-oss.aikucun.com/mshop/486aceb7bdf52a9210065d63e9ba71b617525d45_1731486026091_96.png" />;
        break;
      case 2:
        return <img className={styles.rank__icon} src="https://akim-oss.aikucun.com/mshop/0774a9a831b9e82ba09ed3334ca3077c678f67fd_1731486079413_67.png" />;
        break;
      case 3:
        return <img className={styles.rank__icon} src="https://akim-oss.aikucun.com/mshop/3b268c64c2b5dc5d031f6e891d4a05fdcbd7e371_1731486100790_80.png" />;
        break;
      default:
        return <div>{item.rank}</div>;
        break;
    }
  };

  return (
    <div className={styles.rank_item}>
      <div className={styles.item__rank}>
        {rankDom()}
      </div>
      <div className={styles.item__info}>
        <img className={styles.info__avatar} src={item.avatar} />
        <div className={styles.info__right}>
          <div className={styles.right__code}>
            <span>{item.userCode}</span>
            {/* 入围排名 */}
            {
              item.finalistFlag && <img className={styles.finalist} src="https://akim-oss.aikucun.com/mshop/4c95302984a6273f7a299b5fe4bc0c4902c5f370_1731485945474_5.png" />
            }
          </div>
          <p className={styles.right__gmv}>品牌销售额：{item.gmv}元</p>
        </div>
      </div>

      {/* 我的排名 */}
      {
        item.myselfFlag && <img className={styles.myself} src="https://akim-oss.aikucun.com/mshop/9979e41bf70af3f5314431a4cd82c4c6a503e07d_1731485994889_76.png" />
      }
    </div >
  );
};

export default RankItem;