import loadingPng from "@/base64";
import Bottom from "@/components/bottom";
import { pxtorem } from "@/utils/pxtorem";
import { YouXueSignUpCardComp } from "@akc/mx-taro-h5-components";
import { Image, InfiniteScroll } from "antd-mobile";
import React from "react";
import useStudyTourHistory from "./hook/useStudyTourHistory";
import styles from "./index.module.less";
import { YXMouthPicker } from "./picker";

const YouxueHistoryPage: React.FC = () => {
  const {
    list,
    hasMore,
    loading,
    visiblePicker,
    selectValue,
    loadMore,
    onClosePickerAction,
    onConfirmAction,
    onSelectAction,
    selectPickerTitle,
    onStudyTourActivityDetail,
  } = useStudyTourHistory();

  return (
    <div id="page" className={styles.page}>
      <div className={styles.page__header}>
        <div className={styles.header__select} onClick={onSelectAction}>
          <div className={styles.select__right}>
            {selectPickerTitle()}
            <img
              className={styles.icon__arrow}
              src="https://akim-oss.aikucun.com/mshop/31638c8d685da2f7a7b147912126d346d379cb27_1717552468771_90.png"
            />
          </div>
        </div>
      </div>
      <div className={styles.page__body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              <div className={styles.card}>
                {list.map((item, index) => {
                  return (
                    <>
                      <div className={styles.card__header}>
                        <div className={styles.header__title}>{item?.month}</div>
                        {item?.studyTourProfileVOS?.map((t, innerIndex) => {
                          return (
                            <YouXueSignUpCardComp
                              style={{
                                paddingBottom: pxtorem(12),
                                paddingTop: innerIndex === 0 ? pxtorem(3) : 0,
                              }}
                              key={index}
                              detail={t}
                              onAction={() => onStudyTourActivityDetail(t)}
                            />
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={200}>
                <Bottom
                  loading={loading}
                  hasMore={hasMore}
                  reachBottom={list && list?.length > 0 ? true : false}
                />
              </InfiniteScroll>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.emptyIcon}
                  src="https://akim-oss.aikucun.com/ec86de815884a3fbc3c4450953a9a122fb77879c_1706514708184_43.png"
                />
                <div>暂无相关任务</div>
              </div>
            )
          )}
        </>
      </div>

      {visiblePicker && (
        <YXMouthPicker
          visible={visiblePicker}
          onClosePress={onClosePickerAction}
          defaultValue={selectValue.current}
          onConfirmPress={onConfirmAction}
        />
      )}
    </div>
  );
};

export default YouxueHistoryPage;
