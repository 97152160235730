import { isSwitchBatch } from "@/api";
import { getCookie, getPlatform } from "@/utils/platform";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import { Mask } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { btn_click } from "../../track";

import ArrowRight from "@/assets/arrow_right@2.png";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const menuList = [
  {
    icon: "https://akim-oss.aikucun.com/mshop/0dc514caf9a8428c8748aff654d8af31724fc4ff_1715062228706_90.png",
    text: "待付款",
    type: 0,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/7ded5c855c84f11d18f57eb952ac3c45baefb247_1715062163235_10.png",
    text: "待发货",
    type: 1,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/8f4d1822000722aec5c6e5bf30b32ae3e4b26b29_1715062134390_48.png",
    text: "待收货",
    type: 2,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/c70b5b7957596726b064cf9f6f9cca4bf36ae766_1715062269793_46.png",
    text: "已完成",
    type: 20,
  },
  {
    icon: "https://akim-oss.aikucun.com/mshop/c3794ca936b0ecc6726b20ab2d89c75a1e3771f5_1715062301838_16.png",
    text: "退换/售后",
    type: 11,
  },
];

type Props = {
  onAddressGuide?: () => void;
};

const OrderCenter: React.FC<Props> = ({ onAddressGuide }) => {
  const { isWxH5 } = getPlatform();
  const msUserCode = getCookie("msUserCode");
  const { navigate2H5 } = useNavigation();
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const goOrder = (type: number | undefined, text: string) => {
    const shopId = searchParams.get("shopId");
    const distributorId = searchParams.get("distributorId");
    const options: IOptions = {
      path: "/#/order/list",
      is_micro_app: true,
      query: `type=${type}`,
    };
    if (type === 11) {
      // 跳售后
      // options.path = "/aftersale/serviceRecord";
      // options.page_name = "aftersale";
      // options.query = `/aftersale/serviceRecord?source=3&auditChannel=${isWxH5 ? "2" : "6"}&type=11&queryScene=1&isDistributeOrder=0&userId=${msUserCode}`;
      window.location.href = `/aftersale/serviceRecord?source=3&auditChannel=${
        isWxH5 ? "2" : "6"
      }&type=11&queryScene=1&isDistributeOrder=0&userId=${msUserCode}&shopId=${shopId}${
        distributorId ? `&distributorId=${distributorId}` : ""
      }`;
    } else {
      navigate2H5(options);
    }
    btn_click("我的-订单", text + "订单");
  };
  const queryOrderGuide = async () => {
    const res = await isSwitchBatch({ bwlCodes: ["B-HomePageRefatorH5"] });
    const isHit = !!res?.data[0]?.isHit;
    const dom = document.getElementById("tab-bar-mask");
    const dom2 = document.getElementById("tab-bar-bottom");
    const isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    setVisible(isHit);
    if (isHit && dom && dom2 && isiOS) {
      dom.style.width = "100%";
      dom.style.height = "100%";
      dom2.style.borderTop = "none";
    }
  };
  const onGuide = () => {
    setVisible(false);
    onAddressGuide?.();
    localStorage.setItem("_cache_admin_guide", "1");
  };

  useEffect(() => {
    if (
      !localStorage.getItem("_cache_admin_guide") &&
      (JSON.parse(localStorage.getItem("userinfo") ?? "") ?? {})?.currentRole === 4
    ) {
      queryOrderGuide();
    }
  }, []);

  return (
    <>
      <div className={styles["order-center"]} style={{ zIndex: visible ? 11000 : 10 }}>
        <div className={styles["order-center-header"]}>
          <div className={styles["order-center-header__title"]}>订单中心</div>
          <div
            className={styles["order-center-header__more"]}
            onClick={() => goOrder(undefined, "查看全部")}
          >
            查看全部
            <img src={ArrowRight} alt="" />
          </div>
        </div>
        <div className={styles["order-center-body"]}>
          {menuList.map((item, index) => (
            <div
              className={styles["order-center-body__item"]}
              key={index}
              onClick={() => goOrder(item.type, item.text)}
            >
              <img className={styles["order-center-body__item__icon"]} src={item.icon} alt="" />
              <div className={styles["order-center-body__item__text"]}>{item.text}</div>
            </div>
          ))}
        </div>
        {visible && (
          <>
            <div className={styles["order-center-guide__line"]}></div>
            <div className={styles["order-center-guide__txt"]}>
              新版订单中心搬到这里啦～
              <br />
              可在此查看所有订单哦～
            </div>
            <div className={styles["order-center-guide__button"]} onClick={() => onGuide()}>
              我知道了
            </div>
          </>
        )}
      </div>
      <Mask
        visible={visible}
        disableBodyScroll={true}
        opacity={0.7}
        style={{ "--z-index": "10100" }}
        onMaskClick={() => onGuide()}
      />
    </>
  );
};
export default OrderCenter;
