import TopNavigation from "@/components/top-navigation";
import React from "react";
import { useSelectedActivity, useSelectedActivityIdList } from "../hooks/useActivityProduct";
import SelectionHeader from "./SelectionHeader";

type Props = {
  showSearchIcon: boolean;
  children?: React.ReactNode;
};

const Navigation: React.FC<Props> = ({ children, showSearchIcon }) => {
  const selectedIdList = useSelectedActivityIdList();
  const selectedActivity = useSelectedActivity();

  return (
    <TopNavigation
      showSearchIcon={showSearchIcon}
      showCartIcon={true}
      showFavoriteIcon={true}
      searchPageParams={{
        from: "brand",
        scene: "SEARCH_PRODUCT_FOR_ACTIVITY",
        activityId: selectedIdList.length > 0 ? selectedIdList.join(",") : "",
        activityType: selectedActivity?.activityType,
      }}
      center={children}
    />
  );
};

export default Navigation;
