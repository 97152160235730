import EmptyList from "@/assets/empty-list.png";
import React from "react";
import styles from "./index.module.less";

interface EmptyProps {
  label: string;
}

const Empty: React.FC<EmptyProps> = ({ label }) => {
  return (
    <div className={styles.empty}>
      <img className={styles.picture} src={EmptyList} alt="" />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default Empty;
