import { queryZoneCouponData } from "@/api/coupon/index";
import { useEffect, useState } from "react";

const useZoneCoupon = () => {
  const [thirdCustomerFlag, setThirdCustomerFlag] = useState<number>(0);
  const [thirdCustomerCoupons, setThirdCustomerCoupons] = useState<any[]>([]);

  const query = async () => {
    const params = {
      shopBizCode: localStorage.getItem("shopId"),
      pageCode: "home_page",
    };
    const res = await queryZoneCouponData(params);
    if (res?.data) {
      const { thirdCustomerFlag = 0, thirdCustomerCoupons = [] } = res.data;
      setThirdCustomerFlag(thirdCustomerFlag);
      setThirdCustomerCoupons(thirdCustomerCoupons);
    }
  };

  useEffect(() => {
    query();
  }, []);

  return {
    thirdCustomerFlag,
    thirdCustomerCoupons,
    updateCouponData: query,
  };
};

export default useZoneCoupon;
