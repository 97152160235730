import { queryHomeSkin } from "@/api/decorate";
import { useEffect } from "react";
import { useCommonContext } from "../context/common";

const useSkin = () => {
  const { setSkin } = useCommonContext();
  const getSkinIno = async () => {
    const res = await queryHomeSkin();
    setSkin(res);
  };

  useEffect(() => {
    getSkinIno();
  }, []);
};

export default useSkin;
