import { Popup } from "antd-mobile";
import moment from "moment";
import React, { useMemo, useState } from "react";
import useCalendarDate from "../hook/useCalendarDate";
import styles from "./SignCalendar.module.less";

const daysMap: string[] = ["日", "一", "二", "三", "四", "五", "六"];

const imgs = {
  forget:
    "https://akim-oss.aikucun.com/a17554a0d2b15a664c0e73900184544f19e70227_1723551637574_64.png",
  isSignIn:
    "https://akim-oss.aikucun.com/6c1e671f9af5b46d9c1a52067bdf0e53685674f7_1723551637030_96.png",
  isGetSignInAward:
    "https://akim-oss.aikucun.com/511a418e72591eb7e33f703f04c3fa16df6c90bd_1723551637298_95.png",
  isCanGetSignInAward:
    "https://akim-oss.aikucun.com/mshop/9502bdfe2a26cc9b72862ac4929e647c61fe39f0_1724329337026_76.png",
};

const waitImgs = [
  "https://akim-oss.aikucun.com/d02560dd9d7db4467627745bd6701e809ffca6e3_1723551883818_54.png",
  "https://akim-oss.aikucun.com/c097638f92de80ba8d6c696b26e6e601a5f61eb7_1723551884126_55.png",
  "https://akim-oss.aikucun.com/35e995c107a71caeb833bb3b79f9f54781b33fa1_1723551884305_79.png",
  "https://akim-oss.aikucun.com/1f1362ea41d1bc65be321c0a378a20159f9a26d0_1723551884522_61.png",
];

type CardData = {
  day: string;
  dayStatus: number;
  dayOfSignInScope: number;
  isCanGetSignInAward: boolean;
  isGetSignInAward: boolean;
  isSignIn: boolean;
  statusDesc: string;
};

type CardInfo = {
  date: number;
  data: CardData;
  day: number;
};

const DateCard: React.FC<{ dates: CardInfo[] }> = ({ dates }) => {
  return (
    <>
      {dates.map((date, index) => {
        const { isCanGetSignInAward, isGetSignInAward, isSignIn, dayStatus } = date?.data || {};
        let imgUrl = "";
        let text = "";
        let textColor = "#222222";
        if (isGetSignInAward) {
          imgUrl = imgs["isGetSignInAward"];
          text = "已领奖";
          textColor = "#FF4747";
        } else if (isSignIn) {
          imgUrl = imgs["isSignIn"];
          textColor = "#FF4747";
        } else if (isCanGetSignInAward) {
          imgUrl = imgs["isCanGetSignInAward"];
        } else if (!isSignIn && dayStatus === 1) {
          imgUrl = imgs["forget"];
          text = "漏签";
        } else {
          // imgUrl = waitImgs[Math.floor(Math.random() * 4)];
          imgUrl =
            "https://akim-oss.aikucun.com/mshop/497a5cf5249bcddc2c59434b387ac8220af52502_1724327312284_31.png";
        }
        if (dayStatus === 2) {
          text = "今天";
          textColor = "#FF4747";
        }
        if (!date.data?.day) {
          return (
            <div key={index} className={styles.blankDateItem}>
              {date.date}
            </div>
          );
        }
        return (
          <div key={index} className={styles.dateItem}>
            <img className={styles.dateItemBg} src={imgUrl} />
            <div className={styles.dateItemText} style={{ color: textColor }}>
              {text || date.date}
            </div>
          </div>
        );
      })}
    </>
  );
};
const Calendar: React.FC<{
  promoActivityId: string;
  limitStartTime: string;
  limitEndTime: string;
}> = ({ promoActivityId, limitStartTime, limitEndTime }) => {
  const [curDate, setCurDate] = useState(new Date());
  const [isShowLastMonth, setIsShowLastMonth] = useState(false);
  const [isShowNextMonth, setIsShowNextMonth] = useState(false);
  const { weeks } = useCalendarDate(curDate, promoActivityId);
  const { lastMonth, curMonth, nextMonth } = useMemo(() => {
    const date = new Date(curDate);
    const lastMonth = new Date(date.getFullYear(), date.getMonth() - 1);
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1);
    const firstTimeOfCurMonth = new Date(date.getFullYear(), date.getMonth());
    const firstTimeOfNextMonth = nextMonth;
    setIsShowLastMonth(+limitStartTime < +firstTimeOfCurMonth);
    setIsShowNextMonth(+limitEndTime >= +firstTimeOfNextMonth);
    return {
      lastMonth: lastMonth.getMonth() + 1,
      curMonth: date.getMonth() + 1,
      nextMonth: nextMonth.getMonth() + 1,
    };
  }, [curDate]);

  const toLastMonth = () => {
    isShowLastMonth && setCurDate(new Date(curDate.getFullYear(), curDate.getMonth() - 1));
  };

  const toNextMonth = () => {
    isShowNextMonth && setCurDate(new Date(curDate.getFullYear(), curDate.getMonth() + 1));
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.calendarHeader}>
        <div
          onClick={toLastMonth}
          className={styles.headerLeft + " " + (isShowLastMonth ? "" : styles.disable)}
        >
          <span>{lastMonth}月</span>
        </div>
        <div className={styles.headerCenter}>{curMonth}月</div>
        <div
          onClick={toNextMonth}
          className={styles.headerRight + " " + (isShowNextMonth ? "" : styles.disable)}
        >
          <span>{nextMonth}月</span>
        </div>
      </div>
      <div className={styles.tableHeader}>
        {daysMap.map((item) => (
          <div key={item} className={styles.dayItem}>
            {item}
          </div>
        ))}
      </div>
      <div className={styles.table}>
        {weeks.map((dates, index) => (
          <div key={index} className={styles.row}>
            <DateCard dates={dates} />
          </div>
        ))}
      </div>
    </div>
  );
};
const SignCalendar: React.FC<{
  visible: boolean;
  setVisible: any;
  promoActivityId: string;
  limitStartTime: string;
  limitEndTime: string;
  activityInfo: any;
}> = ({ visible, setVisible, promoActivityId, limitStartTime, limitEndTime, activityInfo }) => {
  let timeTitle = `活动时间：${moment(Number(activityInfo?.startTime)).format("M月D日")}~${moment(Number(activityInfo?.endTime)).format("M月D日")}`;
  let subTitle = `连续签到${activityInfo?.signInTaskInfo?.totalDays || 0}天，可参与抽奖`;
  if (["CANCEL", "END"].includes(activityInfo.status)) {
    subTitle = "本次活动已结束";
  }
  return (
    <Popup
      visible={visible}
      onMaskClick={() => setVisible(false)}
      maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
    >
      <div className={styles.wrap}>
        <div className={styles.header}>
          <span className={styles.time}>{timeTitle}</span>
          <span>{subTitle}</span>
        </div>
        <div
          className={styles.closeBtn}
          onClick={() => {
            setVisible(false);
          }}
        ></div>
        <Calendar
          promoActivityId={promoActivityId}
          limitStartTime={limitStartTime}
          limitEndTime={limitEndTime}
        />
      </div>
    </Popup>
  );
};
export default SignCalendar;
