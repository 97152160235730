import React, { useState } from "react";

import styles from "./Rotate.module.less";
interface Props {
  toggleRotationPress?: () => void;
}

const RotateComp: React.FC<Props> = ({ toggleRotationPress }) => {
  const [isRotating, setIsRotating] = useState(false);

  const toggleRotation = () => {
    setIsRotating(!isRotating);
    toggleRotationPress?.();
  };

  return (
    <div className={styles.refresh}>
      <div
        className={`${styles.container} ${isRotating ? `${styles.rotate360}` : ""}`}
        onClick={toggleRotation}
      >
        <img
          className={styles.refresh_image}
          src={
            "https://akim-oss.aikucun.com/mshop/67c71d814420543fae8523aae0a014111102ccc2_1725608656473_52.png"
          }
        />
      </div>
    </div>
  );
};

export default RotateComp;
