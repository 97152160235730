import { getBaseurl } from "@/utils/url-parse";
import { Request } from "../request";

// 获取好货推荐
export function queryCustomerTrace(data?: any) {
  return Request({
    url: `${getBaseurl()}/api/member/xapp/customerTrace/queryCustomerTrace`,
    method: "post",
    data: data,
  });
}
