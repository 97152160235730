import React, { useEffect, useState } from "react";
import FallCoupon from "./FallCoupon";
import NewPersonCouponV1 from "./NewPersonCouponV1";
import NewPersonCouponV2 from "./NewPersonCouponV2";
import useCouponWindow from "./useCouponWindow";

const CouponWindow: React.FC = () => {
  const { couponPopType, couponInfo } = useCouponWindow();

  useEffect(() => {
    // 弹框展示逻辑
  }, [couponPopType]);

  switch (couponPopType) {
    case "类目天降券":
      return <FallCoupon couponInfo={couponInfo} />;
    case "新版新人券弹框":
      return <NewPersonCouponV2 couponInfo={couponInfo} />;
    case "旧版新人券弹框":
      return <NewPersonCouponV1 couponInfo={couponInfo} />;
    default:
      return <></>;
  }
};

export default CouponWindow;
