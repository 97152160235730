import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  onItemClick?: (imgUrls?: string[], index?: number) => void;
};

/**
 * 价格解释
 */
const PriceExplainComp: React.FC<Props> = ({ onItemClick }) => {
  let imgList = [
    "https://xiangdian-oss.aikucun.com/app_x_ios/a6e99ba0343566875378a282354858e601bc17fb_1719304853522_11.png",
  ];

  return (
    <div className={styles.priceExp}>
      <div className={styles.title}>价格解释</div>
      {imgList?.map((item, index) => {
        return (
          <Image
            key={index}
            className={styles.img}
            src={item}
            fit="contain"
            onClick={() => {
              onItemClick?.(imgList, index);
            }}
          />
        );
      })}
    </div>
  );
};

export default PriceExplainComp;
