import { useCommonContext } from "@/utils/context/common";
import { getCookie } from "@/utils/platform";

type videoRefType = {
  duration: number | undefined;
  currentTime: number | undefined;
  ended: boolean | undefined;
  videoIndex: number;
};
type pageViewParams = {
  tab: number;
  fromPage: string | null;
};
type playParams = {
  item: any;
  videoRef: videoRefType;
  btn_name: string;
  currentPlayId: string;
  fromPage: string | null;
};
type videoExposeParams = {
  item: any;
  currentVideoIndex: number;
  fromPage: string | null;
};
type videoClickParams = {
  item: any;
  currentVideoIndex: number;
  btn_name: string;
  fromPage: string | null;
};
type productCardClickParams = {
  item: any;
  curTab: number;
  materialNo: string;
  btn_name: string;
  index: number;
  currentVideoIndex: number;
  fromPage: string | null;
};
type productCardExposeParams = {
  item: any;
  index: number;
  type: string;
  materialNo: string;
  currentVideoIndex: number;
  fromPage: string | null;
};
type pocketClickParams = {
  item: any;
  curTab: number;
  materialNo: string;
  btn_name: string;
  fromPage: string | null;
};
type pocketExposeParams = {
  item: any;
  index: number;
  resource_content: string;
  materialNo: string;
  fromPage: string | null;
};
//<--发现页-->
type categoryClickParams = {
  item: any;
  resource_type: number;
  fromPage: string | null;
};
type discoveryCardClickParams = {
  item: any;
  btn_name: string;
  index: number;
  tab_name: string;
  fromPage: string | null;
};
//<--搜索页-->
type searchClickParams = {
  btn_text: string;
  btn_name: string;
  previous_page_name: any;
};

type searchResultParams = {
  keyword: string;
  search_type: string;
  totalCount: number;
  searchRequestId: string;
  previous_page_name: any;
};
type searchCardClickParams = {
  index: number;
  btn_name: string;
  previous_page_name: any;
};

//未做：
//1.trackPlay  play————>完播标志获取不正确，drag_time，btn_name
//2. share 分享转发————>share_method，share_channel，share_type
export const useTrack = () => {
  const { track } = useCommonContext();
  //<--index文件-->
  //pageview埋点
  const trackPageView = (params: pageViewParams) => {
    try {
      console.log("params", params);
      const { tab, fromPage } = params;
      const page_name =
        fromPage === "productDetail"
          ? tab === 1
            ? "实拍专区_列表"
            : "实拍专区_视频"
          : tab === 1
            ? "种草社区_发现"
            : "种草社区_推荐";
      if (track.track) {
        track.track("$pageview", {
          page_name: page_name,
          previous_page_name: "",
          tab_name: "推荐",
          domain: fromPage === "productDetail" ? "实拍专区" : "种草",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //<--videoPlay文件-->

  //推荐页面视频埋点
  const trackPlay = (params: playParams) => {
    try {
      const { item, videoRef, btn_name, currentPlayId, fromPage } = params;
      console.log("trackPlay", item, videoRef, btn_name, currentPlayId);
      if (videoRef) {
        if (track.track) {
          track.track("$play", {
            page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
            current_play_id: currentPlayId,
            play_time: videoRef?.currentTime, //实际播放时长
            play_type: videoRef?.ended ? 1 : 0, //完播 0/1
            activity_id: item?.productListVOList[0]?.activityId,
            t: new Date() + "", //时间戳
            video_time: videoRef?.duration, //视频总长
            // drag_time: "", //拖拽时长
            btn_name: btn_name,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            merchant_shop_code: item?.productListVOList[0]?.merchantCode, //店铺商家id
            material_id: item?.materialNo,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //推荐页面视频曝光埋点
  const trackVideoExpose = (params: videoExposeParams) => {
    try {
      const { item, currentVideoIndex, fromPage } = params;
      console.log("item", item);
      console.log("item", currentVideoIndex);
      if (track.track) {
        track.track("resource_expose", {
          page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
          previous_page_name: "",
          resource_type: "视频",
          resource_rank: currentVideoIndex + 1,
          domain: fromPage === "productDetail" ? "实拍专区" : "种草",
          material_id: item?.materialNo,
          search_request_id: getCookie("mstoken") || "" + Date.now() + "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //推荐页面视频组件点击埋点
  const trackVideoClick = (params: videoClickParams) => {
    try {
      const { item, currentVideoIndex, btn_name, fromPage } = params;
      console.log("itemcilck", params);
      if (track.track) {
        track.track("resource_click", {
          page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
          previous_page_name: "",
          tab_name: "推荐",
          btn_name: btn_name,
          resource_type: "视频",
          resource_rank: currentVideoIndex + 1,
          domain: fromPage === "productDetail" ? "实拍专区" : "种草",
          material_id: item?.materialNo,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //推荐页面商品口袋商品列表曝光埋点（页面中未点商品口袋）
  const trackProductCardExpose = (params: productCardExposeParams) => {
    try {
      const { item, index, type, materialNo, currentVideoIndex, fromPage } = params;
      //活动index不准确？activityList和conferenceList是混排？
      console.log("trackProductCardExpose", params);
      if (item) {
        const resourceContentObj = {
          product: "商品",
          conference: "会场",
          activity: "档期",
        };
        let brandId = undefined;
        if (type === "activity") {
          // eslint-disable-next-line
          brandId = item.brandId;
        }
        if (track.track) {
          track.track("resource_expose", {
            page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
            previous_page_name: "",
            resource_content: resourceContentObj[type], //商品档期会场
            resource_type: "购物袋卡片",
            resource_rank: currentVideoIndex + 1,
            relative_rank: index + 1,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            activity_id: item.activityId,
            brand_id: item?.brandInfo?.brandId,
            activity_spu_code: item.spuId, //是这个吗？
            sku_id: item.selectSkuId,
            material_id: materialNo, //当前视频materialNo
            search_request_id: getCookie("mstoken") || "" + Date.now() + "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //推荐页面商品口袋商品列表点击埋点（页面中未点商品口袋）
  const trackProductCardClick = (params: productCardClickParams) => {
    try {
      const { item, curTab, materialNo, btn_name, currentVideoIndex, index, fromPage } = params;
      console.log("trackProductCardClick", params);
      if (item) {
        if (track.track) {
          track.track("resource_click", {
            page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
            previous_page_name: "",
            resource_type: "购物袋卡片",
            tab_name: curTab === 0 ? "商品" : "活动", //商品/活动
            resource_rank: currentVideoIndex + 1,
            relative_rank: index + 1,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            btn_name: btn_name,
            activity_id: item.activityId,
            brand_id: item?.brandInfo?.brandId,
            activity_spu_code: item.spuId,
            sku_id: item.selectSkuId,
            material_id: materialNo, //当前视频materialNo
            search_request_id: getCookie("mstoken") || "" + Date.now() + "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //推荐页面商品口袋商品列表曝光埋点 (弹窗详情)
  const trackPocketExpose = (params: pocketExposeParams) => {
    try {
      const { item, index, resource_content, materialNo, fromPage } = params;
      console.log("trackPocketExpose", params);
      if (item) {
        if (track.track) {
          track.track("resource_expose", {
            page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
            previous_page_name: "",
            module: resource_content, //商品活动
            resource_type: "购物袋卡片",
            resource_rank: index + 1,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            activity_id: item.activityId,
            brand_id: item.brandInfo?.brandId,
            activity_spu_code: item.spuId, //是这个吗？
            sku_id: item.selectSkuId,
            material_id: materialNo, //当前视频materialNo
            search_request_id: getCookie("mstoken") || "" + Date.now() + "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //推荐页面商品口袋商品列表点击埋点 (弹窗详情)
  const trackPocketClick = (params: pocketClickParams) => {
    try {
      const { item, curTab, materialNo, btn_name, fromPage } = params;
      console.log("trackPocketClick", params);
      if (item) {
        if (track.track) {
          track.track("resource_click", {
            page_name: fromPage === "productDetail" ? "实拍专区_视频" : "种草社区_推荐",
            previous_page_name: "",
            tab_name: curTab === 0 ? "商品" : "活动", //商品/活动
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            btn_name: btn_name,
            activity_id: item.activityId,
            brand_id: item.brandInfo?.brandId,
            activity_spu_code: item.spuId, //是这个吗？
            sku_id: item.selectSkuId,
            material_id: materialNo, //当前视频materialNo
            search_request_id: getCookie("mstoken") || "" + Date.now() + "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //<--发现页————category-->
  //发现页tab点击埋点
  const trackTabClick = ({ item, index, fromPage }) => {
    try {
      console.log("item", item, index, fromPage);
      if (item) {
        if (track.track) {
          track.track("tab_click", {
            page_name: fromPage === "productDetail" ? "实拍专区_列表" : "种草社区_发现",
            previous_page_name: "",
            tab_name: item?.name,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            rank: index + 1,
            module: "类目模块", //类目模块
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //发现页tab类目曝光埋点
  const trackTabExpose = ({ item, index, fromPage }) => {
    try {
      console.log("item", item, index, fromPage);
      if (item) {
        if (track.track) {
          track.track("tab_expose", {
            page_name: fromPage === "productDetail" ? "实拍专区_列表" : "种草社区_发现",
            module: "类目模块", //类目模块
            previous_page_name: "",
            tab_name: item?.name,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            rank: index + 1,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //发现列表页-类目点击
  const trackCategoryClick = (params: categoryClickParams) => {
    try {
      const { item, resource_type, fromPage } = params;
      console.log("trackCategoryClick", item, resource_type);
      if (item) {
        if (track.track) {
          track.track("resource_click", {
            page_name: fromPage === "productDetail" ? "实拍专区_列表" : "种草社区_发现",
            previous_page_name: "",
            resource_type: resource_type === 0 ? "我的类目" : "全部类目",
            btn_name: item?.name,
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //<--发现页————discovery-->
  //发现列表卡片点击
  const trackDiscoveryCardClick = (params: discoveryCardClickParams) => {
    try {
      const { item, btn_name, index, tab_name, fromPage } = params;
      console.log("trackDiscoveryCardClick", params);
      if (item) {
        if (track.track) {
          track.track("resource_click", {
            page_name: fromPage === "productDetail" ? "实拍专区_列表" : "种草社区_发现",
            previous_page_name: "",
            domain: fromPage === "productDetail" ? "实拍专区" : "种草",
            tab_name: tab_name,
            resource_type: "种草图文组件",
            resource_name: item?.name,
            resource_rank: index,
            material_id: item?.materialNo,
            search_request_id: getCookie("mstoken") || "" + Date.now() + "", //搜索请求ID
            resource_content: "资源位跳转内容类型：素材",
            btn_name: btn_name,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // //发现页列表页曝光埋点
  // const trackCardExpose = ({ item, index }) => {
  //   console.log('item', item, index);
  //   if (item) {
  //     if (track.track) {
  //       track.track('tab_expose', {
  //         page_name: '逛一逛_发现',
  //         module: '', //类目模块
  //         previous_page_name: '',
  //         tab_name: item?.name,
  //         domain: '种草',
  //         rank: index + 1,
  //       });
  //     }
  //   }
  // };

  //<--搜索页-->
  //搜索落地页点击埋点
  const trackSearchClick = (params: searchClickParams) => {
    try {
      const { btn_text, btn_name, previous_page_name } = params;
      console.log("trackSearchClick", btn_text, btn_name, previous_page_name);
      if (params) {
        if (track.track) {
          track.track("btn_click", {
            previous_page_name: previous_page_name,
            btn_text: btn_text ? btn_text : undefined,
            btn_name: btn_name,
            page_name: "种草页-搜索",
            domain: "种草",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //搜索结果埋点
  const trackSearchResult = (params: searchResultParams) => {
    try {
      const { keyword, search_type, totalCount, searchRequestId, previous_page_name } = params;
      console.log("trackSearchResult", params);
      if (params) {
        if (track.track) {
          track.track("view_search_resullt", {
            previous_page_name: previous_page_name,
            page_name: "种草页-搜索",
            keyword: keyword,
            search_type: search_type,
            search_item: "素材",
            nums: totalCount,
            request_type: "搜索",
            request_id: searchRequestId + "",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //搜索结果materialCard点击埋点
  const trackSearchCardClick = (params: searchCardClickParams) => {
    try {
      const { index, btn_name, previous_page_name } = params;
      console.log("trackSearchCardClick", params);
      if (params) {
        if (track.track) {
          track.track("resource_click", {
            previous_page_name: previous_page_name,
            module: "图文列表",
            page_name: "搜索结果",
            resource_rank: index,
            btn_name: btn_name,
            resource_content: "链接内容：种草图文",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //<--种草图文详情页-->
  //pageview埋点
  const trackDetailPageView = ({ fromPage }) => {
    if (track.track) {
      track.track("$pageview", {
        page_name: fromPage === "productDetail" ? "实拍专区_图文" : "种草详情",
        domain: fromPage === "productDetail" ? "实拍专区" : "种草",
      });
    }
  };
  const trackDetailResourceExpose = ({
    materialNo,
    rematerialNo,
    name,
    resource_rank,
    fromPage,
  }) => {
    try {
      const props = {
        page_name: fromPage === "productDetail" ? "实拍专区_图文" : "种草详情",
        domain: fromPage === "productDetail" ? "实拍专区" : "种草",
        resource_type: "种草图文组件",
        resoure_name: name,
        resource_rank,
        material_id: materialNo,
        click_material_id: rematerialNo,
        search_request_id: getCookie("mstoken") || "" + Date.now() + "",
      };
      // 区分主素材和推荐素材
      if (materialNo === rematerialNo) {
        delete props.click_material_id;
      }
      if (track.track) {
        track.track("$resource_expose", props);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const trackDetailResourceClick = ({
    activity_id,
    material_id,
    brand_id,
    sku_id,
    btn_name,
    relative_rank,
    resource_rank,
    click_material_id,
    activity_spu_code,
    fromPage,
  }) => {
    try {
      const props = {
        page_name: fromPage === "productDetail" ? "实拍专区_图文" : "种草详情",
        module: "购物袋卡片",
        resource_content: "商品",
        relative_rank,
        resource_rank,
        domain: fromPage === "productDetail" ? "实拍专区" : "种草",
        activity_id,
        brand_id,
        material_id,
        sku_id,
        btn_name,
        activity_spu_code,
        click_material_id,
      };
      if (material_id === click_material_id) {
        delete props.click_material_id;
        delete props.activity_spu_code;
      }
      if (track.track) {
        track.track("$resource_click", props);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    trackPageView,
    trackPlay,
    trackVideoExpose,
    trackVideoClick,
    trackProductCardClick,
    trackProductCardExpose,
    trackPocketExpose,
    trackPocketClick,
    trackTabClick,
    trackTabExpose,
    trackCategoryClick,
    trackDiscoveryCardClick,
    trackSearchClick,
    trackSearchResult,
    trackSearchCardClick,
    trackDetailPageView,
    trackDetailResourceExpose,
    trackDetailResourceClick,
  };
};
