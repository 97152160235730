import Track from "@/utils/track";
const track = Track();

export const pageView = () => {
  const trackParams = {
    page_name: "优惠凑单页",
    previous_page_name: "",
    domain: "优惠券凑单",
  };
  track.pageView("优惠凑单页", trackParams);
};

export const topResourceClick = (item: any) => {
  const trackParams = {
    page_name: "优惠凑单页",
    domain: "优惠券凑单",
    module: "省心凑",
    resource_type: "商卡",
    resource_name: item.title,
    activity_spu_code: item.activitySpuId,
    sku_id: item.selectSkuId,
  };
  track.track("resource_click", trackParams);
};

export const secondResourceClick = (item: any) => {
  let rank_name = "综合";
  let rank_type = "降序";
  const { all, sale, price, brand } = item.searchParams;
  if (all === "down") {
    rank_name = "综合";
    rank_type = "降序";
  }
  if (sale) {
    rank_name = "销量";
    rank_type = sale === "up" ? "升序" : "降序";
  }
  if (price) {
    rank_name = "价格";
    if (price.name) {
      rank_type = price.name;
    } else {
      rank_type = `[${price.min}-${price.max}]`;
    }
  }
  if (brand) {
    rank_name = "品牌";
    rank_type =
      item.brandList
        ?.filter((item: any) => brand.includes(item.brandId))
        .map((item: any) => item.brandName) || [];
  }
  const trackParams = {
    page_name: "优惠凑单页",
    domain: "优惠券凑单",
    module: "商品列表",
    resource_type: "商卡",
    resource_name: item.title,
    activity_spu_code: item.activitySpuId,
    sku_id: item.selectSkuId,
    rank_name: rank_name,
    rank_type: rank_type,
  };
  track.track("resource_click", trackParams);
};
