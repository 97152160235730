import TopNavigation from "@/components/top-navigation";
import { platform } from "@/utils/platform";
import React from "react";
import AuditStatusComp from "./components/audit-status";
import BenefitInfoComp from "./components/benefit-info";
import BrandInfoComp from "./components/brand-info";
import InvitationComp from "./components/invitation";
import NoteComp from "./components/note";
import YouXueInfoComp from "./components/yx-info";
import { useConfirm } from "./hooks/useConfirm";
import styles from "./index.module.less";

const YouxueTravelConfirm: React.FC = () => {
  const context = useConfirm();

  return (
    <>
      {(platform.isH5 || platform.isWxH5) && (
        <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
      )}
      <div className={styles.page}>
        <div className={styles.container}>
          <img
            className={styles.bg_img}
            src="https://xiangdian-oss.aikucun.com/app_akc_ios/2c80e1477b82bf26e8eb1e7b46d5e7f5c3128fbd_1717480456616_50.png"
          />
          {context.detail && (
            <div className={styles.list}>
              {/* 品牌信息*/}
              <BrandInfoComp
                logo={context.detail?.studyTourLogo}
                title={context.detail?.studyTourTitle}
              />
              {/* 邀请函*/}
              {context.scene === "CHANGE" && (
                <InvitationComp
                  text={context.detail.inviteContent ?? ""}
                  highlightText={context.detail.inviteHeightLight}
                />
              )}
              <div className={styles.info}>
                {/* 游学信息*/}
                <YouXueInfoComp detail={context.detail} />
                {/* 审核状态*/}
                <AuditStatusComp
                  progList={context.auditStatus.progList}
                  memberSignUpInfo={context.detail?.memberSignUpInfo}
                />
                {/* 游学权益*/}
                <BenefitInfoComp detail={context.detail} />
              </div>
              {/* 注意事项*/}
              <NoteComp />
            </div>
          )}
        </div>
        {context.auditStatus.cancelEnable && (
          <div className={styles.footer}>
            <div className={styles.footer__right} onClick={context.onCancelSignUp}>
              <div className={styles.right__title}>取消报名</div>
            </div>
          </div>
        )}
        {context.auditStatus.confirmEnable && (
          <div className={styles.footer}>
            <div className={styles.footer__left} onClick={context.onSubmitNotJoin}>
              <div className={styles.left__title}>暂不参加</div>
            </div>
            <div className={styles.footer__right} onClick={context.onSubmitJoin}>
              <div className={styles.right__title}>确认参加</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default YouxueTravelConfirm;
