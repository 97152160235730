import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
interface contentProps {
  value?: string;
  picList?: Array<string>;
  disabled?: boolean;
  sellOut?: boolean;
}
// 组件的props类型定义
interface SkuTagProps {
  type?: string;
  active?: boolean;
  content: contentProps;
  isColor?: boolean;
  product?: any;
  tap?: () => void;
}

const SkuTag: React.FC<SkuTagProps> = ({
  type = "text",
  active = false,
  content,
  isColor = false,
  product,
  tap = () => {
    console.log("tap");
  },
}) => {
  const handleClick = () => {
    if (content.disabled) {
      return;
    }
    tap();
  };
  return (
    <>
      <div
        className={`${styles["content-item"]} ${styles["sku-tag-wrapper"]} ${
          active ? styles["sku-tag-wrapper__active"] : styles["sku-tag-wrapper__default"]
        }`}
      >
        <div style={{ position: "relative", boxSizing: "border-box" }}>
          <div
            onClick={handleClick}
            className={`${styles["sku-tag-wrapper-in"]} ${isColor ? styles["min-width-55"] : ""} ${
              type === "text" || !content.picList
                ? styles["sku-tag-wrapper-in__text"]
                : styles["sku-tag-wrapper-in__img-text"]
            } ${
              active ? styles["sku-tag-wrapper-in__active"] : styles["sku-tag-wrapper-in__default"]
            } ${content.disabled ? styles["sku-tag-wrapper-in__disabled"] : ""} ${
              content.sellOut ? styles["sku-tag-wrapper-in__opacity"] : ""
            }`}
          >
            {type === "text" && <span className={styles["content-name"]}>{content.value}</span>}
            {type === "img-text" && (
              <div className={styles["image-name-combine"]}>
                {content?.picList?.length && (
                  <Image className={styles["content-img"]} src={(content?.picList as any)[0]} />
                )}
                <span className={styles["content-name"]}>{content.value}</span>
              </div>
            )}
          </div>
          {content.sellOut && active ? (
            product?.activityType !== 4 &&
            product?.activitySimpleVO &&
            product?.activitySimpleVO.status === 1 ? (
              <Image
                className={styles["sell-out4"]}
                src="https://file-oss.aikucun.com/xd-img/0a294b194f72f58ba560a8e2e2bce35d15ce1675_1686299276096_17.png"
              />
            ) : (
              <Image
                className={styles["sell-out"]}
                src="https://akim-oss.aikucun.com/0e67b80383d15b92cb27f99d3ee0bd53ea446464_1677575771013_70.png"
              />
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default SkuTag;
