import Clickable from "@/components/clickable";
import useStickyStateObserver from "@/utils/hooks/useStickyStateObserver";
import { Popup } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import useBrandList from "../hooks/useBrandList";
import styles from "./BrandTabs.module.less";

const intersectionIndexList: any[] = [];

const observeBrandGroup = (selector, callBack) => {
  const io = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const groupIndex = entry.target.getAttribute("data-index");
        if (entry.intersectionRatio > 0) {
          intersectionIndexList.push(groupIndex);
          const min = Math.min(...intersectionIndexList);
          callBack(min);
        } else {
          const index = intersectionIndexList.findIndex((item) => item === groupIndex);
          if (index > -1) {
            intersectionIndexList.splice(index, 1);
            const min = Math.min(...intersectionIndexList);
            callBack(min);
          }
        }
      });
    },
    { root: document.querySelector(".popMainWrap") },
  );
  const items = document.querySelectorAll(selector);
  items.forEach((el) => {
    io.observe(el);
  });
};

type Props = {
  selectedBrand: any;
  setSelectedBrand: any;
  setIsShowToTopBtn: (a: boolean) => void;
};

const BrandTabs: React.FC<Props> = ({ selectedBrand, setSelectedBrand, setIsShowToTopBtn }) => {
  const [isShowPop, setIsShowPop] = useState(false);
  const [hasSticky, setHasSticky] = useState(false);
  const [curLetterIndex, setCurLetterIndex] = useState(0);
  const { brandList, alphabetDataList } = useBrandList();
  const wrapDom = useRef(null);
  useStickyStateObserver(wrapDom.current, (isSticky) => {
    setHasSticky(isSticky);
    setIsShowToTopBtn(isSticky);
  });
  useEffect(() => {
    !document.querySelector(".tabGroup") &&
      setTimeout(() => {
        observeBrandGroup(".tabGroup", (index) => {
          setCurLetterIndex(index);
        });
      });
  }, [isShowPop]);

  const onTouchMove = (event) => {
    const { clientX, clientY } = event.touches[0];
    const target = document.elementFromPoint(clientX, clientY) as HTMLElement;
    if (target) {
      const { index } = target.dataset;
      if (index) {
        setCurLetterIndex(+index);
        document.querySelector(`#tabGroup_${index}`)?.scrollIntoView();
      }
    }
  };

  return (
    <>
      <div ref={wrapDom} className={styles.wrap + " " + (hasSticky && styles.noBorderRadius)}>
        <div className={styles.list}>
          <Clickable
            className={styles.item + " " + (!selectedBrand && styles.active)}
            trackData={{
              tab_name: "全部",
              tab_rank: 0,
              resource_type: "品牌tab",
              resource_rank: 2,
              relative_rank: 0,
              btn_name: "全部",
            }}
            trackContextKeys={["page_name", "previous_page_name", "domain"]}
            onClick={() => {
              setSelectedBrand(null);
            }}
          >
            全部
          </Clickable>
          {brandList.map((item, index) => (
            <Clickable
              className={
                styles.item + " " + (selectedBrand?.brandId === item.brandId && styles.active)
              }
              key={item.brandId}
              id={"tab_" + item.brandId}
              trackData={{
                tab_name: item.brandName,
                tab_rank: 0,
                resource_type: "品牌tab",
                resource_rank: 2,
                relative_rank: 0,
                btn_name: item.brandName,
              }}
              trackContextKeys={["page_name", "previous_page_name", "domain"]}
              onClick={() => {
                setSelectedBrand({ ...item, tab_rank: index });
              }}
            >
              <img src={item.brandLogo} className={styles.pic} />
            </Clickable>
          ))}
        </div>
        <div
          className={styles.more}
          onClick={() => {
            setIsShowPop(true);
          }}
        >
          更多
        </div>
      </div>
      <Popup
        visible={isShowPop}
        onMaskClick={() => {
          setIsShowPop(false);
        }}
        bodyStyle={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          background: "#F5F5F5",
          zIndex: 10000,
          height: "95vh",
        }}
      >
        <div className={styles.popWrap}>
          <div className={styles.popHeader}>
            更多品牌
            <div
              className={styles.backBtn}
              onClick={() => {
                setIsShowPop(false);
              }}
            ></div>
          </div>
          <div className={styles.popMain + " popMainWrap"}>
            {alphabetDataList.map((group, index) => (
              <dl
                key={group.startsWith}
                className={styles.popItem + " tabGroup"}
                id={"tabGroup_" + index}
                data-index={index}
              >
                <dt className={styles.popItemTitle}>{group.startsWith}</dt>
                {group.list.map((item) => (
                  <dd
                    className={
                      styles.popItemCont +
                      " " +
                      (selectedBrand?.brandId === item.brandId && styles.active)
                    }
                    key={item.brandId}
                    onClick={() => {
                      setSelectedBrand(item);
                      setIsShowPop(false);
                    }}
                  >
                    <img src={item.brandLogo} className={styles.popItemLogo} />
                    <div className={styles.popItemText}>{item.brandName}</div>
                  </dd>
                ))}
              </dl>
            ))}
          </div>
          <ul className={styles.alphabet} onTouchMove={onTouchMove}>
            {alphabetDataList.map((alpha, index) => (
              <li
                key={alpha.startsWith}
                className={index === curLetterIndex ? styles.hl : ""}
                data-index={index}
              >
                {alpha.startsWith}
              </li>
            ))}
          </ul>
        </div>
      </Popup>
    </>
  );
};

export default BrandTabs;
