import React, { useEffect } from "react";
import styles from "./AllCategory.module.less";
import { useOptions, useResets, useToggles } from "../hooks/useFilterOption";

type Props = {
  onClose: () => void;
};

const AllCategory: React.FC<Props> = ({ onClose }) => {
  const [expandedList, setExpandedList] = React.useState<boolean[]>([]);
  const { categoryList } = useOptions();
  const { toggleCategory } = useToggles();
  const { resetCategory } = useResets();

  useEffect(() => {
    setExpandedList(new Array(categoryList.length).fill(false));
  }, [categoryList]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>全部分类</div>
      <div className={styles.body}>
        {categoryList.map((firstLevel, index) => (
          <div key={index} className={styles.itemsWrapper}>
            <div className={styles.subtitle}>{firstLevel.name}</div>
            <div className={styles.items}>
              {(firstLevel.children?.length ?? 0) > 1 && (
                <div
                  className={`${styles.item} ${
                    firstLevel.children?.some((i) => !i.active) ? "" : styles.active
                  }`}
                  onClick={() => toggleCategory(firstLevel)}
                >
                  全部
                </div>
              )}
              {firstLevel.children
                ?.slice(0, expandedList[index] ? undefined : 7)
                ?.map((innerItem, innerIndex) => (
                  <div
                    key={`innerIndex-${innerIndex}`}
                    className={`${styles.item} ${innerItem.active ? styles.active : ""}`}
                    onClick={() => toggleCategory(innerItem)}
                  >
                    <span className={styles.text}>{innerItem.name}</span>
                  </div>
                ))}

              {firstLevel.children && firstLevel.children?.length > 8 && (
                <div
                  className={styles.item}
                  onClick={() => {
                    setExpandedList((expandedList) =>
                      expandedList.map((item, i) => (i === index ? !item : item)),
                    );
                  }}
                >
                  <span>{expandedList[index] ? "收起" : "更多"}</span>
                  <img
                    className={styles.icon}
                    src={
                      expandedList[index]
                        ? "https://akim-oss.aikucun.com/mshop/87b89b94f855864eb916fedb61856ef8b4a1e514_1673256228593_28.png"
                        : "https://akim-oss.aikucun.com/mshop/5329c64b7860ecd323c3743fb1517b14e9be088a_1673255755016_41.png"
                    }
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        <div className={styles.reset} onClick={resetCategory}>
          重置
        </div>
        <div className={styles.confirm} onClick={onClose}>
          确定
        </div>
      </div>
    </div>
  );
};

export default AllCategory;
