import Clickable from "@/components/clickable";
import { pxtorem } from "@/utils/pxtorem";
import { ActivityBrandModel } from "@akc/biz-api";
import React, { useEffect, useMemo, useRef } from "react";
import {
  useSelectedActivity,
  useSetFilterInfo,
  useSetSelectedActivity,
} from "../hooks/useActivityProduct";
import styles from "./ActivitySelector.module.less";

interface Props {
  brandData: ActivityBrandModel;
  borderRadis?: number;
  bgColor?: string;
  small?: boolean;
  initialSelectedActivityId?: string;
  onSelectionChange?: (id: any) => void;
}

const ActivitySelector: React.FC<Props> = ({
  brandData,
  borderRadis,
  bgColor,
  small,
  initialSelectedActivityId,
  onSelectionChange,
}) => {
  // console.error("brandData——", brandData);
  // console.error("selectedId——", initialSelectedActivityId);
  const selectedActivity = useSelectedActivity();
  const setSelectedActivity = useSetSelectedActivity();
  const setFilterInfo = useSetFilterInfo();

  useEffect(() => {
    const dom = document.getElementById(`activity-${initialSelectedActivityId ?? -1}`);
    dom?.scrollIntoView({ inline: "center", block: "end" });
  }, [initialSelectedActivityId]);

  return (
    <div
      className={styles.container}
      style={{
        borderTopLeftRadius: borderRadis !== undefined ? borderRadis + "px" : undefined,
        borderTopRightRadius: borderRadis !== undefined ? borderRadis + "px" : undefined,
        background: bgColor,
      }}
    >
      <div
        className={styles.activitySelectorList}
        style={{ height: small ? pxtorem(30) : pxtorem(60) }}
      >
        {brandData.activityList?.map((item) => {
          return (
            <Clickable
              onClick={() => {
                if (selectedActivity?.activityId === item.activityId) {
                  return;
                }
                const dom = document.getElementById(`activity-${item.activityId ?? -1}`);
                dom?.scrollIntoView({
                  behavior: "smooth",
                  inline: "center",
                  block: "nearest",
                });
                setSelectedActivity(item);
                onSelectionChange?.(item.activityId);
                setFilterInfo(undefined);
              }}
              key={item.activityId ?? "-1"}
              trackData={{
                tab_id: item.activityId
                  ? item.activityId
                  : brandData.activityList?.map((item) => item.activityId).join(","),
                activity_id: item.activityId
                  ? item.activityId
                  : brandData.activityList?.map((item) => item.activityId).join(","),
                resource_type: "TAB",
                tab_name: item.activityAggrTitle,
                tab_rank: (item.rank ?? 0) + 1,
                merchant_shop_code: item.merchantShopCode,
              }}
              id={`activity-${item.activityId ?? -1}`}
              className={`${styles.activitySelector} ${
                selectedActivity?.activityId === item.activityId
                  ? styles.selected
                  : styles.unselected
              }`}
              style={{ height: small ? pxtorem(30) : pxtorem(48) }}
            >
              <div className={styles.category}>
                <span>{item.activityAggrTitle}</span>
                {item.activityHotImg && selectedActivity?.activityId === item.activityId && (
                  <img src={item.activityHotImg} alt="火爆" className={styles.tagImg} />
                )}
                {item.noSelectActivityHotImg &&
                  selectedActivity?.activityId !== item.activityId && (
                    <img src={item.noSelectActivityHotImg} alt="火爆" className={styles.tagImg} />
                  )}
              </div>
              {!small && (
                <div className={styles.highlight}>
                  {item.activityAggrSubTitleList
                    ? item.activityAggrSubTitleList.map((tag, subIndex: number) => {
                        return (
                          <div className={styles.highlightPair} key={subIndex}>
                            <div className={styles.tag}>{tag}</div>
                            {item.activityAggrSubTitleList &&
                              subIndex < item.activityAggrSubTitleList?.length - 1 && (
                                <div className={styles.gapLine}>|</div>
                              )}
                          </div>
                        );
                      })
                    : item.activityAggrSubTitle}
                </div>
              )}
            </Clickable>
          );
        })}
      </div>
    </div>
  );
};

export default ActivitySelector;
