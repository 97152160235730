import React, { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RewardEmpty from "../Empty";
import styles from "./index.module.less";
import RedPackSummary from "./red-pack-summary";
import RedPackTop from "./red-pack-top";
import RedPackItem from "./redpacket-item";

interface IProps {
  type?: string;
  index?: number;
  activityList: any[];
  summary: any;
}

const RedPack: React.FC<IProps> = ({ type, index, activityList, summary }) => {
  const [search] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleReload = useCallback(() => {
    // 获取当前URL的所有查询参数
    const urlParams = new URLSearchParams(location.search);
    // 删除特定的查询参数，例如 "paramName"
    urlParams.delete("shopCode");
    // 创建一个新的URL，不包含特定的参数
    const newUrl = `${location.pathname}?${urlParams.toString()}`;
    navigate(newUrl, { replace: true });
    // 我们只关心组件挂载时的操作，所以不需要清理函数
  }, [history, location]);

  return (
    <>
      <RedPackTop />
      <div
        className={`${styles.redpackContent} ${search.get("shopCode") ? styles.redpackContentShop : ""}`}
      >
        {!search.get("shopCode") ? <RedPackSummary info={summary} /> : ""}
        {/* 档期活动列表 */}
        {activityList?.length > 0 ? (
          activityList.map((item, index) => (
            <RedPackItem key={item.activityId} data={item} index={index} />
          ))
        ) : (
          <RewardEmpty>抱歉，暂无活动</RewardEmpty>
        )}
      </div>
      {search.get("shopCode") && (
        <div className={styles.footerContainer}>
          <div className={styles.footerBtn} onClick={handleReload}>
            查看全部红包
          </div>
        </div>
      )}
    </>
  );
};
export default RedPack;
