import { shopPlusActivityList } from "@/api/shopPlus";
import moreProductPng from "@/assets/more_product.png";
import nullPng from "@/assets/null.png";
import playPng from "@/assets/play.png";
import exposure from "@/utils/expose/exposure";
import { platform } from "@/utils/platform";
import { Image, InfiniteScroll } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CheckTime, initTime } from "../../../../utils/countdown";
import { pxtorem } from "../../../../utils/pxtorem";
import styles from "./index.module.less";
import SaveMoney from "./SaveMoney";
// import { identity } from "@/utils/identity";
import loadLeft from "@/assets/load_left.png";
import loadRight from "@/assets/load_right.png";
import nullActivityImg from "@/assets/nullAcitivity.png";
import loadingPng from "@/base64";
import { activityAddCalendar } from "@/utils/actions";
import { useCommonContext } from "@/utils/context/common";
import { formatImgUrl } from "@/utils/crop";
import useNavigation from "@/utils/useNavigate";
interface Props {
  appStore?: any;
  propData?: any;
  pageData?: any;
  actList?: any;
  resourceRank?: any;
  last?: boolean;
  loadMore?: any;
  isNav?: boolean;
  navType?: any;
  hasNext?: boolean;
  navResourceRank?: any;
  [key: string]: any;
}

const ActivityCom: React.FC<Props> = (props: Props) => {
  const [search] = useSearchParams();
  // const { actList = [] } = props;
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [actArr, setActArr] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const { navigate2Product, navigate2Activity, navigate2LiveRoom } = useNavigation();
  const merchantShopCode = search.get("merchantShopCode");
  const [previous_page_name] = useState<string | null>(search.get("previous_page_name"));
  const { track } = useCommonContext();

  const queryTabActivity = async () => {
    if (loadingActivity) {
      return;
    }
    setLoadingActivity(true);
    if (hasMore) {
      const params = {
        pageId: "H5_Shop",
        searchParams: {
          isNeedSpuIds: true,
          // sortModel: 1,
          // sortType: 2,
          pageNum: pageIndex,
          shopCodes: [merchantShopCode],
          pageSize: 10,
        },
      };
      return shopPlusActivityList(params).then(
        (res: any) => {
          if (!res || !res.data) {
            return;
          }
          try {
            const { result, hasNext, pageIndex } = res.data;
            if (pageIndex > 1) {
              const valArr = result ? actArr.concat(result) : actArr;
              setActArr(valArr);
            } else {
              setActArr(result || []);
            }
            setHasMore(hasNext);
            setPageIndex(pageIndex + 1);
            setLoadingActivity(false);
            setIsEmpty(result?.length === 0 && pageIndex === 1);
          } catch (error) {
            console.error(error);
            setLoadingActivity(false);
          }
        },
        (err) => {
          setLoadingActivity(false);
          console.error(err);
        },
      );
    }
  };

  const TimeLabel = ({ item }) => {
    const { day, hour, minute, seconds } = initTime({
      type: "ms",
      startTime: item?.status === 1 ? item?.beginTimeStr : item?.endTimeStr,
      serverTime: new Date().valueOf(),
    });

    if (
      (day > 0 || hour > 0 || minute > 0 || seconds > 0) &&
      Number(item.activityType) !== 3 &&
      (day < 10 || item?.status === 1)
    ) {
      return (
        <div className={`${styles.countdown} ${item?.status === 1 ? styles.forecast : ""}`}>
          <div className={styles.pad_4}>距{item?.status === 1 ? "开始" : "结束"}</div>
          {day > 0 ? (
            <>
              <div className={styles.color_block}>{CheckTime(day)}</div>
              <div className={styles.pad_4}>天</div>
            </>
          ) : (
            <>
              <div className={styles.color_block}>{CheckTime(hour)}</div>
              <div className={styles.pad_4}>时</div>
              <div className={styles.color_block}>
                {minute > 0 ? CheckTime(minute) : seconds > 0 ? "01" : 0}
              </div>
              <div className={styles.pad_4}>分</div>
            </>
          )}
        </div>
      );
    }

    if (Number(item.activityType) === 3 && Number(item.status) === 2) {
      return <div className={styles.process}>档期进行中</div>;
    }

    return <></>;
  };

  const getDecimal = (marketingPrice) => {
    const decimal = marketingPrice.toString().split(".")[1];
    if (decimal) {
      return <div className={styles.decimal}>.{decimal}</div>;
    }
    return "";
  };

  //跳转商品
  const goProduct = (e, product, item, index) => {
    e.stopPropagation();
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺特卖",
        previous_page_name,
        domain: "B2R旗舰店",
        btn_name: "商品点击",
        resource_type: "活动列表",
        resource_content: "活动",
        resource_name: item.brandName,
        resource_rank: index + 1,
        activity_id: item.activityId,
        activity_spu_code: product.activitySpuId,
        sku_id: product.selectSkuId,
        merchant_shop_code: merchantShopCode,
      });
    }
    navigate2Product(
      {
        activitySpuId: product.activitySpuId,
        activityId: product.activityId,
        selectSkuId: product.selectSkuId,
        // marketName: props.pageData.title,
      },
      {
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "档期",
        targetId: product.activitySpuId,
        resourceRank: props.navResourceRank !== null ? props.navResourceRank : props.resourceRank,
        relativeRank: index,
        hasSpecially: false,
      },
    );
  };

  //跳转会场
  const goConference = (e, item, index?: any) => {
    e.stopPropagation();
    if (track.track) {
      track.track("resource_click", {
        page_name: "店铺特卖",
        previous_page_name,
        domain: "B2R旗舰店",
        btn_name: "进入会场",
        resource_type: "活动列表",
        resource_content: "活动",
        resource_name: item.brandName,
        resource_rank: index + 1,
        activity_id: item.activityId,
        merchant_shop_code: merchantShopCode,
      });
    }
    navigate2Activity(
      {
        activityNo: item.activityId,
        // marketName: props.pageData.pageCode,
      },
      {
        type: props.navType ? (props.navType === "normal" ? "普通导航" : "电梯导航") : "档期",
        targetId: item.activityId,
        resourceRank: props.navResourceRank !== null ? props.navResourceRank : props.resourceRank,
        relativeRank: index,
        hasSpecially: false,
      },
    );
  };

  //跳转直播间
  const goLive = (e, item) => {
    e.stopPropagation();
    navigate2LiveRoom({
      liveId: item.liveVO.liveNo,
      liveStatus: item.liveVO.liveStatus,
    });
  };
  //标签
  const formatLabel = (list, type) => {
    if (list === null || list?.length <= 0) {
      return [];
    }
    const arr: any = [];
    for (const label of list) {
      if (label.locationCode === type && label?.labelInfoVOList?.length > 0) {
        for (const labelList of label.labelInfoVOList) {
          // if (type !== "H5_ScheduleList_BrandDown" && arr.length > 0) {
          //   break;
          // }
          arr.push({
            type: labelList.contentType,
            value: labelList.labelIconUrl ? labelList.labelIconUrl : labelList.labelText,
            height: 15,
            width: labelList.labelIconWidth / (labelList.labelIconHeight / 15),
          });
        }
      }
    }
    return arr;
  };

  const BrandDownLabel = (props) => {
    const { item, className } = props;
    return (
      <div className={`${styles.discount_label} ${className}`}>
        {formatLabel(item?.labelLocationVOList, "H5_Shop_ScheduleList_BrandDown").map(
          (label, labelIndex) => {
            if (label.type === "image") {
              return (
                <Image
                  key={labelIndex}
                  src={label.value}
                  style={{
                    height: pxtorem(15),
                    width: pxtorem(label.width),
                    paddingRight: pxtorem(3),
                    paddingTop: pxtorem(7),
                  }}
                ></Image>
              );
            }
            if (label.type === "text") {
              return (
                <div
                  key={labelIndex}
                  style={{
                    paddingRight: pxtorem(3),
                  }}
                >
                  {label.value}
                </div>
              );
            }
          },
        )}
      </div>
    );
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean; }) => {
    if (hasMore) {
      return pageIndex !== 1 ? (
        <div className={styles.loadingMore}>
          <img src={loadingPng} />
        </div>
      ) : null;
    } else {
      return (
        <div className={styles.loadingEnd}>
          <>
            <img src={loadLeft} />
            <div className={styles.end_word}>已经到底了</div>
            <img src={loadRight} />
          </>
        </div>
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      let flag = false;
      for (let i = 0; i < actArr?.length; i++) {
        const dom = document.getElementById(`${actArr[i].activityId}`);
        if (dom) {
          exposure.add({
            el: dom,
          });
        } else {
          flag = true;
        }
      }
      if (!flag) {
        clearInterval(interval);
      }
    }, 1000);
  }, [actArr]);

  return (
    <>
      {actArr?.length > 0 &&
        actArr?.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.activity}
              id={item.activityId}
              data-param={JSON.stringify({
                page_name: "店铺特卖",
                previous_page_name,
                domain: "B2R旗舰店",
                resource_type: "活动列表",
                resource_content: "活动",
                resource_name: item.brandName,
                resource_rank: index + 1,
                activity_id: item.activityId,
                merchant_shop_code: merchantShopCode,
              })}
              data-eventid={item.activityId}
              onClick={(e) => goConference(e, item, index)}
            >
              <div className={styles.top}>
                <div className={styles.brand}>
                  <img
                    className={styles.img}
                    src={
                      item?.brandLogoUrl
                        ? formatImgUrl(item?.brandLogoUrl, 40, 40)
                        : nullPng
                    }
                  />
                  <div className={styles.title}>
                    <div
                      className={styles.top_name}
                      style={{
                        maxWidth: props.isNav ? pxtorem(130) : pxtorem(180),
                      }}
                    >
                      {item?.brandName}
                    </div>
                    <div
                      className={styles.bottom_name}
                      style={{
                        maxWidth: item.day <= 0 && item.hour > 0 ? pxtorem(105) : pxtorem(120),
                      }}
                    >
                      {item?.brandStoryVO?.brandIntr}
                    </div>
                  </div>
                </div>
                <div className={styles.time}>
                  <div style={{ display: "flex" }}>
                    {platform.isApp && item?.status === 1 && (
                      <div className={styles.notice} onClick={() => activityAddCalendar(item)}>
                        提醒
                      </div>
                    )}
                    <div
                      className={item?.status === 1 ? styles.btn_pre : styles.btn}
                      onClick={(e) => goConference(e, item, index)}
                    >
                      <div>进入会场</div>
                    </div>
                  </div>
                  <TimeLabel item={item} />
                </div>
              </div>
              <BrandDownLabel item={item} />
              <div className={styles.product} onClick={(e) => goConference(e, item, index)}>
                {item?.liveVO &&
                  (item?.liveVO?.liveStatus === 10 || item?.liveVO?.liveStatus === 20) && (
                    <div className={styles.item_block}>
                      <div
                        className={styles.item}
                        style={{
                          background: `url(${formatImgUrl(
                            item?.liveVO?.coverUrl,
                            100,
                            100,
                          )}) no-repeat `,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                          overflow: "hidden",
                        }}
                        onClick={(e) => goLive(e, item)}
                      >
                        <div className={styles.live}>
                          <span className={styles.barTotal}>
                            <span className={styles.bar1 + " " + styles.a1}></span>
                            <span className={styles.bar2 + " " + styles.a2}></span>
                            <span className={styles.bar3 + " " + styles.a3}></span>
                          </span>
                          <div className={styles.text}>{item.liveVO.liveStatusDesc}</div>
                        </div>
                        <div className={styles.liveDesc}>
                          <div className={styles.desc}>{item.liveVO.liveTimeDesc}</div>
                        </div>
                      </div>
                      {!platform.isApp && (
                        <div className={styles.price}>
                          <div className={styles.text}>{item.liveVO.liveTitle}</div>
                        </div>
                      )}
                    </div>
                  )}
                {item?.brandStoryVO &&
                  item?.brandStoryVO?.brandVideoCoverUrl2 &&
                  item?.brandStoryVO?.brandVideo && (
                    <div className={styles.item_block}>
                      <div
                        className={styles.item}
                        style={{
                          background: `url(${item.brandStoryVO.brandVideoCoverUrl2
                            ? formatImgUrl(
                              item.brandStoryVO.brandVideoCoverUrl2,
                              100,
                              100,
                            )
                            : nullPng
                            }) no-repeat `,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                        }}
                        onClick={(e) => goConference(e, item, index)}
                      >
                        <Image className={styles.img_play} src={playPng}></Image>
                      </div>
                      {!platform.isApp && (
                        <div className={styles.story}>
                          <div className={styles.story_text}>
                            <div className={styles.story_text_div}>品牌故事</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                {(platform.isMini
                  ? item?.productListVOList?.slice(0, 3) || []
                  : item?.productListVOList || []
                ).map((product, proIndex) => {
                  const { spuSellOut, skuQuantity } = product;

                  const skuQuantityShow = !spuSellOut && skuQuantity && skuQuantity > 0;

                  return (
                    <div className={styles.item_block} key={proIndex}>
                      <div
                        className={`${styles.item} ${styles.item_price}`}
                        style={{
                          background: `url(${product?.skuMainImageUrls?.[0]
                            ? formatImgUrl(
                              product?.skuMainImageUrls?.[0],
                              100,
                              100,
                            )
                            : nullPng
                            }) no-repeat`,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                        }}
                        onClick={(e) => goProduct(e, product, item, index)}
                      >
                        {product?.skuCurrentPriceInfo?.commission > 0 && !skuQuantityShow && (
                          <SaveMoney
                            platform={platform}
                            skuCurrentPriceInfo={product?.skuCurrentPriceInfo}
                          />
                        )}
                        {spuSellOut && (
                          <div className={styles.sell_out}>
                            <div className={styles.sell_out_circle}>已售罄</div>
                          </div>
                        )}
                        {skuQuantityShow && (
                          <div className={styles.quantity}>仅剩{product.skuQuantity}件</div>
                        )}
                        {formatLabel(
                          product?.labelLocationVOList,
                          "H5_Shop_DecorateSales_ColumnProductPicRight",
                        ).map((label, labelIndex) => {
                          if (label.type === "image") {
                            return (
                              <Image
                                key={labelIndex}
                                src={label.value}
                                style={{
                                  height: pxtorem(40),
                                  width: pxtorem(40),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                                fit="contain"
                              ></Image>
                            );
                          }
                          if (label.type === "text") {
                            return (
                              <div
                                key={labelIndex}
                                style={{
                                  height: pxtorem(15),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                {label.value}
                              </div>
                            );
                          }
                        })}
                        {formatLabel(
                          product?.labelLocationVOList,
                          "H5_SuperMarketing_ActivityStyleNo1_ProductPicLeft",
                        ).map((label, labelIndex) => {
                          if (label.type === "image") {
                            return (
                              <Image
                                key={labelIndex}
                                src={label.value}
                                style={{
                                  height: pxtorem(15),
                                  width: pxtorem(label.width),
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                }}
                              ></Image>
                            );
                          }
                          if (label.type === "text") {
                            return (
                              <div
                                key={labelIndex}
                                style={{
                                  height: pxtorem(15),
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                {label.value}
                              </div>
                            );
                          }
                        })}
                      </div>
                      {!platform.isApp && (
                        <div className={styles.price}>
                          {(product.skuCurrentPriceInfo.promotionType === "SECKILL" ||
                            product.skuCurrentPriceInfo.promotionType === "FIXED_PRICE") && (
                              <div className={styles.price_text}>
                                {product.skuCurrentPriceInfo.promotionTypeName}
                              </div>
                            )}
                          <div className={styles.price_real} style={{ flexGrow: 0 }}>
                            <div>¥</div>
                            {String(product.skuCurrentPriceInfo?.marketingPrice).length < 5 ? (
                              <>
                                <div className={styles.price_big}>
                                  {product.skuCurrentPriceInfo?.marketingPrice}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.price_big}>
                                  {Math.trunc(product.skuCurrentPriceInfo?.marketingPrice)}
                                </div>
                                {getDecimal(product.skuCurrentPriceInfo?.marketingPrice)}
                              </>
                            )}
                          </div>
                          {product.skuCurrentPriceInfo.promotionType !== "SECKILL" &&
                            product.skuCurrentPriceInfo.promotionType !== "FIXED_PRICE" && (
                              <div className={styles.price_old}>
                                ¥
                                <span style={{ paddingLeft: pxtorem(2) }}>
                                  {product.skuCurrentPriceInfo?.tagPrice}
                                </span>
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
                {(item?.hasMoreProduct ||
                  (platform.isMini && item?.productListVOList?.length > 3)) && (
                    <div
                      className={styles.item_block}
                      key={index}
                      style={{ paddingRight: pxtorem(6), marginRight: 0 }}
                    >
                      <div
                        className={`${styles.item} ${styles.item_price}`}
                        style={{
                          background: `url(${moreProductPng}) no-repeat`,
                          backgroundSize: "100% 100%",
                          backgroundColor: " #ddd",
                        }}
                        onClick={(e) => goConference(e, item, index)}
                      ></div>
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      {loadingActivity && pageIndex === 1 && (
        <>
          {Array.from({ length: 4 }).map((_, index) => {
            return (
              <img
                key={index}
                style={{
                  width: pxtorem(351),
                  height: pxtorem(180),
                  margin: `${pxtorem(1)} ${pxtorem(12)}`,
                }}
                src="https://akim-oss.aikucun.com/65a2747ad0c9358eaf813808668d3a493be6db9f_1699014311791_50.png"
              />
            );
          })}
        </>
      )}
      {
        <InfiniteScroll loadMore={queryTabActivity} hasMore={true} threshold={500}>
          <InfiniteScrollContent hasMore={hasMore} />
        </InfiniteScroll>
      }
      {isEmpty && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image src={nullActivityImg} style={{ width: pxtorem(240) }}></Image>
        </div>
      )}
    </>
  );
};

export default inject("appStore")(observer(ActivityCom));
