import { Swiper } from "antd-mobile";
import React from "react";

const replaceSwiperComp: React.FC<Omit<any, "itemCount" | "itemRenderer">> = (props) => {
  return (
    <Swiper
      indicator={props.indicatorDots}
      autoplay={props.autoplay}
      loop={props.circular}
      direction={props.vertical ? "vertical" : "horizontal"}
      onIndexChange={(idx) => {
        props.onChange?.({ detail: { current: idx } });
      }}
    >
      {props.children.map((item: any) => (
        <Swiper.Item key={item}>{item}</Swiper.Item>
      ))}
    </Swiper>
  );
};

export default replaceSwiperComp;
