import { useEffect, useRef, useState } from "react";
import useAddress from "./useAddress";
import { AddressSignUpInfo, MemberSignUpInfo } from "@akc/biz-api";
import { Toast } from "antd-mobile";

const TAG = "useBasicInfo";

const useBasicInfo = (memberInfo?: MemberSignUpInfo) => {
  const inputNameRef = useRef<any>(null); // 姓名
  const inputIdolNumRef = useRef<any>(null); // 爱豆编号
  const inputPhoneRef = useRef<any>(null); // 联系方式
  const inputCetificateRef = useRef<any>(null); // 证书

  const [sexValue, setSexValue] = useState<number | undefined>(); // 性别选择
  const [addressInfo, setAddressInfo] = useState<AddressSignUpInfo | undefined>(); // 城市信息
  const [personalDesc, setPersonalDesc] = useState<string | undefined>(""); // 个人能力描述
  const [statementContent, setStatementContent] = useState<string | undefined>(""); // 声明内容
  const curSignupInfo = useRef<MemberSignUpInfo | undefined>(); // 当前签到的用户信息

  const { addressList, addressSelectVisible, setAddressSelectVisible, onSelectCity } = useAddress();

  useEffect(() => {
    curSignupInfo.current = memberInfo;
  }, []);

  useEffect(() => {
    setSexValue(memberInfo?.sex);
    setAddressInfo(memberInfo?.signUpAddressVO);
    setPersonalDesc(memberInfo?.will ?? "");
  }, [memberInfo]);

  const onInputPersonalDesc = (msg: string) => {
    console.log(TAG, "onInputPersonalDesc----", msg);
    setPersonalDesc(msg);
  };

  const onOnekeyCopy = () => {
    setStatementContent("本人确认入选后即可参加游学，且确认不携带小孩参加");
  };

  // 校验表单内容
  const checkContentIsValid = () => {
    const name = inputNameRef.current.getInputContent();
    if (!name || name.length === 0) {
      console.log("姓名不能为空");
      return false;
    }
    const idolNum = inputIdolNumRef.current.getInputContent();
    if (!idolNum || idolNum.length === 0) {
      console.log("爱豆编号不能为空");
      return false;
    }
    const phoneNumber = inputPhoneRef.current.getInputContent();
    if (!phoneNumber) {
      console.log("请输入有效的联系方式");
      return false;
    }
    if (!sexValue) {
      console.log("性别不能为空");
      return false;
    }
    const cetificate = inputCetificateRef.current.getInputContent();
    if (!cetificate || cetificate.length === 0) {
      console.log("证书不能为空");
      return false;
    }
    if (!addressInfo || addressInfo.province?.length === 0 || addressInfo.city?.length === 0) {
      console.log("城市不能为空");
      return false;
    }
    if (!personalDesc || personalDesc.length === 0) {
      console.log("个人能力描述不能为空");
      return false;
    }
    if (!statementContent || statementContent.length === 0) {
      console.log("请输入抄写内容");
      Toast.show("请输入抄写内容");
      return false;
    }
    const info = {
      name: name,
      mobileNumber: phoneNumber,
      userCode: idolNum,
      sex: sexValue,
      certificate: cetificate,
      signUpAddressVO: { ...addressInfo },
      will: personalDesc,
    };
    curSignupInfo.current = info;
    return true;
  };

  const showDisplayAddress = (selectAddressInfo?: AddressSignUpInfo) => {
    if (selectAddressInfo?.province === selectAddressInfo?.city) {
      return selectAddressInfo?.province;
    }
    return selectAddressInfo?.province + " " + selectAddressInfo?.city;
  };

  return {
    TAG,
    inputNameRef,
    inputIdolNumRef,
    inputPhoneRef,
    inputCetificateRef,
    sexValue,
    setSexValue,
    addressInfo,
    setAddressInfo,
    personalDesc,
    setPersonalDesc,
    statementContent,
    setStatementContent,
    onSelectCity,
    onInputPersonalDesc,
    onOnekeyCopy,
    curSignupInfo,
    addressSelectVisible,
    setAddressSelectVisible,
    addressList,
    checkContentIsValid,
    showDisplayAddress,
  };
};

export default useBasicInfo;
