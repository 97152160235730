import { acceptInvite } from "@/api/videoShare";
import { platform } from "@/utils/platform";
import AKJS from "@akc/akjs";
import { Toast } from "antd-mobile";
import constate from "constate";
import { useState } from "react";

type activityDataParams = {
  anchorId: string;
  channelId: string;
  desc: string;
  qrCodeUrl: string;
  type: 1 | 2;
  startTime: string;
};
type anchorDataParams = {
  anchorName: string;
  bindDate: string;
  anchorId: string;
  anchorDesc: string;
  anchorAvatar;
  activityDataList: activityDataParams[];
};

const useVideoShare = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [anchorDataList, setAnchorDataList] = useState<anchorDataParams[]>([]);
  const [qrImage, setQrImage] = useState("");
  const [pollingActive, setPollingActive] = useState(false);
  const [accountId, setAccountId] = useState<any>("");
  const [anchorName, setAnchorName] = useState<string>("");

  //点击下载图片
  const handleDownload = (imgUrl) => {
    if (platform.isApp) {
      try {
        AKJS.ready().then(() => {
          AKJS.action
            .dispatch("event.media.saveFile", {
              type: 0,
              url: imgUrl,
            })
            .then(() => {
              Toast.show("图片保存成功");
            })
            .catch((error) => {
              console.error("图片保存失败:", error);
            });
        });
        // setTimeout(() => {
        //   Toast.show("图片保存成功");
        // }, 1000);
      } catch (error) {
        Toast.show("图片保存失败");
      }
    } else {
      return;
    }
  };
  // 已经接受邀请
  const queryAcceptInvite = async () => {
    const res: any = await acceptInvite({ accountId: accountId });
    if (res?.data) {
      setPollingActive(true);
    } else if (!res?.success) {
      Toast.show(res?.message || "请求异常");
    }
  };
  //数据处理
  const dataProcess = (data: number) => {
    const dataString = data.toString();

    if (dataString.includes(".")) {
      const parts = dataString.split(".");

      const formattedInteger = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return {
        integer: formattedInteger,
        decimal: parts[1],
      };
    } else {
      const formattedInteger = dataString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return {
        integer: formattedInteger,
        decimal: "",
      };
    }
  };
  return {
    isOpenModal,
    setIsOpenModal,
    anchorDataList,
    setAnchorDataList,
    qrImage,
    setQrImage,
    handleDownload,
    pollingActive,
    setPollingActive,
    accountId,
    setAccountId,
    queryAcceptInvite,
    anchorName,
    setAnchorName,
    dataProcess,
  };
};

const [UseVideoShareProvider, UseVideoShareContext] = constate(useVideoShare);
export { UseVideoShareContext, UseVideoShareProvider };
