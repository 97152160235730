import { countDown } from "@/utils/date-format";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./activity-status.module.less";


type Props = {
  type: "gold-medal" | "achievement" | "brand-bole";
  sysTime: string | number; // 系统时间
  taskEndTime: string; // 结束时间
  taskStatus: "BEGIN" | "AWARD_CALCULATION" | "AWARD_PROCESSING" | "END";
  backgroundColor?: string;
  borderColor?: string;
};
const ActivityStatus: React.FC<Props> = ({ type, sysTime = new Date().getTime(), taskEndTime, taskStatus, backgroundColor = "background: radial-gradient(12% 88% at 92% 50%, #FE0189 0%, #FE013C 100%)", borderColor = "linear-gradient(180deg, #FCB37E 0%, #FEB951 85%)" }) => {
  const [countDownObj, setCountDownObj] = useState<any>({});
  const [timeId, setTimeId] = useState<any>("");
  const countDownDom = () => {
    return (
      <div className={styles.count_down}>
        {countDownObj.day > 0 && <span className={styles.day}>{countDownObj.day}天</span>}
        <span className={styles.block}>{String(countDownObj.hou || 0).charAt(0) || 0}</span>
        <span className={styles.block}>{String(countDownObj.hou || 0).charAt(1) || 0}</span>:&nbsp;
        <span className={styles.block}>{String(countDownObj.min || 0).charAt(0) || 0}</span>
        <span className={styles.block}>{String(countDownObj.min || 0).charAt(1) || 0}</span>:&nbsp;
        <span className={styles.block}>{String(countDownObj.sec || 0).charAt(0) || 0}</span>
        <span className={styles.block}>{String(countDownObj.sec || 0).charAt(1) || 0}</span>
        后活动结束
      </div >
    );
  };
  const initStatusDom = () => {
    switch (taskStatus) {
      case "BEGIN": // 进行中
        return countDownDom();
      case "AWARD_CALCULATION": // 奖励计算中
        return <span>活动奖励计算中</span>;
      case "AWARD_PROCESSING": // 发奖中
        return <span>活动奖励发放中</span>;
      case "END": // 结束
        return <span>奖励发放结束</span>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (taskStatus === "BEGIN" && taskEndTime) {
      setTimeId(
        setInterval(() => {
          let obj = countDown(taskEndTime);
          setCountDownObj(obj);
        }, 1000),
      );
    }
  }, [taskStatus]);

  useEffect(() => {
    if (timeId) {
      clearInterval(timeId);
    }
  }, []);

  return (
    <div className={`${styles.component} ${styles.activity_status}`} style={{ backgroundColor: borderColor }}>
      <div className={styles.component__body} style={{ backgroundColor: backgroundColor }}>{initStatusDom()}</div>
    </div>
  );
};

export default ActivityStatus;