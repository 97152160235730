import React from "react";

import BgHeader from "@/assets/bg_invite_header.png";
import { ReactComponent as Empty } from "@/assets/youxue_result_empty.svg";
import loadingPng from "@/base64";
import TopNavigation from "@/components/top-navigation";
import { getCurrentFormatTime, getCurrentformatTime } from "@/utils/date-format";
import { onClosePage } from "@/utils/page_utils";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { Dialog, Image as ImageM } from "antd-mobile";
import YxSignUpBasicInfoComp from "../sign-up/components/basic-info";
import BenefitInfoComp from "../travel-confirm/components/benefit-info";
import YouXueInfoComp from "../travel-confirm/components/yx-info";
import useInvite from "./hooks/useInvite";
import styles from "./index.module.less";

const YouxueInvitePage: React.FC = () => {
  const signUpSuccessDialog = () => {
    const modal = Dialog.show({
      title: (
        <>
          <div style={{ textAlign: "center", fontSize: 16, color: "#222222" }}>请等待审核结果</div>
        </>
      ),
      content: (
        <>
          <div style={{ textAlign: "center", fontSize: 14, color: "#666666" }}>
            已收到您的游学报名申请，审核结果会以短信/APP站内消息提醒的方式推送，请留意您的消息通知并及时进行行程确认
          </div>
        </>
      ),
      closeOnMaskClick: false,
      actions: [
        {
          key: "confirm",
          text: "我知道了",
          bold: true,
          style: { color: "#FF4747" },
          onClick: () => {
            modal.close();
            onClosePage();
          },
        },
      ],
    });
  };
  // 行程确认
  const travelConfirmSucceesDialog = () => {
    const modal = Dialog.show({
      content: (
        <>
          <div style={{ textAlign: "center", fontSize: 16, color: "#222222", fontWeight: 500 }}>
            行程已经确认
          </div>
          <div style={{ textAlign: "center", fontSize: 16, color: "#222222", fontWeight: 500 }}>
            请准时参加游学活动
          </div>
        </>
      ),
      closeOnMaskClick: false,
      actions: [
        {
          key: "confirm",
          text: "确定",
          bold: true,
          style: { color: "#FF4747" },
          onClick: () => {
            modal.close();
            onClosePage();
          },
        },
      ],
    });
  };

  const {
    isLoading,
    showCheckStatus,
    basicInfoRef,
    signUpDetail,
    notJoin,
    comfirmJoin,
    goSee,
    errorPage,
  } = useInvite(travelConfirmSucceesDialog, signUpSuccessDialog);

  return (
    <>
      {(platform.isH5 || platform.isWxH5) && (
        <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
      )}
      <div className={styles["page"]}>
        {isLoading ? (
          <div className={styles["loading"]}>
            <ImageM src={loadingPng} width={pxtorem(30)} />
          </div>
        ) : (
          <>
            {!errorPage && signUpDetail?.fullFlag === false && signUpDetail.signUpType !== 1 && (
              <div className={styles["container"]}>
                <img
                  className={styles["headerBg"]}
                  src="https://xiangdian-oss.aikucun.com/app_akc_ios/2c80e1477b82bf26e8eb1e7b46d5e7f5c3128fbd_1717480456616_50.png"
                />
                <div className={styles["topC"]}>
                  <img className={styles["logo"]} src={signUpDetail.studyTourLogo} />
                </div>
                <div className={styles["topTitle"]}>游学邀约函</div>
                <div className={styles["inviteHeader"]}>
                  <div className={styles["c1"]}>
                    <div className={styles["msg"]}>
                      <div className={styles["tv1"]}>尊敬的爱豆，</div>
                      <div className={styles["tv2"]}>{signUpDetail?.inviteContent}</div>
                    </div>
                  </div>
                  <ImageM src={BgHeader} className={styles.headerBg} />
                </div>
                <div className={styles["info"]}>
                  {/* 游学信息*/}
                  <YouXueInfoComp detail={signUpDetail} />
                  <div className={styles["info__line"]}></div>
                  {/* 游学权益*/}
                  <BenefitInfoComp detail={signUpDetail} />
                </div>
                {signUpDetail.signUpType === 0 && (
                  <div className={styles["form"]}>
                    <div className={styles["tv1"]}>基本信息填写</div>
                    <div className={styles["contentC"]}>
                      <YxSignUpBasicInfoComp
                        memberInfo={signUpDetail?.memberSignUpInfo}
                        showCheckStatus={showCheckStatus}
                        ref={basicInfoRef}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            {(signUpDetail?.fullFlag === true || signUpDetail?.signUpType === 1) && (
              <div className={styles["emptyC"]}>
                {signUpDetail?.fullFlag === false && (
                  <div className={styles["topC"]}>
                    <img className={styles["logo"]} src={signUpDetail.studyTourLogo} />
                  </div>
                )}
                {signUpDetail?.fullFlag === true ? (
                  <div className={styles["topTitle"]}>{"抱歉～\n活动已满员"}</div>
                ) : (
                  <div className={styles["topTitle1"]}>游学邀约函</div>
                )}
                <div className={styles["c1"]}>
                  <Empty className={styles["empty"]} />
                  <div className={styles["tv1"]}>
                    {signUpDetail?.fullFlag === true
                      ? "  当前游学活动已经满员，\n请移步看看其他游学活动吧～"
                      : "我们已经收到您的游学报名申\n请，请耐心等待审核结果"}
                  </div>
                  <div className={styles["action1"]} onClick={goSee}>
                    去看看
                  </div>
                </div>
              </div>
            )}
            {/** 定向/非定向邀约 */}
            {!errorPage &&
              (signUpDetail?.signUpType === 0 || signUpDetail?.signUpType === 2) &&
              signUpDetail.fullFlag === false && (
                <div className={styles["bottom"]}>
                  <div className={styles["actionC"]}>
                    <div className={styles["actionTv1"]} onClick={notJoin}>
                      暂不参加
                    </div>
                    <div className={styles["actionTv2"]} onClick={comfirmJoin}>
                      {signUpDetail?.signUpType === 0 ? "确认提交" : "确认参加"}
                    </div>
                  </div>
                </div>
              )}
            {errorPage === true && (
              <div className={styles.error}>
                <div className={styles.mask1}></div>
                <div className={styles.mask2}></div>
                <img src="https://akim-oss.aikucun.com/07b7e0bd7b1f5fde90a6e7520edaca02ffe3f832_1702533136907_83.png" />
                <div className={styles.errorText}>服务器开小差了，请稍后重试~</div>
                <div className={styles.retry} onClick={() => location.reload()}>
                  重试
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default YouxueInvitePage;
