import { Dialog } from "antd-mobile";
import React, { useRef } from "react";
import styles from "./index.module.less";

const cancelSignUp = (params: { cancel?: () => void; submit: () => void }) => {
  Dialog.confirm({
    title: (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#222222",
          }}
        >
          是否确认取消报名？
        </div>
      </>
    ),
    content: (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            color: "#666666",
          }}
        >
          提交后自动视为放弃本场游学
        </div>
      </>
    ),
    closeOnMaskClick: false,
    cancelText: <div style={{ textAlign: "center", fontSize: 14, color: "#666666" }}>再想想</div>,
    confirmText: (
      <div style={{ textAlign: "center", fontSize: 14, color: "#FF4747" }}>确认取消</div>
    ),
    onConfirm: () => {
      params.submit();
    },
  });
};

const submitJoin = () => {
  const modal = Dialog.show({
    title: (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#222222",
            whiteSpace: "pre-wrap",
          }}
        >
          {"行程已经确认\n请准时参加游学活动"}
        </div>
      </>
    ),
    closeOnMaskClick: false,
    actions: [
      {
        key: "confirm",
        text: "确定",
        bold: true,
        style: { color: "#FF4747" },
        onClick: () => {
          modal.close();
        },
      },
    ],
  });
};

const CancelSignUpComp: React.FC<{
  onChange: (text?: string) => void;
}> = (props) => {
  return (
    <div className={styles.comp}>
      <div className={styles.title}>
        <div className={styles.title_pre}>*</div>
        <div className={styles.title_text}>请输入不能参加的原因</div>
      </div>
      <div className={styles.input}>
        <textarea
          className={styles.input_area}
          placeholder="请详述不能参加的原因"
          onChange={(res) => {
            props.onChange(res.target.value);
          }}
        ></textarea>
      </div>
      <div className={styles.tips}>
        *提交后自动视为放弃本场游学，若报名成功未游学超过3次，即加入游学黑名单1个月
      </div>
    </div>
  );
};

const onSubmitNotJoin = (params: { submit: () => void; onChange: (text?: string) => void }) => {
  Dialog.confirm({
    bodyStyle: {
      maxWidth: "calc(100vw - 75px)", // 设置最大宽度
      width: "300px", // 固定宽度
      margin: "0 auto", // 居中对齐
    },
    content: <CancelSignUpComp onChange={(rr) => params.onChange(rr)} />,
    closeOnMaskClick: true,
    cancelText: <div style={{ textAlign: "center", fontSize: 16, color: "#666666" }}>取消</div>,
    confirmText: <div style={{ textAlign: "center", fontSize: 16, color: "#FF4747" }}>提交</div>,
    onConfirm: () => {
      params.submit();
    },
  });
};

const onSubmitNotJoin2 = (params: { submit: () => void }) => {
  Dialog.confirm({
    title: (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: 16,
            color: "#222222",
          }}
        >
          请确认是否提交
        </div>
      </>
    ),
    content: (
      <>
        <div
          style={{
            textAlign: "center",
            fontSize: 14,
            color: "#666666",
          }}
        >
          提交后自动视为放弃本场游学
        </div>
      </>
    ),
    closeOnMaskClick: false,
    cancelText: <div style={{ textAlign: "center", fontSize: 14, color: "#666666" }}>取消</div>,
    confirmText: <div style={{ textAlign: "center", fontSize: 14, color: "#FF4747" }}>确认</div>,
    onConfirm: () => {
      params.submit();
    },
  });
};

export const YouxueDialog = {
  cancelSignUp,
  submitJoin,
  onSubmitNotJoin,
  onSubmitNotJoin2,
};
