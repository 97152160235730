import { inject, observer } from "mobx-react";
import React from "react";
import { UseDecorateProvider } from "./hooks/useDecorate";
import Decorate from "./index";
interface IProps {
  appStore?: any;
  [key: string]: any;
}
const Container: React.FC<IProps> = ({ appStore }) => {
  return (
    <UseDecorateProvider>
      <Decorate appStore={appStore} />
    </UseDecorateProvider>
  );
};
export default inject("appStore")(observer(Container));
