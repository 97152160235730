import ProductWaterfall from "@/components/product-waterfall";
import { hostEnv } from "@/utils/platform";
import React from "react";
import { useHasNext, useLoadMore, useProductList } from "../hooks/useActivityProduct";

interface Props {
  trackData?: any;
}
const ProductList: React.FC<Props> = ({ trackData }) => {
  const productList = useProductList();
  const hasNext = useHasNext();
  const loadMore = useLoadMore();

  return (
    <ProductWaterfall
      productList={productList}
      hasNext={hasNext}
      loadMore={loadMore}
      provideBackground={false}
      isOuterMargin={true}
      topRightLocationCode={`${hostEnv}_Shop_ProductList_ProductPicRight`}
      titleFrontLocationCode={`${hostEnv}_Shop_ProductList_TitleFront`}
      titleBottomLocationCode={`${hostEnv}_Shop_ProductList_TitleDown`}
      trackData={trackData}
    />
  );
};

export default ProductList;
