import React, { CSSProperties } from "react";

interface Props {
  text: string;
  highlight?: string[];
  style?: CSSProperties | undefined;
  highlightStyle?: CSSProperties | undefined;
  className?: string | undefined;
  highlightClassName?: string | undefined;
}

const RichText: React.FC<Props> = (props) => {
  if (!props.highlight) {
    return <div>{props.text}</div>;
  }
  const pattern = new RegExp(
    props.highlight
      .map((delimiter) => `(${delimiter.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")})`)
      .join("|"),
    "g",
  );
  const result = props.text.split(pattern);
  console.log(pattern, result);

  // 高亮关键词的函数
  const highlightWord = (word: string) => {
    // 如果关键词数组中包含当前单词，则包裹在高亮元素中
    if (props.highlight?.some((keyword) => word === keyword)) {
      return (
        <span key={word} className={props.highlightClassName}>
          {word}
        </span>
      );
    }
    return (
      <span key={word} className={props.className}>
        {word}
      </span>
    );
  };

  // 遍历单词数组，对需要高亮的单词应用高亮样式
  return <div>{result.map((word) => highlightWord(word))}</div>;
};

export default RichText;
