import { queryCancelAccountInfo, withdrawCancelAccount } from "@/api/my";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import { Toast } from "antd-mobile";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const Process: React.FC = () => {
  const [executionDate, setExecutionDate] = useState("");
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const navigate = useNavigate();

  const queryProcessInfo = async () => {
    const res: any = await queryCancelAccountInfo();
    if (res && res?.success && res.data) {
      setExecutionDate(res.data.executionDate);
    }
  };

  useEffect(() => {
    document.title = "";
    queryProcessInfo();
  }, []);

  const goHome = () => {
    let url = `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`;
    if (searchParams.get("distributorId")) {
      url = `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${searchParams.get(
        "distributorId",
      )}`;
    }
    window.location.replace(url);
  };

  const cancelLogout = async () => {
    const res: any = await withdrawCancelAccount();
    if (res?.success) {
      Toast.show({
        content: "解除成功",
        maskClickable: false,
        afterClose: () => {
          // 跳转到首页
          goHome();
        },
      });
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.container}>
        <div className={styles.main}>
          <img
            className={styles.statusIcon}
            src="https://akim-oss.aikucun.com/d435a6cdd786300dff204ee7c2ef942d3e9034e2_1706015811561_77.png"
          />
          <div className={styles.title}>账号注销流程中</div>
          <div>
            预计 <span className={styles.hl}>{executionDate}</span> 完成账号注销
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.longBtn} onClick={cancelLogout}>
            解除注销
          </div>
        </div>
      </div>
    </div>
  );
};
export default Process;
