import { queryActivityList } from "@/api/activity";
import { queryB2RShopList } from "@/api/b2r-shop";
import { queryBrandList } from "@/api/brand";
import { queryAtmosphereData } from "@/api/newPerson";
import { queryProductDetail, queryProductRecommendList } from "@/api/product";
import { useCommonContext } from "@/utils/context/common";
import {
  ActivityModel,
  BrandCardModel,
  ProductDetailAtmosphereModel,
  ProductDetailModel,
  ProductModel,
  ShopCardModel,
} from "@akc/biz-api";
import React from "react";
import { useSearchParams } from "react-router-dom";

export const useProductDetail = () => {
  const [search] = useSearchParams();
  const { cartNum, currentRole } = useCommonContext();

  const [activityId, setActivityId] = React.useState<string | null | undefined>(
    search.get("activityId"),
  );
  const [selectSkuId, setSelectSkuId] = React.useState(search.get("activitySpuId"));
  const [firstSalePropertyValue, setFirstSalePropertyValue] = React.useState(
    search.get("firstSalePropertyValue"),
  );
  const [secondSalePropertyValue, setSecondSalePropertyValue] = React.useState(
    search.get("secondSalePropertyValue"),
  );

  // 商品详情
  const [productDetail, setProductDetail] = React.useState<ProductDetailModel>();
  // 档期卡片
  const [activityItem, setActivityItem] = React.useState<ActivityModel>();
  // 店铺卡片
  const [shopItem, setShopItem] = React.useState<ShopCardModel>();
  // 品牌卡片
  const [brandItem, setBrandItem] = React.useState<BrandCardModel>();
  // 氛围及购买记录
  const [atmosphereModel, setAtmosphereModel] = React.useState<ProductDetailAtmosphereModel>();
  // 连带推荐商品
  const [relatedProductList, setRelatedProductList] = React.useState<ProductModel[]>();
  // 推荐商品列表
  const [recommendProductList, setRecommendProductList] = React.useState<ProductModel[]>();

  const activitySpuId = search.get("activitySpuId");

  React.useEffect(() => {
    if (!activitySpuId) {
      return;
    }
    // 请求连带推荐商品
    queryProductRecommendList({
      pageId: "H5_RelatedSale",
      relatedScene: "detail_buytogether",
      activitySpuIds: [activitySpuId],
    }).then((res) => {
      setRelatedProductList(res?.result);
    });
    // 请求气氛数据
    queryAtmosphereData({
      activitySpuId,
    }).then((res) => {
      setAtmosphereModel(res);
    });
  }, []);

  React.useEffect(() => {
    // 请求商详
    queryProductDetail({
      activityId,
      activitySpuId: activitySpuId,
      selectSkuId,
      firstSalePropertyValue,
      secondSalePropertyValue,
      pageId: "H5_ProductDetail",
    }).then((res) => {
      setProductDetail(res);
      if (!activityId) {
        setActivityId(res?.activityId);
      }
    });
  }, [selectSkuId, firstSalePropertyValue, secondSalePropertyValue]);

  React.useEffect(() => {
    if (!productDetail?.cardEntranceVO?.cardType) {
      return;
    }
    switch (productDetail.cardEntranceVO.cardType) {
      case "ACTIVITY": {
        // 请求档期卡片
        const activityId = productDetail.cardEntranceVO.cardTargetId ?? productDetail.activityId;
        if (!activityId) {
          return;
        }
        queryActivityList({
          pageId: "H5_ScheduleList",
          searchParams: {
            allowActivityIds: [activityId],
            scene: "PRODUCT_DETAIL",
          },
          voConfig: { withProduct: true },
        }).then((res) => {
          setActivityItem(res?.result?.[0]);
        });
        break;
      }
      case "BRAND": {
        // 请求品牌卡片
        const brandId =
          productDetail.brandInfo?.brandId ?? productDetail.cardEntranceVO.cardTargetId;
        if (!brandId) {
          return;
        }
        queryBrandList({
          brandIds: [],
          pageId: "H5_ProductDetail",
          voConfig: {
            withProduct: true,
          },
          searchParams: {
            pageNum: 1,
            pageSize: 1,
          },
        }).then((res) => {
          setBrandItem(res?.result?.[0]);
        });
        break;
      }
      case "MERCHANT_SHOP": {
        // 请求店铺卡片
        const code = productDetail.cardEntranceVO.cardTargetId;
        if (!code) {
          return;
        }
        queryB2RShopList({
          merchantShopCodes: [code],
          pageId: "H5_ScheduleList",
          searchParams: {
            pageNum: 1,
            pageSize: 1,
          },
          voConfig: {
            withProduct: true,
            withProductCnt: 3,
          },
        }).then((res) => {
          setShopItem(res?.result?.[0]);
        });
        break;
      }
      default:
        break;
    }
  }, [productDetail]);

  React.useEffect(() => {
    // xxx
  }, [activityId]);

  return { productDetail, cartNum, currentRole };
};
