import Track from "@/utils/track";
const track = Track();

export const pageView = (activityId?: string) => {
  const trackParams = {
    page_code: activityId,
  };
  track.pageView("我的奖励", trackParams);
};

export const redPacketTrack = (isTop?: boolean, index?: number) => {
  const trackParams = {
    page_name: "我的奖励",
    tab_name: "红包",
    resource_type: "红包",
    resource_rank: isTop ? index : (index ?? 0) + 1,
    btn_name: isTop ? "去使用" : "去逛逛",
  };
  track.track("resource_click", trackParams);
};

export const couponTrack = (couponId?: string, index?: number) => {
  const trackParams = {
    page_name: "我的奖励",
    tab_name: "优惠券",
    resource_type: "优惠券",
    resource_rank: index,
    btn_name: "去使用",
    coupon_id: couponId,
  };
  track.track("resource_click", trackParams);
};

export const pointTrack = (index?: number) => {
  const trackParams = {
    page_name: "我的奖励",
    tab_name: "积分",
    resource_type: "积分",
    resource_rank: index,
    btn_name: "去查看",
  };
  track.track("resource_click", trackParams);
};
