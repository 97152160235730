import { getShopInfo } from "@/api/home";
import { useEffect, useState } from "react";

type ShopInfo = {
  shopLogoUrl: string;
  shopName: string;
};

const useShopInfo = () => {
  const [shopInfo, setShopInfo] = useState<ShopInfo>({ shopLogoUrl: "", shopName: "" });
  useEffect(() => {
    const shopId = localStorage.getItem("shopId");
    const distributorId = localStorage.getItem("distributorId");
    if (!shopId) {
      return;
    }
    getShopInfo(shopId, distributorId).then((res) => {
      if (res) {
        setShopInfo(res);
      }
    });
  }, []);
  return shopInfo;
};

export default useShopInfo;
