import captchaConfig from "./config";

let captcha:
  | {
      show: () => void;
      hide: () => void;
      reload: () => void;
    }
  | undefined = undefined;

/**
 * 显示无感验证
 */
export const showCaptcha = (tcp: string) => {
  return new Promise<string | undefined>((resolve) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    captcha = window._dx // eslint-disable-line
      .Captcha(document.getElementById("captcha"), {
        ...captchaConfig,
        tcp,
        success: (token) => {
          captcha?.hide();
          captcha?.reload();
          console.log(token);
          resolve(token);
        },
      });
    captcha?.show();
  });
};
