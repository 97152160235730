import { SortType } from "@/api/activity";
import { ReactComponent as FilterIcon } from "@/assets/filter.svg";
import { ReactComponent as DescIcon } from "@/assets/sort_desc.svg";
import Clickable from "@/components/clickable";
import SortIcon from "@/components/sort-icon";
import { hostEnv } from "@/utils/platform";
import { ActivityBrandModel } from "@akc/biz-api";
import { Popup } from "antd-mobile";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUnactivate } from "react-activation";
import {
  useHasFilterOption,
  useProductList,
  useSelectedActivityIdList,
  useSelectedSortInfo,
  useSelectedSortType,
  useSetSelectedSortInfo,
  useSetSelectedSortType,
  useShownFirstSortOption,
  useSortInfoList,
  useSortPopupOptions,
  useUpdateFilterInfo,
} from "../hooks/useActivityProduct";
import { FilterOptionProvider } from "../hooks/useFilterOption";
import FilterBoard from "./FilterBoard";
import styles from "./SortAndFilter.module.less";

type Props = {
  tabData?: any;
  brandData?: ActivityBrandModel;
  onSortFilterChange?: () => void;
  ref?: ForwardedRef<any>;
};

const SortAndFilter: React.FC<Props> = forwardRef(
  ({ onSortFilterChange, tabData, brandData }, ref) => {
    const sortPopupOptions = useSortPopupOptions();
    // console.error("sortPopupOptions——", sortPopupOptions);
    const shownFirstSortOption = useShownFirstSortOption();
    // console.error("shownFirstSortOption——", shownFirstSortOption);
    const sortInfoList = useSortInfoList();
    // console.error("sortInfoList——", sortInfoList);
    const selectedSortInfo = useSelectedSortInfo();
    const setSelectedSortInfo = useSetSelectedSortInfo();
    const selectedSortType = useSelectedSortType();
    const setSelectedSortType = useSetSelectedSortType();
    const updateFilterInfo = useUpdateFilterInfo();
    const hasFilterOption = useHasFilterOption();
    const selectedActivityIdList = useSelectedActivityIdList();

    const [filterBoardVisible, setFilterBoardVisible] = useState(false);
    const filterBoardRef = useRef<any>(null);

    useUnactivate(() => {
      setFilterBoardVisible(false);
    });

    // 自定义由 ref 暴露出来的句柄。
    useImperativeHandle(ref, () => {
      return {
        resetFilter: filterBoardRef.current?.reset,
      };
    });

    const [sortPopupVisible, setSortPopupVisible] = useState(false);
    const productList = useProductList();
    useEffect(() => {
      setSortPopupVisible(false);
    }, [productList]);

    return (
      <>
        <div className={styles.container}>
          <div className={styles.options}>
            {shownFirstSortOption && (
              <div
                className={` ${styles.item} ${shownFirstSortOption.active ? styles.active : ""}`}
                onClick={() => {
                  console.error("click综合——");
                  setSortPopupVisible((value) => !value);
                }}
              >
                <span>{shownFirstSortOption.name}</span>
                <DescIcon className={styles.firstSortOptionIcon} />
              </div>
            )}
            {sortInfoList.length > 0 &&
              sortInfoList.map((item, index) => (
                <Clickable
                  key={index}
                  className={` ${styles.item} ${
                    selectedSortInfo?.sortModel === item.sortModel ? styles.active : ""
                  }`}
                  onClick={() => {
                    if (selectedSortInfo?.sortModel === item.sortModel) {
                      if (item.sortTypes.length === 2) {
                        const index = item.sortTypes.indexOf(selectedSortType);
                        setSelectedSortType(item.sortTypes[(index + 1) % 2]);
                        onSortFilterChange?.();
                      }
                    } else {
                      setSelectedSortInfo(item);
                      setSelectedSortType(item.sortTypes[0]);
                      onSortFilterChange?.();
                    }
                  }}
                  trackData={{
                    resource_type: "排序筛选",
                    btn_name: item.sortModel.toString(),
                  }}
                  trackContextKeys={[
                    "tab_name",
                    "tab_id",
                    "tab_rank",
                    "merchant_shop_code",
                    "activity_id",
                  ]}
                >
                  <span>{item.name}</span>
                  {item.sortTypes.length === 2 && (
                    <SortIcon
                      sortType={
                        selectedSortInfo?.sortModel === item.sortModel
                          ? selectedSortType
                          : SortType.Default
                      }
                    />
                  )}
                </Clickable>
              ))}
            <Clickable
              className={` ${styles.item} ${hasFilterOption ? styles.active : ""}`}
              onClick={() => setFilterBoardVisible(true)}
              trackData={{
                resource_type: "排序筛选",
                btn_name: "筛选",
              }}
              trackContextKeys={[
                "tab_name",
                "tab_id",
                "tab_rank",
                "merchant_shop_code",
                "activity_id",
              ]}
            >
              <span>筛选</span>
              <FilterIcon className={styles.filterIcon} />
            </Clickable>
          </div>

          <div
            className={`${styles.filterPopup} ${sortPopupVisible ? "" : styles.hidden}`}
            onClick={() => {
              setSortPopupVisible(false);
            }}
          >
            <div className={styles.content}>
              {sortPopupOptions?.length > 0 &&
                sortPopupOptions.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.item} ${
                      selectedSortInfo?.sortModel === item.sortModel ? styles.active : ""
                    }`}
                    onClick={() => {
                      if (selectedSortInfo?.sortModel !== item.sortModel) {
                        setSelectedSortInfo(item);
                        setSelectedSortType(item.sortTypes[0]);
                        onSortFilterChange?.();
                      }
                    }}
                  >
                    {item.desc}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <FilterOptionProvider
          filterBoardVisible={filterBoardVisible}
          activityIdList={selectedActivityIdList}
          scene="SALES_REBATE_PAGE"
          pageId={`${hostEnv}_Shop`}
        >
          <Popup
            visible={filterBoardVisible}
            showCloseButton
            onMaskClick={() => {
              setFilterBoardVisible(false);
            }}
            onClose={() => {
              setFilterBoardVisible(false);
            }}
            bodyStyle={{ height: "90vh" }}
          >
            {brandData && (
              <FilterBoard
                ref={filterBoardRef}
                brandData={brandData}
                onFilterChange={(params) => {
                  setFilterBoardVisible(false);
                  updateFilterInfo(params);
                  onSortFilterChange?.();
                }}
              />
            )}
          </Popup>
        </FilterOptionProvider>
      </>
    );
  },
);

export default SortAndFilter;
