import { queryAddressDetail } from "@/api/dailyReward";
import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { useEffect, useRef, useState } from "react";
import { useActivate } from "react-activation";

type Address = {
  id?: string;
  contact?: string;
  phone?: string;
  province?: string;
  city?: string;
  area?: string;
  street?: string;
};

const maskString = (str, positions) => {
  // 将positions数组中指定的位置的字符替换为星号
  positions.forEach((position) => {
    str = str.slice(0, position - 1) + "*" + str.slice(position);
  });
  return str;
};

const useAddress = () => {
  const [address, setAddress] = useState<Address>({});
  const [addressText, setAddressText] = useState<string>("");
  const popstateCallback = useRef<any>(null);
  const { navigate2AddressList } = useNavigation();
  const { isMini, isApp, isSaas, isH5 } = platform;
  const queryAddress = async (newId?) => {
    let id: string | undefined | null;
    // if (isH5) {
    //   id = localStorage.getItem("addressId");
    // } else {
    //   id = newId;
    // }
    id = newId === "default" ? undefined : newId;
    // 没有id时返回默认地址
    const addressDetail: any = await queryAddressDetail({ addressId: id });
    setAddress(addressDetail);
    if (addressDetail?.id) {
      const { contact, phone, province, city, area, street } = addressDetail;
      setAddressText(
        `${maskString(contact, [2, 3])} ${maskString(phone, [4, 5, 6, 7])} ${province} ${city} ${area} ${street}`,
      );
    } else {
      setAddressText("暂无收货地址");
    }
  };

  const addMiniEventListener = () => {
    popstateCallback.current = () => {
      if (window.location.hash) {
        const hash = location.hash.split("#")[1];
        if (hash.startsWith("tmpAddrId=")) {
          const addrId = hash.split("=")[1];
          console.log("hash中的addrId", addrId);
          addrId && queryAddress(addrId || address.id);
        }
      }
    };
    window.addEventListener("popstate", popstateCallback.current);
  };

  const openAddrPage = () => {
    console.log("toGetAddr");
    if (isApp) {
      AKJS.ready().then(() => {
        AKJS.action
          .dispatch("event.info.chooseAddress", { addressId: "" })
          .then((result) => {
            console.error("event.info.chooseAddres", result);
            if (result?.data?.addressId) {
              console.log("地址信息", result?.data?.addressId);
              queryAddress(result?.data?.addressId);
            }
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      });
    } else {
      navigate2AddressList({
        fromPage: "h5",
      });
    }
  };

  useActivate(() => {
    console.log("从上个页面回来，页面缓存住");
    isH5 && queryAddress(localStorage.getItem("addressId"));
  });

  useEffect(() => {
    queryAddress();
    if (isMini) {
      addMiniEventListener();
      return () => {
        window.removeEventListener("popstate", popstateCallback.current);
      };
    }
  }, []);

  return {
    address,
    addressId: address?.id,
    addressText,
    openAddrPage,
  };
};

export default useAddress;
