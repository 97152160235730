import React from "react";
import { useSearchParams } from "react-router-dom";

import KeepAlive from "react-activation";
import Index from "./Index";

interface Props {
  pagePath?: any;
}

const AppContainer: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const customPageCode = search.get("customPageCode");

  return (
    <>
      <KeepAlive cacheKey={`conference-${customPageCode}`} saveScrollPosition={true}>
        <Index />
      </KeepAlive>
    </>
  );
};

export default AppContainer;
