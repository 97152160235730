import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

type Props = {
  logisticsList: any[];
  changeLogisticsItem: (item: any) => void;
};
const LogisticsPackage: React.FC<Props> = ({ logisticsList, changeLogisticsItem }) => {
  const ICON =
    "https://akmer.aikucun.com/mshop/099611721f3d141f6229543d1d8d154e034d3c08_1606457996613_2.png";
  const ICON_ACTIVE =
    "https://akmer.aikucun.com/mshop/716d7c406a5a3dff5660343bc80bcd7b5a7dd5f5_1604999808977_72.png";

  const [searchParams] = useSearchParams();
  let logisticsNo = searchParams.get("logisticsNo") ?? "";
  let logisticsIndex = logisticsList.findIndex((item: any) => item.logisticsNo === logisticsNo);
  const [packageIndex, setPackageIndex] = useState(logisticsIndex !== -1 ? logisticsIndex : 0);
  const onPackageItem = (item: any, index: number) => () => {
    setPackageIndex(index);
    changeLogisticsItem(item);
  };

  return (
    <div className={styles["logistics-package"]}>
      <div className={styles["package-list"]}>
        {logisticsList.map((item, index) => (
          <div
            className={`${styles["package-item"]} ${packageIndex === index ? styles["active"] : ""}`}
            key={index}
            onClick={onPackageItem(item, index)}
          >
            <div className={styles["package-item__content"]}>
              <img src={packageIndex === index ? ICON_ACTIVE : ICON} alt="" />
              <span>包裹{index + 1}</span>
            </div>
            {packageIndex === index && item?.deliveryNodes[0]?.abnormalTypeDesc && (
              <span className={styles["package-item__abnormal"]}>
                {item.deliveryNodes[0].abnormalTypeDesc}
              </span>
            )}
            {packageIndex === index && <div className={styles["package-item__line"]}></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogisticsPackage;
