import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

const SearchPage: React.FC = () => {
  useEffect(() => {
    document.title = "搜索页";
  }, []);

  return <div>SearchPage</div>;
};

export default SearchPage;
