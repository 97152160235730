/**
 * 埋点中涉及商品相关公共字段
 * @param {*} product: 商品对象
 * @param {*} needKeyList: 指定返回需要的key(非必传)
 * @returns
 */
export function getTrackProductInfo(product, needKeyList?: string[]) {
  if (!product) {
    return {};
  }
  const params = {
    activity_id: product?.activityId || "", // 活动ID
    activity_name: product?.activitySimpleVO?.name || "",
    activity_spu_code: product?.activitySpuId || "", // 活动商品SPU
    brand_id: product?.brandInfo.brandId || "",
    brand_name: product?.brandInfo?.brandName || "",
    merchant_shop_code: product?.activitySimpleVO?.merchant_shop_code || "", // 商家店铺编号
    product_model: product?.activityType ? `${product.activityType}` : "", // 商品模式
    product_name: product?.skuBaseInfoVO?.title || "",
    price: product?.skuCurrentPriceInfo?.marketingPrice || "",
    spu_id: product?.activitySpuId || "", // 基础商品SPU
    sku_id: product?.selectSkuId || "", // 商品SKU
  };
  if (needKeyList?.length) {
    const needKeyObj = {};
    needKeyList.forEach((key) => {
      return (needKeyObj[key] = params[key]);
    });
    return needKeyObj;
  } else {
    return params;
  }
}

/**
 * 埋点中涉及素材相关公共字段
 * @param {*} product: 素材对象
 * @param {*} needKeyList: 指定返回需要的key(非必传)
 * @returns
 */
export function getTrackMaterialInfo(material, needKeyList?: string[]) {
  if (!material) {
    return {};
  }
  const params = {
    material_id: material?.materialNo || "",
  };
  if (needKeyList?.length) {
    const needKeyObj = {};
    needKeyList.forEach((key) => {
      return (needKeyObj[key] = params[key]);
    });
    return needKeyObj;
  } else {
    return params;
  }
}
