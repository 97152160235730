import { Tabs } from "antd-mobile";
import React from "react";
import { TopTabModel } from "../../model/TopTabModel";
import styles from "./index.module.less";

type Props = {
  activeKey?: string;
  tabItems?: TopTabModel[];
};

/**
 * 顶部tab栏
 */
const TopTabsComp: React.FC<Props> = (prop) => {
  if ((prop.tabItems?.length ?? 0) <= 0) {
    return <></>;
  }
  return (
    <div className={styles.topTabs}>
      <Tabs
        activeKey={prop.activeKey ?? "1"}
        style={{
          "--title-font-size": "16px",
          "--active-line-color": "#FF4747",
          "--active-title-color": "#222222",
          "--active-line-height": "2px",
          "--content-padding": "8px",
        }}
        onChange={(key) => {
          const element = document.getElementById(`anchor-${key}`);
          if (element) {
            const rect = element.getBoundingClientRect();
            const offset = rect.top + window.pageYOffset - 44;
            setTimeout(() => {
              window?.scrollTo({
                top: offset,
                behavior: "smooth",
              });
            });
          }
        }}
      >
        {prop.tabItems?.map((item) => <Tabs.Tab title={item.title} key={item.key} />)}
      </Tabs>
    </div>
  );
};

export default TopTabsComp;
