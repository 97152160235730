import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";

/**
 * 查询各状态优惠券总数
 * http://yapi.akcops.com/project/1799/interface/api/264831
 */
export async function getCouponCount(): Promise<number | undefined> {
  const res = await Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/selectUserCouponCount`,
    method: "post",
  });
  return res?.data?.availableCount;
}

/**
 * 商品详情、档期详情、超品会场详情、商家店铺的新人券/优惠券弹窗
 * https://yapi.akcops.com/project/2571/interface/api/363192
 */
export function getCouponWindowForVenue(params?: any) {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/couponWindowForVenue`,
    method: "get",
    params: params,
  });
}

export async function batchReceiveCoupon(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/mshop/mshop-aggr-prod/outer/v1/coupon/batchReceiveCoupon`,
    method: "post",
    data: params,
  });
}

export async function queryZoneCouponData(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/zoneCouponData`,
    method: "get",
    params: params,
  });
}

// 优惠券弹窗
export async function queryCouponWindow(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/v2/couponWindow`,
    method: "get",
    params: params,
  });
}

// 领取天降券
export async function drawCouponListForCenter(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/drawCouponListForCenter`,
    method: "post",
    data: params,
  });
}

// 清除弹框次数
export async function clearWindowNum(params?: any): Promise<Response<any>> {
  return Request({
    url: `${getBaseurl()}/api/spweb/order/clearWindowNum`,
    method: "get",
    params: params,
  });
}
