import {
  getCustomerConfig,
  getCustomerStatistics,
  getFrontCustomerStatistics,
} from "@/api/my/shop";
import Track from "@/utils/track";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";
interface ICustomerCount {
  browsePersonCount?: number;
  shoppingPersonCount?: number;
  orderPersonCount?: number;
}

type TItemModel = {
  label: string;
  key: string;
  path: string;
  query?: string;
  icon?: string;
  [key: string]: any;
};

const ShopCustomerData: React.FC = () => {
  const tabList = [
    {
      label: "今日",
      value: "TD",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
    {
      label: "昨日",
      value: "LD",
      startDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
      endDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
    },
    {
      label: "7日",
      value: "7D",
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().subtract(1, "d").format("YYYY-MM-DD"),
    },
  ];

  const customerStasticsList: Array<TItemModel> = [
    {
      label: "店铺顾客",
      key: "browsePersonCount",
      path: "/#/analysis/customer/list",
      query: "searchType=visit",
    },
    {
      label: "加购顾客",
      key: "shoppingPersonCount",
      path: "/#/analysis/customer/list",
      query: "searchType=shopping",
    },
    {
      label: "成交顾客",
      key: "orderPersonCount",
      path: "/#/analysis/customer/list",
      query: "searchType=assets",
    },
  ];

  const moduleList: Array<TItemModel> = [
    {
      label: "浏览顾客",
      key: "shopCustomerFlag",
      path: "/#/analysis/customer",
      icon: "https://akim-oss.aikucun.com/mshop/a7eada72b410f620d47a8a750a2bc7dd930768f9_1715062732049_35.png",
    },
    {
      label: "顾客贡献榜",
      key: "customerContributeFlag",
      path: "/#/analysis/crank",
      icon: "https://akim-oss.aikucun.com/mshop/a7eada72b410f620d47a8a750a2bc7dd930768f9_1715062626012_8.png",
    },
    {
      label: "店铺热卖榜",
      key: "shopHotRankFlag",
      path: "/#/analysis/hotSale",
      icon: "https://akim-oss.aikucun.com/81b6a5c7ae955a38c541d544758e5cc51a0d51ed_1725414736399_0.png",
    },
  ];

  const track = Track();
  const [timeTabIndex, setTimeTabIndex] = useState(0);
  const [customerCount, setCustomerCount] = useState<ICustomerCount>({});
  const { navigate2H5 } = useNavigation();

  const queryCustomerCount = async () => {
    const data = await getFrontCustomerStatistics({
      searchDay: timeTabIndex + 1,
      startDate: tabList[timeTabIndex].startDate,
      endDate: tabList[timeTabIndex].endDate,
    });
    setCustomerCount(data);
  };

  const onTimeTabChange = (index: number) => {
    setTimeTabIndex(index);
    track.track("btn_click", {
      page_name: "我的",
      module: "店铺参谋",
      resource_type: "店铺参谋",
      btn_name: "日期TAB",
      btn_text: tabList[index].label,
    });
  };

  const goCustomerListItem = (item: TItemModel) => {
    const query = `${item.query}&date=${timeTabIndex}`;
    const options: IOptions = {
      path: item.path,
      is_micro_app: true,
      query,
    };
    navigate2H5(options);
    track.track("btn_click", {
      page_name: "我的",
      module: "店铺参谋",
      resource_type: "店铺参谋",
      btn_name: "店铺数据TAB",
      btn_text: item.label,
    });
  };

  const goModuleListItem = (item: TItemModel) => {
    const query = `date=${item.key === "shopCustomerFlag" ? timeTabIndex : tabList[timeTabIndex].value}`;
    const options: IOptions = {
      path: item.path,
      is_micro_app: true,
      query,
    };
    navigate2H5(options);
    track.track("btn_click", {
      page_name: "我的",
      module: "店铺参谋",
      btn_name: "分类列表TAB",
      btn_text: item.label,
    });
  };

  useEffect(() => {
    queryCustomerCount();
  }, [timeTabIndex]);

  return (
    <div className={styles.shopCustomerData}>
      <div className={styles.titleHeader}>
        <span>店铺参谋</span>
        {/* 今日、昨日、7日 */}
        <div className={styles.timeTab}>
          {tabList.map((item, index) => {
            return (
              <div
                className={`${styles.tabItem} ${index === timeTabIndex ? styles.active : null}`}
                key={index}
                onClick={() => onTimeTabChange(index)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
      {/* 浏览顾客、加购顾客、成交顾客 */}
      <div className={styles.stasticContent}>
        {customerStasticsList.map((item, index) => {
          return (
            <div
              className={styles.stasticItem}
              key={index}
              onClick={() => goCustomerListItem(item)}
            >
              <div className={styles.stasticItemTitle}>
                <span>{item.label}</span>
                <i className={styles.icon} />
              </div>
              <div className={styles.stasticItemCount}>
                <span>{customerCount[item.key] ?? 0}</span>
              </div>
            </div>
          );
        })}
      </div>
      {/* 店铺顾客、顾客贡献榜 */}
      <div className={styles.moduleContent}>
        {moduleList.map((item, index) => {
          return (
            <div className={styles.moduleItem} key={index} onClick={() => goModuleListItem(item)}>
              <img src={item.icon} alt="" />
              <div className={styles.moduleItemName}>
                <span>{item.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShopCustomerData;
