import React, { useEffect } from "react";

import GUIDE from "@/assets/guide_share.png";
import SHOP from "@/assets/icon_shop.png";
import SWITCH from "@/assets/icon_switch.png";
import IWechat from "@/assets/icon_wechat.png";
import { UseUserInfoContext } from "@/pages/my/hooks/useUserInfo";
import { Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import { btn_click } from "../../track";
import BindPhonePopup from "../bind-phone-popup";
import RoleSwitchPopup from "../role-switch-popup";
import ShopOwnerQrcode from "../shop-owner-qrcode";
import styles from "./index.module.less";
interface IProps {
  userinfo: any;
  shareInfo: any;
  bindStatus: number;
  labelViewInfo: any;
}

const UserInfo: React.FC<IProps> = ({ userinfo, shareInfo, bindStatus, labelViewInfo }) => {
  const { handleSwitchRole } = UseUserInfoContext();

  const defaultAvatarUrl =
    "https://akim-oss.aikucun.com/5adcf16a0a72ef9926af328220068b695fd489a5_1703043623267_78.png";
  const [showGuide, setShowGuide] = React.useState(false);
  const [contactVisible, setContactVisible] = React.useState(false);
  const [roleSwitchPoupVisible, setRoleSwitchPoupVisible] = React.useState(false);
  const [bindPhonePopupVisible, setBindPhonePopupVisible] = React.useState(false);
  const [bindPhoneBtnVisible, setBindPhoneBtnVisible] = React.useState(0);
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId");
  const distributorId = searchParams.get("distributorId");
  const handleClickContact = () => {
    setContactVisible(true);
    btn_click("我的-头部功能区", "联系店主");
  };
  const handleClickShare = () => {
    setShowGuide(true);
    btn_click("我的-头部功能区", "分享店铺码");
  };
  const handleRoleSwitchConfirm = (switchedRole) => {
    setRoleSwitchPoupVisible(false);
    if (switchedRole === 1) {
      return;
    }
    handleSwitchRole(switchedRole);
  };
  const toAuth = (hasScope = true) => {
    let url = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    // 处理微信自动添加字符串问题，替换为空
    url = url.replace(/\/\?from=(message|timeline|singlemessage|groupmessage)(\S*)#/, "/#");
    url = url.replace(/\/\?(scene|subscene|clicktime)=[\d]+(\S*)#/, "/#");
    window.location.href = `/api/member/auth?${
      hasScope ? "scope=snsapi_userinfo&" : ""
    }backUrl=${window.encodeURIComponent(url)}`;
  };
  const goBindPhone = async () => {
    console.log("bindPhoneBtnVisible", bindPhoneBtnVisible);
    if (bindPhoneBtnVisible === 1) {
      return;
    }
    if (bindPhoneBtnVisible === 2) {
      toAuth();
      return;
    }
    if (bindPhoneBtnVisible === 3 || bindPhoneBtnVisible === 4) {
      setBindPhonePopupVisible(true);
    }
  };
  useEffect(() => {
    setBindPhoneBtnVisible(bindStatus);
  }, [bindStatus]);

  const onCopy = (e) => {
    e.stopPropagation();
    copy(userinfo?.userIdCode || userinfo?.visitorCode);
    Toast.show("复制成功");
  };

  const onFinishBindPhone = () => {
    setBindPhonePopupVisible(false);
    setBindPhoneBtnVisible(1);
    window.location.reload();
  };

  return (
    <div className={styles.userinfo}>
      <div className={styles["userinfo-left"]}>
        <div className={styles["userinfo-left-avatar-box"]}>
          <img
            className={styles["userinfo-left__avatar"]}
            src={userinfo.headImgUrl || defaultAvatarUrl}
            alt=""
          />
          {userinfo.roleSwitchAvailable && (
            <div className={styles["role-switch"]} onClick={() => setRoleSwitchPoupVisible(true)}>
              <img className={styles["role-switch__icon"]} src={SWITCH} alt="" />
              <span className={styles["role-switch__text"]}>切换身份</span>
            </div>
          )}
        </div>
        <div className={styles["userinfo-left__name"]}>
          <p>{userinfo.nickname || userinfo.phone}</p>
          {bindPhoneBtnVisible !== 1 && (
            <div className={styles["bind-phone"]} onClick={goBindPhone}>
              绑定手机
            </div>
          )}
          {userinfo?.currentRole === 1 && labelViewInfo?.labelIconUrl && (
            <div className={styles.label_wrap}>
              <img className={styles.label} src={labelViewInfo.labelIconUrl} />
            </div>
          )}
          {(userinfo?.userIdCode || userinfo?.visitorCode) && (
            <div className={styles["user-id"]} onClick={onCopy}>
              编号: {userinfo?.userIdCode || userinfo?.visitorCode}
              <div className={styles["btn__copy"]}>复制</div>
            </div>
          )}
        </div>
      </div>
      <div className={styles["userinfo-right"]}>
        {shareInfo?.shopQrCodeUrl && (
          <div className={styles["userinfo-right__item"]} onClick={handleClickContact}>
            <img src={IWechat} alt="" />
            <span>联系店主</span>
          </div>
        )}
        <div className={styles["userinfo-right__item"]} onClick={handleClickShare}>
          <img src={SHOP} alt="" />
          <span>分享店铺</span>
        </div>
      </div>
      {showGuide && (
        <div className={styles["guide_mask"]} onClick={() => setShowGuide(false)}>
          <img className={styles["guide"]} src={GUIDE} alt="" />
        </div>
      )}
      {/* 二维码组件 */}
      <ShopOwnerQrcode
        visible={contactVisible}
        setVisible={setContactVisible}
        shareInfo={shareInfo}
      />
      {/* 切换身份弹出层 */}
      <RoleSwitchPopup
        userinfo={userinfo}
        visible={roleSwitchPoupVisible}
        setVisible={setRoleSwitchPoupVisible}
        currentRole={userinfo.currentRole}
        onConfirm={handleRoleSwitchConfirm}
      />
      {/* 绑定手机弹出层 */}
      <BindPhonePopup
        visible={bindPhonePopupVisible}
        setVisible={setBindPhonePopupVisible}
        onFinish={onFinishBindPhone}
        extra={{ bindStatus: bindPhoneBtnVisible }}
      />
    </div>
  );
};

export default UserInfo;
