import AKJS from "@akc/akjs";
import { platform } from "./platform";

// app系统信息
export type AppSystemInfo = {
  appName: string;
  appVersion: string;
  systemType: "android" | "iOS";
  systemVersion: string; // 手机系统版本
  deviceId: string;
  provider: number; // 运营商1：中国移动；2：中国电信；3：中国联通
  connectionType: string; // 网络连接类型：wifi；2G；3G；4G；none,
  screenWidth: number;
  screenHeight: number;
};

export const getAppSystem = (): Promise<AppSystemInfo | undefined> => {
  return new Promise((resolve, reject) => {
    try {
      if (platform.isApp) {
        AKJS.ready().then(() => {
          AKJS.info
            .device()
            .then((res) => {
              resolve(res.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        resolve(undefined);
      }
    } catch (error) {
      reject(error);
    }
  });
};
