import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const Beianhao = () => {
  return (
    <div className={styles.beianhao}>
      <h3>增值电信业务经营许可证：</h3>
      <p>合字B2-20210092</p>
      <h3>互联网药品信息服务资格证书编号：</h3>
      <p>(沪)-经营性-2020-0004</p>
      <h3>上海市网络食品交易第三方平台提供者备案：</h3>
      <p>沪网食备A0025号</p>
      <h3>医疗器械网络交易服务第三方平台备案凭证编号：</h3>
      <p>(沪)网械平台备字[2021]第00006号</p>
    </div>
  );
};

export default Beianhao;
