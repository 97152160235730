import Track from "@/utils/track";
import { Image, ImageViewer } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

const SelectivePosters: React.FC = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const imageList = (search.get("images") ?? "").split(",");

  const [posterImages, setPosterImages] = useState<any>([]);
  const backIcon =
    "https://akim-oss.aikucun.com/mshop/ef82008783e520e71d1f6601db7aaa75cec27241_1714030088871_69.png";
  const pressIcon =
    "https://akim-oss.aikucun.com/mshop/9132e087f1bf1ec918d99ba611cc565ee51c54d8_1714030414052_97.png";

  // 长按事件
  const track = Track();
  const isPressing = useRef(false);
  // eslint-disable-next-line no-undef
  const pressTimer = useRef<NodeJS.Timeout | null>(null);

  const handleLongPress = () => {
    if (isPressing.current) {
      console.log("长按保存");
      track.track("$share", {
        page_name: "海报列表页",
        previous_page_name: "选择性转发页",
        share_type: "SPU_PRODUCT",
        share_t: Date.now(),
        share_name: "长按保存",
        share_method: "海报",
        share_setting: "H5",
        share_channel: "保存到相册",
        scode: search.get("scode"),
      });
    }
  };

  const handleTouchDown = () => {
    pressTimer.current = setTimeout(() => {
      isPressing.current = true;
      handleLongPress();
    }, 1000);
  };

  const handleTouchUp = () => {
    pressTimer.current && clearTimeout(pressTimer.current);
    isPressing.current = false;
  };

  useEffect(() => {
    document.title = "下载海报";
    setPosterImages(imageList);
    return () => {
      ImageViewer.clear();
    };
  }, []);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["header"]}>
          <img className={styles["back"]} src={backIcon} onClick={() => navigate(-1)} />
          <div className={styles["tip"]}>
            已生成<span>{posterImages.length}张</span>商品海报，可<span>长按下载</span>
            <img src={pressIcon} />
          </div>
        </div>
        <div className={styles["content"]}>
          {posterImages?.length > 0 &&
            posterImages.map((item, index) => (
              <div
                key={index}
                className={styles["poster-item"]}
                onClick={() => {
                  ImageViewer.Multi.show({
                    images: posterImages,
                    defaultIndex: index,
                  });
                }}
                onTouchStart={handleTouchDown}
                onTouchMove={handleTouchUp}
                onTouchEnd={handleTouchUp}
              >
                <img src={item} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default SelectivePosters;
