import { useEffect, useRef } from "react";

export type IntersectionEventType =
  | "enterFromTop"
  | "leaveFromTop"
  | "enterFromBottom"
  | "leaveFromBottom";

const useIntersect = (
  dom: any,
  threshold = 0,
  callback: (type: IntersectionEventType) => void,
  top = 0,
  bottom = 0,
) => {
  const status = useRef<IntersectionEventType>();

  useEffect(() => {
    if (!dom) {
      return;
    }

    const getParentScrollView = (element: HTMLElement) => {
      if (!element) {
        return null;
      }
      const parent = element.parentElement;
      if (parent === document.body || parent === null) {
        return null;
      }
      if (getComputedStyle(parent).overflowY === "scroll") {
        return parent;
      }
      return getParentScrollView(parent);
    };

    const root = getParentScrollView(dom);

    let ratio = 1;
    if (document?.querySelectorAll("html")?.[0]) {
      ratio = parseFloat(getComputedStyle(document.querySelectorAll("html")[0]).fontSize) / 50;
    }
    const topDiff = ratio * top;
    const bottomDiff = ratio * bottom;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (
          entry.isIntersecting &&
          (entry.boundingClientRect.top + topDiff < 0 || status.current === "leaveFromTop")
        ) {
          callback("enterFromTop");
          status.current = "enterFromTop";
          return;
        } else if (!entry.isIntersecting && entry.boundingClientRect.top + topDiff < 0) {
          callback("leaveFromTop");
          status.current = "leaveFromTop";
          return;
        } else if (
          entry.isIntersecting &&
          (entry.boundingClientRect.bottom + bottomDiff > window.innerHeight ||
            status.current === "leaveFromBottom")
        ) {
          callback("enterFromBottom");
          status.current = "enterFromBottom";
          return;
        } else if (
          !entry.isIntersecting &&
          entry.boundingClientRect.bottom + bottomDiff > window.innerHeight
        ) {
          callback("leaveFromBottom");
          status.current = "leaveFromBottom";
          return;
        }
      },
      { root, threshold, rootMargin: `${topDiff}px 0px ${bottomDiff}px 0px` },
    );

    observer.observe(dom);

    return () => {
      observer.unobserve(dom);
      observer.disconnect();
    };
  }, [dom, callback]);
};

export default useIntersect;
