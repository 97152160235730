import { Image } from "antd-mobile";
import React from "react";
import styles from "./index.module.less";
type Props = {
  url?: string;
  onImgClick?: () => void;
};
const ItemImgComp: React.FC<Props> = ({ url, onImgClick }) => {
  return (
    <div className={styles.resItemImg} onClick={onImgClick}>
      <Image className={styles.img} src={url} fit="contain" />
    </div>
  );
};

export default ItemImgComp;
