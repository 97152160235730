import { getCurPageClientHeight, getCurPageClientWidth } from "@/utils/getStyle";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
const Navigation: React.FC = () => {
  const [showTopBox, setShowTopBox] = useState(false);
  const scrollHandle = () => {
    const scrollTop = document.getElementById("material")?.scrollTop || 0;
    if (scrollTop > getCurPageClientHeight()) {
      setShowTopBox(true);
    } else {
      setShowTopBox(false);
    }
  };

  useEffect(() => {
    const throttleEvent = throttle(scrollHandle, 500);
    window.addEventListener("scroll", throttleEvent, true);
    return () => {
      window.removeEventListener("scroll", throttleEvent, true);
    };
  }, []);

  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const [left, setLeft] = useState(15);
  const [bottom, setBottom] = useState(60);

  const handleTouchStart = (e) => {
    const { clientX, clientY } = e.touches[0];
    startX.current = clientX;
    startY.current = clientY;
    isDragging.current = true;
  };

  const handleTouchMove = (e) => {
    // 如果没有处于拖拽状态，则直接返回
    if (!isDragging.current) {
      return;
    }

    const { clientX, clientY } = e.touches[0];
    const deltaX = clientX - startX.current;
    const deltaY = startY.current - clientY;

    setLeft((prevLeft) => Math.min(getCurPageClientWidth() - 60, Math.max(15, prevLeft + deltaX)));
    setBottom((prevTop) =>
      Math.min(getCurPageClientHeight() - 110, Math.max(55, prevTop + deltaY)),
    );

    startX.current = clientX;
    startY.current = clientY;
  };
  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  const toTop = useCallback(() => {
    document.getElementById("material")?.scrollTo(0, 0);
    setShowTopBox(false);
  }, []);

  return (
    <div
      className={styles.navigationWrap}
      // onTouchStart={handleTouchStart}
      // onTouchMove={handleTouchMove}
      // onTouchEnd={handleTouchEnd}
      // onTouchCancel={handleTouchEnd}
      style={{ right: left, bottom }}
    >
      {!!showTopBox && (
        <img
          className={styles.img}
          onClick={toTop}
          src="https://akim-oss.aikucun.com/8d3eb2bc64e50dedf127c757cabc1456fae5de47_1706763043738_86.png"
        />
      )}
    </div>
  );
};
export default Navigation;
