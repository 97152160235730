import { getBaseurl } from "@/utils/url-parse";
import { Request, Response } from "../request";
import { NormalScodeParams, PosterBizInfo } from "./scode_params";

const staticUrl = getBaseurl();

export type WritingItem = {
  type: string;
  copyWritings: Array<string>;
};

// 复制转发文案（新版聚合素材、AI）
// https://yapi.akcops.com/project/1911/interface/api/402441
export const fetchCopyWriting = (data: any): Promise<Response<Array<WritingItem>>> => {
  return Request({
    url: `${staticUrl}/api/share/unity/prod/union/copyAggroWriting`,
    method: "post",
    data: {
      ...data,
      contentContext: {
        ...data.contentContext,
        contentType: data.bizInfo.contentType,
        contentId: data.bizInfo.contentId,
      },
    },
  });
};
