import useNavigation from "@/utils/useNavigate";
import { ProductDetailModel, ProductModel } from "@akc/biz-api";
import { CartPanelComp } from "@akc/mx-taro-h5-components";
import { ImageViewer, Popup } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

type AddPurchasePanelProps = {
  visible?: boolean;
  product?: ProductModel | ProductDetailModel | any;
  //指定按钮类型时传入 0:确定 1:还想买 2:加入购物车 3:立即购买 4:提醒 5:仅限饷店购买 6:加入购物车和立即购买 7:立即兑换
  buttonType?: number;
  //某些场景下需要显示传入的文案，例如:商详底部点击加入购物车和立即购买，面板底部按钮都显示确认
  buttonName?: string;
  trackData?: {
    pageName?: string;
    domain?: string;
  };
  close?: (params?: { skuId?: string }) => void;
  confirm?: (params: { type?: number; skuId?: string }) => void;
};

//加购面板
const AddPurchasePanel: React.FC<AddPurchasePanelProps> = (props) => {
  const [search] = useSearchParams();
  const { navigate2OrderConfrim } = useNavigation();
  const product = props.product;
  const [preViewProductImgVisible, setPreViewProductImgVisible] = useState<boolean>(false);
  const [productPicList, setProductPicList] = useState<string[]>();
  const [cartPanelParams, setCartPanelParams] = useState<any>();

  // 业务回调参数，用于点击阴影部分时回调
  const bizResult = useRef<any>();

  //关闭
  const onClose = () => {
    console.log("onClose", bizResult.current?.selectSkuIds?.[0]);
    props.close && props.close({ skuId: bizResult.current?.selectSkuIds?.[0] });
  };

  useEffect(() => {
    //按钮类型
    const getButtonType = () => {
      if (product?.activitySimpleVO?.status === 1) {
        return 2;
      }
      if (product?.pricePanelType === 13 || product?.pricePanelType2 === 7) {
        return 7;
      }
      if (
        product?.skuExtendInfoVO?.buyShoppingCartModel &&
        product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 6;
      }
      if (
        !product?.skuExtendInfoVO?.buyShoppingCartModel &&
        product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 3;
      }
      if (
        product?.skuExtendInfoVO?.buyShoppingCartModel &&
        !product?.skuExtendInfoVO?.buyDirectModel
      ) {
        return 2;
      }
    };

    //是否可以购买
    const getBuyEnable = () => {
      return product?.skuExtendInfoVO?.allowDeliver;
    };

    //是否不可以加购
    const getCartDisabled = () => {
      // 海淘预告不可以加购
      return product?.activitySimpleVO?.status === 1 && product?.skuExtendInfoVO?.isCrossBorder;
    };

    if (props.visible && props.product) {
      console.log(
        "组装面板参数",
        product?.firstPropertyValue || product?.skuBaseInfoVO?.firstSalePropertyValue,
      );
      setCartPanelParams({
        activitySpuId: product?.activitySpuId,
        activityId: product?.activityId,
        firstSalePropertyValue:
          product?.firstPropertyValue || product?.skuBaseInfoVO?.firstSalePropertyValue,
        secondSalePropertyValue:
          product?.secondPropertyValue || product?.skuBaseInfoVO?.secondSalePropertyValue,
        pricePanelType: product?.pricePanelType,
        pricePanelType2: product?.pricePanelType2,
        page_name: props?.trackData?.pageName,
        domain: props?.trackData?.domain,
        brandId: product?.brandInfo?.brandId,
        sizeUrl: product?.spuInfoVO?.sizeUrl,
        buttonType: props?.buttonType || getButtonType(),
        buttonName: props?.buttonName,
        buyEnable: getBuyEnable(),
        cartDisabled: getCartDisabled(),
        isBuyDirectModel: product?.skuExtendInfoVO?.onlyDirectModel,
        liveNo: search.get("liveNo") || "",
        shopId: search.get("shopId") || "",
        awdInstanceId: search.get("awdInstanceId") || "",
        promoActivityId: search.get("promoActivityId") || "",
        refreshTime: new Date().getTime(),
        isVirtual: product?.skuExtendInfoVO?.isVirtual,
      });
    }
  }, [props.product, props.buttonType]);

  // 回调
  const doActionCallback = async (params?: any) => {
    console.log("doActionCallback", params?.bizResult);
    switch (params?.type) {
      case 0: {
        onClose();
        break;
      }
      case 8: {
        bizResult.current = params?.bizResult;
        break;
      }
      case 1: {
        setProductPicList(params?.skipResult?.urls ?? []);
        setPreViewProductImgVisible(true);
        break;
      }
      case 5: {
        props?.confirm?.({
          type: params?.bizResult?.buttonType,
          skuId: params?.bizResult?.selectSkuIds?.[0],
        });
        onClose();
        break;
      }
      case 9: {
        const skuNo = params?.skipResult?.selectSkuId;
        const quantity = params?.skipResult?.productCount;
        const activityNo = product.activityId;
        const spuId = product.activitySpuId;
        const virtualRechargeType = product?.skuExtendInfoVO?.virtualRechargeType || "";
        props?.confirm?.({
          type: params?.bizResult?.buttonType,
          skuId: params?.bizResult?.selectSkuIds?.[0],
        });
        onClose();
        navigate2OrderConfrim({
          miniQuery: {
            skuNo: skuNo,
            quantity,
            virtualRechargeType: virtualRechargeType,
            activityNo: activityNo,
            spuId: spuId,
          },
          h5Query: {
            skuNo,
            num: quantity,
            directcChargeType: virtualRechargeType,
            activityNo,
            spuId,
            liveRoomNo: search.get("liveNo") || "",
            isBusinessVenue: search.get("isBusinessVenue") || "",
          },
        });
        break;
      }
    }
  };

  //底部安全区域高度
  function getSafeAreaInsetBottom() {
    const bodyStyle = window.getComputedStyle(document.body);
    return parseFloat(bodyStyle.getPropertyValue("--safe-area-inset-bottom")) || 10;
  }

  return (
    <React.Fragment>
      <Popup
        style={{ "--z-index": "2000" }}
        maskStyle={{ zIndex: "1999" }}
        visible={props.visible}
        onMaskClick={onClose}
        bodyStyle={{ backgroundColor: "transparent" }}
      >
        <div className={styles.addPurchasePanel}>
          <CartPanelComp
            style={{ "padding-bottom": getSafeAreaInsetBottom() }}
            {...cartPanelParams}
            onPurchaseCallback={(params?: any) => {
              doActionCallback(params);
            }}
          />
        </div>
      </Popup>
      <ImageViewer.Multi
        classNames={{ mask: styles.imageMask }}
        getContainer={document.body}
        images={productPicList}
        visible={preViewProductImgVisible}
        defaultIndex={0}
        onClose={() => {
          setPreViewProductImgVisible(false);
        }}
      />
    </React.Fragment>
  );
};

export default AddPurchasePanel;
