import { Request } from "./request";
const base = process.env.REACT_APP_CS_API_URL;

// 获取基本信息
export function queryNewCsWhitelist(data?: any) {
  return Request({
    url: `${base}/aggregate/api/v1.0/cschat/newCsWhitelist?distributorId=`,
    method: "post",
    data,
  });
}
