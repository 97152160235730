import { getCartTotal, isSwitchBatch } from "@/api";
import Cart from "@/pages/personal/components/float-cart";
import Track from "@/utils/track";
import React, { useEffect, useState } from "react";
import { useActivate } from "react-activation";
import { useSearchParams } from "react-router-dom";
import Personal from "../personal/index";
import Admin from "./components/admin-index";
import ShopOwner from "./components/shop-index";
import { UserInfoProvider, UseUserInfoContext } from "./hooks/useUserInfo";
import styles from "./index.module.less";

const Container = () => {
  const [searchParams] = useSearchParams();
  const shopId = searchParams.get("shopId") ?? "";
  const { userInfo } = UseUserInfoContext();
  const [cartVisible, setCartVisible] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const queryCartCount = async () => {
    const { data } = await getCartTotal({ shopBizCode: shopId });
    setCartCount(data?.cartSkuNum ?? 0);
  };
  const queryCartVisible = async () => {
    const res = await isSwitchBatch({ bwlCodes: ["B-HomePageRefatorH5"] });
    const visible = !res?.data[0]?.isHit;
    setCartVisible(visible);
    visible && queryCartCount();
  };
  useEffect(() => {
    queryCartVisible();
    document.title = "我的";
  }, [userInfo]);
  useActivate(() => {
    queryCartVisible();
  });
  return (
    <div className={styles.myPage} id="my-page">
      {userInfo?.currentRole === 4 && <Admin />}
      {userInfo?.currentRole === 3 && !userInfo?.distributorDisable && <ShopOwner />}
      {(userInfo?.currentRole === 1 ||
        (userInfo?.currentRole === 3 && !!userInfo?.distributorDisable)) && (
        <Personal cartChange={queryCartCount} />
      )}
      {cartVisible && <Cart cartCount={cartCount} />}
    </div>
  );
};

const My: React.FC = () => {
  const track = Track();
  useEffect(() => {
    document.title = "我的";
    track.track("$page_view", {
      page_name: "我的",
      domain: "我的",
    });
  }, []);
  useActivate(() => {
    document.title = "我的";
  });
  return (
    <UserInfoProvider>
      <Container />
    </UserInfoProvider>
  );
};

export default My;
