import ProductWaterfall from "@/components/product-waterfall";
import { hostEnv } from "@/utils/platform";
import React, { useEffect } from "react";
import { useHasNext, useLoadMore, useProductList } from "../hooks/useProduct";

interface Props {
  setCardList?: (any) => void;
  trackData?: any;
}

const ProductList: React.FC<Props> = ({ setCardList, trackData }) => {
  const productList = useProductList();
  const hasNext = useHasNext();
  const loadMore = useLoadMore();

  useEffect(() => {
    setCardList && setCardList(productList || []);
  }, [productList]);

  return (
    <>
      {productList?.length > 0 && (
        <ProductWaterfall
          productList={productList}
          hasNext={hasNext}
          loadMore={loadMore}
          provideBackground={false}
          isOuterMargin={true}
          topRightLocationCode={`${hostEnv}_Shop_ProductList_ProductPicRight`}
          titleFrontLocationCode={`${hostEnv}_Shop_ProductList_TitleFront`}
          titleBottomLocationCode={`${hostEnv}_Shop_ProductList_TitleDown`}
          trackData={trackData}
        />
      )}
    </>
  );
};

export default ProductList;
