import { getActivityListServ } from "@/api/appH5";
import { hostEnv } from "@/utils/platform";
import { ActivityCardNormalV2Comp } from "@akc/mx-taro-h5-components";
import { InfiniteScroll } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./ActivityList.module.less";
// import { useHasNext, useLoadMore, useActivityList } from "../hooks/useActivity";
interface Props {
  setCardList?: (any) => void;
  trackData?: any;
}

const ActivityList: React.FC<Props> = ({ setCardList, trackData }) => {
  // const activityList = useActivityList();
  // const hasNext = useHasNext();
  // const loadMore = useLoadMore();
  // console.error("ActivityList——", {
  //   loadMore,
  //   hasNext,
  //   activityList,
  // });

  const [search] = useSearchParams();
  const actId = search.get("actId") || "";

  const [activityList, setActivityList] = useState<any[]>([]);
  const currentPageNum = useRef<number>(1);
  const currentRequestId = useRef(0);
  const [hasNext, setHasNext] = useState(true);
  const isLoading = useRef(false);

  const updateActivityList = async (requestId: number) => {
    if (!search.get("actId")) {
      return;
    }
    isLoading.current = true;
    const res = await getActivityListServ({
      pageId: `${hostEnv}_Shop`,
      searchParams: {
        pageNum: currentPageNum.current,
        pageSize: 10,
        promoActivityIds: [actId],
        searchRequestId: requestId,
        scene: "SALES_REBATE_PAGE",
      },
    });
    if (requestId !== currentRequestId.current) {
      return;
    }
    setHasNext(res.hasNext);
    let list = (activityList) => {
      return [
        ...activityList,
        ...(res.result?.map((item, index) => ({
          ...item,
          rank: activityList.length + index,
        })) ?? []),
      ];
    };
    setActivityList(list);
    setCardList && setCardList(list);
    currentPageNum.current = res.pageIndex + 1;
    isLoading.current = false;
  };

  const loadMore = async () => {
    if (typeof currentPageNum.current === "undefined" || isLoading.current) {
      return;
    }
    currentRequestId.current = Math.floor(Math.random() * 10000000000);
    await updateActivityList(currentRequestId.current);
  };

  useEffect(() => {
    loadMore();
  }, []);

  return (
    <div className={styles["activity-list-wrap"]}>
      {activityList?.length > 0 &&
        activityList.map((item, index) => (
          <ActivityCardNormalV2Comp
            model={item}
            key={index}
            mainLocationCode={`${hostEnv}_Shop_ScheduleList_BrandDown`}
            productTLLocationCode={`${hostEnv}_Shop_ScheduleList_TopProductPicLeft`}
            productTRLocationCode={`${hostEnv}_Shop_ScheduleList_TopProductPicRight`}
            style={{ margin: "0 0 8px 0" }}
            trackData={trackData}
          />
        ))}
      <InfiniteScroll loadMore={loadMore} hasMore={hasNext} />
    </div>
  );
};

export default ActivityList;
