import ProductWaterfall from "@/components/product-waterfall";
import React from "react";

interface Props {
  prodArr: any[];
  handleSearch: () => Promise<void>;
  secondResourceClick: (item: any) => void;
  hasNext: boolean;
  [key: string]: any;
}
const Product: React.FC<Props> = ({ prodArr, handleSearch, hasNext, secondResourceClick }) => {
  return <ProductWaterfall productList={prodArr} hasNext={hasNext} loadMore={handleSearch} />;
};

export default Product;
