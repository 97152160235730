import { getCustomerForward } from "@/api/my/shop";
import Track from "@/utils/track";
import useNavigation, { IOptions } from "@/utils/useNavigate";
import React, { useEffect, useState } from "react";
import styles from "./index.module.less";

const ShopCustomerTrack: React.FC = () => {
  const [customerList, setCustomerList] = useState<any>([]);
  const { navigate2MyItem } = useNavigation();
  const { navigate2H5 } = useNavigation();
  const track = Track();

  const queryCustomerForward = async () => {
    const data = await getCustomerForward();
    setCustomerList(data);
  };

  const goTransRecord = () => {
    navigate2MyItem("/#/analysis/transRecord");
    track.track("btn_click", {
      page_name: "我的",
      module: "顾客浏览商品统计",
      resource_type: "顾客浏览商品统计",
      btn_name: "查看全部",
    });
  };

  const goHome = () => {
    const options: IOptions = {
      path: "/mx-shop-micro/home",
      is_micro_app: true,
      is_replace: true,
    };
    navigate2H5(options);
    track.track("btn_click", {
      page_name: "我的",
      module: "顾客浏览商品统计",
      btn_name: "去选品",
    });
  };

  useEffect(() => {
    queryCustomerForward();
  }, []);

  return (
    <div className={styles.shopCustomerTrack}>
      <div className={styles.titleHeader}>
        <div className={styles.titleLeft}>顾客浏览商品统计</div>
        <div className={styles.titleRight} onClick={goTransRecord}>
          查看全部
          <i className={styles.arrow} />
        </div>
      </div>
      {customerList.length > 0 &&
        customerList.slice(0, 2).map((item, index) => {
          return (
            <div className={styles.customerItem} key={index}>
              <img src={item.activityLogo} alt="" />
              <div className={styles.itemRight}>
                <div className={styles.itemRightTop}>{item.activityName}</div>
                <div className={styles.itemRightBottom}>
                  <span>{item.visitorsCount}</span>人访问&nbsp;&nbsp;<span>{item.orderCount}</span>
                  件成交
                </div>
              </div>
            </div>
          );
        })}
      {customerList.length === 0 && (
        <div className={styles.customerEmpty}>
          <p>当日暂无顾客浏览，快去选品转发吧~</p>
          <div className={styles.homeBtn} onClick={goHome}>
            去选品
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopCustomerTrack;
