import { project } from "@/utils/project";
import { pxtorem } from "@/utils/pxtorem";
import { CSSProperties } from "react";

type ImageOptions = {
  width: number;
  class: string;
  spaceBetween: number;
  styles: CSSProperties;
};

const imageOptionsMap: { [key: string]: ImageOptions } = {
  ACTIVITY: {
    width: 300,
    class: "act-width",
    spaceBetween: 10,
    styles: {
      width: pxtorem(305),
      height: pxtorem(380),
    },
  },
  ACTIVITY_AGGREGATION: {
    width: 250,
    class: "act-agg-width",
    spaceBetween: 10,
    styles: {
      width: pxtorem(250),
    },
  },
  CONFERENCE3: {
    width: 300,
    class: project() === 2 ? "shop-width" : "conference-width",
    spaceBetween: 10,
    styles: {
      width: pxtorem(305),
      height: pxtorem(380),
    },
  },
  PRODUCT: {
    width: 225,
    class: "pro-width",
    spaceBetween: -80,
    styles: {
      width: pxtorem(260),
      height: pxtorem(475),
    },
  },
  XPOINT_HOME: {
    width: 283,
    class: "xpoint-width",
    spaceBetween: 0,
    styles: {
      width: pxtorem(283),
      height: pxtorem(479),
    },
  },
  SHARE_CATEGORY: {
    width: 300,
    class: "xpoint-width",
    spaceBetween: 10,
    styles: {
      width: pxtorem(305),
      height: pxtorem(380),
    },
  },
  SHARE_BRAND: {
    width: 300,
    class: "xpoint-width",
    spaceBetween: 10,
    styles: {
      width: pxtorem(305),
      height: pxtorem(380),
    },
  },
};

export const calcImgWidthOptions = (shareType: string): ImageOptions => {
  return imageOptionsMap[shareType] || { width: 0, class: "" };
};
