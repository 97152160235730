import { ReactComponent as ArrowRightIcon } from "@/assets/arrow_right.svg";
import { ActivityBrandModel } from "@akc/biz-api";
import { DotLoading, Input, Popup, Toast } from "antd-mobile";
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  useIncludeSaleout,
  useIsCounting,
  useOptions,
  useProductCount,
  useResets,
  useToggles,
} from "../hooks/useFilterOption";
import AllBrand from "./AllBrand";
import AllCategory from "./AllCategory";
import styles from "./FilterBoard.module.less";

type Props = {
  brandData?: ActivityBrandModel;
  brandIds?: string[];
  promotionIdList?: string[];
  categoryIdList?: string[];
  onFilterChange?: (any) => void;
  ref: ForwardedRef<any>;
};

const FilterBoard: React.FC<Props> = forwardRef(
  ({ brandIds, promotionIdList, categoryIdList, onFilterChange }, ref) => {
    const productCount = useProductCount();
    const isCouting = useIsCounting();
    const includeSaleout = useIncludeSaleout();

    const [editingMinPrice, setEditingMinPrice] = useState<string>();
    const [editingMaxPrice, setEditingMaxPrice] = useState<string>();
    const [allCategoryBoardVisible, setAllCategoryBoardVisible] = React.useState(false);
    const [salePropertyExpandedList, setSalePropertyExpandedList] = useState<Array<boolean>>([]);

    const [allBrandBoardVisible, setAllBrandBoardVisible] = React.useState(false);
    const [allBrandBoardShown, setAllBrandBoardShown] = React.useState(false);

    const {
      serviceList,
      boardCategoryList,
      selectedChildCategoryList,
      brandList,
      propertyList,
      promotionList,
      activityStatusList,
      selectedBrandList,
    } = useOptions();

    useEffect(() => {
      if (propertyList) {
        setSalePropertyExpandedList((oldList) => {
          if (oldList.length > 0) {
            return [...oldList];
          } else {
            return new Array(propertyList.length).fill(false);
          }
        });
      }
    }, [propertyList]);

    useEffect(() => {
      if (productCount === "0") {
        Toast.show("暂无匹配商品，请更换筛选项");
      }
    }, [productCount]);

    const {
      toggleSaleout,
      toggleService,
      toggleCategory,
      toggleBrand,
      toggleProperty,
      togglePromotion,
      toggleActivity,
      setPrice,
      constructParams,
    } = useToggles();

    const { resetFilter } = useResets();

    const reset = () => {
      resetFilter();
      setEditingMinPrice("");
      setEditingMaxPrice("");
    };

    useImperativeHandle(ref, () => ({
      reset,
    }));

    const onChangePrice = () => {
      let newMinPrice: number | undefined = undefined;
      let newMaxPrice: number | undefined = undefined;
      if (editingMinPrice !== "") {
        newMinPrice = Number(editingMinPrice);
      }
      if (editingMaxPrice !== "") {
        newMaxPrice = Number(editingMaxPrice);
      }
      if (newMinPrice !== undefined && newMaxPrice !== undefined && newMinPrice > newMaxPrice) {
        setEditingMinPrice(String(newMaxPrice));
        setEditingMaxPrice(String(newMinPrice));
        let tmp = newMaxPrice;
        newMaxPrice = newMinPrice;
        newMinPrice = tmp;
      }
      setPrice(newMinPrice, newMaxPrice);
    };

    const minPriceInputRef = useRef<any>(null);
    const maxPriceInputRef = useRef<any>(null);

    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}>全部筛选</div>
          <div className={styles.body}>
            <div className={styles.subtitle}>服务</div>
            <div className={styles.items}>
              <div
                className={`${styles.item} ${includeSaleout ? "" : styles.active}`}
                onClick={() => toggleSaleout()}
              >
                仅看有货
              </div>
              {serviceList.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.item} ${item.active ? styles.active : ""}`}
                  onClick={() => toggleService(item)}
                >
                  <span className={styles.text}>{item.name}</span>
                </div>
              ))}
            </div>

            {boardCategoryList.length > 0 && !categoryIdList && (
              <>
                <div className={styles.subtitle}>
                  <span>分类</span>
                  <span
                    onClick={() => setAllCategoryBoardVisible(true)}
                    className={`${styles.seeAll} ${
                      selectedChildCategoryList.length > 0 ? styles.active : ""
                    }`}
                  >
                    <span>
                      {selectedChildCategoryList
                        .map((i) => i?.name)
                        .slice(0, 3)
                        .join("、")}
                    </span>
                    {selectedChildCategoryList.length > 3 && <span>...</span>}
                    {selectedChildCategoryList.length === 0 && <span>查看全部</span>}
                    <ArrowRightIcon className={styles.arrowRightIcon} />
                  </span>
                </div>
                <div className={styles.items}>
                  {boardCategoryList.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.item} ${item.active ? styles.active : ""}`}
                      onClick={() => toggleCategory(item)}
                    >
                      <span className={styles.text}>{item.name}</span>
                    </div>
                  ))}
                </div>
              </>
            )}

            {brandList.length > 0 && !brandIds && (
              <>
                <div className={styles.subtitle}>
                  <span>品牌</span>
                  {brandList.length > 9 && (
                    <span
                      onClick={() => setAllBrandBoardVisible(true)}
                      className={`${styles.seeAll} ${
                        selectedBrandList.length > 0 ? styles.active : ""
                      }`}
                    >
                      <span>
                        {selectedBrandList
                          .map((i) => i.name)
                          .slice(0, 3)
                          .join("、")}
                      </span>
                      {selectedBrandList.length > 3 && <span>...</span>}
                      {selectedBrandList.length === 0 && <span>查看全部</span>}
                      <ArrowRightIcon className={styles.arrowRightIcon} />
                    </span>
                  )}
                </div>
                <div className={styles.items}>
                  {brandList.slice(0, 9).map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.item} ${styles.brandItem} ${
                        item.active ? styles.active : ""
                      }`}
                      onClick={() => toggleBrand(item)}
                    >
                      <span
                        className={styles.brandLogoWrapper}
                        style={{ backgroundImage: `url(${item.linkedObj.brandLogo})` }}
                      ></span>
                      <span className={styles.text}>{item.name}</span>
                    </div>
                  ))}
                </div>
              </>
            )}

            {propertyList.map((item, index) => (
              <div key={index}>
                <div className={styles.subtitle}>{item.name}</div>
                <div className={styles.items}>
                  {item.children
                    ?.slice(
                      0,
                      salePropertyExpandedList[index]
                        ? undefined
                        : item.children.length === 9
                          ? 9
                          : 8,
                    )
                    ?.map((innerItem, innerIndex) => (
                      <div
                        key={`innerIndex-${innerIndex}`}
                        className={`${styles.item} ${innerItem.active ? styles.active : ""}`}
                        onClick={() => toggleProperty(innerItem)}
                      >
                        <span className={styles.text}>{innerItem.name}</span>
                      </div>
                    ))}

                  {item.children && item.children?.length > 9 && (
                    <div
                      className={styles.item}
                      onClick={() => {
                        setSalePropertyExpandedList((oldList) => {
                          return oldList.map((oldItem, i) => {
                            if (i === index) {
                              return !oldItem;
                            } else {
                              return oldItem;
                            }
                          });
                        });
                      }}
                    >
                      <span>{salePropertyExpandedList[index] ? "收起" : "更多"}</span>
                      <img
                        className={styles.icon}
                        src={
                          salePropertyExpandedList[index]
                            ? "https://akim-oss.aikucun.com/mshop/87b89b94f855864eb916fedb61856ef8b4a1e514_1673256228593_28.png"
                            : "https://akim-oss.aikucun.com/mshop/5329c64b7860ecd323c3743fb1517b14e9be088a_1673255755016_41.png"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}

            {promotionList.length > 0 && !promotionIdList && (
              <>
                <div className={styles.subtitle}>
                  <span>促销</span>{" "}
                </div>
                <div className={styles.items}>
                  {promotionList.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.item} ${item.active ? styles.active : ""}`}
                      onClick={() => togglePromotion(item)}
                    >
                      <span className={styles.text}>{item.name}</span>
                    </div>
                  ))}
                </div>
              </>
            )}

            {activityStatusList.length > 0 && (
              <>
                <div className={styles.subtitle}>
                  <span>商品类型</span>{" "}
                </div>
                <div className={styles.items}>
                  {activityStatusList.map((item, index) => (
                    <div
                      key={index}
                      className={`${styles.item} ${item.active ? styles.active : ""}`}
                      onClick={() => toggleActivity(item)}
                    >
                      <span className={styles.text}>{item.name}</span>
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className={styles.subtitle}>价格区间(元)</div>
            <div className={styles.priceRange}>
              <div className={styles.inputWrapper} onClick={() => minPriceInputRef.current.focus()}>
                <Input
                  ref={minPriceInputRef}
                  value={editingMinPrice}
                  type="number"
                  pattern="[0-9]*"
                  className={`${styles.input} ${
                    typeof editingMinPrice !== "undefined" && editingMinPrice !== ""
                      ? styles.hasValue
                      : ""
                  }`}
                  placeholder="最低价"
                  onChange={(value) => {
                    setEditingMinPrice(value);
                  }}
                  onBlur={onChangePrice}
                />
              </div>
              <div className={styles.seperator}></div>
              <div className={styles.inputWrapper} onClick={() => maxPriceInputRef.current.focus()}>
                <Input
                  ref={maxPriceInputRef}
                  value={editingMaxPrice}
                  type="number"
                  pattern="[0-9]*"
                  className={`${styles.input} ${
                    typeof editingMaxPrice !== "undefined" && editingMaxPrice !== ""
                      ? styles.hasValue
                      : ""
                  }`}
                  placeholder="最高价"
                  onChange={(value) => {
                    setEditingMaxPrice(value);
                  }}
                  onBlur={onChangePrice}
                />
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.reset} onClick={reset}>
              重置
            </div>
            <div
              className={styles.confirm}
              onClick={() => onFilterChange?.(constructParams({}, false))}
            >
              确定({!isCouting && <span>{productCount}款商品</span>}
              {isCouting && (
                <span>
                  计算中
                  <DotLoading color="currentColor" />
                </span>
              )}
              )
            </div>
          </div>
        </div>
        <Popup
          visible={allCategoryBoardVisible}
          showCloseButton
          onMaskClick={() => setAllCategoryBoardVisible(false)}
          onClose={() => setAllCategoryBoardVisible(false)}
          bodyStyle={{ height: "90vh" }}
        >
          <AllCategory onClose={() => setAllCategoryBoardVisible(false)} />
        </Popup>
        <Popup
          visible={allBrandBoardVisible}
          showCloseButton
          onMaskClick={() => setAllBrandBoardVisible(false)}
          onClose={() => setAllBrandBoardVisible(false)}
          afterShow={() => setAllBrandBoardShown(true)}
          bodyStyle={{ height: "90vh" }}
        >
          {allBrandBoardShown && <AllBrand onClose={() => setAllBrandBoardVisible(false)} />}
        </Popup>
      </>
    );
  },
);

export default FilterBoard;
