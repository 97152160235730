import { queryHideInfoServ, setHideInfoServ } from "@/api/my/shop";
import loadingPng from "@/base64";
import Bottom from "@/components/bottom/index";
import HidePopup from "@/components/hide-popup";
import { onShareAppMessageByHome } from "@/utils/actions";
import { platform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import AKJS from "@akc/akjs";
import { Dialog, Image, InfiniteScroll, Toast } from "antd-mobile";
import React, { useEffect, useRef, useState } from "react";
import BrandItem from "./components/BrandItem";
import CategoryItem from "./components/CategoryItem";
import ShopItem from "./components/ShopItem";
import TopBar from "./components/TopBar";
import styles from "./search.module.less";

const HideSetting: React.FC = () => {
  const tabList = [
    {
      key: "brand",
      value: "品牌",
      tips: "品牌隐藏后，用户进店将看不到此品牌活动，以及品牌下对应的商品。若想再次售卖被隐藏的商品，可点击【取消隐藏】",
      removeHideDialog: {
        title: "取消隐藏",
        content: "请确认是否要取消隐藏该品牌？",
      },
    },
    {
      key: "shop",
      value: "店铺",
      tips: "店铺隐藏后，用户进店将看不到店铺入口以及店铺下的常销商品。若想再次售卖被隐藏的商品或显示出店铺，可点击【取消隐藏】",
      addHideDialog: {
        title: "隐藏店铺",
        content: "隐藏后，用户将看不到对应店铺以及店铺下的常销商品，请确认是否隐藏？",
      },
      removeHideDialog: {
        title: "取消隐藏",
        content: "请确认是否要取消隐藏该店铺？",
      },
    },
  ];
  const [tabIndex, setTabIndex] = useState<number>(0);
  let [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [list, setList] = useState<any[]>([]);
  const [item, setItem] = useState<any>({});
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [pagePaddingTop, setPagePaddingTop] = useState<number>(0);

  const TopBarRef = useRef<any>(null);

  const getList = (params) => {
    setLoading(true);
    queryHideInfoServ({
      pageIndex,
      pageSize,
      hideType: tabList[tabIndex].key,
      ...params,
      queryName: TopBarRef?.current?.inputVal || "",
    })
      .then((res) => {
        const { code, success, data, message } = res || {};
        if (code === "00000" && success) {
          setList(data.pageIndex > 1 ? list.concat(data.result) : data.result);
          setHasNext(data.pageIndex < data.pages);
          setPageIndex(data.pageIndex);
        } else {
          Toast.show({
            content: message || "数据加载失败",
            maskClickable: false,
            position: "center",
            maskStyle: { zIndex: 9999 },
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeTabIndex = (e, index: number) => {
    e.stopPropagation();
    setTabIndex(index);
    setHasNext(false);
    setList([]);
    getList({
      pageIndex: 1,
      hideType: tabList[index].key,
    });
    const dom = document?.getElementById("page");
    if (dom) {
      dom.scrollTop = 0;
    }
  };

  const loadMore = async () => {
    if (hasNext && !loading) {
      pageIndex++;
      getList({
        pageIndex,
      });
    }
  };

  const setHideInfo = (curItem, isTogger = false) => {
    setHideInfoServ({
      hideAction: curItem.newHideType ? "hide" : curItem.isHide ? "cancel_hide" : "hide",
      hideType: curItem.newHideType || curItem.hideType,
      bizNo: curItem.bizNo,
    }).then((res) => {
      const { code, success, data, message } = res || {};
      if (code === "00000" && success) {
        Toast.show({
          content: isTogger ? "修改成功" : curItem.isHide ? "已成功取消隐藏" : "隐藏成功",
          duration: 500,
          maskClickable: false,
          position: "center",
          maskStyle: { zIndex: 9999 },
        });
        if (data) {
          const key = tabList[tabIndex].key;
          if (key === "category") {
            setList(
              list.map((item) => {
                if (item.bizNo === curItem.bizNo) {
                  return {
                    ...item,
                    isHide: !item.isHide,
                  };
                }
                return item;
              }),
            );
          } else if (isTogger) {
            setList(
              list.map((item) => {
                if (item.bizNo === curItem.bizNo) {
                  let obj = {
                    ...curItem,
                    hideType: curItem.newHideType,
                  };
                  delete obj.newHideType;
                  return obj;
                }
                return item;
              }),
            );
          } else if (curItem.isHide) {
            setList(
              list.filter((item) => {
                return item.bizNo !== curItem.bizNo;
              }),
            );
          }
        }
      } else {
        Toast.show({
          content: message || "数据加载失败",
          maskClickable: false,
          position: "center",
          maskStyle: { zIndex: 9999 },
        });
      }
    });
  };

  const cancelHide = (curItem) => {
    // 取消隐藏
    const modal = Dialog.show({
      title: tabList[tabIndex].removeHideDialog?.title,
      content: tabList[tabIndex].removeHideDialog?.content,
      bodyStyle: {
        textAlign: "center",
      },
      actions: [
        [
          {
            key: "close",
            text: "取消",
            style: { color: "#666" },
            onClick: () => {
              modal.close();
            },
          },
          {
            key: "confirm",
            text: "确认",
            style: { color: "#FF4747" },
            onClick: () => {
              setHideInfo(curItem);
              modal.close();
            },
          },
        ],
      ],
    });
  };

  const onConfirmAction = (curItem, isTogger?: boolean) => {
    let modal;

    switch (tabList[tabIndex].key) {
      case "brand": // 品牌
        if (isTogger || !curItem.isHide) {
          // 隐藏
          setItem(curItem);
          setShowPopup(true);
        } else {
          // 取消隐藏
          cancelHide(curItem);
        }
        break;
      case "shop": // 店铺
      case "category": // 类目
        if (curItem.isHide) {
          cancelHide(curItem);
        } else {
          // 隐藏
          modal = Dialog.show({
            title: tabList[tabIndex].addHideDialog?.title,
            content: tabList[tabIndex].addHideDialog?.content,
            bodyStyle: {
              textAlign: "center",
            },
            actions: [
              [
                {
                  key: "close",
                  text: "取消",
                  style: { color: "#666" },
                  onClick: () => {
                    modal.close();
                  },
                },
                {
                  key: "confirm",
                  text: curItem.isHide ? "取消隐藏" : "立即隐藏",
                  style: { color: "#FF4747" },
                  onClick: () => {
                    setHideInfo(curItem);
                    modal.close();
                  },
                },
              ],
            ],
          });
        }

        break;
    }
  };

  const handleHideConfirm = (hideType) => {
    setHideInfo(
      {
        ...item,
        newHideType: hideType,
      },
      true,
    );
  };

  const onSearch = (queryName) => {
    getList({
      pageIndex: 1,
      queryName,
    });
  };

  useEffect(() => {
    if (platform.isApp) {
      AKJS.ready().then(() => {
        AKJS.info.device().then((res: any) => {
          setPagePaddingTop(res?.data?.statusBarHeight || 50);
        });
      });
    }
    document.title = "隐藏设置";
    onShareAppMessageByHome();
    getList({
      pageIndex: 1,
    });
  }, []);

  return (
    <div id="page" className={styles.page}>
      <div className={styles.page__header} style={{ paddingTop: pxtorem(pagePaddingTop) }}>
        <TopBar onSearch={onSearch} ref={TopBarRef} isBackHostEnv={false} />
        <ul className={styles.header__tabs}>
          {tabList.map((item, index) => {
            return (
              <li
                className={
                  index === tabIndex
                    ? `${styles["tabs__item"]} ${styles["tabs__item--active"]}`
                    : `${styles["tabs__item"]}`
                }
                key={item.key}
                onClick={(e) => {
                  changeTabIndex(e, index);
                }}
              >
                {item.value}
              </li>
            );
          })}
        </ul>
        {/* <p className={styles.header__tips}>{tabList[tabIndex].tips}</p> */}
      </div>
      <div className={styles.page__body}>
        {loading && list.length === 0 && (
          <div className={`${styles.loading} ${styles.loadingFixed}`}>
            <Image src={loadingPng} width={pxtorem(30)}></Image>
          </div>
        )}
        <>
          {list?.length > 0 ? (
            <>
              <p className={styles.header__tips}>{tabList[tabIndex].tips}</p>
              {list.map((item, index) => {
                if (tabList[tabIndex].key === "brand") {
                  return (
                    <BrandItem
                      key={index}
                      item={item}
                      index={index}
                      onConfirmAction={onConfirmAction}
                    />
                  );
                } else if (tabList[tabIndex].key === "shop") {
                  return (
                    <ShopItem
                      key={index}
                      item={item}
                      index={index}
                      onConfirmAction={onConfirmAction}
                    />
                  );
                } else if (tabList[tabIndex].key === "category") {
                  return (
                    <CategoryItem
                      key={index}
                      item={item}
                      index={index}
                      onConfirmAction={onConfirmAction}
                    />
                  );
                }
              })}
              <InfiniteScroll loadMore={loadMore} hasMore={hasNext} threshold={0}>
                {loading && (
                  <div className={styles.loading}>
                    <Image src={loadingPng} width={pxtorem(30)}></Image>
                  </div>
                )}
                <Bottom
                  loading={loading}
                  hasMore={hasNext}
                  reachBottom={list && list?.length > 0 ? true : false}
                />
              </InfiniteScroll>
            </>
          ) : (
            !loading && (
              <div className={styles.empty}>
                <img
                  className={styles.emptyIcon}
                  src="https://akim-oss.aikucun.com/mshop/ec63eb18e254f5a136c9e2406e2595e1d84aa8f6_1719307636982_84.png"
                />
                <div>未找到相应{tabList[tabIndex].value}</div>
              </div>
            )
          )}
        </>
      </div>
      <HidePopup
        visible={showPopup}
        setVisible={setShowPopup}
        curHideType={item.hideType}
        onConfirm={handleHideConfirm}
      />
    </div>
  );
};

export default HideSetting;
