import TopNavigation from "@/components/top-navigation";
import { onClosePage } from "@/utils/page_utils";
import { platform } from "@/utils/platform";
import { Dialog } from "antd-mobile";
import React from "react";
import YxSignUpBasicInfoComp from "./components/basic-info";
import useSignup from "./hooks/useSignUp";
import styles from "./index.module.less";
const wx = window.wx;

const YouxueSignUpPage: React.FC = () => {
  const signupSuccessDialog = () => {
    const modal = Dialog.show({
      bodyStyle: {
        maxWidth: "calc(100vw - 75px)", // 设置最大宽度
        width: "300px", // 固定宽度
        margin: "0 auto", // 居中对齐
      },
      title: (
        <>
          <div style={{ textAlign: "center", fontSize: 16, color: "#222222" }}>请等待审核结果</div>
        </>
      ),
      content: (
        <>
          <div style={{ textAlign: "center", fontSize: 14, color: "#666666" }}>
            已收到您的游学报名申请，审核结果会以短信/APP站内消息提醒的方式推送，请留意您的消息通知并及时进行行程确认
          </div>
        </>
      ),
      closeOnMaskClick: false,
      actions: [
        {
          key: "confirm",
          text: "我知道了",
          bold: true,
          style: { color: "#FF4747" },
          onClick: () => {
            modal.close();
            if (platform.isMini) {
              wx.miniProgram.navigateBack({
                delta: 1,
              });
            } else {
              onClosePage({ appOptions: { markId: "youxue_calendar" } });
              onClosePage();
            }
          },
        },
      ],
    });
  };
  const { basicInfoRef, showCheckStatus, onSubmitSignUpInfo, signUpInfo } =
    useSignup(signupSuccessDialog);

  return (
    <>
      {(platform.isH5 || platform.isWxH5) && (
        <TopNavigation hideSearchIcon={true} hideCartIcon={true} hideFavoriteIcon={true} />
      )}
      <div className={styles["page"]}>
        <div className={styles["container"]}>
          <img
            className={styles["headerBg"]}
            src="https://xiangdian-oss.aikucun.com/app_akc_ios/2c80e1477b82bf26e8eb1e7b46d5e7f5c3128fbd_1717480456616_50.png"
          />
          <div className={styles["topTitle"]}>基本信息填写</div>
          <div className={styles["contentC"]}>
            <YxSignUpBasicInfoComp
              showCheckStatus={showCheckStatus}
              ref={basicInfoRef}
              memberInfo={signUpInfo}
            />
          </div>
        </div>
        <div className={styles["bottom"]}>
          <div className={styles["actionC"]} onClick={onSubmitSignUpInfo}>
            <div className={styles["actionTv"]}>提交</div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YouxueSignUpPage;
