import { getCookie } from "@/utils/platform";
import Track from "@/utils/track";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "../index.module.less";
import { ReactComponent as SearchIcon } from "./SearchIcon.svg";

const RewardSearchHeader: React.FC = () => {
  const track = Track();
  const [search] = useSearchParams();
  const navigater = useNavigate();
  const goSearch = () => {
    track.track("resource_click", {
      page_name: "奖励中心",
      previous_page_name: search.get("page_name"),
      domain: "激励活动",
      resource_type: "搜索框",
      resource_name: "品牌搜索",
      btn_name: "点击搜索",
    });
    let envid: any = location.href.match(/[?&]env_identity=([^?#&]+)/);
    envid = envid && envid[1];
    navigater(
      `/mx-shop-micro/reward-search?token=${search.get("token")}&shopId=${search.get("shopId")}&channel=h5Mall&page_name=奖励中心&env_identity=${envid || getCookie("X-Hades-Env-Identity")}`,
    );
  };
  return (
    <div className={styles.header}>
      <div className={styles.search} onClick={goSearch}>
        {/* <img
          
          src="https://akim-oss.aikucun.com/50e910437189c5871b5767b05a8b72e49d5e820f_1713515794171_89.png"
        /> */}
        <SearchIcon className={styles.icon}/>
        <div className={styles.text}>请输入活动名称或品牌名称</div>
      </div>
    </div>
  );
};
export default RewardSearchHeader;
