import React from "react";

import KeepAlive from "react-activation";
import { useSearchParams } from "react-router-dom";
import Index from "./index";

interface Props {
  pagePath?: any;
}

const PromoteDetailContainer: React.FC<Props> = () => {
  const [search] = useSearchParams();
  const materialNo = search.get("materialNo");
  const cacheKey = materialNo ? `zhongcaoxiangqingye-${materialNo}` : "zhongcaoxiangqingye";
  return (
    <KeepAlive cacheKey={cacheKey} saveScrollPosition={true}>
      <Index />
    </KeepAlive>
  );
};

export default PromoteDetailContainer;
