import { getBaseurl } from "@/utils/url-parse";
import { Request } from "./request";

export type GZHInfo = {
  isFollow?: boolean;
  weChatInfoVO?: {
    officialAccountUrl?: string;
    officialAccountImageUrl?: string;
    officialAccountName?: string;
    skipImageUrl?: string;
    remark?: string;
    qrCodeUrl?: string;
  };
};

// 获取公众号相关信息
export async function getGZHInfo(): Promise<GZHInfo | undefined> {
  const res = await Request({
    url: `${getBaseurl()}/api/member/xapp/wechatAccount/weChat/getWeChatAccountInfoUrlByUserId`,
    method: "post",
  });
  return res?.data;
}

export async function getMiniSubscribe(params?: any) {
  const res = await Request({
    url: `${getBaseurl()}/api/mini/member/officialAccount/isSubscribe`,
    method: "get",
    params,
  });
  return res?.data;
}
