import { platform } from "@/utils/platform";
import useNavigation from "@/utils/useNavigate";
import AKJS from "@akc/akjs";
import { Dialog, Stepper, Toast } from "antd-mobile";
import copy from "copy-to-clipboard";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./AddressItem.module.less";

interface Props {
  index: number;
  item: any;
  expectedAwardAmount: number;
  onUpdateAddress: ({ item, index }) => void;
  onDeleteAddress: ({ item, index }) => void;
  onRefreshAddress: ({ item, index }) => void;
  onChangeQuantity: ({ item, index }) => void;
  onShare: ({ item, index }) => void;
  onSelectAddress: ({ item, index }) => void;
}
const AddressItem: React.FC<Props> = ({
  index,
  item,
  expectedAwardAmount,
  onUpdateAddress,
  onDeleteAddress,
  onRefreshAddress,
  onChangeQuantity,
  onShare,
  onSelectAddress,
}) => {
  const { isApp } = platform;

  const [search] = useSearchParams();

  const { navigate2AddressList } = useNavigation();

  const toAddressListPage = () => {
    onSelectAddress({ item, index });
    if (!item.isIdolSubmit) {
      return;
    }
    if (isApp) {
      AKJS.ready().then(() => {
        AKJS.action
          .dispatch("event.info.chooseAddress", { addressId: "" })
          .then((result) => {
            console.error("event.info.chooseAddres", result);
            if (result?.data?.addressId) {
              onUpdateAddress({
                item: {
                  ...item,
                  baseAddressId: result.data.addressId,
                },
                index,
              });
            }
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      });
    } else {
      navigate2AddressList({
        h5BackUrl: encodeURIComponent(
          `${window.location.href}&id=${item.id}&awardQuantity=${item.awardQuantity}`,
        ),
        fromPage: "rewardCenterDetail",
        addressOnlyId: item.id,
      });
    }
  };

  const onDelete = (e) => {
    e.stopPropagation();

    const modal = Dialog.show({
      title: "",
      content: "确认删除该地址？",
      actions: [
        [
          {
            key: "confirm",
            text: "确认",
            style: { color: "#666666" },
            onClick: () => {
              onDeleteAddress({ item, index });
              modal.close();
            },
          },
          {
            key: "close",
            text: "取消",
            bold: true,
            style: { color: "#FF4747" },
            onClick: () => {
              modal.close();
            },
          },
        ],
      ],
    });
  };

  const onRefresh = (e) => {
    e.stopPropagation();
    onRefreshAddress({ item, index });
  };

  const onChange = (awardQuantity) => {
    onChangeQuantity({
      item: {
        ...item,
        oldAwardQuantity: item.awardQuantity,
        awardQuantity,
      },
      index,
    });
  };

  const onCopy = (e, str) => {
    e.stopPropagation();
    copy(str);
    Toast.show({
      content: "复制成功",
      position: "center",
      maskClassName: styles["poster-unify-toast"],
    });
  };

  // useEffect(() => {}, []);

  return (
    <div className={`${styles.address__item} ${item?.submitStatus === 2 ? styles.address__item_accomplish : ""}`}>
      <div className={styles.item__line1}>
        <div className={styles.line1__left}>
          <span className={styles.left__name}>{item?.extraVO?.awardName}</span>
          <span 
          className={`
            ${styles.left__label} 
            ${item?.submitStatus === 0 ? styles.wait_invite : ""} 
            ${item?.submitStatus === 1 ? styles.fill_in : ""}
            ${item?.submitStatus === 2 ? styles.accomplish : ""}
            ${item?.submitStatus === 3 ? styles.overtime : ""}
          `}>{item?.labelContent || ""}</span>
        </div>
        <div className={styles.line1__right}>
          <img
            className={styles.icon_delete}
            src="https://akim-oss.aikucun.com/mshop/c07b50f4a8c59f9fbe1aa006360948daabb94bf5_1714995830264_80.png"
            onClick={(e) => onDelete(e)}
          />
          <img
            className={styles.icon_refresh}
            src="https://akim-oss.aikucun.com/mshop/c07b50f4a8c59f9fbe1aa006360948daabb94bf5_1714995870572_5.png"
            onClick={(e) => onRefresh(e)}
          />
        </div>
      </div>
      <div className={styles.item__line2}>
        <div className={styles.line2__left}>
          <Stepper
            defaultValue={item.awardQuantity}
            value={item.awardQuantity}
            max={expectedAwardAmount}
            min={1}
            onChange={(value) => {
              onChange(value);
            }}
            style={{
              "--active-border": "",
              "--border": "1px solid #ccc",
              "--border-inner": "solid .5px #ccc",
              "--border-radius": "4px",
              "--button-background-color": "#f1f2f5",
              "--button-font-size": "12px",
              "--button-text-color": "#222",
              "--button-width": "25px",
              "--height": "27px",
              "--input-background-color": "#f1f2f5",
              "--input-font-color": "#222",
              "--input-font-size": "12px",
              "--input-width": "37px",
            }}
          />
        </div>
        <div className={styles.line2__right} onClick={toAddressListPage}>
          {item.nickname && (
            <div className={styles.user}>
              <img className={styles.user__avatar} src={item.avatar} />
              <span className={styles.user__nickname}>{item.nickname}</span>
            </div>
          )}
          {!item.nickname && <div className={styles.select}>选择收货地址</div>}
        </div>
      </div>

      {item.address && (
        <div className={styles.item__address} onClick={toAddressListPage}>
          <img
            className={styles.logo}
            src="https://akim-oss.aikucun.com/mshop/3c1e2a2f77d87530e0a2c19f451a77b68d8ef6fd_1715860564041_86.png"
          />
          <span className={styles.username}>{item.linkman}</span>
          <span className={styles.phone}>{item.mobile}</span>
          <span className={styles.address}>{item.address}</span>
        </div>
      )}
      {item?.logisticsVOS?.length &&
        item?.logisticsVOS.map((logisticsItem, logisticsIndex) => {
          return (
            <div key={logisticsIndex} className={styles.item__logistics}>
              <div className={styles.logistics__line1}>
                <div className={styles.line1__left}>
                  <img
                    className={styles.logo}
                    src="https://akim-oss.aikucun.com/mshop/ca7483841dccf3c973984b2e517fb5dcde24023a_1716019928727_27.png"
                  />
                  <span className={styles.no}>物流编号：{logisticsItem.expressNo}</span>
                </div>
                <div className={styles.line1__right}>
                  <div className={styles.copy} onClick={(e) => onCopy(e, logisticsItem.expressNo)}>
                    复制
                  </div>
                </div>
              </div>
              {logisticsItem?.traceList?.length > 0 && (
                <div className={styles.logistics__line2}>
                  <div className={styles.line2__left}>
                    <div className={styles.node}></div>
                  </div>
                  <div className={styles.line2__right}>
                    <span className={styles.status}>
                      {logisticsItem?.traceList?.[0]?.traceStatusDesc}
                    </span>
                    {logisticsItem?.traceList?.[0]?.content}
                  </div>
                </div>
              )}
            </div>
          );
        })}

      <div className={styles.item__line3}>
        <div className={styles.line3__left}>
          {item?.lastModifiedTime && <p>最后修改时间: {item.lastModifiedTime}</p>}
        </div>
        <div className={styles.line3__right}>
          <div
            className={`
              ${styles.right__btn} 
              ${item?.submitStatus === 0 ? styles.wait_invite : ""} 
              ${item?.submitStatus === 1 ? styles.fill_in : ""}
              ${item?.submitStatus === 2 ? styles.accomplish : ""}
              ${item?.submitStatus === 3 ? styles.overtime : ""}
            `}
            onClick={(e) => {
              e.stopPropagation();
              onShare({ item, index });
            }}
          >
            {item?.buttonContent || ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressItem;
