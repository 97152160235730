import { ActivityTaskItem, ActivityTaskParams } from "@/api/rewardCenter";
import { queryTask } from "@/api/yearEndBonus";
import Track from "@/utils/track";
import constate from "constate";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

type actSellRebateStatus = 10 | 20 | 30 | 40;

interface tabItem {
  name: string;
  value: actSellRebateStatus;
  total: number;
}

const tabList: tabItem[] = [
  {
    name: "未开始",
    value: 10,
    total: 0,
  },
  {
    name: "进行中",
    value: 20,
    total: 0,
  },
  {
    name: "开奖中",
    value: 30,
    total: 0,
  },
  {
    name: "已开奖",
    value: 40,
    total: 0,
  }
];


const useSearch = () => {
  const track = Track();
  const [search] = useSearchParams();
  const taskAggregationCode = search.get("activityCode") || "";

  const [searchTagText, setSearchTagText] = useState("");
  const [showSearchTag, setShowSearchTag] = useState(true);

  const [showSuggest, setShowSuggest] = useState(false);

  const [inputText, setInputText] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [list, setList] = useState<ActivityTaskItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  //获取tab下的列表
  const queryList = async (params: ActivityTaskParams) => {
    // if (loading) {
    //   return;
    // }
    setLoading(true);
    const { pageIndex } = params || {};
    let num = pageIndex || pageNum || 1;
    let actList: any = [];
    try {
      if (num === 1) {
        setList([]);
      }
      const data: any = await queryTask({ ...params, pageIndex: num, pageSize: 10, taskAggregationCode });
      actList = data?.result || [];
      if (num > 1 && actList.length > 0) {
        setList(list.concat(actList));
      } else {
        setList(actList);
      }
      setPageNum(num + 1);
      setHasMore(data?.hasNext);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSearch = (data) => {
    setShowSuggest(false);
    setShowSearchTag(true);
    queryList({
      brandId: data.brandId,
      pageIndex: 1,
    });
  };

  const handleClickKeyTag = (data: any) => {
    setShowSuggest(false);
    setShowSearchTag(true);
    setSearchTagText(data.brandName);
    handleSearch(data);
  };

  // 加载更多
  const loadMore = async () => {
    // if (hasMore) {
    //   await queryList({ ...(query || {}) });
    // }
  };

  const onSearch = (index?: number) => {
    queryList({
      actSellRebateStatus: tabList[index ?? tabIndex].value,
      pageIndex: 1,
    });
  }

  const onSetTabIndex = (index) => {
    setTabIndex(index);
    onSearch(index)
  }

  useEffect(() => {
    let _tabIndex = search.get("tabIndex");
    onSetTabIndex(_tabIndex ? Number(_tabIndex) : 0)
  }, []);

  // useActivate(() => {
  //   let _tabIndex = search.get("tabIndex") ? Number(search.get("tabIndex")) : 0;
  //   if (_tabIndex !== tabIndex) {
  //     onSetTabIndex(_tabIndex ? Number(_tabIndex) : 0)
  //   }
  // });

  // useUnactivate(() => {
  //   setSearchTagText("");
  //   setShowSearchTag(true);
  //   setShowSuggest(false);
  //   setInputText("");
  //   setList([]);
  // });

  return {
    searchTagText,
    showSearchTag,
    showSuggest,
    setShowSearchTag,
    setShowSuggest,
    setSearchTagText,
    handleSearch,
    handleClickKeyTag,
    inputText,
    setInputText,
    tabList,
    tabIndex,
    setTabIndex,
    onSetTabIndex,
    list,
    loading,
    hasMore,
    pageNum,
    loadMore,
    onSearch
  };
};

export const [SearchProvider, useSearchContext] = constate(useSearch);
