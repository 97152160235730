import React from "react";
import styles from "./index.module.less";

interface Props {
  skuCurrentPriceInfo?: any;
}

const PriceCom: React.FC<Props> = (props: Props) => {
  const { skuCurrentPriceInfo } = props;

  const getDecimal = () => {
    const decimal = skuCurrentPriceInfo?.marketingPrice.toString().split(".")[1];
    if (decimal) {
      return <div className={styles.decimal}>.{decimal}</div>;
    }
    return "";
  };

  return (
    <div className={styles.price_real}>
      <div className={styles.symbol}>¥</div>
      <div className={styles.price_big}>{Math.trunc(skuCurrentPriceInfo?.marketingPrice)}</div>
      {getDecimal()}
    </div>
  );
};

export default PriceCom;
