import closeImage from "@/assets/icon_close_gray.png";
import ProductWaterfall from "@/components/product-waterfall";
import { getPlatform } from "@/utils/platform";
import { pxtorem } from "@/utils/pxtorem";
import { debounce } from "lodash";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { getSafeAreaInsetBottom } from "../../common.js";
import Empty from "../Empty/index";
import styles from "./index.module.less";

interface SearchContainerProps {
  dataSource: any[];
  pageState: {
    hasNext: boolean;
  };
  isLoading: boolean;
  loadMore: () => Promise<void>;
  requestCount?: number | boolean;
  trackData?: object;
  images: any[];
  closeFn?: (value: any) => void;
  onCartChange?: () => any;
  renderHeader: () => ReactNode;
}

const loadingPng =
  "https://akim-oss.aikucun.com/586c047ad6fdba65ce060e7f8b37a399d2fd1d9b_1729047640191_29.png";
const bottomDistance = 255;

let isTop = true,
  active = false,
  distance = 0,
  startY = 0,
  currentY = 0,
  initHeight = 0,
  initHightContainer = 0;

const SearchContainer: React.FC<SearchContainerProps> = ({
  dataSource,
  isLoading,
  pageState,
  loadMore,
  requestCount,
  trackData,
  images,
  closeFn,
  onCartChange,
  renderHeader,
}) => {
  let touchStatus = false;
  const containerHeight = useRef(initHightContainer);
  const [contetHeight, setContetHeight] = useState(0);

  useEffect(() => {
    // 初始高度
    distance = getSafeAreaInsetBottom();

    const ele = document.getElementById("search-container") as HTMLDivElement;

    initHightContainer = ele.offsetHeight - distance;
    containerHeight.current = initHightContainer;
  }, []);
  useEffect(() => {
    setContetHeight(images.length ? 100 : 60);
  }, [images]);

  // 阻止事件冒泡
  const stopTouchToParent = (e: React.TouchEvent<HTMLDivElement>, type?: string) => {
    e.stopPropagation();

    if (type) {
      setTimeout(() => {
        if (!isTop) {
          isTop = true;
          const ele = document.querySelector("#search-container") as HTMLDivElement;
          ele.style.position = "absolute";
          ele.style.height = initHightContainer + "px";
          containerHeight.current = initHightContainer;
        }
      }, 400);
    }
  };

  const moveStart = (e: React.TouchEvent<HTMLDivElement>) => {
    startY = e.changedTouches[0].clientY;
    initHeight = containerHeight.current;
    touchStatus = true;
  };
  const moving = (e: React.TouchEvent<HTMLDivElement>) => {
    const current = e.changedTouches[0].clientY;
    currentY = current - startY;

    if (initHeight - currentY < bottomDistance || initHeight - currentY > initHightContainer) {
      return;
    } else {
      const ele = document.querySelector("#search-container") as HTMLDivElement;
      ele.style.position = "absolute";
      ele.style.height = initHeight - currentY + "px";

      containerHeight.current = initHeight - currentY;
    }
  };
  const moveEnd = () => {
    if (initHeight === containerHeight.current) {
      return;
    }

    active = true;
    const ele = document.querySelector("#search-container") as HTMLDivElement;

    // 控制上滑和下滑不同应用的样式
    if (isTop) {
      ele.style.height = "auto";
      ele.style.position = "relative";
      ele.style.flex = "1";
    } else {
      ele.style.height = initHightContainer + "px";
    }

    isTop = isTop ? false : true;
    containerHeight.current = ele.offsetHeight - distance;
    setTimeout(() => {
      active = false;
    }, 2000);
  };
  const handleClickToClose = (e: any) => {
    e.stopPropagation();
    closeFn?.(e);
  };
  const onCartChangeFn = async () => {
    onCartChange?.();
  };
  const mergeTarkData = useMemo(() => {
    return dataSource.map((item, index) => ({
      ...item,
      trackData: {
        ...trackData,
        resource_id: item.activitySpuId,
        resource_rank: index + 1,
        click_activity_id: item.activityId,
        click_activity_spu_code: item.activitySpuId,
        click_sku_id: item.selectSkuId,
      },
    }));
  }, [dataSource, trackData]);

  return (
    <div
      className={`${styles["search-container"]} ${active ? styles["search-container-active"] : ""}`}
      style={{ height: getPlatform().isMini ? "93.5%" : "" }}
      id="search-container"
      onTouchStart={moveStart}
      onTouchMove={moving}
      onTouchEnd={moveEnd}
    >
      <div className={styles["close-icon"]} onClick={handleClickToClose}>
        <img src={closeImage} alt="" />
      </div>
      {renderHeader && <div className={styles["header-wrap"]}>{renderHeader()}</div>}
      {isLoading ? (
        <div className={styles.loadingMore}>
          <img src={loadingPng} />
        </div>
      ) : mergeTarkData.length ? (
        <div
          className={styles["product-container"]}
          onTouchStart={stopTouchToParent}
          onTouchMove={(e) => stopTouchToParent(e, "move")}
          onTouchEnd={(e) => stopTouchToParent(e, "end")}
          style={{ height: `calc(100% - ${pxtorem(contetHeight)})` }}
        >
          <ProductWaterfall
            productList={mergeTarkData}
            trackData={mergeTarkData}
            hasNext={pageState.hasNext}
            loadMore={loadMore}
            provideBackground={false}
            onCartChange={onCartChangeFn}
            btnClickName="resource_click"
            topRightLocationCode="H5_ScheduleDetail_SmallList_ProductPicRight"
            titleFrontLocationCode="H5_ScheduleDetail_SmallList_TitleFront"
            titleBottomLocationCode="H5_ScheduleDetail_SmallList_TitleDown"
          />
        </div>
      ) : (
        requestCount && <Empty label="未找到相关商品" />
      )}
    </div>
  );
};

export default SearchContainer;
