import { getCartTotal } from "@/api";
import BackPng from "@/assets/icon-arrow-left.png";
import HomePng from "@/assets/icon-home.png";
import CartPng from "@/assets/icon_navigation_cart_2.png";
import SearchPng from "@/assets/icon_search_black.png";
import { MICRO_APP_BASEURL } from "@/utils/platform";
import Track from "@/utils/track";
import useNavigation from "@/utils/useNavigate";
import { Image } from "antd-mobile";
import { inject, observer } from "mobx-react";
import React, {
  ReactElement,
  useCallback,
  // useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./index.module.less";

interface IProps {
  center?: ReactElement; // 中间的内容
  isShowCenter?: boolean; // 状态：是否为吸顶
  isStickyBackground?: any;
  isShowSearch?: boolean; // 状态：是否显示搜索框
  isHideRight?: boolean; // 状态：是否隐藏右侧
  reflash?: any;
}

const TopbarCom: React.FC<IProps> = ({
  center,
  isShowCenter,
  isStickyBackground,
  isShowSearch = true,
  isHideRight = false,
  reflash,
}: IProps) => {
  const { navigate2Search } = useNavigation();
  const [search] = useSearchParams();
  const merchantShopCode = search.get("merchantShopCode");
  const shopId = search.get("shopId");
  const navigater = useNavigate();
  const track = Track();
  const [cartTotal, setCartTotal] = useState(0);
  const goSearch = () => {
    navigate2Search({
      h5Query: {
        merchantShopCode: merchantShopCode,
        scene: "SEARCH_PRODUCT_FOR_MERCHANT_SHOP",
      },
    });
  };
  const goBackOrHome = () => {
    if (history.length > 1) {
      const url = location.href;
      history.back();
      setTimeout(() => {
        if (location.href === url) {
          if (search.get("distributorId")) {
            navigater(
              `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
                "distributorId",
              )}`,
            );
          } else {
            navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
          }
        }
      }, 500);
    } else {
      if (search.get("distributorId")) {
        navigater(
          `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
            "distributorId",
          )}`,
        );
      } else {
        navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
      }
    }
  };
  const toCart = useCallback(async () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "购物车",
        btn_name: "购物车",
        btn_text: "购物车",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/#/cart?shopId=${shopId}&channel=h5Mall&refSpm=30.2`);
    }
  }, []);
  const toIndex = useCallback(async () => {
    /* eslint-disable */
    if (track.track) {
      track.track("btn_click", {
        page_name: document.title,
        module: "首页",
        btn_name: "首页",
        btn_text: "首页",
      });
    }
    /* eslint-enable */
    if (search.get("distributorId")) {
      navigater(
        `${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall&distributorId=${search.get(
          "distributorId",
        )}`,
      );
    } else {
      navigater(`${MICRO_APP_BASEURL}/mx-shop-micro/home?shopId=${shopId}&channel=h5Mall`);
    }
  }, []);
  const queryGetCartTotal = async () => {
    const res = await getCartTotal({ shopBizCode: shopId });
    const { cartSkuNum = 0 } = res?.data || {};
    setCartTotal(cartSkuNum);
  };
  useEffect(() => {
    queryGetCartTotal();
  }, [reflash]);

  return (
    <div className={styles.container}>
      <div className={`${styles.left} top-bar-left`}>
        <div className={styles.leftImageBox} onClick={goBackOrHome}>
          <Image src={BackPng} className={styles.image}></Image>
        </div>
        <div className={styles.leftImageBox} onClick={toIndex}>
          <Image src={HomePng} className={styles.image}></Image>
        </div>
      </div>
      {center && isShowCenter && <div className={styles.center}>{center}</div>}
      {!isHideRight && isShowSearch && (
        <div className={styles.right}>
          <div className={styles.rightImageBox} onClick={() => goSearch()}>
            <Image src={SearchPng}></Image>
          </div>
          <div className={styles.rightImageBox} onClick={toCart}>
            <Image src={CartPng}></Image>
            {cartTotal > 0 && (
              <div
                className={[styles.badge, isStickyBackground ? styles.badgeSticky : ""].join(" ")}
              >
                {cartTotal}
              </div>
            )}
          </div>
        </div>
      )}
      {!isHideRight && !isShowSearch && (
        <div className={`${styles.right2} top-bar-right`}>
          <div className={styles.rightImageBox} onClick={toCart}>
            <Image src={CartPng}></Image>
            {cartTotal > 0 && (
              <div
                className={[styles.badge, isStickyBackground ? styles.badgeSticky : ""].join(" ")}
              >
                {cartTotal}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default inject("appStore")(observer(TopbarCom));
