import { ProductModel } from "@/models/product";
import AKJS from "@akc/akjs";
import { getAppSystem } from "./app-system";
import { platform } from "./platform";
import { versionsCompare } from "./version-compare";

/**
 * 弹出选色蒙层
 *
 * @param productInfo 商品模型
 */
export const showSKUPreview = async (productInfo: ProductModel) => {
  const { isApp, isiOS } = platform;
  if (isApp) {
    const appVersion = (await getAppSystem())?.appVersion;
    // Android 7.5.0 之后才支持选色
    // iOS 7.3.0 之后才支持选色
    const minVersion = isiOS ? "7.3.0" : "7.5.0";
    if (appVersion && versionsCompare(appVersion, minVersion) >= 0) {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("natRouterPresentFloating", {
          pageName: "skuPreview",
          target: 2,
          params: {
            spuId: productInfo.spuId,
            skus: JSON.stringify(productInfo.salePropertyFirstList),
            activityId: productInfo.activityId,
            activitySpuId: productInfo.activitySpuId,
          },
        });
      });
      return true;
    }
  }
  // 外面弹出选色弹层
  return false;
};
