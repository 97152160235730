import { ActivityTaskParams } from "@/api/rewardCenter";
import { pxtorem } from "@/utils/pxtorem";
import React, { useEffect, useState } from "react";
import styles from "../index.module.less";

interface IProps {
  tabs: {
    name: React.ReactNode;
    value: number | string;
    key: string;
  }[];
  value?: ActivityTaskParams["searchType"] | ActivityTaskParams["taskWinningStatus"];
  onChange?: (value: ActivityTaskParams["searchType"]) => void;
}

const RewardTabs: React.FC<IProps> = ({ tabs, value, onChange }) => {
  const [activeTab, setActiveTab] = useState<number | string>();
  //切换tab
  const changeTab = (val: ActivityTaskParams["searchType"]) => {
    setActiveTab(val);
    onChange?.(val);
  };

  useEffect(() => {
    if (value === undefined) {
      setActiveTab(tabs[0].value);
      onChange?.(tabs[0].value as ActivityTaskParams["searchType"]);
    } else {
      setActiveTab(value);
    }
  }, [value]);

  return (
    <div className={styles.tabs}>
      {tabs.map((item, index) => {
        return (
          <div
            className={styles.tab + " " + (activeTab === item.value ? styles.active : "")}
            key={index}
            onClick={() => changeTab(item.value as ActivityTaskParams["searchType"])}
          >
            {item.name}
          </div>
        );
      })}
      <div
        className={styles.line}
        style={{
          width: pxtorem(32),
          transform: `translateX(calc( 100vw / ${tabs.length} / 2 - ${pxtorem(16)} + 100vw / ${
            tabs.length
          } * ${tabs.findIndex((item) => activeTab === item.value) || 0})`,
        }}
      ></div>
    </div>
  );
};
export default RewardTabs;
