import Activity from "@/pages/activity";
import AfterPay from "@/pages/after-pay";
import Index from "@/pages/AppContainer";
import CouponList from "@/pages/CouponList";
import DailyReward from "@/pages/daily-reward";
import CustomerReward from "@/pages/daily-reward/customerReward";
import RedPacketDetail from "@/pages/daily-reward/redPacketDetail";
import MyReward from "@/pages/daily-reward/reward";
import Decorate from "@/pages/decorate/container";
import DispatchTask from "@/pages/dispatch-task";
import EnticeSearch from "@/pages/enticeSearch/searchContainer";
import Focus from "@/pages/Focus";
import HideSetting from "@/pages/hide-setting";
import HideSettingSearch from "@/pages/hide-setting/search";
import HighCommission from "@/pages/HighCommission";
import Home from "@/pages/home/index";
import ImageSearch from "@/pages/imageSearch";
import JoinBill from "@/pages/JointBill/JointBill";
import Logistics from "@/pages/logistics";
import Logout from "@/pages/logout";
import MarketContainer from "@/pages/MarketContainer";
import Material from "@/pages/material/container";
import AftersaleApp from "@/pages/MicroAppContainer/modules/aftersaleApp";
import CschatApp from "@/pages/MicroAppContainer/modules/cschatApp";
import MicroAppDetail from "@/pages/MicroAppDetail";
import My from "@/pages/my";
import MySetting from "@/pages/my-setting";
import NewPerson from "@/pages/new-person";
import Personal from "@/pages/personal";
import PersonalSetting from "@/pages/personal-setting";
import ProductDetailMaterial from "@/pages/product-detail/material";
import ProductDetail from "@/pages/product-detail/normal";
import PromoteDetail from "@/pages/promote-detail/container";
import PromoteContainer from "@/pages/promote/container";
import RecommendList from "@/pages/recommend-list";
import RewardCenter from "@/pages/rewardCenter";
import RewardCenterDetail from "@/pages/rewardCenter/detail";
import RewardCenterFillAddress from "@/pages/rewardCenter/fillAddress";
import RewardCenterLanding from "@/pages/rewardCenter/landing";
import RewardSearch from "@/pages/rewardCenter/search";
import SaleCalendar from "@/pages/sale-calendar";
import SearchPage from "@/pages/search-page";
import Search from "@/pages/searchContainer";
import SecKill from "@/pages/SecKill";
import SelectiveForward from "@/pages/selective-forward";
import SelectivePosters from "@/pages/selective-posters";
import ShareSetting from "@/pages/share-setting";
import ShopInfo from "@/pages/ShopInfo";
import ShopList from "@/pages/ShopList";
import ShowImages from "@/pages/ShowImages";
import TopList from "@/pages/TopList";
import UserTrack from "@/pages/userTrack";
import VideoShare from "@/pages/videoSharer";
import HistoryData from "@/pages/videoSharer/historyData";
import PerformanceBoard from "@/pages/videoSharer/performanceBoard";
import VideoSharerInstruction from "@/pages/videoSharer/VideoSharerInstruction";
import Achievement from "@/pages/year-end-bonus/achievement";
import BrandBole from "@/pages/year-end-bonus/brand-bole";
import YearEndBonusDetail from "@/pages/year-end-bonus/detail";
import Empowerment from "@/pages/year-end-bonus/empowerment";
import GoldMedal from "@/pages/year-end-bonus/gold-medal";
import YearEndBonusLanding from "@/pages/year-end-bonus/landing";
import YearEndBonusRank from "@/pages/year-end-bonus/rank";
import YearEndBonusSearch from "@/pages/year-end-bonus/search";
import YouxueCalendarPage from "@/pages/youxue/calendar";
import YouxueHistoryPage from "@/pages/youxue/history";
import YouxueInvitePage from "@/pages/youxue/invite";
import YouxueSignUpPage from "@/pages/youxue/sign-up";
import YouxueTravelConfirm from "@/pages/youxue/travel-confirm";
import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import ErrorBoundary from "../pages/ErrorBoundary";
import { KeepAliveContainer, Layout, lazyLoad, RouteName, TrackDataComp } from "./extra";

// 自定义路由实例
type Route = {
  path: string; // 路由路径
  name: RouteName; // 路由名称
  element?: React.ReactNode; // 入口react节点，lazy 为true时无用
  lazy?: boolean; // 是否懒加载
  lazyPath?: string; // 页面路径, lazy为true时必选
  to?: string; // 重定向，如果有这个属性，就会重定向到这个路由
  title?: string; // 页面标题
  KeepAlive?: boolean; // 是否缓存页面
  keepAliveList?: string[]; // 缓存页面的key,会从路由上去取
};

// 新增路由，先在extra的Route中定义，然后在下面的routesList中添加
export const routesList: Array<Route> = [
  {
    path: "/mx-shop-micro",
    name: RouteName.Index,
    element: <Index />,
    title: "会场",
  },
  {
    path: "/mx-shop-micro/activity/:activityId",
    name: RouteName.Activity,
    KeepAlive: true,
    keepAliveList: ["activityId", "isShowAll", "type"],
    element: <Activity />,
  },
  {
    path: "/mx-shop-micro/decorate",
    name: RouteName.Decorate,
    KeepAlive: true,
    keepAliveList: ["merchantShopCode"],
    element: <Decorate />,
  },
  {
    path: "/mx-shop-micro/micro-app-detail",
    name: RouteName.MicroAppDetail,
    element: <MicroAppDetail />,
    to: "/",
  },
  {
    path: "/mx-shop-micro/joint-bill",
    name: RouteName.JoinBill,
    KeepAlive: true,
    keepAliveList: ["couponId", "voucherNo", "awdInstanceId"],
    element: <JoinBill />,
  },
  {
    path: "/mx-shop-micro/seckill",
    name: RouteName.SecKill,
    element: <SecKill />,
  },
  {
    path: "/mx-shop-micro/decorate/coupon",
    name: RouteName.CouponList,
    element: <CouponList />,
  },
  {
    path: "/mx-shop-micro/decorate/toplist",
    name: RouteName.TopList,
    element: <TopList />,
  },
  {
    path: "/mx-shop-micro/logistics",
    name: RouteName.Logistics,
    KeepAlive: true,
    keepAliveList: ["timestamp"],
    element: <Logistics />,
  },
  {
    path: "/",
    name: RouteName.MicroAppDetail,
    element: <MicroAppDetail />,
  },
  {
    path: "/mx-shop-micro/decorate/profit",
    name: RouteName.HighCommission,
    element: <HighCommission />,
  },
  {
    path: "/mx-shop-micro/decorate/categroy",
    name: RouteName.HighCommission,
    element: <HighCommission />,
  },
  {
    path: "/mx-shop-micro/decorate/seckill",
    name: RouteName.SecKill,
    KeepAlive: true,
    keepAliveList: ["merchantShopCode", "componentCode"],
    element: <SecKill />,
  },
  {
    path: "/mx-shop-micro/shop/info",
    name: RouteName.ShopInfo,
    element: <ShopInfo />,
  },
  {
    path: "/mx-shop-micro/show-images",
    name: RouteName.ShowImages,
    element: <ShowImages />,
  },
  {
    path: "/mx-shop-micro/market",
    name: RouteName.Market,
    element: <MarketContainer />,
  },
  {
    path: "/mx-shop-micro/market/marketSearch",
    name: RouteName.MarketSearch,
    element: <Search />,
  },
  {
    path: "/mx-shop-micro/market/marketFocus",
    name: RouteName.Focus,
    element: <Focus />,
  },
  {
    path: "/mx-shop-micro/shopList",
    name: RouteName.ShopList,
    element: <ShopList />,
  },
  {
    path: "/mx-shop-micro/promote",
    name: RouteName.Promote,
    element: <PromoteContainer />,
  },
  {
    path: "/mx-shop-micro/promoteDetail",
    name: RouteName.PromoteDetail,
    element: <PromoteDetail />,
  },
  {
    path: "/mx-shop-micro/recommendList",
    name: RouteName.RecommendList,
    element: <RecommendList />,
  },
  {
    path: "/mx-shop-micro/enticeSearch",
    name: RouteName.EnticeSearch,
    element: <EnticeSearch />,
  },
  {
    path: "/mx-shop-micro/video-share",
    name: RouteName.VideoShare,
    KeepAlive: true,
    element: <VideoShare />,
  },
  {
    path: "/mx-shop-micro/my",
    name: RouteName.My,
    KeepAlive: true,
    element: <My />,
  },
  {
    path: "/mx-shop-micro/sub-setting",
    name: RouteName.MySetting,
    element: <MySetting />,
  },
  {
    path: "/mx-shop-micro/personal",
    name: RouteName.Personal,
    KeepAlive: true,
    element: <Personal />,
  },
  {
    path: "/mx-shop-micro/setting",
    name: RouteName.PersonalSetting,
    element: <PersonalSetting />,
  },
  {
    path: "/mx-shop-micro/track",
    name: RouteName.UserTrack,
    element: <UserTrack />,
  },
  {
    path: "/mx-shop-micro/home",
    name: RouteName.Home,
    KeepAlive: true,
    element: <Home />,
  },
  {
    path: "/mx-shop-micro/video-sharer-instruction",
    name: RouteName.VideoSharerInstruction,
    element: <VideoSharerInstruction />,
  },
  {
    path: "/mx-shop-micro/new-person",
    name: RouteName.NewPerson,
    KeepAlive: true,
    element: <NewPerson />,
  },
  {
    path: "/mx-shop-micro/logout",
    name: RouteName.Logout,
    element: <Logout />,
  },
  {
    path: "/mx-shop-micro/material",
    name: RouteName.Material,
    element: <Material />,
  },
  {
    path: "/mx-shop-micro/video-share/performanceBoard",
    name: RouteName.PerformanceBoard,
    element: <PerformanceBoard />,
  },
  {
    path: "/mx-shop-micro/video-share/historyData",
    name: RouteName.HistoryData,
    element: <HistoryData />,
  },
  {
    path: "/mx-shop-micro/selective-forward",
    name: RouteName.SelectiveForward,
    KeepAlive: true,
    keepAliveList: ["activityIdList", "brandIds", "categoryIdList", "shopCodes", "timestamp"],
    element: <SelectiveForward />,
  },
  {
    path: "/mx-shop-micro/selective-posters",
    name: RouteName.SelectivePosters,
    element: <SelectivePosters />,
  },
  {
    path: "/mx-shop-micro/share-setting",
    name: RouteName.ShareSetting,
    KeepAlive: true,
    element: <ShareSetting />,
  },
  {
    path: "/mx-shop-micro/search-page",
    name: RouteName.Search,
    KeepAlive: true,
    element: <SearchPage />,
  },
  {
    path: "/mx-shop-micro/dispatch-task",
    name: RouteName.DispatchTask,
    KeepAlive: true,
    element: <DispatchTask />,
  },
  {
    path: "/mx-shop-micro/reward-center",
    name: RouteName.RewardCenter,
    KeepAlive: true,
    element: <RewardCenter />,
  },
  {
    path: "/mx-shop-micro/reward-center/detail",
    name: RouteName.RewardCenterDetail,
    element: <RewardCenterDetail />,
  },
  {
    path: "/mx-shop-micro/reward-center/fill-address",
    name: RouteName.RewardCenterFillAddress,
    element: <RewardCenterFillAddress />,
  },
  {
    path: "/mx-shop-micro/reward-center/landing",
    name: RouteName.RewardCenterLanding,
    element: <RewardCenterLanding />,
  },
  {
    path: "/mx-shop-micro/reward-search",
    name: RouteName.RewardSearch,
    element: <RewardSearch />,
  },
  {
    path: "/mx-shop-micro/aftersale/*",
    name: RouteName.AftersaleApp,
    element: <AftersaleApp />,
  },
  {
    path: "/mx-shop-micro/cschat/*",
    name: RouteName.CschatApp,
    element: <CschatApp />,
  },
  {
    path: "/mx-shop-micro/yx/calendar",
    name: RouteName.YXCalendar,
    element: <YouxueCalendarPage />,
  },
  {
    path: "/mx-shop-micro/yx/invite",
    name: RouteName.YXInvite,
    element: <YouxueInvitePage />,
  },
  {
    path: "/mx-shop-micro/yx/signup",
    name: RouteName.YXSignUp,
    element: <YouxueSignUpPage />,
  },
  {
    path: "/mx-shop-micro/yx/travelConfirm",
    name: RouteName.YXTravelConfirm,
    element: <YouxueTravelConfirm />,
  },
  {
    path: "/mx-shop-micro/yx/history",
    name: RouteName.YXHistory,
    element: <YouxueHistoryPage />,
  },
  {
    path: "/mx-shop-micro/hide-setting",
    name: RouteName.HideSetting,
    element: <HideSetting />,
  },
  {
    path: "/mx-shop-micro/hide-setting/search",
    name: RouteName.HideSettingSearch,
    element: <HideSettingSearch />,
  },
  {
    path: "/mx-shop-micro/sale-calendar",
    name: RouteName.SaleCalendar,
    KeepAlive: true,
    element: <SaleCalendar />,
  },
  {
    path: "/mx-shop-micro/daily-reward",
    name: RouteName.DailyReward,
    KeepAlive: true,
    element: <DailyReward />,
  },
  {
    path: "/mx-shop-micro/reward",
    name: RouteName.MyReward,
    KeepAlive: true,
    keepAliveList: ["timestamp"],
    element: <MyReward />,
  },
  {
    path: "/mx-shop-micro/customer-reward",
    name: RouteName.CustomerReward,
    KeepAlive: true,
    element: <CustomerReward />,
  },
  {
    path: "/mx-shop-micro/redPacket-detail",
    name: RouteName.RedPacketDetail,
    element: <RedPacketDetail />,
  },
  {
    path: "/mx-shop-micro/product",
    name: RouteName.ProductDetail,
    element: <ProductDetail />,
  },
  {
    path: "/mx-shop-micro/product-material",
    name: RouteName.ProductDetailMaterial,
    element: <ProductDetailMaterial />,
  },
  {
    path: "/mx-shop-micro/image-search",
    name: RouteName.ImageSearch,
    element: <ImageSearch />,
  },
  {
    path: "/mx-shop-micro/year-end-bonus/brand-bole",
    name: RouteName.BrandBole,
    element: <BrandBole />,
    KeepAlive: true,
    keepAliveList: ["activityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/gold-medal",
    name: RouteName.GoldMedal,
    element: <GoldMedal />,
    KeepAlive: true,
    keepAliveList: ["activityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/achievement",
    name: RouteName.Achievement,
    element: <Achievement />,
    KeepAlive: true,
    keepAliveList: ["activityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/landing",
    name: RouteName.YearEndBonusLanding,
    element: <YearEndBonusLanding />,
    KeepAlive: true,
    keepAliveList: ["brandActivityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/search",
    name: RouteName.YearEndBonusSearch,
    element: <YearEndBonusSearch />,
    KeepAlive: true,
    keepAliveList: ["activityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/detail",
    name: RouteName.YearEndBonusDetail,
    element: <YearEndBonusDetail />,
    KeepAlive: true,
    keepAliveList: ["brandActivityCode"],
  },
  {
    path: "/mx-shop-micro/year-end-bonus/rank",
    name: RouteName.YearEndBonusRank,
    element: <YearEndBonusRank />,
  },
  {
    path: "/mx-shop-micro/year-end-bonus/empowerment",
    name: RouteName.Empowerment,
    element: <Empowerment />,
  },
  {
    path: "/mx-shop-micro/after-pay",
    name: RouteName.AfterPay,
    element: <AfterPay />,
  },
];

const children = routesList.map((item) => {
  if (item.to) {
    return {
      path: item.path,
      element: <Navigate replace to={item.to}></Navigate>,
      errorElement: <ErrorBoundary />,
    };
  }
  if (item.lazy && item.lazyPath) {
    return {
      path: item.path,
      element: item.KeepAlive ? (
        <KeepAliveContainer
          element={lazyLoad(item.lazyPath)}
          name={item.name}
          keepAliveList={item.keepAliveList}
        />
      ) : (
        <TrackDataComp>{lazyLoad(item.lazyPath)}</TrackDataComp>
      ),
      errorElement: <ErrorBoundary />,
    };
  }
  if (item.KeepAlive) {
    return {
      path: item.path,
      element: (
        <KeepAliveContainer
          element={item.element}
          name={item.name}
          keepAliveList={item.keepAliveList}
        />
      ),
      errorElement: <ErrorBoundary />,
    };
  }
  return {
    path: item.path,
    element: <TrackDataComp>{item.element}</TrackDataComp>,
    errorElement: <ErrorBoundary />,
  };
});

const routes = [
  {
    path: "/",
    element: <Layout />,
    children,
  },
];

const router = createBrowserRouter(routes);

export default router;
