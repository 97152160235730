import { getBaseurl } from "@/utils/url-parse";
import AKJS from "@akc/akjs";
import { Toast } from "antd-mobile";
import { Request, Response } from "../../api/request";
import { getAppSystem } from "../app-system";
import { navigate } from "../navigate";
import { platform } from "../platform";
import { versionsCompare } from "../version-compare";
import { wxShare } from "../wx-share";
// import useNavigate from "../useNavigate";

const staticUrl = getBaseurl();

// 档期转发
export const shareActivity = (options: {
  appOptions?: {
    activityNo: string;
    src?: string;
    sid?: string;
    marketName?: string;
    isOTC?: boolean;
    redPacket?: boolean;
  };
  miniOptions?: {
    activityNo: string;
    isOTC?: boolean;
  };
}) => {
  const { isApp, isMini } = platform;
  // const { navigate } = useNavigate();
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.page.open", {
        url: "/forwardActivity",
        param: options.appOptions,
      });
    });
  } else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          ...options.miniOptions,
          shareType: "activity",
          pageName: "activity-detail",
          shareOnlyId: options.miniOptions?.activityNo,
        },
      },
    });
  }
};

// 商品转发
export const shareProduct = async (options: {
  appOptions?: {
    activityNo: string;
    productId: string;
    src?: string;
    sid?: string;
    selectSkuId?: string;
    spuId?: string;
    marketName?: string;
    marketId?: string;
    isOTC?: boolean;
  };
  miniOptions?: {
    activityNo?: string;
    productNo?: string;
    pageCode?: string;
    skuMainImageUrls?: any;
    isOTC?: boolean;
  };
}) => {
  const { isApp, isMini } = platform;
  // const { navigate } = useNavigate();
  if (isApp) {
    const appVersion = (await getAppSystem())?.appVersion;
    if (appVersion && versionsCompare(appVersion, "7.0.0") < 0) {
      // 常销前版本，拿到skc信息，进入转发页面
      const response: Response<
        [
          {
            skcId?: string;
          },
        ]
      > = await Request({
        url: `${staticUrl}/api/mshop/mshop-aggr-prod/outer/product/convertProductId`,
        method: "post",
        data: {
          activitySpuIds: [options.appOptions?.productId],
          convertType: 2,
        },
      });
      if (response?.data && (response?.data?.length ?? 0) > 0) {
        const skcId = response?.data[0]?.skcId;
        if (!skcId) {
          console.log(`未查到对应的skcid. activitySpuId: ${options.appOptions?.productId}`);
          return;
        }
        navigate({
          appOptions: {
            url: "forward_product",
            query: {
              activityId: options.appOptions?.activityNo,
              productId: skcId,
              isOTC: options.appOptions?.isOTC,
            },
          },
        });
      }
      return;
    }
    navigate({
      appOptions: {
        url: "forward_product",
        query: options.appOptions,
      },
    });
  } else if (isMini) {
    delete options.miniOptions?.skuMainImageUrls;
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          ...options.miniOptions,
          shareType: "spu_product",
        },
      },
    });
  }
};

/**
 * 优惠券转发
 * 目前仅支持小程序
 * @returns
 */
export const shareCoupon = async (voucherNo: string) => {
  const { isApp, isMini } = platform;
  // const { navigate } = useNavigate();
  if (isApp) {
    // 暂时不会走到 App 逻辑
    return;
  } else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          voucherNo,
          shareType: "SHOP_COUPON",
        },
      },
    });
  }
};

// app分享卡片
export const shareCardByApp = (options: {
  appOptions?: {
    shareUrl: string;
    imageUrl: string;
    method?: number; // 分享方式：0 or 不传：默认（iOS 是系统分享）、1：转发助手 （目前仅爱库存实现了）
    title: string;
    onShare?: string; // 易观打点回调js方法名
  };
}) => {
  const { isApp } = platform;
  if (isApp) {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.share.shareToWX", options.appOptions);
    });
  }
};

// h5分享卡片
export const shareCardByH5 = (
  shareParams: {
    title?: string;
    desc?: string;
    link?: string;
    imgUrl?: string;
    scode?: any;
  },
  confirmParams:
    | {
        requestId: string;
        scene: string;
        extraMap: {
          contentId: string;
          contentType: string;
          activity_spu_code: string;
          sku_id: string;
        };
      }
    | boolean,
  url?: any,
  // extraParams?: {
  //   type: 1 | 2 | 3 | 4 | 5 | 6; //1: 逛一逛-推荐  2: 逛一逛-发现列表 3: 逛一逛-发现详情 4:实拍专区-列表 5: 实拍专区-详情-图文 6: 实拍专区-详情-视频
  //   materialNo: string | null | undefined; //种草
  // },
) => {
  wxShare(shareParams, confirmParams, {}, url);
};

/**
 * 转发海报链接 - App Only
 */
export const forwardPosterLink4App = async (props: {
  imageUrl: string;
  shareMessage?: string;
  copyUrl?: string;
  toastMessage?: string;
  pageCode?: string;
  pageName?: string;
  isShowVideoLink?: boolean;
  onShare?: (channel?: string) => void;
}) => {
  const { isApp } = platform;
  if (!isApp) {
    return;
  }

  const onShare = (res) => {
    const json = JSON.parse(res);
    const channel = json && json.data && json.data.share_channel;
    props.onShare?.(channel);
  };

  const appVersion = (await getAppSystem())?.appVersion;
  if (appVersion && versionsCompare(appVersion, "7.4.0") >= 0) {
    // 新版本，调用新的 JSBridge
    AKJS.action.dispatch("event.share.poster", {
      imgUrl: props.imageUrl,
      shareMessage: props.shareMessage,
      toastMessage: props.toastMessage,
      marketId: props.pageCode,
      marketName: props.pageName,
      conferenceId: props.pageCode,
      conferenceType: 6,
      copyUrl: props.copyUrl,
      copyMessage: props.copyUrl,
      isShowVideoLink: props.isShowVideoLink,
      onshare: onShare,
    });
  } else {
    AKJS.ready().then(() => {
      AKJS.action.dispatch("event.share.posterurl", {
        image: props.imageUrl,
        shareMessage: props.shareMessage,
        toastMessage: props.toastMessage,
        marketId: props.pageCode,
        marketName: props.pageName,
        copyUrl: props.copyUrl,
        copyMessage: props.copyUrl,
        isShowVideoLink: props.isShowVideoLink,
        onshare: onShare,
      });
    });
  }
};

// 素材转发
export const shareMaterial = async (options: {
  appOptions?: {
    materialId: string;
    activitySpuId: string;
    src?: string;
    sid?: string;
  };
  miniOptions?: {
    contentId: string;
  };
}) => {
  const { isApp, isMini } = platform;
  if (isApp) {
    navigate({
      appOptions: {
        url: "forwardNoteMaterial",
        query: options.appOptions,
      },
    });
  } else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          shareType: "MATERIAL3",
          contentId: options?.miniOptions?.contentId,
          contentType: "MATERIAL3",
        },
      },
    });
  }
};

// [满返, 满赠, PK]转发
export const shareB2R = async (options: {
  appOptions?: {
    id: string;
    bizType: string;
    mainTitle?: string;
    subTitle?: string;
    sourceScene?: string;
  };
  miniOptions?: {
    contentId: string;
    contentType: string;
    mainTitle?: string;
    subTitle?: string;
    sourceScene?: string;
  };
}) => {
  const { isApp, isMini } = platform;
  if (isApp) {
    navigate({
      appOptions: {
        url: "forwardB2rPromo",
        query: options.appOptions,
      },
    });
  } else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          ...options.miniOptions,
          shareType: "B2R",
        },
      },
    });
  }
};

// [满赠-收货地址]转发
export const shareB2RGift = async (options: {
  appOptions?: {
    contentId: string;
    contentDetailId: string;
    awardName: string;
    awardImage: string;
    imgUrl?: string;
  };
  miniOptions?: {
    contentId: string;
    contentDetailId: string;
    awardName: string;
    awardImage: string;
  };
}) => {
  const { isApp, isMini } = platform;
  if (isApp) {
    const appVersion = (await getAppSystem())?.appVersion;

    if (appVersion && versionsCompare(appVersion, "7.4.0") >= 0) {
      // 新版本，调用新的 JSBridge
      AKJS.action.dispatch("event.share.poster", {
        imgUrl: options?.appOptions?.imgUrl,
      });
    } else {
      AKJS.ready().then(() => {
        AKJS.action.dispatch("event.share.posterurl", {
          imgUrl: options?.appOptions?.imgUrl,
        });
      });
    }
  } else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          ...options.miniOptions,
          shareType: "B2R_GIFT",
        },
      },
    });
  }
};

// 必卖日历转发
export const shareSaleCalendar = async (options: {
  appOptions?: {
    contentId: string;
  };
}) => {
  const { isApp } = platform;
  if (isApp) {
    console.error("forwardComponents——", {
      appOptions: {
        url: "forwardComponents",
        query: { ...options.appOptions, contentType: "SALE_CALENDAR" },
      },
    });
    navigate({
      appOptions: {
        url: "forwardComponents",
        query: { ...options.appOptions, contentType: "SALE_CALENDAR" },
      },
    });
  }
};
// 日日奖转发
export const shareDailyReward = async (options: {
  contentId: string;
  shareType: string;
  contentDetailId?: string;
  awardName?: string;
  awardType?: string;
}) => {
  const { isApp, isMini } = platform;
  if (isApp) {
    const appVersion = (await getAppSystem())?.appVersion;
    if (appVersion && versionsCompare(appVersion, "7.50.0") < 0) {
      Toast.show("当前App版本过低，请更新至最新版本体验此功能");
      return;
    }
    AKJS.action.dispatch("natRouterPresentFloating", {
      target: 2,
      pageName: "forwardFloatingDialog",
      params: {
        src: "POSTER",
        bizInfo: JSON.stringify({
          config: { withPreview: true },
          track: {
            domain: "B2R",
            share_type: options?.shareType,
            img_type: `${options?.shareType}_POSTER`,
          },
          query: {
            contentId: options?.contentId, // 场景id
            contentType: options?.shareType, // 场景类型
            contentDetailId: options?.contentDetailId, // 用户信息
            bizContentExtra: { awardName: `${options?.awardName}`, awardType: options?.awardType }, // 额外字段
          },
        }),
      },
    });
  } else if (isMini) {
    const contentExtra = { awardName: options?.awardName, awardType: options?.awardType };
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          shareType: options?.shareType,
          contentId: options?.contentId,
          contentType: options?.shareType,
          contentDetailId: options?.contentDetailId, // 用户信息
          contentExtra: JSON.stringify(contentExtra), // 额外字段
        },
      },
    });
  }
};

// [年终奖]转发
export const shareAnnualBouns = async (options: {
  appOptions?: {
    contentId: string;
    contentType?: string;
    pageName?: string;
    src?: string; // sourceScene
  };
  miniOptions?: {
    contentId: string;
    contentType?: string;
    pageName?: string;
    src?: string; // sourceScene
  };
}) => {
  const { isApp, isMini } = platform;
  if (isApp) {
    navigate({
      appOptions: {
        url: "forwardComponents",
        query: {
          ...options.appOptions,
          sid: options?.appOptions?.contentId || "",
          shareType: options?.appOptions?.contentType || "",
        },
      },
    });
  }  else if (isMini) {
    navigate({
      miniOptions: {
        url: "/pages/poster-page/poster-page",
        query: {
          ...options.miniOptions,
          shareType: options?.miniOptions?.contentType  || "",
          sourceScene: options?.miniOptions?.src || "",
        },
      },
    });
  }
};